import React, { useRef, useState, useEffect } from 'react';
import { Animated, Image, Platform, Text, TextInput, TouchableWithoutFeedback, View } from 'react-native';

import moment from 'moment';

import Appearance from 'eCarra/styles/Appearance.js';
import ProgressBar from 'eCarra/views/ProgressBar/';

const TextField = React.forwardRef(({
    animations, animateOnMount, append, appendContent, autoCapitalize, autoComplete, autoCompleteType, autoCorrect, className, containerStyle, fieldStyle, format, icon, iconStyle, insetLabel, insetLabelStyle, insetContainerStyle, isSecure, keyboardType, loading, multiline, onChange, onBlur, onPress, placeholder, prepend, spellCheck, textContentType, useDelay, value
}, ref) => {

    const fieldRef = useRef(null);
    const textTimeout = useRef(null);

    const [active, setActive] = useState(false);
    const [text, setText] = useState(value !== null && value !== undefined ? value : '');
    const [onFocus, setOnFocus] = useState(null);
    const [styleAnimations, setStyleAnimations] = useState(null);

    useEffect(() => {
        setText(value !== null && value !== undefined ? value : '')
    }, [value]);

    const setOnFocusEvent = (callback) => {
        setOnFocus(callback);
    }

    const onFieldPress = () => {
        setActive(true);
        if(onPress && typeof(onPress) === 'function') {
            onPress();
        }
    }

    const onFieldBlur = () => {
        setActive(false);
        if(onBlur && typeof(onBlur) === 'function') {
            onBlur();
        }
    }

    const onTextChange = text => {

        if(textTimeout.current) {
            clearTimeout(textTimeout.current);
        }

        let newText = text.replace(prepend || '', '').replace(append || '', '');
        switch(format) {
            case 'phone_number':
            let cleaned = ('' + newText).replace(/\D/g, '')
            let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
            if(match) {
                let intlCode = (match[1] ? '+1 ' : '');
                newText = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
            }
            break;

            default:
            if(typeof(format) === 'function') {
                newText = format(newText);
            }
        }

        setText(newText);
        textTimeout.current = setTimeout(() => {
            if(typeof(onChange) === 'function') {
                onChange(newText);
            }
        }, useDelay === false ? 0 : 250);
    }

    const onTextEventChange = evt => {
        onTextChange(evt.target.value);
    }

    const getTextInputComponent = () => {

        if(Platform.OS === 'web' && multiline === true) {
            return (
                <textarea
                onChange={onTextEventChange}
                style={{
                    flex: 1,
                    borderWidth: 0,
                    flexGrow: 1,
                    height: 115,
                    fontSize: 14,
                    ...Appearance.fontWeight.get(700),
                    padding: 0,
                    resize: 'none',
                    color: Appearance.colors.text(),
                    backgroundColor: Appearance.colors.transparent,
                    outlineStyle: 'none',
                    outlineColor: Appearance.colors.transparent,
                    ...fieldStyle
                }} />
            )
        }

        return (
            <TextInput
            ref={ref}
            setOnFocusEvent={setOnFocusEvent}
            multiline={multiline || false}
            textAlignVertical={multiline ? 'top' : 'center'}
            onBlur={onFieldBlur}
            onChangeText={onTextChange}
            value={(prepend || '') + (text || '') + (append || '')}
            placeholder={placeholder || ''}
            placeholderTextColor={Appearance.colors.subText()}
            type={isSecure === true ? 'password' : 'text'}
            keyboardType={keyboardType}
            autoCompleteType={autoCompleteType}
            textContentType={textContentType}
            secureTextEntry={isSecure || false}
            autoComplete={autoComplete === false ? 'off' : 'on'}
            autoCorrect={autoCorrect !== false}
            autoCapitalize={autoCapitalize === false ? 'none' :'sentences'}
            onFocus={({ nativeEvent }) => {
                if(typeof(onFocus) === 'function') {
                    onFocus(nativeEvent);
                }
            }}
            style={{
                flex: 1,
                borderWidth: 0,
                flexGrow: 1,
                height: multiline ? 125 : 35,
                fontSize: 14,
                ...Appearance.fontWeight.get(700),
                padding: 0,
                color: Appearance.colors.text(),
                backgroundColor: Appearance.colors.transparent,
                ...fieldStyle,
                ...Platform.OS === 'web' && {
                    outlineStyle: 'none',
                    outlineColor: Appearance.colors.transparent
                }
            }} />
        )
    }

    useEffect(() => {

        // Convert object to object of animated values
        if(animations) {
            setStyleAnimations(Object.keys(animations).reduce((object, key) => {
                return {
                    ...object,
                    [key]: new Animated.Value(animations[key])
                }
            }, {}));
        }
    }, [animations]);

    useEffect(() => {
        if(styleAnimations && animateOnMount) {
            Object.keys(animateOnMount).forEach(key => {
                if(styleAnimations[key]) {
                    Animated.spring(styleAnimations[key], {
                        toValue: animateOnMount[key],
                        duration: 500,
                        friction: 10,
                        useNativeDriver: false
                    }).start();
                }
            });
        }
    }, [styleAnimations, animateOnMount]);

    return (
        <Animated.View style={{
            position: 'relative',
            flexGrow: 1,
            width: '100%',
            height: multiline ? 125 : 35,
            backgroundColor: Appearance.colors.textField(),
            borderRadius: 8,
            borderWidth: 1,
            borderColor: 'rgba(175,175,175,0)',
            overflow: 'hidden',
            ...styleAnimations,
            ...containerStyle
        }}>
            <View style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                height: '100%'
            }}>
                <View style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    flexGrow: 1,
                    position: 'relative',
                    height: '100%',
                    paddingVertical: multiline ? 12 : 5,
                    paddingHorizontal: 10
                }}>
                    {loading === true && (
                        <View style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: 2,
                        }}>
                            <View style={{
                                overflow: 'hidden',
                                borderRadius: 1,
                                height: 2
                            }}>
                                <ProgressBar animate={loading}/>
                            </View>
                        </View>
                    )}
                    {getIcon(icon) && (
                        <View style={{
                            paddingRight: 8,
                            marginRight: 8,
                            borderRightWidth: 1,
                            borderRightColor: Appearance.colors.lightGrey
                        }}>
                            <Image
                            source={getIcon(icon)}
                            style={{
                                width: 12,
                                height: 12,
                                resizeMode: 'contain',
                                tintColor: Appearance.colors.lightGrey,
                                ...iconStyle
                            }}/>
                        </View>
                    )}
                    {insetLabel && (
                        <View style={{
                            paddingRight: 8,
                            marginRight: 8,
                            borderRightWidth: 1,
                            borderRightColor: Appearance.colors.softBorder(),
                            ...insetContainerStyle
                        }}>
                            <Text ellipsizeMode={'tail'}
                            numberOfLines={1}
                            style={{
                                fontSize: 10,
                                ...Appearance.fontWeight.get(600),
                                color: Appearance.colors.subText(),
                                ...insetLabelStyle
                            }}>{insetLabel}</Text>
                        </View>
                    )}
                    <TouchableWithoutFeedback
                    onPress={onFieldPress}
                    style={{
                        flexGrow: 1
                    }}>
                        {getTextInputComponent()}
                    </TouchableWithoutFeedback>
                    {appendContent && typeof(appendContent) === 'function' && appendContent()}
                </View>
            </View>
        </Animated.View>
    )
})
export default TextField;

export const getIcon = icon => {
    switch(icon) {
        case 'user':
        return require('eCarra/images/user-icon-small.png');

        case 'email':
        return require('eCarra/images/envelope-icon-small.png');

        case 'phone':
        return require('eCarra/images/phone-icon-small.png');

        case 'calendar':
        return require('eCarra/images/calendar-alt-icon-small.png');

        case 'house':
        return require('eCarra/images/home-icon-small.png');

        case 'search':
        return require('eCarra/images/search-icon-small.png');

        case 'lock':
        return require('eCarra/images/lock-icon-small.png');

        case 'location':
        return require('eCarra/images/location-arrow-icon-small.png');

        case 'payment':
        return require('eCarra/images/check-alt-icon-small.png');

        default:
        return;
    }
}
