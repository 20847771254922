export default {
    check: async () => {
        return new Promise((resolve, reject) => {
            console.warn('Permissions have not been implemented for web');
            resolve(true);
        })
    },
    request: async () => {
        return new Promise((resolve, reject) => {
            console.warn('Permissions have not been implemented for web');
            resolve(true);
        })
    }
}
export const RESULTS = {}
export const PERMISSIONS = {
    IOS: {},
    ANDROID: {}
}
