import React, { useRef, useState, useEffect } from 'react';
import { Animated, Easing, Image, Platform, Text, View } from 'react-native';

import Appearance from 'eCarra/styles/Appearance.js';
import DropdownHeader from 'eCarra/views/DropdownHeader.js';
import LottieView from 'eCarra/views/Lottie/';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import Utils from 'eCarra/files/Utils.js';

const DetailsButton = require('eCarra/files/lottie/horizontal-details-grey.json');
const RefreshButton = require('eCarra/files/lottie/refresh-button-grey.json');

const HeaderWithButton = ({ button, label, loading, onAsyncPress, onPress, onDropDownPress, style, subLabel }) => {

    const [shouldRotate, setShouldRotate] = useState(loading || false);
    const [source, setSource] = useState(null);

    const animation = useRef(null);
    const rotationVal = useRef(new Animated.Value(0));
    const rotation = rotationVal.current.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '360deg']
    });

    const onButtonClick = async () => {
        try {
            if(typeof(onAsyncPress) === 'function') {
                setShouldRotate(true);
                await onAsyncPress();
                setShouldRotate(false);
            }
            if(typeof(onPress) === 'function') {
                onPress();
            }
        } catch(e) {
            console.log(e.message);
            setShouldRotate(false);
        }
    }

    const onSetRotation = () => {
        if(button !== 'refresh') {
            return;
        }
        animation.current = Animated.loop(
            Animated.timing(rotationVal.current, {
                toValue: 1,
                duration: 2000,
                easing: Easing.linear,
                useNativeDriver: true
            })
        );
    }

    const onUpdateRotation = () => {
        if(button !== 'refresh' || !animation.current) {
            return;
        }
        if(shouldRotate === true) {
            animation.current.start();
            return;
        }
        animation.current.stop();
        rotationVal.current.setValue(0);
    }

    const getSource = () => {
        switch(button) {
            case 'new':
            return require('eCarra/images/new-button-clear.png');

            case 'refresh':
            return require('eCarra/images/refresh-button-clear.png');

            case 'details':
            return require('eCarra/images/details-icon-clear-small.png');

            default:
            return null;
        }
    }

    useEffect(() => {
        onUpdateRotation();
    }, [shouldRotate]);

    useEffect(() => {
        setShouldRotate(loading || false);
    }, [loading]);

    useEffect(() => {
        onSetRotation();
    }, []);

    return (
        <View style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            ...style
        }}>
            {onDropDownPress
                ?
                <DropdownHeader
                label={label}
                subLabel={subLabel}
                onPress={onDropDownPress} />
                :
                <Text style={Appearance.textStyles.panelTitle()}>{label}</Text>
            }
            <TouchableOpacity
            activeOpacity={0.6}
            onPress={onButtonClick}
            style={{
                width: 30,
                height: 30,
                padding: 2
            }}>
                <View style={{
                    overflow: 'hidden',
                    borderRadius: 15
                }}>
                    <Animated.Image
                    source={getSource()}
                    style={{
                        width: '100%',
                        height: '100%',
                        resizeMode: 'contain',
                        backgroundColor: Appearance.colors.grey(),
                        ...button === 'refresh' && {
                            transform: [{ rotate: rotation }]
                        }
                    }} />
                </View>
            </TouchableOpacity>
        </View>
    )
}
export default HeaderWithButton;
