import React from 'react';
const Video = React.forwardRef((props, ref) => {
    const { autoPlay, disableFocus, muted, repeat, source, style } = props;
    return (
        <video
        ref={ref}
        autoPlay={autoPlay}
        muted={muted}
        loop={repeat}
        src={source}
        style={style} />
    )
})
export default Video;
