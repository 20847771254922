import React, { useEffect, useState } from 'react';
import Utils from 'eCarra/files/Utils.js';

const TouchableHighlight = ({ activeOpacity, children, onPress, underlayColor, style }) => {

    const [color, setColor] = useState(null);
    const [hover, setHover] = useState(false);

    useEffect(() => {
        setColor(Utils.hexToRGBA(underlayColor, activeOpacity));
    }, []);

    return (
        <div
        className={'touchable-highlight'}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={onPress}
        style={{
            ...Utils.transformStylesForWeb(style),
            ...hover && typeof(onPress) === 'function' && {
                cursor: 'pointer',
                backgroundColor: color
            }
        }}>
            {children}
        </div>
    )
}
export default TouchableHighlight;
