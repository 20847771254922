import React, { useState } from 'react';
import moment from 'moment';

import API from 'eCarra/files/api.js';
import Abstract from 'eCarra/classes/Abstract.js';
import Appearance from 'eCarra/styles/Appearance.js';
import Button from 'eCarra/views/Button.js';
import Company from 'eCarra/classes/Company';
import Driver from 'eCarra/classes/Driver.js';
import Message from 'eCarra/classes/Message';
import Mood from 'eCarra/classes/Mood';
import Note from 'eCarra/classes/Note';
import PaymentMethod from 'eCarra/classes/PaymentMethod.js';
import PromoCode from 'eCarra/classes/PromoCode';
import PromoCodes from 'eCarra/files/PromoCodes.js';
import Request from 'eCarra/files/Request/';
import Service from 'eCarra/classes/Service';
import SocketHelper from 'eCarra/files/SocketHelper.js';
import StatusCodes from 'eCarra/files/StatusCodes.js';
import Subscription from 'eCarra/classes/Subscription.js';
import User from 'eCarra/classes/User';
import Utils from 'eCarra/files/Utils';
import Valhalla from 'eCarra/classes/Valhalla.js';
import Vehicle from 'eCarra/classes/Vehicle.js';

class ReservationClass {

    _private = {};

    id = null;
	user_id = null;
    driver_id = null;
    customer = null;
    other_users = null;
    quick_scan_route = null;
	company = null;
	vehicle = null;
	service = null;
	pickup_date = null;
	date_submitted = null;
	passengers = 1;
	luggage = 0;
    mood = null;
    music = null;
    subscription = null;
    status = null;
    special_requests = {};
    polyline = null;
    multi_leg_polyline = null;
    overlays = [];
    distance = {
        estimate: null,
        real: null
    };
    duration = {
        estimate: null,
        real: null
    };
	origin = null;
    stops = null;
	destination = null;
    calculated_cost = null;
    credits = null;
    driveTip = null;
    payment_method = null;
    driver = null;
    emissions = null;
    promo_code = null;
    data = null;
    seeds = {};
    valhalla = {};
    is_return_trip = false; // internal mobile app use only
    edits = false;
    messages = false;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.driver_id = props.driver_id;
        this.customer = props.customer ? User.create(props.customer) : null;
        this.other_users = props.other_users ? props.other_users.map(user => User.create(user)) : null;
        this.company = props.company ? Company.create(props.company) : null;
        this.vehicle = props.vehicle ? Vehicle.Category.create(props.vehicle) : null;
        this.service = props.service ? Service.create(props.service) : null;
        this.promo_code = props.promo_code ? PromoCode.create(props.promo_code) : null;
    	this.pickup_date = moment(props.pickup_date);
    	this.date_submitted = moment(props.date_submitted);
    	this.passengers = props.passengers;
    	this.luggage = props.luggage;
        this.mood = props.mood;
        this.music = props.music;
        this.status = props.status;
        this.status = props.status ? getStatus(props.status.code) : null;
        this.subscription = props.subscription ? Subscription.create(props.subscription) : null;
        this.driver = props.driver ? Driver.create(props.driver) : null;
        this.polyline = props.polyline && Utils.decodePolyline(props.polyline, 6);
        this.multi_leg_polyline = props.multi_leg_polyline && props.multi_leg_polyline.map(shape => Utils.decodePolyline(shape, 6));
        this.distance = props.distance;
        this.duration = props.duration;
    	this.special_requests = props.special_requests;

    	this.origin = {
            name: props.origin.name,
            address: props.origin.address,
            location: {
                latitude: props.origin.lat,
                longitude: props.origin.long
            }
        };
    	this.destination = {
            name: props.destination.name,
            address: props.destination.address,
            location: props.destination.lat && props.destination.long && {
                latitude: props.destination.lat,
                longitude: props.destination.long
            }
        };

        this.stops = props.stops && {
            ...props.stops,
            locations: props.stops.locations && props.stops.locations.map(stop => ({
                id: stop.id,
                name: stop.name,
                address: stop.address,
                location: {
                    latitude: stop.lat,
                    longitude: stop.long
                }
            }))
        };
        this.emissions = props.emissions;
        this.driver_tip = props.driver_tip;
        this.credits = props.credits;
        this.data = props.data;
        this.calculated_cost = props.cost;

        return this;
    }

    getOverlays = () => {
        try {
            if(this.polyline) {
                return [{
                    id: 'reservation-route',
                    coordinates: this.polyline
                }];
            }
            if(this.multi_leg_polyline) {
                return this.multi_leg_polyline.map((shape, index) => ({
                    id: `reservation-route-${index}`,
                    coordinates: shape
                }))
            }
            return null;

        } catch(e) {
            console.error(e.message);
        }
    }

    getLocations = () => {
        let reservation = this.edits || this;
        let locations = [{
            id: 'origin',
            ...reservation.origin
        }];

        if(reservation.stops && reservation.stops.locations && reservation.stops.locations.length > 0) {
            reservation.stops.locations.forEach((stop, index) => {
                locations.push({
                    ...stop,
                    icon: { type: 'grey-broadcast' },
                })
            });
        }

        if(reservation.destination) {
            locations.push({
                id: 'destination',
                ...reservation.destination
            })
        }
        return locations;
    }

    isOnDemandRide = () => {
        return this.special_requests && this.special_requests.on_demand === true ? true : false;
    };

    setStopAsCompleted = async (utils, index) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id } = await Request.post(utils, '/reservation/', {
                    type: 'set_stop_as_completed',
                    reservation_id: this.id,
                    stop_id: this.stops.locations[index].id
                });
                this.stops.locations[index].completed = id;
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    getNavigationLocations = () => {

        let locations = [];
        if(this.stops && this.stops.locations && this.stops.locations.length > 0) {
            locations = locations.concat(this.stops.locations.filter(stop => {
                return stop.completed ? false : true;
            }).reduce((array, stop, index) => array.concat({
                key: `stop-${index}`,
                ...stop
            }), []))
        }

        if(this.destination) {
            locations.push({
                key: 'destination',
                ...this.destination
            })
        }

        return locations;
    }

    open = () => {
        this.edits = {
            id: this.id,
            user_id: this.user_id,
            customer: this.customer,
            other_users: this.other_users,
            company: this.company,
            vehicle: this.vehicle,
            service: this.service,
            pickup_date: this.pickup_date,
            origin: this.origin,
            stops: this.stops,
            destination: this.destination,
            passengers: this.passengers,
            luggage: this.luggage,
            mood: this.mood,
            subscription: this.subscription,
            status: this.status,
            special_requests: this.special_requests,
            distance: this.distance,
            duration: this.duration,
            cost: this.cost,
            credits: this.credits,
            promo_code: this.promo_code,
            payment_method: this.payment_method,
            driver_tip: this.driver_tip
        }
        return this.edits;
    }

    set = (props = {}) => {
        if(!this.edits) {
            this.open();
        }
        this.edits = {
            id: this.id,
            customer: props.customer || this.edits.customer,
            company: props.company !== undefined ? props.company : this.edits.company,
            vehicle: props.vehicle || this.edits.vehicle,
            service: props.service || this.edits.service,
            pickup_date: props.pickup_date || this.edits.pickup_date,
            origin: props.origin !== undefined ? props.origin : this.edits.origin,
            destination: props.destination !== undefined ? props.destination : this.edits.destination,
            passengers: props.passengers || this.edits.passengers,
            luggage: props.luggage !== undefined ? props.luggage : this.edits.luggage,
            mood: props.mood || this.edits.mood,
            status: props.status || this.edits.status,
            special_requests: props.special_requests || this.edits.special_requests || {},
            distance: props.distance || this.edits.distance,
            duration: props.duration || this.edits.duration,
            credits: props.credits !== undefined ? props.credits : this.edits.credits,
            subscription: props.subscription !== undefined ? props.subscription : this.edits.subscription,
            promo_code: props.promo_code !== undefined ? props.promo_code : this.edits.promo_code,
            payment_method: props.payment_method !== undefined ? props.payment_method : this.edits.payment_method,
            driver_tip: props.driver_tip !== undefined ? props.driver_tip : this.edits.driver_tip,
            stops: (props.stops || this.edits.stops) && {
                ...this.edits.stops,
                ...props.stops && {
                    ...props.stops,
                    locations: props.stops.locations.map(stop => ({
                        ...stop,
                        location: stop.location || {
                            latitude: stop.lat,
                            longitude: stop.long
                        }
                    }))
                }
            }
        }

        // attach customer company if applicable
        if(props.customer && props.customer.company) {
            this.edits.company = props.customer.company;
        }

        // set new payment method
        if(props.payment_method) {
            this.edits.payment_method = props.payment_method;
            this.edits.special_requests.card_id = props.payment_method.id;
        }

        // set new flight
        if(props.flight) {
            this.edits.special_requests.flight = props.flight;
        }

        // set new driver message
        if(props.requests) {
            this.edits.special_requests.message = props.requests;
        }

        // set on-demand props if applicable
        if(props.on_demand !== undefined) {
            this.edits.special_requests.on_demand = props.on_demand;
        }
        return this.edits;
    }

    close = () => {
        let reservation = this.edits || this;
        this.customer = reservation.customer;
        this.other_users = reservation.other_users;
        this.company = reservation.company;
        this.vehicle = reservation.vehicle;
        this.service = reservation.service;
        this.pickup_date = moment(reservation.pickup_date);
        this.distance = reservation.distance;
        this.duration = reservation.duration;
        this.origin = reservation.origin;
        this.destination = reservation.destination;
        this.passengers = reservation.passengers;
        this.luggage = reservation.luggage;
        this.music = reservation.music;
        this.mood = reservation.mood;
        this.subscription = reservation.subscription;
        this.driver_tip = reservation.driver_tip;
        this.credits = reservation.credits;
        this.special_requests = reservation.special_requests;
        this.other_users = reservation.other_users;
        this.musicChoice = reservation.musicChoice
        this.promo_code = reservation.promo_code;
        this.stops = reservation.stops;
        this.edits = null;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let reservation = this.edits;
                let { reservation_id } = await Request.post(utils, '/reservation/', {
                    type: 'new',
                    booking_channel: this.booking_channel,
                    user_id: reservation.customer.user_id,
                    route_id: this.quick_scan_route ? this.quick_scan_route.id : null,
                    company: reservation.company ? reservation.company.id : null,
                    other_users: reservation.other_users ? reservation.other_users.map(user => user.user_id) : null,
                    vehicle: reservation.vehicle.id,
                    service: reservation.service.id || 1,
                    date_submitted: moment().format('YYYY-MM-DD HH:mm:ss'),
                    pickup_date: moment(reservation.pickup_date).format('YYYY-MM-DD HH:mm:ss'),
                    passengers: reservation.passengers,
                    luggage: reservation.luggage,
                    mood: reservation.mood ? reservation.mood.id : null,
                    music: reservation.music,
                    subscription: reservation.subscription ? reservation.subscription.id : null,
                    driver_tip: reservation.driver_tip,
                    credits: reservation.credits,
                    promo_code: reservation.promo_code ? reservation.promo_code.id : null,
                    special_requests: reservation.special_requests,
                    origin: {
                        name: reservation.origin.name,
                        address: reservation.origin.address,
                        lat: reservation.origin.location.latitude,
                        long: reservation.origin.location.longitude
                    },
                    destination: {
                        name: reservation.destination.name,
                        address: reservation.destination.address,
                        lat: reservation.destination.location.latitude,
                        long: reservation.destination.location.longitude
                    },
                    stops: reservation.stops && reservation.stops.locations && {
                        ...reservation.stops,
                        locations: reservation.stops.locations.map(stop => ({
                            id: stop.id,
                            name: stop.name,
                            address: stop.address,
                            lat: stop.lat || stop.location.latitude,
                            long: stop.long || stop.location.longitude
                        }))
                    }
                })

                this.id = reservation_id;
                this.close();

                // join object room
                utils.sockets.emit('messages', 'join', {
                    id: reservation_id,
                    type: 'reservations'
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let reservation = this.edits;
                let { message, multi_leg_polyline, polyline } = await Request.post(utils, '/reservation/', {
                    type: 'update',
                    reservation_id: this.id,
                    route_id: this.quick_scan_route ? this.quick_scan_route.id : null,
                    customer_id: reservation.customer.user_id,
                    other_users: reservation.other_users && reservation.other_users.length > 0 ? reservation.other_users.map(user => user.user_id) : null,
                    company: reservation.company ? reservation.company.id : null,
                    vehicle: reservation.vehicle.id,
                    service: reservation.service.id || 1,
                    pickup_date: moment(reservation.pickup_date).format('YYYY-MM-DD HH:mm:ss'),
                    passengers: reservation.passengers,
                    luggage: reservation.luggage,
                    mood: reservation.mood ? reservation.mood.id : null,
                    music: reservation.music,
                    subscription: reservation.subscription ? reservation.subscription.id : null,
                    driver_tip: reservation.driver_tip,
                    credits: reservation.credits,
                    promo_code: reservation.promo_code ? reservation.promo_code.id : null,
                    special_requests: reservation.special_requests,
                    origin: {
                        name: reservation.origin.name,
                        address: reservation.origin.address,
                        lat: reservation.origin.location.latitude,
                        long: reservation.origin.location.longitude
                    },
                    destination: {
                        name: reservation.destination.name,
                        address: reservation.destination.address,
                        lat: reservation.destination.location.latitude,
                        long: reservation.destination.location.longitude
                    },
                    stops: reservation.stops && reservation.stops.locations && {
                        ...reservation.stops,
                        locations: reservation.stops.locations.map(stop => ({
                            id: stop.id,
                            name: stop.name,
                            address: stop.address,
                            lat: stop.lat || stop.location.latitude,
                            long: stop.long || stop.location.longitude
                        }))
                    }
                })

                this.close();
                this.polyline = polyline && Utils.decodePolyline(polyline, 6);
                this.multi_leg_polyline = multi_leg_polyline && multi_leg_polyline.map(shape => Utils.decodePolyline(shape, 6));
                resolve({ message: message });

            } catch(e) {
                reject(e);
            }
        })
    }

    formatPickupDate = () => {

        let pickup_date = moment(this.pickup_date);
        if(pickup_date.isSame(moment(), 'day')) {
            return pickup_date.format('[today at] h:mma')
        }
        if(pickup_date.isSame(moment().add(1, 'days'), 'day')) {
            return pickup_date.format('[tomorrow at] h:mma');
        }
        return pickup_date.format('MMMM Do [at] h:mma');
    }

    recentMessages = () => {
        return this.messages ? this.messages.slice(Math.max(this.messages.length - 5, 0)) : null
    }

    currentPaymentMethod = () => {
        if(this.customer.payment_methods) {

            let cardID = this.special_requests ? this.special_requests.card_id : null;
            for(var i in this.customer.payment_methods) {
                if((cardID && this.customer.payment_methods[i].id === cardID) || (!cardID && this.customer.payment_methods[i].default)) {
                    return this.customer.payment_methods[i];
                }
            }
         }
        return null;
    }

    translatePaymentMethod = async (utils, callback) => {

        let reservation = this.edits || this;
        let cardID = reservation.special_requests ? reservation.special_requests.card_id : null;

        if(!reservation.customer.payment_methods) {
            try {
                await reservation.customer.getPaymentMethods(utils);
                callback();
            } catch(e) {
                console.error(e.message);
            }
            return 'Loading payment methods...';
        }

        for(var i in reservation.customer.payment_methods) {
            if(reservation.customer.payment_methods[i].id === cardID) {
                reservation.payment_method = reservation.customer.payment_methods[i];
                return reservation.customer.payment_methods[i].summary();
            }
        }
        return reservation.customer.payment_methods.length === 0 ? 'No Payment Methods Found' : 'Default Payment Method';
    }

    getRevenue = () => {
        return this.calculated_cost ? Utils.toCurrency(this.calculated_cost.total_cost - (this.calculated_cost.credits || 0) - (this.calculated_cost.processing_fee || 0)) : null;
    }

    costBreakdown = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let reservation = this.edits || this;
                let { calculations, polyline, multi_leg_polyline, route } = await Request.post(utils, '/reservation/', {
                    type: 'cost',
                    reservation_id: this.id,
                    route_id: this.quick_scan_route ? this.quick_scan_route.id : null,
                    pickup_date : reservation.pickup_date ? moment(reservation.pickup_date).format('YYYY-MM-DD HH:mm:ss') : null,
                    company: reservation.company ? reservation.company.id : null,
                    service: reservation.service ? reservation.service.id : null,
                    vehicle: reservation.vehicle ? reservation.vehicle.id : null,
                    promo_code: reservation.promo_code ? reservation.promo_code.id : null,
                    subscription: reservation.subscription ? reservation.subscription.id : null,
                    credits: reservation.credits,
                    driver_tip: reservation.driver_tip,
                    origin: reservation.origin && reservation.origin.location && {
                        lat: reservation.origin.location.latitude,
                        long: reservation.origin.location.longitude
                    },
                    destination: reservation.destination && reservation.destination.location && {
                        lat: reservation.destination.location.latitude,
                        long: reservation.destination.location.longitude
                    },
                    stops: reservation.stops && reservation.stops.locations && {
                        ...reservation.stops,
                        locations: reservation.stops.locations.map(stop => ({
                            id: stop.id,
                            name: stop.name,
                            address: stop.address,
                            lat: stop.lat || stop.location.latitude,
                            long: stop.long || stop.location.longitude
                        }))
                    },
                    ...props
                })

                // update route variables if applicable
                if(route) {
                    this.route = route;
                    reservation.distance = {
                        ...reservation.distance,
                        estimate: route.distance
                    };
                    reservation.duration = {
                        ...reservation.duration,
                        estimate: route.duration
                    };
                }

                // update polylines if applicable
                this.polyline = polyline && Utils.decodePolyline(polyline, 6);
                this.multi_leg_polyline = multi_leg_polyline && multi_leg_polyline.map(shape => Utils.decodePolyline(shape, 6));

                // format calculations
                if(calculations && calculations.transaction_data) {
                    let { cost, warnings, transaction_data } = calculations;
                    resolve({
                        route: this.route,
                        warnings: warnings,
                        breakdown: {
                            total: cost,
                            service: cost,
                            credits: transaction_data.credits || 0,
                            driver_tip: transaction_data.driver_tip || 0,
                            promo_code: transaction_data.promo_code || 0,
                            line_items: transaction_data.line_items || [],
                            company_discount: transaction_data.company_discount || 0
                        }
                    });
                    return;
                }
                throw new Error('An unknown error occurred');

            } catch(e) {
                reject(e);
            }
        })
    }

    hasPreauthorization = () => {
        return (this.data && this.data.preauthorization && !this.data.preauthorization.revoked);
    }

    updateStatus = async (utils, code) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { status } = await Request.post(utils, '/reservation/', {
                    type: 'set_status',
                    reservation_id: this.id,
                    status: code
                })

                this.status = getStatus(status);
                utils.content.update(Abstract.create({
                    type: 'reservations',
                    object: this
                }))
                resolve(this.status);

            } catch(e) {
                reject(e);
            }
        })
    }
}

const getStatus = (c) => {

    // Appearance module could be null at runtime
    let code = c === null ? c : parseInt(c);
    let status = {
        code: isNaN(code) ? null : code
    };

    if(code === StatusCodes.reservations.rejected) {
        status.text = 'Declined';
        status.realText = 'Rejected';
        status.color = Appearance ? Appearance.colors.red : null;

    } else if(code === StatusCodes.reservations.approved) {
        status.text = 'Approved';
        status.realText = 'Approved';
        status.color = Appearance ? Appearance.colors.primary() : null;

    } else if(code === StatusCodes.reservations.returned) {
        status.text = 'Returned to Queue';
        status.realText = 'Returned to Queue';
        status.color = Appearance ? Appearance.colors.primary() : null;

    } else if(code === StatusCodes.reservations.cancelled) {
        status.text = 'Cancelled';
        status.realText = 'Cancelled';
        status.color = '#812222';

    } else if(code === StatusCodes.reservations.toPickup || code === StatusCodes.reservations.toDestination) {
        status.text = 'In Progress';
        status.realText = code === StatusCodes.reservations.toPickup ? 'In Route To Pickup' : 'In Route To Destination';
        status.color = Appearance ? Appearance.colors.blue : null;

    } else if(code === StatusCodes.reservations.completed) {
        status.text = 'Completed';
        status.realText = 'Completed';
        status.color = Appearance ? Appearance.colors.darkGrey : null;

    } else if(code === StatusCodes.reservations.unpaid) {
        status.text = 'Unpaid';
        status.realText = 'Unpaid';
        status.color = Appearance ? Appearance.colors.lightGrey : null;

    } else if(code === StatusCodes.reservations.preauthorized) {
        status.text = 'Preauthorized';
        status.realText = 'Preauthorized';
        status.color = Appearance ? Appearance.colors.lightGrey : null;

    } else if(code === StatusCodes.reservations.preauthorizationFailed) {
        status.text = 'Preauthorization Failed';
        status.realText = 'Preauthorization Failed';
        status.color = Appearance ? Appearance.colors.red : null;

    } else if(code === StatusCodes.reservations.preauthorizationRevoked) {
        status.text = 'Preauthorization Revoked';
        status.realText = 'Preauthorization Revoked';
        status.color = Appearance ? Appearance.colors.red : null;

    } else if(code === StatusCodes.reservations.adminUpdated) {
        status.text = 'Admin Updated';
        status.realText = 'Admin Updated';
        status.color = Appearance ? Appearance.colors.grey() : null;

    } else if(code === StatusCodes.reservations.driverUpdated) {
        status.text = 'Driver Updated';
        status.realText = 'Driver Updated';
        status.color = Appearance ? Appearance.colors.grey() : null;

    } else if(code === StatusCodes.reservations.customerEdited) {
        status.text = 'Customer Edited';
        status.realText = 'Customer Edited';
        status.color = Appearance ? Appearance.colors.grey() : null;

    } else if(code === StatusCodes.reservations.chargeProcessing) {
        status.text = 'Charge Processing';
        status.realText = 'Charge Processing';
        status.color = Appearance ? Appearance.colors.grey() : null;

    } else if(code === StatusCodes.reservations.chargePosted) {
        status.text = 'Charge Posted';
        status.realText = 'Charge Posted';
        status.color = Appearance ? Appearance.colors.darkGrey : null;

    } else if(code === StatusCodes.reservations.chargeFailed) {
        status.text = 'Charge Failed';
        status.realText = 'Charge Failed';
        status.color = Appearance ? Appearance.colors.red : null;

    } else if(code === StatusCodes.reservations.chargeIssue) {
        status.text = 'Charge Issue';
        status.realText = 'Charge Issue';
        status.color = Appearance ? Appearance.colors.red : null;

    } else {
        status.text = 'Pending';
        status.realText = 'Pending';
        status.color = Appearance ? Appearance.colors.grey() : null;
    }

    return status;
}

const fetchReservation = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { reservation } = await Request.get(utils, '/reservation/', {
                type: 'details',
                id: id
            });
            let target = new ReservationClass().create(reservation);
            resolve(target);
        } catch(e) {
            reject(e);
        }
    })
}

export default {
    new: () => new ReservationClass(),
    get: fetchReservation,
    create: props => new ReservationClass().create(props),
    getStatus: getStatus
};
