import React, { useRef, useState, useEffect } from 'react';
import { Image, TouchableOpacity, View } from 'react-native';

import Appearance from 'eCarra/styles/Appearance.js';
import Utils from 'eCarra/files/Utils.js';

const DirectionalButtons = ({ onBack, onNext }) => {

    const onButtonClick = type => {
        if(type === 'back' && typeof(onBack) === 'function') {
            onBack();
        }
        if(type === 'next' && typeof(onNext) === 'function') {
            onNext();
        }
    }

    return (
        <View style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        }}>
            <TouchableOpacity
            activeOpacity={0.6}
            onPress={onButtonClick.bind(this, 'back')}
            style={{
                width: 30,
                height: 30,
                padding: 2
            }}>
                <Image
                source={require('eCarra/images/back-arrow-button-clear.png')}
                style={{
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                    borderRadius: 15,
                    backgroundColor: Appearance.colors.grey()
                }} />
            </TouchableOpacity>
            <TouchableOpacity
            activeOpacity={0.6}
            onPress={onButtonClick.bind(this, 'next')}
            style={{
                width: 30,
                height: 30,
                padding: 2
            }}>
                <Image
                source={require('eCarra/images/next-arrow-button-clear.png')}
                style={{
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                    borderRadius: 15,
                    backgroundColor: Appearance.colors.grey()
                }} />
            </TouchableOpacity>
        </View>
    )
}
export default DirectionalButtons;
