export default {
    events: {
        restartNode: 1,
        restartSystem: 2,
        enableBAT: 3,
        disableBAT: 4,
        softwareUpdate: 5,
        addSensor: 6,
        removeSensor: 7,
        listSensors: 8,
        addNetwork: 9,
        removeNetwork: 10,
        connectToNetwork: 11,
        disconnectFromNetwork: 12,
        listNetworks: 13,
        onSensorsRead: 14,
        onSystemRead: 15,
        onWifiRead: 16,
        onUpdate: 17,
        onTCPChange: 18,
        onBATChange: 19,
        onSync: 20,
        onWifiChange: 21,
        onDataPrefChange: 22
    },
    modifiers: {
        bat: {
            enable: 1,
            disable: 2
        },
        dataPref: {
            max_data: 1,
            balanced: 2,
            extend_life: 3
        }
    },
    devices: {
        seedpod: 1,
        aq: 2
    },
    errors: {
        general: 1,
        system: 2,
        notFound: 3,
        notAuthorized: 4,
        notAvailable: 5
    }
}
