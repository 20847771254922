import React from 'react';

import OrderChannel from 'eCarra/classes/OrderChannel.js';
import OrderHost from 'eCarra/classes/OrderHost.js';
import OrderOption from 'eCarra/classes/OrderOption.js';

class OrderCategoryClass {

    id = null;
    title = null;
    description = null;
    host = null;
    channel = null;
    options = null;
    active = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.title = props.title;
        this.description = props.description;
        this.host = props.host ? OrderHost.create(props.host) : null;
        this.channel = props.channel ? OrderChannel.create(props.channel) : null;
        this.active = Boolean(props.active);
        this.options = props.options ? props.options.map(o => {
            let option = OrderOption.create(o);
            option.category = this;
            return option;
        }).sort((a, b) => a.name > b.name) : null
        return this;
    }
}
export default {
    create: props => new OrderCategoryClass().create(props)
};
