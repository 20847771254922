import React, { useRef, useState, useEffect } from 'react';
import { Animated, Image, Keyboard, Platform, ScrollView, Text, TextInput, TouchableWithoutFeedback, View } from 'react-native';

import { fetchVehicleCategories } from 'eCarra/managers/Vehicles.js';
import { fetchServices } from 'eCarra/managers/Reservations.js';
import moment from 'moment';
import { requestNotificationPermission } from 'eCarra/managers/Settings.js';
import update from 'immutability-helper';

import API from 'eCarra/files/api.js';
import Abstract from 'eCarra/classes/Abstract.js';
import AddressLookupField from 'eCarra/views/AddressLookupField.js';
import AnimatedLoader from 'eCarra/views/AnimatedLoader.js';
import Appearance from 'eCarra/styles/Appearance.js';
import AsyncStorage from '@react-native-community/async-storage';
import Button from 'eCarra/views/Button.js';
import { Camera } from 'eCarra/files/Camera/';
import { DriverARLocation } from 'eCarra/managers/Reservations.js';
import KeyboardManager from 'eCarra/files/KeyboardManager.js';
import Layer from 'eCarra/structure/Layer.js';
import LottieButton from 'eCarra/views/LottieButton.js';
import LottieView from 'eCarra/views/Lottie/';
import { Annotation, Follow, FollowWithCourse, Map, DallasCenterCoordinate } from 'eCarra/views/Maps/';
import { NewQuickScanCustomerRoute } from 'eCarra/managers/Routes.js';
import Order from 'eCarra/classes/Order.js';
import ProgressBar from 'eCarra/views/ProgressBar/';
import Reservation from 'eCarra/classes/Reservation.js';
import Request from 'eCarra/files/Request/';
import Route from 'eCarra/classes/Route.js';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import TouchableHighlight from 'eCarra/views/TouchableHighlight/';
import { TripNavigator } from 'eCarra/managers/Reservations.js';
import Service from 'eCarra/classes/Service.js';
import Screen from 'eCarra/files/Screen.js';
import SocketHelper from 'eCarra/files/SocketHelper.js';
import StatusCodes from 'eCarra/files/StatusCodes.js';
import User from 'eCarra/classes/User.js';
import Utils, { ModularContentLogic } from 'eCarra/files/Utils.js'
import Vehicle from 'eCarra/classes/Vehicle.js';
import Views, { AltBadge } from 'eCarra/views/Main.js';

const ShareLocationButton = require('eCarra/files/lottie/share-location-grey.json');

export const RideBooking = ({ initAbstract }, { utils, onPanelStateChange }) => {

    const viewID = `rideBooking`;
    const locationsHeight = 144;
    const shareLocation = useRef(null);
    const shouldFetchDriver = useRef(true);
    const shouldScan = useRef(true);

    const [abstract, setAbstract] = useState(initAbstract);
    const [addressBook, setAddressBook] = useState([]);
    const [driver, setDriver] = useState(null);
    const [driverProps, setDriverProps] = useState(null);
    const [followUserLocation, setFollowUserLocation] = useState(false);
    const [isQuickScanInProgress, setIsQuickScanInProgress] = useState(false);
    const [keyboardHeight, setKeyboardHeight] = useState(0);
    const [keyboardOpen, setKeyboardOpen] = useState(KeyboardManager.keyboardOpen);
    const [loading, setLoading] = useState(true);
    const [location, setLocation] = useState(utils.location.last());
    const [modularContent, setModularContent] = useState(null);
    const [nonce, setNonce] = useState(Utils.randomString());
    const [nearbyVehicles, setNearbyVehicles] = useState([]);
    const [panelState, setPanelState] = useState({ view: null, field: 'destination' });
    const [results, setResults] = useState([]);
    const [routeID, setRouteID] = useState(null);
    const [searching, setSearching] = useState(false);
    const [searchText, setSearchText] = useState(null);
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [shareLocationSource, setShareLocationSource] = useState(null);
    const [targets, setTargets] = useState([]);

    const [fields, setFields] = useState({
        origin: {
            key: 'origin',
            ref: useRef(null),
            text: null
        },
        destination: {
            key: 'destination',
            ref: useRef(null),
            text: null
        }
    })

    const [booking, setBooking] = useState({
        top: new Animated.Value(-100),
        height: new Animated.Value(locationsHeight),
        opacity: new Animated.Value(0),
        padding: {
            top: new Animated.Value(15 + (Screen.safeArea.top + 50))
        },
        buttons: {
            opacity: new Animated.Value(0),
            bottom: new Animated.Value(15)
        }
    });
    const [camera, setCamera] = useState({
        opacity: new Animated.Value(0)
    });
    const [details, setDetails] = useState({
        options: {
            opacity: new Animated.Value(0)
        },
        buttons: {
            height: new Animated.Value(0),
            opacity: new Animated.Value(0)
        }
    })
    const [map, setMap] = useState({
        opacity: new Animated.Value(1)
    });

    const onAddAddressBookItem = () => {

        Keyboard.dismiss();
        utils.layer.open({
            id: 'add-address-book-item',
            Component: AddAddressBookItem.bind(this, {
                onChange: async place => {
                    if(!place) {
                        return;
                    }
                    try {

                        let id = await utils.user.get().addToAddressBook(utils, place);
                        let distance = location ? Utils.linearDistance(location, place.location) : null;
                        setAddressBook(addressBook => update(addressBook, {
                            $unshift: [{
                                id: id,
                                name: place.name,
                                address: place.address,
                                nearby: distance && distance < 5,
                                location: {
                                    lat: place.location.latitude,
                                    long: place.location.longitude
                                }
                            }]
                        }))

                    } catch(e) {
                        utils.alert.show({
                            title: 'Oops!',
                            message: `There was an issue adding your new place. ${e.message || 'An unknown error occurred'}`
                        })
                    }
                }
            })
        })
    }

    const onAnimateFields = async () => {
        return new Promise((resolve ,reject) => {

            if(panelState.view === 'locations') {
                resolve(); // prevent animation delay if view is already set
                return;
            }

            utils.structure.navigation.hide();
            setPanelState(panelState => update(panelState, {
                view: {
                    $set: 'locations'
                }
            }))

            Animated.timing(map.opacity, {
                toValue: 0,
                duration: 500,
                useNativeDriver: true
            }).start(() => {
                if(shareLocation.current) {
                    shareLocation.current.play(0, 39);
                }
                resolve();
            })
            Animated.spring(booking.top, {
                toValue: Screen.safeArea.top,
                duration: 500,
                friction: 10,
                useNativeDriver: false
            }).start()
            Animated.spring(booking.padding.top, {
                toValue: 0,
                duration: 500,
                friction: 10,
                useNativeDriver: false
            }).start();
        })
    }

    const onAutoCompletePress = location => {

        // update label for current booking field
        setFields(fields => update(fields, {
            [panelState.field]: {
                text: {
                    $set: location.name || location.address
                }
            }
        }));

        // update selection and clear results list
        setResults([]);
        abstract.object.set({ [panelState.field]: location });
    }

    const onBookRide = async target => {
        return new Promise(async (resolve, reject) => {
            try {

                // verify that contact information is present for customer before moving forward
                await onValidateAccountDetails();

                // submit reservation and update subscribers
                await target.object.submit(utils);
                utils.content.fetch('reservations');
                resolve();

                // reset view back to default booking view
                setPanelState(panelState => update(panelState, {
                    view: {
                        $set: 'reservations'
                    }
                }));

                // create target for next booking
                setAbstract(Abstract.create({
                    type: 'reservations',
                    object: Reservation.new()
                }));

                // show on-demand searching if applicable
                if(target.object.isOnDemandRide()) {
                    utils.on_demand.searching.set({ abstract: target });
                    return;
                }

                // update address book items
                try {
                    await utils.user.get().addToAddressBook(utils, {
                        ...target.object.destination,
                        location: {
                            lat: target.object.destination.location.latitude,
                            long: target.object.destination.location.longitude
                        }
                    });

                } catch(e) {
                    console.error(e.message);
                }

                // attach driver to quick scan route and present trip navigator
                if(target.object.quick_scan_route) {
                    target.object.driver = target.object.quick_scan_route.driver;
                    utils.alert.show({
                        title: `Let's Start Moving`,
                        message: `Your ride has been sent to your driver. Please let ${target.object.quick_scan_route.driver.first_name} know when you're ready to go`,
                        icon: {
                            path: target.object.quick_scan_route.driver.avatar
                        },
                        buttons: [{
                            key: 'navigator',
                            title: 'Open Trip Navigator',
                            style: 'default'
                        },{
                            key: 'cancel',
                            title: 'Dismiss',
                            style: 'cancel'
                        }],
                        onPress: key => {
                            if(key === 'navigator') {
                                utils.layer.openFloating({
                                    id: `trip-navigator-${target.getTag()}`,
                                    abstract: target,
                                    Component: TripNavigator
                                });
                                return;
                            }
                        }
                    });
                    return;
                }

                // present option to book return trip if service allows
                if(target.object.is_return_trip !== true && target.object.service.shouldBookReturnTrip) {
                    utils.alert.show({
                        title: 'All Done!',
                        message: `Your Reservation for ${target.object.formatPickupDate()} has been submitted. Would you like to book your return trip?`,
                        buttons: [{
                            key: 'confirm',
                            title: 'Yes',
                            style: 'default'
                        },{
                            key: 'cancel',
                            title: 'Maybe Later',
                            style: 'cancel'
                        }],
                        onPress: async key => {

                            if(key !== 'confirm') {
                                return;
                            }

                            let res = Reservation.new();
                            res.company = abstract.object.company;
                            res.is_return_trip = true;
                            res.customer = utils.user.get();
                            res.other_users = abstract.object.other_users;
                            res.pickup_date = moment(abstract.object.pickup_date).add(2, 'hours');
                            res.service = abstract.object.service;
                            res.vehicle = abstract.object.vehicle;
                            res.origin = abstract.object.destination;
                            res.destination = abstract.object.origin;
                            res.passengers = abstract.object.passengers;
                            res.luggage = abstract.object.luggage;
                            res.subscription = abstract.object.subscription;

                            utils.layer.reservation.details(res, {
                                onBookRide: onBookRide,
                                onUpdateRide: onUpdateRide,
                                onClose: () => {
                                    // add subscribers back for normal keyboard usage
                                    utils.keyboard.subscribe(viewID, getKeyboardCallbacks)
                                }
                            })
                        }
                    });
                    return;
                }

                utils.alert.show({
                    title: 'All Done!',
                    message: `Your Reservation for ${target.object.formatPickupDate()} has been submitted. We'll let you know if there are any issues with your pickup time or date.`
                });

            } catch(e) {
                reject(e);
            }
        })
    }

    const onCameraReady = async ({ setCamera }) => {
        try {

            // hide fullscreen loader
            utils.loader.hide(onLoadComplete);

            // start location updates if permission has already been granted
            let { foreground } = await utils.location.check();
            if(foreground) {
                utils.location.start();
            }

            // set camera to current location
            await setCamera({
                zoomLevel: 0,
                animationDuration: 1000,
                centerCoordinate: {
                    location: {
                        latitude: DallasCenterCoordinate[1],
                        longitude: DallasCenterCoordinate[0],
                        ...location
                    }
                }
            }, true);

            // request permissions if applicable
            await Utils.sleep(1);
            onSetupPermissions();

        } catch(e) {
            console.error(e.message);
        }
    }

    const onCommunityUpdate = data => {
        try {
            let { user_id, vehicle_id, location } = JSON.parse(data);
            setNearbyVehicles(vehicles => {

                // prevent nearby vehicles from showing for user
                if(user_id == utils.user.get().user_id) {
                    return vehicles;
                }

                // update previous vehicle
                let index = (vehicles || []).findIndex(v => v.id === vehicle_id);
                if(index < 0) {
                    return (vehicles || []).concat([{
                        id: vehicle_id,
                        location: {
                            latitude: location.lat,
                            longitude: location.long,
                            heading: location.heading
                        }
                    }]);
                }

                // add new vehicle
                return update((vehicles || []), {
                    [index]: {
                        location: {
                            $set: {
                                latitude: location.lat,
                                longitude: location.long,
                                heading: location.heading
                            }
                        }
                    }
                });
            });

        } catch(e) {
            console.error(e.message);
        }
    }

    const onCurrentLocationPress = async () => {
        try {

            setSearching(true);
            let location = await utils.location.get();
            if(shareLocation.current) {
                shareLocation.current.play(40, 90);
            }

            // update target with current location
            abstract.object.set({
                [panelState.field]: {
                    name: 'Current Location',
                    address: null,
                    location: {
                        latitude: location.latitude,
                        longitude: location.longitude
                    }
                }
            });

            // update currently active text field
            setFields(fields => update(fields, {
                [panelState.field]: {
                    text: {
                        $set: 'Current Location'
                    }
                }
            }));

            setSearching(false);

        } catch(e) {
            setSearching(false);
            utils.alert.show({
                title: 'Location Services',
                message: `It looks like we were unable to find your current location. Please make sure that Location Services are enabled for the ${utils.client.get().name} App`
            });
        }
    }

    const onDonePress = async () => {
        try {

            // start loading while geocoding is in progress
            setLoading('done');

            // verify that a pickup and drop-off location was provided
            let { destination, origin } = abstract.object.edits;
            if(!destination || !origin) {
                throw new Error(`Please choose a pickup and drop-off location before moving on.`);
            }

            // geocode origin as needed
            // address book items do not need to be geocoded
            // current location does not need to be geocoded
            if(!origin.location && origin.name !== 'Current Location') {
                let { result } = await Utils.geocode(utils, origin, nonce);
                abstract.object.edits.origin = {
                    ...abstract.object.edits.origin,
                    location: result && {
                        latitude: result.location.lat,
                        longitude: result.location.long
                    }
                }
            }

            // geocode destination as needed
            // address book items do not need to be geocoded
            if(!destination.location) {
                let { result } = await Utils.geocode(utils, destination, nonce);
                abstract.object.edits.destination = {
                    ...abstract.object.edits.destination,
                    location: result && {
                        latitude: result.location.lat,
                        longitude: result.location.long
                    }
                }
            }

            // show navigation elements and transition
            await utils.structure.navigation.showAsync();
            Animated.timing(map.opacity, {
                toValue: 1,
                duration: 500,
                useNativeDriver: true
            }).start();
            Animated.spring(booking.top, {
                toValue: 0,
                duration: 500,
                friction: 10,
                useNativeDriver: false
            }).start();
            Animated.spring(booking.padding.top, {
                toValue: 15 + (Screen.safeArea.top + 50),
                duration: 500,
                friction: 10,
                useNativeDriver: false
            }).start();
            Animated.spring(booking.height, {
                toValue: locationsHeight,
                duration: 250,
                useNativeDriver: false
            }).start(() => {
                setPanelState(panelState => update(panelState, {
                    view: {
                        $set: 'reservations'
                    }
                }));
            });

            // remove subscriber for keyboard events
            // details layer is responsible for keyboard events moving forward
            // add subscribers back to view when booking has ended to enable next booking process
            await Utils.sleep(0.5);
            Keyboard.dismiss();
            utils.keyboard.unsubscribe(viewID);

            // apply temp edits and open details layer for ride confirmation
            abstract.object.close();
            utils.layer.reservation.details(abstract.object, {
                onBookRide: onBookRide,
                onUpdateRide: abstract.object.quick_scan_route ? null : onUpdateRide,
                onClose: utils.keyboard.subscribe.bind(this, viewID, getKeyboardCallbacks)
            });

            // end loading
            setLoading(false);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue proceeding with your reservation. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDriverPress = async () => {
        try {
            // find matching customer target
            let match = targets.find(entry => {
                return entry.id === driverProps.target.id && entry.type === driverProps.target.type;
            });
            if(!match) {
                return null;
            }

            let { id, type } = match;
            switch(type) {
                case 'orders':
                let order = await Order.get(utils, id);
                utils.layer.openFloating({
                    id: `trip-navigator-${abstract.getTag()}`,
                    abstract: Abstract.create({
                        type: 'orders',
                        object: order
                    }),
                    Component: TripNavigator
                });
                break;

                case 'reservations':
                let reservation = await Reservation.get(utils, id);
                utils.layer.openFloating({
                    id: `trip-navigator-${abstract.getTag()}`,
                    abstract: Abstract.create({
                        type: 'reservations',
                        object: reservation
                    }),
                    Component: TripNavigator
                });
                break;
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your content. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onFieldChange = async (key, text) => {

        // set search text
        setSearchText(text ? text.toLowerCase() : null);

        // update active field label
        setFields(fields => update(fields, {
            [key]: {
                text: {
                    $set: text
                }
            }
        }));

        // reset results, update edits, and prevent moving forward if no text was provided
        if(!text || text.length === 0) {
            setResults([]);
            abstract.object.set({ [key]: null });
            return;
        }

        // retrieve auto complete results
        try {
            setSearching(true);
            let results = await Utils.addressLookup(utils, text, nonce);

            setSearching(false);
            setResults(results);

        } catch(e) {
            setSearching(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue completing your search. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onFocusOriginField = () => {
        if(abstract.object.quick_scan_route) {
            Keyboard.dismiss();
            utils.alert.show({
                title: 'QuickScan',
                message: 'We have your pickup set to your current location. Please book a standard Reservation if you need to be picked up from somewhere else',
                onPress: () => {
                    Keyboard.dismiss(); // fallback
                    setPanelState(panelState => update(panelState, {
                        field: {
                            $set: 'destination'
                        }
                    }))
                }
            })
            return;
        }
        setPanelState(panelState => update(panelState, {
            view: {
                $set: 'locations'
            },
            field: {
                $set: 'origin'
            }
        }))
    }

    const onLoadComplete = async () => {
        setPanelState(panelState => update(panelState, {
            view: {
                $set: 'reservations'
            }
        }));
    }

    const onQRCodeScan = async result => {
        try {
            if(!result.data || routeID || shouldScan.current === false) {
                return;
            }

            shouldScan.current = false;
            let { channel, client_id, route_id } = JSON.parse(result.data);
            if(channel !== 'quick_scan') {
                utils.alert.show({
                    title: 'Oops!',
                    message: 'It looks like the QR code is not meant for Quick Scan rides',
                    onPress: onResetScanning
                });
                return;
            }

            if(client_id !== API.get_client_id()) {
                utils.alert.show({
                    title: 'Oops!',
                    message: `It looks like the QR code is not meant for ${Appearance.appName()}`,
                    onPress: onResetScanning
                });
                return;
            }

            if(!route_id) {
                utils.alert.show({
                    title: 'Oops!',
                    message: 'We were unable to gathering the required information from this QR code. Please try a different code',
                    onPress: onResetScanning
                });
                return;
            }

            setRouteID(route_id);
            utils.events.emit('on_new_quick_scan_route_id', { route_id: route_id });

            // notify driver
            let user = utils.user.get();
            await utils.sockets.emit('quick_scan', 'code_scan', {
                type: 'quick_scan',
                route_id: route_id,
                user: {
                    user_id: user.user_id,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    avatar: user.avatar ? user.avatar.uri : null
                }
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: 'There was an issue reading the QR code. Make sure that the code is valid and there is sufficient lighting in the scene ',
                onPress: onResetScanning
            })
        }
    }

    const onQuickScanPress = () => {

        // prevent moving forward if platform is web
        if(Platform.OS === 'web') {
            Utils.promptMobileDownload(utils);
            return;
        }

        // clear previous route id and set scanning flag to true
        setRouteID(null);
        shouldScan.current = true;

        // update panel state for quickscan
        setPanelState(panelState => update(panelState, {
            view: {
                $set: 'quick_scan'
            }
        }));

        // present options to scan qr code and join quickscan route
        utils.layer.open({
            id: `new-quick-scan-customer-route`,
            showDimView: false,
            Component: NewQuickScanCustomerRoute.bind(this, {
                onScan: val => setIsQuickScanInProgress(val),
                onVerify: onVerifyQuickScanRoute,
                onClose: () => {
                    Animated.timing(camera.opacity, {
                        toValue: 0,
                        duration: 500,
                        useNativeDriver: true
                    }).start(() => {

                        // reset scanning props
                        setRouteID(null);
                        shouldScan.current = true;

                        // set view back to standard ride booking
                        setPanelState(panelState => update(panelState, {
                            view: {
                                $set: 'reservations'
                            }
                        }))
                    });
                }
            })
        })
    }

    const onRemoveAddressBookItem = (item, index) => {

        Keyboard.dismiss();
        utils.alert.show({
            title: 'Remove from Address Book',
            message: `Are you sure that you want to remove "${item.name}" from your address book?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'default'
            }],
            onPress: async key => {
                if(key === 'confirm') {
                    try {
                        setAddressBook(book => update(book, {
                            [index]: {
                                loading: {
                                    $set: true
                                }
                            }
                        }))
                        await Request.post(utils, '/user/', {
                            type: 'remove_from_address_book',
                            id: item.id
                        })
                        setAddressBook(addressBook => update(addressBook, {
                            $apply: entries => entries.filter(entry => entry.id !== item.id)
                        }))

                    } catch(e) {
                        utils.alert.show({
                            title: 'Oops!',
                            message: `There was an issue updating your Address Book. ${e.message || 'An unknown error occurred'}`
                        });
                        setAddressBook(addressBook => update(addressBook, {
                            [index]: {
                                loading: {
                                    $set: false
                                }
                            }
                        }))
                    }
                }
            }
        })
    }

    const onResetScanning = () => {
        setRouteID(null);
        shouldScan.current = true;
    }

    const onReversePress = () => {
        let { origin, destination } = abstract.object.edits;
        if(!origin || !destination) {
            return;
        }
        abstract.object.set({
            origin: destination,
            destination: origin
        });
        setFields(fields => update(fields, {
            ...origin && {
                destination: {
                    text: {
                        $set: origin.name || origin.address
                    }
                }
            },
            ...destination && {
                origin: {
                    text: {
                        $set: destination.name || destination.address
                    }
                }
            }
        }));
    }

    const onRunSetup = async () => {

        // apply system blue color to lottie location buton
        setShareLocationSource(Utils.replaceLottieColor(ShareLocationButton, '0.290196078431,0.290196078431,0.290196078431', Appearance.colors.blue));

        // setup quickscan callback
        utils.structure.navigation.set({ onRightPress: onQuickScanPress });

        // connect to community socket and setup nearby vehicles for map
        onSetupCommunitySocket();

        // fetch targets for trip navigator and setup listeners
        onSetupTripNavigatior();

        // attempt to fetch payment methods for customer
        try {
            let { methods } = await utils.user.get().getPaymentMethods(utils);
            abstract.object.set({
                payment_method: methods && methods.length > 0 ? methods.find(m => m.default) : null
            })

        } catch(e) {
            console.error(e.message);
        }

        // attempt to fetch address book entries for customer
        try {
            let entries = await utils.user.get().getAddressBook(utils);
            setAddressBook(entries.map(entry => {
                return {
                    ...entry,
                    loading: false
                }
            }));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your saved locations. ${e.message || 'An unknown error occurred'}`
            })
        }

        // setup keyboard listeners
        utils.keyboard.subscribe(viewID, getKeyboardCallbacks);

        // fetch modular content and display if applicable
        try {
            let { content } = await Utils.fetchModularContent(utils, 'bookRide');
            setModularContent(content);
        } catch(e) {
            console.error(e.message);
        }

        // setup location subscribers
        utils.location.subscribe(viewID, {
            onUpdate: location => {
                setLocation(location);
            },
            onPermissionChange: async ({ foreground }) => {
                if(!foreground) {
                    return;
                }
                utils.location.start()
            }
        });
    }

    const onSelectAddressBookItem = entry => {
        abstract.object.set({
            [panelState.field]: {
                name: entry.name,
                address: entry.address,
                location: {
                    latitude: entry.location.lat,
                    longitude: entry.location.long
                }
            }
        });
        setFields(fields => update(fields, {
            [panelState.field]: {
                text: {
                    $set: entry.name || entry.address
                }
            }
        }))
    }

    const onSelectQuickScanRouteOption = option => {
        abstract.object.set({
            destination: {
                name: option.name,
                address: option.address,
                location: option.location
            }
        });
        setFields(fields => update(fields, {
            destination: {
                text: {
                    $set: option.name
                }
            }
        }));
    }

    const onSetupBookingTarget = async () => {
        try {

            let target = abstract;
            if(!target || target.object.quick_scan_route) {
                target = Abstract.create({
                    type: 'reservations',
                    object: Reservation.new()
                })
                setAbstract(target);
            }

            // Set origin as current location if no place has been selected
            let location = utils.location.last();
            if(location && !target.object.edits.origin) {
                target.object.set({
                    origin: {
                        name: 'Current Location',
                        address: null,
                        location: {
                            latitude: location.latitude,
                            longitude: location.longitude
                        }
                    }
                })
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the booking process. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSetupCommunitySocket = () => {
        let attempts = 0;
        const attemptConnection = async () => {
            try {
                attempts++;
                await utils.sockets.on('community', 'on_nearby_update', onCommunityUpdate);
                console.log('connected to community socket');

            } catch(e) {
                console.log('waiting on community socket...');
                if(attempts <= 5) {
                    setTimeout(attemptConnection, 1000);
                }
            }
        }
        attemptConnection();
    }

    const onSetupPermissions = async () => {

        try {
            // skip permission request if platform is web
            if(Platform.OS === 'web') {
                utils.location.start();
                return;
            }

            // only show onboarding permissions once
            let date = await AsyncStorage.getItem('has_requested_permission');
            if(!date) {
                await AsyncStorage.setItem('has_requested_permission', moment().format('YYYY-MM-DD HH:mm:ss'));
                utils.permissions.request({
                    onPermissionChange: permission => {
                        if(permission.key === 'location') {
                            utils.location.start();
                        }
                    }
                });
                return;
            }

            // update push token on each login
            if(date) {
                await requestNotificationPermission(utils);
            }

            // start location updates if permission has already been granted
            let { foreground } = await utils.location.check();
            if(foreground) {
                utils.location.start();
            }

        } catch(e) {
            console.error(e.message);
        }
    }

    const onSetupAbstract = async () => {
        try {
            // prevent moving forward if ride is part of a quick scan route
            // no further configuration is needed
            if(abstract.object.booking_channel === 'quick_scan') {
                return;
            }

            // fetch default booking targets
            let { pickup_date, service, vehicle_category } = await Request.get(utils, '/reservation/', {
                type: 'default_booking_targets',
                date: moment().format('YYYY-MM-DD HH:mm:ss')
            });

            // setup target for booking
            abstract.object.set({
                customer: utils.user.get(),
                company: utils.user.get().company,
                passengers: 1,
                luggage: 0,
                pickup_date: moment(pickup_date),
                service: service && Service.create(service),
                vehicle: vehicle_category && Vehicle.Category.create(vehicle_category)
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing the booking process. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetupTripNavigatior = async () => {
        try {
             let { targets } = await Request.get(utils, '/user/', {
                 type: 'targets',
                 date: moment().format('YYYY-MM-DD HH:mm:ss')
             });

              // setup target listeners
             targets.forEach(target => {
                 utils.sockets.on(target.type, `on_active_update_${target.id}`, onTargetUpdate.bind(this, target));
             });

             // set target objects
             setTargets(targets.map(target => ({
                 ...target,
                 origin: {
                     name: target.origin.name,
                     address: target.origin.address,
                     latitude: target.origin.lat,
                     longitude: target.origin.long
                 },
                 destination: {
                     name: target.destination.name,
                     address: target.destination.address,
                     latitude: target.destination.lat,
                     longitude: target.destination.long
                 }
             })));

        } catch(e) {
            console.error(e.message);
        }
    }

    const getScrollableContent = () => {

        // show quick scan route category options if applicable
        let { categories } = abstract.object.quick_scan_route || {};
        if(categories && categories.length > 0) {
            return (
                <ScrollView
                scrollEventThrottle={1}
                onScroll={() => Keyboard.dismiss()}
                showsVerticalScrollIndicator={false}
                keyboardShouldPersistTaps={'always'}
                style={{
                    maxHeight: '100%',
                    borderRadius: 10,
                    overflow: 'hidden'
                }}>
                    {categories.filter(category => {
                        if(searchText) {
                            return category.name.toLowerCase().includes(searchText) || category.information.toLowerCase().includes(searchText) ||
                            category.options.find(option => {
                                return option.name.toLowerCase().includes(searchText) || option.address.toLowerCase().includes(searchText);
                            })
                        }
                        return true;

                    }).map((category, index, categories) => {
                        return (
                            <View key={index}>
                                <View style={{
                                    paddingHorizontal: 12,
                                    paddingVertical: 8,
                                    backgroundColor: Appearance.colors.divider()
                                }}>
                                    <Text style={Appearance.textStyles.subTitle()}>{category.name}</Text>
                                </View>
                                {category.options.filter(option => {
                                    return searchText ? option.name.toLowerCase().includes(searchText) || option.address.toLowerCase().includes(searchText) : true;
                                }).map((option, index, options) => {
                                    return (
                                        Views.entry({
                                            key: index,
                                            title: option.name,
                                            subTitle: option.address,
                                            hideIcon: true,
                                            bottomBorder: index !== options.length - 1,
                                            onPress: onSelectQuickScanRouteOption.bind(this, option)
                                        })
                                    )
                                })}
                            </View>
                        )
                    })}
                </ScrollView>
            );
        }

        // show search results if at least one result is present
        if(results && results.length > 0) {
            return (
                <ScrollView
                scrollEventThrottle={1}
                onScroll={() => Keyboard.dismiss()}
                showsVerticalScrollIndicator={false}
                keyboardShouldPersistTaps={'always'}
                style={{
                    maxHeight: '100%',
                    borderRadius: 10,
                    overflow: 'hidden'
                }}>
                    {results.map((result, index) => {
                        let distance = location ? Utils.linearDistance(result.location, location) : null;
                        return (
                            Views.entry({
                                key: index,
                                title: result.name,
                                subTitle: result.address,
                                hideIcon: true,
                                badge: {
                                    text: distance && distance < 5 ? 'Nearby' : null,
                                    color: Appearance.colors.primary()
                                },
                                bottomBorder: false,
                                onPress: onAutoCompletePress.bind(this, result)
                            })
                        )
                    })}
                </ScrollView>
            )
        }

        // fallback to address book entries
        return (
            <ScrollView
            scrollEventThrottle={1}
            onScroll={() => Keyboard.dismiss()}
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps={'always'}
            stickyHeaderIndices={[0]}
            style={{
                maxHeight: '100%',
                borderRadius: 10,
                overflow: 'hidden'
            }}>
                <View style={{
                    height: 37,
                    paddingLeft: 13,
                    paddingRight: 8,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    backgroundColor: Appearance.colors.divider(),
                    overflow: 'hidden'
                }}>
                    <View style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <Text style={{
                            ...Appearance.textStyles.title(),
                            color: Appearance.colors.subText()
                        }}>{'Address Book'}</Text>
                        <AltBadge
                        onPress={onAddAddressBookItem}
                        content={{
                            text: 'Add New Place',
                            color: Appearance.colors.grey()
                        }} style={{
                            marginRight: 0
                        }}/>
                    </View>
                </View>
                {addressBook && addressBook.length > 0
                    ?
                    getAddressBookEntries()
                    :
                    Views.entry({
                        title: 'No Places Found',
                        subTitle: 'Tap to add your first place',
                        hideIcon: true,
                        bottomBorder: false,
                        onPress: onAddAddressBookItem
                    })
                }
            </ScrollView>
        )
    }

    const onTargetUpdate = (target, props) => {
        try {
            let { driver, order, reservation } = JSON.parse(props) || {};
            setDriverProps({
                driver: driver,
                status: reservation ? reservation.status : order.status,
                location: driver.location,
                target: {
                    id: reservation ? reservation.id : order.id,
                    type: target.type
                }
            });

        } catch(e) {
            console.error(e.message);
        }
    }

    const onUpdateRide = edits => {
        abstract.object.edits = edits;
        setAbstract(abstract);
    }

    const onValidateAccountDetails = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                // require that every customer have a method of contact before allowing them to book
                if(!utils.user.get().phone_number) {
                    utils.alert.show({
                        title: 'Just a Second',
                        message: 'It looks like you have not added a phone number to your account. We require every customer have a phone number available for their driver in the event that the driver needs to contact them.',
                        textFields: [{
                            key: 'phone_number',
                            placeholder: 'Phone Number',
                            format: 'phone_number'
                        }],
                        buttons: [{
                            key: 'done',
                            title: 'Continue',
                            style: 'default'
                        }],
                        onPress: async response => {
                            try {

                                await Utils.sleep(1);
                                if(!response.phone_number) {
                                    let error = new Error('A phone number is required before you can book your ride.');
                                    reject(error);
                                    return;
                                }

                                // set phone number
                                let customer = utils.user.get();
                                let props = { phone_number: response.phone_number };
                                customer.set(props);

                                // update user details
                                await customer.update(utils);
                                utils.user.set(props);
                                resolve();

                            } catch(e) {
                                reject(e);
                            }

                        }
                    });
                    return;
                }
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    const onVerifyQuickScanRoute = async route => {
        try {
            setRouteID(null);
            shouldScan.current = true;

            let reservation = Reservation.new();
            reservation.booking_channel = 'quick_scan';
            reservation.quick_scan_route = route;

            let location = await utils.location.get();
            await reservation.set({
                customer: utils.user.get(),
                company: utils.user.get().company,
                service: route.service,
                vehicle: route.vehicle,
                pickup_date: moment(),
                passengers: 1,
                luggage: 0,
                origin: {
                    name: 'Current Location',
                    location: {
                        latitude: location.latitude,
                        longitude: location.longitude
                    }
                }
            });

            let target = Abstract.create({
                type: 'reservations',
                object: reservation
            });
            setAbstract(target);

            Animated.timing(camera.opacity, {
                toValue: 0,
                duration: 500,
                useNativeDriver: true
            }).start(() => {
                setPanelState(panelState => update(panelState, {
                    view: {
                        $set: 'locations'
                    }
                }))
            });

        } catch(e) {
            utils.alert.show({
                title: 'Location Services',
                message: `It looks like we were unable to find your current location. Please make sure that Location Services are enabled for the ${utils.client.get().name} App`,
                onPress: () => {
                    setRouteID(null);
                    shouldScan.current = true;
                }
            });
        }
    }

    const getAddressBookEntries = () => {
        return addressBook.map(entry => {
            let distance = location ? Utils.linearDistance(location, entry.location) : null;
            entry.nearby = distance && distance < 5;
            return entry;
        }).sort((a, b) => {
            if(a.nearby === b.nearby) {
                return a.name.localeCompare(b.name);
            }
            return a.nearby ? -1 : 1;
        }).map((entry, index) => {
            return (
                Views.entry({
                    key: index,
                    title: entry.name,
                    subTitle: entry.address,
                    hideIcon: true,
                    loading: entry.loading,
                    badge: {
                        text: entry.nearby ? 'Nearby' : null,
                        color: Appearance.colors.primary()
                    },
                    propStyles: {
                        subTitle: {
                            numberOfLines: entry.expanded ? 10 : 1
                        }
                    },
                    ...Platform.OS === 'android' && {
                        textStyles: {
                            title: {
                                // min height needed for android
                                // text is cut-off vertically and we are unsure why
                                minHeight: 25
                            }
                        }
                    },
                    bottomBorder: index !== addressBook.length - 1,
                    onPress: onSelectAddressBookItem.bind(this, entry),
                    rightContent: (
                        <TouchableOpacity
                        activeOpacity={0.6}
                        onPress={onRemoveAddressBookItem.bind(this, entry, index)}
                        style={{
                            width: 30,
                            height: 30,
                            padding: 8
                        }}>
                            <Image
                            source={require('eCarra/images/x-icon-grey-small.png')}
                            style={{
                                width: '100%',
                                height: '100%',
                                tintColor: Appearance.themeStyle() === 'dark' ? 'white' : Appearance.colors.grey()
                            }} />
                        </TouchableOpacity>
                    )
                })
            )
        })
    }

    const getApproxLocation = () => {
        let location = utils.user.get().approximate_location;
        if(!location) {
            return null;
        }
        return {
            latitude: location.latitude,
            longitude: location.longitude
        };
    }

    const getBookingFields = () => (
        <>
        <View style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            marginBottom: 30
        }}>
            <Image
            source={panelState.view !== 'locations' || panelState.field === 'origin' ? require('eCarra/images/location-circle-blue-small.png') : require('eCarra/images/location-circle-grey-small.png')}
            style={{
                width: 25,
                height: 25,
                resizeMode: 'contain',
                marginRight: 10
            }}/>
            <View style={{
                flex: 1,
                flowGrow: 1,
                display: 'flex',
                flexDirection: 'column'
            }}>
                <BookingField
                type={'origin'}
                ref={fields.origin.ref}
                value={fields.origin.text}
                onAnimate={onAnimateFields}
                onChange={text => onFieldChange('origin', text)}
                onFocus={onFocusOriginField} />
            </View>
        </View>
        <View style={{
            display: 'flex',
            flexDirection: 'row',
            width: Platform.OS === 'web' ? 'calc(100% - 18px)' : '100%',
            alignItems: 'center',
            position: 'absolute',
            top: 44,
            left: 18
        }}>
            <View style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                width: 22,
                height: 22
            }}>
                {[...new Array(3)].map((_, index) => {
                    return <View key={index} style={{
                        width: 4,
                        height: 4,
                        borderRadius: 2,
                        marginBottom: 5,
                        backgroundColor: Appearance.colors.softBorder()
                    }}/>
                })}
            </View>
            <View style={{
                position: 'relative',
                flexGrow: 1,
                height: 2,
                borderRadius: 1,
                marginRight: 15,
                backgroundColor: Appearance.colors.softBorder(),
                overflow: 'hidden'
            }}>
                <ProgressBar
                animate={searching}
                color={panelState.field === 'origin' ? Appearance.colors.blue : Appearance.colors.green}/>
            </View>

            {Utils.apply(abstract.type, {
                reservations: () => (
                    <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={onReversePress}
                    style={{
                        padding: 2,
                        marginRight: 8,
                        height: 25,
                        width: abstract.object.edits && abstract.object.edits.origin && abstract.object.edits.destination ? 25 : 0,
                        overflow: 'hidden'
                    }}>
                        <Image
                        source={require('eCarra/images/reverse-icon-grey-small.png')}
                        style={{
                            width: '100%',
                            height: '100%',
                            resizeMode: 'contain'
                        }}/>
                    </TouchableOpacity>
                )
            })}
        </View>

        <View style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center'
        }}>
            <Image
            source={panelState.view !== 'locations' || panelState.field === 'destination' ? require('eCarra/images/map-marker-icon-green-small.png') : require('eCarra/images/map-marker-icon-grey-small.png')}
            style={{
                width: 25,
                height: 30,
                resizeMode: 'contain',
                marginRight: 10,
                marginBottom: 2
            }}/>
            <View style={{
                flex: 1,
                flowGrow: 1,
                display: 'flex',
                flexDirection: 'column'
            }}>
                <BookingField
                type={'destination'}
                ref={fields.destination.ref}
                value={fields.destination.text}
                onAnimate={onAnimateFields}
                onChange={text => onFieldChange('destination', text)}
                onFocus={() => {
                    setPanelState(panelState => update(panelState, {
                        view: {
                            $set: 'locations'
                        },
                        field: {
                            $set: 'destination'
                        }
                    }))
                }} />
            </View>
        </View>
        </>
    )

    const getButtons = () => {

        let leftButton = null;
        let rightButton = null;

        switch(panelState.view) {
            case 'locations':
                leftButton = (
                    <Button
                    label={'Back'}
                    color={Appearance.colors.grey()}
                    onPress={() => {
                        let confirm = () => {
                            Keyboard.dismiss();
                            utils.structure.navigation.show(() => {

                                Animated.timing(map.opacity, {
                                    toValue: 1,
                                    duration: 500,
                                    useNativeDriver: true
                                }).start();
                                Animated.timing(booking.top, {
                                    toValue: 0,
                                    duration: 500,
                                    friction: 10,
                                    useNativeDriver: false
                                }).start();
                                Animated.timing(booking.padding.top, {
                                    toValue: 15 + (Screen.safeArea.top + 50),
                                    duration: 500,
                                    useNativeDriver: false
                                }).start();
                                Animated.spring(booking.height, {
                                    toValue: locationsHeight,
                                    duration: 250,
                                    useNativeDriver: false
                                }).start();
                                setTimeout(() => {
                                    setPanelState(update(panelState, {
                                        view: {
                                            $set: 'reservations'
                                        }
                                    }));
                                }, 250)
                            })
                        }

                        if(abstract.object.quick_scan_route) {
                            Keyboard.dismiss();
                            utils.alert.show({
                                title: 'Leave QuickScan',
                                message: 'Are you sure that you want to leave QuickScan ride booking?',
                                buttons: [{
                                    key: 'confirm',
                                    title: 'Yes',
                                    style: 'destructive'
                                },{
                                    key: 'cancel',
                                    title: 'Continue Booking',
                                    style: 'default'
                                }],
                                onPress: (key) => {
                                    if(key === 'confirm') {
                                        confirm();
                                        return;
                                    }
                                }
                            });
                            return;
                        }
                        confirm();

                    }}/>
                )
                rightButton = (
                    <Button
                    label={'Done'}
                    color={'primary'}
                    loading={loading === 'done'}
                    onPress={onDonePress}/>
                )
                break;

            default:
                return null;
        }

        return (
            <Animated.View style={{
                width: '100%',
                height: 45
            }}>
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center'
                }}>
                    <View style={{
                        flexGrow: 1
                    }}>
                        {leftButton}
                    </View>
                    <View style={{
                        display: abstract.object.booking_channel === 'quick_scan' ? 'flex' : 'none',
                        width: 8,
                        height: 30
                    }}/>
                    <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={onCurrentLocationPress}
                    style={{
                        display: abstract.object.booking_channel === 'quick_scan' ? 'none' : 'flex',
                        width: 45,
                        height: 45
                    }}>
                        {shareLocationSource && (
                            <LottieView
                            ref={shareLocation}
                            autoPlay={false}
                            loop={false}
                            source={shareLocationSource}
                            duration={2500}
                            style={{
                                width: '100%',
                                height: '100%'
                            }}/>
                        )}
                    </TouchableOpacity>
                    <View style={{
                        flexGrow: 1
                    }}>
                        {rightButton}
                    </View>
                </View>
            </Animated.View>
        )
    }

    const getContent = () => {
        return (
            <View style={{
                display: panelState.view === 'locations' ? 'flex' : 'none',
                flexGrow: 1,
                paddingHorizontal: 15,
                paddingBottom: locationsHeight + 105
                // 105 is some magic number that just works regardless of safe area top/bottom height
            }}>
                <View style={{
                    paddingBottom: 15,
                    ...Utils.isMobile() === true && {
                        height: '100%' // height stretch is only needed for mobile layout
                    }
                }}>
                    <View
                    renderToHardwareTextureAndroid={true}
                    style={Appearance.styles.panel()}>
                        {getScrollableContent()}
                    </View>
                </View>
                {getButtons()}
            </View>
        )
    }

    const getDriverProps = () => {
        if(!driverProps) {
            return null;
        }

        // find matching customer target
        let target = targets.find(entry => {
            return entry.id === driverProps.target.id && entry.type === driverProps.target.type;
        });
        if(!target) {
            return null;
        }

        // get target location based on target status
        let location = Utils.apply(driverProps.target.type, {
            orders: () => {
                switch(driverProps.status) {
                    case StatusCodes.orders.toPickup:
                    return [{
                        id: 'origin',
                        location: target.origin
                    }]

                    case StatusCodes.orders.toDestination:
                    return [{
                        id: 'destination',
                        location: target.destination
                    }]

                    default:
                    return [];
                }
            },
            reservations: () => {
                switch(driverProps.status) {
                    case StatusCodes.reservations.toPickup:
                    return [{
                        id: 'origin',
                        location: target.origin
                    }]

                    case StatusCodes.reservations.toDestination:
                    return [{
                        id: 'destination',
                        location: target.destination
                    }]

                    default:
                    return [];
                }
            }
        });

        return {
            annotations: [location],
            driverLocation: driverProps.location,
            driverLocationIcon: require('eCarra/images/tesla-model-s.png'),
            followUserLocation: false,
            cameraPadding: {
                top: Screen.safeArea.top + 215,
                left: 50,
                right: 50,
                bottom: Screen.safeArea.bottom + 70
            }
        }
    }

    const getDriverTarget = () => {
        if(!driverProps) {
            return null;
        }

        // find matching customer target
        let target = targets.find(entry => {
            return entry.id === driverProps.target.id && entry.type === driverProps.target.type;
        });
        if(!target) {
            return null;
        }

        return (
            <View style={{
                ...Appearance.styles.panel(),
                position: 'absolute',
                width: Screen.layer.maxWidth - 30,
                bottom: Screen.safeArea.bottom + 15,
                left: 15,
                right: 15
            }}>
                {Views.entry({
                    bottomBorder: false,
                    onPress: onDriverPress,
                    ...Utils.apply(driverProps.target.type, {
                        orders: () => {
                            switch(driverProps.status) {
                                case StatusCodes.orders.toPickup:
                                return {
                                    title: `${driver ? driver.getShortName() : 'Driver'} Arriving at ${moment().add(driverProps.location.time_to_arrival || 0, 'seconds').format('h:mma')}`,
                                    subTitle: `Pickup at ${target.origin.name || target.origin.address}`,
                                    icon: {
                                        path: driver ? driver.avatar : utils.client.get().logos.mobile
                                    }
                                }

                                case StatusCodes.orders.toDestination:
                                return {
                                    title: `Order Arriving at ${moment().add(driverProps.location.time_to_arrival || 0, 'seconds').format('h:mma')}`,
                                    subTitle: `Drop-Off at ${target.destination.name || target.destination.address}`,
                                    icon: {
                                        path: driver ? driver.avatar : utils.client.get().logos.mobile
                                    }
                                }

                                default:
                                return null
                            }
                        },
                        reservations: () => {
                            switch(driverProps.status) {
                                case StatusCodes.reservations.toPickup:
                                return {
                                    title: `${driver ? driver.getShortName() : 'Driver'} Arriving at ${moment().add(driverProps.location.time_to_arrival || 0, 'seconds').format('h:mma')}`,
                                    subTitle: `Pickup at ${target.origin.name || target.origin.address}`,
                                    icon: {
                                        path: driver ? driver.avatar : utils.client.get().logos.mobile
                                    }
                                }

                                case StatusCodes.reservations.toDestination:
                                return {
                                    title: `Arriving at ${moment().add(driverProps.location.time_to_arrival || 0, 'seconds').format('h:mma')}`,
                                    subTitle: `Drop-Off at ${target.destination.name || target.destination.address}`,
                                    icon: {
                                        path: driver ? driver.avatar : utils.client.get().logos.mobile
                                    }
                                }

                                default:
                                return null;
                            }
                        }
                    })
                })}
            </View>
        )
    }

    const getKeyboardCallbacks = {
        onVisibility: visible => setKeyboardOpen(visible),
        onShow: (e) => {
            setKeyboardHeight(e.endCoordinates.height);
            Animated.spring(booking.height, {
                toValue: Screen.height() - Screen.safeArea.top - e.endCoordinates.height,
                duration: 500,
                friction: 10,
                useNativeDriver: false
            }).start()
        },
        onChange: (e) => {
            setKeyboardHeight(e.endCoordinates.height);
            Animated.spring(booking.height, {
                toValue: Screen.height() - Screen.safeArea.top - e.endCoordinates.height,
                duration: 500,
                friction: 10,
                useNativeDriver: false
            }).start()
        },
        onHide: () => {
            Animated.spring(booking.height, {
                toValue: Screen.height() - Screen.safeArea.top - Screen.safeArea.bottom,
                duration: 500,
                friction: 10,
                useNativeDriver: false
            }).start()
        }
    }

    const fetchDriverDetails = async () => {
        try {
            if(shouldFetchDriver.current === false) {
                return;
            }
            shouldFetchDriver.current = false;
            let { driver } = await Request.get(utils, '/driver/', {
                type: 'details',
                user_id: driverProps.driver.user_id
            });

            abstract.object.driver = driver ? User.create(driver) : null;
            setDriver(abstract.object.driver);

        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        if(panelState.view === null) {
            return;
        }
        switch(panelState.view) {
            case 'reservations':
            Animated.timing(map.opacity, {
                toValue: 1,
                duration: 500,
                useNativeDriver: true
            }).start();
            Animated.timing(camera.opacity, {
                toValue: 0,
                duration: 500,
                useNativeDriver: true
            }).start();

            utils.structure.navigation.show(() => {
                Animated.timing(booking.opacity, {
                    toValue: 1,
                    duration: 500,
                    useNativeDriver: false
                }).start()
                Animated.spring(booking.top, {
                    toValue: 0,
                    duration: 500,
                    friction: 10,
                    useNativeDriver: false
                }).start();
                Animated.spring(booking.padding.top, {
                    toValue: 15 + (Screen.safeArea.top + 50),
                    duration: 500,
                    friction: 10,
                    useNativeDriver: false
                }).start();
            })
            onSetupBookingTarget();
            break;

            case 'quick_scan':
            utils.structure.navigation.hide(() => {
                Animated.timing(camera.opacity, {
                    toValue: 1,
                    duration: 500,
                    useNativeDriver: true
                }).start();
                Animated.timing(map.opacity, {
                    toValue: 0,
                    duration: 500,
                    useNativeDriver: true
                }).start();
                Animated.timing(booking.opacity, {
                    toValue: 0,
                    duration: 500,
                    useNativeDriver: false
                }).start()
                Animated.spring(booking.top, {
                    toValue: -100,
                    duration: 500,
                    friction: 10,
                    useNativeDriver: false
                }).start();
            });
            break;

            case 'locations':
            Animated.timing(camera.opacity, {
                toValue: 0,
                duration: 500,
                useNativeDriver: true
            }).start()
            Animated.timing(booking.opacity, {
                toValue: 1,
                duration: 500,
                useNativeDriver: false
            }).start()
            Animated.spring(booking.top, {
                toValue: Screen.safeArea.top,
                duration: 500,
                friction: 10,
                useNativeDriver: false
            }).start()
            Animated.spring(booking.height, {
                toValue: Screen.height() - Screen.safeArea.bottom - Screen.safeArea.top,
                duration: 500,
                friction: 10,
                useNativeDriver: false
            }).start()
            Animated.spring(booking.padding.top, {
                toValue: 0,
                duration: 500,
                friction: 10,
                useNativeDriver: false
            }).start()
            break;
        }

    }, [panelState.view]);

    useEffect(() => {
        if(driverProps) {
            fetchDriverDetails();
        }
    }, [driverProps])

    useEffect(() => {
        if(keyboardOpen === true) {
            if(abstract.object.quick_scan_route && fields[panelState.field].key === 'origin') {
                return; // prevent auto focus for origin field during quick scan ride booking
            }
            fields[panelState.field].ref.current.fieldFocus();
        }
    }, [panelState.field])

    useEffect(() => {
        let target = abstract.object.edits;
        setFields(fields => update(fields, {
            origin: {
                text: {
                    $set: target && target.origin ? (target.origin.name || target.origin.address) : null
                }
            },
            destination: {
                text: {
                    $set: target && target.destination ? (target.destination.name || target.destination.address) : null
                }
            }
        }));
    }, [abstract.object.edits]);

    useEffect(() => {
        onSetupAbstract();
    }, [abstract]);

    useEffect(() => {
        onRunSetup();
        return () => {
            utils.location.unsubscribe(viewID);
            utils.keyboard.unsubscribe(viewID);
            utils.sockets.off('community', 'on_nearby_update', onCommunityUpdate);
        }
    }, []);

    return (
        <Animated.View style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            height: Screen.height(),
            backgroundColor: Appearance.colors.background()
        }}>
            <View style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%'
            }}>
                <View style={{
                    flex: 1,
                    flexGrow: 1,
                    width: '100%'
                }}>
                    {/* quickscan camera */}
                    <Animated.View
                    renderToHardwareTextureAndroid={true}
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        width: '100%',
                        height: '100%',
                        opacity: camera.opacity
                    }}>
                        {panelState.view === 'quick_scan' && (
                            <Camera
                            type={Camera.Constants.Type.back}
                            flashMode={Camera.Constants.FlashMode.off}
                            onBarCodeRead={shouldScan.current ? onQRCodeScan : null}
                            style={{
                                width: '100%',
                                height: '100%'
                            }}
                            androidCameraPermissionOptions={{
                                title: 'Camera',
                                message: 'We need permission to use your camera',
                                buttonPositive: 'Okay',
                                buttonNegative: 'Cancel',
                            }} />
                        )}
                        <View style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            padding: 30
                        }}>
                            <LottieView
                            autoPlay={true}
                            loop={true}
                            duration={2500}
                            source={require('eCarra/files/lottie/qr-code-frame-white.json')}
                            style={{
                                width: Screen.layer.maxWidth - 60,
                                height: Screen.layer.maxWidth - 60
                            }}/>
                            {isQuickScanInProgress && (
                                <View style={{
                                    position: 'absolute'
                                }}>
                                    <LottieView
                                    autoPlay={true}
                                    loop={true}
                                    source={require('eCarra/files/lottie/dots-white.json')}
                                    duration={2500}
                                    style={{
                                        width: 50
                                    }}/>
                                </View>
                            )}
                        </View>
                    </Animated.View>

                    {/* reservations map */}
                    <Animated.View style={{
                        width: '100%',
                        height: '100%',
                        opacity: map.opacity,
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }}>
                        <Map
                        utils={utils}
                        userLocation={location}
                        followUserLocation={followUserLocation}
                        followZoomLevel={followUserLocation ? 14 : null}
                        {...getDriverProps()}
                        approxUserLocation={getApproxLocation()}
                        isScrollEnabled={false}
                        isZoomEnabled={false}
                        isRotateEnabled={false}
                        nearbyVehicles={nearbyVehicles}
                        animationDuration={100}
                        animationMode={'moveTo'}
                        onCameraReady={onCameraReady}
                        style={{
                            borderWidth: 0
                        }} />

                        {getDriverTarget()}
                    </Animated.View>
                </View>
            </View>

            {/* Content Container */}
            <Animated.View style={{
                position: 'absolute',
                width: '100%',
                paddingTop: booking.padding.top,
                width: '100%',
                maxWidth: Screen.component.maxWidth
            }}>
                {/* Booking Container */}
                <Animated.View style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    top: booking.top,
                    opacity: booking.opacity,
                    height: booking.height,
                    overflow: 'hidden'
                }}>
                    <View style={{
                        padding: 15
                    }}>
                        <View
                        renderToHardwareTextureAndroid={true}
                        style={{
                            ...Appearance.styles.panel(),
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            padding: 10,
                            ...Appearance.boxShadow({
                                opacity: 0.5,
                                radius: 8,
                                offset: {
                                    width: 3,
                                    height: 3
                                }
                            }) // changed from standard panel shadow for overflow clipping
                        }}>
                            {getBookingFields()}
                        </View>
                    </View>

                    {getContent()}

                </Animated.View>
            </Animated.View>
        </Animated.View>
    )
}

export const BookingField = React.forwardRef(({ onAnimate, onBlur, onChange, onFocus, placeholder, type, value }, ref) => {

    const inputRef = useRef(null);
    const textTimeout = useRef(null);
    const touchableRef = useRef(null);

    const [focused, setFocused] = useState(false);
    const [text, setText] = useState(value);

    const onContainerPress = async () => {
        try {
            await onAnimate();
            onFieldFocus();
        } catch(e) {
            console.error(e.message);
        }
    }

    const onFieldBlur = () => {
        setFocused(false);
        if(typeof(onBlur) === 'function') {
            onBlur();
        }
    }

    const onFieldFocus = () => {
        setFocused(true);
        if(typeof(onFocus) === 'function') {
            onFocus();
        }
    }

    const onRefFocus = () => {
        if(inputRef.current) {
            inputRef.current.focus();
            return;
        }
        onContainerPress();
    }

    const onTextChange = next => {
        if(textTimeout.current) {
            clearTimeout(textTimeout.current);
        }
        setText(prev => {
            let target = prev === 'Current Location' ? null : next;
            textTimeout.current = setTimeout(onChange.bind(this, target), 250);
            return target;
        });
    }

    const getFieldComponents = () => {
        if(focused !== true) {
            return (
                <TouchableWithoutFeedback
                ref={touchableRef}
                onPress={onContainerPress}
                style={{
                    width: '100%',
                    paddingRight: 35
                }}>
                    <View style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    }}>
                        {getLabel()}
                        <View style={{
                            width: '100%',
                            height: 20,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Text style={{
                                color: text ? Appearance.colors.text() : Appearance.colors.subText(),
                                fontSize: 16,
                                ...Appearance.fontWeight.get(700),
                                width: '100%'
                            }}>{text || 'Search by name or address...'}</Text>
                        </View>
                    </View>
                </TouchableWithoutFeedback>
            )
        }
        return (
            <>
            {getLabel()}
            <TextInput
            ref={inputRef}
            value={text}
            placeholder={'Search by name or address...'}
            placeholderTextColor={Appearance.colors.subText()}
            onChangeText={onTextChange}
            onFocus={onFieldFocus}
            onBlur={onFieldBlur}
            style={{
                width: '100%',
                height: 20,
                padding: 0,
                borderWidth: 0,
                paddingRight: 35,
                color: Appearance.colors.text(),
                fontSize: 16,
                ...Appearance.fontWeight.get(700),
                backgroundColor: Appearance.colors.transparent,
                ...Platform.OS === 'web' && {
                    outlineStyle: 'none',
                    outlineColor: Appearance.colors.transparent
                }
            }}/>
            </>
        )
    }

    const getLabel = () => {
        return (
            <Text style={{
                color: Appearance.colors.subText(),
                fontSize: 12,
                ...Appearance.fontWeight.get(600)
            }}>{type === 'origin' ? 'Pickup' : 'Drop Off'}</Text>
        )
    }

    useEffect(() => {
        setText(value);
    }, [value]);

    useEffect(() => {
        if(focused && inputRef.current) {
            inputRef.current.focus();
        }
    }, [focused]);

    useEffect(() => {
        if(ref.current && !ref.current.fieldFocus) {
            ref.current.fieldFocus = onRefFocus;
        }
    }, [ref]);

    return (
        <View ref={ref}>
            {getFieldComponents()}
        </View>
    )
})

export const AddAddressBookItem = ({ onChange }, { index, options, utils }) => {

    const layerID = 'add-address-book-item';
    const [place, setPlace] = useState(null);
    const [layerState, setLayerState] = useState(null);

    return (
        <Layer
            id={layerID}
            title={'Add a New Place'}
            utils={utils}
            index={index}
            options={{
                ...options,
                bottomCard: true,
                layerState: layerState
            }}
            buttons={[{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onPress: () => {

                    Keyboard.dismiss();
                    setLayerState('close');
                    if(typeof(onChange) === 'function') {
                        onChange(place);
                    }
                }
            }]}>

            <View style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 5
            }}>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 6
                }}>{'Add a New Place'}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 20,
                    textAlign: 'center'
                }}>{'Your address book can help speed up the booking process by suggesting places that you frequently visit. Search by name or address to get started.'}</Text>

                <AddressLookupField
                utils={utils}
                showAddressBook={false}
                onChange={place => setPlace(place)}/>
            </View>
        </Layer>
    )
}
