import React, { useEffect, useState } from 'react';
import { Image, Platform, Text, TouchableOpacity, View } from 'react-native';

import Appearance from 'eCarra/styles/Appearance.js';
import InnerShadow from 'eCarra/views/InnerShadow.js';
import LottieView from 'eCarra/views/Lottie/';

const Button = ({ color, label, loading, labelStyle, onPress, style, type }) => {

    const getStyles = () => {

        let styles = {
            fontSize: 10,
            width: 'auto',
            color: 'white',
          	...Appearance.fontWeight.get(600),
            backgroundColor: colors(),
        };
        switch(type) {

            case 'auto':
            styles = {
                ...styles,
                fontSize: 10,
                width: 'auto',
            }
            break;

            case 'large':
            case 'book_with_apple_pay':
            case 'order_with_apple_pay':
            styles = {
                ...styles,
                ...Appearance.fontWeight.get(500),
                fontSize: 12,
                width: '100%',
            }
            break;

            case 'small':
            styles = {
                ...styles,
                height: 25,
                fontSize: 10,
                width: 'auto',
            }
            break;
        }

        return styles;
    }

    const colors = () => {
        switch(color) {
            case 'secondary':
            return Appearance.colors.secondary();

            case 'grey':
            return Appearance.colors.grey();

            case 'danger':
            return Appearance.colors.red;

            case 'primary':
            return Appearance.colors.primary();

            default:
            return color;
        }
    }

    const getSource = () => {
        return color === 'white' ? require('eCarra/files/lottie/dots-grey.json') : require('eCarra/files/lottie/dots-white.json')
    }

    const getButton = () => {

        if(type === 'book_with_apple_pay') {
            return (
                <View style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 35,
                    paddingHorizontal: 20,
                    backgroundColor: Appearance.themeStyle() === 'dark' ? 'white' : 'black',
                    ...style
                }}>
                    {loading === true
                        ?
                        <LottieView
                        autoPlay={true}
                        loop={true}
                        source={getSource()}
                        duration={2500}
                        style={{
                            width: '100%',
                            height: 25
                        }}/>
                        :
                        <Image
                        source={Appearance.themeStyle() === 'dark' ? require('eCarra/images/apple-pay-dark.png') : require('eCarra/images/apple-pay-light.png')}
                        style={{
                            width: '100%',
                            height: '100%',
                            resizeMode: 'contain'
                        }} />
                    }
                </View>
            )
        }

        if(type === 'order_with_apple_pay') {
            return (
                <View style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 35,
                    paddingHorizontal: 20,
                    backgroundColor: Appearance.themeStyle() === 'dark' ? 'white' : 'black',
                    ...style
                }}>
                    {loading === true
                        ?
                        <LottieView
                        autoPlay={true}
                        loop={true}
                        source={getSource()}
                        duration={2500}
                        style={{
                            width: '100%',
                            height: 25
                        }}/>
                        :
                        <Image
                        source={Appearance.themeStyle() === 'dark' ? require('eCarra/images/apple-pay-order-dark.png') : require('eCarra/images/apple-pay-order-light.png')}
                        style={{
                            width: '100%',
                            height: '100%',
                            resizeMode: 'contain'
                        }} />
                    }
                </View>
            )
        }

        return (
            <View style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 35,
                paddingHorizontal: 20,
                ...getStyles(),
                ...style
            }}>
                {loading === true && (
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={getSource()}
                    duration={2500}
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: 25
                    }}/>
                )}
                <Text
                numberOfLines={1}
                style={{
                    fontSize: 15,
                    textAlign: 'center',
                    color: 'white',
                    opacity: loading === true ? 0 : 1,
                    ...Appearance.fontWeight.get(600),
                    ...labelStyle
                }}>{label}</Text>
            </View>
        )
    }

    return (
        <TouchableOpacity
        activeOpacity={loading === true ? 1 : 0.6}
        onPress={loading === true ? null : onPress}
        style={{
            height: 35,
            borderRadius: 17.5,
            overflow: 'hidden',
            ...getStyles(),
            ...style
        }}>
            <InnerShadow
            light={{ radius: 8 }}
            dark={{
                radius: 8,
                opacity: 0.15
            }}>
                {getButton()}
            </InnerShadow>
        </TouchableOpacity>
    )
}
export default Button;
