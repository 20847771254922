import React, { useRef, useState, useEffect } from 'react';
import { Animated, Image, Keyboard, Platform, ScrollView, Text, View } from 'react-native';

import * as shape from 'd3-shape';
import moment from 'moment';
import update from 'immutability-helper';
import base64 from 'react-native-base64';

import API from 'eCarra/files/api.js';
import Abstract from 'eCarra/classes/Abstract.js';
import AddressLookupField from 'eCarra/views/AddressLookupField.js';
import Appearance from 'eCarra/styles/Appearance.js';
import AsyncStorage from '@react-native-community/async-storage';
import Ble from 'eCarra/files/Ble.js';
import BoolToggle from 'eCarra/views/BoolToggle.js';
import { BubbleHeader } from 'eCarra/structure/Navigation.js';
import Button from 'eCarra/views/Button.js';
import DatePicker from 'eCarra/views/DatePicker/';
import DeviceInfo from 'react-native-device-info';
import { DotsLoader } from 'eCarra/views/Loader.js';
import DropdownMenu from 'eCarra/views/DropdownMenu.js';
import HeaderWithButton from 'eCarra/views/HeaderWithButton.js';
import ImagePicker from 'eCarra/files/ImagePicker/';
import LottieView from 'eCarra/views/Lottie/';
import Layer, { LayerToolbar, LayerToolbarHeight } from 'eCarra/structure/Layer.js';
import LinearGradient from 'eCarra/views/LinearGradient/';
import { LinearGradient as LinearGrad, Stop } from 'react-native-svg';
import { LineChart, YAxis, XAxis } from 'react-native-svg-charts';
import List from 'eCarra/views/List.js';
import { Map } from 'eCarra/views/Maps/';
import NumberStepper from 'eCarra/views/NumberStepper.js';
import Panel from 'eCarra/structure/Panel.js';
import Permissions, { PERMISSIONS, RESULTS } from 'eCarra/files/Permissions/';
import Picker from 'eCarra/views/Picker/';
import ProgressBar from 'eCarra/views/ProgressBar/';
import Request from 'eCarra/files/Request/';
import Screen from 'eCarra/files/Screen.js';
import StatusCodes from 'eCarra/files/StatusCodes.js';
import Svg, { G, Path, Polyline, Rect, Use, Defs, Mask, Polygon, Filter, Circle } from 'react-native-svg';
import TextField from 'eCarra/views/TextField.js';
import TextView from 'eCarra/views/TextView.js';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import { TripNavigator } from 'eCarra/managers/Reservations.js';
import Utils, { LineItem } from 'eCarra/files/Utils.js';
import Vehicle from 'eCarra/classes/Vehicle.js';
import Video from 'eCarra/views/Video/';
import Views from 'eCarra/views/Main.js';
import { WifiCharacteristic, SystemCharacteristic, SensorsCharacteristic } from 'eCarra/files/BluetoothManager.js';

const FanSource = require('eCarra/files/lottie/rotating-fan-white.json');

export const fetchVehicleCategories = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { categories } = await Request.get(utils, '/vehicles/', {
                type: 'list_options',
                ...props
            });
            resolve(categories.map(v => Vehicle.Category.create(v)));

        } catch(e) {
            reject(e);
        }
    })

}

export const removeDriverVehicle = async (vehicle, utils) => {
    return new Promise(async (resolve, reject) => {
        try {
            await vehicle.return(utils);
            await AsyncStorage.removeItem('driverVehicleVIN');
            utils.driver.vehicle.return();
            resolve();

        } catch(e) {
            reject(e);
        }
    })
}

export const driverVehicleMileageRequest = async (vehicle, utils) => {
    return new Promise((resolve, reject) => {
        utils.alert.show({
            title: vehicle.category.name,
            message: `It looks like your vehicle is owned by an individual or an external company. Please enter the vehicle's current mileage or return this vehicle to continue`,
            textFields: [{
                key: 'mileage',
                required: true,
                placeholder: 'Mileage',
                keyboardType: 'number-pad'
            }],
            buttons: [{
                key: 'done',
                title: 'Continue',
                style: 'default'
            },{
                key: 'return',
                title: 'Return Vehicle',
                style: 'destructive'
            }],
            onPress: async ({ mileage }) => {
                try {
                    if(!mileage) {
                        await removeDriverVehicle(vehicle, utils);
                        reject(new Error('Current mileage for this vehicle is required before you can start driving. We have returned your vehicle to the queue'));
                        return;
                    }
                    await fetchDriverVehicle(utils, {
                        vin: vehicle.vin,
                        mileage: mileage
                    });
                    resolve(mileage);

                } catch(e) {
                    reject(e);
                }
            }
        })
    })
}

export const fetchDriverVehicle = async (utils, props = {}) => {
    return new Promise(async (resolve, reject) => {
        try {

            let prevVehicle = await AsyncStorage.getItem('driverVehicleVIN');
            let vehicleInfo = prevVehicle ? JSON.parse(prevVehicle) : null;
            let { exp } = vehicleInfo || {};

            // remove data if vehicle has been attached for over 24 hours
            // user provided vin will prevent this process
            let { vin, mileage } = props;
            if(!vin && exp && moment(exp) > moment()) {
                vin = vehicleInfo.vin;
            }

            if(!vin) {
                throw new Error('No vin supplied');
            }

            // Fetch vehicle from last 6 of vin
            let location = await utils.location.get();
            if(!location) {
                throw new Error('We were unable to get your current location. Location permission is required before you can start driving');
                return;
            }

            let { mileage_request, vehicle } = await Request.get(utils, '/vehicle/', {
                type: 'find_by_short_vin',
                vin: vin,
                mileage: mileage,
                lat: location.latitude,
                long: location.longitude
            });

            // check for mileage request for customer or company vehicle
            let target = Vehicle.create(vehicle);
            if(mileage_request) {
                resolve({
                    vehicle: target,
                    mileageRequest: true
                });
                return;
            }
            resolve({ vehicle: target });

            // Update local storage with vehicle vin and expiration
            await AsyncStorage.setItem('driverVehicleVIN', JSON.stringify({
                vin: vin,
                exp: moment().add(24, 'hours').format('YYYY-MM-DD HH:mm:ss')
            }))

        } catch(e) {
            reject(e);
        }
    })
}

// Panels
export const ActiveAbstractHeader = ({ abstract, driver, style, utils }) => {

    const viewID = 'driverOnTheWay';
    const [target, setTarget] = useState(abstract.object);

    const onMessagingPress = () => {
        utils.layer.messaging(abstract);
    }

    const onNavigatorPress = () => {
        utils.layer.openFloating({
            id: `trip-navigator-${abstract.getTag()}`,
            abstract: abstract,
            Component: TripNavigator
        });
    }

    const getCenterContent = () => {
        return (
            <View style={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    color: 'white',
                    fontSize: 16,
                    textAlign: 'center'
                }}>{getTitle()}</Text>
                {driver && driver.time_to_arrival > 0 && (
                    <Text style={{
                        color: 'white',
                        ...Appearance.fontWeight.get(500),
                        fontSize: 12,
                        textAlign: 'center'
                    }}>{`Arriving at ${moment().add(driver.time_to_arrival, 'seconds').format('h:mma')}`}</Text>
                )}
            </View>
        )
    }
    const getContent = () => {
        if(!target || !target.status) {
            return null;
        }
        switch(abstract.type) {
            case 'orders':
            if(![ StatusCodes.orders.toPickup, StatusCodes.orders.toDestination ].includes(target.status.code)) {
                return null;
            }
            break;

            case 'reservations':
            if(![ StatusCodes.reservations.toPickup, StatusCodes.reservations.toDestination ].includes(target.status.code)) {
                return null;
            }
            break;
        }
        return (
            <BubbleHeader
            left={getLeftButton()}
            center={getCenterContent()}
            right={getRightContent()}
            style={{
                width: '100%',
                maxWidth: Screen.panel.maxWidth(),
                height: driver && driver.time_to_arrival ? 50 : 40,
                backgroundColor: Appearance.colors.blue,
                ...style
            }}/>
        )
    }

    const getLeftButton = () => {
        return (
            <TouchableOpacity
            activeOpacity={0.6}
            onPress={onMessagingPress}
            style={{
                width: 25,
                height: 25,
                padding: 2
            }}>
                <Image
                source={require('eCarra/images/messaging-icon-white-small.png')}
                style={{
                    width: '100%',
                    height: '100%',
                    resizeMode: 'contain',
                    tintColor: 'white'
                }}/>
            </TouchableOpacity>
        )
    }

    const getRightContent = () => {
        return (
            <TouchableOpacity
            activeOpacity={0.6}
            onPress={onNavigatorPress}
            style={{
                width: 25,
                height: 25,
                padding: 4
            }}>
                <Image
                source={require('eCarra/images/location-icon-white-small.png')}
                style={{
                    width: '100%',
                    height: '100%',
                    resizeMode: 'contain',
                    tintColor: 'white'
                }}/>
            </TouchableOpacity>
        )
    }

    const getTitle = () => {
        switch(abstract.type) {
            case 'orders':
            return target.status.code === StatusCodes.orders.toPickup ? 'Driver On The Way' : 'Order In-Progress';

            case 'reservations':
            return target.status.code === StatusCodes.reservations.toPickup ? 'Driver On The Way' : 'Reservation In-Progress';

            default:
            return null;
        }
    }

    useEffect(() => {
        utils.content.subscribe(viewID, [ 'orders', 'reservations' ], {
            onUpdate: next => {
                setTarget(next.compare(abstract));
            }
        });
        return () => {
            utils.content.unsubscribe(viewID);
        }
    }, []);

    return getContent();
}

export const CustomerVehicles = ({ utils }) => {

    const panelID = 'customerVehicles';
    const [searchText, setSearchText] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [vehicles, setVehicles] = useState([]);
    const [locations, setLocations] = useState({});

    const onNewVehicle = () => {
        utils.layer.open({
            id: 'vehicle-onboarding',
            Component: VehicleOnboarding.bind(this, {})
        });
    }

    const onVehicleOptions = (vehicle) => {
        utils.sheet.show({
            title: vehicle.category.name,
            message: vehicle.vin,
            content: vehicle.deepLinkEnabled ? null : (
                <Text style={{
                    display: 'flex',
                    color: Appearance.colors.subText(),
                    fontSize: 12,
                    ...Appearance.fontWeight.get(400),
                    textAlign: 'center',
                    paddingHorizontal: 15
                }}>{`It looks like you have not added your ${vehicle.make} login for this vehicle. We can use your ${vehicle.make} account to provide you with energy consumption, battery levels, security states, and other valuable real-time statistics.`}</Text>
            ),
            items: [{
                key: 'schedule',
                title: 'Set Schedule',
                style: 'default'
            },{
                key: 'view',
                title: 'View History',
                style: 'default'
            },{
                key: 'settings',
                title: 'Settings',
                style: 'default'
            }]
        }, (key) => {
            if(key === 'view') {
                utils.layer.open({
                    id: `customer-vehicle-history-logs-${vehicle.id}`,
                    Component: CustomerVehicleHistoryLogs.bind(this, {
                        vehicle: vehicle
                    })
                })

            } else if(key === 'settings') {
                utils.layer.open({
                    id: `customer-vehicle-settings-${vehicle.id}`,
                    Component: CustomerVehicleSettings.bind(this, {
                        vehicle: vehicle
                    })
                })
            }
        })
    }

    const onNoLocationAvailable = () => {
        utils.alert.show({
            title: 'No Location Available',
            message: `There is currently no location available for your ${selectedVehicle.category.name}. This area is updated real-time as new location data becomes available`
        });
    }

    const fetchVehicles = async () => {
        try {
            let { vehicles } = await Request.get(utils, '/vehicles/', {
                type: 'list_customer',
                user_id: utils.user.get().user_id
            });

            let targets = vehicles.map(vehicle => Vehicle.create(vehicle));
            setVehicles(targets);
            setSelectedVehicle(targets.length > 0 ? targets[0] : null);
            setLocations(targets.reduce((object, vehicle) => {
                if(vehicle.location) {
                    object[vehicle.id] = { location: vehicle.location }
                }
                return object;
            }, {}));
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your vehicles list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchVehicles();
    }, []);

    useEffect(() => {
        utils.sockets.subscribe(panelID, {
            onVehicleLocationUpdate: ({ vehicle_id, location }) => {
                setLocations(locations => {
                    return {
                        ...locations,
                        [vehicle_id]: {
                            location: {
                                latitude: location.lat,
                                longitude: location.long,
                                speed: location.speed,
                                heading: location.heading
                            }
                        }
                    };
                })
            }
        });
        return () => {
            utils.sockets.unsubscribe(panelID);
        }
    }, [vehicles]);

    return vehicles && vehicles.length > 0 ? (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'My Vehicles'}
        header={(
            <HeaderWithButton
            label={'My Vehicles'}
            button={'new'}
            onPress={onNewVehicle}
            style={{
                marginBottom: 4
            }}/>
        )}
        options={{
            loading: loading,
            shouldStyle: false,
            removeOverflow: true
        }}>

            <View style={Appearance.styles.panel()}>
                {selectedVehicle && !searchText && (
                    <View style={{
                        position: 'relative',
                        width: '100%',
                        height: 250,
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Map
                        utils={utils}
                        minZoomLevel={5}
                        userLocationIcon={require('eCarra/images/tesla-model-s.png')}
                        {...locations[selectedVehicle.id] && locations[selectedVehicle.id].location && {
                            userLocation: locations[selectedVehicle.id].location,
                            userLocationIconStyle: {
                                iconSize: 0.4,
                                iconRotate: locations[selectedVehicle.id].location.heading || 0
                            }
                        }}
                        style={{
                            height: 250,
                            overflow: 'hidden',
                            borderTopRightRadius: 10,
                            borderTopLeftRadius: 10,
                            borderBottomWidth: 1,
                            borderBottomColor: Appearance.colors.divider()
                        }}/>
                        <TouchableOpacity
                        activeOpacity={0.6}
                        onPress={onNoLocationAvailable}
                        style={{
                            position: 'absolute',
                            width: 65,
                            height: 65,
                            display: locations[selectedVehicle.id] && locations[selectedVehicle.id].location ? 'none' : 'flex'
                        }}>
                            <Image
                            source={require('eCarra/images/no-data-found.png')}
                            style={{
                                width: '100%',
                                height: '100%',
                                resizeMode: 'contain'
                            }} />
                        </TouchableOpacity>
                    </View>
                )}
                {vehicles && vehicles.length > 0 && (
                    vehicles.map((vehicle, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: vehicle.category.name,
                                subTitle: vehicle.vin,
                                icon: {
                                    path: require('eCarra/images/tesla-model-x.png'),
                                    style: Appearance.icons.vehicle(),
                                    imageStyle: vehicle ? null : {
                                        tintColor: Appearance.themeStyle() === 'dark' ? 'white' : Appearance.colors.grey()
                                    }
                                },
                                badge: selectedVehicle && selectedVehicle.id === vehicle.id && vehicles.length > 1 && {
                                    text: 'Selected',
                                    color: Appearance.colors.primary()
                                },
                                onPress: () => setSelectedVehicle(vehicle),
                                bottomBorder: index !== vehicles.length - 1
                            })
                        )
                    })
                )}
            </View>
        </Panel>
    ) : null
}

export const VehicleUsageTrends = ({ utils }) => {

    const panelID = 'vehicleUsageTrends';
    const [loading, setLoading] = useState(true);

    const [logData, setLogData] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [vehicles, setVehicles] = useState(null);
    const [selected, setSelected] = useState('distance');
    const [selectedVehicle, setSelectedVehicle] = useState(null);

    const options = [{
        key: 'duration',
        title: 'Hours of Use'
    },{
        key: 'speed',
        title: 'Average Speed'
    },{
        key: 'distance',
        title: 'Miles Travelled'
    }]

    const Gradient = () => (
        <Defs key={'gradient'}>
            <LinearGrad id={'gradient'} x1={'0'} y={'0%'} x2={'100%'} y2={'0%'}>
                <Stop offset={'0%'} stopColor={Utils.hexToRGBA(Appearance.colors.primary())}/>
                <Stop offset={'100%'} stopColor={Utils.hexToRGBA(Appearance.colors.secondary())}/>
            </LinearGrad>
        </Defs>
    )

    const onPerformancePress = () => {
        utils.alert.show({
            title: 'Performance',
            message: 'This is the overall usage for your vehicle broken down into speed, mileage, and time. Speed readings are available for vehicles with DeepLink setup.'
        });
    }

    const fetchVehicles = async () => {
        try {
            let { vehicles } = await Request.get(utils, '/vehicles/', {
                type: 'usage_reports',
                user_id: utils.user.get().user_id
            });
            let targets = vehicles.map(v => {
                let vehicle = Vehicle.create(v);
                vehicle.logs = v.logs;
                return vehicle;
            });

            setLoading(false);
            setVehicles(targets);
            setSelectedVehicle(targets.length > 0 ? targets[0] : null);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your vehicle usage reports. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onNoTrendsAvailable = () => {
        utils.alert.show({
            title: 'No Data Available',
            message: `There is currently no historic data available for your ${selectedVehicle.category.name}. This area is updated real-time as new data becomes available`
        });
    }

    useEffect(() => {
        fetchVehicles();
    }, []);

    useEffect(() => {

        if(!selectedVehicle) {
            return;
        }
        setLogData(selectedVehicle.logs);
        setGraphData(selectedVehicle.logs.map(log => {
            switch(selected) {
                case 'duration':
                    return (log.duration || 0) / 60 / 60; // seconds to hours
                case 'distance':
                    return (log.distance || 0) / 1609.344; // meters to miles
            }
            return log[selected] || 0;
        }));

    }, [selected, selectedVehicle]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Usage Trends'}
        header={(
            <HeaderWithButton
            label={'Usage Trends'}
            button={'details'}
            onPress={onPerformancePress}
            style={{
                marginBottom: 4
            }}/>
        )}
        options={{
            loading: loading,
            removeOverflow: true,
            shouldStyle: false
        }}>

            <View style={Appearance.styles.panel()}>
                <View style={{
                    borderBottomWidth: 1,
                    borderBottomColor: Appearance.colors.divider()
                }}>
                    <View style={{
                        position: 'relative',
                        width: '100%',
                        height: 250,
                        padding: 15,
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <View>
                            <View style={{
                                flexDirection: 'row',
                                width: '100%',
                                alignItems: 'flex-start',
                            }}>
                                <YAxis
                                data={graphData}
                                numberOfTicks={7}
                                contentInset={{ top: 20, bottom: 20, right: 20 }}
                                formatLabel={value => Utils.yAxisFormat(value)}
                                svg={{
                                    fill: Appearance.colors.subText(),
                                    ...Appearance.fontWeight.get(600),
                                    fontSize: 10,
                                }} />
                                <LineChart
                                data={graphData}
                                contentInset={{ top: 20, left: 20, right: 20, bottom: 20 }}
                                svg={{
                                    strokeWidth: 2,
                                    stroke: 'url(#gradient)',
                                }}
                                style={{
                                    width: Screen.panel.maxWidth() - 80,
                                    height: 200
                                }}>
                                    <Gradient />
                                </LineChart>
                            </View>
                            <View style={{
                                height: 20
                            }}>
                                <XAxis
                                data={graphData}
                                contentInset={{ top: 20, left: 35, right: 20, bottom: 20 }}
                                formatLabel={(value, index) => logData[index] ? moment(logData[index].date).format('MMM') : null}
                                svg={{
                                    fill: Appearance.colors.subText(),
                                    ...Appearance.fontWeight.get(600),
                                    fontSize: 10,
                                }} />
                            </View>
                        </View>

                        <TouchableOpacity
                        activeOpacity={0.6}
                        onPress={onNoTrendsAvailable}
                        style={{
                            position: 'absolute',
                            width: 65,
                            height: 65,
                            display: !graphData || graphData.length === 0 ? 'flex' : 'none'
                        }}>
                            <Image source={require('eCarra/images/no-data-found.png')} style={{
                                width: '100%',
                                height: '100%',
                                resizeMode: 'contain'
                            }} />
                        </TouchableOpacity>
                    </View>

                    <DropdownMenu
                    utils={utils}
                    items={options}
                    label={options.find(option => option.key === selected).title}
                    onChange={item => setSelected(item.key)}/>
                </View>
                {vehicles && vehicles.length > 0
                    ?
                    vehicles.map((vehicle, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: vehicle.category.name,
                                subTitle: vehicle.vin,
                                bottomBorder: index !== vehicles.length - 1,
                                icon: {
                                    path: require('eCarra/images/tesla-model-x.png'),
                                    style: Appearance.icons.vehicle()
                                },
                                badge: {
                                    text: selectedVehicle && selectedVehicle.id === vehicle.id ? 'Selected' : null,
                                    color: Appearance.colors.primary()
                                },
                                onPress: () => setSelectedVehicle(vehicle)
                            })
                        )
                    })
                    :
                    null
                }
            </View>
        </Panel>
    )
}

export const CustomerVehicleApplications = ({ utils }) => {

    const panelID = 'customerVehicleApplications';
    const [applications, setApplications] = useState([]);
    const [loading, setLoading] = useState(true);

    const onApplicationPress = application => {

        utils.layer.open({
            id: `vehicle-application-${application.id}`,
            Component: VehicleOnboarding.bind(this, {
                abstract: Abstract.create({
                    type: 'voApplications',
                    object: application
                })
            })
        })
    }

    const onNewApplication = () => {
        utils.layer.open({
            id: 'vehicle-onboarding',
            Component: VehicleOnboarding.bind(this, {})
        });
    }

    const getContent = () => {
        if(loading === true) {
            return Views.loader();
        }
        if(applications.length === 0) {
            return (
                Views.entry({
                    title: 'No Applications Found',
                    subTitle: 'No open vehicle applications were found',
                    hideIcon: true,
                    bottomBorder: false
                })
            )
        }

        return applications.map((application, index) => {
            return (
                Views.entry({
                    key: index,
                    title: `Application VA${application.id}`,
                    subTitle: moment(application.date).format('MMM Do, YYYY [at] h:mma'),
                    bottomBorder: index !== applications.length - 1,
                    hideIcon: true,
                    badge: application.status,
                    onPress: onApplicationPress.bind(this, application)
                })
            )
        });
    }

    const fetchApplications = async () => {
        try {
            let { applications } = await Request.get(utils, '/vehicles/', {
                type: 'onboarding_applications',
                user_id: utils.user.get().user_id
            });
            setLoading(false);
            setApplications(applications.map(p => Vehicle.Application.create(p)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your vehicle applications. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchApplications();
    }, []);

    useEffect(() => {
        utils.content.subscribe(panelID, 'voApps', {
            onFetch: fetchApplications,
            onUpdate: abstract => {
                setApplications(applications => {
                    return applications.map(application => abstract.compare(application));
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, [applications]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'My Applications'}
        header={(
            <HeaderWithButton
            label={'My Applications'}
            button={'new'}
            onPress={onNewApplication}
            style={{
                marginBottom: 4
            }}/>
        )}
        options={{
            loading: loading,
            removeOverflow: true,
            shouldStyle: false
        }}>
            <View style={Appearance.styles.panel()}>
                {getContent()}
            </View>
        </Panel>
    )
}

export const CustomerVehicleRecentActivity = ({ utils }) => {

    const panelID = 'customerVehicleRecentActivity';
    const [loading, setLoading] = useState(true);
    const [logs, setLogs] = useState([]);

    const onLogPress = log => {
        utils.layer.open({
            id: `customer-vehicle-log-details-${log.vehicle.id}-${log.date}`,
            Component: CustomerVehicleLogDetails.bind(this, {
                log: log
            })
        });
    }

    const fetchLogs = async () => {
        try {
            await fetchLogsAsync();
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your vehicle history. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchLogsAsync = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading('logs');
                let { logs } = await Request.get(utils, '/vehicle/', {
                    type: 'customer_location_logs',
                    limit: 5,
                    user_id: utils.user.get().user_id
                });

                setLoading(false);
                setLogs(logs);
                if(logs.length === 0) {
                    return;
                }
                utils.sockets.subscribe(panelID, logs.reduce((object, log) => {
                    return {
                        ...object,
                        [`onVehicleUpdate-${log.id}`]: ({ id }) => {
                            setLogs(logs => {
                                return (logs || []).map(log => {
                                    log.active = log.id === id;
                                    return log;
                                });
                            })
                        }
                    }
                }, {}));
                resolve();

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        });
    }

    useEffect(() => {
        fetchLogs();
        return () => {
            utils.sockets.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Recent Activity'}
        header={(
            <HeaderWithButton
            label={'Recent Activity'}
            button={'refresh'}
            loading={loading === 'logs'}
            onAsyncPress={fetchLogsAsync}
            style={{
                marginBottom: 4
            }}/>
        )}
        options={{
            loading: loading === true,
            removeOverflow: true,
            shouldStyle: false
        }}>
            <View style={Appearance.styles.panel()}>
                {logs && logs.length > 0
                    ?
                    logs.map((log, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: log.vehicle.category.name,
                                subTitle: log.driver ? `From ${log.driver.full_name}` : 'Driver Not Available',
                                bottomBorder: index !== logs.length - 1,
                                hideIcon: log.driver ? false : true,
                                onPress: onLogPress.bind(this, log),
                                icon: {
                                    path: log.driver ? { uri: log.driver.avatar } : null,
                                },
                                badge: log.active ? {
                                    text: 'In Progress',
                                    color: Appearance.colors.blue
                                } : {
                                    text: Utils.formatDate(log.date),
                                    color: Appearance.colors.grey(),
                                    style: {
                                        paddingHorizontal: 12
                                    }
                                },
                            })
                        )
                    })
                    :
                    Views.entry({
                        title: 'No Activity Found',
                        subTitle: 'No recent movements were found for your account',
                        hideIcon: true,
                        bottomBorder: false
                    })
                }
            </View>
        </Panel>
    )
}

// Layers
export const CustomerVehiclesDeepLink = ({ index, options, utils }) => {

    const layerID = `customer-vehicles-deep-link`;
    const [loading, setLoading] = useState(true);
    const [layerState, setLayerState] = useState(null);
    const [vehicles, setVehicles] = useState(null);

    const onVehiclePress = (vehicle) => {

        utils.alert.show({
            title: vehicle.category.name,
            message: `Please enter the username and password that you use for your ${vehicle.make} account. We keep this information private and only use your account to provide you with usage statistics`,
            textFields: [{
                key: 'username',
                placeholder: 'Username',
                autoCompleteType: 'email',
                textContentType: 'email_address',
                keyboardType: 'email-address',
                autoCapitalize: false
            },{
                key: 'password',
                secure: true,
                placeholder: 'Password',
                autoCompleteType: 'password',
                textContentType: 'password'
            }],
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onPress: async response => {

                let { username, password } = response || {};
                if(!username || !password) {
                    return;
                }

                // Update login
                try {
                    await Request.post(utils, '/vehicle/', {
                        type: 'set_deep_link_credentials',
                        vehicle_id: vehicle.id,
                        username: username,
                        password: password
                    });
                    utils.alert.show({
                        title: 'All Done!',
                        message: `Your login has been updated and DeepLink has been enabled for your ${vehicle.category.name}`,
                        onPress: () => setLayerState('close')
                    });
                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue updating your login. ${e.message || 'An unknown error occurred'}`
                    });
                }
            }
        })
    }

    const fetchVehicles = async () => {
        try {
            let { vehicles } = await Request.get(utils, '/vehicles/', {
                type: 'list_customer',
                user_id: utils.user.get().user_id
            });
            if(vehicles && vehicles.length > 0) {
                setVehicles(vehicles.map(vehicle => Vehicle.create(vehicle)));
                return;
            }
            setLayerState('close');
            utils.alert.show({
                title: 'Oops!',
                message: 'It looks like you have not added any vehicles yet',
                buttons: [{
                    key: 'add',
                    title: 'Add Vehicle',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Dismiss',
                    style: 'cancel'
                }],
                onPress: key => {
                    if(key === 'add') {
                        utils.layer.open({
                            id: 'vehicle-onboarding',
                            Component: VehicleOnboarding.bind(this, {})
                        });
                    }
                }
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your vehicles list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchVehicles();
    }, [])

    return (
        <Layer
            id={layerID}
            title={`My Vehicles`}
            utils={utils}
            index={index}
            options={{
                ...options,
                layerState: layerState,
                removePadding: true,
                bottomCard: true
            }}>

            <View style={{
                paddingTop: 15,
                paddingHorizontal: 15,
                alignItems: 'center',
                borderBottomWidth: 1,
                borderBottomColor: Appearance.colors.divider()
            }}>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 6
                }}>{'DeepLink'}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 20,
                    textAlign: 'center'
                }}>{`With DeepLink, we can use your vehicle's account to provide you with energy consumption, battery levels, security states, and other valuable real-time statistics.`}</Text>
            </View>
            {vehicles && vehicles.length > 0
                ?
                vehicles.map((vehicle, index) => {
                    return (
                        Views.entry({
                            key: index,
                            title: vehicle.category.name,
                            subTitle: vehicle.vin,
                            bottomBorder: index !== vehicles.length - 1,
                            icon: {
                                path: require('eCarra/images/tesla-model-x.png'),
                                style: Appearance.icons.vehicle()
                            },
                            onPress: onVehiclePress.bind(this, vehicle)
                        })
                    )
                })
                :
                null
            }
        </Layer>
    )
}

export const CustomerVehicleSettings = ({ vehicle }, { index, options, utils }) => {

    const layerID = `customer-vehicle-settings-${vehicle.id}`;
    const [loading, setLoading] = useState(true);
    const [layerState, setLayerState] = useState(null);
    const [lineItems, setLineItems] = useState(null);
    const [location, setLocation] = useState(vehicle.location);
    const [preferences, setPreferences] = useState(null);

    const getPrefs = async () => {
        try {
            let { line_items } = await Request.get(utils, '/vehicle/', {
                type: 'mobile_preferences',
                vehicle_id: vehicle.id,
                device_id: DeviceInfo.getUniqueId(),
                ...preferences
            });
            setLoading(false);
            setLineItems(line_items);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your settings for this vehicle. ${e.message || 'An unknown error occurred'}`,
                onPress: () => setLayerState('close')
            });
        }
    }

    const updatePrefs = async () => {
        try {
            if(!preferences) {
                return;
            }
            await Request.post(utils, '/vehicle/', {
                type: 'update_mobile_preferences',
                vehicle_id: vehicle.id,
                device_id: DeviceInfo.getUniqueId(),
                ...preferences
            });
            getPrefs();

            // join or leave socket if prefs have been altered for real-time updates
            if(typeof(preferences.location) === 'boolean') {
                utils.sockets.manager().setVehicleLocationPref(preferences.location, {
                    id: vehicle.id,
                    company_id: vehicle.company ? vehicle.company.id : null,
                    user_id: vehicle.customer ? vehicle.customer.user_id : null
                });
            }

            if(typeof(preferences.deep_link) === 'boolean') {
                utils.sockets.manager().setVehicleDeppLinkPref(preferences.deep_link, {
                    id: vehicle.id,
                    company_id: vehicle.company ? vehicle.company.id : null,
                    user_id: vehicle.customer ? vehicle.customer.user_id : null
                });
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating your settings for this vehicle. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onItemUpdate = (item, content) => {
        setPreferences(prefs => {
            return {
                ...prefs,
                ...content
            }
        });
    }

    const onItemPress = (item) => {

        utils.layer.open({
            id: `line-item-${item.key}`,
            Component: LineItem.bind(this, {
                item: item,
                onChange: onItemUpdate
            })
        })
    }

    useEffect(() => {
        updatePrefs();
    }, [preferences])

    useEffect(() => {

        getPrefs();
        utils.sockets.subscribe(layerID, {
            onVehicleLocationUpdate: ({ vehicle_id, location }) => {
                if(vehicle_id !== vehicle.id) {
                    return;
                }
                setLocation({
                    latitude: location.lat,
                    longitude: location.long,
                    speed: location.speed,
                    heading: location.heading
                })
            }
        });
        return () => {
            utils.sockets.unsubscribe(layerID);
        }
    }, [])

    return (
        <Layer
            id={layerID}
            title={vehicle.category.name}
            utils={utils}
            index={index}
            options={{
                ...options,
                layerState: layerState,
                removePadding: true
            }}
            buttons={[{
                key: 'return',
                text: 'Request a Return',
                color: 'danger',
                onPress: () => {

                }
            }]}>

            <View style={{
                width: '100%',
                height: 250,
                position: 'relative',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Map
                utils={utils}
                userLocationIcon={require('eCarra/images/tesla-model-s.png')}
                {...(location ? {
                    userLocation: location,
                    userLocationIconStyle: {
                        iconSize: 0.4,
                        iconRotate: location.heading || 0
                    }
                } : null)}
                style={{
                    height: 250,
                    overflow: 'hidden',
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10
                }}/>
            </View>

            <View style={{
                width: '100%',
                paddingHorizontal: 15
            }}>
                {lineItems && (
                    lineItems.filter(lineItem => {
                        return lineItem.items && lineItem.items.length > 0;
                    }).map((lineItem, index, lineItems) => {
                        return (
                            <View
                            key={lineItem.key}
                            style={{
                                marginTop: 20
                            }}>
                                <Text
                                ellipsizeMode={'tail'}
                                numberOfLines={1}
                                style={{
                                    ...Appearance.textStyles.title(),
                                    fontSize: 16,
                                    marginBottom: 12
                                }}>
                                    {lineItem.title}
                                </Text>
                                <View style={Appearance.styles.panel()}>
                                    {lineItem.items.map((item, index, items) => {
                                        return  (
                                            Views.entry({
                                                key: item.key,
                                                title: item.title,
                                                subTitle: item.formatted || item.null_value,
                                                hideIcon: true,
                                                bottomBorder: index !== items.length - 1,
                                                onPress: onItemPress.bind(this, item),
                                                propStyles: {
                                                    subTitle: {
                                                        numberOfLines: 2
                                                    }
                                                },
                                                rightContent: item.required && !item.value && (
                                                    <View style={{
                                                        width: 12,
                                                        height: 12,
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <View style={{
                                                            width: 8,
                                                            height: 8,
                                                            borderRadius: 4,
                                                            backgroundColor: Appearance.colors.red
                                                        }}/>
                                                    </View>
                                                )
                                            })
                                        )
                                    })}
                                </View>
                            </View>
                        )
                    })
                )}
            </View>
        </Layer>
    )
}

export const CustomerVehicleLogDetails = ({ log }, { index, options, utils }) => {

    const layerID = `customer-vehicle-log-details-${log.vehicle.id}-${log.date}`;
    const [loading, setLoading] = useState(true);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);

    const [logData, setLogData] = useState(null);
    const [lineItems, setLineItems] = useState(null);

    // Live updates from socket
    const [location, setLocation] = useState(null);
    const [socketData, setSocketData] = useState({});
    const [overlay, setOverlay] = useState(null);

    const fetchLogDetails = async () => {
        try {
            let { line_items, log } = await Request.get(utils, '/vehicle/', {
                type: 'location_log_details',
                id: log.id,
                vehicle_id: log.vehicle.id,
                driver_id: log.driver.user_id
            });

            setLoading(false);
            if(!log) {
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue retrieving details for this history event. An unknown error occurred`,
                    onPress: () => setLayerState('close')
                });
                return;
            }

            setLogData(log);
            setLineItems(line_items);

            // decode polyline
            setOverlay(json.log.shape ? {
                key: 'route',
                coordinates: Utils.decodePolyline(json.log.shape, 6)
            } : null);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving details for this history event. ${e.message || 'An unknown error occurred'}`,
                onPress: () => setLayerState('close')
            });
        }
    }

    useEffect(() => {

        fetchLogDetails();
        utils.sockets.subscribe(layerID, {
            [`onVehicleUpdate-${log.id}`]: props => {
                setSocketData(socketData => {
                    return {
                        ...socketData,
                        ...props
                    }
                })
            },
            onVehicleLocationUpdate: ({ id, vehicle_id, location }) => {
                if(id !== log.id || vehicle_id !== log.vehicle.id) {
                    return;
                }
                setLocation({
                    latitude: location.lat,
                    longitude: location.long,
                    speed: location.speed,
                    heading: location.heading
                });

                // Append new locations to the end of the prebuilt route
                // Map component will flip lat/long for Mapbox
                setOverlay(overlay => {
                    if(overlay) {
                        overlay.coordinates.push([ location.lat, location.long ]);
                    }
                    return overlay;
                })
            }
        });
        return () => {
            utils.sockets.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
            id={layerID}
            title={log.vehicle.category.name}
            utils={utils}
            index={index}
            options={{
                ...options,
                layerState: layerState,
                removePadding: true
            }}
            buttons={[{
                key: 'done',
                text: 'Download',
                color: 'grey',
                loading: downloadLoading,
                onPress: () => {

                }
            }]}>

            <View style={{
                width: '100%',
                height: 250,
                position: 'relative',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Map
                utils={utils}
                overlays={overlay ? [overlay] : null}
                userLocationIcon={require('eCarra/images/tesla-model-s.png')}
                {...(location ? {
                    userLocation: location,
                    userLocationIconStyle: {
                        iconSize: 0.4,
                        iconRotate: location.heading || 0
                    }
                } : null)}
                style={{
                    height: 250,
                    overflow: 'hidden',
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10
                }}/>
                <DotsLoader loading={loading} style={{
                    position: 'absolute',
                    width: 75,
                    height: 75
                }}/>
            </View>

            <View style={{
                width: '100%',
                paddingHorizontal: 15,
                marginTop: 20
            }}>
                {/* driver entry */}
                <Text
                ellipsizeMode={'tail'}
                numberOfLines={1}
                style={{
                    ...Appearance.textStyles.title(),
                    fontSize: 16,
                    marginBottom: 12
                }}>
                    {'Driver'}
                </Text>
                <View style={Appearance.styles.panel()}>
                    {Views.entry({
                        title: log.driver.full_name,
                        subTitle: Utils.formatPhoneNumber(log.driver.phone_number),
                        icon: {
                            path: { uri: log.driver.avatar }
                        },
                        bottomBorder: false
                    })}
                </View>

                {lineItems && (
                    lineItems.filter(lineItem => {
                        return lineItem.items && lineItem.items.length > 0;
                    }).map((lineItem, index, lineItems) => {
                        return (
                            <View
                            key={lineItem.key}
                            style={{
                                marginTop: 20
                            }}>
                                <Text
                                ellipsizeMode={'tail'}
                                numberOfLines={1}
                                style={{
                                    ...Appearance.textStyles.title(),
                                    fontSize: 16,
                                    marginBottom: 12
                                }}>
                                    {lineItem.title}
                                </Text>
                                <View style={Appearance.styles.panel()}>
                                {lineItem.items.map((item, index, items) => {

                                    let localValue = socketData && socketData[item.key]? socketData[item.key].formatted : null;
                                    return  (
                                        Views.entry({
                                            key: item.key,
                                            title: item.title,
                                            subTitle: localValue || item.formatted || item.null_value,
                                            hideIcon: true,
                                            bottomBorder: index !== items.length - 1,
                                            propStyles: {
                                                subTitle: {
                                                    numberOfLines: 2
                                                }
                                            }
                                        })
                                    )
                                })}
                                </View>
                            </View>
                        )
                    })
                )}
            </View>
        </Layer>
    )
}

export const CustomerVehicleHistoryLogs = ({ vehicle }, { index, options, utils }) => {

    const layerID = `customer-vehicle-history-logs-${vehicle.id}`;
    const [loading, setLoading] = useState(true);
    const [layerState, setLayerState] = useState(null);
    const [logs, setLogs] = useState(null);

    const onLogPress = (log) => {
        utils.layer.open({
            id: `customer-vehicle-log-details-${log.vehicle.id}-${log.date}`,
            Component: CustomerVehicleLogDetails.bind(this, {
                log: log
            })
        });
    }

    const fetchHistoryLogs = async () => {
        try {
            let { logs } = await Request.get(utils, '/vehicle/', {
                type: 'customer_location_logs',
                user_id: utils.user.get().user_id,
                vehicle_id: vehicle.id
            });

            setLoading(false);
            setLogs(logs);
            utils.sockets.subscribe(layerID, logs.reduce((object, log) => {
                return {
                    ...object,
                    [`onVehicleUpdate-${log.id}`]: ({ id }) => {
                        setLogs(logs => {
                            return (logs || []).map(log => {
                                log.active = log.id === id;
                                return log;
                            });
                        })
                    }
                }
            }, {}));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your vehicle history. ${e.message || 'An unknown error occurred'}`,
                onPress: () => setLayerState('close')
            });
        }
    }

    useEffect(() => {
        fetchHistoryLogs();
        return () => {
            utils.sockets.unsubscribe(layerID);
        }
    }, [])

    return (
        <Layer
            id={layerID}
            title={`${vehicle.category.name} History`}
            utils={utils}
            index={index}
            options={{
                ...options,
                layerState: layerState,
                removePadding: true
            }}>

            <View style={{
                paddingTop: 15,
                paddingHorizontal: 15,
                marginTop: 20,
                alignItems: 'center'
            }}>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 6
                }}>{vehicle.category.name}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 20
                }}>{logs ? `${logs.length} History ${logs.length === 1 ? 'Event' : 'Events'}` : '...'}</Text>
            </View>
            {logs && logs.length > 0
                ?
                logs.map((log, index) => {
                    return (
                        Views.entry({
                            key: index,
                            title: moment(log.date).format('MMM Do, [at] h:mma'),
                            subTitle: log.driver ? `Driver: ${log.driver.full_name}` : 'Driver Not Available',
                            bottomBorder: index !== logs.length - 1,
                            icon: {
                                path: log.driver ? { uri: log.driver.avatar } : null,
                            },
                            badge: {
                                text: log.active ? 'In Progress' : null,
                                color: Appearance.colors.blue
                            },
                            hideIcon: log.driver ? false : true,
                            onPress: onLogPress.bind(this, log)
                        })
                    )
                })
                :
                loading
                    ?
                    null
                    :
                    Views.entry({
                        title: 'No Activity Found',
                        subTitle: `No recent movements were found for your ${vehicle.category.name}`,
                        hideIcon: true,
                        bottomBorder: false
                    })
            }
        </Layer>
    )
}

export const VehicleOnboardingItem = ({ children, item, onChange }, { index, options, utils }) => {

    const layerID = `vehicle-onboarding-${item.key}`;
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const [layerState, setLayerState] = useState(null);

    useEffect(() => {
        utils.keyboard.subscribe(layerID, {
            onVisibility: visible => setKeyboardOpen(visible)
        });

        return () => {
            utils.keyboard.unsubscribe(layerID);
        }
    }, [])

    return (
        <Layer
            id={layerID}
            title={item.title}
            utils={utils}
            index={index}
            options={{
                ...options,
                bottomCard: true,
                layerState: layerState
            }}
            buttons={[{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onPress: () => {
                    Keyboard.dismiss();
                    setLayerState('close');
                    if(typeof(onChange) === 'function') {
                        onChange();
                    }
                }
            }]}>

            <View style={{
                alignItems: 'center',
                marginTop: 5
            }}>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 6
                }}>{item.title}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 20,
                    textAlign: 'center'
                }}>{item.on_edit.description}</Text>
                {children()}
            </View>
        </Layer>
    )
}

export const VehicleOnboarding = ({ abstract }, { index, options, utils }) => {

    const layerID = abstract ? `vehicle-application-${abstract.getID()}` : 'vehicle-onboarding';
    const [loading, setLoading] = useState(false);
    const [header, setHeader] = useState(null);
    const [lineItems, setLineItems] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [images, setImages] = useState({});
    const [application, setApplication] = useState(abstract ? {
        ...abstract.object.application,
        ...abstract.object.contact
    } : {});

    const onCancelApplication = () => {
        utils.alert.show({
            title: 'Cancel Application',
            message: 'Are you sure that you want to cancel this application? This can not be undone',
            buttons: [{
                key: 'confirm',
                title: 'Cancel',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Cancel',
                style: 'default'
            }],
            onPress: (key) => {
                if(key === 'confirm') {
                    onCancelApplicationConfirm();
                    return;
                }
            }
        })
    }

    const onCancelApplicationConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            let { status } = await Request.post(utils, '/vehicles/', {
                type: 'cancel_onboarding_application',
                application_id: abstract.getID()
            });

            setLoading(false);
            utils.alert.show({
                title: 'Application Cancelled',
                message: `Your application has been cancelled. You can re-submit this application if you wish to continue through the process in the future`,
                onPress: () => setLayerState('close')
            });

            abstract.object.status = Vehicle.Application.getStatus(status);
            utils.content.update(abstract);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue cancelling your application. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onItemPress = async item => {
        if(!item.on_edit || item.on_edit.component !== 'image_picker') {
            utils.layer.open({
                id: `line-item-${item.key}`,
                Component: LineItem.bind(this, {
                    item: item,
                    onChange: onItemUpdate
                })
            });
            return;
        }
        try {
            let response = await ImagePicker.openPicker({
                mediaType: 'photo',
                includeExif: true,
                compressImageQuality: 1,
                includeBase64: true
            });

            onItemUpdate(item, { [item.key]: true });
            setImages(images => update(images, {
                [item.key]: {
                    $set: {
                        uri: response.path,
                        type: response.mime,
                        data: response.data
                    }
                }
            }))

        } catch(e) {
            console.error(e.message);
        }
    }

    const onItemUpdate = (item, content) => {
        setApplication(application => {
            return {
                ...application,
                ...content
            }
        });
    }

    const onResumeApplication = () => {
        utils.alert.show({
            title: 'Resume Application',
            message: 'Are you sure that you want to resume this application? We will automatically resubmit your previous answers for a new review.',
            buttons: [{
                key: 'confirm',
                title: 'Resume',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Resume',
                style: 'default'
            }],
            onPress: (key) => {
                if(key === 'confirm') {
                    onResumeApplicationConfirm();
                    return;
                }
            }
        })
    }

    const onResumeApplicationConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            let { status } = await Request.post(utils, '/vehicles/', {
                type: 'resume_onboarding_application',
                application_id: abstract.getID()
            });

            setLoading(false);
            utils.alert.show({
                title: 'Application Submitted',
                message: `Thank you for submitting your application. We'll contact you with any questions or concerns while we review the information that you submitted`,
                onPress: () => setLayerState('close')
            });
            abstract.object.status = Vehicle.Application.getStatus(status);
            utils.content.update(abstract);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue resuming your application. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSubmitApplication = async () => {

        let requiredItems = lineItems.filter(lineItem => lineItem.items.filter(item => {
            return item.value === null && item.required === true
        }).length > 0);
        if(requiredItems.length > 0) {
            utils.alert.show({
                title: 'Just a Second',
                message: `Please fill out all the required areas in the "${requiredItems[0].title}" section before submitting your application`
            });
            return;
        }
        try {
            setLoading(true);
            await Request.post(utils, '/vehicles/', {
                type: 'new_onboarding_application',
                user_id: utils.user.get().user_id,
                ...application,
                ...images
            });

            setLoading(false);
            utils.content.fetch('voApps');
            utils.alert.show({
                title: 'Application Submitted',
                message: `Thank you for submitting your application. We'll contact you with any questions or concerns while we review the information that you submitted`,
                onPress: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting your application. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {

        if(abstract && abstract.object.status) {
            if(abstract.object.status.code === StatusCodes.voApp.cancelled) {
                return [{
                    key: 'resume',
                    text: 'Resume',
                    loading: loading,
                    color: 'primary',
                    onPress: onResumeApplication
                }];
            }
            return [{
                key: 'cancel',
                text: 'Cancel',
                loading: loading,
                color: 'danger',
                onPress: onCancelApplication
            }];
        }

        return [{
            key: 'done',
            text: 'Submit',
            loading: loading,
            color: 'primary',
            onPress: onSubmitApplication
        }];
    }

    const fetchOnboardingLineItems = async () => {
        try {
            let { header, line_items } = await Request.get(utils, '/vehicles/', {
                type: 'onboarding_line_items',
                application_id: abstract ? abstract.getID() : null,
                ...application
            });
            setHeader(header);
            setLineItems(line_items ? line_items.sections : null);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the onboarding options. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchOnboardingLineItems();
    }, [application, images])

    return (
        <Layer
        id={layerID}
        title={'Vehicle Onboarding'}
        utils={utils}
        index={index}
        buttons={getButtons()}
        options={{
            ...options,
            layerState: layerState,
            removePadding: true
        }}>
            <View style={{
                alignItems: 'center',
                marginTop: 20,
                padding: 15,
                borderBottomWidth: 1,
                borderBottomColor: Appearance.colors.divider()
            }}>
                <Video
                muted={true}
                repeat={true}
                autoPlay={true}
                disableFocus={true}
                mixWithOthers={'mix'}
                resizeMode={'contain'}
                playWhenInactive={true}
                source={Appearance.themeStyle() === 'dark' ? require('eCarra/files/vehicle-onboarding-dark.mp4') : require('eCarra/files/vehicle-onboarding-light.mp4')}
                style={{
                    height: (Screen.layer.maxWidth / 1500) * 1100,
                    width: Screen.layer.maxWidth,
                    marginBottom: 20
                }}/>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 6
                }}>{header ? header.title : null}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    textAlign: 'center'
                }}>{header ? header.description : null}</Text>
            </View>
            <View style={{
                padding: 15
            }}>
                {lineItems && lineItems.length > 0 && (
                    lineItems.filter(lineItem => {
                        return lineItem.items && lineItem.items.length > 0;
                    }).map((lineItem, index) => {
                        return (
                            <View
                            key={lineItem.key}
                            style={{
                                marginTop: index === 0 ? 0 : 20
                            }}>
                                <Text
                                ellipsizeMode={'tail'}
                                numberOfLines={1}
                                style={{
                                    ...Appearance.textStyles.title(),
                                    fontSize: 16,
                                    marginBottom: 12
                                }}>
                                    {lineItem.title}
                                </Text>
                                <View style={Appearance.styles.panel()}>
                                    {lineItem.items.map((item, index, items) => {
                                        return  (
                                            Views.entry({
                                                key: item.key,
                                                title: item.title,
                                                subTitle: item.formatted || item.null_value,
                                                loading: item.loading,
                                                hideIcon: item.url || images[item.key] ? false : true,
                                                icon: (item.url || images[item.key]) && {
                                                    path: { uri: item.url || images[item.key].uri },
                                                    style: {
                                                        borderRadius: 5
                                                    }
                                                },
                                                bottomBorder: index !== items.length - 1,
                                                onPress: item.on_edit && item.on_edit.enabled ? onItemPress.bind(this, item) : null,
                                                propStyles: {
                                                    subTitle: {
                                                        numberOfLines: 2
                                                    }
                                                },
                                                rightContent: item.required && !item.value && (
                                                    <View style={{
                                                        width: 12,
                                                        height: 12,
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <View style={{
                                                            width: 8,
                                                            height: 8,
                                                            borderRadius: 4,
                                                            backgroundColor: Appearance.colors.red
                                                        }}/>
                                                    </View>
                                                )
                                            })
                                        )
                                    })}
                                </View>
                            </View>
                        )
                    })
                )}
            </View>
        </Layer>
    )
}

export const SeedPodConfig = ({ closeLayer, floatingLayerState, setFloatingLayerState, index, options, utils }) => {

    // Use state update prepended with current state for fresh data in async callbacks
    const layerID = `seed-pod-config`;

    const seedPodRef = useRef(null);
    const sensorsRef = useRef(null);
    const seedPodLottie = useRef(null);
    const airQualityLottie = useRef(null);

    const [loading, setLoading] = useState(false);
    const [maxHeight, setMaxHeight] = useState(new Animated.Value(0));

    const [searching, setSearching] = useState(true);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [fanSource, setFanSource] = useState(null);

    const [seedPods, setSeedPods] = useState([]);
    const [nearbyPeripherals, setNearbyPeripherals] = useState([]);
    const [pendingPeripherals, setPendingPeripherals] = useState([]);

    const [seedPod, setSeedPod] = useState(null);
    const [sensorUUIDs, setSensorUUIDs] = useState([]);
    const [networks, setNetworks] = useState([]);
    const [sensors, setSensors] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);

    // Connect and Disconnect
    const onConnectPress = async peripheral => {
        if(loading === true) {
            console.log('already connected');
            return;
        }
        try {
            setLoading(true);
            setSearching(peripheral.id);

            await readCharacteristics(peripheral);
            setLoading(false);
            setSearching(false);
            setSeedPod(peripheral);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: 'There was an issue connecting to SeedPod. Please check that you are within range and try again.'
            })
        }
    }

    const onDisconnectPress = () => {
        utils.alert.show({
            title: 'Disconnect from SeedPod',
            message: 'Are you sure that you want to disconnect from SeedPod? This will stop all sensor communication',
            buttons: [{
                key: 'confirm',
                title: 'Disconnect',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Disconnect',
                style: 'default'
            }],
            onPress: async key => {
                if(key === 'confirm') {
                    try {
                        setLoading(true);
                        //await utils.bluetooth.get().stop(seedPod.device.id);
                        setLayerState('close');

                    } catch(e) {
                        setLoading(false);
                        utils.alert.show({
                            title: 'Oops!',
                            message: 'There was an issue disconnecting from SeedPod. Please check that you are within range and try again.'
                        })
                    }
                }
            }
        })
    }

    // Loading and Timeouts
    const setBleTimeout = (event, duration) => {
        setLoading({
            event: event,
            timeout: setTimeout(onTimeoutError.bind(this, event), duration ? duration * 1000 : 10000)
        });
    }

    const invalidateBleTimeout = event => {
        setLoading(loading => {
            if(!loading || typeof(loading) !== 'object' || loading.event !== event) {
                return loading;
            }
            clearTimeout(loading.timeout);
            return null;
        });
    }

    const onTimeoutError = event => {
        setLoading(false);
        let message = 'An unknown error occurred while sending your request';
        switch(event) {

            case Ble.events.restartNode:
            case Ble.events.restartSystem:
                message = 'There was an issue completing the restart process. Please check that you are within range of SeedPod';
                break;

            case Ble.events.enableBAT:
            case Ble.events.disableBAT:
            case Ble.events.onBATChange:
                message = 'There was an issue performing the wifi/mesh transition. Please check that you are within range of SeedPod';
                break;

            case Ble.events.softwareUpdate:
                message = 'There was an issue running the software update. Please check that SeedPod and the Seeds have a wifi network added and that you are within range of the devices and the wifi network';
                break;

            case Ble.events.addSensor:
                message = 'There was an issue adding the Seed to SeedPod. Please check that you are within range of SeedPod and the Seed';
                break;

            case Ble.events.removeSensor:
                message = 'There was an issue removing the Seed from SeedPod. Please check that you are within range of SeedPod and the Seed';
                break;

            case Ble.events.addNetwork:
                message = 'There was an issue adding the network to SeedPod. Please check that you are within range of the network and SeedPod';
                break;

            case Ble.events.removeNetwork:
                message = 'There was an issue removing the network from SeedPod. Please check that you are within range of the network and SeedPod';
                break;

            case Ble.events.connectToNetwork:
                message = 'There was an issue connecting to the network. Please check that you are within range of the network and SeedPod';
                break;

            case Ble.events.disconnectFromNetwork:
                message = 'There was an issue disconnecting from the network. Please check that you are within range of the network and SeedPod';
                break;

            case Ble.events.onSync:
                message = 'There was an issue syncing SeedPod with eCarra. Please check that you have added a wifi network to SeedPod and are within range of the network and SeedPod';
                break;

            case Ble.events.onDataPrefChange:
                message = 'There was an issue setting the data preferences for this Seed. Please check that you are within range of the Seed';
                break;
        }
        utils.alert.show({
            title: 'Oops!',
            message: message
        })
    }

    // Wifi
    const onNewWifiNetwork = () => {

        utils.alert.show({
            title: 'Add a Network',
            message: 'SeedPod and each Seed use a wifi network to check for software updates, run system maintenance, and perform other tasks that require an internet connection.',
            textFields: [{
                key: 'ssid',
                placeholder: 'Name/SSID'
            },{
                key: 'pass',
                secure: true,
                placeholder: 'Password'
            }],
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onPress: ({ ssid, pass }) => {
                if(!ssid || !pass) {
                    return;
                }
                setBleTimeout(Ble.events.addNetwork, 60);
                /*
                utils.bluetooth.get().write(seedPod.wifi, {
                    id: seedPod.id,
                    event: Ble.events.addNetwork,
                    data: `${ssid}:${pass}`
                })
                */
            }
        })
    }

    const onNetworkPress = (network) => {
        utils.sheet.show({
            items: [{
                key: 'connection',
                title: network.connected ? 'Disconnect' : 'Connect',
                style: network.conencted ? 'destructive' : 'default'
            },{
                key: 'remove',
                title: 'Remove Network',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }]
        }, (key) => {
            if(key === 'connection') {
                onSetWifiConfirm(network);

            } else if(key === 'remove') {
                if(network.ssid === 'Chateau') {
                    utils.alert.show({
                        title: 'Just a Second',
                        message: 'The eCarra wifi network is reserved as a backup for technical troubleshooting. This wifi network can not be removed'
                    });
                    return;
                }
                onRemoveWifiConfirm(network);
            }
        })
    }

    const onAddNetwork = ({ id, event, data }) => {

        try {
            if(!data) {
                throw new Error('No data received from device');
                return;
            }

            let ssid = data[0];
            let pass = data[1];
            let success = data[2];
            if(!ssid || !pass) {
                throw new Error('Missing ssid or pass');
                return;
            }
            if(!success) {
                throw new Error('An unknown error occurred');
                return;
            }
            invalidateBleTimeout(event);
            setNetworks(networks => {
                return networks.concat([{
                    ssid: ssid,
                    pass: pass
                }])
            })

        } catch(e) {
            console.error(e.message);
            invalidateBleTimeout(event);
            utils.alert.show({
                title: 'Oops!',
                message: 'We were unable to add your wifi network to SeedPod. Please check that the name/ssid and password are correct and that you are within range of SeedPod'
            });
        }
    }

    const onConnectToNetwork = ({ id, event, data }) => {

        try {
            if(!data) {
                throw new Error('No data received from device');
                return;
            }

            let ssid = data[0];
            let pass = data[1];
            let success = data[2];
            if(!ssid || !pass) {
                throw new Error('Missing ssid or pass');
                return;
            }
            if(!success) {
                throw new Error('An unknown error occurred');
                return;
            }

            invalidateBleTimeout(event);
            setSeedPod(seedPod => {
                if(seedPod && seedPod.id === id) {
                    seedPod.tcp = false;
                    seedPod.bat = false;
                    seedPod.network = ssid;
                }
                return seedPod;
            });
            setSensors(sensors => {
                return sensors.map(sensor => {
                    if(id === sensor.id) {
                        sensor.tcp = false;
                        sensor.bat = false;
                        sensor.network = ssid;
                    }
                    return sensor;
                })
            })
            setNetworks(networks => {
                return networks.map(network => {
                    network.connected = network.ssid === ssid && network.pass === pass
                    return network;
                })
            })

        } catch(e) {
            invalidateBleTimeout(event);
            utils.alert.show({
                title: 'Oops!',
                message: 'There was an issue connecting to the wifi network. Please check that you are within range of SeedPod'
            })
        }
    }

    const onDisconnectFromNetwork = ({ id, event, data }) => {

        try {
            if(!data) {
                throw new Error('No data received from device');
                return;
            }

            let ssid = data[0];
            let pass = data[1];
            let success = data[2];
            if(!ssid || !pass) {
                throw new Error('Missing ssid or pass');
                return;
            }
            if(!success) {
                throw new Error('An unknown error occurred');
                return;
            }

            invalidateBleTimeout(event);
            setSeedPod(seedPod => {
                if(seedPod && seedPod.id === id) {
                    seedPod.network = null;
                }
                return seedPod;
            });
            setSensors(sensors => {
                return sensors.map(sensor => {
                    if(id === sensor.id) {
                        sensor.network = null;
                    }
                    return sensor;
                })
            })
            setNetworks(networks => {
                return networks.map(network => {
                    network.connected = false;
                    return network;
                })
            })

        } catch(e) {
            invalidateBleTimeout(event);
            utils.alert.show({
                title: 'Oops!',
                message: 'There was an issue connecting to the wifi network. Please check that the network name and password are correct and that you are within range of SeedPod'
            })
        }
    }

    const onRemoveNetwork = ({ event, data }) => {

        try {
            if(!data) {
                throw new Error('No data received from device');
                return;
            }

            let ssid = data[0];
            let pass = data[1];
            let success = data[2];
            if(!ssid || !pass) {
                throw new Error('Missing ssid or pass');
                return;
            }
            if(!success) {
                throw new Error('An unknown error occurred');
                return;
            }
            invalidateBleTimeout(event);
            setNetworks(networks => {
                return networks.filter(network => network.ssid !== ssid && network.pass !== pass)
            })

        } catch(e) {
            invalidateBleTimeout(event);
            utils.alert.show({
                title: 'Oops!',
                message: 'We were unable to remove your wifi network from SeedPod. Please check that you are within range of SeedPod'
            });
        }
    }

    const onSetWifiConfirm = (network) => {

        if(network.connected === null) {
            utils.alert.show({
                title: 'Network Waiting',
                message: 'This network is currently awaiting a response from SeedPod'
            });
            return;
        }

        utils.alert.show({
            title: `${network.connected ? 'Disconnect from' : 'Connect to'} ${network.ssid}`,
            message: `Are you sure that you want to ${network.connected ? 'disconnect from' : 'connect to'} the "${network.ssid}" network? SeedPod will no longer be able to ${network.connected ? 'communicate with the internet if you disconnect' : 'communicate with each Seed if you connect'}`,
            buttons: [{
                key: 'confirm',
                title: network.connected ? 'Disconnect' : 'Connect',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onPress: (key) => {
                if(key === 'confirm') {
                    setBleTimeout(Ble.events[network.connected ? 'disconnectFromNetwork' : 'connectToNetwork'], 60);
                    /*
                    utils.bluetooth.get().write(seedPod.wifi, {
                        id: seedPod.id,
                        event: network.connected ? Ble.events.disconnectFromNetwork : Ble.events.connectToNetwork,
                        data: `${network.ssid}:${network.pass}`
                    });
                    */
                }
            }
        })
    }

    const onRemoveWifiConfirm = (network) => {
        utils.alert.show({
            title: `Remove "${network.ssid}" Network`,
            message: `Are you sure that you want to remove "${network.ssid}" from SeedPod? We will remove this network from Seedpod and all connected Seeds`,
            buttons: [{
                key: 'confirm',
                title: 'Remove',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'default'
            }],
            onPress: (key) => {
                if(key === 'confirm') {

                    setBleTimeout(Ble.events.removeNetwork);
                    /*
                    utils.bluetooth.get().write(seedPod.wifi, {
                        id: seedPod.id,
                        event: Ble.events.removeNetwork,
                        data: `${network.ssid}:${network.pass}`
                    });
                    nearbyPeripherals.forEach(peripheral => {
                        utils.bluetooth.get().write(peripheral.wifi, {
                            id: peripheral.id,
                            event: Ble.events.removeNetwork,
                            data: `${network.ssid}:${network.pass}`
                        });
                    })
                    */
                }
            }
        })
    }

    // BAT and TCP
    const setTCPStatus = ({ id, event, data }) => {

        invalidateBleTimeout(event);
        setSeedPod(seedPod => {
            if(seedPod && seedPod.serialNumber === id) {
                seedPod.tcp = Boolean(data);
                seedPod.network = data ? false : seedPod.network;
                seedPodLottie.current[data ? 'play' : 'reset']();
            }
            return seedPod;
        })

        setSensors(sensors => update(sensors, {
            $apply: sensors => sensors.map(sensor => {
                if(id === sensor.serialNumber) {
                    sensor.tcp = Boolean(data);
                    sensor.network = data ? false : sensor.network;
                }
                return sensor;
            })
        }))

    }

    const setBATStatus = ({ id, event, data }) => {

        invalidateBleTimeout(event);
        setSeedPod(seedPod => {
            if(seedPod && seedPod.serialNumber === id) {
                seedPod.bat = Boolean(data);
                seedPod.network = data ? null : seedPod.network
                seedPodLottie.current.reset();
            }
            return seedPod;
        })
        setSensors(sensors => update(sensors, {
            $apply: sensors => sensors.map(sensor => {
                if(id === sensor.serialNumber) {
                    sensor.bat = Boolean(data);
                    sensor.network = data ? null : sensor.network
                }
                return sensor;
            })
        }))
    }

    const setWifiStatus = ({ id, event, data }) => {

        invalidateBleTimeout(event);
        setSeedPod(seedPod => {
            if(seedPod && seedPod.serialNumber === id) {
                seedPod.network = data;
                seedPod.tcp = data ? false : seedPod.tcp;
                seedPod.bat = data ? false : seedPod.bat;
                seedPodLottie.current[!seedPod.bat || data ? 'reset' : 'play']();
            }
            return seedPod;
        })
        setSensors(sensors => update(sensors, {
            $apply: sensors => sensors.map(sensor => {
                if(id === sensor.serialNumber) {
                    sensor.network = data;
                    sensor.tcp = data ? false : sensor.tcp;
                    sensor.bat = data ? false : sensor.bat;
                }
                return sensor;
            })
        }))
    }

    const setSeedPodBAT = event => {

        utils.alert.show({
            title: `${event === Ble.modifiers.bat.enable ? 'Enable' : 'Disable'} Mesh`,
            message: `Are you sure that you want to change the communications for SeedPod? ${event === Ble.modifiers.bat.enable ? 'Enabling' : 'Disabling'} will ${event === Ble.modifiers.bat.enable  ? 'allow SeedPod to communicate with other Seeds' : 'disconnect this SeedPod from its Seeds and deactivate the network'}. This is an advanced feature and should only be used for troubleshooting`,
            buttons: [{
                key: 'confirm',
                title: `${event === Ble.modifiers.bat.enable ? 'Enable' : 'Disable'} Mesh`,
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onPress: (key) => {
                if(key === 'confirm') {
                    setBleTimeout(Ble.events.onBATChange, 30);
                    /*
                    utils.bluetooth.get().write(seedPod.system, {
                        id: seedPod.id,
                        event: Ble.events.onBATChange,
                        data: event
                    })
                    */
                }
            }
        })
    }

    const setSensorBAT = (peripheral, event) => {

        utils.alert.show({
            title: `${event === Ble.modifiers.bat.enable ? 'Enable' : 'Disable'} Mesh`,
            message: `Are you sure that you want to change the communications for this Seed? ${event === Ble.modifiers.bat.enable ? 'Enabling' : 'Disabling'} mesh will ${event === Ble.modifiers.bat.enable ? 'allow this Seed to communicate with SeedPod' : 'disconnect this Seed from SeedPod and deactivate the network'}. This is an advanced feature and should only be used for troubleshooting`,
            buttons: [{
                key: 'confirm',
                title: `${event === Ble.modifiers.bat.enable ? 'Enable' : 'Disable'} Mesh`,
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onPress: (key) => {
                if(key === 'confirm') {
                    setBleTimeout(Ble.events.onBATChange, 30);
                    /*
                    utils.bluetooth.get().write(peripheral.system, {
                        id: peripheral.id,
                        event: Ble.events.onBATChange,
                        data: event
                    })
                    */
                }
            }
        })
    }

    // SeedPod
    const readCharacteristics = async peripheral => {
        return new Promise(async (resolve, reject) => {
            resolve();
            /*
            utils.bluetooth.get().stopScanning();
            try {
                let { service, characteristics } = await utils.bluetooth.get().readAllCharacteristics(peripheral.device);
                characteristics.forEach(({ characteristic, payload }) => {

                    switch(characteristic.uuid) {
                        case SensorsCharacteristic:
                            peripheral.uuids = payload.data;
                            peripheral.sensors = characteristic;
                            setSensorUUIDs(payload.data || []);
                            break;

                        case WifiCharacteristic:
                            peripheral.wifi = characteristic;
                            console.log(payload);
                            setNetworks(payload.data.map(network => {
                                return {
                                    ssid: network[0],
                                    pass: network[1]
                                }
                            }));
                            break;

                        case SystemCharacteristic:
                            peripheral.bat = payload.data[0];
                            peripheral.tcp = payload.data[1];
                            peripheral.serialNumber = payload.id;
                            peripheral.system = characteristic;
                            break;
                    }
                    setupSubscriptions(characteristic);
                })

                // save service id for future auto-connects
                // service id requried to start scanning for specific devices
                utils.bluetooth.get().updateServices(service)
                resolve(characteristics);

            } catch(e) {
                reject(e);
            }
            */
        })
    }

    const onSettingsPress = () => {
        utils.sheet.show({
            items: [{
                key: 'about',
                title: 'About',
                style: 'default'
            },{
                key: 'advanced',
                title: 'Advanced',
                style: 'destructive'
            },{
                key: 'disconnect',
                title: 'Disconnect',
                style: 'destructive'
            }]
        }, (key) => {

            if(key === 'about') {
                utils.alert.show({
                    title: 'SeedPod',
                    message: 'SeedPod is a wireless hub that connects to specialty in-car sensors for an elevated customer experienced. SeedPod communicates wirelessly with your device over Bluetooth LE and utilizes your internet connection to transmit data back to eCarra'
                })

            } else if(key === 'disconnect') {
                onDisconnectPress();

            } else if(key === 'advanced') {
                onAdvancedPress();
            }
        })
    }

    const onAdvancedPress = () => {
        utils.sheet.show({
            items: [{
                key: 'sync',
                title: 'Sync Logs',
                style: 'default'
            },{
                key: 'bat',
                title: `${seedPod && seedPod.bat ? 'Disable' : 'Enable'} Mesh`,
                style: 'default'
            },{
                key: 'update',
                title: 'Software Update',
                style: 'default'
            },{
                key: 'restart',
                title: 'Restart',
                style: 'destructive'
            }]
        }, (key) => {

            if(key === 'bat') {
                setSeedPodBAT(seedPod.bat ? Ble.modifiers.bat.disable : Ble.modifiers.bat.enable);

            } else if(key === 'sync') {
                syncLogs();

            } else if(key === 'update') {
                runSoftwareUpdate();

            } else if(key === 'restart') {
                restartSeedPod();
            }
        })
    }

    const syncLogs = () => {
        utils.alert.show({
            title: 'Sync',
            message: `Syncing SeedPod with upload all of the saved information from SeedPod to eCarra. This process uses your device's internet connection to facilitate the upload and may take a couple of minutes to complete`,
            buttons: [{
                key: 'start',
                title: 'Start Syncing',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: 'cancel'
            }],
            onPress: (key) => {
                if(key === 'start') {
                    startSyncing();
                }
            }
        })
    }

    const startSyncing = () => {
        setBleTimeout(Ble.events.onSync, 300); // 5 minute timeout
        /*
        utils.bluetooth.get().write(seedPod.system, {
            id: seedPod.id,
            event: Ble.events.onSync,
            data: true
        })
        */

        // Update SeedPod BAT and TCP
        setSeedPod(seedPod => {
            seedPod.bat = false;
            seedPod.tcp = false;
            return seedPod;
        })
    }

    const restartSeedPod = () => {
        utils.alert.show({
            title: 'Restart SeedPod',
            message: 'Are you sure that you want to restart SeedPod?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Restart',
                style: 'default'
            }],
            onPress: (key) => {
                if(key === 'confirm') {
                    /*
                    utils.bluetooth.get().write(seedPod.system, {
                        id: seedPod.id,
                        event: Ble.events.restartSystem,
                        data: true
                    })
                    */
                }
            }
        })
    }

    // Sensors
    const pairPreviousSensors = async () => {
        return;
        /*
        if(sensorUUIDs.length === 0) {
            return;
        }
        // Auto pair to previously connected sensors
        for (let i = 0; i < nearbyPeripherals.length; i++) {
            let peripheral = nearbyPeripherals[i];
            if(sensors.find(sensor => sensor.id === peripheral.id)) {
                continue; // skip already paired sensors
            }

            let { serialNumber } = await utils.bluetooth.get().getSerialNumber(peripheral.device);
            if(sensorUUIDs.includes(serialNumber)) {
                await readCharacteristics(peripheral);
                setSensors(sensors => {
                    if(!sensors.find(sensor => sensor.serialNumber === serialNumber)) {
                        return sensors.concat([peripheral])
                    }
                    return sensors.map(sensor => {
                        if(sensor.serialNumber === serialNumber) {
                            return peripheral;
                        }
                        return sensor;
                    });
                })
            }
        }
        */
    }

    const onSearchPeripherals = () => {
        //utils.bluetooth.get().start();
    }

    const onSensorPress = (peripheral) => {
        utils.sheet.show({
            items: [{
                key: 'about',
                title: 'About',
                style: 'default'
            },{
                key: 'map',
                title: 'Show Map',
                visible: peripheral.type === Ble.devices.aq,
                style: 'default'
            },{
                key: 'advanced',
                title: 'Advanced',
                style: 'destructive'
            },{
                key: 'disconnect',
                title: 'Disconnect',
                style: 'destructive'
            }]
        }, async key => {

            if(key === 'bat') {
                setSensorBAT(peripheral, Ble.modifiers.bat[peripheral.bat ? 'disable' : 'enable']);

            } else if(key === 'advanced') {
                onAdvancedSensorPress(peripheral);

            } else if(key === 'disconnect') {
                try {
                    //await utils.bluetooth.get().stop(peripheral.device.id);
                    setSensors(sensors => update(sensors, {
                        $apply: sensors => sensors.filter(sensor => sensor.id !== peripheral.device.id)
                    }));

                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue disconnecting from this sensor. Please check that you are within range of the Seed and try again`
                    });
                }
            }
        })
    }

    const onAdvancedSensorPress = (peripheral) => {
        utils.sheet.show({
            items: [{
                key: 'bat',
                title: `${peripheral.bat ? 'Disable' : 'Enable'} Mesh`,
                style: 'default'
            },{
                key: 'pref',
                title: 'Set Data Frequency',
                style: 'default'
            },{
                key: 'restart',
                title: 'Restart',
                style: 'destructive'
            }]
        }, async key => {

            if(key === 'bat') {
                setSensorBAT(peripheral, peripheral.bat ? Ble.modifiers.bat.disable : Ble.modifiers.bat.enable);

            } else if(key === 'pref') {
                onSensorDataPref(peripheral)

            } else if(key === 'restart') {
                restartSensor(peripheral);
            }
        })
    }

    const onSensorDataPref = (peripheral) => {
        utils.sheet.show({
            title: 'Data Frequency',
            message: 'By default, each Seed collects data at a frequency that extends the life of the sensors. You can change the frequency if you need to collect data more often but this may result in a shorter life span for your Seed.',
            items: [{
                key: Ble.modifiers.dataPref.max_data,
                title: 'Max Data',
                style: 'default'
            },{
                key: Ble.modifiers.dataPref.balanced,
                title: 'Balanced',
                style: 'default'
            },{
                key: Ble.modifiers.dataPref.extend_life,
                title: 'Extend Life',
                style: 'default'
            }]
        }, async key => {

            if(key === 'cancel') {
                return;
            }

            setBleTimeout(Ble.events.onDataPrefChange);
            /*
            utils.bluetooth.get().write(peripheral.system, {
                id: peripheral.serialNumber,
                event: Ble.events.onDataPrefChange,
                data: key
            })
            */
        })
    }

    const onAddPeripheral = async peripheral => {

        /*
        if(peripheral.connecting) {
            console.log('already connecting');
            return;
        }

        // Update connecting status
        setNearbyPeripherals(nearbyPeripherals => update(nearbyPeripherals, {
            $apply: peripherals => peripherals.map(p => {
                if(p.id === peripheral.id) {
                    p.connecting = true;
                }
                return p;
            })
        }));

        try {
            // Read system characteristic to get serial number
            let { service, serialNumber } = await utils.bluetooth.get().getSerialNumber(peripheral.device);
            setNearbyPeripherals(nearbyPeripherals => update(nearbyPeripherals, {
                $apply: peripherals => peripherals.map(p => {
                    if(p.id === peripheral.id) {
                        p.connecting = false;
                    }
                    return p;
                })
            }));

            // Write request to SeedPod
            setBleTimeout(Ble.events.addSensor);
            utils.bluetooth.get().write(seedPod.sensors, {
                id: serialNumber,
                event: Ble.events.addSensor
            })

        } catch(e) {
            invalidateBleTimeout(Ble.events.addSensor);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue connecting to the Seed. Please check that you are within range of the Seed and SeedPod`
            });
        }
        */
    }

    const onPeripheralAdded = ({ id, event, data }) => {

        try {
            if(!data) {
                throw new Error('Data not found in payload');
                return;
            }

            // Move peripheral from nearby to paired and update pair sensor uuids
            invalidateBleTimeout(event);
            setSensorUUIDs(sensorUUIDs => sensorUUIDs.concat([id]));
            setNearbyPeripherals(nearbyPeripherals => update(nearbyPeripherals, {
                $apply: peripherals => peripherals.filter(peripheral => {
                    if(id === peripheral.id) {
                        getSensorCharacteristics(peripheral);
                        setSensors(sensors => sensors.concat([peripheral]))
                        return false;
                    }
                    return peripheral;
                })
            }));

        } catch(e) {
            invalidateBleTimeout(event);
            utils.alert.show({
                title: 'Oops!',
                message: 'There was an issue adding this Seed to SeedPod. Please check that SeedPod is within range of the Seed and try again'
            });
        }
    }

    const onDeviceDisconnect = (device) => {
        utils.alert.show({
            title: 'Oops!',
            message: `Your device has lost connection to ${device.name}. Please check that you are within range of ${device.name} and try to reconnect`,
            onPress: () => {
                if(seedPodRef.current && device.id === seedPodRef.current.id) {
                    closeLayer(layerID)
                }
            }
        });
    }

    // BLE payloads and subscriptions
    const setupSubscriptions = (characteristic) => {
        let subscription = characteristic.monitor((error, c) => {
            try {
                /*
                if(!c) {

                    // Lost connection to SeedPod
                    if(seedPodRef.current && seedPodRef.current.device.id === characteristic.deviceID) {
                        onDeviceDisconnect(seedPodRef.current);
                    }

                    // Lost connection to Seed
                    if(sensorsRef.current) {
                        let sensor = sensorsRef.current.find(sensor => sensor.device.id === characteristic.deviceID);
                        if(sensor) {
                            onDeviceDisconnect(sensor);
                        }
                    }
                    return;
                }

                let payload = utils.bluetooth.get().decodePayload(c.value);
                switch(payload.event) {
                    case Ble.events.restartSystem:
                        onRestartSystem(payload);
                        break;
                    case Ble.events.onTCPChange:
                        setTCPStatus(payload);
                        break;
                    case Ble.events.onBATChange:
                        setBATStatus(payload);
                        break;
                    case Ble.events.onWifiChange:
                        setWifiStatus(payload);
                        break;
                    case Ble.events.addSensor:
                        onPeripheralAdded(payload);
                        break;
                    case Ble.events.addNetwork:
                        onAddNetwork(payload);
                        break;
                    case Ble.events.removeNetwork:
                        onRemoveNetwork(payload);
                        break;
                    case Ble.events.connectToNetwork:
                        onConnectToNetwork(payload);
                        break;
                    case Ble.events.disconnectFromNetwork:
                        onDisconnectFromNetwork(payload);
                        break;
                    case Ble.events.softwareUpdate:
                        onSoftwareUpdate(payload);
                        break;
                    case Ble.events.onUpdate:
                        formatSensorData(payload);
                        break;
                    case Ble.events.onDataPrefChange:
                        onDataPrefChange(payload);
                        break;
                    case Ble.events.onSync:
                        onSync(payload);
                        break;
                }
                */
            } catch(e) {
                console.log(e)
            }
        });
        setSubscriptions(subscriptions => update(subscriptions, {
            $push: [subscription]
        }))
    }

    const onDataPrefChange = ({ id, event, data }) => {

        try {
            if(!data) {
                throw new Error('An unknown error occurred');
                return;
            }
            invalidateBleTimeout(event);
            utils.alert.show({
                title: 'All Done!',
                message: 'Your data preference for this Seed has been saved'
            })

        } catch(e) {
            invalidateBleTimeout(event);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting the data preference for this Seed. Please check that you are within range of the Seed and try again`
            })
        }
    }

    const getAQColor = (value) => {
        if(value <= 12) {
            return Appearance.colors.green;
        }
        if(value <= 35.4) {
            return Appearance.colors.yellow;
        }
        if(value <= 55.4) {
            return Appearance.colors.orange;
        }
        return Appearance.colors.red;
    }

    const formatSensorData = async ({ id, device, event, data }) => {
        try {

            let reading = null;
            let location = data.length > 2 ? data[2] : null;
            if(!location) {
                throw new Error('Location not found in payload');
                return;
            }

            let socketPayload = {
                sn: seedPodRef.current.serialNumber,
                device: device,
                location: {
                    lat: location[0],
                    long: location[1]
                }
            };

            switch(device) {
                case Ble.devices.aq:
                    reading = `${data[0]} μg/m\xB3 fine particles and ${data[1]} μg/m\xB3 coarse particles`
                    socketPayload.aq = {
                        id: id,
                        pm25: data[0],
                        pm10: data[1]
                    }
                    break;
            }
            setSensors(sensors => sensors.map(sensor => {
                if(id === sensor.serialNumber) {
                    sensor.tcp = true;
                    sensor.bat = true;
                    sensor.reading = reading;

                    if(sensor.type === Ble.devices.aq) {
                        if(!sensor.clusters) {
                            sensor.clusters = [];
                        }
                        sensor.clusters = sensor.clusters.concat([{
                            location: [ location[1], location[0] ],
                            pref: {
                                value: data[0],
                                color: getAQColor(data[0])
                            }
                        }])
                    }
                }
                return sensor;
            }));

            setSeedPod(seedPod => {
                seedPod.tcp = true;
                seedPod.bat = true;
                seedPod.network = null;
                return seedPod
            });

            await utils.sockets.emit('sensors', 'active_update', socketPayload);

        } catch(e) {
            console.error(e.message);
        }
    }

    const onSync = ({ id, event, data }) => {

        try {
            if(!data) {
                throw new Error('An unknown error occurred');
                return;
            }
            invalidateBleTimeout(event);
            utils.alert.show({
                title: 'All Done!',
                message: `The syncing process has been completed. SeedPod will start reconnecting to nearby Seeds if available`,
                onPress: () => {
                    setBleTimeout(Ble.events.onBATChange, 30);
                    /*
                    setSeedPod(seedPod => {
                        utils.bluetooth.get().write(seedPod.system, {
                            id: seedPod.id,
                            event: Ble.events.onBATChange,
                            data: Ble.modifiers.bat.enable
                        });
                        return seedPod;
                    })
                    */
                }
            });

        } catch(e) {
            invalidateBleTimeout(event);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue completing the syncing process. Please check that you are within range of SeedPod and that your device has an active internet connection`,
                buttons: [{
                    key: 'confirm',
                    title: 'Try Again',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Dismiss',
                    style: 'cancel'
                }],
                onPress: (key) => {
                    if(key === 'confirm') {
                        startSyncing();
                        return;
                    }

                    // Reconnect to BAT and TCP
                    setSeedPod(seedPod => {
                        /*
                        utils.bluetooth.get().write(seedPod.system, {
                            id: seedPod.id,
                            event: Ble.events.onBATChange,
                            data: Ble.modifiers.bat.enable
                        })
                        */
                        return seedPod;
                    })
                }
            });
        }
    }

    const onRestartSystem = ({ id, event, data }) => {
        closeLayer(layerID);
        utils.alert.show({
            title: 'Restarting',
            message: 'SeedPod is restarting and will now close. The restart process can take up to a minute to complete'
        });
    }

    // Software updates
    const runSoftwareUpdate = () => {
        utils.alert.show({
            title: `Software Update`,
            message: `We'll have SeedPod check if there are new software updates available. We'll automatically run the update if one is available. Please check that SeedPod has a wifi network added and that you are near that wifi network before moving on`,
            buttons: [{
                key: 'confirm',
                title: `Run Update`,
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onPress: (key) => {
                if(key === 'confirm') {
                    startSoftwareUpdate();
                }
            }
        })
    }

    const startSoftwareUpdate = () => {
        setBleTimeout(Ble.events.softwareUpdate, 120);
        /*
        utils.bluetooth.get().write(seedPod.system, {
            id: seedPod.id,
            event: Ble.events.softwareUpdate
        })
        */
        setSeedPod(seedPod => {
            seedPod.bat = false;
            seedPod.tcp = false;
            return seedPod;
        })
    }

    const onSoftwareUpdate = ({ id, event, data }) => {
        try {
            if(!data) {
                throw new Error('An unknown error occurred');
                return;
            }
            invalidateBleTimeout(event);
            utils.alert.show({
                title: 'All Done!',
                message: `Software update is complete. SeedPod needs to restart before the update can take effect.`,
                buttons: [{
                    key: 'confirm',
                    title: 'Restart',
                    style: 'default'
                }],
                onPress: (key) => {
                    if(key === 'confirm') {
                        setSeedPod(seedPod => {
                            /*
                            utils.bluetooth.get().write(seedPod.system, {
                                id: seedPod.id,
                                event: Ble.events.restartNode
                            });
                            */
                            return seedPod;
                        })
                        utils.alert.show({
                            title: 'Restarting',
                            message: 'SeedPod is restarting and will now close. The restart process can take up to a minute to complete',
                            onPress: () => closeLayer(layerID)
                        });
                    }
                }
            });

        } catch(e) {
            invalidateBleTimeout(event);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue running the software update. Please check that you have added a wifi network to SeedPod and are within range of SeedPod and the wifi network`,
                buttons: [{
                    key: 'confirm',
                    title: 'Try Again',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Dismiss',
                    style: 'cancel'
                }],
                onPress: (key) => {
                    if(key === 'confirm') {
                        startSoftwareUpdate();
                        return;
                    }

                    // Reconnect to BAT and TCP
                    setSeedPod(seedPod => {
                        /*write(seedPod.system, {
                            id: seedPod.id,
                            event: Ble.events.onBATChange,
                            data: Ble.modifiers.bat.enable
                        })
                        */
                        return seedPod;
                    })
                }
            });
        }
    }

    // Content
    const getSensorDescription = (sensor) => {
        if(sensor.reading) {
            return sensor.reading
        }
        if(sensor.tcp) {
            return 'Connected to SeedPod';
        }
        if(sensor.bat) {
            return 'Searching for SeedPod...';
        }
        return 'Waiting for connection...';
    }

    const getSensorComponent = sensor => {
        return (
            <View key={sensor.id}>
                <Map
                utils={utils}
                isScrollEnabled={true}
                isZoomEnabled={true}
                isRotationEnabled={true}
                clusters={sensor.clusters}
                style={{
                    height: 150,
                    overflow: 'hidden',
                    borderTopRightRadius: 10,
                    borderTopLeftRadius: 10,
                    borderBottomWidth: 1,
                    borderBottomColor: Appearance.colors.divider()
                }} />
                {Views.entry({
                    title: sensor.name,
                    subTitle: getSensorDescription(sensor),
                    propStyles: {
                        subTitle: {
                            numberOfLines: 2
                        }
                    },
                    icon: () => {
                        switch(sensor.type) {
                            case Ble.devices.aq:
                            return (
                                <View style={{
                                    ...Appearance.icons.vehicle(),
                                    padding: 2
                                }}>
                                    {fanSource
                                        ?
                                        <LottieView
                                        ref={airQualityLottie}
                                        autoPlay={true}
                                        loop={true}
                                        source={fanSource}
                                        duration={2500}
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}/>
                                        :
                                        null
                                    }
                                </View>
                            )
                        }
                    },
                    onPress: onSensorPress.bind(this, sensor)
                })}
            </View>
        )
    }
    const getContent = () => {

        // Connected
        if(seedPod) {
            return (
                <View>
                    <View style={{
                        alignItems: 'center',
                        paddingTop: 15
                    }}>
                        <LottieView
                        ref={seedPodLottie}
                        autoPlay={false}
                        loop={true}
                        source={require('eCarra/files/lottie/seedpod-pulse.json')}
                        duration={2500}
                        style={{
                            width: '100%',
                            height: 165
                        }}/>
                    </View>

                    <View style={{
                        position: 'relative'
                    }}>
                        <ScrollView
                        showsVerticalScrollIndicator={false}
                        style={{
                            maxHeight: Screen.height() / 2,
                            width: '100%',
                            marginBottom: 15
                        }}
                        contentContainerStyle={{
                            padding: 15
                        }}>
                            <HeaderWithButton
                            label={'Sensors'}
                            button={'refresh'}
                            onPress={onSearchPeripherals}
                            style={{
                                marginBottom: 4
                            }}/>
                            <View style={{
                                ...Appearance.styles.panel(),
                                marginBottom: 20
                            }}>
                                {sensors && sensors.length > 0
                                    ?
                                    sensors.map((sensor, index) => getSensorComponent(sensor))
                                    :
                                    Views.entry({
                                        title: 'No Sensors Found',
                                        subTitle: 'Tap the refresh button to check again',
                                        bottomBorder: false,
                                        icon: {
                                            path: Utils.convertWirelessSignal().icon,
                                            style: Appearance.icons.vehicle(),
                                            imageStyle: {
                                                resizeMode: 'contain'
                                            }
                                        }
                                    })
                                }
                            </View>

                            <HeaderWithButton
                            label={'Nearby Sensors'}
                            button={'refresh'}
                            onPress={onSearchPeripherals}
                            style={{
                                marginBottom: 4
                            }}/>
                            <View style={{
                                ...Appearance.styles.panel(),
                                marginBottom: 20
                            }}>
                                {nearbyPeripherals && nearbyPeripherals.length > 0
                                    ?
                                    nearbyPeripherals.map((peripheral, index) => {
                                        return (
                                            Views.entry({
                                                key: index,
                                                title: peripheral.name,
                                                subTitle: peripheral.connecting ? 'Connecting to SeedPod...' : 'Not Connected',
                                                propStyles: {
                                                    subTitle: {
                                                        numberOfLines: 2
                                                    }
                                                },
                                                onPress: onAddPeripheral.bind(this, peripheral),
                                                icon: {
                                                    path: Utils.convertWirelessSignal(peripheral.signal).icon,
                                                    style: Appearance.icons.vehicle(),
                                                    imageStyle: {
                                                        resizeMode: 'contain'
                                                    }
                                                }
                                            })
                                        )
                                    })
                                    :
                                    Views.entry({
                                        title: 'No Nearby Sensors Found',
                                        subTitle: 'Tap the refresh button to search for sensors',
                                        bottomBorder: false,
                                        icon: {
                                            path: Utils.convertWirelessSignal().icon,
                                            style: Appearance.icons.vehicle(),
                                            imageStyle: {
                                                resizeMode: 'contain'
                                            }
                                        }
                                    })
                                }
                            </View>

                            <HeaderWithButton
                            label={'Wifi Networks'}
                            button={'new'}
                            onPress={onNewWifiNetwork}
                            style={{
                                marginBottom: 4
                            }} />
                            <View style={Appearance.styles.panel()}>
                                {networks && networks.length > 0
                                    ?
                                    networks.map((network, index) => {
                                        return (
                                            Views.entry({
                                                key: index,
                                                title: network.ssid === 'Chateau' ? 'eCarra' : network.ssid,
                                                subTitle: network.connected === null ? 'Waiting...' : (network.connected ? 'Connected' : 'Not Connected'),
                                                bottomBorder: index !== networks.length - 1,
                                                onPress: onNetworkPress.bind(this, network),
                                                icon: {
                                                    path: Utils.convertWirelessSignal(network.connected ? 'full' : null, 'wifi').icon,
                                                    style: Appearance.icons.vehicle(),
                                                    imageStyle: {
                                                        resizeMode: 'contain'
                                                    }
                                                }
                                            })
                                        )
                                    })
                                    :
                                    Views.entry({
                                        title: 'No Networks Found',
                                        subTitle: 'Tap to setup a wifi network',
                                        bottomBorder: true,
                                        icon: {
                                            path: Utils.convertWirelessSignal(null, 'wifi').icon,
                                            style: Appearance.icons.vehicle(),
                                            imageStyle: {
                                                resizeMode: 'contain'
                                            }
                                        }
                                    })
                                }
                            </View>
                        </ScrollView>

                        <LinearGradient
                        colors={[Appearance.colors.layerBackground(), Appearance.layerBackgroundColor(0)]}
                        style={{
                            height: 25,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0
                        }}/>
                    </View>
                </View>
            )
        }

        // Searching
        if(searching) {
            return (
                <View>
                    <View style={{
                        alignItems: 'center',
                        padding: 15
                    }}>
                        <LottieView
                        autoPlay={true}
                        loop={true}
                        source={require('eCarra/files/lottie/seedpod-pulse.json')}
                        duration={2500}
                        style={{
                            width: '100%',
                            height: 175
                        }}/>
                    </View>

                    <View style={{
                        padding: 15,
                        paddingTop: 0
                    }}>
                        <View style={Appearance.styles.panel()}>
                            <View style={{
                                paddingHorizontal: 12,
                                paddingVertical: 10,
                                backgroundColor: Appearance.colors.divider(),
                                borderTopLeftRadius: 10,
                                borderTopRightRadius: 10,
                                overflow: 'hidden'
                            }}>
                                <Text style={Appearance.textStyles.title()}>{'Nearby SeedPods'}</Text>
                            </View>
                            <ScrollView showsVerticalScrollIndicator={false}
                            style={{
                                maxHeight: Screen.height() / 2,
                                width: '100%'
                            }}>
                                {seedPods && seedPods.length > 0
                                    ?
                                    seedPods.map((seedPod, index) => {
                                        return (
                                            Views.entry({
                                                key: index,
                                                title: seedPod.name,
                                                subTitle: seedPod.connecting ? 'Connecting...' : 'Not Connected',
                                                bottomBorder: index !== seedPods.length - 1,
                                                onPress: onConnectPress.bind(this, seedPod),
                                                icon: {
                                                    path: Utils.convertWirelessSignal(seedPod.signal).icon,
                                                    style: Appearance.icons.vehicle(),
                                                    imageStyle: {
                                                        resizeMode: 'contain',
                                                        tintColor: Appearance.colors.grey()
                                                    }
                                                }
                                            })
                                        )
                                    })
                                    :
                                    Views.entry({
                                        title: 'Searching...',
                                        bottomBorder: false,
                                        icon: {
                                            path: require('eCarra/images/search-icon-small.png'),
                                            style: {
                                                ...Appearance.icons.vehicle(),
                                                padding: 8,
                                                resizeMode: 'contain'
                                            }
                                        }
                                    })
                                }
                            </ScrollView>
                        </View>
                    </View>
                </View>
            )
        }
    }

    const getToolbarContent = () => {

        if(!seedPod) {
            return null;
        }

        if(loading && typeof(loading) === 'object' && (
            loading.event === Ble.modifiers.bat.enable || loading.event === Ble.modifiers.bat.disable
        )) {
            console.log('loading');
            return null;
        }

        return (
            <View style={{
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                {seedPod.tcp
                    ?
                    <Image source={require('eCarra/images/seedpod-tcp.png')} style={{
                        height: 20,
                        width: 20,
                        marginHorizontal: 4,
                        resizeMode: 'contain',
                        tintColor: Appearance.colors.primary()
                    }} />
                    :
                    null
                }
                {seedPod.bat
                    ?
                    <Image source={require('eCarra/images/seedpod-bat.png')} style={{
                        height: 20,
                        width: 22,
                        marginHorizontal: 4,
                        resizeMode: 'contain',
                        tintColor: Appearance.colors.primary()
                    }} />
                    :
                    null
                }
                {seedPod.network
                    ?
                    <Image source={require('eCarra/images/seedpod-wifi.png')} style={{
                        height: 20,
                        width: 27.5,
                        marginHorizontal: 4,
                        resizeMode: 'contain',
                        tintColor: Appearance.colors.primary()
                    }} />
                    :
                    null
                }
            </View>
        )
    }

    // Effects
    useEffect(() => {
        // Move pending peripherals to nearby if not already added
        pendingPeripherals.forEach(peripheral => {
            if(!sensors.find(s => {
                return s.id === peripheral.id
            }) && !nearbyPeripherals.find(p => {
                return p.id === peripheral.id
            })) {
                setNearbyPeripherals(nearbyPeripherals => {
                    nearbyPeripherals.push(peripheral);
                    return nearbyPeripherals;
                })
            }
        })
    }, [pendingPeripherals]);

    useEffect(() => {
        // Remove sensor from nearby list if connected
        setNearbyPeripherals(nearbyPeripherals => {
            return nearbyPeripherals.filter(newPeripheral => {
                return (sensors || []).find(sensor => sensor.id !== newPeripheral.id)
            })
        })
        // Update ref for sensors
        sensorsRef.current = sensors;
    }, [sensors])

    useEffect(() => {
        // Seedpod tcp lottie animation changes
        if(seedPod && seedPodLottie.current) {
            seedPodLottie.current[seedPod.tcp ? 'play' : 'reset']();
        }
        // Update ref for SeedPod
        seedPodRef.current = seedPod;
    }, [seedPod])

    useEffect(() => {
        // Check for previous sensor pairings
        // When saved uuids are retrieved from seedpod
        // When nearby sensors are found
        pairPreviousSensors();
    }, [pendingPeripherals, sensorUUIDs]);

    useEffect(() => {

        Animated.spring(maxHeight, {
            toValue: floatingLayerState === 'open' ? (Screen.height() - Screen.safeArea.top) : (50 + Screen.safeArea.bottom),
            duration: 500,
            friction: 10,
            useNativeDriver: false
        }).start();

    }, [floatingLayerState])

    useEffect(() => {

        let color = Appearance.themeStyle() === 'dark' ? '#FFFFFF' : Appearance.colors.grey();
        let source = Utils.replaceLottieColor(FanSource, '1,1,1', color);
        source = Utils.replaceLottieColor(source, '1,1,1', color);
        source = Utils.replaceLottieColor(source, '1,1,1', color);
        source = Utils.replaceLottieColor(source, '1,1,1', color);
        setFanSource(source);

        // Bluetooth entry
        /*
        utils.bluetooth.get().subscribe(layerID, {
            onDiscoverSensor: peripheral => {
                setPendingPeripherals(update(pendingPeripherals, {
                    $push: [peripheral]
                }))
            },
            onDiscoverSeedPod: async peripheral => {

                // Auto pair to previously connected device
                if(peripheral.connected) {
                    try {
                        await readCharacteristics(peripheral);
                        setSearching(false);
                        setSeedPod(peripheral);

                    } catch(e) {
                        console.error(e.message);
                        utils.alert.show({
                            title: 'Oops!',
                            message: `We were unable to retrieve information from SeedPod. Check that you within range of SeedPod`
                        });
                    }
                    return;
                }

                // Add to list of connectable devices
                setSeedPods(seedPods => {
                    if(!seedPods.find(seedPod => seedPod.id === peripheral.id)) {
                        return seedPods.concat([peripheral])
                    }
                    return seedPods;
                })
            },
            onStop: response => {
                if(seedPod && seedPod.device.id === response.id) {
                    setLayerState('close');
                    utils.alert.show({
                        title: 'Disconnected',
                        message: 'Your device is no longer connected to SeedPod'
                    });
                    return;
                }
            }
        }).start();
        */

        setTimeout(() => {
            setFloatingLayerState('open');
        }, 500);

        return () => {
            subscriptions.map(subscription => subscription.remove());
            //utils.bluetooth.get().unsubscribe(layerID);
        }

    }, []);

    return (
        <Animated.View style={{
            width: '100%',
            maxHeight: maxHeight,
            backgroundColor: Appearance.colors.layerBackground(),
            paddingBottom: 15 + Screen.safeArea.bottom
        }}>
            <View style={{
                minHeight: LayerToolbarHeight + (floatingLayerState === 'open' ? 0 : Screen.safeArea.bottom)
            }}>
                <LayerToolbar
                title={floatingLayerState === 'open' ? null : 'SeedPod'}
                onClose={() => closeLayer(layerID)}
                floatingLayerState={floatingLayerState}
                onFloatingLayerStateChange={props => {
                    Keyboard.dismiss();
                    if(typeof(setFloatingLayerState) === 'function') {
                        setFloatingLayerState(props);
                    }
                }}
                middleContent={getToolbarContent()}
                style={{
                    paddingBottom: floatingLayerState === 'open' ? 0 : Screen.safeArea.bottom
                }}/>
            </View>
            <View style={{
                position: 'relative',
                width: '100%',
                height: 2
            }}>

                {loading && typeof(loading) === 'object' && loading.progress
                    ?
                    <ProgressBar
                    animate={true}
                    progress={loading.progress} />
                    :
                    <ProgressBar animate={loading} />
                }
            </View>
            {getContent()}
            {seedPod
                ?
                <View style={{
                    flexDirection: 'row',
                    width: '100%',
                    paddingHorizontal: 15
                }}>
                    <View style={{
                        width: '50%',
                        paddingRight: 4
                    }}>
                        <Button
                        label={'Settings'}
                        color={Appearance.colors.grey()}
                        onPress={onSettingsPress}/>
                    </View>
                    <View style={{
                        width: '50%',
                        paddingLeft: 4
                    }}>
                        <Button
                        label={'Disconnect'}
                        color={Appearance.colors.red}
                        onPress={onDisconnectPress}/>
                    </View>
                </View>
                :
                null
            }
        </Animated.View>
    )
}
