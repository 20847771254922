import React, { useRef, useState, useEffect } from 'react';
import { Animated, Easing, Image, Keyboard, Platform, ScrollView, Share, Text, TouchableWithoutFeedback, View } from 'react-native';

import moment from 'moment';
import update from 'immutability-helper';

import API from 'eCarra/files/api.js';
import Abstract from 'eCarra/classes/Abstract.js';
import AddNewCard from 'eCarra/views/AddNewCard.js';
import Appearance from 'eCarra/styles/Appearance.js';
import Button from 'eCarra/views/Button.js';
import Calendar from 'eCarra/views/Calendar.js';
import DatePicker from 'eCarra/views/DatePicker/';
import DirectionalButtons from 'eCarra/views/DirectionalButtons.js';
import Driver from 'eCarra/classes/Driver.js';
import DropdownHeader from 'eCarra/views/DropdownHeader.js';
import HeaderWithButton from 'eCarra/views/HeaderWithButton.js';
import Layer, { FloatingLayerMinimizedHeight, LayerItem, LayerShell, LayerHeaderSpacing } from 'eCarra/structure/Layer.js';
import LottieView from 'eCarra/views/Lottie/';
import { Map, Annotation } from 'eCarra/views/Maps/';
import Panel from 'eCarra/structure/Panel.js';
import Payment from 'eCarra/classes/Payment.js';
import PromoCodeLookup from 'eCarra/views/PromoCodeLookup.js';
import Request from 'eCarra/files/Request/';
import RNFetchBlob from 'eCarra/files/FetchBlob/';
import Screen from 'eCarra/files/Screen.js';
import Service from 'eCarra/classes/Service.js';
import Stripe from 'eCarra/files/Stripe/';
import Subscription from 'eCarra/classes/Subscription.js';
import Svg, { G, Rect, Use, Defs, Mask, Polygon } from 'react-native-svg';
import TextField from 'eCarra/views/TextField.js';
import TouchableHighlight from 'eCarra/views/TouchableHighlight/';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import Utils, { useLoading } from 'eCarra/files/Utils.js';
import Vehicle from 'eCarra/classes/Vehicle.js';
import Views from 'eCarra/views/Main.js';

const PaymentMethodAnimation = require('eCarra/files/lottie/payment-method.json');

export const fetchPaymentLineItems = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { line_items } = await Request.post(utils, '/payment/', {
                type: 'line_items',
                ...props
            });
            resolve(line_items);
        } catch(e) {
            reject(e);
        }
    })
}

export const fetchSubscriptionLineItems = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { line_items } = await Request.get(utils, '/subscription/', {
                type: 'line_items',
                ...props
            });
            resolve(line_items);
        } catch(e) {
            reject(e);
        }
    })
}

// Panels
export const PaymentMethods = ({ utils }) => {

    const panelID = 'payment_methods';
    const cardSpacing = 42;
    const itemHeight = 45;

    const width = Platform.OS === 'web' || Utils.isMobile() === false ? Screen.panel.maxWidth() / 3 : Screen.panel.maxWidth() - 30;
    const height = width / 1.68;

    const [cardsHeight, setCardsHeight] = useState(new Animated.Value(height));
    const [expanded, setExpanded] = useState(null);
    const [loading, setLoading] = useState(false);
    const [methods, setMethods] = useState([]);
    const [payments, setPayments] = useState([]);
    const [placeholderCard, setPlaceholderCard] = useState({
        opacity: new Animated.Value(1)
    });
    const [primarySource, setPrimarySource] = useState(null);
    const [secondarySource, setSecondarySource] = useState(null);

    const options = [{
        key: 'default',
        title: 'Set as Default',
        icon: require('eCarra/images/checkmark-green-small.png')
    },{
        key: 'invoice',
        title: 'View Payment History',
        icon: require('eCarra/images/download-grey-small.png')
    },{
        key: 'remove',
        title: 'Remove from Account',
        icon: require('eCarra/images/trash-red-small.png')
    }];

    const onAddNewCard = () => {
        utils.layer.open({
            id: 'add-new-card',
            Component: AddNewCard.bind(this, {
                onAddCard: fetchMethods
            })
        })
    }

    const onMethodPress = (method, index) => {

        // present method options if platform is web or device is not mobile
        if(Platform.OS === 'web' || Utils.isMobile() === false) {
            utils.sheet.show({
                title: method.type(),
                message: method.subType(),
                items: getOptions(method).map(option => ({
                    ...option,
                    style: option.key === 'remove' ? 'destructive' : 'default'
                }))
            }, key => {
                if(key !== 'cancel') {
                    onOptionPress(method, getOptions(method).find(option => option.key === key));
                    return;
                }
            })
            return;
        }

        // fallback to mobile-only animations
        let expandedID = expanded === method.id ? null : method.id;
        setExpanded(expandedID);

        // move other cards out of view
        methods.forEach((method, index) => {
            Animated.timing(method.animations.opacity, {
                toValue: expandedID ? 0:1,
                duration: 250,
                useNativeDriver: false
            }).start();
            Animated.timing(method.animations.top, {
                easing: Easing.bezier(0.42, 0, 0.58, 1),
                toValue: expandedID ? (index > index ? Screen.height() : -Screen.height()) : (method.index * cardSpacing),
                duration: 500,
                useNativeDriver: false
            }).start();
        });

        // move selected card into position
        let selected_method = methods.find(prev_method => prev_method.id === method.id);
        const setOptions = (delay) => {
            setTimeout(() => {
                Animated.spring(method.animations.buttons.height, {
                    friction: 10,
                    toValue: expandedID ? (itemHeight * getOptions(selected_method).length) : 0,
                    duration: 250,
                    useNativeDriver: false
                }).start();
            }, delay || 0);
        }

        // hide before card close if no longer expanded. delay if card is in the front
        if(!expandedID) {
            setOptions(index === methods.length - 1 ? 0 : 100);
        }

        Animated.timing(selected_method.animations.opacity, {
            toValue: 1,
            duration: 250,
            useNativeDriver: false
        }).start();
        Animated.timing(cardsHeight, {
            easing: Easing.bezier(0.42, 0, 0.58, 1),
            toValue: expandedID ? (height + itemHeight * getOptions(selected_method).length) : (height + (cardSpacing * (methods.length - 1))),
            duration: 500,
            useNativeDriver: false
        }).start();
        Animated.timing(selected_method.animations.top, {
            easing: Easing.bezier(0.42, 0, 0.58, 1),
            toValue: expandedID ? 0 : (selected_method.index * cardSpacing),
            duration: 500,
            useNativeDriver: false
        }).start();

        // show after card open if now expanded
        if(expandedID) {
            setOptions(200);
        }
    }

    const onOptionPress = (method, { key, title }) => {

        if(key === 'default') {
            utils.alert.show({
                title: 'Default Payment Method',
                message: 'Setting your preferred payment method helps us expedite your booking process. Changing your default payment method will not effect previous Orders or Reservations where you selected a specific payment method to be used.',
                buttons: [{
                    key: 'confirm',
                    title: 'Set as Default',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Maybe Later',
                    style: 'cancel'
                }],
                onPress: (key) => {
                    if(key === 'confirm') {
                        onSetAsDefault(method);
                    }
                }
            })
            return;
        }
        if(key === 'invoice') {

            utils.layer.open({
                id: `payment-invoice-${method.id}`,
                Component: PaymentMethodInvoice.bind(this, {
                    method: method
                })
            })
            return;
        }

        if(key === 'remove') {

            if(methods.length <= 1) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: 'It looks like you only have one payment method setup for your account. Please add another payment method if you wish to remove this one.'
                });
                return;
            }

            utils.alert.show({
                title: title,
                message: 'Are you sure that you want to remove this card from your account?',
                buttons: [{
                    key: 'confirm',
                    title: 'Remove',
                    style: 'destructive'
                },{
                    key: 'cancel',
                    title: 'Do Not Remove',
                    style: 'default'
                }],
                onPress: (key) => {
                    if(key === 'confirm') {
                        onRemoveCard(method);
                    }
                }
            })
        }
    }

    const onSetAsDefault = async method => {

        try {
            setLoading(method.id);
            await method.setAsDefault(utils);

            setLoading(false);
            let nextMethods = methods.map(prevMethod => {
                prevMethod.default = prevMethod.id === method.id;
                return prevMethod;
            })
            setMethods(prevMethods => update(prevMethods, {
                $set: onSetMethodAnimations(nextMethods)
            }));
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting this payment method as your default payment method. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onRemoveCard = async method => {
        try {
            setLoading(method.id);
            await method.removeMethod(utils);

            setLoading(false);
            let nextMethods = methods.filter(prevMethod => prevMethod.id !== method.id);
            setMethods(prevMethods => update(prevMethods, {
                $set: onSetMethodAnimations(nextMethods)
            }));
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing your card. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onRunMethodAnimations = async () => {

        // loop through each payment method and animate position
        methods.forEach((method, index) => {
            Animated.timing(method.animations.top, {
                easing: Easing.bezier(0.42, 0, 0.58, 1),
                toValue: index * cardSpacing,
                duration: 500,
                delay: index * 50,
                useNativeDriver: false
            }).start();
        })

        await Utils.sleep(0.3);
        Animated.spring(placeholderCard.opacity, {
            toValue: 0,
            duration: 500,
            friction: 10,
            useNativeDriver: false
        }).start();
        Animated.spring(cardsHeight, {
            toValue: height + (cardSpacing * (methods.length - 1)),
            duration: 500,
            friction: 10,
            useNativeDriver: false
        }).start(() => {
            setLoading(false);
        });
    }

    const onSetMethodAnimations = methods => {
        let nextMethods = methods.sort((a, b) => a.default ? 1:-1).map((method, index) => {
            method.index = index;
            method.animations = {
                opacity: new Animated.Value(1),
                top: new Animated.Value(-Screen.height() / 2),
                buttons: {
                    height: new Animated.Value(0)
                }
            }
            return method;
        })
        return nextMethods; // force animation update
    }

    const getContent = () => {
        if(Platform.OS === 'web' || Utils.isMobile() === false) {
            if(loading === true) {
                return (
                    <View style={{
                        ...Appearance.styles.panel(),
                        width: '100%'
                    }}>
                        {Views.loader()}
                    </View>
                )
            }
            if(methods.length === 0) {
                return (
                    <View style={{
                        ...Appearance.styles.panel(),
                        width: '100%'
                    }}>
                        {Views.entry({
                            title: 'No Payments Methods Found',
                            subTitle: `Tap to add your first payment method`,
                            hideIcon: true,
                            bottomBorder: false,
                            onPress: onAddNewCard
                        })}
                    </View>
                )
            }
            return (
                <View style={{
                    ...Appearance.styles.panel(),
                    width: '100%'
                }}>
                    {methods.map((method, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: method.type(),
                                subTitle: method.subType(),
                                hideIcon: true,
                                bottomBorder: index !== methods.length - 1,
                                onPress: onMethodPress.bind(this, method, index)
                            })
                        )
                    })}
                </View>
            )
        }

        return (
            <Animated.View style={{
                height: cardsHeight,
            }}>
                {getPlaceholderCard({ position: 'absolute' })}
                {methods.map((method, index) => {
                    return getPaymentMethodComponent(method, index, {
                        ...method.animations,
                        position: 'absolute'
                    });
                })}
            </Animated.View>
        )
    }

    const getOptions = method => {
        return options.filter(o => o.key === 'default' ? method.default !== true : true)
    }

    const getPaymentMethodAnimation = (method, index) => {
        if(!primarySource || !secondarySource) {
            console.log('no source');
            return;
        }
        return (
            <LottieView
            loop={false}
            autoPlay={true}
            resizeMode={'cover'}
            source={methods.length === 1 || method.index % 2 !== 0 ? primarySource : secondarySource}
            duration={2500}
            style={{
                width: width,
                height: height
            }}/>
        )
    }

    const getPaymentMethodComponent = (method, index, props) => {
        return (
            <Animated.View
            key={index}
            style={{
                ...props,
                width: width,
                height: height,
                ...Appearance.boxShadow({
                    radius: 10,
                    opacity: 0.2,
                    offset: {
                        width: 5,
                        height: 5
                    }
                })
            }}>
                <View style={{
                    ...Appearance.styles.panel(),
                    borderWidth: 2.5,
                    borderColor: Appearance.themeStyle() === 'dark' ? 'rgba(25,25,25,1)' : 'white',
                    borderRadius: 12,
                    overflow: 'hidden'
                }}>
                    <TouchableOpacity
                    activeOpacity={1}
                    onPress={onMethodPress.bind(this, method, index)}
                    style={{
                        width: width,
                        height: height
                    }}>
                        <View style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%'
                        }}>
                            {getPaymentMethodAnimation(method, index)}
                            <Text style={{
                                ...Appearance.textStyles.panelTitle(),
                                position: 'absolute',
                                left: 15,
                                top: 10,
                                fontSize: 16,
                                color: 'white',
                                zIndex: 5500 + (index * 25)
                            }}>{method.brand}</Text>
                            <Text style={{
                                ...Appearance.textStyles.panelTitle(),
                                position: 'absolute',
                                right: 15,
                                bottom: 10,
                                fontSize: 16,
                                color: 'white',
                                zIndex: 5500 + (index * 25)
                            }}>{method.last4}</Text>
                            {loading === method.id && (
                                <LottieView
                                autoPlay={true}
                                loop={true}
                                source={require('eCarra/files/lottie/dots-white.json')}
                                style={{
                                    position: 'absolute',
                                    width: 50,
                                    height: 50
                                }} />
                            )}
                        </View>
                    </TouchableOpacity>

                    {/* card options */}
                    <Animated.View style={{
                        height: method.animations.buttons.height
                    }}>
                        {getOptions(method).map((option, index, options) => {
                            return (
                                <TouchableHighlight
                                key={index}
                                activeOpacity={0.9}
                                underlayColor={Appearance.colors.divider()}
                                onPress={onOptionPress.bind(this, method, option)}
                                style={{
                                    padding: 12,
                                    dispaly: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: itemHeight,
                                    borderBottomWidth: index !== options.length - 1 ? 1:0,
                                    borderBottomColor: Appearance.colors.divider()
                                }}>
                                    <>
                                    <View style={{
                                        width: 20,
                                        height: 20
                                    }}>
                                        <Image
                                        source={option.icon}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            resizeMode: 'cover'
                                        }}/>
                                    </View>
                                    <Text style={{
                                        ...Appearance.textStyles.title(),
                                        marginLeft: 8
                                    }}>{option.title}</Text>
                                    </>
                                </TouchableHighlight>
                            )
                        })}
                    </Animated.View>
                </View>
            </Animated.View>
        )
    }

    const getPaymentsForCard = () => {

        if(!expanded) {
            return null;
        }

        let method = methods.find(method => method.id === expanded);
        let sectioned = Object.values(payments.filter(payment => {
            if(payment.reservation) {
                let requests = payment.reservation.special_requests;
                return requests ? (requests.card_id ? requests.card_id === expanded : false) : false;
            }
            return false;
        }).reduce((object, payment, index) => {
            let date = moment(payment.date).format('YYYY-MM-DD');
            if(!object[date]) {
                object[date] = {
                    date: payment.date,
                    payments: []
                }
            }
            object[date].payments.push(payment);
            object[date].payments.sort((a, b) => a.date < b.date);
            return object;
        }, {})).sort((a, b) => a.date < b.date);

        return (
            sectioned.map((section, index) => {
                return (
                    <View key={index}>
                        <Text style={{
                            ...Appearance.textStyles.title(),
                            color: Appearance.colors.darkGrey,
                            marginTop: index === 0 ? 30:25,
                            marginBottom: 8
                        }}>{section.date.isSame(moment(), 'year') ? section.date.format(`${section.date.isSame(moment(), 'day') ? '[Today, ]' : ''}MMMM Do`) : section.date.format('MMMM Do, YYYY')}</Text>
                        {section.payments.map((payment, index, payments) => {
                            return (
                                <View
                                key={index}
                                style={{
                                    ...Appearance.styles.panel(),
                                    marginBottom: index !== payments.length - 1 ? 8:0
                                }}>
                                    {Views.entry({
                                        title: payment.reservation && payment.reservation.destination ? payment.reservation.destination.name : 'Destination Not Available',
                                        subTitle: moment(payment.date).format('MMMM Do, YYYY [at] h:mma'),
                                        bottomBorder: false,
                                        hideIcon: true
                                    })}
                                </View>
                            )
                        })}
                    </View>
                )
            })
        )
    }

    const getPlaceholderCard = style => {
        return (
            <Animated.View style={{
                ...style,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                opacity: placeholderCard.opacity,
                width: width,
                height: height
            }}>
                <View style={{
                    width: width,
                    height: height
                }}>
                    <Svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
                        <Defs>
                            <Rect id={'path-1'} x={3} y={3} width={width - 10} height={height - 10} rx={10} />
                            <Mask id={'mask-2'} maskContentUnits={'userSpaceOnUse'} maskUnits={'objectBoundingBox'} x={-3} y={-3} width={width} height={height}>
                                <Rect x={0} y={0} width={width} height={height} fill={'white'} />
                                <Use href={'#path-1'} fill={'black'} />
                            </Mask>
                            <Mask id={'mask-4'} maskContentUnits={'userSpaceOnUse'} maskUnits={'objectBoundingBox'} x={-3} y={-3} width={width} height={height}>
                                <Rect x={0} y={0} width={width} height={height} fill={'white'} />
                                <Use href={'#path-1'} fill={'black'} />
                            </Mask>
                        </Defs>
                        <G id={'Page-1'} strokeWidth={1} fillRule={'evenodd'}>
                            <Mask id={'mask-3'} fill={'white'}>
                                <Use href={'#path-1'} />
                            </Mask>
                            <G stroke={Appearance.colors.grey()} mask={'url(#mask-2)'} strokeWidth={6} strokeDasharray={'10,9'}>
                                <Use mask={'url(#mask-4)'} href={'#path-1'} />
                            </G>
                        </G>
                    </Svg>
                </View>
                {loading === true && Views.loader({ position: 'absolute' })}
                {loading === false && methods.length === 0 && (
                    <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={onAddNewCard}
                    style={{
                        position: 'absolute',
                        top: 12.5,
                        left: 12.5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: width - 30,
                        height: height - 30,
                        borderRadius: 10,
                        overflow: 'hidden',
                        backgroundColor: Appearance.colors.panelBackground()
                    }}>
                        <Image
                        source={require('eCarra/images/payment-icon-clear-small.png')}
                        style={{
                            width: 50,
                            height: 50,
                            backgroundColor: Appearance.colors.grey(),
                            marginBottom: 8,
                            borderRadius: 25,
                            overflow: 'hidden'
                        }} />
                        <Text style={{
                            ...Appearance.textStyles.title(),
                            color: Appearance.colors.subText()
                        }}>{'Tap to Add Card'}</Text>
                    </TouchableOpacity>
                )}
            </Animated.View>
        )
    }

    const fetchMethods = async () => {
        try {
            setLoading(true);
            let { methods } = await utils.user.get().getPaymentMethods(utils);

            setLoading(false);
            setMethods(onSetMethodAnimations(methods));
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your payment methods. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchPayments = async () => {
        try {
            let { payments } = await Request.get(utils, '/payments/', {
                type: 'all'
            });
            setPayments(payments.map(payment => Payment.create(payment)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your payments. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {

        // setup source animations
        let primary = Utils.replaceLottieColor(PaymentMethodAnimation, '0.423529411765,0.749019607843,0.4', Appearance.colors.primary());
        primary = Utils.replaceLottieColor(primary, '0.341176470588,0.674509803922,0.317647058824', Appearance.colors.secondary());
        setPrimarySource(Utils.replaceLottieColor(primary, '0.529411764706,0.854901960784,0.505882352941', Appearance.colors.tertiary()));

        let secondary = Utils.replaceLottieColor(PaymentMethodAnimation, '0.423529411765,0.749019607843,0.4', Utils.changeHue(Appearance.colors.primary(), 25));
        secondary = Utils.replaceLottieColor(secondary, '0.341176470588,0.674509803922,0.317647058824', Utils.changeHue(Appearance.colors.secondary(), 25));
        setSecondarySource(Utils.replaceLottieColor(secondary, '0.529411764706,0.854901960784,0.505882352941', Utils.changeHue(Appearance.colors.tertiary(), 25)));

    }, []);

    useEffect(() => {
        // fetch methods after lottie animation sources are created
        if(primarySource && secondarySource) {
            fetchMethods();
            fetchPayments();
        }
    }, [primarySource, secondarySource])

    useEffect(() => {
        if(!methods || methods.length === 0) {
            return;
        }
        onRunMethodAnimations();
    }, [methods, primarySource, secondarySource]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        header={(
            <HeaderWithButton
            label={'My Cards'}
            button={'new'}
            onPress={onAddNewCard} />
        )}
        style={{
            marginBottom: 12
        }}
        options={{
            removeOverflow: true,
            shouldStyle: false
        }}>
            {getContent()}
        </Panel>
    )
}

export const PaymentsCalendar = ({ utils }) => {

    const panelID = 'paymentsCalendar';
    const [loading, setLoading] = useState(true);
    const [payments, setPayments] = useState([]);
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

    const onChooseDate = () => {

        let tempDate = null;
        utils.layer.open({
            id: 'calendar-custom-date',
            Component: LayerShell.bind(this, {
                layerID: 'calendar-custom-date',
                extendedOptions: {
                    bottomCard: true
                },
                buttons: [{
                    key: 'done',
                    text: 'Done',
                    color: 'primary',
                    onPress: () => {
                        utils.events.emit('onLayerAction', {
                            action: 'close',
                            layerID: 'calendar-custom-date'
                        })
                        setDate(tempDate ? moment(tempDate).format('YYYY-MM-DD') : date)
                    }
                }],
                children: (
                    <View style={{
                        alignItems: 'center',
                        marginTop: 5
                    }}>
                        <Text style={{
                            ...Appearance.textStyles.panelTitle(),
                            marginBottom: 6
                        }}>{'Browse Other Dates'}</Text>
                        <Text style={{
                            ...Appearance.textStyles.subTitle(),
                            marginBottom: 20,
                            textAlign: 'center'
                        }}>{'Choose a month and year from the list below to view more Payments'}</Text>
                        <DatePicker
                        utils={utils}
                        date={date}
                        onChange={date => tempDate = date}/>
                    </View>
                )
            })
        })
    }

    const fetchPayments = async () => {
        try {
            let { payments } = await Request.get(utils, '/payments/', {
                type: 'all'
            });
            setLoading(false);
            setPayments(payments.map(p => Payment.create(p)));
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the payments calendar. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getContent = () => {

        let targets = getPayments();
        if(Platform.OS === 'web' || Utils.isMobile() === false) {
            return (
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    paddingRight: 12
                }}>
                    <View style={{
                        ...Appearance.styles.panel(),
                        flexGrow: 1,
                        width: '50%',
                        paddingTop: 12
                    }}>
                        <Calendar
                        activeDate={date}
                        events={payments}
                        onDateChange={date => setDate(date.format('YYYY-MM-DD'))}
                        eventFilter={(day, payment) => day.isSame(payment.date, 'day')} />
                    </View>
                    <View style={{
                        ...Appearance.styles.panel(),
                        width: '50%',
                        marginLeft: 12,
                        marginRight: 12,
                        padding: 12
                    }}>
                        <View style={{
                            borderRadius: 10,
                            overflow: 'hidden'
                        }}>
                            {getPaymentComponents(targets)}
                        </View>
                    </View>
                </View>
            )
        }

        return (
            <>
            <View style={Appearance.styles.panel()}>
                <Calendar
                activeDate={date}
                events={payments}
                onDateChange={date => setDate(date.format('YYYY-MM-DD'))}
                eventFilter={(day, payment) => day.isSame(payment.date, 'day')} />
            </View>
            {targets.length > 0 && (
                <View style={{
                    ...Appearance.styles.panel(),
                    marginTop: 8
                }}>
                    {getPaymentComponents(targets)}
                </View>
            )}
            </>
        )
    }

    const getPayments = () => {
        return payments.filter(p => moment(date).isSame(p.date, 'day'));
    }

    const getPaymentComponents = targets => {

        // return components showing that no payments were found if platform is web or device is not mobile
        if(targets.length === 0) {
            if(Platform.OS === 'web' || Utils.isMobile() === false) {
                return (
                    Views.entry({
                        title: 'No Payments Found',
                        subTitle: `No payments were processed on ${moment(date).format('MMMM Do, YYYY')}`,
                        hideIcon: true,
                        bottomBorder: false
                    })
                )
            }
            return null;
        }

        // return all matching payments
        return targets.map((payment, index) => {
            return (
                Views.entry({
                    key: index,
                    ...payment.getTargetSummary(),
                    bottomBorder: index !== payments.length - 1,
                    hideIcon: true,
                    onPress: utils.layer.payment.details.bind(this, payment)
                })
            )
        })
    }

    useEffect(() => {
        fetchPayments();
        utils.content.subscribe(panelID, 'payments', {
            onFetch: fetchPayments,
            onUpdate: abstract => {
                setPayments(payments => {
                    return payments.map(payment => {
                        return payment.id === abstract.getID() ? abstract.object : null
                    })
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Payments Calendar'}
        header={(
            <DropdownHeader
            label={`${moment(date).isSame(moment(), 'year') ? moment(date).format('MMMM') : moment(date).format('MMMM YYYY')} Payments`}
            onPress={onChooseDate}/>
        )}
        rightContent={(
            <DirectionalButtons
            onNext={() => setDate(moment(date).add(1, 'months'))}
            onBack={() => setDate(moment(date).subtract(1, 'months'))} />
        )}
        options={{
            loading: loading,
            removeOverflow: true,
            shouldStyle: false
        }}>
            {getContent()}
        </Panel>
    )
}

export const RecentPayments = ({ utils }) => {

    const panelID = 'recentPayments';
    const [loading, setLoading] = useState(true);
    const [payments, setPayments] = useState([]);

    const getLocations = (payment) => {

        return [];

        if(payment.order) {
            return payment.order.getLocations().map((place, index) => {
                return {
                    ...place,
                    key: `location-${index}`
                }
            })
        }
        if(payment.reservation) {
            return payment.reservation.getLocations().map((place, index) => {
                return {
                    ...place,
                    key: `location-${index}`
                }
            })
        }
        return [];
    }

    const fetchPayments = async () => {
        try {
            let { payments } = await Request.get(utils, '/payments/', {
                type: 'all'
            });
            setLoading(false);
            setPayments(payments.map(payment => {
                return Payment.create(payment);
            }).sort((a, b) => {
                return moment(a.date).unix() - moment(b.date).unix() ? 1:-1;
            }).filter((payment, index) => {
                return index < 5;
            }));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your Payments. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchPayments();
        utils.content.subscribe(panelID, 'payments', {
            onFetch: fetchPayments,
            onUpdate: abstract => {
                setPayments(payments => {
                    return payments.map(payment => {
                        return payment.id === abstract.getID() ? abstract.object : null
                    })
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Recent Payments'}
        options={{
            loading: loading,
            removeOverflow: true,
            shouldStyle: false
        }}>
            <View style={Appearance.styles.panel()}>
                {payments && payments.length > 0
                    ?
                    payments.map((payment, index) => {
                        return (
                            Views.entry({
                                key: index,
                                ...payment.getTargetSummary(),
                                hideIcon: true,
                                bottomBorder: index !== payments.length - 1,
                                onPress: utils.layer.payment.details.bind(this, payment)
                            })
                        )
                    })
                    :
                    Views.entry({
                        title: 'Nothing to see here',
                        subTitle: 'No payments have been processed',
                        hideIcon: true,
                        bottomBorder: false,
                        propStyles: {
                            subTitle: {
                                numberOfLines: 2
                            }
                        }
                    })
                }
            </View>
        </Panel>
    )
}

export const SubscriptionsList = ({ utils }) => {

    const panelID = `subscriptionsList`;
    const [loading, setLoading] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);

    const onNewSubscription = () => {
        utils.layer.open({
            id: 'subscription-picker',
            Component: SubscriptionPicker
        })
    }

    const onSubscriptionPress = subscription => {
        utils.layer.subscription.details(subscription);
    }

    const fetchSubscriptions = async download => {
        try {
            let { subscriptions } = await Request.get(utils, '/subscriptions/', {
                type: 'all'
            });
            setLoading(false);
            setSubscriptions(subscriptions.map(subscription => Subscription.create(subscription)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your Subscriptions list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }
    useEffect(() => {
        fetchSubscriptions();
        utils.content.subscribe(panelID, 'subscriptions', {
            onFetch: fetchSubscriptions,
            onRemove: abstract => {
                setSubscriptions(update(subscriptions, {
                    $apply: subscriptions => subscriptions.filter(s => s.id !== abstract.getID())
                }))
            },
            onUpdate: abstract => {
                let index = (subscriptions || []).findIndex(s => s.id === abstract.getID());
                if(index >= 0) {
                    setSubscriptions(update(subscriptions, {
                        [index]: {
                            $set: abstract.object
                        }
                    }))
                }
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`Subscriptions`}
        header={(
            <HeaderWithButton
            label={'My Subscriptions'}
            button={'new'}
            onPress={onNewSubscription} />
        )}
        options={{
            loading: loading,
            removeOverflow: true,
            shouldStyle: false
        }}>
            <View style={Appearance.styles.panel()}>
                {subscriptions.length === 0
                    ?
                    Views.entry({
                        title: 'No Subscriptions Found',
                        subTitle: 'You do not have any active subscriptions',
                        hideIcon: true,
                        bottomBorder: false
                    })
                    :
                    subscriptions.map((subscription, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: subscription.plan.name,
                                subTitle: subscription.plan.description,
                                icon: {
                                    style: {
                                        ...Appearance.icons.vehicle(),
                                        backgroundColor: Appearance.colors.primary()
                                    },
                                    path: subscription.plan.image
                                },
                                badge: {
                                    text: Utils.toCurrency(subscription.plan.cost),
                                    color: Appearance.colors.grey()
                                },
                                propStyles: {
                                    subTitle: {
                                        numberOfLines: 2
                                    }
                                },
                                bottomBorder: index !== subscriptions.length - 1,
                                onPress: onSubscriptionPress.bind(this, subscription)
                            })
                        )
                    })
                }
            </View>
        </Panel>
    )
}

export const CreditsList = ({ utils }) => {

    const panelID = `creditsList`;
    const [loading, setLoading] = useState(false);
    const [credits, setCredits] = useState([]);

    const onAddCredits = () => {
        utils.sheet.show({
            title: 'Credits',
            message: `At ${utils.client.get().name}, Credits are a currency the we use to help you book Orders and Reservations without having to use your debit or credit card. Credits can be purchased in advance, redeemed through promotions, of gifted as part of a Subscription.`,
            items: [{
                key: 'buy',
                title: 'Buy Credits',
                style: 'default'
            },{
                key: 'redeem',
                title: 'Redeem Credits',
                style: 'default'
            }]
        }, async key => {
            if(key === 'buy') {

                let { credits } = await utils.user.get().getPaymentMethods(utils);
                if(credits.length <= 1) {
                    utils.layer.open({
                        id: 'buy-credits',
                        Component: BuyCredits.bind(this, {
                            method: credits.length > 0 ? credits[0] : null
                        })
                    });
                    return
                }

                // choose credits method for purchase destination if more than one method is present
                utils.sheet.show({
                    title: 'Buy Credits',
                    message: 'Where would you like to add credits?',
                    items: credits.map((credit, index) => {
                        return {
                            key: index,
                            title: credit.getType(),
                            style: 'default'
                        }
                    })
                }, key => {
                    if(key === 'cancel') {
                        return;
                    }
                    utils.layer.open({
                        id: 'buy-credits',
                        Component: BuyCredits.bind(this, {
                            method: credits[key]
                        })
                    });
                })

            } else if(key === 'redeem') {
                utils.layer.open({
                    id: 'redeem-credits',
                    Component: RedeemCredits
                })

            } else if(key === 'subscriptions') {
                utils.layer.open({
                    id: 'subscription-picker',
                    Component: SubscriptionPicker
                })
            }
        })
    }

    const onMethodPress = method => {
        utils.sheet.show({
            title: method.getType(),
            message: `Current Balance: ${Utils.toCurrency(method.balance())}`,
            items: [{
                key: 'buy',
                title: 'Buy Credits',
                style: 'default'
            },{
                key: 'view',
                title: 'View History',
                style: 'default'
            }]
        }, (key) => {
            if(key === 'buy') {
                utils.layer.open({
                    id: 'buy-credits',
                    Component: BuyCredits.bind(this, {
                        method: method
                    })
                })

            } else if(key === 'view') {
                onViewCreditsHistory(method);
            }
        })
    }

    const onViewCreditsHistory = async method => {
        try {
            let response = await Request.get(utils, '/credits/', {
                type: 'invoice',
                card_id: method.card.id,
                category: method.type,
                user_id: utils.user.get().user_id,
                stripe_id: utils.user.get().stripe_customer_id
            });
            if(!response.entries || response.entries.length === 0) {
                utils.alert.show({
                    title: 'No Payments Found',
                    message: 'We were unable to locate any history events for this credits payment method'
                });
                return;
            }

            utils.layer.open({
                id: `credits-invoice-${method.id}`,
                Component: CreditsMethodInvoice.bind(this, {
                    ...response,
                    method: method
                })
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your previous payments. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchMethods = async () => {
        try {
            let { credits } = await utils.user.get().getPaymentMethods(utils);
            setCredits(credits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your Credits. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchMethods();
        utils.content.subscribe(panelID, 'credits', {
            onFetch: fetchMethods
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`Credits`}
        header={(
            <HeaderWithButton
            label={'My Credits'}
            button={'new'}
            onPress={onAddCredits} />
        )}
        options={{
            loading: loading,
            removeOverflow: true,
            shouldStyle: false
        }}>
            <View style={Appearance.styles.panel()}>
                {credits.length === 0
                    ?
                    Views.entry({
                        title: 'No Credits Found',
                        subTitle: 'You do not have any credits available',
                        hideIcon: true,
                        bottomBorder: false
                    })
                    :
                    credits.map((method, index, methods) => {
                        return (
                            Views.entry({
                                key: index,
                                title: method.getType(),
                                subTitle: `Balance: ${Utils.toCurrency(method.balance())}`,
                                icon: {
                                    style: {
                                        ...Appearance.icons.standard,
                                        backgroundColor: Appearance.colors.primary()
                                    },
                                    path: method.company ? method.company.image : (method.balance() <= 0 ? require('eCarra/images/rejected-red-small.png') : require('eCarra/images/payment-icon-clear-small.png'))
                                },
                                badge: method.active === false ? {
                                    text: 'Not Active',
                                    color: Appearance.colors.grey()
                                } : null,
                                textStyles: {
                                    subTitle: {
                                        color: method.balance() <= 0 ? Appearance.colors.red : Appearance.colors.subText()
                                    }
                                },
                                bottomBorder: index !== methods.length - 1,
                                onPress: onMethodPress.bind(this, method)
                            })
                        )
                    })
                }
            </View>
        </Panel>
    )
}

// Layers
export const PaymentDetails = ({ abstract, index, options, utils }) => {

    const layerID = `payment-${abstract.getID()}`;
    const [annotations, setAnnotations] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [lineItems, setLineItems] = useState([]);
    const [loading, setLoading] = useLoading();
    const [overlays, setOverlays] = useState([]);
    const [payment, setPayment] = useState(abstract.object);
    const [paymentLineItems, setPaymentLineItems] = useState([]);
    const [target, setTarget] = useState(null);

    const onShareInvoice = async () => {
        try {
            await Share.share({
                url: payment.invoice_url, // ios only
                message: Platform.OS === 'ios' ? null : payment.invoice_url,  // android only
                title: Platform.OS === 'ios' ? null : 'Invoice' // android only
            });
        } catch(e) {
            console.error(e.message);
        }
    }

    const onTargetClick = () => {
        switch(target.type) {
            case 'orders':
            utils.layer.order.details(target.object);
            break;

            case 'reservations':
            utils.layer.reservation.details(target.object);
            break;

            case 'subscriptions':
            utils.layer.subscription.details(target.object);
            break;
        }
    }

    const onUpdateAnnotations = () => {
        setAnnotations(() => {
            let annotations = [{
                id: `pickup-${target.getID()}`,
                title: `Pickup Location (${target.getID()})`,
                subTitle: target.object.origin.address,
                location: target.object.origin.location,
                data: { key: `pickup-${target.getID()}` }
            }];

            if(target.object.stops && target.object.stops.locations) {
                annotations = annotations.concat(target.object.stops.locations.map((stop, index) => {
                    return {
                        id: `stop-${index}-${target.getID()}`,
                        title: `${Utils.integerToOrdinal(index + 1)} Stop (${target.getID()})`,
                        subTitle: stop.address,
                        location: stop.location,
                        icon: { type: 'grey-broadcast' },
                        data: { key: `stop-${index}-${target.getID()}` }
                    }
                }))
            }
            if(target.object.destination.address && target.object.destination.location) {
                annotations.push({
                    id: `dropoff-${target.getID()}`,
                    title: `Drop-Off Location (${target.getID()})`,
                    subTitle: target.object.destination.address,
                    location: target.object.destination.location,
                    data: { key: `dropoff-${target.getID()}` }
                })
            }
            return annotations;
        });
    }

    const onUpdateOverlays = async () => {
        if(!target) {
            return;
        }
        setOverlays(target.object.getOverlays());
    }

    const onViewInvoice = () => {
        utils.layer.webView({
            id: `payment-invoice-${payment.id}`,
            title: `Payment #${payment.id}`,
            url: payment.invoice_url,
            indentHeader: true,
            buttons: [{
                key: 'share',
                text: 'Share',
                color: 'primary',
                onPress: onShareInvoice
            }]
        });
    }

    const getButtons = () => {
        let buttons = [];
        if(payment.invoice_url) {
            buttons.push({
                key: 'invoice',
                text: 'View Invoice',
                color: target ? 'secondary' : 'primary',
                onPress: onViewInvoice
            });
        }
        if(target) {
            switch(target.type) {
                case 'orders':
                buttons.push({
                    key: 'order',
                    text: 'View Order',
                    color: 'primary',
                    onPress: onTargetClick
                });
                break;

                case 'reservations':
                buttons.push({
                    key: 'reservation',
                    text: 'View Reservation',
                    color: 'primary',
                    onPress: onTargetClick
                });
                break;

                case 'subscriptions':
                buttons.push({
                    key: 'subscription',
                    text: 'View Subscription',
                    color: 'primary',
                    onPress: onTargetClick
                });
                break;
            }
        }
        return buttons;
    }

    const getContent = () => {
        if(loading === 'init') {
            return (
                <View style={Appearance.styles.panel()}>
                    {Views.loader()}
                </View>
            )
        }
        return (
            <>
            {getOverview()}
            {getLineItems()}
            </>
        )
    }

    const getLineItems = () => {
        return lineItems.filter(lineItem => {
            return lineItem.items && lineItem.items.length > 0;
        }).map((lineItem, index) => {
            return (
                <LayerItem
                key={index}
                title={lineItem.title}>
                    {lineItem.items.map((item, index, items) => (
                        Views.entry({
                            key: index,
                            title: item.title,
                            subTitle: item.formatted || item.null_value,
                            hideIcon: true,
                            bottomBorder: index !== items.length - 1
                        })
                    ))}
                </LayerItem>
            )
        })
    }

    const getOverview = () => {
        if(!target || target.type === 'subscriptions') {
            return null;
        }
        return (
            <View style={{
                ...Appearance.styles.panel(),
                width: '100%',
                marginBottom: 25
            }}>
                <Map
                utils={utils}
                overlays={overlays}
                annotations={annotations}
                style={{
                    height: Platform.OS === 'web' || Utils.isMobile() === false ? 300 : 200,
                    width: Screen.layer.maxWidth - 24,
                    borderBottomWidth: 1,
                    borderBottomColor: Appearance.colors.divider()
                }}/>
                {getOverviewComponents()}
                {paymentLineItems && paymentLineItems.map((item, index, items) => {
                    return (
                        <View
                        key={index}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                            paddingHorizontal: 12,
                            paddingVertical: 8,
                            borderBottomWidth: 1,
                            borderBottomColor: Appearance.colors.divider()
                        }}>
                            <Text style={Appearance.textStyles.key()}>{item.title}</Text>
                            <Text style={Appearance.textStyles.value()}>{item.message}</Text>
                        </View>
                    )
                })}
            </View>
        )
    }

    const getOverviewComponents = () => {
        switch(target.type) {
            case 'orders':
            return (
                Views.entry({
                    title: target.object.destination ? (target.object.destination.name || target.object.destination.address) : 'Destination Not Selected',
                    subTitle: target.object.drop_off_date ? moment(target.object.drop_off_date).format('MMMM Do, YYYY [at] h:mma') : 'Drop Off Date Not Selected',
                    hideIcon: true,
                    textStyles: {
                        title: Appearance.textStyles.panelTitle(),
                        subTitle: {
                            ...Appearance.textStyles.title(),
                            color: Appearance.colors.subText()
                        }
                    }
                })
            )

            case 'reservations':
            return (
                Views.entry({
                    title: target.object.destination ? (target.object.destination.name || target.object.destination.address) : 'Destination Not Selected',
                    subTitle: moment(target.object.pickup_date).format('MMMM Do, YYYY [at] h:mma'),
                    hideIcon: true,
                    textStyles: {
                        title: Appearance.textStyles.panelTitle(),
                        subTitle: {
                            ...Appearance.textStyles.title(),
                            color: Appearance.colors.subText()
                        }
                    }
                })
            )

            default:
            return null;
        }
    }

    const fetchLineItems = async () => {
        try {
            // fetch payment line items
            let lineItems = await fetchPaymentLineItems(utils, { id: abstract.getID() });
            setLoading(false);
            setLineItems(lineItems);

            // fetch cost breakdown if applicable
            if(target) {
                let { breakdown, route } = await target.object.costBreakdown(utils);
                setPaymentLineItems(breakdown ? breakdown.line_items : []);

                // update overlays if route object was returned
                if(route && target.object.stops && target.object.stops.locations) {
                    let shapes = route.legs.map(leg => Utils.decodePolyline(leg.shape, 6));
                    setOverlays(shapes.map((coordinates, index) => ({
                        key: `${abstract.getTag()}-${index}`,
                        coordinates: coordinates
                    })));
                }
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the cost breakdown. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const setupTarget = () => {
        let target = null;
        if(payment.order) {
            target = Abstract.create({
                type: 'orders',
                object: payment.order
            });
        }
        if(payment.reservation) {
            target = Abstract.create({
                type: 'reservations',
                object: payment.reservation
            });
        }
        if(payment.subscription) {
            target = Abstract.create({
                type: 'subscriptions',
                object: payment.subscription
            });
        }
        setTarget(target);
    }

    useEffect(() => {
        if(!target || target.type === 'subscriptions') {
            return;
        }
        onUpdateAnnotations();
        onUpdateOverlays();
    }, [target]);

    useEffect(() => {
        fetchLineItems();
        setupTarget();
        utils.content.subscribe(layerID, 'payments', {
            onUpdate: next => {
                setPayment(abstract.compare(next));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={'Payment Details'}
        utils={utils}
        index={index}
        buttons={getButtons()}
        contentStyle={{
            paddingBottom: FloatingLayerMinimizedHeight + 15
        }}
        options={{
            ...options,
            loading: loading,
            layerState: layerState,
            removePadding: true
        }}>
            <View style={{
                paddingTop: LayerHeaderSpacing.get(),
                paddingHorizontal: 15,
                marginBottom: 25
            }}>
                {getContent()}
            </View>
        </Layer>
    )
}

export const SubscriptionDetails = ({ abstract, index, options, utils }) => {

    const layerID = `subscription-${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [lineItems, setLineItems] = useState(null);
    const [loading, setLoading] = useState(false);
    const [subscription, setSubscription] = useState(abstract.object);

    const onCancelSubscription = () => {
        utils.alert.show({
            title: 'Cancel Subscription',
            message: 'Are you sure that you want to cancel your subscription? This will effect the cost of any upcoming Orders or Reservations that you have booked with this subscription.',
            buttons: [{
                key: 'confirm',
                title: 'Cancel',
                style: 'cancel'
            },{
                key: 'cancel',
                title: 'Do Not Cancel',
                style: 'default'
            }],
            onPress: async key => {
                if(key === 'confirm') {
                    try {
                        setLoading(true);
                        await Request.post(utils, '/subscription/', {
                            type: 'cancel',
                            subscription_id: abstract.getID()
                        });

                        setLoading(false);
                        utils.content.fetch('subscriptions');
                        utils.alert.show({
                            title: 'All Done!',
                            message: `Your subscription has been cancelled`,
                            onPress: () => setLayerState('close')
                        });

                    } catch(e) {
                        setLoading(false);
                        utils.alert.show({
                            title: 'Oops!',
                            message: `There was an issue cancelling your subscription. ${e.message || 'An unknown error occurred'}`
                        });
                    }
                }
            }
        })
    }

    const fetchLineItems = async () => {
        try {
            let lineItems = await fetchSubscriptionLineItems(utils, {
                id: abstract.getID()
            });
            setLineItems(lineItems);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your subscription details. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchLineItems();
        utils.content.subscribe(layerID, 'subscriptions', {
            onUpdate: abstract => {
                if(abstract.getID() === subscription.id) {
                    setSubscription(abstract.object);
                }
            }
        })
        return () => {
            utils.content.unsubscribe(layerID);
        }

    }, []);

    return (
        <Layer
        id={layerID}
        title={'Subscription Details'}
        utils={utils}
        index={index}
        options={{
            ...options,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Cancel',
            color: 'danger',
            loading: loading,
            onPress: onCancelSubscription
        }]}>
            <View style={{
                padding: 15,
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 20
            }}>
                <View style={{
                    width: 75,
                    height: 75,
                    borderRadius: 37.5,
                    backgroundColor: Appearance.colors.primary(),
                    padding: 15,
                    overflow: 'hidden',
                    marginBottom: 12
                }}>
                    <Image
                    source={subscription.plan.image}
                    style={{
                        width: '100%',
                        height: '100%',
                        resizeMode: 'contain'
                    }}/>
                </View>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 6
                }}>{subscription.plan.name}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    textAlign: 'center'
                }}>{subscription.plan.description}</Text>
            </View>
            {lineItems && lineItems.filter(lineItem => {
                return lineItem.items && lineItem.items.length > 0;
            }).map((lineItem, index, items) => {
                return (
                    <LayerItem
                    key={index}
                    title={lineItem.title}>
                        {lineItem.items.map((item, index, items) => (
                            Views.entry({
                                key: index,
                                title: item.title,
                                subTitle: item.formatted || item.null_value,
                                hideIcon: true,
                                bottomBorder: index !== items.length - 1
                            })
                        ))}
                    </LayerItem>
                )
            })}
        </Layer>
    )
}

export const SubscriptionPicker = ({ index, options, utils }) => {

    const layerID = 'subscription-picker';
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);

    const onAddNewSubscription = async methods => {

        // Check for Stripe account
        // Prompt new card addition if not found
        if(!methods || methods.length === 0 || !utils.user.get().stripe_customer_id) {

            setLoading(false);
            utils.alert.show({
                title: 'Just a Second',
                message: 'It looks like you have not setup your payment method yet. Please add payment method before signing up for a Subscription',
                buttons: [{
                    key: 'add',
                    title: 'Add Payment Method',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Maybe Layer',
                    style: 'cancel'
                }],
                onPress: (key) => {
                    if(key === 'add') {
                        utils.layer.open({
                            id: 'add-new-card',
                            Component: AddNewCard.bind(this, {
                                onAddCard: () => {
                                    utils.alert.show({
                                        title: 'Continue with Subscription',
                                        message: 'Would you like to continue with your Subscription signup?',
                                        buttons: [{
                                            key: 'confirm',
                                            title: 'Yes',
                                            style: 'default'
                                        },{
                                            key: 'cancel',
                                            title: 'Maybe Later',
                                            style: 'cancel'
                                        }],
                                        onPress: async key => {
                                            if(key === 'confirm') {
                                                try {
                                                    setLoading(true);
                                                    let { methods } = utils.user.get().getPaymentMethods(utils); onAddNewSubscription(methods);

                                                } catch(e) {
                                                    setLoading(false);
                                                    utils.alert.show({
                                                        title: 'Oops!',
                                                        message: `There was an issue retrieving your payment methods. ${e.message || 'An unknown error occurred'}`
                                                    });
                                                }
                                            }
                                        }
                                    })
                                }
                            })
                        });
                    }
                }
            })
            return;
        }

        // Signup for subscription and process first payment
        try {
            await Request.post(utils, '/subscription/', {
                type: 'signup',
                plan_id: selected.id,
                user_id: utils.user.get().user_id
            });

            setLayerState('close');
            utils.content.fetch('subscriptions');
            utils.alert.show({
                title: 'All Done!',
                message: 'Your new Subscription has been added and is ready to use'
            });
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up your Subscription. ${e.message || 'An unknown error occurred '}`
            });
        }
    }

    const onBeforeCloseTap = callback => {
        utils.alert.show({
            title: 'Just a Second',
            message: `It looks like you aren't finishsed setting up your new Subscription. Are you sure you want to stop the signup process? Any unsaved account information will be lost.`,
            buttons: [{
                key: 'confirm',
                title: 'Leave Signup',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Continue with Signup',
                style: 'default'
            }],
            onPress: key => {
                if(key === 'confirm') {
                    if(typeof(callback) === 'function') {
                        callback();
                    }
                }
            }
        })
    }

    const onConfirmSubscription = () => {
        utils.alert.show({
            title: 'Confirm your Subscription',
            message: `"${selected.name}" will be added to your account and will become available during Order and Reservation booking. This subscription plan will be billed at ${Utils.toCurrency(selected.cost)} ${selected.billingToText(true)}. Subscription renewals are automatically processed at the end of each billing cycle using your default payment method. Subscription renewal costs are non-refundable. You can cancel your subscription at any time.`,
            buttons: [{
                key: 'confirm',
                title: 'Sign Me Up',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: 'cancel'
            }],
            onPress: async key => {
                if(key === 'confirm') {
                    // Fetch up to date payment methods
                    try {
                        setLoading(true);
                        let { methods } = await utils.user.get().getPaymentMethods(utils);
                        onAddNewSubscription(methods);

                    } catch(e) {
                        setLoading(false);
                        utils.alert.show({
                            title: 'Oops!',
                            message: `There was an issue retrieving your payment methods. ${e.message || 'An unknown error occurred'}`
                        });
                    }

                }
            }
        })
    }

    const onSubscriptionPress = subscription => {

        if(subscription.subscribed) {
            utils.alert.show({
                title: subscription.plan.name,
                message: 'You are currently subscribed to this plan. You can make changes to your subscription by tapping on the Subscription in the "My Subscriptions" list in the Payments area'
            });
            return;
        }
        setSelected(subscription.plan);
    }

    const getButtons = () => {
        if(!selected) {
            return null;
        }
        return [{
            key: 'back',
            text: 'Back',
            color: 'grey',
            onPress: () => setSelected(null)
        },{
            key: 'confirm',
            text: 'Confirm',
            color: 'primary',
            loading: loading,
            onPress: onConfirmSubscription
        }];
    }

    const getContent = () => {
        if(loading === true) {
            return (
                <View style={{
                    ...Appearance.styles.panel()
                }}>
                    {Views.loader()}
                </View>
            )
        }
        if(subscriptions.length === 0) {
            return (
                <View style={{
                    ...Appearance.styles.panel()
                }}>
                    {Views.entry({
                        title: 'No Subscriptions Found',
                        subTitle: 'There are no Subscription plans available at this time',
                        hideIcon: true,
                        bottomBorder: false,
                    })}
                </View>
            )
        }
        return subscriptions.map((subscription, index, subscriptions) => {
            return (
                <View
                key={index}
                style={{
                    ...Appearance.styles.panel(),
                    marginBottom: index !== subscriptions.length - 1 ? 8 : 0
                }}>
                    {Views.entry({
                        title: subscription.plan.name,
                        subTitle: subscription.plan.description,
                        icon: {
                            path: subscription.plan.image,
                            style: {
                                ...Appearance.icons.vehicle(),
                                backgroundColor: Appearance.colors.primary()
                            }
                        },
                        badge: subscription.subscribed ? {
                            text: 'Subscribed',
                            color: Appearance.colors.primary()
                        } : {
                            text: `${Utils.toCurrency(subscription.plan.cost)} ${subscription.plan.billingToText(true)}`,
                            color: Appearance.colors.grey()
                        },
                        bottomBorder: true,
                        onPress: onSubscriptionPress.bind(this, subscription),
                        propStyles: {
                            subTitle: {
                                numberOfLines: 2
                            }
                        }
                    })}
                </View>
            )
        });
    }

    const fetchSubscriptions = async () => {
        try {
            let { subscriptions } = await Request.get(utils, '/subscriptions/', {
                type: 'all_available',
                user_id: utils.user.get().user_id
            });
            setLoading(false);
            setSubscriptions(subscriptions.map(subscription => {
                return {
                    subscribed: subscription.subscribed,
                    plan: Subscription.Plan.create(subscription.plan)
                };
            }))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving available subscriptions. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchSubscriptions();
    }, [])

    return (
        <Layer
        id={layerID}
        title={'Subscriptions'}
        utils={utils}
        index={index}
        buttons={getButtons()}
        options={{
            ...options,
            layerState: layerState,
            bottomCard: true,
            removePadding: true,
            onBeforeCloseTap: selected ? onBeforeCloseTap : null
        }}>
            {selected
                ?
                <>
                <View style={{
                    padding: 15,
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderBottomColor: Appearance.colors.divider(),
                    borderBottomWidth: 1
                }}>
                    <View style={{
                        width: 75,
                        height: 75,
                        borderRadius: 37.5,
                        backgroundColor: Appearance.colors.primary(),
                        padding: 15,
                        overflow: 'hidden',
                        marginBottom: 8
                    }}>
                        <Image
                        source={selected.image}
                        style={{
                            width: '100%',
                            height: '100%',
                            resizeMode: 'contain'
                        }}/>
                    </View>
                    <Text style={{
                        ...Appearance.textStyles.panelTitle(),
                        marginBottom: 6
                    }}>{selected.name}</Text>
                    <Text style={{
                        ...Appearance.textStyles.subTitle(),
                        textAlign: 'center'
                    }}>{selected.description}</Text>
                </View>
                </>
                :
                <>
                <View style={{
                    padding: 15,
                    paddingBottom: 0,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Text style={{
                        ...Appearance.textStyles.panelTitle(),
                        marginBottom: 6
                    }}>{'Subscriptions'}</Text>
                    <Text style={{
                        ...Appearance.textStyles.subTitle(),
                        textAlign: 'center'
                    }}>{'We offer a collection of subscriptions that give a wide range of discounts for Orders and Reservations, free booking credits, and additional trees planted for each use. Choose a subscription plan to learn more'}</Text>
                </View>
                <ScrollView showsVerticalScrollIndicator={false}
                style={{
                    width: '100%',
                    maxHeight: Screen.height() / 2,
                    padding: 15
                }}>
                    {getContent()}
                </ScrollView>
                </>
            }
        </Layer>
    )
}

export const PaymentMethodInvoice = ({ method }, { index, options, utils }) => {

    const layerID = `payment-invoice-${method.id}`;
    const [loading, setLoading] = useState(true);
    const [layerState, setLayerState] = useState(null);
    const [url, setUrl] = useState(false);
    const [charges, setCharges] = useState(null);

    const onChargePress = (charge) => {

        utils.sheet.show({
            title: charge.failed ? 'Charge Failed' : null,
            message: charge.failed ? charge.failure_message : null,
            items: [{
                key: 'support',
                title: 'Get Support',
                style: 'default',
            },{
                key: 'order',
                title: 'View Order',
                style: 'default',
                visible: charge.order_id ? true : false
            },{
                key: 'reservation',
                title: 'View Reservation',
                style: 'default',
                visible: charge.reservation_id ? true : false
            },{
                key: 'subscription',
                title: 'View Subscription',
                style: 'default',
                visible: charge.subscription_id ? true : false
            },{
                key: 'about',
                title: 'About this Payment',
                style: 'default',
                visible: !charge.order_id && !charge.reservation_id && !charge.subscription_id ? true : false
            }]
        }, async key => {

            if(key === 'cancel') {
                return;
            }
            if(key === 'support') {
                Utils.showSupportOptions(utils, {
                    message: `I have a question about a transaction for ${Utils.toCurrency(charge.amount)} on ${moment(charge.date).format('MMMM Do, YYYY [at] h:mma')}. Transaction ID ${charge.id}`
                });
                return;

            }
            if(key === 'about') {
                utils.alert.show({
                    title: 'Custom Payment',
                    message: `This payment was ${charge.failed ? 'attempted' : 'made'} on ${moment(charge.date).format('MMMM Do, YYYY [at] h:mma')} for the amount of ${Utils.toCurrency(charge.amount)}. Please reach out to support if you have questions or concerns about this transaction`
                });
                return;
            }

            try {
                let { order, reservation, subscription } = await Utils.createMultiple(utils, {
                    order_id: charge.order_id,
                    reservation_id: charge.reservation_id,
                    subscriptionID: charge.subscription_id
                });

                if(key === 'order') {
                    if(!order) {
                        throw new Error('Order not found');
                        return;
                    }
                    utils.layer.order.details(order);

                } else if(key === 'reservation') {
                    if(!reservation) {
                        throw new Error('Reservation not found');
                        return;
                    }
                    utils.layer.reservation.details(reservation);

                } else if(key === 'subscription') {
                    if(!subscription) {
                        throw new Error('Subscription not found');
                        return;
                    }
                    utils.layer.subscription.details(subscription);
                }

            } catch(e) {
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue retrieving the information for this transacion. ${e.message || 'An unknown error occurred'}`
                });
            }
        })
    }

    const onDownloadCharges = async () => {
        if(!url) {
            utils.alert.show({
                title: 'Oops!',
                message: 'An unexpected error occurred when trying to generate your download'
            });
            return;
        }

        // Download file and show share sheet
        try {
            setLoading(true);
            let res = await RNFetchBlob.config({
                fileCache: true,
                path: `${RNFetchBlob.fs.dirs.CacheDir}/payment-history-${moment().unix()}.xls`
            }).fetch('GET', url);

            setLoading(false);
            Share.share({
                url: res.path(), // iOS
                message: Platform.OS === 'ios' ? null : res.path(),  // Android
                title: Platform.OS === 'ios' ? null : 'Payment History' // Android
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue downloading your payment history. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchCharges = async () => {
        try {
            let { charges, url } = await Request.get(utils, '/payments/', {
                type: 'get_invoice',
                stripe_id: utils.user.get().stripe_customer_id,
                card_id: method.id
            });
            if(!charges || charges.length === 0) {
                utils.alert.show({
                    title: 'No Payments Found',
                    message: 'We were unable to locate any payments that were processed with this payment method.',
                    onPress: () => setLayerState('close')
                });
                return;
            }
            setLoading(false);
            setUrl(url);
            setCharges(charges);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your previous payments. ${e.message || 'An unknown error occurred'}`,
                onPress: () => setLayerState('close')
            })
        }
    }

    useEffect(() => {
        fetchCharges();
    }, [])

    return (
        <Layer
            id={layerID}
            title={'Payment Method Invoice'}
            utils={utils}
            index={index}
            options={{
                ...options,
                layerState: layerState,
                bottomCard: true,
                removePadding: true
            }}
            buttons={[{
                key: 'download',
                text: 'Download',
                color: 'grey',
                loading: loading,
                onPress: onDownloadCharges
            }]}>
            <View style={{
                padding: 15,
                alignItems: 'center',
                justifyContent: 'center',
                borderBottomColor: Appearance.colors.divider(),
                borderBottomWidth: 1
            }}>
                <Text style={Appearance.textStyles.panelTitle()}>{'Payment History'}</Text>
            </View>
            <ScrollView showsVerticalScrollIndicator={false}
            style={{
                width: '100%',
                maxHeight: Screen.height() / 2,
                borderBottomColor: Appearance.colors.divider(),
                borderBottomWidth: 1
            }}>
                {charges && charges.length > 0
                    ?
                    charges.map((charge, index, charges) => {

                        return (
                            Views.entry({
                                key: index,
                                title: charge.title,
                                subTitle: moment(charge.date).format('MMMM Do, YYYY [at] h:mma'),
                                hideIcon: true,
                                badge: charge.badge ? {
                                    text: charge.badge.text,
                                    color: typeof(Appearance.colors[charge.badge.color]) === 'function' ? Appearance.colors[charge.badge.color]() : Appearance.colors[charge.badge.color]
                                } : null,
                                bottomBorder: index !== charges.length - 1,
                                onPress: onChargePress.bind(this, charge)
                            })
                        )
                    })
                    :
                    null
                }
            </ScrollView>
        </Layer>
    )
}

export const RedeemCredits = ({ index, options, utils }) => {

    const layerID = `redeem-credits`;
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);

    const onChangeCode = code => {
        if(!code) {
            return;
        }
        utils.alert.show({
            title: code.title,
            message: `${code.description}. Would you like to redeem these credits?`,
            buttons: [{
                key: 'confirm',
                title: 'Redeem',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onPress: (key) => {
                if(key === 'confirm') {
                    onRedeemCredits(code);
                }
            }
        });
        return;
    }

    const onRedeemCredits = async promo_code => {

        // Fetch credits methods
        // automatically use if only one credits method is found
        // present options to choose if multiple credits methods are found
        try {
            let { credits} = await utils.user.get().getPaymentMethods(utils);
            if(credits.length <= 1) {
                submitRedemption(promo_code, credits[0]);
                return;
            }

            utils.sheet.show({
                title: 'Credits',
                message: 'Where would you like to redeem your credits?',
                items: credits.map((credit, index) => {
                    return {
                        key: index,
                        title: credit.getType(),
                        style: 'default'
                    }
                })
            }, (key) => {
                if(key === 'cancel') {
                    return;
                }
                submitRedemption(promo_code, credits[key]);
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue redeeming your credits. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const submitRedemption = async (promo_code, method) => {
        try {
            await Request.post(utils, '/payments/', {
                type: 'redeem',
                code: promo_code.code,
                user_id: utils.user.get().user_id,
                company_id: method && method.company ? method.company.id : null,
                category: method ? method.type : 'user'
            });
            utils.alert.show({
                title: 'All Done!',
                message: `${Utils.toCurrency(promo_code.discount)} of credits have been added to "${method ? method.getType() : 'Personal Credits'}"`,
                onPress: () => {
                    setLayerState('close');
                    utils.content.fetch('credits');
                }
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue redeeming your credits. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        utils.keyboard.subscribe(layerID, {
            onVisibility: visible => setKeyboardOpen(visible)
        });
        return () => {
            utils.keyboard.unsubscribe(layerID);
        }
    }, [])

    return (
        <Layer
        id={layerID}
        title={'Redeem Credits'}
        utils={utils}
        index={index}
        options={{
            ...options,
            layerState: layerState,
            bottomCard: true
        }}>
            <View style={{
                width: '100%',
                alignItems: 'center',
                marginTop: 5,
                marginBottom: keyboardOpen ? 15 : Screen.safeArea.bottom
            }}>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 6
                }}>{'Redeem Credits'}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 20,
                    textAlign: 'center'
                }}>{'Please enter your credits redemption code below to redeem your credits'}</Text>

                <PromoCodeLookup
                utils={utils}
                redemption={true}
                onChange={onChangeCode}/>
            </View>
        </Layer>
    )
}

export const CreditsMethodInvoice = ({ method, entries, url }, { index, options, utils }) => {

    const layerID = `credits-invoice-${method.id}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);

    const onEntryPress = (entry) => {

        utils.alert.show({
            title: 'About this Entry',
            message: entry.notes || 'No additional information is available for this entry',
            buttons: [{
                key: 'order',
                title: 'View Order',
                style: 'default',
                visible: entry.order_id ? true : false
            },{
                key: 'support',
                title: 'Get Support',
                style: 'default',
            },{
                key: 'reservation',
                title: 'View Reservation',
                style: 'default',
                visible: entry.reservation_id ? true : false
            },{
                key: 'cancel',
                title: 'Dismiss',
                style: 'cancel'
            }],
            onPress: async key => {

                if(key === 'support') {
                    Utils.showSupportOptions(utils, {
                        message: `I have a question about some activity on my ${utils.client.get().name} Credits account. My Credits Account ID is CR${method.id} and the activity that I'm asking about is CRID${entry.id}`
                    });

                } else if(key === 'order' || key === 'reservation') {

                    try {
                        setLoading(true);
                        let { abstract } = await Utils.create(utils, {
                            id: entry.order_id || entry.reservation_id,
                            type: entry.order_id ? 'orders' : 'reservations'
                        });

                        setLoading(false);
                        switch(abstract.type) {
                            case 'orders':
                                utils.layer.order.details(abstract.object);
                                break;
                            case 'reservations':
                                utils.layer.reservation.details(abstract.object);
                                break;
                        }

                    } catch(e) {
                        setLoading(false);
                        utils.alert.show({
                            title: 'Oops!',
                            message: `There was an issue retrieving the information for this ${entry.order_id ? 'Order' : 'Reservation'}. ${e.message || 'An unknown error occurred'}`
                        });
                    }
                }
            }
        });
    }

    const onDownloadEntries = () => {
        if(!url) {
            utils.alert.show({
                title: 'Oops!',
                message: 'An unexpected error occurred when trying to generate your download'
            });
            return;
        }

        // Download file and show share sheet
        setLoading(true);
        RNFetchBlob.config({
            fileCache: true,
            path: `${RNFetchBlob.fs.dirs.CacheDir}/credits-history-${moment().unix()}.xls`
        }).fetch('GET', url).then((res) => {

            setLoading(false);
            Share.share({
                url: res.path(), // iOS
                message: Platform.OS === 'ios' ? null : res.path(),  // Android
                title: Platform.OS === 'ios' ? null : 'Credits History' // Android
            })
        }).catch(e => {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue downloading your credits history. ${e.message || 'An unknown error occurred'}`
            })
        })
    }

    return (
        <Layer
            id={layerID}
            title={'Credits Method Invoice'}
            utils={utils}
            index={index}
            options={{
                ...options,
                loading: loading,
                layerState: layerState,
                bottomCard: true,
                removePadding: true
            }}
            buttons={[{
                key: 'download',
                text: 'Download',
                color: 'grey',
                loading: loading,
                onPress: onDownloadEntries
            }]}>
            <View style={{
                padding: 15,
                paddingBottom: 0,
                alignItems: 'center',
                justifyContent: 'center',
                borderBottomColor: Appearance.colors.divider(),
                borderBottomWidth: 1
            }}>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 2
                }}>{'Credits Usage History'}</Text>
                <Text style={Appearance.textStyles.subTitle()}>{`Current Balance: ${Utils.toCurrency(method.balance())}`}</Text>
            </View>
            <ScrollView showsVerticalScrollIndicator={false}
            style={{
                width: '100%',
                maxHeight: Screen.height() / 2,
                padding: 15
            }}>
                {entries.map((entry, index, entries) => {

                    return (
                        <View
                        key={index}
                        style={{
                            ...Appearance.styles.panel(),
                            marginBottom: index !== entries.length - 1 ? 8 : 0
                        }}>
                            {Views.entry({
                                title: entry.title,
                                subTitle: moment(entry.date).format('MMMM Do, YYYY [at] h:mma'),
                                hideIcon: true,
                                badge: {
                                    text: entry.badge,
                                    color: Utils.apply(entry.color, {
                                        primary: () => Appearance.colors.primary(),
                                        danger: () => Appearance.colors.red,
                                        default: () => Appearance.colors.grey()
                                    })
                                },
                                bottomBorder: index !== entries.length - 1,
                                onPress: onEntryPress.bind(this, entry)
                            })}
                        </View>
                    )
                })}
            </ScrollView>
        </Layer>
    )
}

export const BuyCredits = ({ method }, { abstract, index, options, utils }) => {

    const layerID = 'buy-credits';
    const amounts = [5,10,15,25,50,'Custom'];
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [showCustomAmount, setShowCustomAmount] = useState(false);
    const [amount, setAmount] = useState('0.00');
    const [supportsNativePay, setSupportsNativePay] = useState(false)

    const onAmountPress = amount => {

        Keyboard.dismiss();
        if(isNaN(amount) || amount < 0.50) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'Please enter an amount greater than $0.50'
            });
            return;
        }
        setAmount(amount);
        choosePaymentMethod(amount);
    }

    const choosePaymentMethod = async amount => {

        try {

            // Add card if none are present
            let { methods } = await utils.user.get().getPaymentMethods(utils);
            if(methods.length === 0) {
                utils.layer.open({
                    id: 'add-new-card',
                    Component: AddNewCard.bind(this, {
                        onAddCard: choosePaymentMethod.bind(this, amount)
                    })
                });
                return;
            }

            // Choose payment method
            utils.sheet.show({
                title: 'Payment Method',
                message: 'How would you like to pay for these credits?',
                items: [{
                    key: 'apple-pay',
                    title: 'Apple Pay',
                    visible: Platform.OS === 'ios' && supportsNativePay === true,
                    style: 'default'
                },{
                    key: 'android-pay',
                    title: 'Android Pay',
                    visible: Platform.OS === 'android' && supportsNativePay === true,
                    style: 'default'
                }].concat(methods.map((method, index) => {
                    return {
                        key: method.id,
                        title: method.summary(),
                        style: 'default'
                    }
                })).concat([{
                    key: 'addCard',
                    title: 'Add New Card',
                    style: 'default'
                }])
            }, (key) => {
                if(key === 'cancel') {
                    return;
                }
                if(key === 'apple-pay' || key === 'android-pay') {
                    showNativePay(amount);
                    return;
                }
                if(key === 'addCard') {
                    utils.layer.open({
                        id: 'add-new-card',
                        Component: AddNewCard.bind(this, {
                            onAddCard: choosePaymentMethod.bind(this, amount)
                        })
                    });
                    return;
                }
                onProcessPayment({ sourceID: key, amount: amount }); // key is payment method ID
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your payment methods. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onProcessPayment = async ({ token, amount, sourceID }) => {
        try {
            setLoading(amount);
            let customer = utils.user.get();
            await Request.post(utils, '/credits/', {
                type: 'add',
                amount: amount,
                user_id: customer.user_id,
                token: token,
                source_id: sourceID,
                stripe_id: customer.stripe_customer_id,
                company_id: method && method.company ? method.company.id : null,
                category: method ? method.type : 'user'
            });

            setLoading(false);
            await Stripe.completeNativePayRequest();
            utils.content.fetch('payments');
            utils.alert.show({
                title: 'All Done!',
                message: `We have added ${Utils.toCurrency(amount)} of credits to "${method ? method.getType() : 'Personal Credits'}"`,
                onPress: () => {
                    setLayerState('close');
                    utils.content.fetch('credits');
                }
            });

        } catch (e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue purchasing your credits. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {

        let buttons = [];
        if(showCustomAmount) {
            buttons.push({
                text: 'Done',
                color: isNaN(amount) || amount < 0.50 ? 'grey' : 'primary',
                onPress: onAmountPress.bind(this, amount)
            })
        }
        return buttons.length > 0 ? buttons : null;
    }

    const showNativePay = async amount => {

        try {
            const { cardId, tokenId } = await Stripe.paymentRequestWithNativePay({
                total_price: amount,
                currency_code: 'USD',
                shipping_address_required: false,
                billing_address_required: false,
                line_items: [{
                    currency_code: 'USD',
                    description: 'Credits',
                    total_price: amount,
                    unit_price: amount,
                    quantity: '1'
                }],
            }, [{
                label: 'Credits',
                amount: amount.toString(),
            }]);

            onProcessPayment({
                amount: amount,
                token: tokenId,
                sourceID: cardId
            });

        } catch(e) {
            if(e.message === 'Cancelled by user') {
                return;
            }
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue completing your payment. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const setupNativePay = async () => {
        try {
            let supported = await Stripe.deviceSupportsNativePay();
            setSupportsNativePay(supported);
        } catch(e) {
            setSupportsNativePay(false);
        }
    }

    useEffect(() => {
        setupNativePay();
        Stripe.setOptions({
            merchantId: API.apple_pay,
            publishableKey: API.stripe,
            androidPayMode: 'production'
        })
    }, [])

    return (
        <Layer
        id={layerID}
        title={'Buy Credits'}
        index={index}
        utils={utils}
        buttons={getButtons()}
        options={{
            ...options,
            loading: loading,
            layerState: layerState,
            bottomCard: true,
            removePadding: true,
        }}>

            <View style={{
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 5
            }}>
                <View style={{
                    padding: 15,
                    paddingBottom: 0,
                    alignItems: 'center'
                }}>
                    <Text style={{
                        ...Appearance.textStyles.panelTitle(),
                        marginBottom: 6
                    }}>{'Buy Credits'}</Text>
                    <Text style={{
                        ...Appearance.textStyles.subTitle(),
                        marginBottom: 20,
                        textAlign: 'center'
                    }}>{`At ${utils.client.get().name}, Credits are a currency the we use to help you book Orders and Reservations without having to use your debit or credit card.`}</Text>
                </View>

                <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                style={{
                    height: 35,
                    width: '100%',
                    marginBottom: 12
                }}
                contentContainerStyle={{
                    paddingHorizontal: 15
                }}>
                    {amounts.map((amount, index) => {
                        return (
                            <View
                            key={index}
                            style={{
                                paddingRight: index !== amounts.length - 1 ? 4:0,
                                paddingLeft: index !== 0 ? 4:0
                            }}>
                                <Button
                                onPress={isNaN(amount) ? () => setShowCustomAmount(true) : onAmountPress.bind(this, amount)}
                                label={isNaN(amount) ? amount : Utils.toCurrency(amount)}
                                loading={amount === loading}
                                color={isNaN(amount) ? 'grey' : 'primary'}/>
                            </View>
                        )
                    })}
                </ScrollView>

                {showCustomAmount
                    ?
                    <View style={{
                        padding: 15,
                        width: '100%'
                    }}>
                        <TextField
                        value={amount}
                        prepend={'$'}
                        placeholder={'Custom Amount'}
                        onChange={text => setAmount(text)} />
                    </View>
                    :
                    null
                }
            </View>
        </Layer>
    )
}

export const DriverTip = ({ driverID, shouldProcess, onAddTip, onRemoveTip }, { abstract, index, options, utils }) => {

    const layerID = `driver-tip-${driverID || 'new'}`;
    const amounts = [5,10,15,25,50,'Custom'];
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [showCustomAmount, setShowCustomAmount] = useState(false);
    const [driver, setDriver] = useState(null);
    const [amount, setAmount] = useState('0.00');

    const onAmountPress = (amount) => {

        Keyboard.dismiss();
        if(isNaN(amount) || amount < 0.50) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'Please enter an amount greater than $0.50'
            });
            return;
        }

        // Return amount if shouldProcess is false
        if(shouldProcess !== true) {
            setLayerState('close');
            if(typeof(onAddTip) === 'function') {
                onAddTip(amount);
            }
            return;
        }

        setAmount(amount);
        choosePaymentMethod();
    }

    const choosePaymentMethod = async () => {

        try {

            // Add card if none are present
            let { methods } = await utils.user.get().getPaymentMethods(utils);
            if(methods.length === 0) {
                utils.layer.open({
                    id: 'add-new-card',
                    Component: AddNewCard.bind(this, {
                        onAddCard: choosePaymentMethod
                    })
                });
                return;
            }

            // Choose payment method
            utils.sheet.show({
                title: 'Payment Method',
                message: 'How would you like to pay for this tip?',
                items: methods.map((method, index) => {
                    return {
                        key: method.id,
                        title: method.summary(),
                        style: 'default'
                    }
                }).concat([{
                    key: 'addCard',
                    title: 'Add New Card',
                    style: 'default'
                }])
            }, (key) => {
                if(key === 'cancel') {
                    return;
                }
                if(key === 'addCard') {
                    utils.layer.open({
                        id: 'add-new-card',
                        Component: AddNewCard.bind(this, {
                            onAddCard: choosePaymentMethod
                        })
                    });
                    return;
                }
                onProcessTip(key); // key is payment method ID is non NaN
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your payment methods. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onProcessTip = async cardID => {

        // Return amount if processing is not needed
        if(shouldProcess !== true) {
            setLayerState('close');
            if(typeof(onAddTip) === 'function') {
                onAddTip(amount);
            }
            return;
        }

        try {

            if(!driver) {
                throw new Error('Unable to locate driver information');
                return;
            }

            // Process tip
            let customer = utils.user.get();
            await Request.get(utils, '/credits/', {
                type: 'driver_tip',
                amount: amount,
                card_id: cardID,
                user_id: customer.user_id,
                driver_id: driverID,
                company: customer.company ? customer.company.id : null,
                stripe_id: customer.stripe_customer_id,
                ...Utils.apply(abstract.type, {
                    orders: () => {
                        return { order_id: abstract.getID() }
                    },
                    reservations: () => {
                        return { reservation_id: abstract.getID() }
                    }
                })
            });
            utils.alert.show({
                title: 'All Done!',
                message: `Thank you for taking the time to show ${driver.full_name} how much you appreciated your trip.`,
                onPress: () => {
                    setLayerState('close');
                    if(typeof(onAddTip) === 'function') {
                        onAddTip(amount);
                    }
                }
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: Utils.apply(abstract.type, {
                    orders: () => `There was an issue processing the tip for this Order. ${e.message || 'An unknown error occurred'}`,
                    reservations: () => `There was an issue processing the tip for this Reservation. ${e.message || 'An unknown error occurred'}`
                })
            });
        }
    }

    const fetchDriver = async () => {

        if(!driverID) {
            return;
        }

        try {
            let { driver } = await Request.get(utils, '/driver/', {
                type: 'details',
                user_id: driverID
            });
            setDriver(Driver.create(driver));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: Utils.apply(abstract.type, {
                    orders: () => `There was an issue locating the driver for this Order. ${e.message}`,
                    reservations: () => `There was an issue locating the driver for this Reservation. ${e.message}`
                })
            });
        }
    }

    const getButtons = () => {

        let buttons = [];
        if(typeof(onRemoveTip) === 'function') {
            buttons.push({
                text: 'Remove',
                color: 'danger',
                onPress: () => {
                    setLayerState('close');
                    onRemoveTip();
                }
            })
        }
        if(showCustomAmount) {
            buttons.push({
                text: 'Done',
                color: isNaN(amount) || amount < 0.50 ? 'grey' : 'primary',
                onPress: onAmountPress.bind(this, amount)
            })
        }
        return buttons.length > 0 ? buttons : null;
    }

    useEffect(() => {
        fetchDriver();
    }, [])

    return (
        <Layer
            id={layerID}
            title={'Driver Tip'}
            utils={utils}
            index={index}
            options={{
                ...options,
                loading: loading,
                layerState: layerState,
                bottomCard: true,
                removePadding: true,
            }}
            buttons={getButtons()}>

            <View style={{
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 5
            }}>
                <View style={{
                    padding: 15,
                    paddingBottom: 0,
                    alignItems: 'center'
                }}>
                    {driver && (
                        <Image
                        source={driver.avatar}
                        style={{
                            width: 75,
                            height: 75,
                            resizeMode: 'cover',
                            borderRadius: 37.5,
                            overflow: 'hidden',
                            marginBottom: 12
                        }}/>
                    )}
                    <Text style={{
                        ...Appearance.textStyles.panelTitle(),
                        marginBottom: 6
                    }}>{driver ? driver.full_name : 'Driver Tip'}</Text>
                    <Text style={{
                        ...Appearance.textStyles.subTitle(),
                        marginBottom: 20,
                        textAlign: 'center'
                    }}>{'Show your driver some love by leaving them a tip for their excellent service. 100% of your tip goes to your driver.'}</Text>
                </View>

                <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                style={{
                    height: 35,
                    width: '100%',
                    marginBottom: 12
                }}
                contentContainerStyle={{
                    paddingHorizontal: 15
                }}>
                    {amounts.map((amount, index) => {
                        return (
                            <View key={index} style={{
                                paddingRight: index !== amounts.length - 1 ? 4:0,
                                paddingLeft: index !== 0 ? 4:0
                            }}>
                                <Button
                                onPress={isNaN(amount) ? () => setShowCustomAmount(true) : onAmountPress.bind(this, amount)}
                                label={isNaN(amount) ? amount : Utils.toCurrency(amount)}
                                color={isNaN(amount) ? 'grey' : 'primary'}/>
                            </View>
                        )
                    })}
                </ScrollView>

                {showCustomAmount && (
                    <View style={{
                        padding: 15,
                        width: '100%'
                    }}>
                        <TextField
                        value={amount}
                        prepend={'$'}
                        placeholder={'Custom Amount'}
                        onChange={text => setAmount(text)} />
                    </View>
                )}
            </View>
        </Layer>
    )
}
