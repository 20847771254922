import React, { useEffect, useState } from 'react';

export const AppearanceProvider = ({ children, nonce, style }) => {
    return (
        <div
        nonce={nonce}
        style={style}>
            {children}
        </div>
    )
}
