import React, { useEffect, useState } from 'react';
import { Animated, Image, Keyboard, Platform, ScrollView, Text, View } from 'react-native';

import update from 'immutability-helper';

import Appearance from 'eCarra/styles/Appearance';
import Button from 'eCarra/views/Button.js';
import { CodeField, Cursor } from 'react-native-confirmation-code-field';
import KeyboardManager from 'eCarra/files/KeyboardManager.js';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import Screen from 'eCarra/files/Screen.js';
import Sound from 'eCarra/files/Sound/';
import TextField from 'eCarra/views/TextField.js';
import TouchableHighlight from 'eCarra/views/TouchableHighlight/';

const Alert = ({ id, utils, content, buttons, index, icon, message, onPress, onClose, textFields, title, twoFactor }) => {

    const [visible, setVisible] = useState(false);
    const [_title, _setTitle] = useState(null);
    const [_message, _setMessage] = useState(null);
    const [_buttons, _setButtons] = useState([]);
    const [_textFields, _setTextFields] = useState(false);
    const [twoFactorText, setTwoFactorText] = useState(null);

    const [scale, setScale] = useState(new Animated.Value(0));
    const [translate, setTranslate] = useState(new Animated.Value(0));
    const [bottom, setBottom] = useState(new Animated.Value(0));
    const [opacity, setOpacity] = useState(new Animated.Value(0));

    const hide = callback => {

        Animated.spring(scale, {
            toValue: 0,
            duration: 150,
            useNativeDriver: false
        }).start();
        Animated.timing(opacity, {
            toValue: 0,
            duration: 150,
            useNativeDriver: false
        }).start(() => {
            setVisible(false);
            if(onClose && typeof(onClose) === 'function') {
                onClose(id);
            }
            if(callback && typeof(callback) === 'function') {
                callback();
            }
        });
    }

    const parseTextFields = (key) => {

        if(key !== 'cancel' && key !== 'dismiss') {
            if(onPress && typeof(onPress) === 'function') {
                let textFieldValues = {};
                for(var i in _textFields) {
                    let t = _textFields[i];
                    if(!t.value && t.required === true) {
                        return;
                    }
                    textFieldValues[t.field.key] = t.value;
                }
                onPress(textFieldValues);
            }
        }
        if(onClose && typeof(onClose) === 'function') {
            onClose(id);
        }
    }

    const buttonPress = (key) => {
        hide(() => {
            if(_textFields) {
                parseTextFields(key);
                return;
            }
            if(twoFactor) {
                if(twoFactorText && twoFactorText.length === 6) {
                    if(onPress && typeof(onPress) === 'function') {
                        onPress({ twoFactor: twoFactorText });
                    }
                }
                return;
            }
            if(onPress && typeof(onPress) === 'function') {
                onPress(key);
            }
        });
    }

    const useRowLayout = (expButtons) => {

        // Prevent truncated text on longer labeled buttons
        let b = expButtons || _buttons;
        let length = b.reduce((prevLength, button) => {
            return button.title.length > prevLength ? button.title.length : prevLength
        }, 0);
        if(length > 11) {
            return false;
        }
        return b.filter(item => item.visible !== false).length === 2;
    }

    useEffect(() => {
        Animated.spring(scale, {
            toValue: index > 3 ? 0 : 1 - (index / 4),
            duration: 250,
            friction: 5,
            useNativeDriver: false
        }).start();
        Animated.timing(opacity, {
            toValue: index === 0 ? 1 : 0,
            duration: 250,
            useNativeDriver: false
        }).start();
    }, [index])

    useEffect(() => {
        _setTextFields(textFields ? textFields.map(t => {
            return {
                value: t.value,
                field: t
            }
        }) : null);
    }, [textFields]);

    useEffect(() => {
        let b = buttons || [{
            key: 'dismiss',
            title: 'Dismiss',
            style: 'cancel'
        }];
        if(useRowLayout(b)) {
            b = b.reverse(); // primary button should be on the right
        }
        _setButtons(b);
    }, []);

    useEffect(() => {
        _setTitle(title === 'Oops!' ? 'Pardon the Interuption' : title);
    }, [title]);

    useEffect(() => {
        if(message) {
            console.log(message);
            _setMessage(message.replace('Network request failed', 'Your internet connection appears to be offline'))
        }
    }, [message]);

    useEffect(() => {

        Keyboard.dismiss();
        setVisible(true);
        setTimeout(() => {

            Animated.spring(scale, {
                toValue: 1,
                duration: 500,
                friction: 5,
                useNativeDriver: false
            }).start();
            Animated.timing(opacity, {
                toValue: 1,
                duration: 250,
                useNativeDriver: false
            }).start();

            let errorMessage = ['oops', 'just a second', 'pardon the interuption'].includes(title.toLowerCase());
            if(errorMessage) {
                Sound.Alert.Error.play();
            }

            if(Platform.OS === 'ios') {
                ReactNativeHapticFeedback.trigger(errorMessage ? 'notificationError' : 'notificationSuccess', {
                    enableVibrateFallback: false,
                    ignoreAndroidSystemSettings: false
                });
            }

        }, 0);

        utils.keyboard.subscribe('Alert', {
            onShow: (e) => {
                Animated.spring(bottom, {
                    toValue: e.endCoordinates.height / 2,
                    duration: 100,
                    friction: 10,
                    useNativeDriver: false
                }).start()
            },
            onHide: () => {
                Animated.spring(bottom, {
                    toValue: 0,
                    duration: 100,
                    friction: 10,
                    useNativeDriver: false
                }).start()
            }
        })

        return () => {
            utils.keyboard.unsubscribe('Alert');
        }
    }, [])

    return visible ? (
        <View style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: Appearance.colors.transparent,
            zIndex: 9950,
            elevation: 6
        }}>
            <Animated.View style={{
                marginTop: Screen.safeArea.top,
                borderRadius: 15,
                backgroundColor: Appearance.colors.alert(),
                overflow: 'hidden',
                maxWidth: 400,
                maxHeight: Screen.height() - 30,
                width: Screen.width() - 60,
                alignItems: 'center',
                zIndex: 9999,
                opacity: opacity,
                bottom: bottom,
                transform: [{
                    scale: scale
                }]
            }}>
                <View style={{
                    width: 65,
                    height: 65,
                    marginTop: 25,
                    marginBottom: 10,
                }}>
                    <View style={{
                        width: 65,
                        height: 65,
                        borderRadius: 15,
                        overflow: 'hidden',
                        ...icon && icon.style
                    }}>
                        <Image
                        source={icon ? icon.path : utils.client.get().logos.mobile}
                        style={{
                            width: '100%',
                            height: '100%',
                            resizeMode: 'contain',
                            ...icon && icon.imageStyle
                        }}/>
                    </View>
                </View>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    paddingTop: icon ? 8 : 15,
                    paddingLeft: 15,
                    paddingRight: 15,
                    marginBottom: 8,
                    textAlign: 'center',
                }}>{_title}</Text>

                <ScrollView style={{
                    flexGrow: 1,
                    width: '100%',
                    maxHeight: Screen.height() / 2,
                    marginBottom: 15
                }}>
                    <View style={{
                        flex: 1,
                        width: '100%'
                    }}>
                        <Text style={{
                            ...Appearance.textStyles.subTitle(),
                            width: '100%',
                            height: '100%',
                            paddingLeft: 15,
                            paddingRight: 15,
                            textAlign: 'center',
                        }}>{_message}</Text>
                    </View>
                </ScrollView>

                {content}

                {_textFields && _textFields.length > 0 && (
                    <View style={{
                        width: '100%',
                        paddingLeft: 15,
                        paddingRight: 15,
                        marginBottom: 15
                    }}>
                        {_textFields.map((item, index, fields) => {
                            return (
                                <View
                                key={index}
                                style={{
                                    marginBottom: fields.length > 1 ? 8 : 0
                                }}>
                                    <TextField
                                    {...item.field}
                                    value={item.value}
                                    useDelay={false}
                                    format={item.field.format}
                                    isSecure={item.field.secure}
                                    placeholder={item.field.placeholder}
                                    fieldStyle={{
                                        textAlign: 'center'
                                    }}
                                    onChange={text => {
                                        _setTextFields(update(_textFields, {
                                            [index]: {
                                                value: {
                                                    $set: text
                                                }
                                            }
                                        }))
                                    }} />
                                </View>
                            )
                        })}
                    </View>
                )}

                {twoFactor && (
                    <View style={{
                        width: '100%',
                        maxWidth: 275,
                        paddingHorizontal: 12,
                        marginVertical: 15
                    }}>
                        <CodeField
                        value={twoFactorText}
                        onChangeText={text => setTwoFactorText(text)}
                        cellCount={6}
                        rootStyle={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        keyboardType={'number-pad'}
                        textContentType={'oneTimeCode'}
                        renderCell={({index, symbol, isFocused}) => (
                            <View
                            key={index}
                            style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginHorizontal: 4,
                                backgroundColor: Appearance.colors.textField(),
                                borderRadius: 8,
                                borderWidth: 1,
                                borderColor: 'rgba(175,175,175,0)',
                                height: 40,
                                width: 35,
                                minWidth: 35,
                                overflow: 'hidden'
                            }}>
                                <Text style={{
                                    width: 35,
                                    fontSize: 18,
                                    ...Appearance.fontWeight.get(600),
                                    color: Appearance.colors.text(),
                                    textAlign: 'center'
                                }}>
                                    {symbol || (isFocused ? <Cursor /> : null)}
                                </Text>
                            </View>
                        )} />
                    </View>
                )}

                <View style={{
                    paddingHorizontal: 15,
                    paddingBottom: 15,
                    width: '100%',
                    display: 'flex',
                    flexDirection: useRowLayout() ? 'row' : 'column'
                }}>
                    {_buttons.filter(item => {
                        return item.visible !== false;
                    }).map((item, index) => {

                        return (
                            <View
                            key={index}
                            style={{
                                marginTop: 8,
                                width: useRowLayout() ? '50%' : '100%',
                                paddingLeft: useRowLayout() ? (index === 0 ? 0 : 4) : 0,
                                paddingRight: useRowLayout() ? (index !== 0 ? 0 : 4) : 0
                            }}>
                                <Button
                                label={item.title}
                                color={item.style === 'default' ? Appearance.colors.primary() : Appearance.colors.grey()}
                                onPress={buttonPress.bind(this, item.key)} />
                            </View>
                        )
                    })}
                </View>
            </Animated.View>
        </View>
    ) : null
}

export default Alert;
