import React, { useRef, useState, useEffect } from 'react';
import { View, Animated } from 'react-native';

import Appearance from 'eCarra/styles/Appearance.js';
import LottieView from 'eCarra/views/Lottie/';

const AnimatedLoader = ({ loading, onLoadComplete, style }) => {

    const loader = useRef(null);
    const loadingRef = useRef(false);
    const [opacity, setOpacity] = useState(new Animated.Value(0));

    const onAnimationFinish = () => {
        if(loadingRef.current === false) {
            Animated.timing(opacity, {
                toValue: 0,
                duration: 500,
                useNativeDriver: false
            }).start(onLoadComplete);
            return;
        }
        if(loader.current) {
            loader.current.play();
        }
    }

    useEffect(() => {
        loadingRef.current = loading;
    }, [loading]);

    useEffect(() => {
        Animated.timing(opacity, {
            toValue: 1,
            duration: 500,
            useNativeDriver: false
        }).start();
    }, []);

    return (
        <Animated.View style={{
            width: '100%',
            height: '100%',
            backgroundColor: Appearance.colors.background(),
            alignItems: 'center',
            justifyContent: 'center',
            opacity: opacity,
            ...style
        }}>
            <LottieView
            ref={loader}
            autoPlay={true}
            loop={false}
            onAnimationFinish={onAnimationFinish}
            source={require('eCarra/files/lottie/loader-white.json')}
            style={{
                width: 150,
                height: 150
            }}/>
        </Animated.View>
    )
}

export default AnimatedLoader;
