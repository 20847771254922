import React, { useEffect, useState } from 'react';
import { Image, Text, View } from 'react-native';

import Appearance from 'eCarra/styles/Appearance.js';
import Screen from 'eCarra/files/Screen.js';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';

const DropdownHeader = ({ content, label, onChange, onPress, style, subLabel }) => {

    const [expanded, setExpanded] = useState(false);

    const onDropdownPress = () => {
        setExpanded(!expanded);
        if(typeof(onPress) === 'function') {
            onPress();
        }
    }

    return (
        <View style={{
            display: 'flex',
            flexDirection: 'column',
            widtgh: '100%'
        }}>
            <View style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                ...style
            }}>
                <TouchableOpacity
                activeOpacity={0.6}
                onPress={onDropdownPress}>
                    <Text
                    numberOfLines={1}
                    style={{
                        ...Appearance.textStyles.panelTitle(),
                        maxWidth: Screen.width() - 150,
                    }}>{label}</Text>
                </TouchableOpacity>

                <TouchableOpacity
                activeOpacity={0.6}
                onPress={onDropdownPress}>
                    <Image
                    source={require('eCarra/images/down-arrow-grey-small.png')}
                    style={{
                        width: 15,
                        height: 15,
                        marginLeft: 8,
                        resizeMode: 'contain',
                        tintColor: Appearance.colors.subText()
                    }}/>
                </TouchableOpacity>
                {expanded && content}
            </View>
            {typeof(subLabel) === 'string' && (
                <Text
                numberOfLines={1}
                style={{
                    ...Appearance.textStyles.title(),
                    color: Appearance.colors.subText(),
                    maxWidth: Screen.width() - 150,
                    marginTop: 2,
                    marginBottom: 4
                }}>{subLabel || 'sub label goes here'}</Text>
            )}
        </View>
    )
}
export default DropdownHeader;
