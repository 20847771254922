import React, { useEffect, useRef, useState } from 'react';
import { Animated, Image, Keyboard, Platform, Text, TouchableWithoutFeedback, View } from 'react-native';

import Appearance from 'eCarra/styles/Appearance.js';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import Screen from 'eCarra/files/Screen.js';
import TouchableHighlight from 'eCarra/views/TouchableHighlight/';
import Utils from 'eCarra/files/Utils.js';

const Sheet = ({ title, message, content, icon, items, onClose, onPress }) => {

    const sheetRef = useRef(null);
    const [bottom, setBottom] = useState(new Animated.Value(-500));
    const [opacity, setOpacity] = useState(new Animated.Value(0));
    const [scale, setScale] = useState(new Animated.Value(0));
    const [sheetItems, setSheetItems] = useState([]);

    const onCloseSheetTap = async () => {
        try {
            await onCloseSheet();
            onRunDismissActions(null);
        } catch(e) {
            console.error(e.message);
        }
    }

    const onCloseSheet = async () => {
        return new Promise((resolve, reject) => {
            try {
                if(Platform.OS !== 'web' && Utils.isMobile() === true) {
                    Animated.timing(bottom, {
                        toValue: -500,
                        duration: 150,
                        useNativeDriver: false
                    }).start();
                }
                if(Platform.OS === 'web' || Utils.isMobile() === false) {
                    Animated.timing(scale, {
                        toValue: 0,
                        duration: 150,
                        useNativeDriver: false
                    }).start();
                }
                Animated.timing(opacity, {
                    toValue: 0,
                    duration: 250,
                    useNativeDriver: false
                }).start(resolve);
            } catch(e) {
                reject(e);
            }
        })
    }

    const onItemClick = async key => {
        try {
            await onCloseSheet();
            onRunDismissActions(key);
        } catch(e) {
            console.error(e.message);
        }
    }

    const onRunDismissActions = key => {
        if(typeof(onClose) === 'function') {
            onClose();
        }
        if(typeof(onPress) === 'function') {
            onPress(key);
        }
    }

    const onShowSheet = () => {
        if(Platform.OS !== 'web' && Utils.isMobile() === true) {
            Animated.spring(bottom, {
                toValue: 0,
                duration: 250,
                friction: 10,
                useNativeDriver: false
            }).start();
        }
        if(Platform.OS === 'web' || Utils.isMobile() === false) {
            Animated.spring(scale, {
                toValue: 1,
                duration: 250,
                friction: 7,
                useNativeDriver: false
            }).start();
        }
    }

    useEffect(() => {
        onShowSheet();
    }, [sheetRef.current]);

    useEffect(() => {

        // add cancel button if not already present
        if(!items.find(item => item.key === 'cancel')) {
            items.push({
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            })
        }

        // set items and hide keyboard if applicable
        setSheetItems(items);
        Keyboard.dismiss();

        setTimeout(() => {
            // give haptic feedback for sheet reveal for ios only
            if(Platform.OS === 'ios') {
                ReactNativeHapticFeedback.trigger('impactMedium', {
                    enableVibrateFallback: false,
                    ignoreAndroidSystemSettings: false
                });
            }
            // animate sheet into view
            Animated.timing(opacity, {
                toValue: 1,
                duration: 250,
                useNativeDriver: false
            }).start();
        }, 0)
    }, []);

    return (
        <View style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 9500,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <TouchableWithoutFeedback
            onPress={onCloseSheetTap}
            style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
            }}>
                <Animated.View
                style={{
                    backgroundColor: Appearance.colors.dim,
                    width: '100%',
                    height: '100%',
                    opacity: opacity
                }} />
            </TouchableWithoutFeedback>

            <Animated.View style={{
                position: 'absolute',
                width: Screen.component.maxWidth,
                borderTopLeftRadius: 20,
                borderTopRightRadius: 20,
                overflow: 'hidden',
                backgroundColor: Appearance.colors.sheet(),
                textAlign: 'center',
                zIndex: 9999,
                paddingBottom: Screen.safeArea.bottom + 15,
                ...Platform.OS !== 'web' && Utils.isMobile() === true && {
                    bottom: bottom
                },
                ...(Platform.OS === 'web' || Utils.isMobile() === false) && {
                    transform: [{ scale: scale }],
                    paddingBottom: 0,
                    borderBottomLeftRadius: 20,
                    borderBottomRightRadius: 20
                }
            }}>
                <View
                ref={sheetRef}
                style={{
                    width: '100%'
                }}>
                    {(title || message || icon) && (
                        <View style={{
                            alignItems: 'center',
                            padding: 15
                        }}>
                            {icon && (
                                <Image
                                source={icon}
                                style={{
                                    width: 50,
                                    height: 50,
                                    resizeMode: 'cover',
                                    borderRadius: 25,
                                    overflow: 'hidden',
                                    marginBottom: 12
                                }} />
                            )}
                            {typeof(title) === 'string' && (
                                <Text style={{
                                    ...Appearance.textStyles.panelTitle(),
                                    marginBottom: message ? 4 : 0,
                                    textAlign: 'center'
                                }}>{title}</Text>
                            )}
                            {typeof(message) === 'string' && (
                                <Text
                                numberOfLines={15}
                                style={{
                                    ...Appearance.textStyles.subTitle(),
                                    textAlign: 'center'
                                }}>{message}</Text>
                            )}
                        </View>
                    )}

                    {content && (
                        <View style={{
                            marginBottom: 15
                        }}>
                            {content}
                        </View>
                    )}
                    {sheetItems.filter(item => {
                        return item.visible !== false;
                    }).map(item => {
                        return (
                            <TouchableHighlight
                            key={item.key}
                            activeOpacity={0.9}
                            underlayColor={Appearance.colors.divider()}
                            onPress={onItemClick.bind(this, item.key)}>
                                <View style={{
                                    display: 'flex',
                                    width: '100%',
                                    height: 50,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderTopWidth: 1,
                                    borderTopColor: Appearance.colors.divider()
                                }}>
                                    <Text style={{
                                        width: '100%',
                                        ...Appearance.textStyles.title(),
                                        color: (item.style === 'destructive' || item.style === 'cancel') ? Appearance.colors.subText() : Appearance.colors.text(),
                                        fontWeight: (item.style === 'destructive' || item.style === 'cancel') ? '500' : '700',
                                        textAlign: 'center'
                                    }}>{item.title}</Text>
                                </View>
                            </TouchableHighlight>
                        );
                    })}
                </View>
            </Animated.View>
         </View>
    )
}
export default Sheet;
