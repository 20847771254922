import React from 'react';

import API from 'eCarra/files/api.js';
import Request from 'eCarra/files/Request/';
import Utils from 'eCarra/files/Utils.js';

class RouteManuever {

    type = null;
    distance = null;
    duration = null;
    streetNames = [];
    instruction = null;
    voiceAlert = null;
    hasToll = false;
    travel = {
        mode: null,
        type: null
    }

    _types = {
        kNone: 0,
        kStart: 1,
        kStartRight: 2,
        kStartLeft: 3,
        kDestination: 4,
        kDestinationRight: 5,
        kDestinationLeft: 6,
        kBecomes: 7,
        kContinue: 8,
        kSlightRight: 9,
        kRight: 10,
        kSharpRight: 11,
        kUturnRight: 12,
        kUturnLeft: 13,
        kSharpLeft: 14,
        kLeft: 15,
        kSlightLeft: 16,
        kRampStraight: 17,
        kRampRight: 18,
        kRampLeft: 19,
        kExitRight: 20,
        kExitLeft: 21,
        kStayStraight: 22,
        kStayRight: 23,
        kStayLeft: 24,
        kMerge: 25,
        kRoundaboutEnter: 26,
        kRoundaboutExit: 27,
        kFerryEnter: 28,
        kFerryExit: 29,
        kTransit: 30,
        kTransitTransfer: 31,
        kTransitRemainOn: 32,
        kTransitConnectionStart: 33,
        kTransitConnectionTransfer: 34,
        kTransitConnectionDestination: 35,
        kPostTransitConnectionDestination: 36,
        kMergeRight: 37,
        kMergeLeft: 38
    }

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.type = this.getType(props.type);
        this.hasToll = props.toll || false;
        this.duration = props.time;
        this.distance = props.length;
        this.instruction = props.instruction;
        this.voiceAlert = props.verbal_transition_alert_instruction;
        this.streetNames = props.street_names || []
        this.travel = {
            mode: props.travel_mode,
            type: props.travel_type
        }

        return this;
    }

    getType = type => {
        let types = Object.values(this._types);
        for(var i in types) {
            if(types[i] === type) {
                return Object.keys(this._types)[i];
            }
        }
    }
}

class RouteClass {

    legs = null;
    polyline = null;
    distance = null;
    duration = null;
    steps = [];

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.duration = props.summary.time;
        this.distance = props.summary.length;
        this.polyline = props.shape ? Utils.decodePolyline(props.shape, 6) : null;
        this.steps = props.maneuvers ? props.maneuvers.map(m => new RouteManuever().create(m)) : [];
        this.legs = props.legs ? props.legs.map(leg => ({
            ...leg,
            shape: Utils.decodePolyline(leg.shape, 6),
            maneuvers: leg.maneuvers.map(m => new RouteManuever().create(m))
        })) : [];

        return this;
    }
}

const getRoute = async (utils, coordinates) => {
    return new Promise(async (resolve, reject) => {
        try {
            let locations = JSON.stringify(coordinates.filter(c => {
                return c.location && c.location.latitude && c.location.longitude
            }).map(c => ({
                lat: c.location.latitude,
                lon: c.location.longitude
            })))

            if(locations.length < 2) {
                throw new Error('At least 2 locations a requird for routing');
                return;
            }

            let response = await Request.get(utils, '/valhalla/', {
                type: 'route',
                locations: locations,
                _operator: 'Valhalla'
            });
            resolve(new RouteClass().create(response));

        } catch(e) {
            reject(e);
        }
    })
}

export default {
    getRoute: getRoute,
    Route: {
        create: props => new RouteClass().create(props)
    }
}
