import React, { useEffect, useState } from 'react';
import { View, Text } from 'react-native';

import moment from 'moment';

import Appearance from 'eCarra/styles/Appearance.js';

const PickerComponent = ({ selected, items, onChange, style}) => {

    return (
        <View style={{
            width: '100%',
            height: 215,
            backgroundColor: Appearance.colors.background()
        }}/>
    )
}

export default PickerComponent;
