import React from 'react';
import { Keyboard, Platform } from 'react-native';
import Screen, { androidDeviceHeight } from 'eCarra/files/Screen.js';

class KeyboardManager {

    keyboardOpen = false;
    subscribers = {};

    constructor() {
        Keyboard.addListener('keyboardDidChangeFrame', this.onChange);
        Keyboard.addListener(Platform.OS === 'ios' ? 'keyboardWillShow' : 'keyboardDidShow', this.onShow);
        Keyboard.addListener(Platform.OS === 'ios' ? 'keyboardWillHide' : 'keyboardDidHide', this.onHide);
    }

    onShow = (props) => {
        if(props.isEventFromThisApp === false) {
            return;
        }
        this.keyboardOpen = true;
        if(Platform.OS === 'android') {

            // endCoordinates.height does not take into account android keyboard accessory views
            props = {
                ...props,
                endCoordinates: {
                    ...props.endCoordinates,
                    height: androidDeviceHeight - props.endCoordinates.screenY - Screen.safeArea.top + Screen.navigationBar
                }
            }
        }
        Object.values(this.subscribers).forEach(({ id, callbacks }) => {
            this.onVisibility(callbacks, true);
            if(typeof(callbacks.onShow) === 'function') {
                callbacks.onShow(props);
                if(this.subscribers[id]) {
                    this.subscribers[id].isOpen = true;
                }
            }
        })
    }
    onHide = (props) => {
        if(props.isEventFromThisApp === false) {
            return;
        }
        this.keyboardOpen = false;
        Object.values(this.subscribers).forEach(({ id, callbacks }) => {
            this.onVisibility(callbacks, false);
            if(typeof(callbacks.onHide) === 'function') {
                callbacks.onHide(props);
                if(this.subscribers[id]) {
                    this.subscribers[id].isOpen = true;
                }
            }
        })
    }
    onChange = (props) => {
        if(props.isEventFromThisApp === false) {
            return;
        }
        Object.values(this.subscribers).forEach(({ id, callbacks }) => {
            if(typeof(callbacks.onChange) === 'function') {
                if(this.subscribers[id].isOpen !== true) {
                    callbacks.onChange(props);
                }
            }
        })
    }
    onVisibility = (callbacks, visible) => {
        if(callbacks.onVisibility && typeof(callbacks.onVisibility) === 'function') {
            callbacks.onVisibility(visible);
        }
    }

    subscribe = (id, callbacks) => {
        this.subscribers[id] = {
            id: id,
            callbacks: callbacks
        };
    }

    unsubscribe = id => {
        delete this.subscribers[id];
    }
}
export default new KeyboardManager;
