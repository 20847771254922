import React from 'react';
import { AppState } from 'react-native';

import API from 'eCarra/files/api.js';
import Abstract from 'eCarra/classes/Abstract.js';
import Driver from 'eCarra/classes/Driver.js';
import Notification from 'eCarra/classes/Notification.js';
import Request from 'eCarra/files/Request/';
import SocketHelper from 'eCarra/files/SocketHelper.js';
import SocketIO from 'socket.io-client';
import User from 'eCarra/classes/User.js';
import Utils from 'eCarra/files/Utils.js';

class SocketManager {

    auth = null;
    sockets = null;
    subscribers = {};
    orders = [];
    routes = [];
    reservations = [];

    constructor() {
        return this;
    }

    setClient = path => {
        return `${path}-${API.get_client_id()}`;
    }

    addObjects = (type, ids) => {

        let newIDs = [];
        switch(type) {
            case 'orders':
            newIDs = ids.filter(id => !this.orders.includes(id));
            break;

            case 'routes':
            newIDs = ids.filter(id => !this.routes.includes(id));
            break;

            case 'reservations':
            newIDs = ids.filter(id => !this.reservations.includes(id));
            break;

            default:
            console.log('unsupported type for socket manager addition');
            return;
        }

        newIDs.forEach((id, index) => {
            switch(type) {
                case 'orders':
                this.sockets.orders.on(SocketHelper.clientPath(`on_status_change_${id}`), this.onStatusChange.bind(this, type));
                break;

                case 'routes':
                this.sockets.quick_scan.on(SocketHelper.clientPath(`on_status_change_${id}`), this.onStatusChange.bind(this, type));
                break;

                case 'reservations':
                this.sockets.reservations.on(SocketHelper.clientPath(`on_status_change_${id}`), this.onStatusChange.bind(this, type));
                break;
            }
        })
    }

    removeObjects = (type, ids) => {
        switch(type) {
            case 'orders':
            this.orders = this.orders.filter(id => !ids.includes(id));
            break;

            case 'routes':
            this.routes = this.routes.filter(id => !ids.includes(id));
            break;

            case 'reservations':
            this.reservations = this.reservations.filter(id => !ids.includes(id));
            break;

            default:
            console.log('unsupported type for socket manager addition');
            return;
        }

        ids.forEach(id => {
            switch(type) {
                case 'orders':
                this.sockets.orders.off(SocketHelper.clientPath(`on_status_change_${id}`), this.onStatusChange.bind(this, type));
                break;

                case 'routes':
                this.sockets.quick_scan.off(SocketHelper.clientPath(`on_status_change_${id}`), this.onStatusChange.bind(this, type));
                break;

                case 'reservations':
                this.sockets.reservations.off(SocketHelper.clientPath(`on_status_change_${id}`), this.onStatusChange.bind(this, type));
                break;
            }
        })
    }

    on = async (socketKey, key, callback) => {
        return new Promise(async (resolve, reject) => {
            try {
                await this.validateProps(socketKey, key);
                this.sockets[socketKey].on(this.setClient(key), callback);
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    persistOn = async (socketKey, key, callback) => {
        try {
            await this.on(socketKey, key, callback);
        } catch(e) {
            console.log(`waiting for ${socketKey}`)
            setTimeout(() => {
                this.persistOn(socketKey, key, callback)
            }, 500);
        }
    }

    off = async (socketKey, key, callback) => {
        return new Promise(async (resolve, reject) => {
            try {
                await this.validateProps(socketKey, key);
                this.sockets[socketKey].off(this.setClient(key), callback);
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    emit = async (socketKey, key, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                await this.validateProps(socketKey, key);
                this.sockets[socketKey].emit(this.setClient(key), { ...data });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    validateProps = async (socketKey, key) => {
        return new Promise((resolve, reject) => {
            try {
                if(!this.sockets[socketKey]) {
                    throw new Error(`${Utils.ucFirst(socketKey)} channel is not available`);
                    return
                }
                if(this.sockets[socketKey].connected !== true) {
                    throw new Error(`${Utils.ucFirst(socketKey)} channel is not connected`);
                    return;
                }
                if(!key) {
                    throw new Error(`Missing required props for channel`);
                    return;
                }
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    subscribe = (id, callbacks) => {
        this.subscribers[id] = {
            id: id,
            callbacks: callbacks
        };

        let keys = Object.keys(callbacks);
        keys.forEach(key => {

            switch(key) {
                case 'on_demand':
                if(this.sockets[key] && callbacks[key].on_driver_start) {
                    this.sockets[key].on(`on_driver_start_${callbacks[key].on_driver_start.id}-${API.get_client_id()}`, callbacks[key].on_driver_start.callback);
                }
                if(this.sockets[key] && callbacks[key].on_driver_not_available) {
                    this.sockets[key].on(`on_driver_not_available_${callbacks[key].on_driver_not_available.id}-${API.get_client_id()}`, callbacks[key].on_driver_not_available.callback);
                }
                break;
            }
        })

        return this;
    }

    updateSubscriber = (id, callbacks) => {
        this.subscribers[id] = {
            id: id,
            callbacks: callbacks
        };
        return this;
    }

    notifySubscribers = (key, props) => {

        Object.values(this.subscribers).forEach(({ id, callbacks }) => {
            if(typeof(callbacks[key]) === 'function') {
                callbacks[key](props);
            }
        })
    }

    unsubscribe = (id) => {

        if(!this.subscribers[id]) {
            return;
        }

        let callbacks = this.subscribers[id].callbacks;
        let keys = Object.keys(callbacks);
        keys.forEach(key => {

            switch(key) {
                case 'on_demand':
                if(this.sockets[key] && callbacks[key].on_driver_start) {
                    this.sockets[key].off(`on_driver_start_${callbacks[key].on_driver_start.id}-${API.get_client_id()}`, callbacks[key].on_driver_start.callback);
                }
                if(this.sockets[key] && callbacks[key].on_driver_not_available) {
                    this.sockets[key].off(`on_driver_not_available_${callbacks[key].on_driver_not_available.id}-${API.get_client_id()}`, callbacks[key].on_driver_not_available.callback);
                }
                break;
            }
        })
        delete this.subscribers[id];
    }

    onStatusChange = (type, data) => {
        if(!type || !data) {
            return;
        }
        try {
            let { order_id, route_id, reservation_id } = JSON.parse(data);
            this.notifySubscribers('on_status_change', {
                id: order_id || route_id || reservation_id,
                type: type,
                abstract: Abstract.create({
                    id: order_id || route_id || reservation_id,
                    type: type
                })
            });
        } catch(e) {
            console.error(e.message);
        }
    }

    subscribeToObjects = async (user, utils) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { ids } = await Request.get(utils, '/user/', {
                    type: 'socket_subscriptions'
                })

                if(!this.sockets || !this.sockets.orders || !this.sockets.quick_scan || !this.sockets.reservations ) {
                    throw new Error('One or more sockets are not connected');
                    return;
                }

                this.addObjects('orders', ids ? ids.orders : []);
                this.addObjects('routes', ids ? ids.routes : []);
                this.addObjects('reservations', ids ? ids.reservations : []);
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    setVehicleLocationPref = (enabled, props) => {
        this.sockets.vehicles.emit(SocketHelper.clientPath(enabled ? 'location_updates' : 'leave_location_updates'), {
            auth: this.auth,
            company_id: props.company_id,
            vehicle_id: props.id
        });
    }

    setVehicleDeepLinkPref = (enabled, props) => {
        this.sockets.vehicles.emit(SocketHelper.clientPath(enabled ? 'updates' : 'leave_updates'), {
            auth: this.auth,
            company_id: props.company_id,
            vehicle_id: props.id
        });
    }

    connect = async (utils, user) => {
        return new Promise(async (resolve, reject) => {
            this.auth = {
                user_id: user.user_id,
                client_id: API.get_client_id(),
                api_key: user.api_key,
                version: API.api
            };
            let sockets = {
                main: new SocketIO(API.sockets, {
                    forceNew: false,
                    jsonp: false,
                    transports: ['websocket'],
                    autoConnect: true,
                    query: { auth: JSON.stringify(this.auth) }
                })
            };

            sockets.main.on('error', (e) => {
                reject(new Error('Your device has lost connection to our live update server'));
            })

            sockets.main.on('connect_timeout', (e) => {
                reject(new Error('The request to connect to our live update server has timed-out'));
            })

            sockets.main.on('connect_error', (e) => {
                reject(new Error('We ran into an issue while trying to connect to our live update server'));
            })

            sockets.main.on('disconnect', (e) => {
                reject(new Error('Your device has lost connection to our live update server and we were unable to automatically reconnect'));
            })

            sockets.main.on('connect', async () => {

                // Levels
                sockets.level = Utils.apply(user.level, {
                    [User.level.admin]: () => {
                        return sockets.main.io.socket('/admin', {
                            forceNew: true,
                            jsonp: false,
                            transports: ['websocket'],
                            autoConnect: true,
                            query: { auth: JSON.stringify(this.auth) }
                        });
                    },
                    [User.level.driver]: () => {
                        return sockets.main.io.socket('/drivers', {
                            forceNew: true,
                            jsonp: false,
                            transports: ['websocket'],
                            autoConnect: true,
                            query: { auth: JSON.stringify(this.auth) }
                        });
                    },
                    default: () => {
                        return sockets.main.io.socket('/customers', {
                            forceNew: true,
                            jsonp: false,
                            transports: ['websocket'],
                            autoConnect: true,
                            query: { auth: JSON.stringify(this.auth) }
                        });
                    }
                })

                // System
                sockets.system = sockets.main.io.socket('/system', {
                    forceNew: true,
                    jsonp: false,
                    transports: ['websocket'],
                    autoConnect: true,
                    query: { auth: JSON.stringify(this.auth) }
                });
                sockets.system.on('connect', () => {

                    // User Online
                    sockets.system.on(SocketHelper.clientPath('online_check'), () => {
                        sockets.system.emit(SocketHelper.clientPath('online_check_response'), { auth: this.auth });
                    })
                })

                // Admin and Driver sockets
                if(user.level <= User.level.driver) {

                    // Sensors
                    sockets.sensors = sockets.main.io.socket('/sensors', {
                        forceNew: true,
                        jsonp: false,
                        transports: ['websocket'],
                        autoConnect: true,
                        query: { auth: JSON.stringify(this.auth) }
                    });

                    // Seeds
                    sockets.seeds = sockets.main.io.socket('/seeds', {
                        forceNew: true,
                        jsonp: false,
                        transports: ['websocket'],
                        autoConnect: true,
                        query: { auth: JSON.stringify(this.auth) }
                    });
                }

                // Vehicles
                // For admin, drivers, and customer who have vehicles on the platform
                if(user.level <= User.level.driver || (user.vehicles && user.vehicles.length > 0)) {
                    sockets.vehicles = sockets.main.io.socket('/vehicles', {
                        forceNew: true,
                        jsonp: false,
                        transports: ['websocket'],
                        autoConnect: true,
                        query: { auth: JSON.stringify(this.auth) }
                    });

                    this.sockets = sockets;
                    if(user.vehicles && user.vehicles.length > 0) {
                        user.vehicles.forEach(vehicle => {

                            if(!vehicle.prefs || vehicle.prefs.real_time_updates.location !== false) {
                                this.setVehicleLocationPref(true, {
                                    id: vehicle.id,
                                    user_id: vehicle.customer ? vehicle.customer.user_id : null,
                                    company_id: vehicle.company ? vehicle.company.id : null
                                });
                            }

                            if(!vehicle.prefs || vehicle.prefs.real_time_updates.deep_link !== false) {
                                this.setVehicleDeepLinkPref(true, {
                                    id: vehicle.id,
                                    user_id: vehicle.customer ? vehicle.customer.user_id : null,
                                    company_id: vehicle.company ? vehicle.company.id : null
                                });
                            }

                            sockets.vehicles.on(SocketHelper.clientPath(`on_update_${vehicle.id}`), data => {
                                try {
                                    let json = JSON.parse(data);
                                    this.notifySubscribers(`on_vehicle_update_${json.id}`, json);
                                } catch(e) {
                                    console.error(e.message);
                                }
                            })

                            sockets.vehicles.on(SocketHelper.clientPath(`on_location-update_${vehicle.id}`), data => {
                                try {
                                    this.notifySubscribers('on_vehicle_location_update', JSON.parse(data));
                                } catch(e) {
                                    console.error(e.message);
                                }
                            })
                        })
                    }
                }

                // Notifications
                sockets.notifications = sockets.main.io.socket('/notifications', {
                    forceNew: true,
                    jsonp: false,
                    transports: ['websocket'],
                    autoConnect: true,
                    query: { auth: JSON.stringify(this.auth) }
                });

                sockets.notifications.on(SocketHelper.clientPath(`on_notification_${user.user_id}`), (data) => {

                    try {
                        if(AppState.currentState === 'active') {

                            let json = JSON.parse(data);
                            let notification = Notification.create(json);
                            if(json.objects && json.objects.from_user) {
                                switch(json.objects.from_user.type) {
                                    case 'user':
                                    notification.from_user = User.create(json.objects.from_user.data);
                                    break;

                                    case 'driver':
                                    notification.from_user = Driver.create(json.objects.from_user.data);
                                    break;
                                }
                            }
                            this.notifySubscribers('on_notification', notification);
                        }
                    } catch(e) {
                        console.error(e.message);
                    }
                })

                // Community
                sockets.community = sockets.main.io.socket('/community', {
                    forceNew: true,
                    jsonp: false,
                    transports: ['websocket'],
                    autoConnect: true,
                    query: { auth: JSON.stringify(this.auth) }
                });

                // On Demand
                sockets.on_demand = sockets.main.io.socket('/on_demand', {
                    forceNew: true,
                    jsonp: false,
                    transports: ['websocket'],
                    autoConnect: true,
                    query: { auth: JSON.stringify(this.auth) }
                });
                if(user.level <= User.level.driver) {
                    sockets.on_demand.on(SocketHelper.clientPath('on_new'), data => {
                        try {
                            if(AppState.currentState === 'active') {
                                this.notifySubscribers('on_new_ride', {
                                    type: 'on_demand',
                                    data: JSON.parse(data)
                                });
                            }
                        } catch(e) {
                            console.error(e.message);
                        }
                    })
                }

                // Messaging
                sockets.messages = sockets.main.io.socket('/messages', {
                    forceNew: true,
                    jsonp: false,
                    transports: ['websocket'],
                    autoConnect: true,
                    query: { auth: JSON.stringify(this.auth) }
                });

                // Quick Scan
                sockets.quick_scan = sockets.main.io.socket('/quick_scan', {
                    forceNew: true,
                    jsonp: false,
                    transports: ['websocket'],
                    autoConnect: true,
                    query: { auth: JSON.stringify(self.socketAuth) }
                });

                if(user.level <= User.level.driver) {
                    sockets.quick_scan.on(SocketHelper.clientPath(`on_new_route_ride_${user.user_id}`), data => {
                        try {
                            this.notifySubscribers('on_new_ride', {
                                type: 'routes',
                                data: JSON.parse(data)
                            });
                        } catch(e) {
                            console.error(e.message);
                        }
                    })
                }

                // Locations
                sockets.locations = sockets.main.io.socket('/locations', {
                    forceNew: true,
                    jsonp: false,
                    transports: ['websocket'],
                    autoConnect: true,
                    query: { auth: JSON.stringify(this.auth) }
                });

                // Orders
                sockets.orders = sockets.main.io.socket('/orders', {
                    forceNew: true,
                    jsonp: false,
                    transports: ['websocket'],
                    autoConnect: true,
                    query: { auth: JSON.stringify(this.auth) }
                });

                sockets.orders.on('connect', () => {

                    if(user.level > User.level.driver) {
                        return
                    }

                    // New Order
                    sockets.orders.on(SocketHelper.clientPath('on_new'), data => {
                        try {
                            this.notifySubscribers('on_new_order', {
                                type: 'orders',
                                data: JSON.parse(data)
                            });
                        } catch(e) {
                            console.error(e.message);
                        }
                    });

                    // Completed Order
                    sockets.orders.on(SocketHelper.clientPath(`on_complete_${user.user_id}`), data => {
                        try {
                            this.notifySubscribers('on_trip_completed', JSON.parse(data));
                        } catch(e) {
                            console.error(e.message);
                        }
                    });

                    // Driver tip
                    sockets.orders.on(SocketHelper.clientPath(`on_driver_tip_${user.user_id}`), data => {
                        try {

                            let json = JSON.parse(data);
                            let { amount, driver, customer, order_id } = json;
                            if(!amount || !order_id || !customer || !driver) {
                                return;
                            }
                            this.notifySubscribers('on_driver_tip', {
                                amount: amount,
                                customer: User.create(customer)
                            });

                        } catch(e) {
                            console.error(e.message);
                        }
                    });
                })

                // Reservations
                sockets.reservations = sockets.main.io.socket('/reservations', {
                    forceNew: true,
                    jsonp: false,
                    transports: ['websocket'],
                    autoConnect: true,
                    query: { auth: JSON.stringify(this.auth) }
                });

                sockets.reservations.on(SocketHelper.clientPath('on_join'), () => {

                    if(user.level > User.level.driver) {
                        return
                    }

                    // New Reservation
                    sockets.reservations.on(SocketHelper.clientPath('on_new'), data => {
                        try {
                            this.notifySubscribers('on_new_ride', {
                                type: 'reservations',
                                data: JSON.parse(data)
                            });
                        } catch(e) {
                            console.error(e.message);
                        }
                    })

                    // Completed Reservation
                    sockets.reservations.on(SocketHelper.clientPath(`on_complete_${user.user_id}`), data => {
                        try {
                            this.notifySubscribers('on_trip_completed', JSON.parse(data));
                        } catch(e) {
                            console.error(e.message);
                        }
                    });

                    // Driver tip
                    sockets.reservations.on(SocketHelper.clientPath(`on_driver_tip_${user.user_id}`), data => {
                        try {

                            let json = JSON.parse(data);
                            let { amount, driver, customer, reservation_id } = json;
                            if(!amount || !reservation_id || !customer || !driver) {
                                return;
                            }
                            this.notifySubscribers('on_driver_tip', {
                                amount: amount,
                                customer: User.create(customer)
                            });

                        } catch(e) {
                            console.error(e.message);
                        }
                    })
                })

                this.sockets = sockets;
                await this.subscribeToObjects(user, utils);
                resolve();
            });
        })
    }
}

export default SocketManager;
