import React from 'react';
import moment from 'moment';
import User from 'eCarra/classes/User.js';

class MessageClass {

    id = null;
    from_user = null;
    to = [];
    date = null;
    text = null;
    attachment = null;
    data = null;
    viewed = false;

    constructor() {
        return this;
    }
    create = (props = {}) => {
        this.id = props.id;
        this.from_user = props.sent_by ? User.create(props.sent_by) : null;
        this.text = props.text;
        this.viewed = true;
        this.date = props.date ? moment(props.date) : null;
        this.data = props.data;
        this.attachment = props.attachment ? new MessageAttachmentClass().create(props.attachment) : null;
        return this;
    }
}

class MessageAttachmentClass {

    type = null;
    fileName = null;
    url = null;
    metadata = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.type = props.type;
        this.fileName = props.file_name;
        this.url = props.url;
        this.metadata = props.metadata;
        return this;
    }
}

class MessageThreadClass {

    id = null;
    date = null;
    order_id = null
    dispatchID = null;
    reservation_id = null;
    preview = null;
    lastMessage = null;
    users = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.date = props.date ? moment(props.date) : null;
        this.order_id = props.order_id;
        this.dispatchID = props.dispatch_id;
        this.reservation_id = props.reservation_id;
        this.preview = props.preview ? props.preview.map(message => new MessageClass().create(message)) : null;
        this.lastMessage = props.last_message ? new MessageClass().create(props.last_message) : null;
        this.users = props.users ? props.users.map(user => User.create(user)) : [];
        return this;
    }
}

export default {
    new: props => new MessageClass(),
    create: props => new MessageClass().create(props),
    Attachment: {
        create: props => new MessageAttachmentClass().create(props)
    },
    Thread: {
        create: props => new MessageThreadClass().create(props)
    }
};
