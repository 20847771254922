import React from 'react';
import moment from 'moment';

import Appearance from 'eCarra/styles/Appearance.js';
import Company from 'eCarra/classes/Company.js';
import StatusCodes from 'eCarra/files/StatusCodes.js';
import User from 'eCarra/classes/User.js';

class RewardClass {

    id = null;
    title = null;
    description = null;
    category = null;
    amount = null;
    cost = null;
    image = null;
    active = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.title = props.title;
        this.description = props.description;
        this.category = props.category;
        this.amount = props.amount;
        this.cost = props.cost;
        this.image = props.image ? { uri: props.image } : null;
        this.active = Boolean(props.active);
        return this;
    }
}

class RewardEventClass {

    id = null;
    date = null;
    customer = null;
    company = null;
    status = null;
    reward = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.date = props.date ? moment(props.date) : null;
        this.customer = props.customer ? User.create(props.customer) : null;
        this.company = props.company ? Company.create(props.company) : null;
        this.status = getStatus(props.status);
        this.reward = props.reward ? new RewardClass().create(props.reward) : null;
        return this;
    }
}

const getStatus = code => {

    // Appearance module could be null at runtime
    switch(code) {
        case StatusCodes.rewards.rejected:
        return {
            code: code,
            text: 'Rejected',
            realText: 'Rejected',
            color: Appearance ? Appearance.colors.red : null
        };

        case StatusCodes.rewards.approved:
        return {
            code: code,
            text: 'Approved',
            realText: 'Approved',
            color: Appearance ? Appearance.colors.primary() : null
        };

        case StatusCodes.rewards.cancelled:
        return {
            code: code,
            text: 'Cancelled',
            realText: 'Cancelled',
            color: '#812222'
        };

        case StatusCodes.rewards.completed:
        return {
            code: code,
            text: 'Completed',
            realText: 'Completed',
            color: Appearance ? Appearance.colors.darkGrey : null
        };

        default:
        return {
            code: null,
            text: 'Pending',
            realText: 'Pending',
            color: Appearance ? Appearance.colors.lightGrey : null
        }
    }
}

export default {
    create: props => new RewardClass().create(props),
    Event: {
        create: props => new RewardEventClass().create(props),
        getStatus: getStatus
    }
};
