import React from 'react';
import moment from 'moment';

import API from 'eCarra/files/api.js';
import Appearance from 'eCarra/styles/Appearance.js';
import OrderHost from 'eCarra/classes/OrderHost.js';
import OrderCategory from 'eCarra/classes/OrderCategory.js';
import OrderChannel from 'eCarra/classes/OrderChannel.js';
import Utils from 'eCarra/files/Utils.js';

class OrderOptionClass {

    id = null;
    copy_id = null;
    name = null;
    description = null;
    channel = null;
    host = null;
    category = null;
    cost = null;
    options = null;
    parameters = null;
    featured = null;
    requests = null; // reserved for order customizations
    images = null;
    active = null;
    seeds = {};

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.copy_id = `${props.id}-${moment().unix()}-${Math.random()}`;
        this.name = props.name;
        this.description = props.description;
        this.channel = props.channel ? OrderChannel.create(props.channel) : null;
        this.category = props.category;
        this.cost = props.cost;
        this.host = props.host ? OrderHost.create(props.host) : null;
        this.images = props.images ? props.images.map(image => ({ uri: image })) : null;
        this.parameters = props.parameters;
        this.featured = props.featured;
        this.requests = props.requests;
        this.active = props.active;
        this.options = props.options;
        return this;
    }

    copy = (props = {}) => {
        this.id = props.id;
        this.copy_id = `${props.id}-${moment().unix()}-${Math.random()}`;
        this.name = props.name;
        this.description = props.description;
        this.host = props.host;
        this.category = props.category;
        this.cost = props.cost;
        this.host = props.host;
        this.images = props.images;
        this.parameters = props.parameters;
        this.featured = props.featured;
        this.requests = props.requests;
        this.active = props.active;
        this.options = props.options ? props.options.map(opt => ({ ...opt })) : null;
        return this;
    }

    getCustomizations = () => {
        if(!this.options) {
            return [];
        }
        return this.options.reduce((array, option) => {
            switch(option.type) {
                case 'list':
                return option.options.reduce((array, opt) => {
                    if(opt.selected) {
                        array.push({
                            name: option.title,
                            title: opt.title,
                            cost: opt.cost
                        });
                    }
                    return array;
                }, array);

                case 'text-area':
                array.push({
                    name: option.title,
                    cost: option.cost,
                    value: option.value
                });
                break;
            }
            return array;
        }, []);
    }

    getCustomizedCost = () => {
        if(!this.options) {
            return this.cost;
        }
        return this.options.reduce((total, option) => {
            switch(option.type) {
                case 'list':
                return option.options.reduce((total, opt) => {
                    return total += (opt.selected && opt.cost ? opt.cost : 0);
                }, total);

                case 'text-area':
                total += (option.cost || 0);
                break;
            }
            return total;
        }, this.cost);
    }
}

export const orderOptionItemTypes = [{
    key: 'text-area',
    title: 'Show a text area to write a response'
},{
    key: 'list',
    title: 'Show a list to select one or more items'
}];

export const orderOptionItemSubTypes = [{
    key: 'amount',
    title: 'Show the option to choose the amount'
},{
    key: 'checkbox',
    title: 'Show a checkbox to select the item'
}];

const optionToJSON = (props = {}) => {
    let { id, options = [], requests } = props;
    return {
        id: id,
        requests: requests,
        options: options.map(opt => ({
            id: opt.id,
            value: opt.value,
            ...opt.options && {
                options: opt.options.filter(entry => entry.selected).map(entry => ({
                    id: entry.id
                }))
            }
        }))
    }
}

export default {
    create: props => new OrderOptionClass().create(props),
    toJSON: optionToJSON
};
