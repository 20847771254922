import React from 'react';
import moment from 'moment';

class ModularContentClass {

    id = null;
    title = null;
    message = null;
    description = null;
    category = null;
    url = null;
    mobileAction = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.title = props.title;
        this.message = props.message;
        this.description = props.description;
        this.category = props.category;
        this.url = props.url;
        this.mobileAction = props.mobile_action;
        return this;
    }
}
export default {
    create: props => new ModularContentClass().create(props)
};
