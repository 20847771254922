import React, { useRef, useState, useEffect } from 'react';
import { Animated, Image, Keyboard, Linking, Platform, ScrollView, Text, Vibration, View } from 'react-native';

import moment from 'moment';
import update from 'immutability-helper';
import { fetchReservations } from 'eCarra/managers/Reservations.js';

import API from 'eCarra/files/api.js';
import Appearance from 'eCarra/styles/Appearance.js';
import { Call, Text as TextMessage } from 'react-native-openanything';
import CameraRoll from 'eCarra/files/CameraRoll/';
import Clipboard from 'eCarra/files/Clipboard/';
import HeaderWithButton from 'eCarra/views/HeaderWithButton.js';
import * as ImagePicker from 'expo-image-picker';
import KeyboardManager from 'eCarra/files/KeyboardManager.js';
import Layer, { LayerShell, LayerToolbar, LayerToolbarHeight } from 'eCarra/structure/Layer.js';
import LottieView from 'eCarra/views/Lottie/';
import { Map } from 'eCarra/views/Maps/';
import Message from 'eCarra/classes/Message.js';
import Order from 'eCarra/classes/Order.js';
import Panel from 'eCarra/structure/Panel.js';
import Permissions, { PERMISSIONS, RESULTS } from 'eCarra/files/Permissions/';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import Request from 'eCarra/files/Request/';
import Reservation from 'eCarra/classes/Reservation.js';
import RNFetchBlob from 'eCarra/files/FetchBlob/';
import Screen from 'eCarra/files/Screen.js';
import Abstract, { buildTag } from 'eCarra/classes/Abstract.js';
import SocketHelper from 'eCarra/files/SocketHelper.js';
import Sound from 'eCarra/files/Sound/';
import TextField from 'eCarra/views/TextField.js';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import Utils from 'eCarra/files/Utils.js';
import Views from 'eCarra/views/Main.js';

const LocationIcon = require('eCarra/files/lottie/location-icon.json');
const SendMessageButton = require('eCarra/files/lottie/send-message.json');
const ImagePickerButton = require('eCarra/files/lottie/image-picker-grey.json');
const ShareLocationButton = require('eCarra/files/lottie/share-location-grey.json');

export const MessageComponent = ({ delay, showDate, user, message, style, onUserPress, onPress }) => {

    const [opacity, setOpacity] = useState(new Animated.Value(0));
    const [left, setLeft] = useState(new Animated.Value(-Screen.layer.maxWidth));
    const [right, setRight] = useState(new Animated.Value(-Screen.layer.maxWidth));
    const [locationSource, setLocationSource] = useState(null);

    const formatDate = () => {

        if(moment(message.date).isSame(moment(), 'day')) {
            return moment(message.date).format('h:mma');
        }
        if(moment(message.date).isSame(moment().subtract(1, 'days'), 'day')) {
            return moment(message.date).format('[Yesterday at] h:mma');
        }
        if(moment(message.date) > moment().subtract(7, 'days')) {
            return moment(message.date).format('dddd [at] h:mma');
        }
        if(moment(message.date).isSame(moment(), 'year')) {
            return moment(message.date).format('MMMM Do [at] h:mma');
        }
        return moment(message.date).format('MMMM Do, YYYY [at] h:mma');
    }

    const getContent = () => {

        if(message.attachment) {
            switch(message.attachment.type) {
                case 'image':
                return (
                    <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={onPress.bind(this, message)}
                    style={{
                        width: message.attachment.metadata && message.attachment.metadata.width > message.attachment.metadata.height ? 200 : 150,
                        height: message.attachment.metadata && message.attachment.metadata.width > message.attachment.metadata.height ? 150 : 200,
                        borderRadius: 10,
                        borderColor: Appearance.colors.divider(),
                        borderWidth: 1,
                        overflow: 'hidden'
                    }}>
                        <Image
                        source={{ uri: message.attachment.url }}
                        style={{
                            width: '100%',
                            height: '100%',
                            resizeMode: 'cover'
                        }} />
                    </TouchableOpacity>
                )
            }
        }

        if(message.data && message.data.type) {
            switch(message.data.type) {
                case 'location':
                return (
                    <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={onPress.bind(this, message)}
                    style={{
                        width: 125,
                        height: 125,
                        borderWidth: 1.5,
                        borderColor: Appearance.colors.softBorder(),
                        backgroundColor: Appearance.colors.divider(),
                        borderRadius: 10,
                        overflow: 'hidden',
                        padding: 25
                    }}>
                        {locationSource && (
                            <LottieView
                            autoPlay={true}
                            loop={true}
                            source={locationSource}
                            duration={5000}
                            style={{
                                width: '100%',
                                height: '100%'
                            }}/>
                        )}
                    </TouchableOpacity>
                )
            }
        }

        return (
            <TouchableOpacity
            activeOpacity={0.6}
            onPress={onPress.bind(this, message)}
            style={{
                backgroundColor: message.data && message.data.deleted ? Appearance.colors.red : (message.from_user.user_id === user.user_id ? Appearance.colors.blue : Appearance.colors.lighterGrey),
                borderRadius: 20,
                paddingHorizontal: 15,
                paddingVertical: 8,
                overflow: 'hidden',
                maxWidth: '66%'
            }}>
                <Text style={{
                    ...Appearance.textStyles.title(),
                    color: message.from_user.user_id === user.user_id || (message.data && message.data.deleted) ? 'white' : Appearance.colors.darkGrey,
                    ...Platform.OS === 'web' && {
                        whiteSpace: 'normal'
                    }
                }}>
                    {message.data && message.data.deleted ? 'This message was deleted' : message.text}
                </Text>
            </TouchableOpacity>
        )
    }

    useEffect(() => {

        setLocationSource(Utils.replaceLottieColor(LocationIcon, '0.290196078431,0.290196078431,0.290196078431', message.from_user.user_id === user.user_id ? Appearance.colors.blue : Appearance.colors.grey()));

        setTimeout(() => {
            Animated.spring(opacity, {
                toValue: 1,
                duration: 250,
                useNativeDriver: false
            }).start();

            Animated.spring(message.from_user.user_id === user.user_id ? right : left, {
                toValue: 0,
                duration: 750,
                friction: 10,
                useNativeDriver: false
            }).start();

        }, delay)
    }, [])

    return (
        <Animated.View style={{
            width: '100%',
            opacity: opacity,
            ...message.from_user.user_id !== user.user_id && {
                left: left
            },
            ...message.from_user.user_id === user.user_id && {
                right: right
            },
            ...style
        }}>
            {showDate && (
                <View style={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 15
                }}>
                    <Text style={Appearance.textStyles.subTitle()}>{formatDate()}</Text>
                </View>
            )}
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                justifyContent: message.from_user.user_id === user.user_id ? 'flex-end' : 'flex-start'
            }}>
                {message.from_user.user_id !== user.user_id && (
                    <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={onUserPress ? onUserPress.bind(this, message.from_user) : null}
                    style={{
                        width: 32,
                        height: 32,
                        marginRight: 8
                    }}>
                        <Image
                        source={message.from_user.avatar}
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: 15,
                            overflow: 'hidden',
                            resizeMode: 'cover'
                        }}/>
                    </TouchableOpacity>
                )}
                {getContent()}
                {message.from_user.user_id === user.user_id && (
                    <Image
                    source={message.from_user.avatar}
                    style={{
                        width: 32,
                        height: 32,
                        borderRadius: 15,
                        overflow: 'hidden',
                        resizeMode: 'cover',
                        marginLeft: 8,
                        alignSelf: message.attachment || (message.data && message.data.type) ? 'flex-end' : 'center'
                    }}/>
                )}
            </View>
        </Animated.View>
    )
}

// Panels
export const MessageThreadsList = ({ utils }) => {

    const panelID = `messageThreads`;
    const [loading, setLoading] = useState(true);
    const [threads, setThreads] = useState([]);
    const [orders, setOrders] = useState([]);

    const onNewMessage = async () => {
        try {
            let response = await Request.get(utils, '/resources/',  {
                type: 'message_targets',
                date: moment().format('YYYY-MM-DD HH:mm:ss')
            });
            let orders = response.orders.map(order => Order.create(order));
            let reservations = response.reservations.map(reservation => Reservation.create(reservation));

            let layerID = 'new-message';
            utils.layer.open({
                id: layerID,
                Component: LayerShell.bind(this, {
                    layerID: layerID,
                    children: (
                        <View style={{
                            alignItems: 'center',
                            marginTop: 20
                        }}>
                            <Text style={{
                                ...Appearance.textStyles.panelTitle(),
                                fontSize: 16
                            }}>{'New Message'}</Text>
                            <Text style={{
                                ...Appearance.textStyles.subTitle(),
                                marginBottom: 20,
                                textAlign: 'center'
                            }}>{'We can connect you with your Driver for your upcoming Orders and Reservations. Tap an Order or Reservation from the list below to get started.'}</Text>

                            <View style={Appearance.styles.panel()}>
                                {orders && orders.length > 0
                                    ?
                                    orders.map((order, index) => {
                                        return (
                                            Views.entry({
                                                key: index,
                                                title: order.destination.name && order.destination.name !== 'Current Location' ? order.destination.name : order.destination.address,
                                                subTitle: `Ordered from ${order.host.name}`,
                                                icon: {
                                                    path: order.host.image
                                                },
                                                badge: {
                                                    text: `Order #${order.id}`,
                                                    color: Appearance.colors.grey()
                                                },
                                                onPress: onCreateNewMessage.bind(this, Abstract.create({
                                                    type: 'orders',
                                                    object: order
                                                })),
                                                bottomBorder: index !== orders.length - 1
                                            })
                                        )
                                    })
                                    :
                                    Views.entry({
                                        title: 'No Orders Found',
                                        subTitle: 'It looks like you do not have any upcoming Orders scheduled',
                                        hideIcon: true,
                                        bottomBorder: false,
                                        propStyles: {
                                            subTitle: {
                                                numberOfLines: 2
                                            }
                                        }
                                    })
                                }
                            </View>

                            <View style={{
                                ...Appearance.styles.panel(),
                                marginTop: 12
                            }}>
                                {reservations && reservations.length > 0
                                    ?
                                    reservations.map((reservation, index) => {
                                        return (
                                            Views.entry({
                                                key: index,
                                                title: reservation.destination.name && reservation.destination.name !== 'Current Location' ? reservation.destination.name : (order.destination.address || 'Destination Not Chosen'),
                                                subTitle: moment(reservation.pickup_date).format('MMMM Do, YYYY [at] h:mma'),
                                                icon: {
                                                    path: require('eCarra/images/tesla-model-x.png'),
                                                    style: Appearance.icons.vehicle()
                                                },
                                                badge: {
                                                    text: `Reservation #${reservation.id}`,
                                                    color: Appearance.colors.grey()
                                                },
                                                onPress: onCreateNewMessage.bind(this, Abstract.create({
                                                    type: 'reservations',
                                                    object: reservation
                                                })),
                                                bottomBorder: index !== reservations.length - 1
                                            })
                                        )
                                    })
                                    :
                                    Views.entry({
                                        title: 'No Reservations Found',
                                        subTitle: 'It looks like you do not have any upcoming Reservations scheduled',
                                        hideIcon: true,
                                        bottomBorder: false,
                                        propStyles: {
                                            subTitle: {
                                                numberOfLines: 2
                                            }
                                        }
                                    })
                                }
                            </View>
                        </View>
                    )
                })
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your messages. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onCreateNewMessage = (abstract) => {

        if(!abstract.object.driver) {
            utils.alert.show({
                title: 'Just a Second',
                message: Utils.apply(abstract.type, {
                    orders: () => `It looks like we have not yet assigned a driver to your ${abstract.object.channel.name} Order. Would you like to get into contact with ${utils.client.get().name} Support?`,
                    reservations: () => `It looks like we have not yet assigned a driver to your Reservation. Would you like to get into contact with ${utils.client.get().name} Support?`
                }),
                buttons: [{
                    key: 'support',
                    title: 'Contact Support',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Maybe Later',
                    style: 'cancel'
                }],
                onPress: key => {
                    if(key === 'support') {
                        Utils.showSupportOptions(utils, {
                            message: Utils.apply(abstract.type, {
                                orders: () => `I had a question about ${abstract.object.channel.name} Order #${abstract.getID()}.`,
                                reservations: () => `I had a question about Reservation #${abstract.getID()}.`
                            })
                        });
                        return;
                    }
                }
            });
            return;
        }

        utils.events.emit('onLayerAction', {
            action: 'close',
            layerID: 'new-message'
        })
        setTimeout(() => {
            utils.layer.messaging(abstract);
        }, 750);
    }

    const onThreadPress = async ({ order_id, reservation_id }) => {
        try {
            let { order, reservation } = await Utils.createMultiple(utils, {
                order_id: order_id,
                reservation_id: reservation_id
            });
            if(order_id && !order) {
                throw new Error('Order not found');
                return;
            }
            if(reservation_id && !reservation) {
                throw new Error('Reservation not found');
                return;
            }
            let abstract = Abstract.create({
                type: order ? 'orders' : 'reservations',
                object: order || reservation
            });
            utils.layer.openFloating({
                id: `messages-${abstract.getTag()}`,
                abstract: abstract,
                Component: Messaging
            });
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your messages. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getContent = () => {
        let targets = threads.filter(thread => {
            return thread.lastMessage ? true : false;
        })
        if(targets.length === 0) {
            return (
                <View style={Appearance.styles.panel()}>
                    {Views.entry({
                        title: 'Nothing to see here',
                        subTitle: 'No messages were found for your account',
                        hideIcon: true,
                        bottomBorder: false
                    })}
                </View>
            )
        }
        return targets.map((thread, index, threads) => {
            let title = 'New Message';
            if(thread.order_id) {
                title = `Order #${thread.order_id}`
            } else if(thread.reservation_id) {
                title = `Reservation #${thread.reservation_id}`
            }
            return (
                <View
                key={index}
                style={{
                    ...Appearance.styles.panel(),
                    marginBottom: 8
                }}>
                    {Views.entry({
                        title: title,
                        subTitle: getMessageText(thread.lastMessage),
                        icon: {
                            path: thread.lastMessage.from_user && thread.lastMessage.from_user.avatar
                        },
                        bottomBorder: false,
                        onPress: onThreadPress.bind(this, thread),
                        rightContent: (
                            <View style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                                {(Platform.OS === 'web' || Utils.isMobile() === false) && (
                                    <Text style={{
                                        ...Appearance.textStyles.subTitle(),
                                        marginLeft: 12
                                    }}>{getMessageDate(thread)}</Text>
                                )}
                                <Image
                                source={require('eCarra/images/next-arrow-grey-small.png')}
                                style={{
                                    width: 15,
                                    height: 15,
                                    resizeMode: 'contain',
                                    tintColor: Appearance.themeStyle() === 'dark' ? 'white' : Appearance.colors.grey(),
                                    marginLeft: 8
                                }}/>
                            </View>
                        ),
                    })}
                </View>
            )
        })
    }

    const getMessageDate = message => {
        let date = moment(message.date);
        if(moment().isSame(date, 'day')) {
            return date.format('h:mma');
        }
        if(date > moment().subtract(7, 'days')) {
            return date.format('dddd');
        }
        return date.format('M/D/YY');
    }

    const getMessageText = message => {
        if(!message.from_user) {
            return message.text;
        }
        if(message.from_user.user_id == utils.user.get().user_id) {
            return message.attachment ? `You ${message.text.toLowerCase()}` : `You said "${message.text}"`;
        }
        return message.attachment ? `${message.from_user.first_name} ${message.text.toLowerCase()}` : `${message.from_user.first_name} said "${message.text}"`;
    }

    const fetchThreads = async () => {
        try {
            let { threads } = await Request.get(utils, '/messages/', {
                type: 'threads'
            });
            setLoading(false);
            setThreads(threads.map(thread => Message.Thread.create(thread)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your Messages. ${e.message || ''}`
            })
        }
    }

    useEffect(() => {
        fetchThreads();
        utils.content.subscribe(panelID, ['messages', 'messageThreads'], {
            onFetch: fetchThreads,
            onUpdate: abstract => {
                console.log(abstract.object.lastMessage);
                setThreads(threads => {
                    return threads.map(thread => {
                        if(thread.id !== abstract.getID()) {
                            return thread;
                        }
                        return {
                            ...thread,
                            lastMessage: abstract.object.lastMessage
                        }
                    });
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`Recent Messages`}
        options={{
            loading: loading,
            removeOverflow: true,
            shouldStyle: false
        }}>
            {getContent()}
        </Panel>
    )
}

// Layers
export const Messaging = ({ closeLayer, floatingLayerState, setFloatingLayerState, utils, abstract }) => {

    const layerID = `messages-${abstract.getTag()}`;
    const extraPadding = Platform.OS === 'web' || Utils.isMobile() === false ? 30 : 0;

    const [text, setText] = useState(null);
    const [messages, setMessages] = useState([]);
    const [sending, setSending] = useState(false);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    const scrollView = useRef(null);
    const shareLocation = useRef(null);
    const imagePicker = useRef(null);
    const sendMessage = useRef(null);

    const [sendMessageSource, setSendMessageSource] = useState(null);
    const [imagePickerSource, setImagePickerSource] = useState(null);
    const [shareLocationSource, setShareLocationSource] = useState(null);
    const [maxHeight, setMaxHeight] = useState(new Animated.Value(0));
    const [paddingBottom, setPaddingBottom] = useState(new Animated.Value(15 + Screen.safeArea.bottom));
    const [textField, setTextField] = useState({
        left: new Animated.Value(Screen.width() / 2),
        right: new Animated.Value(Screen.width() / 2)
    })

    const onUserPress = (user) => {
        utils.sheet.show({
            title: user.full_name,
            message: `Would you like to contact ${user.first_name}?`,
            icon: user.avatar,
            items: [{
                key: 'call',
                title: 'Call',
                style: 'default'
            },{
                key: 'text',
                title: 'Text Message',
                style: 'default'
            }]
        }, (key) => {
            if(key === 'call') {
                Call(user.phone_number, true).catch(e => {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue setting up your call. ${e.message || 'An unknown error occurred'}`
                    })
                })

            } else if(key === 'text') {
                TextMessage(user.phone_number).catch(e => {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue setting up your text message. ${e.message || 'An unknown error occurred'}`
                    })
                })
            }
        })
    }

    const onMessagePress = (message) => {

        let title = false;
        let subTitle = false;
        let items = [{
            key: 'copy',
            title: 'Copy',
            style: 'default'
        }];

        if(message.attachment) {
            switch(message.attachment.type) {
                case 'image':
                items.push({
                    key: 'save',
                    title: 'Save to Photos',
                    style: 'default'
                });
                break;
            }
        }

        if(message.data) {
            switch(message.data.type) {
                case 'location':
                title = message.from_user.user_id === utils.user.get().user_id ? 'You Sent Your Location' : `${message.from_user.first_name} Sent Their Location`,
                subTitle = message.data.address;
                items = [{
                    key: 'maps',
                    title: 'Open in Maps',
                    visible: message.data.address ? true : false,
                    style: 'default'
                },{
                    key: 'copy',
                    title: 'Copy Address',
                    style: 'default'
                }]
                break;
            }
        }

        if(message.from_user.user_id === utils.user.get().user_id) {
            items.push({
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            })
        }

        utils.sheet.show({
            title: title,
            message: subTitle,
            items: items
        }, async key => {

            if(key === 'save') {
                onSaveToPhotos(message);
                return;
            }
            if(key === 'maps') {
                try {
                    let address = message.data.address;
                    let url = Platform.OS === 'ios' ? `https://maps.apple.com/?address=${encodeURI(address)}` : `https://www.google.com/maps/place/${encodeURI(address)}`;
                    await Linking.openURL(url);
                } catch(e) {
                    console.error(e.message);
                }
                return;
            }
            if(key === 'delete') {
                onDeleteMessage(message);
                return;
            }
            if(key === 'copy') {

                if(message.attachment && message.attachment.type === 'image') {
                    Clipboard.setString(message.attachment.url);
                    return;
                }

                if(message.data && message.data.type === 'location') {
                    Clipboard.setString(message.data.address);
                    return;
                }
                Clipboard.setString(message.text);
            }
        })
    }

    const onSaveToPhotos = async message => {
        try {

            let result = await Permissions.check(Platform.OS == 'ios' ? PERMISSIONS.IOS.PHOTO_LIBRARY : PERMISSIONS.ANDROID.WRITE_EXTERNAL_STORAGE);
            if(result !== RESULTS.GRANTED) {
                throw new Error('Photo library permission is required before we can save your photo');
                return;
            }

            let { data } = await RNFetchBlob.config({
                fileCache: true,
                appendExt: 'png',
            }).fetch('GET', message.attachment.url);

            let resource = await CameraRoll.save(data, 'photo');
            utils.alert.show({
                title: 'All Done!',
                message: 'This image has been saved to your photo library'
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving this photo. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onDeleteMessage = message => {
        utils.alert.show({
            title: 'Delete Message',
            message: 'Are you sure that you want to delete this message? This can not be undone',
            buttons: [{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onPress: async key => {
                if(key == 'delete') {
                    onDeleteMessageConfirm(message);
                    return;
                }
            }
        })
    }

    const onDeleteMessageConfirm = async message => {
        try {
            await Request.post(utils, '/message/', {
                type: 'delete',
                id: message.id,
                abstract: {
                    id: abstract.getID(),
                    type: abstract.type
                }
            });

            // fitler out delete message
            setMessages(messages => update(messages, {
                $apply: messages => messages.filter(m => m.id !== message.id)
            }));

            // sound and vibration
            Vibration.vibrate(500);
            Sound.Message.Deleted.play(success => {});

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this message. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onChooseImage = async () => {
        try {
            imagePicker.current.play(40, 90);
            let result = await ImagePicker.launchImageLibraryAsync({
                allowsEditing: true,
                aspect: [19.5, 9],
                quality: 1,
                exif: true,
                base64: true
            });
            if(result.cancelled) {
                return;
            }

            if(!result.uri || (!result.uri.includes('.png') && !result.uri.includes('.jpg'))) {
                throw new Error('Messaging supports PNG and JPG images. It looks like the image that you chose is in an unsupported format');
                return;
            }
            let message = Message.new();
            message.text = 'Sent an Image';
            message.attachment = {
                url: result.uri,
                type: result.type,
                file_type: `image/${result.uri.includes('.png') ? 'png' : 'jpeg'}`,
                image_data: result.base64,
                metadata: {
                    ...result.exif,
                    width: result.width,
                    height: result.height,
                }
            }

            sendMessage.current.play(40, 90);
            await sendMessageToServer(message);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `We were unable to load your photo library. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSendLocation = () => {

        Keyboard.dismiss();
        utils.alert.show({
            title: 'Send My Location',
            message: `Would you like to share your location in this message? Location sharing requires location permission for the ${utils.client.get().name} app`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: 'destructive'
            }],
            onPress: async key => {
                if(key === 'confirm') {
                    try {
                        let { latitude, longitude } = await utils.location.get();
                        let result = await Utils.geocode(utils, {
                            latitude: latitude,
                            longitude: longitude
                        });

                        let { address, city, state, zipcode } = result;
                        let message = Message.new();
                        message.text = '...';
                        message.data = {
                            type: 'location',
                            address: address,
                            city: city,
                            state: state,
                            zipcode: zipcode,
                            latitude: latitude,
                            longitude: longitude
                        }

                        shareLocation.current.play(40, 90);
                        sendMessageToServer(message);

                    } catch(e) {
                        utils.alert.show({
                            title: 'Oops!',
                            message: e.message
                        })
                    }
                }
            }
        })
    }

    const onSendMessage = () => {

        if(!text || text.length === 0) {
            return;
        }

        let message = Message.new();
        message.text = text;

        sendMessage.current.play(40, 90);
        sendMessageToServer(message);
    }

    const sendMessageToServer = async message => {
        try {

            // update message props
            message.id = moment().unix();
            message.date = moment();
            message.from_user = utils.user.get();

            // notify user that message is sending
            Sound.Message.Sent.play();

            // update message text field and update messages list
            setText('');
            setMessages(messages => update(messages, {
                $push: [message]
            }));

            // submit message to server
            let { attachment, formatted, id } = await Request.post(utils, '/message/', {
                type: 'new',
                message: message.text,
                data: message.data,
                ...abstract.type === 'orders' && {
                    order_id: abstract.getID()
                },
                ...abstract.type === 'reservations' && {
                    reservation_id: abstract.getID()
                },
                ...message.attachment && {
                    attachment: {
                        metadata: message.attachment.metadata,
                        file_type: message.attachment.file_type,
                        image_data: message.attachment.image_data
                    }
                }
            });

            // update id for message in messages list
            setMessages(messages => {
                let index = messages.findIndex(m => m.id === message.id);
                if(index < 0) {
                    return messages;
                }
                return update(messages, {
                    [index]: {
                        id: {
                            $set: id
                        },
                        text: {
                            $set: formatted
                        },
                        attachment: {
                            $set: attachment ? Message.Attachment.create(attachment) : null
                        }
                    }
                });
            });

        } catch(e) {
            Keyboard.dismiss();
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue sending your message. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onCloseMessaging = () => {
        utils.content.fetch('messages');
        closeLayer(layerID);
    }

    const fetchMessages = async () => {
        try {
            let { messages } = await Request.get(utils, '/messages/', {
                type: 'all',
                order_id: abstract.type === 'orders' && abstract.getID(),
                reservation_id: abstract.type === 'reservations' && abstract.getID()
            });

            setLoading(false);
            setMessages(messages.map(message => Message.create(message)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your messages. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onNewMessage = data => {
        try {
            let payload = JSON.parse(data);
            if(abstract.getTag() !== buildTag(payload.abstract)) {
                return;
            }

            // prevent moving forward is sender was current user
            if(payload.from_user && payload.from_user.user_id === utils.user.get().user_id) {
                return;
            }
            if(payload.sent_by && payload.sent_by.user_id === utils.user.get().user_id) {
                return;
            }

            // create message and add to end of list of messages
            let message = Message.create(payload);
            setMessages(messages => update(messages, {
                $push: [message]
            }));

            // start sound alert and vibration pattern
            Vibration.vibrate(500);
            Sound.Message.Received.play(success => { })

        } catch(e) {
            console.error(e.message);
        }
    }

    const onMessageDeleted = response => {
        try {
            let json = JSON.parse(response);
            if(abstract.getTag() !== buildTag(json.abstract)) {
                return;
            }
            setMessages(messages => messages.map(message => {
                if(message.id === json.id) {
                    message.data = {
                        ...message.data,
                        deleted: true
                    }
                }
                return message;
            }));

            // Sound and Vibration
            Vibration.vibrate(500);
            Sound.Message.Deleted.Sound.play(success => {
                //console.log(success);
            })

        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {

        fetchMessages();
        setTimeout(() => {
            setFloatingLayerState('open');
        },500);

        setImagePickerSource(Utils.replaceLottieColor(ImagePickerButton, '0.607843137255,0.607843137255,0.607843137255', Appearance.colors.grey()));
        setShareLocationSource(Utils.replaceLottieColor(ShareLocationButton, '0.290196078431,0.290196078431,0.290196078431', Appearance.colors.grey()));
        setSendMessageSource(Utils.replaceLottieColor(SendMessageButton, '0.423529411765,0.749019607843,0.4', Appearance.colors.blue));

        // sockets
        utils.sockets.emit('messages', 'join', {
            id: abstract.getID(),
            type: abstract.type
        });
        utils.sockets.on('messages', `on_new_message_${abstract.type}_${abstract.getID()}`, onNewMessage);
        utils.sockets.on('messages', `on_delete_message_${abstract.type}_${abstract.getID()}`, onMessageDeleted);

        // keyboard
        utils.keyboard.subscribe(layerID, {
            onShow: (e) => {
                Animated.spring(paddingBottom, {
                    toValue: 15 + e.endCoordinates.height,
                    friction: 10,
                    duration: 150,
                    useNativeDriver: false
                }).start()
                setTimeout(() => {
                    if(scrollView.current) {
                        scrollView.current.scrollToEnd();
                    }
                }, 500)
            },
            onChange: (e) => {
                Animated.spring(paddingBottom, {
                    toValue: 15 + e.endCoordinates.height,
                    friction: 10,
                    duration: 150,
                    useNativeDriver: false
                }).start();
                setTimeout(() => {
                    if(scrollView.current) {
                        scrollView.current.scrollToEnd();
                    }
                }, 500)
            },
            onHide: () => {
                Animated.spring(paddingBottom, {
                    toValue: 15 + Screen.safeArea.bottom,
                    friction: 10,
                    duration: 150,
                    useNativeDriver: false
                }).start();
                setTimeout(() => {
                    if(scrollView.current) {
                        scrollView.current.scrollToEnd();
                    }
                }, 500)
            }
        })

        return () => {
            // send messages as updated content push to thread on close
            utils.sockets.unsubscribe(layerID);
            utils.keyboard.unsubscribe(layerID);
            utils.sockets.off('messages', `on_new_message_${abstract.type}_${abstract.getID()}`, onNewMessage);
            utils.sockets.off('messages', `on_delete_message_${abstract.type}_${abstract.getID()}`, onMessageDeleted);
        }

    }, []);

    useEffect(() => {
        if(scrollView.current) {
            scrollView.current.scrollToEnd();
        }
    }, [messages]);

    useEffect(() => {

        Animated.spring(maxHeight, {
            toValue: floatingLayerState === 'open' ? ((Screen.height() - Screen.safeArea.top) - extraPadding) : (50 + Screen.safeArea.bottom),
            duration: 500,
            friction: 10,
            useNativeDriver: false
        }).start();

        if(floatingLayerState === 'open') {
            Animated.spring(textField.left, {
                toValue: 0,
                duration: 500,
                friction: 10,
                useNativeDriver: false
            }).start();

            Animated.spring(textField.right, {
                toValue: 0,
                duration: 500,
                friction: 10,
                useNativeDriver: false
            }).start();
        }

        setTimeout(() => {
            if(sendMessage.current && shareLocation.current) {
                switch(floatingLayerState) {
                    case 'open':
                    sendMessage.current.play(0, 39);
                    imagePicker.current.play(0, 39);
                    shareLocation.current.play(0, 39);
                    break;

                    default:
                    sendMessage.current.reset();
                    imagePicker.current.reset();
                    shareLocation.current.reset();

                    Animated.spring(textField.left, {
                        toValue: Screen.width() / 2,
                        duration: 500,
                        friction: 10,
                        useNativeDriver: false
                    }).start();

                    Animated.spring(textField.right, {
                        toValue: Screen.width() / 2,
                        duration: 500,
                        friction: 10,
                        useNativeDriver: false
                    }).start();
                }
            }
        }, 250);

    }, [floatingLayerState])

    return (
        <Animated.View style={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: maxHeight,
            paddingBottom: paddingBottom,
            backgroundColor: Appearance.colors.layerBackground(),
            overflow: 'hidden',
            ...Platform.OS === 'web' && {
                height: '100vh'
            }
        }}>
            <View style={{
                minHeight: LayerToolbarHeight + (floatingLayerState === 'open' ? 0 : Screen.safeArea.bottom)
            }}>
            <LayerToolbar
            title={'Messaging'}
            onClose={onCloseMessaging}
            floatingLayerState={floatingLayerState}
            subTitle={Utils.apply(abstract.type, {
                orders: () => `Order #${abstract.getID()}`,
                reservations: () => `Reservation #${abstract.getID()}`
            })}
            onFloatingLayerStateChange={props => {
                Keyboard.dismiss();
                if(typeof(setFloatingLayerState) === 'function') {
                    setFloatingLayerState(props);
                }
            }}
            style={{
                paddingBottom: floatingLayerState === 'open' ? 0 : Screen.safeArea.bottom
            }}/>
            </View>

            <ScrollView
            ref={scrollView}
            style={{
                flexGrow: 1,
                height: '100%',
                width: '100%',
                borderBottomWidth: 1,
                borderBottomColor: Appearance.colors.divider(),
                marginBottom: 15,
            }}
            contentContainerStyle={{
                padding: 15
            }}>
                {messages.map((message, index) => {
                    return (
                        <MessageComponent
                        key={index}
                        message={message}
                        onPress={onMessagePress}
                        onUserPress={onUserPress}
                        user={utils.user.get()}
                        style={{
                            marginTop: index !== 0 ? 20 : 0
                        }}
                        showDate={messages[index - 1] && messages[index - 1].from_user.user_id !== message.from_user.user_id}/>
                    )
                })}
            </ScrollView>
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                paddingLeft: 10,
                paddingRight: 10
            }}>
                <TouchableOpacity
                activeOpacity={0.6}
                onPress={onChooseImage}
                style={{
                    width: 40,
                    height: 40,
                    borderRadius: 15,
                    overflow: 'hidden',
                }}>
                    {imagePickerSource && (
                        <LottieView
                        ref={imagePicker}
                        autoPlay={false}
                        loop={false}
                        source={imagePickerSource}
                        duration={2500}
                        style={{
                            width: '100%',
                            height: '100%'
                        }}/>
                    )}
                </TouchableOpacity>

                <TouchableOpacity
                activeOpacity={0.6}
                onPress={onSendLocation}
                style={{
                    width: 40,
                    height: 40,
                    borderRadius: 15,
                    overflow: 'hidden',
                    marginRight: 8
                }}>
                    {shareLocationSource && (
                        <LottieView
                        ref={shareLocation}
                        autoPlay={false}
                        loop={false}
                        source={shareLocationSource}
                        duration={2500}
                        style={{
                            width: '100%',
                            height: '100%'
                        }}/>
                    )}
                </TouchableOpacity>

                <View style={{
                    flexGrow: 1,
                    height: 35,
                    position: 'relative'
                }}>
                    <Animated.View style={{
                        left: textField.left,
                        right: textField.right,
                        overflow: 'hidden',
                        position: 'absolute'
                    }}>
                        <TextField
                        value={text}
                        placeholder={'Message'}
                        onChange={text => setText(text)} />
                    </Animated.View>
                </View>
                <TouchableOpacity
                activeOpacity={0.6}
                onPress={onSendMessage}
                style={{
                    width: 40,
                    height: 40,
                    borderRadius: 15,
                    overflow: 'hidden',
                    marginLeft: 8
                }}>
                    {sendMessageSource && (
                        <LottieView
                        ref={sendMessage}
                        autoPlay={false}
                        loop={false}
                        source={sendMessageSource}
                        duration={2500}
                        style={{
                            width: '100%',
                            height: '100%'
                        }}/>
                    )}
                </TouchableOpacity>
            </View>
            {loading && floatingLayerState === 'open' && (
                <View style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={Appearance.themeStyle() === 'dark' ? require('eCarra/files/lottie/dots-white.json') : require('eCarra/files/lottie/dots-grey.json')}
                    duration={2500}
                    style={{
                        width: 50
                    }}/>
                </View>
            )}
        </Animated.View>
    )
}
Messaging.displayName = 'Messaging';
