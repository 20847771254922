import React, { useRef, useState, useEffect } from 'react';
import { Animated, Easing, Image, Linking, Platform, Text, ScrollView, View } from 'react-native';

import * as shape from 'd3-shape';
import moment from 'moment';
import update from 'immutability-helper';

import API from 'eCarra/files/api.js';
import Appearance from 'eCarra/styles/Appearance.js';
import BezierLine from 'eCarra/views/BezierLine.js';
import { Call, Email, Text as TextMessage } from 'react-native-openanything';
import Clipboard from 'eCarra/files/Clipboard/';
import DropdownMenu from 'eCarra/views/DropdownMenu.js';
import FBSDK from 'eCarra/files/FBSDK/';
import HeaderWithButton from 'eCarra/views/HeaderWithButton.js';
import Layer from 'eCarra/structure/Layer.js';
import { LineChart, YAxis, XAxis } from 'react-native-svg-charts';
import { LinearGradient, Stop } from 'react-native-svg';
import Panel from 'eCarra/structure/Panel.js';
import RangeSlider from 'eCarra/views/RangeSlider/';
import Request, { buildRequest } from 'eCarra/files/Request/';
import Reward from 'eCarra/classes/Reward.js';
import Screen from 'eCarra/files/Screen.js';
import { ShareToFeed } from 'ec-instagram';
import Svg, { G, Path, Polyline, Rect, Use, Defs, Mask, Polygon, Filter, Circle } from 'react-native-svg';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import User from 'eCarra/classes/User.js';
import Utils from 'eCarra/files/Utils.js';
import Views, { AltBadge } from 'eCarra/views/Main.js';
import ViewShot from 'react-native-view-shot';

export const shareEmissions = async (utils, type, content) => {
    return new Promise(async (resolve, reject) => {
        try {
            switch(type) {

                case 'email':
                await Email(null, `${utils.client.get().name} Emissions`, `${content.message} \n\n${content.url}`);
                break;

                case 'text':
                await TextMessage('', `${content.message} ${content.url}`);
                break;

                case 'twitter':
                let request = buildRequest('https://twitter.com/intent/tweet', {
                    text: content.message,
                    url: content.url
                });
                await Linking.openURL(request);
                resolve({ response: 'success' });
                break;

                case 'instagram':
                utils.alert.show({
                    title: 'Share to Instagram',
                    message: `We have prepared your photo and some information about your Emissions. You can paste you Emissions summary into your post once Instagram opens. Instagram won't let us automatically fill in this information for you`,
                    buttons: [{
                        key: 'done',
                        title: 'Okay',
                        style: 'cancel'
                    }],
                    onPress: async () => {
                        try {
                            await Utils.sleep(0.5);
                            Clipboard.setString(content.message);
                            let response = await ShareToFeed.send(content.message, content.image);
                            resolve(response);
                        } catch(e) {
                            reject(e);
                        }
                    }
                });
                break;

                case 'facebook':
                let props = {
                    contentType: 'link',
                    contentUrl: content.url,
                    contentDescription: content.message,
                };

                let canShow = await FBSDK.ShareDialog.canShow(props);
                if(!canShow) {
                    throw new Error('Your device is unable to share to Facebook at this time. Please check that you have the Facebook app installed and setup.');
                    return;
                }
                utils.alert.show({
                    title: 'Share to Facebook',
                    message: `We have prepared your photo and some information about your Emissions. You can paste you Emissions summary into your post once Facebook opens. Facebook won't let us automatically fill in this information for you`,
                    buttons: [{
                        key: 'done',
                        title: 'Okay',
                        style: 'cancel'
                    }],
                    onPress: async () => {
                        try {
                            Clipboard.setString(content.message);
                            let result = await FBSDK.ShareDialog.show(props, utils);
                            resolve({ fbid: result.postId })
                        } catch(e) {
                            reject(e);
                        }
                    }
                });
                break;
            }

        } catch(e) {
            reject(e);
        }
    })
}

export const shareContent = async (utils, type, content) => {
    return new Promise(async (resolve, reject) => {
        try {
            switch(type) {
                case 'email':
                await Email(null, utils.client.get().name, `${content.message} \n\n${content.url}`);
                resolve();
                break;

                case 'text':
                await TextMessage('', `${content.message} ${content.url}`);
                resolve();
                break;

                case 'twitter':
                let request = buildRequest('https://twitter.com/intent/tweet', {
                    text: content.message,
                    url: content.url
                });
                await Linking.openURL(request);
                resolve();
                break;

                case 'instagram':
                utils.alert.show({
                    title: 'Share to Instagram',
                    message: `We have prepared your shareable content. You can paste this information into your post once Instagram opens. Instagram won't let us automatically fill in this information for you`,
                    buttons: [{
                        key: 'done',
                        title: 'Okay',
                        style: 'cancel'
                    }],
                    onPress: async () => {
                        try {
                            await Utils.sleep(0.5);
                            Clipboard.setString(content.message);
                            let response = await ShareToFeed.send(content.message, content.image);
                            resolve(response);

                        } catch(e) {
                            reject(e);
                        }
                    }
                });
                break;

                case 'facebook':
                let props = {
                    contentType: 'link',
                    contentUrl: content.url,
                    contentDescription: content.message,
                };

                let canShow = await FBSDK.ShareDialog.canShow(props);
                if(!canShow) {
                    throw new Error('Your device is unable to share to Facebook at this time. Please check that you have the Facebook app installed and setup.');
                    return;
                }
                utils.alert.show({
                    title: 'Share to Facebook',
                    message: `We have prepared your shareable content. You can paste this information into your post once Facebook opens. Facebook won't let us automatically fill in this information for you`,
                    buttons: [{
                        key: 'done',
                        title: 'Okay',
                        style: 'cancel'
                    }],
                    onPress: async () => {
                        try {
                            Clipboard.setString(content.message);
                            let result = await FBSDK.ShareDialog.show(props, utils);
                            resolve({ fbid: result.postId });
                        } catch(e) {
                            reject(e);
                        }
                    }
                });
                break;
            }

        } catch(e) {
            reject(e);
        }
    })
}

export const EmissionsChart = ({ utils }) => {

    const panelID = 'emissionsChart';
    const captureRef = useRef(null);

    const [spin, setSpin] = useState(new Animated.Value(0));
    const spinVal = spin.interpolate({
        inputRange: [ 0, 1 ],
        outputRange: [ '0deg', '360deg' ]
    });

    const radius = Platform.OS === 'web' || Utils.isMobile() === false ? 12 : 7;
    const width = Screen.component.hero.maxWidth - 30;
    const height = Screen.component.hero.maxWidth - 80;
    const containerWidth = Screen.panel.maxWidth() - 30 - (Utils.isMobile() === true ? 0 : 50);
    const circles = {
        outer: {
            total: 30,
            radius: width / 3
        },
        middle: {
            total: 25,
            radius: width / 3.8
        },
        inner: {
            total: 18,
            radius: width / 5.3
        }
    }

    const modifiers = [{
        key: 'week',
        title: 'This Week'
    },{
        key: 'month',
        title: 'This Month'
    },{
        key: 'year',
        title: 'This Year'
    }]

    const [modifier, setModifier] = useState(modifiers[1]);
    const [loading, setLoading] = useState(true);
    const [emissions, setEmissions] = useState(null);
    const colors = {
        carbon: '#A1CA7A',
        gas: '#68BB62',
        trees: '#3B9262'
    };

    const onModifierChange = item => {
        if(item) {
            setModifier(item);
        }
    }

    const getAngleForIndex = (index, circle) => {

        let degree = ((360 / circle.total) * index);
        let a = degree * (Math.PI / 180);
        let x = circle.radius * Math.cos(a);
        let y = circle.radius * Math.sin(a);

        return {
            x: isNaN(x) ? 0 : x,
            y: isNaN(y) ? 0 : y,
            progress: (100 / 360 ) * degree
        }
    }

    const getValueForItem = key => {
        if(!emissions || !emissions[key]) {
            return {
                value: 0,
                formatted: '',
                progress: 0,
                graph: []
            }
        }

        let formatted = '0';
        let value = emissions[key].values ? emissions[key].values[modifier.key] : 0;
        switch(key) {
            case 'carbon':
            formatted = `${Utils.numberFormat(value)} Grams`;
            break;

            case 'gas':
            formatted = `${parseFloat(value).toFixed(1)} ${value === 1 ? 'Gallon' : 'Gallons'}`;
            break;

            case 'trees':
            formatted = `${value} Planted`;
            break;
        }

        return {
            value: value,
            formatted: formatted,
            progress: emissions[key].progress ? ((emissions[key].progress[modifier.key] || 0) * 100) : 0,
            graph: emissions.graphing && emissions.graphing[key] && emissions.graphing[key][modifier.key] ? (emissions.graphing[key][modifier.key].data || []) : []
        };
    }

    const fetchEmissions = async () => {
        try {
            let response = await Request.get(utils, '/user/', {
                type: 'emissions',
                user_id: utils.user.get().user_id
            });
            setLoading(false);
            setEmissions(response);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the emissions chart. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {

        Animated.loop(Animated.sequence([
            Animated.timing(spin, {
                toValue: 1,
                duration: 1500,
                easing: Easing.linear,
                useNativeDriver: false
            })
        ])).start();

        fetchEmissions();
        utils.structure.navigation.set({
            onRightPress: () => {
                captureRef.current.capture().then(uri => {
                    utils.layer.open({
                        id: 'share-content',
                        Component: ShareContent.bind(this, {
                            image: uri,
                            category: 'emissions',
                            title: 'Share Emissions',
                            message: 'Let your friends and family know about the postive change you are making by choosing to ride electric. We can share your Emissions progress for the week, month, or the year',
                            activeModifier: modifiers.findIndex(m => m.key === modifier.key),
                            modifiers: modifiers,
                            onModiferChange: (modifier, callback) => {
                                setModifier(modifier);
                                captureRef.current.capture().then(uri => {
                                    callback({ image: uri });
                                });
                            }
                        })
                    })
                });
            }
        })

    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        options={{
            removeOverflow: true,
            shouldStyle: false,
            ...(Platform.OS === 'web' || Utils.isMobile() === false) && {
                removeOverflow: false,
                shouldStyle: true
            }
        }}>
            <View style={{
                ...(Platform.OS === 'web' || Utils.isMobile() === false) && {
                    paddingTop: 0,
                    paddingBottom: 15,
                    paddingHorizontal: 15
                }
            }}>
                <ViewShot
                ref={captureRef}
                options={{
                    format: 'jpg',
                    quality: 1
                }}>
                    <View style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        ...(Platform.OS === 'web' || Utils.isMobile() === false) && {
                            paddingTop: 0,
                            paddingBottom: 15,
                            paddingHorizontal: 15
                        }
                    }}>
                        <View style={{
                            position: 'relative',
                            width: width,
                            height: height,
                            ...Utils.isMobile() === true && {
                                backgroundColor: Appearance.colors.background()
                            }
                        }}>
                            <Svg
                            width={width}
                            height={height}
                            viewBox={`-${width / 2} -${height / 2} ${width} ${height}`}>
                                <G transform={'scale(-1,1), rotate(-90)'}>
                                    {/* outer circle */}
                                    {[...new Array(circles.outer.total)].map((_, index) => {
                                        let angle = getAngleForIndex(index, circles.outer);
                                        return (
                                            <Circle
                                            r={radius}
                                            key={index}
                                            cx={angle.x}
                                            cy={angle.y}
                                            fill={angle.progress < getValueForItem('carbon').progress ? '#A1CA7A' : (Appearance.themeStyle() === 'dark' ? Appearance.colors.darkGrey : Appearance.colors.lighterGrey)} />
                                        )
                                    })}
                                    {/* middle circle */}
                                    {[...new Array(circles.middle.total)].map((_, index) => {
                                        let angle = getAngleForIndex(index, circles.middle);
                                        return (
                                            <Circle
                                            r={radius}
                                            key={index}
                                            cx={angle.x}
                                            cy={angle.y}
                                            fill={angle.progress < getValueForItem('gas').progress ? '#68BB62' : (Appearance.themeStyle() === 'dark' ? Appearance.colors.darkGrey : Appearance.colors.lighterGrey)} />
                                        )
                                    })}
                                    {/* inner circle */}
                                    {[...new Array(circles.inner.total)].map((_, index) => {
                                        let angle = getAngleForIndex(index, circles.inner);
                                        return (
                                            <Circle
                                            r={radius}
                                            key={index}
                                            cx={angle.x}
                                            cy={angle.y}
                                            fill={angle.progress < getValueForItem('trees').progress ? '#3B9262' : (Appearance.themeStyle() === 'dark' ? Appearance.colors.darkGrey : Appearance.colors.lighterGrey)} />
                                        )
                                    })}
                                </G>
                            </Svg>
                            <View style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'absolute',
                                top: (height / 2) - ((height / 2.4) / 2),
                                left: (width / 2) - ((width / 2.4) / 2),
                                width: width / 2.4,
                                height: height / 2.4,
                            }}>
                                <Image
                                source={Appearance.themeStyle() === 'dark' ? require('eCarra/images/emissions-chart-center-dark.png') : require('eCarra/images/emissions-chart-center-light.png')}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    resizeMode: 'contain'
                                }}/>
                                {loading && (
                                    <Animated.Image
                                    source={require('eCarra/images/loader-spinner-frame.png')}
                                    style={{
                                        position: 'absolute',
                                        width: (width / 2.4) - 15,
                                        height: (height / 2.4) - 15,
                                        resizeMode: 'contain',
                                        transform: [{
                                            rotate: spinVal
                                        }]
                                    }} />
                                )}
                            </View>
                        </View>
                    </View>
                </ViewShot>

                <DropdownMenu
                utils={utils}
                items={modifiers}
                label={modifier.title}
                onChange={onModifierChange}/>

                {['carbon','gas','trees'].map((emission, index, keys) => {
                    return (
                        <View
                        key={index}
                        style={{
                            marginBottom: index === keys.length - 1 ? 0:20
                        }}>
                            <View style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: containerWidth,
                                alignItems: 'flex-end',
                                justifyContent: 'space-between'
                            }}>
                                <View style={{
                                    flexDirection: 'column'
                                }}>
                                    <Text style={{
                                        ...Appearance.textStyles.title(),
                                        fontSize: 16,
                                        color: Appearance.colors.text()
                                    }}>{Utils.ucFirst(emission)}</Text>
                                    <Text style={{
                                        ...Appearance.textStyles.supporting(),
                                        fontSize: 14
                                    }}>{getValueForItem(emission).formatted}</Text>
                                </View>
                                <BezierLine
                                width={100}
                                height={25}
                                data={[{
                                    data: getValueForItem(emission).graph,
                                    strokeWidth: 2,
                                    strokeColor: Appearance.colors.lightGrey
                                }]}
                                style={{
                                    marginBottom: 4
                                }}/>
                            </View>
                            <View style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                marginTop: 8
                            }}>
                                {[...new Array(Math.ceil(containerWidth / 12))].map((_, index) => {
                                    return (
                                        <View
                                        key={index}
                                        style={{
                                            width: 4,
                                            height: 25,
                                            backgroundColor: Appearance.themeStyle() === 'dark' ? Appearance.colors.darkGrey : Appearance.colors.lighterGrey,
                                            borderRadius: 2,
                                            marginRight: 8,
                                            ...getValueForItem(emission).progress > ((100 / Math.ceil(containerWidth / 12)) * index) && {
                                                backgroundColor: colors[emission]
                                            }
                                        }}/>
                                    )
                                })}
                            </View>
                        </View>
                    )
                })}
            </View>
        </Panel>
    )
}

export const EmissionsPotential = ({ utils }) => {

    const panelID = 'emissionsPotential';
    const chartHeight = Platform.OS === 'web' || Utils.isMobile() === false ? 300 : 200;

    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(1);
    const [months, setMonths] = useState(24);
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState('carbon');

    const [minRides, setMinRides] = useState(1);
    const [maxRides, setMaxRides] = useState(3);
    const [minMiles, setMinMiles] = useState(5);
    const [maxMiles, setMaxMiles] = useState(15);

    const Gradient = () => (
        <Defs key={'gradient'}>
            <LinearGradient id={'gradient'} x1={'0'} y={'0%'} x2={'100%'} y2={'0%'}>
                <Stop offset={'0%'} stopColor={Utils.hexToRGBA(Appearance.colors.primary())}/>
                <Stop offset={'100%'} stopColor={Utils.hexToRGBA(Appearance.colors.secondary())}/>
            </LinearGradient>
        </Defs>
    )

    const onAboutPotentialPress = () => {
        utils.alert.show({
            title: 'Future Potential',
            message: 'This is the impact the you can make by choosing to ride electric. You can explore your potential impact by adjusting the number of electric rides per day, miles driven per ride, and total overall months. We use a randomized selection within your chosen ranges to help visualize how much of an impact can be made by reducing your carbon footprint'
        });
    }


    const getYAxis = () => {

        let ticks = 7;
        let inset = { top: 20, bottom: 20, right: 20 };
        let textStyle = {
            ...Appearance.fontWeight.get(600),
            fontSize: 10,
        }

        if(Platform.OS === 'web') {
            let min = Math.min(...data);
            let max = Math.max(...data);
            return (
                <View style={{
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: chartHeight,
                    top: inset.top,
                    bottom: inset.bottom,
                    left: 20,
                    paddingRight: inset.right
                }}>
                    {[ ...new Array(ticks)].map((_, index) => {
                        let value = ((max - min) / ticks) * (ticks - (index + 1));
                        return (
                            <Text
                            key={index}
                            style={{
                                ...textStyle,
                                color: Appearance.colors.subText()
                            }}>{Utils.yAxisFormat(value)}</Text>
                        )
                    })}
                </View>
            )
        }

        return (
            <YAxis
            data={data}
            contentInset={inset}
            numberOfTicks={ticks}
            formatLabel={value => Utils.yAxisFormat(value)}
            svg={{
                ...textStyle,
                fill: Appearance.colors.subText()
            }}/>
        )
    }

    useEffect(() => {

        const mpg = 24.7;
        setData([...Array(months)].map((month, index) => {
            let randomMiles = (Math.floor(Math.random() * (maxMiles - minMiles)) + minMiles);
            let randomRides = (Math.floor(Math.random() * (maxRides - minRides)) + minRides);
            switch(selected) {
                case 'carbon':
                    return (((mpg / randomMiles) * 8887) * randomRides) * (index + 1);
                case 'gas':
                    return ((mpg / randomMiles) * randomRides) * (index + 1);
                case 'trees':
                    return randomRides * (index + 1);
            }
        }));
    }, [count, minMiles, maxMiles, minRides, maxRides, months, selected]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Future Potential'}
        header={(
            <HeaderWithButton
            label={'Future Potential'}
            button={'details'}
            onPress={onAboutPotentialPress}
            style={{
                marginBottom: 4
            }}/>
        )}
        options={{
            loading: loading,
            removeOverflow: true,
            shouldStyle: false
        }}>
            <View style={Appearance.styles.panel()}>
                <View style={{
                    borderBottomColor: Appearance.colors.divider(),
                    borderBottomWidth: 1,
                    marginBottom: 5
                }}>
                    <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'flex-start',
                        padding: 15,
                        paddingBottom: 0,
                        position: 'relative'
                    }}>
                        {getYAxis()}
                        <LineChart
                        data={ data }
                        curve={shape.curveNatural}
                        contentInset={{ top: 20, left: 20, right: 20, bottom: 20 }}
                        svg={{
                            strokeWidth: 2,
                            stroke: 'url(#gradient)',
                        }}
                        style={{
                            width: Screen.panel.maxWidth() - 80,
                            height: chartHeight
                        }}>
                            <Gradient/>
                        </LineChart>
                    </View>
                    <DropdownMenu
                    utils={utils}
                    label={Utils.apply(selected, {
                        carbon: () => 'Grams of Carbon',
                        gas: () => 'Gallons of Gas',
                        trees: () => 'Trees Planted'
                    })}
                    items={[{
                        key: 'carbon',
                        title: 'Carbon'
                    },{
                        key: 'gas',
                        title: 'Gasoline'
                    },{
                        key: 'trees',
                        title: 'Trees Planted'
                    }]}
                    onChange={item => setSelected(item.key)}/>
                </View>
                {Views.entry({
                    title: 'Electric rides per day',
                    subTitle: `Between ${minRides} ${minRides === 1 ? 'ride' : 'rides'} and ${maxRides} ${maxRides === 1 ? 'ride' : 'rides'}`,
                    bottomBorder: false,
                    hideIcon: true
                })}
                <View style={{
                    paddingHorizontal: 15,
                    paddingBottom: 15,
                    marginBottom: 5,
                    borderBottomWidth: 1,
                    borderBottomColor: Appearance.colors.divider()
                }}>
                    <RangeSlider
                    style={{ width: '100%', height: 25 }}
                    gravity={'center'}
                    step={1}
                    min={1}
                    max={10}
                    thumbRadius={12}
                    thumbColor={'#FFFFFF'}
                    thumbBorderColor={Appearance.themeStyle() === 'dark' ? '#FFFFFF' : '#CCCCCC'}
                    initialLowValue={minRides}
                    initialHighValue={maxRides}
                    labelStyle={'none'}
                    labelBackgroundColor={Appearance.colors.panelBackground()}
                    labelTextColor={Appearance.colors.text()}
                    labelPadding={10}
                    labelBorderRadius={10}
                    labelBorderColor={Appearance.colors.background()}
                    selectionColor={Appearance.colors.primary()}
                    blankColor={Appearance.colors.grey()}
                    onValueChanged={(low, high) => {
                        setMinRides(low);
                        setMaxRides(high);
                    }}/>
                </View>
                {Views.entry({
                    title: 'Miles driven per ride',
                    subTitle: `Between ${minMiles} ${minMiles === 1 ? 'mile' : 'miles'} and ${maxMiles} ${maxMiles === 1 ? 'mile' : 'miles'}`,
                    bottomBorder: false,
                    hideIcon: true
                })}
                <View style={{
                    paddingHorizontal: 15,
                    paddingBottom: 15,
                    marginBottom: 5,
                    borderBottomWidth: 1,
                    borderBottomColor: Appearance.colors.divider()
                }}>
                    <RangeSlider
                    style={{ width: '100%', height: 25 }}
                    gravity={'center'}
                    step={1}
                    min={1}
                    max={50}
                    thumbRadius={12}
                    thumbColor={'#FFFFFF'}
                    thumbBorderColor={Appearance.themeStyle() === 'dark' ? '#FFFFFF' : '#CCCCCC'}
                    initialLowValue={minMiles}
                    initialHighValue={maxMiles}
                    labelStyle={'none'}
                    labelBackgroundColor={Appearance.colors.panelBackground()}
                    labelTextColor={Appearance.colors.text()}
                    labelPadding={10}
                    labelBorderRadius={10}
                    labelBorderColor={Appearance.colors.background()}
                    selectionColor={Appearance.colors.primary()}
                    blankColor={Appearance.colors.grey()}
                    onValueChanged={(low, high) => {
                        setMinMiles(low);
                        setMaxMiles(high);
                    }}/>
                </View>

                {Views.entry({
                    title: 'Over how many months?',
                    subTitle: `Up to ${maxRides * months} rides over ${months} ${months === 1 ? 'month' : 'months'}`,
                    bottomBorder: false,
                    hideIcon: true
                })}
                <View style={{
                    paddingHorizontal: 15,
                    paddingBottom: 15
                }}>
                    <RangeSlider
                    rangeEnabled={false}
                    style={{ width: '100%', height: 25 }}
                    gravity={'center'}
                    step={1}
                    min={12}
                    max={120}
                    thumbRadius={12}
                    thumbColor={'#FFFFFF'}
                    thumbBorderColor={Appearance.themeStyle() === 'dark' ? '#FFFFFF' : '#CCCCCC'}
                    initialLowValue={months}
                    labelStyle={'none'}
                    labelBackgroundColor={Appearance.colors.panelBackground()}
                    labelTextColor={Appearance.colors.text()}
                    labelPadding={10}
                    labelBorderRadius={10}
                    labelBorderColor={Appearance.colors.background()}
                    selectionColor={Appearance.colors.primary()}
                    blankColor={Appearance.colors.grey()}
                    onValueChanged={value => setMonths(value)}/>
                </View>
            </View>
        </Panel>
    )
}

export const EmissionsTopMembers = ({ utils }) => {

    const panelID = 'emissionsTopMembers';
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);

    const fetchUsers = async () => {
        try {
            let { users } = await Request.get(utils, '/users/', {
                type: 'top_users_emissions'
            })
            setLoading(false);
            setUsers(users.map(entry => ({
                ...entry,
                user: User.create(entry.user)
            })));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the top members list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUserPress = entry => {
        utils.alert.show({
            title: 'Top Members',
            message: `${entry.user.first_name} has saved enough carbon and gasoline by booking sustainable orders and rides to earn ${parseInt(entry.score)} emission ${parseInt(entry.score) === 1 ? 'point' : 'points'}. Emission points, also referred to as your Impact Score, are used to provide additional discounts and rewards for making a difference in our world.`,
            buttons: [{
                key: 'learn',
                title: 'Learn More',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Dismiss',
                style: 'cancel'
            }],
            onPress: (key) => {
                if(key === 'learn') {
                    setTimeout(onLearnMorePress, 500);
                }
            }
        })
    }

    const onLearnMorePress = async () => {
        try {
            let { mpg, grams, trees, worldwide } = await Request.get(utils, '/calculations/', {
                type: 'emission_values'
            });
            utils.alert.show({
                title: 'About Impact Scores',
                message: `We use the distance travelled for all of your completed Orders and Reservations to calculate an Impact Score. We use the EPA recommended value of ${mpg} miles per gallon and the recommended value of ${grams} grams of carbon per gallon of gas to calculate your score. We also factor in the amount of trees planted per Order and Reservations by taking a look at each movement. A single Order produces ${trees.orders} and a single Reservation produces ${trees.reservations}. As a company, we have planted ${Utils.numberFormat(worldwide.trees)} ${worldwide.trees === 1 ? 'tree' : 'trees'}, reduced our carbon footprint by ${Utils.numberFormat(worldwide.carbon)} grams of carbon, and have saved ${Utils.numberFormat(worldwide.gas)} ${worldwide.gas === 1 ? 'gallon' : 'gallons'} of gasoline.`
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the overview for emission score calculations. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchUsers();
        utils.content.subscribe(panelID, 'rewards', {
            onFetch: fetchUsers
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Top Members'}
        options={{
            loading: loading,
            removeOverflow: true,
            shouldStyle: false
        }}>
            <View style={Appearance.styles.panel()}>
                {users.length > 0
                    ?
                    users.map((entry, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: `${entry.user.first_name} ${entry.user.last_name.charAt(0)}`,
                                subTitle: entry.user.city && entry.user.state && entry.user.country ? `${entry.user.city}, ${entry.user.state}. ${entry.user.country}` : 'Location Not Available',
                                icon: {
                                    path: entry.user.avatar
                                },
                                bottomBorder: index !== users.length - 1,
                                onPress: onUserPress.bind(this, entry)
                            })
                        )
                    })
                    :
                    Views.entry({
                        title: 'No Members Found',
                        subTitle: 'Book a ride or order to start competing',
                        hideIcon: true,
                        propStyles: {
                            subTitle: {
                                numberOfLines: 2
                            }
                        }
                    })
                }
            </View>
        </Panel>
    )
}

export const EmissionsTopTreesPlanted = ({ utils }) => {

    const panelID = 'emissionsTopTreesPlanted';
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);

    const fetchUsers = async () => {
        try {
            let response = await Request.get(utils, '/users/', {
                type: 'top_users_trees'
            });

            setLoading(false);
            setUsers(users.map(entry => ({
                ...entry,
                user: User.create(entry.user)
            })));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the top tree planters list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUserPress = entry => {
        utils.alert.show({
            title: 'Top Tree Planters',
            message: `${entry.user.first_name} has supported planting enough trees by booking sustainable orders and rides to earn ${entry.total} tree planting ${entry.total === 1 ? 'point' : 'points'}. Tree planting points, also referred to as your Reforestation Score, are used to provide additional discounts and rewards for making a difference in our world.`,
            buttons: [{
                key: 'learn',
                title: 'Learn More',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Dismiss',
                style: 'cancel'
            }],
            onPress: (key) => {
                if(key === 'learn') {
                    setTimeout(onLearnMorePress, 500);
                }
            }
        })
    }

    const onLearnMorePress = async () => {
        try {
            let { mpg, grams, trees, worldwide } = await Request.get(utils, '/calculations/',  {
                type: 'emission_values'
            });

            utils.alert.show({
                title: 'About Reforestation Scores',
                message: `We believe that carpooling and reducing the amount of vehicles on the road can make a huge impact in the world. A single Order produces ${trees.orders} and a single Reservation produces ${trees.reservations}. As a company, we have planted ${Utils.numberFormat(worldwide.trees)} ${worldwide.trees === 1 ? 'tree' : 'trees'} by helping our customers move sustainably.`
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the overview for emission score calculations. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchUsers();
        utils.content.subscribe(panelID, 'rewards', {
            onFetch: fetchUsers
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Top Tree Planters'}
        options={{
            loading: loading,
            removeOverflow: true,
            shouldStyle: false
        }}>
            <View style={Appearance.styles.panel()}>
                {users.length > 0
                    ?
                    users.map((entry, index) => {
                    return (
                        Views.entry({
                            key: index,
                            title: `${entry.user.first_name} ${entry.user.last_name.charAt(0)}`,
                            subTitle: entry.user.city && entry.user.state && entry.user.country ? `${entry.user.city}, ${entry.user.state}. ${entry.user.country}` : 'Location Not Available',
                            icon: {
                                path: entry.user.avatar
                            },
                            bottomBorder: index !== users.length - 1,
                            onPress: onUserPress.bind(this, entry),
                            rightContent: (
                                <AltBadge content={{
                                    color: Appearance.colors.secondary(),
                                    text: `${entry.total} ${entry.total === 1 ? 'Tree' : 'Trees'}`
                                }} />
                            )
                        })
                    )
                })
                :
                Views.entry({
                    title: 'No Members Found',
                    subTitle: 'Book a ride or order to start competing',
                    hideIcon: true,
                    propStyles: {
                        subTitle: {
                            numberOfLines: 2
                        }
                    }
                })}
            </View>
        </Panel>
    )
}

export const EmissionsRewardEvents = ({ utils }) => {

    const panelID = 'emissionsRewardEvents';
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState(null);

    const onRewardEventPress = event => {
        utils.alert.show({
            title: event.reward.title,
            message: event.reward.description,
            icon: {
                path: event.reward.image,
                style: {
                    backgroundColor: Appearance.colors.primary(),
                    padding: 6
                }
            }
        })
    }

    const onNewRewardPress = () => {
        utils.layer.open({
            id: 'reward-picker',
            Component: RewardPicker
        })
    }

    const fetchRewardEvents = async () => {
        try {
            let { events } = await Request.get(utils, '/rewards/',  {
                type: 'all_events',
                user_id: utils.user.get().user_id
            });
            setLoading(false);
            setEvents(events.map(e => Reward.Event.create(e)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the rewards list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchRewardEvents();
        utils.content.subscribe(panelID, 'rewards', {
            onFetch: fetchRewardEvents
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'My Rewards'}
        header={(
            <HeaderWithButton
            label={'My Rewards'}
            button={'new'}
            onPress={onNewRewardPress}
            style={{
                marginBottom: 4
            }}/>
        )}
        options={{
            loading: loading,
            removeOverflow: true,
            shouldStyle: false
        }}>
            <View style={Appearance.styles.panel()}>
                {events && events.length > 0
                    ?
                    events.map((e, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: e.reward.title,
                                subTitle: moment(e.date).format('MMMM Do, YYYY'),
                                badge: {
                                    text: Utils.toCurrency(e.reward.amount),
                                    color: Appearance.colors.primary()
                                },
                                icon: {
                                    path: e.reward.image,
                                    style: {
                                        padding: 6.5
                                    },
                                    imageStyle: {
                                        resizeMode: 'contain'
                                    }
                                },
                                bottomBorder: index !== events.length - 1,
                                onPress: onRewardEventPress.bind(this, e)
                            })
                        )
                    })
                    :
                    Views.entry({
                        title: `No Rewards Found`,
                        subTitle: `No rewards were found for your account`,
                        hideIcon: true,
                        bottomBorder: false
                    })
                }
            </View>
        </Panel>
    )
}

// Layers
export const ShareContent = ({ activeModifier, category, channels, image, message, modifiers, onModiferChange, title }, { index, options, utils }) => {

    const layerID = `share-content`;
    const [imageData, setImageData] = useState(image);
    const [layerState, setLayerState] = useState(null);
    const [modifier, setModifier] = useState(modifiers ? modifiers[activeModifier] : null);

    const shareOptions = [{
        key: 'facebook',
        title: 'Facebook',
        icon: require('eCarra/images/share-content-facebook.png')
    },{
        key: 'twitter',
        title: 'Twitter',
        icon: require('eCarra/images/share-content-twitter.png')
    },{
        key: 'instagram',
        title: 'Instagram',
        icon: require('eCarra/images/share-content-instagram.png')
    },{
        key: 'email',
        title: 'Email',
        icon: require('eCarra/images/share-content-email.png')
    },{
        key: 'text',
        title: 'Text Message',
        icon: require('eCarra/images/share-content-text.png'),
        visible: Platform.OS !== 'web'
    }];

    const onShareContent = async type => {
        switch(category) {
            case 'emissions':
            try {
                let response = await Request.get(utils, '/user/',  {
                    type: 'emissions_summary',
                    duration: modifier.key
                });
                try {
                    await shareEmissions(utils, type, {
                        ...response,
                        image: imageData
                    });
                    setLayerState('close');

                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue preparing your emissions summary. ${e.message || 'An unknown error occurred'}`
                    })
                }

            } catch(e) {
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue loading your emissions summary. ${e.message || 'An unknown error occurred'}`
                })
            }
            break;

            default:
            try {
                let response = await Request.get(utils, '/user/',  {
                    type: 'share_summary'
                });
                await shareContent(utils, type, {
                    ...response,
                    image: imageData
                });
                setLayerState('close');

            } catch(e) {
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue preparing your sharable content. ${e.message || 'An unknown error occurred'}`
                })
            }
        }
    }

    useEffect(() => {
        setImageData(image);
    }, [image]);

    return (
        <Layer
            id={layerID}
            utils={utils}
            index={index}
            options={{
                ...options,
                bottomCard: true,
                layerState: layerState
            }}>

            <View style={{
                alignItems: 'center',
                padding: 5
            }}>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 6
                }}>{title}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 20,
                    textAlign: 'center'
                }}>{message}</Text>

                {modifiers
                    ?
                    <DropdownMenu
                    utils={utils}
                    items={modifiers}
                    label={modifier ? modifier.title : '...'}
                    onChange={item => {
                        setModifier(item);
                        if(typeof(onModiferChange) === 'function') {
                            onModiferChange(item, ({ image }) => {
                                setImageData(image);
                            })
                        }
                    }}
                    style={{
                        marginBottom: 12
                    }}/>
                    :
                    null
                }

                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {shareOptions.filter(option => {
                        if(option.visible === false) {
                            return false;
                        }
                        return channels ? channels.includes(option.key) : true
                    }).map((option, index) => {
                        return (
                            <TouchableOpacity
                            key={index}
                            activeOpacity={0.6}
                            onPress={onShareContent.bind(this, option.key)}
                            style={{
                                width: 45,
                                height: 45,
                                borderRadius: 22.5,
                                overflow: 'hidden',
                                marginRight: index !== shareOptions.length - 1 ? 15:0,
                                backgroundColor: Appearance.colors.grey()
                            }}>
                                <Image source={option.icon} style={{
                                    width: '100%',
                                    height: '100%',
                                    resizeMode: 'cover'
                                }}/>
                            </TouchableOpacity>
                        )
                    })}
                </View>
            </View>
        </Layer>
    )
}

export const RewardPicker = ({ index, options, utils }) => {

    const layerID = 'reward-picker';
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(true);
    const [rewards, setRewards] = useState([]);
    const [selected, setSelected] = useState(null);
    const [score, setScore] = useState(null);

    const onRedeemReward = () => {
        if(selected.cost > score) {
            utils.alert.show({
                title: 'Oops!',
                message: `It looks like you do not have enough points for this reward. You currently have ${Utils.numberFormat(score)} ${score === 1 ? 'point' : 'points'} and this reward requires ${selected.cost} ${selected.cost === 1 ? 'point' : 'points'}`,
                icon: {
                    path: selected.image,
                    style: {
                        backgroundColor: Appearance.colors.primary(),
                        padding: 6
                    }
                }
            });
            return;
        }
        utils.alert.show({
            title: `Redeem ${selected.cost} ${selected.cost === 1 ? 'Point' : 'Points'}`,
            message: `Are you sure that you want to redeem ${selected.cost} ${selected.cost === 1 ? 'point' : 'points'} for this reward?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onPress: key => {
                if(key === 'confirm') {
                    onRedeemRewardConfirm();
                    return;
                }
            }
        })
    }

    const onRedeemRewardConfirm = async () => {
        try {
            setLoading('confirm');
            await Utils.sleep(1);
            let { score } = await Request.post(utils, '/reward/', {
                type: 'redeem',
                id: selected.id,
                user_id: utils.user.get().user_id
            });

            setScore(score);
            setLoading(false);
            utils.content.fetch('rewards');
            utils.alert.show({
                title: 'All Done!',
                message: `You have redeemed ${selected.cost} ${selected.cost === 1 ? 'point' : 'points'} for the "${selected.title}" reward and can immediately take advantage of the benefits.`,
                icon: {
                    path: selected.image,
                    style: {
                        backgroundColor: Appearance.colors.primary(),
                        padding: 6
                    }
                },
                onPress: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an redeeming your points. ${e.message || 'An unknown error occurred'}`,
                icon: {
                    path: selected.image,
                    style: {
                        backgroundColor: Appearance.colors.primary(),
                        padding: 6
                    }
                }
            })
        }
    }

    const getButtons = () => {
        if(!selected) {
            return null;
        }
        return [{
            key: 'back',
            text: 'Back',
            color: 'grey',
            onPress: () => setSelected(null)
        },{
            key: 'confirm',
            text: 'Confirm',
            color: 'primary',
            loading: loading === 'confirm',
            onPress: onRedeemReward
        }];
    }

    const getContent = () => {
        if(loading === true) {
            return (
                <View style={{
                    ...Appearance.styles.panel()
                }}>
                    {Views.loader()}
                </View>
            )
        }
        if(rewards.length === 0) {
            return (
                Views.entry({
                    title: 'No Rewards Found',
                    subTitle: 'There are no rewards available at this time',
                    hideIcon: true,
                    bottomBorder: false,
                })
            )
        }
        return rewards.map((reward, index) => {
            return (
                <View
                key={index}
                style={{
                    ...Appearance.styles.panel(),
                    marginBottom: index !== rewards.length - 1 ? 8: 0
                }}>
                    {Views.entry({
                        title: reward.title,
                        subTitle: reward.description,
                        icon: {
                            path: reward.image,
                            style: {
                                backgroundColor: Appearance.colors.primary(),
                                padding: 6,
                            },
                            imageStyle: {
                                resizeMode: 'contain'
                            }
                        },
                        badge: {
                            text: `${reward.cost} ${reward.cost === 1 ? 'Point' : 'Points'}`,
                            color: Appearance.colors.grey()
                        },
                        bottomBorder: true,
                        onPress: () => setSelected(reward),
                        propStyles: {
                            subTitle: {
                                numberOfLines: 2
                            }
                        }
                    })}
                </View>
            )
        });
    }

    const fetchRewards = async () => {
        try {
            let { rewards } = await Request.get(utils, '/rewards/', {
                type: 'all'
            });
            setLoading(false);
            setRewards(rewards.map(r => Reward.create(r)));
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the available rewards. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchScore = async () => {
        try {
            let { score } = await Request.get(utils, '/user/', {
                type: 'emissions_score'
            });
            setScore(score);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your impact score. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchScore();
        fetchRewards();
    }, [])

    return (
        <Layer
        id={layerID}
        title={'Rewards'}
        utils={utils}
        index={index}
        buttons={getButtons()}
        options={{
            ...options,
            layerState: layerState,
            bottomCard: true,
            removePadding: true
        }}>
            {selected
                ?
                <>
                <View style={{
                    padding: 15,
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderBottomColor: Appearance.colors.divider(),
                    borderBottomWidth: 1
                }}>
                    <View style={{
                        width: 75,
                        height: 75,
                        borderRadius: 37.5,
                        backgroundColor: Appearance.colors.primary(),
                        padding: 15,
                        overflow: 'hidden',
                        marginBottom: 8
                    }}>
                        <Image
                        source={selected.image}
                        style={{
                            width: '100%',
                            height: '100%',
                            resizeMode: 'contain'
                        }}/>
                    </View>
                    <Text style={{
                        ...Appearance.textStyles.panelTitle(),
                        marginBottom: 6
                    }}>{selected.title}</Text>
                    <Text style={{
                        ...Appearance.textStyles.subTitle(),
                        textAlign: 'center'
                    }}>{selected.description}</Text>
                </View>
                </>
                :
                <>
                <View style={{
                    padding: 15,
                    paddingBottom: 0,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Text style={{
                        ...Appearance.textStyles.panelTitle(),
                        marginBottom: 6
                    }}>{'Rewards'}</Text>
                    <Text style={{
                        ...Appearance.textStyles.subTitle(),
                        textAlign: 'center'
                    }}>{`You can redeem the emission points that you've earned with your Impact Score for special rewards and discounts. Choose a reward from the below to learn more.`}</Text>
                    {score !== null && (
                        <AltBadge
                        style={{ marginTop: 15 }}
                        content={{
                            text: `You have ${Utils.softNumberFormat(score)} emission ${score === 1 ? 'point' : 'points'}`,
                            color: score >= 0 ? Appearance.colors.primary() : Appearance.colors.red
                        }} />
                    )}
                </View>
                <View style={{
                    padding: 15
                }}>
                    <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{
                        width: '100%',
                        maxHeight: Screen.height() / 2
                    }}>
                        {getContent()}
                    </ScrollView>
                </View>
                </>
            }
        </Layer>
    )
}
