import React from 'react';

import API from 'eCarra/files/api.js';
import Request from 'eCarra/files/Request/';

const Here = {
    flow: (utils, location) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { results } = await Request.get(utils, '/vehicles/', {
                    type: 'here_flow',
                    location: {
                        lat: location.latitude,
                        long: location.longitude
                    }
                })
                resolve(results);
            } catch(e) {
                reject(e);
            }
        })
    },
    incidents: async (utils, location) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { results } = await Request.get(utils, '/vehicles/', {
                    type: 'here_incidents',
                    location: {
                        lat: location.latitude,
                        long: location.longitude
                    }
                })
                resolve(results);
            } catch(e) {
                reject(e);
            }
        })
    },
    trafficAnnotation: type => {
        switch(type) {
            case 'CONST':
            return  require('eCarra/images/construction-annotation-orange.png')

            case 'SLOW':
            return require('eCarra/images/slow-traffic-annotation-yellow.png')

            case 'ACC':
            return require('eCarra/images/vehicle-accident-annotation-red.png')

            case 'CLOS':
            return require('eCarra/images/road-closed-annotation-red.png')

            default:
            return require('eCarra/images/traffic-annotation-grey.png')

        }
    },
    trafficIcon: type => {
        switch(type) {
            case 'CONST':
            return require('eCarra/images/construction-icon-orange.png')

            case 'SLOW':
            return require('eCarra/images/slow-traffic-icon-yellow.png')

            case 'ACC':
            return require('eCarra/images/vehicle-accident-icon-red.png')

            case 'CLOS':
            return require('eCarra/images/road-closed-icon-red.png')

            default:
            return require('eCarra/images/traffic-icon-grey.png')

        }
    },
    typeToText: type => {
        switch(type) {
            case 'CONST':
            return 'Constrsuction';

            case 'SLOW':
            return 'Slow Traffic';

            case 'ACC':
            return 'Accident';

            case 'CLOS':
            return 'Road Closure';

            default:
            return 'Unknown'

        }
    }
}

export default Here;
