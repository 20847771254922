import React from 'react';
import { View } from 'react-native';
import QRCodeComponent from 'react-qr-code';

export const QRCode = ({ backgroundColor, color, content, size}) => {
    return (
        <View style={{
            width: size,
            height: size,
            padding: 15,
            backgroundColor: backgroundColor,
            borderRadius: 10,
            removeOverflow: 'hidden'
        }}>
            <QRCodeComponent
            size={size - 30}
            fgColor={color}
            bgColor={backgroundColor}
            value={content}/>
        </View>
    )
}
