import React from 'react';
import { Platform } from 'react-native';

import { Appearance, useColorScheme } from 'react-native-appearance';
import Utils from 'eCarra/files/Utils.js';

const _primary = () => {
    return global.client ? global.client.colors.regular : '#6CBF66'
}

const _background = () => {
    return Appearance.getColorScheme() === 'dark' ? '#181818' : '#EDEDED';
}

const _panelBackground = opacity => {
    if(!isNaN(opacity)) {
        return Appearance.getColorScheme() === 'dark' ? `rgba(45,45,45,${opacity})`:`rgba(250,250,250,${opacity})`;
    }
    return Appearance.getColorScheme() === 'dark' ? '#2D2D2D' : '#FAFAFA';
}

const _text = () => {
    return Appearance.getColorScheme() === 'dark' ? '#FFFFFF' : '#323232';
}

const _subText = () => {
    return Appearance.getColorScheme() === 'dark' ? 'rgba(200,200,200,1)' : '#9B9B9B';
}

const _divider = () => {
    return getThemeStyle() === 'dark' ? '#383838' : '#F0F0F0';
}

const _softBorder = () => {
    return getThemeStyle() === 'dark' ? 'rgba(85,85,85,1)' : 'rgba(220,220,220,1)';
}

const getThemeStyle = () => {
    if(Platform.OS === 'web') {
        return window.theme;
    }
    // style can return 'no-preference' on android if no system style is selected
    let style = Appearance.getColorScheme();
    return style === 'dark' ? 'dark' : 'light';
}

const getFontWeight = value => {

    if(Platform.OS !== 'android') {
        return { fontWeight: `${value}` };
    }

    // prevent android from requesting over 900
    let nextVal = `w${value === 900 ? 900 : (value + 100)}`;
    let weights = {
        w100: {
            fontFamily: 'Roboto-Thin' ,
            fontWeight: '100'
        },
        w200: {
            fontFamily: 'Roboto-Light',
            fontWeight: '200'
        },
        w300: {
            fontFamily: 'Roboto-Light',
            fontWeight: '200'
        },
        w400: {
            fontFamily: 'Roboto-Regular',
            fontWeight: '400'
        },
        w500: {
            fontFamily: 'Roboto-Medium',
            fontWeight: '500'
        },
        w600: {
            fontFamily: 'Roboto-Bold',
            fontWeight: '700'
        },
        w700: {
            fontFamily: 'Roboto-Bold',
            fontWeight: '700'
        },
        w800: {
            fontFamily: 'Roboto-Black',
            fontWeight: '700'
        },
        w900: {
            fontFamily: 'Roboto-Black',
            fontWeight: '900'
        }
    }
    return weights[nextVal];
}

const appearance = {
    themeStyle: getThemeStyle,
    colors: {
        primary: _primary,
        secondary: () => {
            return global.client ? global.client.colors.semi_dark : '#43955D'
        },
        tertiary: () => {
            return global.client ? global.client.colors.semi_light : '#87DA81'
        },
        soft: () => {
            return getThemeStyle() === 'dark' ? 'rgba(45,45,45,1)' : 'rgba(245,245,245,1)';
        },
        grey: () => {
            return getThemeStyle() === 'dark' ? '#4A4A4A' : '#9B9B9B';
        },
        textField: () => {
            return getThemeStyle() === 'dark' ? 'rgba(65,65,65,1)' : '#E2E3E4'
        },
        dropdown: () => {
            return getThemeStyle() === 'dark' ? 'rgba(65,65,65,1)' : '#E2E3E4'
        },
        sidebarIcon: () => {
            return getThemeStyle() === 'dark' ? 'white' : '#B4B4B4'
        },
        background: _background,
        loginBackground: () => {
            return getThemeStyle() === 'dark' ? _background() : _primary();
        },
        panelBackground: _panelBackground,
        layerBackground: opacity => {
            if(!isNaN(opacity)) {
                return getThemeStyle() === 'dark' ? `rgba(45,45,45,${opacity})`:`rgba(250,250,250,${opacity})`;
            }
            return getThemeStyle() === 'dark' ? '#232323' : '#FAFAFA';
        },
        alert: () => {
            return getThemeStyle() === 'dark' ? 'rgba(45,45,45,1)' : 'rgba(245,245,245,1)';
        },
        sheet: () => {
            return getThemeStyle() === 'dark' ? 'rgba(45,45,45,1)' : 'rgba(245,245,245,1)';
        },
        dropDown: () => {
            return getThemeStyle() === 'dark' ? 'rgba(35,35,35,1)' : 'white';
        },
        field: () => {
            return getThemeStyle() === 'dark' ? 'rgba(45,45,45,1)' : 'rgba(245,245,245,1)';
        },
        text: _text,
        subText: _subText,
        divider: _divider,
        softBorder: _softBorder,
        red: '#F45D4C',
        darkRed: '#CD4738',
        orange: '#EE8A2F',
        blue: '#4A90E2',
        green: '#6CBF66',
        yellow: '#E6C02A',
        lightGrey: '#9B9B9B',
        lighterGrey: '#D8D8D8',
        darkGrey: '#4A4A4A',
        darkerGrey: 'rgba(45,45,45,1)',
        dim: 'rgba(0,0,0,0.45)',
        transparent: 'rgba(0,0,0,0)'
    },
    fontWeight: {
        get: getFontWeight
    },
    styles: {
        panel: () => {
            return {
                width: '100%',
                overflow: 'hidden',
                backgroundColor: _panelBackground(),
                borderRadius: 15,
                borderWidth: 1,
                borderColor: _softBorder()
                /*...appearance.boxShadow({
                    renderAndroid: true,
                    opacity: 0.5,
                    radius: 8,
                    offset: {
                        width: 5,
                        height: 5
                    }
                }),
                ...Platform.OS === 'web' && {
                    borderWidth: 1,
                    borderColor: _softBorder()
                }*/
            }
        },
        card: () => {
            return {
                borderWidth: 1,
                borderColor: 'rgba(174,174,174,0.1)',
                backgroundColor: getThemeStyle() === 'dark' ? 'rgba(55,55,55,1)' : 'rgba(245,245,245,1)',
                borderRadius: 10,
                ...appearance.boxShadow({
                    opacity: 0.15,
                    radius: 5,
                    offset: {
                        width: 3.5,
                        height: 3.5
                    }
                })
            }
        }
    },
    textStyles: {
        standard: props => ({
            ...getFontWeight(700),
            color: _text(),
            fontSize: 16,
            ...props
        }),
        supporting: props => ({
            ...getFontWeight(500),
            color: _subText(),
            fontSize: 12,
            ...props
        }),
        small: props => ({
            ...getFontWeight(500),
            color: _subText(),
            fontSize: 10,
            ...props
        }),
        layerItemTitle: props => ({
            ...getFontWeight(800),
            color: _text(),
            fontSize: 16,
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...props
        }),
        panelTitle: props => ({
            ...getFontWeight(800),
            color: _text(),
            fontSize: 20,
            maxWidth: '100%',
            ...props
        }),
        header: props => ({
            ...getFontWeight(700),
            color: _text(),
            fontSize: 20,
            maxWidth: '100%',
            ...props
        }),
        title: props => ({
            ...getFontWeight(700),
            color: _text(),
            fontSize: 16,
            maxWidth: '100%',
            ...Platform.OS === 'web' && {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            },
            ...props
        }),
        subTitle: props => ({
            ...getFontWeight(500),
            color: _subText(),
            fontSize: 14,
            maxWidth: '100%',
            ...Platform.OS === 'web' && {
                whiteSpace: 'break-spaces'
            },
            ...props
        }),
        key: props => ({
            ...getFontWeight(500),
            color: _text(),
            fontSize: 14,
            maxWidth: '66%',
            ...props
        }),
        keyOverflow: props => ({
            ...getFontWeight(500),
            color: _text(),
            fontSize: 14,
            ...props
        }),
        value: props => ({
            ...getFontWeight(500),
            color: _subText(),
            fontSize: 14,
            textAlign: 'right',
            ...props
        })
    },
    icons: {
        standard: {
            width: 30,
            height: 30,
            borderRadius: 17.5,
        	resizeMode: 'cover',
            backgroundColor: _primary()
        },
        small: {
            width: 30,
            height: 30,
            borderRadius: 15,
        	borderWidth: 1.5,
            borderColor: 'white',
        	resizeMode: 'cover',
            backgroundColor: _primary()
        },
        overviewIcon: {
            width: 50,
            height: 50,
            borderRadius: 25,
        	borderWidth: 2,
            borderColor: 'white',
        	resizeMode: 'cover',
            backgroundColor: _primary()
        },
        user: {
            width: 30,
            height: 30,
            borderRadius: 17.5,
        	resizeMode: 'cover',
            backgroundColor: _primary()
        },
        vehicle: () => {
            return {
                width: 30,
                height: 30,
                borderRadius: 17.5,
                padding: 5,
            	backgroundColor: getThemeStyle() === 'dark' ? 'rgba(55,55,55,1)' : 'rgba(245,245,245,1)',
                borderWidth: 1.5,
                borderColor: _softBorder()
            }
        }
    },
    panelHeader: minimized => {
        return {
            css: {
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 10,
                paddingBottom: 10,
                backgroundColor: _background(),
                borderRadius: 15
            },
            buttons: {
                red: '#FF5B51',
                yellow: '#FDC90F',
                green: '#54C22C',
                onHover: {
                    //boxShadow: 'inset 2px 2px 4px rgba(174,174,174,0.5), inset -2px -2px 4px rgba(255,255,255,0.5)'
                }
            }
        };
    },
    boxShadow: (props = {}) => {

        let { color, offset, opacity, radius } = props;
        if(Platform.OS === 'android') {
            return props.renderAndroid ? {
                borderColor: _softBorder(),
                borderWidth: 1
            } : null
        }
        return {
            shadowColor: props && props.color ? props.color : (getThemeStyle() === 'dark' ? 'rgba(15,15,15,1)' : 'rgba(175,175,174,1)'),
            shadowOpacity: props && props.opacity ? props.opacity : 0.25,
            shadowRadius: props && props.radius ? props.radius : 7.5,
            shadowOffset: props && props.offset ? props.offset : {
                width: 2.5,
                height: 2.5
            }
        }
    },
    dummyField: () => {
        return {
            flexGrow: 1,
            height: 35,
            backgroundColor: _background(),
            borderRadius: 8,
            borderWidth: 1,
            borderColor: 'rgba(175,175,175,0.25)',
        }
    },
    headerBackgroundColor: () => 'rgba(174,174,174,0.1)'
}
export default appearance
