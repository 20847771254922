import React, { useEffect, useState } from 'react';
import { Animated, Image, ScrollView, Text, View } from 'react-native';

import moment from 'moment';
import update from 'immutability-helper';

import Appearance from 'eCarra/styles/Appearance';
import DatePicker from 'eCarra/views/DatePicker/';
import Driver from 'eCarra/classes/Driver.js';
import Layer from 'eCarra/structure/Layer.js';
import LottieView from 'eCarra/views/Lottie/';
import { Map } from 'eCarra/views/Maps/';
import Screen from 'eCarra/files/Screen.js';
import SocketHelper from 'eCarra/files/SocketHelper.js';
import TouchableHighlight from 'eCarra/views/TouchableHighlight/';
import { TripNavigator, EditReservationItem } from 'eCarra/managers/Reservations.js';
import Utils from 'eCarra/files/Utils.js';
import Valhalla from 'eCarra/classes/Valhalla.js';

const OnDemandSearching = ({ abstract, onPress, onClose, utils }) => {

    const alertID = 'onDemandSearching';

    const [bottom, setBottom] = useState(new Animated.Value(0));
    const [nearbyVehicles, setNearbyVehicles] = useState([]);
    const [opacity, setOpacity] = useState(new Animated.Value(0));
    const [scale, setScale] = useState(new Animated.Value(0));
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [visible, setVisible] = useState(false);

    const scheduleTimeAndDate = noDrivers => {
        utils.layer.open({
            id: `on-demand-set-schedule-${abstract.getTag()}`,
            abstract: abstract,
            Component: OnDemandSetSchedule.bind(this, {
                noDrivers: noDrivers
            })
        });
    }

    const onButtonPress = key => {
        if(key === 'schedule') {
            scheduleTimeAndDate(false);
        }
        onCloseAlert();
    }

    const onCloseAlert = callback => {

        setSearchTimeout(searchTimeout => {
            if(searchTimeout) {
                clearTimeout(searchTimeout);
            }
            return null;
        });

        Animated.spring(scale, {
            toValue: 0,
            duration: 150,
            useNativeDriver: false
        }).start();
        Animated.timing(opacity, {
            toValue: 0,
            duration: 150,
            useNativeDriver: false
        }).start(() => {
            setVisible(false);
            if(typeof(onClose) === 'function') {
                onClose();
            }
        });
    }

    const onCommunityUpdate = data => {
        try {
            let { locations, user_id, vehicle_id } = JSON.parse(data);
            setNearbyVehicles(vehicles => {

                // prevent nearby vehicle from showing if vehicle is driven by current user
                if(user_id == utils.user.get().user_id) {
                    return vehicles;
                }

                // declare most recent location
                let location = locations && locations.length > 0 ? locations[locations.length - 1] : null;
                if(!location) {
                    throw new Error('no location found in the payload');
                }

                // update previous vehicle props if found
                let targets = vehicles || [];
                let index = targets.findIndex(vehicle => vehicle.id === vehicle_id);
                if(index < 0) {
                    return targets.concat([{
                        id: vehicle_id,
                        location: {
                            latitude: location.lat,
                            longitude: location.long,
                            heading: location.heading
                        }
                    }]);
                }

                // add newly found vehicle to nearby vehicles
                return update(targets, {
                    [index]: {
                        location: {
                            $set: {
                                latitude: location.lat,
                                longitude: location.long,
                                heading: location.heading
                            }
                        }
                    }
                });
            });

        } catch(e) {
            console.error(e.message);
        }
    }

    const onDriverStart = data => {
        try {
            let { driver } = JSON.parse(data);
            abstract.object.driver = Driver.create(driver);

            utils.alert.show({
                title: 'On the Way!',
                message: `Your ${Utils.apply(abstract.type, {
                    orders: () => `${abstract.object.channel.name} Order`,
                    reservations: () => 'Reservation'
                })} has been started by ${abstract.object.driver.full_name}. ${Utils.apply(abstract.type, {
                    orders: () => `${abstract.object.driver.first_name} is on the way to pickup your order from ${abstract.object.host.name}`,
                    reservations: () => `${abstract.object.driver.first_name} is on the way to the pickup location at ${abstract.object.origin.address}`
                })}`,
                icon: {
                    path: abstract.object.driver.avatar,
                    style: {
                        borderRadius: 32.5,
                        overflow: 'hidden'
                    }
                },
                buttons: [{
                    key: 'contact',
                    title: 'Contact Driver',
                    style: 'default'
                },{
                    key: 'trip-navigator',
                    title: 'Open Trip Navigator',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Dismiss',
                    style: 'cancel'
                }],
                onPress: key => {
                    if(key === 'contact') {
                        utils.layer.messaging(abstract);
                        return;
                    }
                    if(key === 'trip-navigator') {
                        onOpenTripNavigator(abstract);
                        return;
                    }
                }
            });
            onCloseAlert();

        } catch(e) {
            onCloseAlert();
            utils.alert.show({
                title: 'Oops!',
                message: Utils.apply(abstract.type, {
                    orders: () => `A driver has started your ${abstract.object.channel.name} Order but there was an issue opening your Navigator. ${e.message || 'An unknown error occurred'}`,
                    reservations: () => `A driver started your ride but there was an issue opening your Navigator. ${e.message || 'An unknown error occurred'}`
                })
            });
        }
    }

    const onOpenTripNavigator = async abstract => {
        try {
            // fetch route for rides that do not have any stops
            if(!abstract.object.stops || abstract.object.stops.length === 0) {
                let route = await Valhalla.getRoute(utils, abstract.object.getLocations());
                abstract.object.route = route;
                abstract.object.overlays = [{
                    id: 'suggested-route',
                    coordinates: route.polyline
                }];
            }

            // open trip navigator
            utils.layer.openFloating({
                id: `trip-navigator-${abstract.getTag()}`,
                abstract: abstract,
                Component: TripNavigator
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing the trip navigator. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getContent = () => {
        if(visible === false) {
            return null;
        }
        let buttons = [{
            key: 'schedule',
            title: 'Schedule Ride',
            style: 'default'
        }];
        return (
            <View style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                width: Screen.width(),
                height: Screen.height(),
                backgroundColor: Appearance.colors.transparent,
                zIndex: 9950
            }}>
                <Animated.View style={{
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    opacity: opacity,
                    position: 'absolute',
                    backgroundColor: Appearance.colors.dim
                }} />

                <Animated.View style={{
                    borderRadius: 10,
                    backgroundColor: Appearance.colors.alert(),
                    overflow:'hidden',
                    width: '100%',
                    maxHeight: Screen.height() - 30,
                    maxWidth: Screen.layer.maxWidth - 30,
                    alignItems: 'center',
                    zIndex: 9999,
                    opacity: opacity,
                    bottom: bottom,
                    transform: [{
                        scale: scale
                    }]
                }}>
                    <Map
                    utils={utils}
                    nearbyVehicles={nearbyVehicles}
                    annotations={[{
                        id: 'location',
                        location: abstract.object.origin.location,
                        anchor: {
                            x: 0.5,
                            y: 0.5
                        },
                        content: (
                            <LottieView
                            autoPlay={true}
                            loop={true}
                            source={require('eCarra/files/lottie/location-broadcast-blue.json')}
                            duration={2500}
                            style={{
                                width: 65,
                                height: 65,
                            }}/>
                        )
                    }]}
                    style={{
                        width: '100%',
                        height: Screen.width() * 0.65,
                        maxHeight: 350,
                        borderBottomWidth: 1,
                        borderBottomColor: Appearance.colors.divider()
                    }} />

                    <Text style={{
                        ...Appearance.textStyles.panelTitle(),
                        paddingTop: 15,
                        paddingLeft: 15,
                        paddingRight: 15,
                        marginBottom: 8,
                        textAlign: 'center',
                    }}>{'Connecting with Nearby Drivers'}</Text>

                    <ScrollView style={{
                        flexGrow: 1,
                        width: '100%',
                        maxHeight: Screen.height() / 2,
                        marginBottom: 15
                    }}>
                        <View style={{
                            flex: 1,
                            width: '100%'
                        }}>
                            <Text style={{
                                ...Appearance.textStyles.subTitle(),
                                width: '100%',
                                height: '100%',
                                paddingLeft: 15,
                                paddingRight: 15,
                                textAlign: 'center',
                            }}>{`We're searching for available drivers in your area. You can choose a time to schedule your ride for a dedicated driver if no drivers are found in your area.`}</Text>
                        </View>
                    </ScrollView>

                    {buttons.filter(item => {
                        return item.visible !== false;
                    }).map(item => {
                        return (
                            <TouchableHighlight
                            key={item.key}
                            activeOpacity={0.9}
                            underlayColor={Appearance.colors.divider()}
                            onPress={onButtonPress.bind(this, item.key)}
                            style={{
                                height: 50,
                                width: '100%'
                            }}>
                                <View style={{ height: 50 }}>
                                    <View style={{
                                        width: '100%',
                                        height: 1,
                                        backgroundColor: Appearance.colors.divider()
                                    }}/>
                                    <View style={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 50
                                    }}>
                                        <Text style={{
                                            fontSize: 16,
                                            ...Appearance.fontWeight.get(400),
                                            textAlign: 'center',
                                            color: (item.style == 'cancel' || item.style == 'destructive') ? Appearance.colors.text() : Appearance.colors.primary()
                                        }}>{item.title}</Text>
                                    </View>
                                </View>
                            </TouchableHighlight>
                        )
                    })}
                </Animated.View>
            </View>
        )
    }

    const setupTimeout = async () => {
        try {
            setSearchTimeout(setTimeout(() => {
                onCloseAlert();
                scheduleTimeAndDate(true);
            }, 30000));
        } catch(e) {
            console.error(e.message);
            onCloseAlert();
        }
    }

    useEffect(() => {

        setVisible(true);
        setupTimeout();
        setTimeout(() => {
            Animated.spring(scale, {
                toValue: 1,
                duration: 500,
                useNativeDriver: false
            }).start();
            Animated.timing(opacity, {
                toValue: 1,
                duration: 250,
                useNativeDriver: false
            }).start();
        }, 300);

        utils.sockets.on('community', 'on_nearby_update', onCommunityUpdate);
        utils.sockets.on('on_demand', `on_driver_start_${abstract.getTag()}`, onDriverStart);

        return () => {
            utils.sockets.off('community', 'on_nearby_update', onCommunityUpdate);
            utils.sockets.off('on_demand', `on_driver_start_${abstract.getTag()}`, onDriverStart);
            if(searchTimeout) {
                clearTimeout(searchTimeout);
            }
        }
    }, []);

    return getContent();
}

export default OnDemandSearching;

export const OnDemandSetSchedule = ({ noDrivers }, { index, options, abstract, utils }) => {

    const layerID = `on-demand-set-schedule-${abstract.getTag()}`;
    const [date, setDate] = useState(moment().add((abstract.object.service.minBookingTime || 0), 'seconds'));
    const [invalidDate, setInvalidDate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [minDate, setMinDate] = useState(moment().add((abstract.object.service.minBookingTime || 0), 'seconds'));

    const onInvalidDateChange = val => {
        setInvalidDate(val);
    }

    const onUpdateTarget = async () => {
        if(!date) {
            utils.alert.show({
                title: 'Oops!',
                message: Utils.apply(abstract.type, {
                    orders: () => `Please choose a drop off time and date for this ${abstract.object.channel.name} Order`,
                    reservations: () => 'Please choose a pickup time and date for this Reservation'
                })
            });
            return;
        }
        if(invalidDate) {
            utils.alert.show({
                title: 'Just a Second',
                message: `Please choose a date after ${Utils.formatDate(minDate)} before moving on`
            });
            return;
        }
        try {

            setLoading('done');
            abstract.object.open();
            abstract.object.set({
                ...abstract.type === 'orders' && {
                    drop_off_date: date
                },
                ...abstract.type === 'reservations' && {
                    pickup_date: date
                }
            });

            await abstract.object.update(utils);
            setLoading(false);
            setLayerState('close');

            utils.content.fetch(abstract.type);
            utils.alert.show({
                title: 'All Done!',
                message: Utils.apply(abstract.type, {
                    orders: () => `Your order has been submitted for ${date.format('MMMM Do [at] h:mma')} and is pending approval.`,
                    reservations: () => `Your reservation has been submitted for ${date.format('MMMM Do [at] h:mma')} and is pending approval.`
                })
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating ${item.title.toLowerCase()}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    return (
        <Layer
        id={layerID}
        title={noDrivers ? 'No Drivers Found' : 'Schedule Date and Time'}
        utils={utils}
        index={index}
        options={{
            ...options,
            bottomCard: true,
            canCloseOnTap: false,
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: 'Done',
            color: 'primary',
            loading: loading === 'done',
            onPress: onUpdateTarget
        }]}>

            <View style={{
                alignItems: 'center',
                marginTop: 5
            }}>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 6
                }}>{noDrivers ? 'No Drivers Found' : 'Schedule Date and Time'}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 20,
                    textAlign: 'center'
                }}>{`${noDrivers ? 'We were unable to find available drivers in your area. ' : ''}${Utils.apply(abstract.type, {
                    orders: () => `Please choose a pickup time and date for your ${abstract.object.channel.name} Order`,
                    reservations: () => 'Please choose a pickup time and date for your Reservation'
                })}`}</Text>

                <DatePicker
                utils={utils}
                showTime={true}
                onInvalidChange={onInvalidDateChange}
                minDate={minDate}
                onChange={date => setDate(date)}/>

            </View>
        </Layer>
    )
}
