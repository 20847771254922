import React, { useRef, useState, useEffect } from 'react';
import { Animated, Image, Keyboard, Platform, ScrollView, Text, TextInput, View } from 'react-native';

import moment from 'moment';
import stripe from 'eCarra/files/Stripe/';
import update from 'immutability-helper';

import API from 'eCarra/files/api.js';
import Abstract from 'eCarra/classes/Abstract.js';
import AddressLookupField from 'eCarra/views/AddressLookupField.js';
import { ActiveAbstractHeader } from 'eCarra/managers/Vehicles.js';
import Appearance from 'eCarra/styles/Appearance.js';
import { BubbleHeader } from 'eCarra/structure/Navigation.js';
import Button from 'eCarra/views/Button.js';
import Calendar from 'eCarra/views/Calendar.js';
import { Call, Text as TextMessage } from 'react-native-openanything';
import Carousel, { ParallaxImage } from 'react-native-snap-carousel';
import CheckboxList from 'eCarra/views/CheckboxList.js';
import CreditsManager from 'eCarra/views/CreditsManager.js';
import DatePicker from 'eCarra/views/DatePicker/';
import DirectionalButtons from 'eCarra/views/DirectionalButtons.js';
import DropdownHeader from 'eCarra/views/DropdownHeader.js';
import { DriverNavigation, TripNavigator } from 'eCarra/managers/Reservations.js';
import DropdownMenu from 'eCarra/views/DropdownMenu.js';
import HeaderWithButton from 'eCarra/views/HeaderWithButton.js';
import KeyboardManager from 'eCarra/files/KeyboardManager.js';
import Layer, { LayerHeaderSpacing, LayerItem, LayerShell, FloatingLayerMinimizedHeight } from 'eCarra/structure/Layer.js';
import List from 'eCarra/views/List.js';
import LottieView from 'eCarra/views/Lottie/';
import { Map, Annotation } from 'eCarra/views/Maps/';
import { Messaging } from 'eCarra/managers/Messages.js';
import MultipleAddressLookupField from 'eCarra/views/MultipleAddressLookupField.js';
import { NavigationHeight } from 'eCarra/structure/Navigation.js';
import Order from 'eCarra/classes/Order.js';
import OrderHost from 'eCarra/classes/OrderHost.js';
import OrderChannel from 'eCarra/classes/OrderChannel.js';
import OrderCategory from 'eCarra/classes/OrderCategory.js';
import OrderOption from 'eCarra/classes/OrderOption.js';
import Panel from 'eCarra/structure/Panel.js';
import PaymentMethod from 'eCarra/classes/PaymentMethod.js';
import PaymentMethodManager from 'eCarra/views/PaymentMethodManager.js';
import PromoCodeLookup from 'eCarra/views/PromoCodeLookup.js';
import ProgressBar from 'eCarra/views/ProgressBar/';
import Request from 'eCarra/files/Request/';
import Screen from 'eCarra/files/Screen.js';
import Service from 'eCarra/classes/Service.js';
import StatusCodes from 'eCarra/files/StatusCodes.js';
import User from 'eCarra/classes/User.js';
import TextView from 'eCarra/views/TextView.js';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import Utils, { LineItem, ModularContentLogic, useRequestManager } from 'eCarra/files/Utils.js';
import Views, { AltBadge } from 'eCarra/views/Main.js';

export const fetchOrderChannels = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { channels } = await Request.get(utils, '/orders/', {
                type: 'channels',
                ...props
            });
            resolve(channels.map(c => OrderChannel.create(c)));
        } catch(e) {
            reject(e);
        }
    })
}

export const fetchOrderLineItems = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { payment, line_items, updated_items } = await Request.post(utils, '/order/', {
                type: 'line_items',
                ...props
            });
            resolve({
                payment: payment,
                line_items: line_items || [],
                updated_items: updated_items
            });
        } catch(e) {
            reject(e);
        }
    })
}

export const onStartOrder = async (utils, abstract) => {
    return new Promise(async (resolve, reject) => {
        try {
            if(!utils.driver.vehicle.get()) {
                throw new Error(`You need to add a vehicle before you start this ${abstract.object.channel.name} Order`);
                return;
            }
            if(abstract.object.driver && abstract.object.driver.user_id !== utils.user.get().user_id) {
                throw new Error(`This ${abstract.object.channel.name} Order has already been started by ${abstract.object.driver.full_name}`);
                return;
            }

            let status = await abstract.object.updateStatus(utils, StatusCodes.orders.toPickup);
            utils.sockets.emit('messages', 'join', {
                id: abstract.getID(),
                type: abstract.type
            });
            resolve(abstract);

            setTimeout(() => {
                abstract.object.status = status;
                utils.layer.openFloating({
                    id: `driver-navigation-${abstract.getTag()}`,
                    abstract: abstract,
                    Component: DriverNavigation.bind(this, {
                        resuming: false
                    })
                });
            }, 1000)

        } catch(e) {
            reject(e);
        }
    })
}

export const onResumeOrder = async (utils, abstract) => {
    return new Promise(async (resolve, reject) => {
        try {

            if(!utils.driver.vehicle.get()) {
                throw new Error(`You need to add a vehicle before you start this ${abstract.object.channel.name} Order`);
                return;
            }
            if(abstract.object.driver && abstract.object.driver.user_id !== utils.user.get().user_id) {
                throw new Error(`This ${abstract.object.channel.name} Order has been started by ${abstract.object.driver.full_name}`);
                return;
            }

            resolve();
            setTimeout(() => {
                utils.layer.openFloating({
                    id: `driver-navigation-${abstract.getTag()}`,
                    abstract: abstract,
                    Component: DriverNavigation.bind(this, {
                        resuming: true
                    })
                });
            }, 1000)

        } catch(e) {
            reject(e);
        }
    })
}

// Panels
export const OrderBooking = ({ channel }, { utils }) => {

    const panelID = `order${channel.id}Booking`;
    const newHostsRef = useRef(null);
    const popularHostsRef = useRef(null);
    const popularItemsRef = useRef(null);
    const recentOrdersRef = useRef(null);

    const [loading, setLoading] = useState(true);
    const [nearbyHosts, setNearbyHosts] = useState(null);
    const [newHosts, setNewHosts] = useState(null);
    const [newItems, setNewItems] = useState(null);
    const [recentOrders, setRecentOrders] = useState();
    const [popularHosts, setPopularHosts] = useState(null);
    const [popularItems, setPopularItems] = useState(null);
    const [scrollOffsets, setScrollOffsets] = useState({
        popular_hosts: 0,
        popular_items: 0,
        new_hosts: 0,
        recent_orders: 0
    });

    const fetchOverview = async () => {
        try {
            setLoading(true);
            let location = utils.location.last();
            let { new_hosts, new_items, popular_hosts, popular_items, nearby_hosts, recent_orders } = await Request.get(utils, '/orders/', {
                type: 'mobile_overview',
                order_channel: channel.id,
                location: location && {
                    lat: location.latitude,
                    long: location.longitude
                }
            })

            setLoading(false);
            setRecentOrders(recent_orders.map(order => Order.create(order)));
            setNewHosts(new_hosts.map(host => OrderHost.create(host)));
            setPopularHosts(popular_hosts.map(host => OrderHost.create(host)));
            setNearbyHosts(nearby_hosts.map(host => OrderHost.create(host)));
            setPopularItems(popular_items.map(item => {
                let option = OrderOption.create(item);
                option.category.host = OrderHost.create(item.category.host);
                return option;
            }));
            setNewItems(new_items.map(item => {
                let option = OrderOption.create(item);
                option.category.host = OrderHost.create(item.category.host);
                return option;
            }));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing the ${channel.name} Order content. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onHostPress = async host => {
        try {
            // fetch most recent location
            let location = await utils.location.get();

            // build order object and set default variables
            let order = Order.new();
            order.host = host;
            order.channel = channel;
            order.customer = utils.user.get();
            order.service = host.service;
            order.drop_off_date = moment().add(host.service.minBookingTime || 0, 'seconds');
            order.origin = host.getClosestLocation(utils);
            order.destination = location && {
                name: location.name,
                location: location
            }

            // open order details layer
            utils.layer.open({
                id: `order-host-details-${host.id}`,
                Component: OrderHostDetails.bind(this, {
                    host: host,
                    abstract: Abstract.create({
                        type: 'orders',
                        object: order
                    })
                })
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up your ${host.channel.name} Order for ${host.name}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onItemPress = async item => {
        try {

            // fetch most recent location
            let location = await utils.location.get();

            // build order object and set default variables
            let order = Order.new();
            order.host = item.host;
            order.channel = channel;
            order.customer = utils.user.get();
            order.service = item.host.service;
            order.drop_off_date = moment().add(item.host.service.minBookingTime || 0, 'seconds');
            order.origin = item.host.getClosestLocation(utils);
            order.destination = location && {
                name: location.name,
                location: location
            }

            // open order details layer
            utils.layer.open({
                id: `order-host-details-${item.host.id}`,
                Component: OrderHostDetails.bind(this, {
                    item: item,
                    host: item.host,
                    abstract: Abstract.create({
                        type: 'orders',
                        object: order
                    })
                })
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up your ${item.category.host.channel.name} Order for ${item.category.host.name}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onOrderAgain = async order => {
        try {
            order.id = null;
            order.status = null;
            order.drop_off_date = null;
            utils.layer.open({
                id: `order-host-details-${order.host.id}`,
                Component: OrderHostDetails.bind(this, {
                    host: order.host,
                    abstract: Abstract.create({
                        type: 'orders',
                        object: order
                    })
                })
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up your ${host.channel.name} Order for ${host.name}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUpdateScrollOffset = (key, index) => {
        setScrollOffsets(offsets => update(offsets, {
            [key]: {
                $set: index
            }
        }))
    }

    const getCarouselItemStyles = () => {
        return {
            paddingTop: 8,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 20,
            ...(Platform.OS === 'web' || Utils.isMobile() === false) && {
                paddingLeft: 15
            }
        }
    }

    const getCarouselProps = target => {
        return {
            sliderWidth: Screen.panel.maxWidth(),
            itemWidth: Screen.panel.maxWidth() - 60,
            ...Platform.OS !== 'web' && {
                layoutCardOffset: 0,
                loop: target.length > 1
            },
            ...(Platform.OS === 'web' || Utils.isMobile() === false) && {
                sliderWidth: Screen.panel.maxWidth() - 15,
                itemWidth: Screen.panel.maxWidth() - 30,
                ...target.length >= 3 && {
                    itemWidth: Screen.panel.maxWidth() / 3
                }
            }
        }
    }

    const getNearbyHosts = () => {
        if(!nearbyHosts || nearbyHosts.length === 0) {
            return null;
        }
        return (
            <View style={{
                marginBottom: 30,
                paddingHorizontal: 15
            }}>
                <Text
                ellipsizeMode={'tail'}
                numberOfLines={1}
                style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 8
                }}>
                    {`${channel.name} within 10 miles`}
                </Text>
                <View style={Appearance.styles.panel()}>
                    {nearbyHosts.map((host, index) => {
                        let { text } = host.getHostOperatingHours();
                        let distance = host.locations.reduce((distance, location) => {
                            return !distance || location.distance < distance ? location.distance : distance;
                        }, 0);
                        return (
                            Views.entry({
                                key: index,
                                title: host.name,
                                subTitle: text,
                                icon: {
                                    path: host.image
                                },
                                badge: {
                                    text: distance ? `${Utils.distanceConversion(distance || 0)} away` : null,
                                    color: Appearance.colors.grey()
                                },
                                bottomBorder: index !== nearbyHosts.length - 1,
                                onPress: onHostPress.bind(this, host)
                            })
                        )
                    })}
                </View>
            </View>
        )
    }

    const getNewHosts = () => {
        if(!newHosts || newHosts.length === 0) {
            return null;
        }
        return (
            <View style={{
                marginBottom: 10
            }}>
                <Text
                ellipsizeMode={'tail'}
                numberOfLines={1}
                style={{
                    ...Appearance.textStyles.panelTitle(),
                    paddingHorizontal: 15,
                    marginBottom: 3
                }}>
                    {`Now Available on ${channel.name}`}
                </Text>
                <Text
                ellipsizeMode={'tail'}
                numberOfLines={1}
                style={{
                    ...Appearance.textStyles.subTitle(),
                    paddingHorizontal: 15
                }}>
                    {`Get to know the newest additions`}
                </Text>
                {getNewHostsComponents()}
            </View>
        )
    }

    const getNewHostsComponents = () => {
        if(Utils.isMobile() === false) {
            return (
                <View style={{
                    paddingHorizontal: 15,
                    marginTop: 8,
                    marginBottom: 15
                }}>
                    <View style={{
                        ...Appearance.styles.panel(),
                        width: '100%'
                    }}>
                        {newHosts.map((host, index) => {
                            return (
                                Views.entry({
                                    key: index,
                                    title: host.name,
                                    subTitle: host.description,
                                    icon: {
                                        path: host.image
                                    },
                                    bottomBorder: index !== newHosts.length - 1,
                                    onPress: onHostPress.bind(this, host)
                                })
                            )
                        })}
                    </View>
                </View>
            )
        }
        return (
            <>
            <Carousel
            ref={newHostsRef}
            data={newHosts}
            enableSnap={true}
            activeSlideAlignment={'center'}
            {...getCarouselProps(newHosts)}
            onScrollIndexChanged={onUpdateScrollOffset.bind(this, 'new_hosts')}
            renderItem={({ item, index }) => {
                let host = item;
                return (
                    <View
                    key={index}
                    style={getCarouselItemStyles()}>
                        <TouchableOpacity
                        activeOpacity={0.6}
                        onPress={onHostPress.bind(this, host)}
                        style={{
                            ...Appearance.styles.panel(),
                            width: '100%',
                            overflow: 'hidden'
                        }}>
                            <View style={{
                                borderBottomColor: Appearance.colors.divider(),
                                borderBottomWidth: 1
                            }}>
                                <Image
                                source={host.cover_image}
                                style={{
                                    height: Platform.OS === 'web' || Utils.isMobile() === false ? 250 : 175,
                                    width: '100%',
                                    resizeMode: 'cover'
                                }}/>
                            </View>
                            {Views.entry({
                                title: host.name,
                                subTitle: host.description,
                                hideIcon: true,
                                bottomBorder: false,
                                propStyles: {
                                    subTitle: {
                                        numberOfLines: 2
                                    }
                                },
                                onPress: onHostPress.bind(this, host)
                            })}
                        </TouchableOpacity>
                    </View>
                )
            }}/>
            {getPagingComponent('new_hosts', newHosts)}
            </>
        )
    }

    const getNewItems = () => {
        if(!newItems || newItems.length === 0) {
            return null;
        }
        return (
            <View style={{
                marginBottom: 30,
                paddingHorizontal: 15
            }}>
                <Text
                ellipsizeMode={'tail'}
                numberOfLines={1}
                style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 3
                }}>
                    {`Newly Added ${channel.name} Items`}
                </Text>
                <Text
                ellipsizeMode={'tail'}
                numberOfLines={1}
                style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 8
                }}>
                    {`Learn more about what we're adding to ${channel.name}`}
                </Text>

                <View style={Appearance.styles.panel()}>
                    {newItems.map((item, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: item.name,
                                subTitle: item.description,
                                badge: {
                                    text: Utils.toCurrency(item.cost),
                                    color: Appearance.colors.primary()
                                },
                                icon: {
                                    path: item.images[0]
                                },
                                propStyles: {
                                    subTitle: {
                                        numberOfLines: 2
                                    }
                                },
                                onPress: onItemPress.bind(this, item),
                                bottomBorder: index !== newItems.length - 1
                            })
                        )
                    })}
                </View>
            </View>

        )
    }

    const getPagingComponent = (key, target) => {
        if(!target || target.length <= 1 || Utils.isMobile() === true) {
            return null;
        }
        return (
            <View style={{
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%',
                marginBottom: 5
            }}>
                {target.map((_, index) => {
                    return (
                        <View
                        key={index}
                        style={{
                            width: 15,
                            height: 3,
                            borderRadius: 1.5,
                            backgroundColor: scrollOffsets[key] === index ? Appearance.colors.primary() : Appearance.colors.grey(),
                            marginHorizontal: 4
                        }}/>
                    )
                })}
            </View>
        )
    }

    const getPopularHosts = () => {
        if(!popularHosts || popularHosts.length === 0) {
            return null;
        }
        return (
            <View style={{
                marginBottom: 10
            }}>
                <Text
                ellipsizeMode={'tail'}
                numberOfLines={1}
                style={{
                    ...Appearance.textStyles.panelTitle(),
                    paddingHorizontal: 15,
                    marginBottom: 3
                }}>
                    {`Trending on ${channel.name}`}
                </Text>
                <Text
                ellipsizeMode={'tail'}
                numberOfLines={1}
                style={{
                    ...Appearance.textStyles.subTitle(),
                    paddingHorizontal: 15
                }}>
                    {`Most loved throughout the community`}
                </Text>
                {getPopularHostsComponents()}
            </View>
        )
    }

    const getPopularHostsComponents = () => {
        if(Utils.isMobile() === false) {
            return (
                <View style={{
                    paddingHorizontal: 15,
                    marginTop: 8,
                    marginBottom: 15
                }}>
                    <View style={{
                        ...Appearance.styles.panel(),
                        width: '100%'
                    }}>
                        {popularHosts.map((host, index) => {
                            return (
                                Views.entry({
                                    key: index,
                                    title: host.name,
                                    subTitle: host.description,
                                    icon: {
                                        path: host.image
                                    },
                                    bottomBorder: index !== popularHosts.length - 1,
                                    onPress: onHostPress.bind(this, host)
                                })
                            )
                        })}
                    </View>
                </View>
            )
        }
        return (
            <>
            <Carousel
            ref={popularHostsRef}
            data={popularHosts}
            enableSnap={true}
            activeSlideAlignment={'center'}
            {...getCarouselProps(popularHosts)}
            onScrollIndexChanged={onUpdateScrollOffset.bind(this, 'popular_hosts')}
            renderItem={({ item, index }) => {
                let host = item;
                let hours = host.getHostOperatingHours();
                return (
                    <View
                    key={index}
                    style={getCarouselItemStyles()}>
                        <TouchableOpacity
                        activeOpacity={0.6}
                        onPress={onHostPress.bind(this, host)}
                        style={{
                            ...Appearance.styles.panel(),
                            width: '100%',
                            overflow: 'hidden'
                        }}>
                            <View style={{
                                borderBottomColor: Appearance.colors.divider(),
                                borderBottomWidth: 1
                            }}>
                                <Image
                                source={host.image}
                                style={{
                                    width: '100%',
                                    resizeMode: 'cover',
                                    height: Platform.OS === 'web' || Utils.isMobile() === false ? 250 : 175
                                }}/>
                            </View>
                            <View style={{
                                padding: 10,
                                alignItems: 'center'
                            }}>
                                <Text style={{
                                    ...Appearance.textStyles.title(),
                                    textAlign: 'center',
                                    marginBottom: 5
                                }}>{host.name}</Text>
                                <AltBadge content={hours} />
                            </View>
                        </TouchableOpacity>
                    </View>
                )
            }} />
            {getPagingComponent('popular_hosts', popularHosts)}
            </>
        )
    }

    const getPopularItems = () => {
        if(!popularItems || popularItems.length === 0) {
            return null;
        }
        return (
            <View style={{
                marginBottom: 10
            }}>
                <Text
                ellipsizeMode={'tail'}
                numberOfLines={1}
                style={{
                    ...Appearance.textStyles.panelTitle(),
                    paddingHorizontal: 15,
                    marginBottom: 3
                }}>
                    {`Most Popular ${channel.name} Items`}
                </Text>
                <Text
                ellipsizeMode={'tail'}
                numberOfLines={1}
                style={{
                    ...Appearance.textStyles.subTitle(),
                    paddingHorizontal: 15
                }}>
                    {`Most loved throughout the community`}
                </Text>
                {getPopularItemsComponents()}
            </View>
        )
    }

    const getPopularItemsComponents = () => {
        if(Utils.isMobile() === false) {
            return (
                <View style={{
                    paddingHorizontal: 15,
                    marginTop: 8,
                    marginBottom: 15
                }}>
                    <View style={{
                        ...Appearance.styles.panel(),
                        width: '100%'
                    }}>
                        {popularItems.map((item, index) => {
                            return (
                                Views.entry({
                                    key: index,
                                    title: item.name,
                                    subTitle: item.description,
                                    icon: {
                                        path: item.category.host.image
                                    },
                                    badge: {
                                        text: Utils.toCurrency(item.cost),
                                        color: Appearance.colors.primary()
                                    },
                                    bottomBorder: index !== popularItems.length - 1,
                                    onPress: onItemPress.bind(this, item)
                                })
                            )
                        })}
                    </View>
                </View>
            )
        }
        return (
            <>
            <Carousel
            ref={popularItemsRef}
            data={popularItems}
            enableSnap={true}
            activeSlideAlignment={'center'}
            {...getCarouselProps(popularItems)}
            onScrollIndexChanged={onUpdateScrollOffset.bind(this, 'popular_items')}
            renderItem={({ item, index }) => {
                return (
                    <View
                    key={index}
                    style={getCarouselItemStyles()}>
                        <View style={{
                            ...Appearance.styles.panel(),
                            width: '100%',
                            overflow: 'hidden'
                        }}>
                            <TouchableOpacity
                            activeOpacity={0.6}
                            onPress={onItemPress.bind(this, item)}
                            style={{
                                position: 'relative',
                                borderBottomColor: Appearance.colors.divider(),
                                borderBottomWidth: 1
                            }}>
                                <Image
                                source={item.images[0]}
                                style={{
                                    height: Platform.OS === 'web' || Utils.isMobile() === false ? 250 : 175,
                                    width: '100%',
                                    resizeMode: 'cover'
                                }}/>
                                <View style={{
                                    position: 'absolute',
                                    right: 12,
                                    bottom: 12,
                                    borderWidth: 2,
                                    borderColor: 'white',
                                    height: 35,
                                    width: 35,
                                    borderRadius: 17.5,
                                    overflow: 'hidden'
                                }}>
                                    <Image
                                    source={item.category.host.image}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        resizeMode: 'cover',

                                    }}/>
                                </View>
                            </TouchableOpacity>
                            {Views.entry({
                                title: item.name,
                                subTitle: item.description,
                                badge: {
                                    text: Utils.toCurrency(item.cost),
                                    color: Appearance.colors.primary()
                                },
                                hideIcon: true,
                                bottomBorder: false,
                                onPress: onItemPress.bind(this, item),
                                propStyles: {
                                    subTitle: {
                                        numberOfLines: 2
                                    }
                                }
                            })}
                        </View>
                    </View>
                )
            }}/>
            {getPagingComponent('popular_items', popularItems)}
            </>
        )
    }

    const getRecentOrders = () => {
        if(!recentOrders || recentOrders.length === 0) {
            return null;
        }
        return (
            <View style={{
                marginBottom: 10
            }}>
                <Text
                ellipsizeMode={'tail'}
                numberOfLines={1}
                style={{
                    ...Appearance.textStyles.panelTitle(),
                    paddingHorizontal: 15,
                    marginBottom: 3
                }}>
                    {`Order Again`}
                </Text>
                <Text
                ellipsizeMode={'tail'}
                numberOfLines={1}
                style={{
                    ...Appearance.textStyles.subTitle(),
                    paddingHorizontal: 15
                }}>
                    {`Let's stick to the classics`}
                </Text>
                {getRecentOrdersComponents()}
            </View>
        )
    }

    const getRecentOrdersComponents = () => {
        if(Utils.isMobile() === false) {
            return (
                <View style={{
                    paddingHorizontal: 15,
                    marginTop: 8,
                    marginBottom: 15
                }}>
                    <View style={{
                        ...Appearance.styles.panel(),
                        width: '100%'
                    }}>
                        {recentOrders.map((order, index) => {
                            let cost = order.cost_line_items ? order.cost_line_items.find(item => item.key === 'estimated_total') : null;
                            return (
                                Views.entry({
                                    key: index,
                                    title: order.host.name,
                                    subTitle: Utils.oxfordImplode(order.options.map(opt => opt.name)),
                                    icon: {
                                        path: order.options[0].images[0]
                                    },
                                    badge: {
                                        text: cost ? cost.message : null,
                                        color: Appearance.colors.primary()
                                    },
                                    bottomBorder: index !== recentOrders.length - 1,
                                    onPress: onOrderAgain.bind(this, order)
                                })
                            )
                        })}
                    </View>
                </View>
            )
        }
        return (
            <>
            <Carousel
            ref={recentOrdersRef}
            data={recentOrders}
            enableSnap={true}
            activeSlideAlignment={'center'}
            {...getCarouselProps(recentOrders)}
            onScrollIndexChanged={onUpdateScrollOffset.bind(this, 'recent_orders')}
            renderItem={({ item, index }) => {
                let order = item;
                let cost = order.cost_line_items ? order.cost_line_items.find(item => item.key === 'estimated_total') : null;
                return (
                    <View
                    key={index}
                    style={getCarouselItemStyles()}>
                        <View style={{
                            ...Appearance.styles.panel(),
                            width: '100%',
                            overflow: 'hidden'
                        }}>
                            <TouchableOpacity
                            activeOpacity={0.6}
                            onPress={onOrderAgain.bind(this, order)}
                            style={{
                                position: 'relative',
                                borderBottomColor: Appearance.colors.divider(),
                                borderBottomWidth: 1
                            }}>
                                <Image
                                source={order.options[0].images[0]}
                                style={{
                                    height: Platform.OS === 'web' || Utils.isMobile() === false ? 250 : 175,
                                    width: '100%',
                                    resizeMode: 'cover'
                                }}/>
                                <View style={{
                                    position: 'absolute',
                                    right: 12,
                                    bottom: 12,
                                    borderWidth: 2,
                                    borderColor: 'white',
                                    height: 35,
                                    width: 35,
                                    borderRadius: 17.5,
                                    overflow: 'hidden'
                                }}>
                                    <Image
                                    source={order.host.image}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        resizeMode: 'cover',

                                    }}/>
                                </View>
                            </TouchableOpacity>
                            {Views.entry({
                                title: order.host.name,
                                subTitle: Utils.oxfordImplode(order.options.map(opt => opt.name)),
                                badge: {
                                    text: cost ? cost.message : null,
                                    color: Appearance.colors.primary()
                                },
                                hideIcon: true,
                                bottomBorder: false,
                                propStyles: {
                                    subTitle: {
                                        numberOfLines: 2
                                    }
                                },
                                onPress: onOrderAgain.bind(this, order)
                            })}
                        </View>
                    </View>
                )
            }}/>
            {getPagingComponent('recent_orders', recentOrders)}
            </>
        )
    }

    useEffect(() => {
        if(popularHostsRef.current && popularHosts.length >= 3) {
            popularHostsRef.current.snapToItem(1, true);
        }
    }, [popularHostsRef.current, popularHosts]);

    useEffect(() => {
        if(recentOrdersRef.current && recentOrders.length >= 3) {
            recentOrdersRef.current.snapToItem(1, true);
        }
    }, [recentOrdersRef.current, recentOrders]);

    useEffect(() => {
        if(popularItemsRef.current && popularItems.length >= 3) {
            popularItemsRef.current.snapToItem(1, true);
        }
    }, [popularItemsRef.current, popularItems]);

    useEffect(() => {
        if(newHostsRef.current && newHosts.length >= 3) {
            newHostsRef.current.snapToItem(1, true);
        }
    }, [newHostsRef.current, newHosts]);

    useEffect(() => {
        fetchOverview();
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        options={{
            loading: loading,
            removeOverflow: true,
            removePadding: true,
            shouldStyle: false
        }}>
            <View style={{
                width: '100%',
                marginTop: 10
            }}>
                {getNearbyHosts()}
                {getPopularHosts()}
                {getRecentOrders()}
                {getPopularItems()}
                {getNewItems()}
                {getNewHosts()}
            </View>
        </Panel>
    );
}

export const OnTheWayOrders = ({ channel }, { utils }) => {

    const panelID = `onTheWayOrders${channel.id}`;
    const [loading, setLoading] = useState(true);
    const [overlays, setOverlays] = useState(null);
    const [driver, setDriver] = useState(null);
    const [order, setOrder] = useState(null);

    const onTripNavigatorPress = () => {

        let abstract = Abstract.create({
            type: 'orders',
            object: order
        })
        utils.layer.openFloating({
            id: `trip-navigator-${abstract.getTag()}`,
            abstract: abstract,
            Component: TripNavigator
        });
    }

    const fetchOrders = async () => {
        try {
            let { orders } = await Request.get(utils, '/orders/', {
                type: 'all_active_orders',
                order_channel: channel.id
            })
            setLoading(false);
            let order = orders.map(o => Order.create(o)).find(order => order.driver);
            if(order) {
                setOrder(order);
                setDriver(order.driver);
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your active ${channel.name} Orders. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getTimeToArrival = () => {
        if(!driver || !driver.time_to_arrival) {
            return `Arriving at ${moment(order.drop_off_date).format('h:mma')}`
        }
        return `Arriving at ${moment().add(driver.time_to_arrival, 'seconds').format('h:mma')}`
    }

    const onLocationUpdate = data => {
        try {
            let { order } = JSON.parse(data);
            let { location } = order.driver;
            if(location.polyline) {
                let coordinates = Utils.decodePolyline(location.polyline)
                setOverlays([{
                    key: 'driver_route',
                    coordinates: coordinates.map(c => [ c[1], c[0] ])
                }])
            }

            setDriver(driver => update(driver, {
                heading: {
                    $set: location.heading
                },
                time_to_arrival: {
                    $set: location.time_to_arrival
                },
                location: {
                    $set: {
                        latitude: location.lat,
                        longitude: location.long
                    }
                }
            }))

        } catch(e) {
            console.error(e.message)
        }
    }

    const onDetailsPress = () => {
        utils.sheet.show({
            items: [{
                key: 'contact',
                title: 'Contact Driver',
                style: 'default'
            },{
                key: 'trip-navigator',
                title: 'Open Trip Navigator',
                style: 'default'
            }]
        }, (key) => {
            if(key === 'cancel') {
                return;
            }

            let abstract = Abstract.create({
                type: 'orders',
                object: order
            })
            if(key === 'contact') {
                utils.layer.messaging(abstract);

            } else if(key === 'trip-navigator') {
                utils.layer.openFloating({
                    id: `trip-navigator-${abstract.getTag()}`,
                    abstract: abstract,
                    Component: TripNavigator
                });
            }
        })
    }

    const getContent = () => {
        if(!order) {
            return null;
        }
        return (
            <View style={{
                paddingHorizontal: 15
            }}>
                <ActiveAbstractHeader
                utils={utils}
                driver={driver}
                abstract={abstract} />

                <View style={{
                    ...Appearance.styles.panel(),
                    marginTop: 8
                }}>
                    {Views.entry({
                        title: driver ? driver.full_name : null,
                        subTitle: getTimeToArrival(),
                        icon: {
                            path: driver ? driver.avatar : null
                        },
                        bottomBorder: false,
                        onPress: onTripNavigatorPress,
                        propStyles: {
                            subTitle: {
                                numberOfLines: 2
                            }
                        }
                    })}
                </View>
            </View>
        )
    }

    useEffect(() => {
        fetchOrders();
    }, []);

    useEffect(() => {
        utils.content.subscribe(panelID, 'orders', {
            onFetch: fetchOrders,
            onUpdate: abstract => {

                // freshly ended orders
                if(order && order.id === abstract.getID()) {
                    if([
                        StatusCodes.orders.toPickup,
                        StatusCodes.orders.toDestination
                    ].includes(abstract.object.status.code)) {
                        setOrder(abstract.object);
                        return;
                    }
                    setOrder(null);
                    return;
                }

                // newlty started orders
                if([
                    StatusCodes.orders.toPickup,
                    StatusCodes.orders.arrivedAtHost,
                    StatusCodes.orders.toDestination
                ].includes(abstract.object.status.code)) {
                    fetchOrders();
                }
            }
        })

        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, [order])

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        style={{
            marginBottom: order ? 32 : 0
        }}
        options={{
            loading: loading,
            removeOverflow: true,
            shouldStyle: false,
            removePadding: true
        }}>
            {getContent()}
        </Panel>
    )
}

export const OrdersCalendar = ({ channel }, { utils }) => {

    const panelID = 'ordersCalendar';
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [searchText, setSearchText] = useState(null);

    const onChooseDate = () => {

        let tempDate = null;
        utils.layer.open({
            id: 'calendar-custom-date',
            Component: LayerShell.bind(this, {
                layerID: 'calendar-custom-date',
                extendedOptions: {
                    bottomCard: true
                },
                buttons: [{
                    key: 'done',
                    text: 'Done',
                    color: 'primary',
                    onPress: () => {
                        utils.events.emit('onLayerAction', {
                            action: 'close',
                            layerID: 'calendar-custom-date'
                        })
                        setDate(tempDate ? moment(tempDate).format('YYYY-MM-DD') : date)
                    }
                }],
                children: (
                    <View style={{
                        alignItems: 'center',
                        marginTop: 5
                    }}>
                        <Text style={{
                            ...Appearance.textStyles.panelTitle(),
                            marginBottom: 6
                        }}>{'Browse Other Dates'}</Text>
                        <Text style={{
                            ...Appearance.textStyles.subTitle(),
                            marginBottom: 20,
                            textAlign: 'center'
                        }}>{`Choose a month and year from the list below to view more ${channel.name} Orders`}</Text>
                        <DatePicker
                        utils={utils}
                        date={date}
                        onChange={date => tempDate = date}/>
                    </View>
                )
            })
        })
    }

    const getContent = () => {

        let targets = getOrders();
        if(Platform.OS === 'web' || Utils.isMobile() === false) {
            return (
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    paddingRight: 12
                }}>
                    <View style={{
                        ...Appearance.styles.panel(),
                        flexGrow: 1,
                        width: '50%',
                        paddingTop: 12
                    }}>
                        <Calendar
                        activeDate={date}
                        events={orders}
                        onDateChange={date => setDate(date.format('YYYY-MM-DD'))}
                        eventFilter={(day, order) => day.isSame(order.drop_off_date, 'day')}/>
                    </View>
                    <View style={{
                        ...Appearance.styles.panel(),
                        width: '50%',
                        marginLeft: 12,
                        marginRight: 12,
                        padding: 12
                    }}>
                        <View style={{
                            borderRadius: 10,
                            overflow: 'hidden'
                        }}>
                            {getOrderComponents(targets)}
                        </View>
                    </View>
                </View>
            )
        }

        return (
            <>
            <View style={Appearance.styles.panel()}>
                <Calendar
                activeDate={date}
                events={orders}
                onDateChange={date => setDate(date.format('YYYY-MM-DD'))}
                eventFilter={(day, order) => day.isSame(order.drop_off_date, 'day')}/>
            </View>
            {targets.length > 0 && (
                <View style={{
                    ...Appearance.styles.panel(),
                    marginTop: 8
                }}>
                    {getOrderComponents(targets)}
                </View>
            )}
            </>
        )
    }

    const getOrders = () => {
        return orders.filter(order => {
            if(searchText) {
                let searchTargets = [
                    order.id,
                    order.customer.full_name,
                    order.host.name,
                    order.origin.name,
                    order.origin.address,
                    order.destination.name,
                    order.destination.address
                ];
                if(!searchTargets.find(target => {
                    return target && target.toString().toLowerCase().includes(searchText);
                })) {
                    return false;
                }
            }
            return moment(date).isSame(order.drop_off_date, 'day');

        }).sort((a, b) => {
            return moment(a.drop_off_date).unix() - moment(b.drop_off_date).unix();
        });
    }

    const getOrderComponents = targets => {

        // return components showing that no orders were found if platform is web or device is not mobile
        if(targets.length === 0) {
            if(Platform.OS === 'web' || Utils.isMobile() === false) {
                return (
                    Views.entry({
                        title: 'No Orders Found',
                        subTitle: `There are no orders scheduled for ${moment(date).format('MMMM Do, YYYY')}`,
                        hideIcon: true,
                        bottomBorder: false
                    })
                )
            }
            return null;
        }

        // return all matching orders
        return targets.map((order, index, orders) => {
            return (
                Views.entry({
                    key: index,
                    title: order.destination.name && order.destination.name !== 'Current Location' ? order.destination.name : order.destination.address,
                    subTitle: `Ordered from ${order.host.name}`,
                    icon: {
                        path: order.host.image
                    },
                    bottomBorder: index !== orders.length - 1,
                    onPress: utils.layer.order.details.bind(this, order)
                })
            )
        })
    }

    const fetchOrders = async () => {
        try {
            let { orders } = await Request.get(utils, '/orders/', {
                type: 'all_orders',
                order_channel: channel.id
            });
            setLoading(false);
            setOrders(orders.map(o => Order.create(o)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the ${channel.name} Order calendar. ${(e.message || 'An unknown error occurred')}`
            })
        }
    }

    useEffect(() => {
        utils.content.subscribe(panelID, 'orders', {
            onFetch: fetchOrders,
            onUpdate: abstract => {
                let index = (orders || []).findIndex(order => order.id === abstract.getID());
                if(index >= 0) {
                    setOrders(update(orders, {
                        [index]: {
                            $set: abstract.object
                        }
                    }))
                }
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }

    }, [orders]);

    useEffect(() => {
        fetchOrders();
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`${channel.name} Order Calendar`}
        header={(
            <DropdownHeader
            label={`${moment(date).isSame(moment(), 'year') ? moment(date).format('MMMM') : moment(date).format('MMMM YYYY')} Orders`}
            onPress={onChooseDate}/>
        )}
        rightContent={(
            <DirectionalButtons
            onNext={() => setDate(moment(date).add(1, 'months'))}
            onBack={() => setDate(moment(date).subtract(1, 'months'))} />
        )}
        options={{
            loading: loading,
            removeOverflow: true,
            shouldStyle: false
        }}>
            {getContent()}
        </Panel>
    )
}

export const UpcomingOrders = ({ channel }, { utils }) => {

    const panelID = 'upcomingOrders';
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [scrollOffset, setScrollOffset] = useState(0);

    const getCarouselItemStyles = () => {
        return {
            paddingTop: 8,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 20,
            ...(Platform.OS === 'web' || Utils.isMobile() === false) && {
                paddingLeft: 15
            }
        }
    }

    const getCarouselProps = target => {
        return {
            sliderWidth: Screen.panel.maxWidth(),
            itemWidth: Screen.panel.maxWidth() - 30,
            ...Platform.OS !== 'web' && {
                layoutCardOffset: 0,
                loop: target.length > 1
            },
            ...(Platform.OS === 'web' || Utils.isMobile() === false) && {
                sliderWidth: Screen.panel.maxWidth() - 15,
                itemWidth: Screen.panel.maxWidth() - 30,
                ...target.length >= 3 && {
                    itemWidth: Screen.panel.maxWidth() / 3
                }
            }
        }
    }

    const getContent = () => {
        if(Platform.OS === 'web' || Utils.isMobile() === false) {
            return (
                <View style={{
                    paddingHorizontal: 15,
                    marginBottom: 15
                }}>
                    <View style={Appearance.styles.panel()}>
                        {orders.map((order, index) => {
                            return (
                                Views.entry({
                                    key: index,
                                    title: order.destination.name && order.destination.name !== 'Current Location' ? order.destination.name : order.destination.address,
                                    subTitle: moment(order.drop_off_date).format('MMMM Do, YYYY [at] h:mma'),
                                    icon: {
                                        path: order.host.image,
                                        style: Appearance.icons.user
                                    },
                                    bottomBorder: false,
                                    onPress: utils.layer.order.details.bind(this, order)
                                })
                            )
                        })}
                    </View>
                </View>
            )
        }
        return (
            <>
            <Carousel
            data={orders}
            enableSnap={true}
            activeSlideAlignment={'center'}
            {...getCarouselProps(orders)}
            onScrollIndexChanged={index => setScrollOffset(index)}
            renderItem={({ item, index }) => {
                let o = item;
                return (
                    <View
                    key={index}
                    style={getCarouselItemStyles()}>
                        <View style={{
                            ...Appearance.styles.panel()
                        }}>
                            <TouchableOpacity
                            activeOpacity={0.6}
                            onPress={utils.layer.order.details.bind(this, o)}
                            style={{
                                borderTopLeftRadius: 12,
                                borderTopRightRadius: 12,
                                overflow: 'hidden'
                            }}>
                                <Map
                                utils={utils}
                                overlays={o.getOverlays()}
                                annotations={o.getLocations()}
                                style={{
                                    height: Platform.OS === 'web' || Utils.isMobile() === false ? 400 : 200,
                                    width: '100%',
                                    marginBottom: 4,
                                    borderBottomWidth: 1,
                                    borderBottomColor: Appearance.colors.divider()
                                }}/>
                            </TouchableOpacity>
                            <View style={{
                                paddingTop: 3,
                                paddingHorizontal: 8,
                                paddingBottom: 8
                            }}>
                                {Views.entry({
                                    title: o.destination.name && o.destination.name !== 'Current Location' ? o.destination.name : o.destination.address,
                                    subTitle: moment(o.drop_off_date).format('MMMM Do, YYYY [at] h:mma'),
                                    icon: {
                                        path: o.host.image,
                                        style: Appearance.icons.user
                                    },
                                    bottomBorder: false,
                                    onPress: utils.layer.order.details.bind(this, o)
                                })}
                            </View>
                        </View>
                    </View>
                )
            }} />
            {orders && orders.length > 1 && (
                <View style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '100%',
                    marginBottom: 25
                }}>
                    {orders.map((_, index) => {
                        return (
                            <View
                            key={index}
                            style={{
                                width: 15,
                                height: 3,
                                borderRadius: 1.5,
                                backgroundColor: scrollOffset === index ? Appearance.colors.primary() : Appearance.colors.grey(),
                                marginHorizontal: 4
                            }}/>
                        )
                    })}
                </View>
            )}
            </>
        )
    }

    const fetchOrders = async () => {
        try {
            let { orders } = await Request.get(utils, '/orders/', {
                type: 'all_orders',
                date: moment().format('YYYY-MM-DD'),
                order_channel: channel.id
            })
            setLoading(false);
            setOrders(orders.map(o => Order.create(o)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your upcoming ${channel.name} orders. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchOrders();
    }, []);

    return orders.length > 0 ? (
        <Panel
        key={panelID}
        panelID={panelID}
        options={{
            loading: loading,
            removeOverflow: true,
            shouldStyle: false,
            removePadding: true
        }}>
            <Text
            numberOfLines={1}
            style={{
                ...Appearance.textStyles.panelTitle(),
                marginBottom: 6,
                paddingHorizontal: 15
            }}>
                {`Upcoming ${channel.name} Orders`}
            </Text>
            {getContent()}
        </Panel>
    ) : null
}

export const DriverOrdersList = ({ showAssignments, channel }, { utils }) => {

    const panelID = showAssignments ? 'driverOrderAssignmentsList' : 'driverOrdersList';
    const dateRef = useRef(null);

    const [date, setDate] = useState(moment());
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onChooseDate = () => {

        let tempDate = null;

        utils.layer.open({
            id: 'calendar-custom-date',
            Component: LayerShell.bind(this, {
                layerID: 'calendar-custom-date',
                extendedOptions: {
                    bottomCard: true
                },
                buttons: [{
                    key: 'done',
                    text: 'Done',
                    color: 'primary',
                    onPress: () => {
                        utils.events.emit('onLayerAction', {
                            action: 'close',
                            layerID: 'calendar-custom-date'
                        })
                        setDate(tempDate ? moment(tempDate) : date)
                    }
                }],
                children: (
                    <View style={{
                        alignItems: 'center',
                        marginTop: 5
                    }}>
                        <Text style={{
                            ...Appearance.textStyles.panelTitle(),
                            marginBottom: 6
                        }}>{'Browse Other Dates'}</Text>
                        <Text style={{
                            ...Appearance.textStyles.subTitle(),
                            marginBottom: 20,
                            textAlign: 'center'
                    }}>{'Choose a month and year from the list below to view more Orders'}</Text>
                        <DatePicker
                        utils={utils}
                        date={date}
                        onChange={date => tempDate = date}/>
                    </View>
                )
            })
        })
    }

    const onFormatHeader = () => {
        if(moment(date).isSame(moment(), 'day')) {
            return showAssignments ? 'Assignments':`${channel.name} Orders`;
        }
        if(moment(date).isSame(moment().add(1, 'days'), 'day')) {
            return `Tomorrow's ${showAssignments ? 'Assignments':`${channel.name} Orders`}`;
        }
        if(moment(date).isSame(moment().subtract(1, 'days'), 'day')) {
            return `Yesterday's ${showAssignments ? 'Assignments':`${channel.name} Orders`}`;
        }
        return `${showAssignments ? 'Assignments':`${channel.name} Orders`} for ${moment(date).format('MMM Do')}`;
    }

    const onOrderPress = order => {
        utils.layer.order.details(order, getButtons(order))
    }

    const onOrderUpdate = data => {
        try {
            let { current_drop_off_date, original_drop_off_date } = JSON.parse(data);
            if(moment().isSame(moment(current_drop_off_date), 'day') || moment().isSame(moment(original_drop_off_date), 'day')) {
                fetchOrders();
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    const getButtons = order => {

        let buttons = {};
        if([
            StatusCodes.orders.approved,
            StatusCodes.orders.returned,
            StatusCodes.orders.preauthorized,
            StatusCodes.orders.adminUpdated,
            StatusCodes.orders.driverUpdated
        ].includes(order.status.code)) {
            buttons.onStartOrder = onStartOrder;
        }
        if([
            StatusCodes.orders.toPickup,
            StatusCodes.orders.arrivedAtHost,
            StatusCodes.orders.toDestination
        ].includes(order.status.code)) {
            buttons.onResumeOrder = onResumeOrder;
        }
        return buttons;
    }

    const fetchOrders = async () => {
        try {
            await fetchOrdersAsync();
        } catch(e) {
            console.error(e.message);
        }
    }

    const fetchOrdersAsync = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { orders } = await Request.get(utils, '/orders/', {
                    type: 'driver_orders',
                    assignments: showAssignments,
                    date: moment(dateRef.current).format('YYYY-MM-DD'),
                    order_channel: channel.id
                });
                setLoading(false);
                setOrders(prev => {
                    prev.forEach(order => {
                        utils.sockets.off('orders', `on_status_change_${prev.id}`, fetchOrders);
                    });
                    return orders.map(props => {
                        let order = Order.create(props);
                        utils.sockets.on('orders', `on_status_change_${order.id}`, fetchOrders);
                        return order;
                    });
                });
                resolve();

            } catch(e) {
                reject(e);
                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue loading the ${channel.name} order list. ${e.message || 'An unknown error occurred'}`
                });
            }
        })
    }

    useEffect(() => {
        dateRef.current = date;
        fetchOrders();
    }, [date]);

    useEffect(() => {
        utils.sockets.on('orders', 'on_new', fetchOrders);
        utils.sockets.on('orders', 'on_update', onOrderUpdate);
        utils.structure.navigation.subscribe(panelID, { onSearch: text => setSearchText(text.toLowerCase()) });
        utils.content.subscribe(panelID, 'orders', {
            onFetch: fetchOrders,
            onUpdate: abstract => {
                setOrders(orders => {
                    return orders.map(order => abstract.compare(order));
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.structure.navigation.unsubscribe(panelID);
            utils.sockets.off('orders', 'on_new', fetchOrders);
            utils.sockets.off('orders', 'on_update', onOrderUpdate);
            setOrders(prev => {
                prev.forEach(order => {
                    utils.sockets.off('orders', `on_status_change_${prev.id}`, fetchOrders);
                });
                return prev;
            });
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={onFormatHeader()}
        header={(
            <HeaderWithButton
            label={onFormatHeader()}
            button={'refresh'}
            loading={loading}
            onDropDownPress={onChooseDate}
            onAsyncPress={fetchOrdersAsync} />
        )}
        options={{
            removeOverflow: true,
            shouldStyle: false
        }}>

            <View style={Appearance.styles.panel()}>
                {orders.length === 0
                    ?
                    Views.entry({
                        title: 'Nothing to see here',
                        subTitle: `No Orders are ${showAssignments ? 'assigned to you' : 'booked'} for ${moment(date).format('MMM Do')}`,
                        hideIcon: true,
                        bottomBorder: false,
                        propStyles: {
                            subTitle: {
                                numberOfLines: 2
                            }
                        }
                    })
                    :
                    orders.map((order, index) => {
                        if(searchText) {
                            let searchTargets = [
                                order.id,
                                order.customer.full_name,
                                order.host.name,
                                order.origin.name,
                                order.origin.address,
                                order.destination.name,
                                order.destination.address
                            ];
                            if(!searchTargets.find(target => {
                                return target && target.toString().toLowerCase().includes(searchText);
                            })) {
                                return null;
                            }
                        }
                        return (
                            Views.entry({
                                key: index,
                                title: order.customer.full_name,
                                subTitle: `Ordered from ${order.host.name}`,
                                badge: order.status.code === StatusCodes.orders.approved || order.status.code === StatusCodes.orders.returned ? {
                                    text: moment(order.drop_off_date).format('h:mma'),
                                    color: Appearance.colors.grey()
                                } : {
                                    text: order.status.text,
                                    color: order.status.color
                                },
                                icon: {
                                    path: order.customer.avatar
                                },
                                propStyles: {
                                    subTitle: {
                                        numberOfLines: 2
                                    }
                                },
                                bottomBorder: index !== orders.length - 1,
                                onPress: onOrderPress.bind(this, order)
                            })
                        )
                    })
                }
            </View>
        </Panel>
    )
}

// Layers
export const OrderHostDetails = ({ abstract, item, host }, { index, utils, options }) => {

    const layerID = `order-host-details-${host.id}`;
    const [category, setCategory] = useState(null);
    const [categoryOptions, setCategoryOptions] = useState(null);
    const [featuredOptions, setFeaturedOptions] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState(null);
    const [scrollOffset, setScrollOffset] = useState(0);
    const [searchText, setSearchText] = useState(null);
    const [subTotalLayer, setSubTotalLayer] = useState(null);

    const fetchOrderCategories = async () => {
        try {
            let categories = await host.getCategories(utils);
            setFeaturedOptions(categories.reduce((array, category) => {
                return array.concat(category.options.filter(option => option.featured));
            }, []))
            setCategoryOptions(categories.reduce((array, category) => {
                return array.concat(category.options);
            }, []));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the categories and options for ${host.name}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getCategoryInfo = () => {
        let items = categoryOptions.filter(option => category ? category.id === option.category.id : true);
        return `${items.length} ${items.length === 1 ? 'item' : 'items'} available`;
    }

    const getOrderCostSummary = () => {
        let cost = order.options.reduce((total, option) => total += option.getCustomizedCost(), 0);
        return `${order.options.length} ${order.options.length == 1 ? 'Item' : 'Items'} for ${Utils.toCurrency(cost)}`
    }

    const onBookOrder = async (_abstract) => {
        return new Promise(async (resolve, reject) => {
            try {

                // Check that order falls within operating hours for host
                await host.verifyBookingDate(order.drop_off_date);

                // Submit order and update subscribers
                await _abstract.object.submit(utils);
                utils.content.fetch('orders');
                resolve();

                try {
                    // Update Address Book
                    await utils.user.get().addToAddressBook(utils, {
                        name: _abstract.object.destination.name,
                        address: _abstract.object.destination.address,
                        location: {
                            lat: _abstract.object.destination.location.latitude,
                            long: _abstract.object.destination.location.longitude
                        }
                    });
                } catch(e) {
                    // dont report error
                }

                // Show on-Demand searching if applicable
                if(_abstract.object.isOnDemandOrder()) {
                    utils.on_demand.searching.set({ abstract: _abstract })
                    return;
                }

                utils.alert.show({
                    title: 'All Done!',
                    message: `Your ${abstract.object.channel.name} Order from ${_abstract.object.host.name} for ${Utils.formatDate(_abstract.object.drop_off_date)} has been submitted. We'll let you know if there are any issues with your drop-off time or date.`
                });

            } catch(e) {
                reject(e);
            }
        })
    }

    const onConfirmOrder = () => {

        if(order.options.length === 0) {
            utils.alert.show({
                title: 'Oops!',
                message: `Please add at least one item before confirming your ${order.channel.name} Order`
            });
            return
        }

        abstract.object.close();
        utils.layer.order.details(abstract.object, {
            onBookOrder: () => {
                // wrap onBookOrder promise so this layer can be closed on completion
                return new Promise(async (resolve, reject) => {
                    try {
                        await onBookOrder(abstract);
                        resolve();
                        setTimeout(() => {
                            setLayerState('close');
                        }, 1000);

                    } catch(e) {
                        reject(e);
                    }
                })
            }
        });
    }

    const onCloseAttempt = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                if(order.options.length === 0) {
                    resolve();
                    return;
                }
                utils.alert.show({
                    title: 'Just a Second',
                    message: `It looks like you have started a ${host.channel.name} Order with ${host.name}. Do you want to continue with this Order?`,
                    buttons: [{
                        key: 'confirm',
                        title: 'Continue',
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'Cancel Order',
                        style: 'cancel'
                    }],
                    onPress: (key) => {
                        if(key === 'confirm') {
                            reject(new Error('User cancelled request'));
                            return;
                        }
                        resolve();
                    }
                })
            } catch(e) {
                reject(e);
            }
        })
    }

    const onViewOrder = () => {
        utils.layer.open({
            id: 'view-order',
            abstract: abstract,
            Component: OrderCartDetails.bind(this, {
                isNewTarget: abstract.object.id ? false : true,
                onConfirmOrder: () => setTimeout(onConfirmOrder, 500),
                onRemoveItem: item => {
                    let edits = abstract.object.set({
                        options: order.options.filter(option => option.id !== item.id)
                    });
                    setOrder(edits);
                },
                onUpdateItem: item => {
                    let index = order.options.findIndex(opt => opt.copy_id === item.copy_id);
                    if(index < 0) {
                        return;
                    }
                    let edits = abstract.object.set({
                        options: update(order.options, {
                            [index]: {
                                $set: item
                            }
                        })
                    });
                    setOrder(edits);
                }
            })
        })
    }

    const onItemPress = option => {
        utils.layer.open({
            id: `order-${host.channel.id}-option-${option.copy_id}`,
            abstract: Abstract.create({
                type: 'orderOptions',
                object: option
            }),
            Component: OrderOptionDetails.bind(this, {
                channel: host.channel,
                isEditing: false,
                onAddToOrder: next => {
                    let edits = abstract.object.set({
                        options: update(abstract.object.edits.options, {
                            $push: [next]
                        })
                    });
                    setOrder(edits);
                }
            })
        })
    }

    const getButtons = () => {
        if(!order || !order.options || order.options.length === 0) {
            return null;
        }
        return [{
            key: 'view',
            text: 'View My Order',
            color: 'grey',
            onPress: onViewOrder
        },{
            key: 'continue',
            text: 'Continue to Delivery Options',
            color: 'primary',
            onPress: onConfirmOrder
        }]
    }

    const getCarouselItemStyles = () => {
        return {
            paddingTop: 8,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 20
        }
    }

    const getCarouselProps = target => {
        return {
            sliderWidth: Screen.layer.maxWidth,
            itemWidth: Screen.layer.maxWidth - 60,
            ...Platform.OS !== 'web' && {
                layoutCardOffset: 0,
                loop: target.length > 1
            },
            ...(Platform.OS === 'web' || Utils.isMobile() === false) && {
                itemWidth: Screen.layer.maxWidth - 80,
            }
        }
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setOrder(edits);
    }

    useEffect(() => {
        setupTarget();
        fetchOrderCategories();
    }, []);

    useEffect(() => {
        if(item) {
            setTimeout(onItemPress.bind(this, item), 750);
        }
    }, [item]);

    return (
        <Layer
        id={layerID}
        title={host.name}
        utils={utils}
        index={index}
        buttons={getButtons()}
        options={{
            ...options,
            layerState: layerState,
            removePadding: true,
            onCloseAttempt: onCloseAttempt,
            stackButtons: true
        }}>
            <View style={{
                width: '100%',
                position: 'relative',
                alignItems: 'center'
            }}>
                <Image
                source={host.cover_image}
                style={{
                    width: '100%',
                    height: Platform.OS === 'web' || Utils.isMobile() === false ? 400 : 300,
                    resizeMode: 'cover',
                    borderTopLeftRadius: 15,
                    borderTopRightRadius: 15,
                    overflow: 'hidden'
                }}/>
                <View style={{
                    position: 'absolute',
                    top: (Platform.OS === 'web' || Utils.isMobile() === false ? 400 : 300) - 62.5,
                    width: 125,
                    height: 125,
                    ...Appearance.boxShadow({
                        radius: 10,
                        opacity: 0.25,
                        color: 'rgba(150,150,150)',
                        offset: {
                            width: 4,
                            height: 4
                        }
                    })
                }}>
                    <View style={{
                        width: 125,
                        height: 125,
                        borderRadius: 62.5,
                        overflow: 'hidden',
                        borderWidth: 3,
                        borderColor: 'white'
                    }}>
                        <Image
                        source={host.image}
                        style={{
                            width: '100%',
                            height: '100%',
                            resizeMode: 'cover'
                        }}/>
                    </View>
                </View>

                <View style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingHorizontal: 15,
                    marginTop: 62.5 + 20, // push text below absolutely position logo,
                    marginBottom: 20
                }}>
                    <Text style={{
                        ...Appearance.textStyles.header(),
                        marginBottom: 4,
                        textAlign: 'center'
                    }}>{host.name}</Text>
                    <Text style={{
                        ...Appearance.textStyles.subTitle(),
                        marginBottom: 8,
                        textAlign: 'center'
                    }}>{host.description}</Text>
                    <AltBadge content={host.getHostOperatingHours()}/>
                </View>
            </View>

            {/* featured options and category options */}
            {categoryOptions && categoryOptions.length > 0
                ?
                <View style={{
                    width: '100%',
                    paddingBottom: Screen.safeArea.bottom
                }}>
                    {/* featured options */}
                    {categoryOptions.filter(item => item.featured).length > 0 && (
                        <>
                        <Carousel
                        data={featuredOptions}
                        enableSnap={true}
                        activeSlideAlignment={'center'}
                        {...getCarouselProps(featuredOptions)}
                        onScrollIndexChanged={index => setScrollOffset(index)}
                        renderItem={({ item, index }) => {
                            return (
                                <View
                                key={item.id}
                                style={getCarouselItemStyles()}>
                                    <View style={{
                                        ...Appearance.styles.panel(),
                                        height: Platform.OS === 'web' || Utils.isMobile() === false ? 400 : 300,
                                        width: '100%',
                                        flexDirection: 'column'
                                    }}>
                                        <TouchableOpacity
                                        activeOpacity={0.6}
                                        onPress={onItemPress.bind(this, item)}
                                        style={{
                                            flex: 1,
                                            flexGrow: 1,
                                            width: '100%',
                                            borderBottomWidth: 1,
                                            borderBottomColor: Appearance.colors.divider(),
                                            borderTopLeftRadius: 8,
                                            borderTopRightRadius: 8,
                                            overflow: 'hidden'
                                        }}>
                                            <Image
                                            source={item.images[0]}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                resizeMode: 'cover',
                                                backgroundColor: Appearance.colors.grey()
                                            }}/>
                                        </TouchableOpacity>

                                        <TouchableOpacity
                                        activeOpacity={0.6}
                                        onPress={onItemPress.bind(this, item)}
                                        style={{
                                            padding: 12
                                        }}>
                                            <Text style={{
                                                ...Appearance.textStyles.title()
                                            }}>
                                                {item.name}
                                            </Text>
                                            <Text
                                            numberOfLines={2}
                                            style={{
                                                ...Appearance.textStyles.subTitle(),
                                                marginTop: 2
                                            }}>
                                                {item.description}
                                            </Text>
                                            <View style={{
                                                flexDirection: 'row',
                                                width: '100%',
                                                alignItems: 'center',
                                                marginTop: 8
                                            }}>
                                                <AltBadge content={{
                                                    text: 'Featured',
                                                    color: Appearance.colors.primary()
                                                }} style={{
                                                    marginRight: 8
                                                }} />
                                                <AltBadge content={{
                                                    text: `${item.options.length > 0 ? 'Starting at ' : ''}${Utils.toCurrency(item.cost)}`,
                                                    color: Appearance.colors.grey()
                                                }} style={{
                                                    marginRight: 8
                                                }} />
                                            </View>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            )
                        }}/>
                        {featuredOptions.length > 1 && (
                            <View style={{
                                flexDirection: 'row',
                                justifyContent: 'center',
                                width: '100%',
                                marginBottom: 5
                            }}>
                                {featuredOptions.map((_, index) => {
                                    return (
                                        <View
                                        key={index}
                                        style={{
                                            width: 15,
                                            height: 3,
                                            borderRadius: 1.5,
                                            backgroundColor: scrollOffset === index ? Appearance.colors.primary() : Appearance.colors.grey(),
                                            marginHorizontal: 4
                                        }}/>
                                    )
                                })}
                            </View>
                        )}
                        </>
                    )}

                    {/* category options */}
                    <View style={{
                        padding: 15,
                        marginTop: 3
                    }}>
                        <View style={{
                            flexDirection: 'row',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: 12
                        }}>
                            <View style={{
                                flexDirection: 'column'
                            }}>
                                <Text
                                numberOfLines={1}
                                style={Appearance.textStyles.header()}>{
                                    category ? category.title : 'All Items'
                                }</Text>
                                <Text numberOfLines={1}
                                style={Appearance.textStyles.subTitle()}>{getCategoryInfo()}</Text>
                            </View>
                            <DropdownMenu
                            utils={utils}
                            items={host.categories ? [{
                                key: 'all',
                                title: 'All Items'
                            }].concat(host.categories.map(category => {
                                return {
                                    key: category.id,
                                    title: category.title
                                }
                            })) : []}
                            label={'Filter'}
                            onChange={item => setCategory(host.categories.find(c => c.id == item.key))}
                            style={{
                                alignItems: 'flex-start'
                            }}/>
                        </View>

                        {/* options list */}
                        {categoryOptions.filter(o => {
                            return searchText ? (o.title.toLowerCase().includes(searchText) || o.description.toLowerCase().includes(searchText)) : true;
                        }).map((item, index, items) => {

                            if(category && item.category.id !== category.id) {
                                return null;
                            }

                            return (
                                <View
                                key={item.id}
                                style={{
                                    ...Appearance.styles.panel(),
                                    marginBottom: 12
                                }}>
                                    <TouchableOpacity
                                    activeOpacity={0.6}
                                    onPress={onItemPress.bind(this, item)}
                                    style={{
                                        padding: 12
                                    }}>
                                        <View style={{
                                            flexDirection: 'row',
                                            width: '100%',
                                            alignItems: 'center'
                                        }}>
                                            <View style={{
                                                flex: 1,
                                                flexGrow: 1,
                                                paddingRight: 12
                                            }}>
                                                <Text style={{
                                                    ...Appearance.textStyles.title()
                                                }}>
                                                    {item.name}
                                                </Text>
                                                <Text
                                                numberOfLines={2}
                                                style={{
                                                    ...Appearance.textStyles.subTitle(),
                                                    marginTop: 2
                                                }}>
                                                    {item.description}
                                                </Text>
                                                <View style={{
                                                    flexDirection: 'row',
                                                    width: '100%',
                                                    alignItems: 'center',
                                                    marginTop: 8
                                                }}>
                                                    <AltBadge content={{
                                                        text: `${item.options.length > 0 ? 'Starting at ' : ''}${Utils.toCurrency(item.cost)}`,
                                                        color: Appearance.colors.grey()
                                                    }} style={{
                                                        marginRight: 8
                                                    }} />
                                                </View>
                                            </View>
                                            <View style={{
                                                width: 50,
                                                height: 50,
                                                borderRadius: 8,
                                                overflow: 'hidden',
                                                borderWidth: 1,
                                                borderColor: Appearance.colors.divider()
                                            }}>
                                                <Image
                                                source={item.images[0]}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    resizeMode: 'cover',
                                                    backgroundColor: Appearance.colors.grey()
                                                }}/>
                                            </View>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                            )
                        })}
                    </View>
                </View>
                :
                null
            }

        </Layer>
    )
}

export const OrderCartDetails = ({ isNewTarget, onConfirmOrder, onRemoveItem, onUpdateItem }, { abstract, index, options, utils }) => {

    const layerID = 'view-order';
    const [items, setItems] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [runningTotal, setRunningTotal] = useState(0);
    const [paymentLineItems, setPaymentLineItems] = useState(null);

    const onConfirmOrderPress = () => {
        setLayerState('close');
        if(typeof(onConfirmOrder) === 'function') {
            onConfirmOrder();
        }
    }

    const onItemPress = item => {
        if(!isNewTarget) {
            utils.alert.show({
                title: item.name,
                message: item.description
            })
            return;
        }

        let { copy_id, description, name } = item;
        utils.sheet.show({
            title: name,
            message: description,
            items: [{
                key: 'change',
                title: 'Change',
                style: 'default'
            },{
                key: 'remove',
                title: 'Remove',
                style: 'destructive'
            }]
        }, (key) => {
            if(key === 'remove') {
                if(typeof(onRemoveItem) === 'function') {
                    onRemoveItem(item);
                }
                let next = items.filter(item => item.copy_id !== copy_id);
                setItems(next);
                if(next.length === 0) {
                    setLayerState('close');
                }
                return;
            }
            if(key === 'change') {
                utils.layer.open({
                    id: `order-${abstract.object.host.channel.id}-option-${copy_id}`,
                    abstract: Abstract.create({
                        type: 'orderOptions',
                        object: item
                    }),
                    Component: OrderOptionDetails.bind(this, {
                        channel: abstract.object.channel,
                        isEditing: true,
                        onUpdateOrder: next => {
                            setItems(items => {
                                return items.map(item => {
                                    return item.copy_id === copy_id ? next : item;
                                });
                            });
                            if(typeof(onUpdateItem) === 'function') {
                                onUpdateItem(next);
                            }
                        }
                    })
                })
                return;
            }
        })
    }

    const getButtons = () => {
        if(!isNewTarget) {
            return null;
        }
        return [{
            key: 'confirm',
            text: `Continue to Delivery Options`,
            color: 'primary',
            onPress: onConfirmOrderPress
        }];
    }

    const getHostOperatingHours = () => {
        let { text } = abstract.object.host.getHostOperatingHours();
        return text || 'Operating hours not available';
    }

    const fetchCostBreakdown = async () => {
        try {
            let { breakdown } = await abstract.object.costBreakdown(utils);
            setRunningTotal(breakdown.total);
            setPaymentLineItems(breakdown ? breakdown.line_items : []);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue calculating your cost estimate. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchCostBreakdown();
        setItems(isNewTarget ? abstract.object.edits.options : abstract.object.options);
    }, []);

    return (
        <Layer
        id={layerID}
        title={'My Order'}
        utils={utils}
        index={index}
        options={{
            ...options,
            layerState: layerState,
        }}
        buttons={getButtons()}>

            {paymentLineItems
                ?
                <View style={{
                    ...Appearance.styles.panel(),
                    marginTop: 45,
                    marginBottom: 12
                }}>
                    <View style={{
                        borderBottomColor: Appearance.colors.divider(),
                        borderBottomWidth: 1
                    }}>
                        {Views.entry({
                            title: abstract.object.host.name,
                            subTitle: getHostOperatingHours(),
                            icon: {
                                path: abstract.object.host.image
                            },
                            bottomBorder: false
                        })}
                    </View>
                    {paymentLineItems.map((item, index, items) => {
                        return (
                            <View
                            key={index}
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '100%',
                                paddingHorizontal: 12,
                                paddingVertical: 8,
                                borderBottomWidth: 1,
                                borderBottomColor: Appearance.colors.divider()
                            }}>
                                <Text style={Appearance.textStyles.key()}>{item.title}</Text>
                                <Text style={Appearance.textStyles.value()}>{item.message}</Text>
                            </View>
                        )
                    })}
                </View>
                :
                <View style={{
                    ...Appearance.styles.panel(),
                    marginTop: 20,
                    marginBottom: 12,
                    padding: 20,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={Appearance.themeStyle() === 'dark' ? require('eCarra/files/lottie/dots-white.json') : require('eCarra/files/lottie/dots-grey.json')}
                    duration={2500}
                    style={{
                        width: 50
                    }}/>
                </View>
            }

            {items.map((item, index) => {
                let cust = item.getCustomizations();
                return (
                    <TouchableOpacity
                    key={index}
                    activeOpacity={0.6}
                    onPress={onItemPress.bind(this, item)}
                    style={{
                        ...Appearance.styles.panel(),
                        marginBottom: 12
                    }}>
                        {Views.entry({
                            title: item.name,
                            subTitle: item.description,
                            badge: {
                                text: Utils.toCurrency(item.getCustomizedCost()),
                                color: Appearance.colors.primary()
                            },
                            icon: {
                                path: item.images[0]
                            },
                            propStyles: {
                                subTitle: {
                                    numberOfLines: 2
                                }
                            },
                            useTouchable: false,
                            bottomBorder: cust.length > 0
                        })}
                        {cust.map((c, index) => {
                            return (
                                <View
                                key={index}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    borderBottomWidth: index !== cust.length - 1 ? 1 : 0,
                                    borderBottomColor: Appearance.colors.divider(),
                                    paddingHorizontal: 12,
                                    paddingVertical: 10
                                }}>
                                    <View style={{
                                        flex: 1,
                                        flexGrow: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingRight: 12
                                    }}>
                                        <Text style={{
                                            ...Appearance.textStyles.subTitle(),
                                            color: Appearance.colors.text(),
                                            marginBottom: 2
                                        }}>{c.name}</Text>
                                        {typeof(c.title) === 'string' && (
                                            <Text
                                            numberOfLines={1}
                                            style={Appearance.textStyles.subTitle()}>{c.title}</Text>
                                        )}
                                        {typeof(c.value) === 'string' && (
                                            <Text
                                            numberOfLines={1}
                                            style={Appearance.textStyles.subTitle()}>{c.value}</Text>
                                        )}
                                    </View>
                                    {c.cost > 0 && (
                                        <AltBadge content={{
                                            text: `Add ${Utils.toCurrency(c.cost)}`,
                                            color: Appearance.colors.grey()
                                        }}/>
                                    )}
                                </View>
                            )
                        })}
                        {typeof(item.requests) === 'string' && (
                            <View style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                borderTopWidth: 1,
                                borderTopColor: Appearance.colors.divider(),
                                paddingHorizontal: 12,
                                paddingVertical: 10,
                                width: '100%'
                            }}>
                                <Text style={{
                                    ...Appearance.textStyles.subTitle(),
                                    color: Appearance.colors.text(),
                                    marginBottom: 2
                                }}>{'Special Requests'}</Text>
                                <Text style={Appearance.textStyles.subTitle()}>{item.requests}</Text>
                            </View>
                        )}
                    </TouchableOpacity>
                )
            })}

        </Layer>
    )
}

export const OrderDetails = ({ channel, isNewTarget, onClose, onBookOrder, onStartOrder, onResumeOrder }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? `new-order-${channel.id}` : `order-${abstract.getID()}-${channel.id}`;
    const [annotations, setAnnotations] = useState([]);
    const [breakdownWarnings, setBreakdownWarnings] = useState([]);
    const [driver, setDriver] = useState(abstract.object.driver);
    const [lineItems, setLineItems] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingCancellation, setLoadingCancellation] = useState(false);
    const [manager, setManager, setStatus] = useRequestManager([ 'line_items', 'routing' ]);
    const [modularContent, setModularContent] = useState(null);
    const [order, setOrder] = useState(null);
    const [overlays, setOverlays] = useState([]);
    const [paymentLineItems, setPaymentLineItems] = useState([]);
    const [runningTotal, setRunningTotal] = useState(0);

    const onCancelOrder = () => {

        const submitCancellation = async approveFees => {
            try {
                setLoadingCancellation(true);
                let { confirmation } = await Request.post(utils, '/order/', {
                    type: 'cancel',
                    approve_fees: approveFees || false,
                    order_id: abstract.getID(),
                    order_channel: channel.id
                });

                if(confirmation) {
                    utils.alert.show({
                        title: 'Are You Sure?',
                        message: confirmation,
                        buttons: [{
                            key: 'confirm',
                            title: `Yes`,
                            style: 'destructive'
                        },{
                            key: 'cancel',
                            title: 'Do Not Cancel',
                            style: 'default'
                        }],
                        onPress: (key) => {
                            if(key === 'confirm') {
                                submitCancellation(true);
                            }
                        }
                    })
                    return;
                }

                utils.alert.show({
                    title: 'All Done!',
                    message: `Your ${channel.name} Order for ${Utils.formatDate(abstract.object.drop_off_date)} has been cancelled. Would you mind taking a second to let us know why you cancelled your order?`,
                    buttons: [{
                        key: 'confirm',
                        title: `Let's Talk About It`,
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'Not Right Now',
                        style: 'destructive'
                    }],
                    onPress: (key) => {

                        setLayerState('close');
                        utils.content.fetch('orders');
                        if(key === 'confirm') {
                            utils.quickFeedback.show();
                        }
                    }
                })
            } catch(e) {
                setLoadingCancellation(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue cancelling your ${channel.name} Order. ${e.message || 'An unknown error occurred'}`
                })
            }
        }

        utils.alert.show({
            title: `Cancel Order`,
            message: `Are you sure that you want to cancel this ${channel.name} Order?`,
            buttons: [{
                key: 'confirm',
                title: 'Cancel',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Cancel',
                style: 'default'
            }],
            onPress: (key) => {
                if(key === 'confirm') {
                    submitCancellation();
                }
            }
        })
    }

    const onItemPress = item => {

        if(!item.on_edit) {
            return;
        }
        if(checkBreakdownWarnings(item) === false) {
            showEditOptions(item);
        }
    }

    const onItemUpdate = async (item, content) => {

        // prevent moving forward if no changes were submitted
        if(!content) {
            return;
        }

        // update local line item values
        setLineItems(lineItems => update(lineItems, {
            $apply: items => items.map(section => {
                section.items = section.items.map(i => {
                    i.loading = i.key === item.key;
                    return i;
                })
                return section;
            })
        }));

        // apply edits
        let edits = abstract.object.set(content);
        setOrder(edits);

        // fetch line items if target has not been submitted
        if(isNewTarget === true) {
            fetchLineItems();
            return;
        }

        // apply chanages to target and fetch line items
        try {
            await abstract.object.update(utils);
            utils.content.update(abstract);
            fetchLineItems();

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating "${item.title}". ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onLocationUpdate = data => {
        try {
            let { order } = JSON.parse(data);
            let { location } = order.driver;
            if(location.polyline) {
                let coordinates = Utils.decodePolyline(location.polyline)
                setOverlays([{
                    key: 'driver_route',
                    coordinates: coordinates.map(c => [ c[1], c[0] ])
                }])
            }

            setDriver(driver => update(driver, {
                heading: {
                    $set: location.heading
                },
                time_to_arrival: {
                    $set: location.time_to_arrival
                },
                location: {
                    $set: {
                        latitude: location.lat,
                        longitude: location.long
                    }
                }
            }))

        } catch(e) {
            console.error(e.message)
        }
    }

    const onMessageDriver = async () => {
        try {
            let { thread } = await Request.get(utils, '/messages/', {
                type: 'thread_for_target',
                target_id: abstract.getID(),
                target_type: abstract.type
            });
            if(!thread) {
                utils.alert.show({
                    title: 'Awaiting Driver',
                    message: `It looks like we have not assigned a driver to your order yet. Most rides and orders will have a driver assigned within a few hours of the pickup date`
                })
                return;
            }
            utils.layer.openFloating({
                id: `messages-${abstract.getTag()}`,
                abstract: abstract,
                Component: Messaging
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your messages. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onOrderWithApplePay = async () => {
        try {

            // Create apple pay token
            let supported = await stripe.deviceSupportsNativePay();
            if(!supported) {
                utils.alert.show({
                    title: 'Oops!',
                    message: `It looks like Apple Pay is not supported on your device. Please check that one have at least one card added in your Wallet app.`
                });
                return;
            }
            const token = await stripe.paymentRequestWithNativePay({ }, [{
                label: order.service.name,
                amount: runningTotal.toString(),
            }]);

            // Create stripe method
            let { card } = await Request.post(utils, '/payment/', {
                type: 'add_apple_pay_method',
                user_id: utils.user.get().user_id,
                stripe_id: utils.user.get().stripe_customer_id,
                token: token
            });
            if(!card) {
                throw new Error('An unknown error occurred');
                return;
            }

            onItemUpdate({
                key: 'payment_method',
                title: 'Payment Method'
            }, {
                payment_method: PaymentMethod.create(card)
            });

            await stripe.completeNativePayRequest();
            onSubmit();

        } catch(e) {
            await stripe.cancelNativePayRequest();
            if(e.message.toString() === 'Cancelled by user') {
                return;
            }
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue booking your Order with Apple Pay. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSetStatus = status => {
        utils.alert.show({
            title: `${status === StatusCodes.orders.approved ? 'Approve' : 'Decline'} Order`,
            message: `Are you sure that you want to ${status === StatusCodes.orders.approved ? 'approve' : 'decline'} this ${channel.name} Order? ${abstract.object.customer.full_name} will be notified with your decision`,
            buttons: [{
                key: 'confirm',
                title: status === StatusCodes.orders.approved ? 'Approve' : 'Decline',
                style: status === StatusCodes.orders.approved ? 'default' : 'destructive'
            },{
                key: 'cancel',
                title: status === StatusCodes.orders.approved ? 'Do Not Approve' : 'Do Not Decline',
                style: status === StatusCodes.orders.approved ? 'destructive' : 'default'
            }],
            onPress: async key => {
                if(key !== 'confirm') {
                    return;
                }

                try {
                    setLoading(status === StatusCodes.orders.approved ? 'approve' : 'decline');
                    let response = await Request.post(utils, '/order/', {
                        type: 'set_status',
                        status: status,
                        channel_id: channel.id,
                        order_id: abstract.getID()
                    })

                    setLoading(false);
                    abstract.object.status = Order.getStatus(response.status);
                    utils.content.update(abstract);

                    utils.alert.show({
                        title: 'All Done!',
                        message: `This ${channel.name} Order has been ${response.status === StatusCodes.orders.approved ? 'approved' : 'declined'}`,
                        onPress: () => {
                            if(status === StatusCodes.orders.rejected) {
                                setLayerState('close');
                            }
                        }
                    })

                } catch(e) {
                    setLoading(false);
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue ${status === StatusCodes.orders.approved ? 'approving' : 'declining'} this ${channel.name} Order. ${e.message || 'An unknown error occurred'}`
                    })
                }
            }
        })
    }

    const onSubmit = async () => {

        // Warnings and required field validation
        if(checkBreakdownWarnings()) {
            return;
        }

        let requirements = lineItems.reduce((array, lineItem) => {
            let items = lineItem.items.filter(item => {
                return item.required && (item.value === null || item.value === undefined);
            });
            if(items) {
                array = array.concat(items);
            }
            return array;
        }, [])

        if(requirements.length > 0) {
            utils.alert.show({
                title: requirements[0].title,
                message: `Please fill out the ${requirements[0].title.toLowerCase()} before submitting your Order. ${requirements[0].on_edit.description}`,
                buttons: [{
                    key: 'change',
                    title: 'Change',
                    style: 'default'
                },{
                    key: 'dismiss',
                    title: 'Dismiss',
                    style: 'cancel'
                }],
                onPress: (key) => {
                    if(key === 'change') {
                        showEditOptions(requirements[0]);
                    }
                }
            });
            return;
        }

        try {
            setLoading(true);
            await onBookOrder(abstract);
            setLayerState('close');

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting your Order. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUpdateMapElements = async () => {
        try {
            // prevent moving forward if no manager props are present or changes are not pending
            if(!order || !manager.routing || manager.routing.pending === false) {
                return;
            }

            // set overlays from pre-calculated polylines
            setOverlays(abstract.object.getOverlays());

            // create annotations from order locations
            setAnnotations(annotations => update(annotations, {
                $apply: () => {
                    let annotations = [{
                        id: 'pickup',
                        title: `Pickup Location (${order.id})`,
                        subTitle: order.origin.address,
                        location: order.origin.location,
                        data: {
                            key: 'pickup',
                            order: order
                        }
                    },{
                        id: 'dropoff',
                        title: `Drop-Off Location (${order.id})`,
                        subTitle: order.destination.address,
                        location: order.destination.location,
                        data: {
                            key: 'drop-off',
                            order: order
                        }
                    }];
                    if(!order.stops || order.stops.locations.length === 0) {
                        return annotations;
                    }
                    return annotations.concat(order.stops.locations.map((stop, index) => {
                        return {
                            id: `stop-${index}`,
                            title: `${Utils.integerToOrdinal(index + 1)} Stop (${order.id})`,
                            subTitle: stop.address,
                            location: stop.location,
                            icon: { type: 'grey-broadcast' },
                            data: {
                                key: `stop-${index}`,
                                order: order
                            }
                        }
                    }))
                }
            }));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue calculating the route for this ${channel.name} order. ${e.message || 'An unknown error occurred'}`,
                onPress: () => setLayerState('close')
            })
        }
    }

    const onUpdateOrder = () => {

        if(order.options.length === 0) {
            utils.alert.show({
                title: 'Oops!',
                message: `Please add at least one item before updating your ${order.channel.name} Order`
            });
            return
        }

        abstract.object.edits = order;
        abstract.object.close();
        utils.layer.order.details(abstract.object, {
            onUpdateOrder: async () => {
                try {
                    await abstract.object.update(utils);
                    utils.alert.show({
                        title: 'All Done!',
                        message: `Your ${order.channel.name} Order has been updated. We'll reach out if we find any issues with your changes`
                    });

                } catch(e) {
                    reject(e);
                }
            }
        });
    }

    const onUpdateOtherUsers = async users => {

        let prevUsers = order.other_users;
        try {
            abstract.object.set({ other_users: users });
            setOrder({ ... abstract.object.edits });
            if(!isNewTarget) {
                await abstract.object.update(utils);
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the friends and family for this ${channel.name} Order. ${e.message || 'An unknown error occurred'}`,
                onPress: () => {
                    // Revert to previous users list
                    abstract.object.set({ other_users: prevUsers });
                    setOrder({ ... abstract.object.edits });
                }
            });
        }
    }

    const onUpdatePaymentMethod = () => {
        utils.alert.show({
            title: 'Unable to Process Order',
            message: `There was an issue with your payment method for this ${channel.name} Order. Please update your card to continue booking Orders`,
            buttons: [{
                key: 'update',
                title: 'Update Payment Method',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: 'cancel'
            }],
            onPress: (key) => {
                if(key === 'update') {

                    let tempCard = null;
                    let layerID = `edit-payment-method`;

                    utils.layer.open({
                        id: layerID,
                        Component: LayerShell.bind(this, {
                            layerID: layerID,
                            title: 'Payment Method',
                            extendedOptions: {
                                bottomCard: true
                            },
                            buttons: [{
                                key: 'confirm',
                                text: 'Update',
                                color: 'primary',
                                onPress: async () => {
                                    if(!tempCard) {
                                        utils.alert.show({
                                            title: 'Oops!',
                                            message: 'Please choose a payment method to continue'
                                        });
                                        return
                                    }
                                    utils.events.emit('onLayerAction', {
                                        action: 'close',
                                        layerID: layerID
                                    });

                                    try {
                                        let { status } = await Request.post(utils, '/payment/', {
                                            type: 'update_payment_method',
                                            id: abstract.getID(),
                                            target_type: abstract.type,
                                            card_id: tempCard.card_id
                                        })

                                        abstract.object.status = Order.getStatus(status);
                                        abstract.object.requests = {
                                            ...abstract.object.requests,
                                            card_id: tempCard.card_id
                                        }
                                        utils.content.update(abstract);
                                        utils.alert.show({
                                            title: 'All Done!',
                                            message: 'Thank you for updating your payment method',
                                            onPress: () => setLayerState('close')
                                        })

                                    } catch(e) {
                                        utils.alert.show({
                                            title: 'Oops!',
                                            message: `There was an issue updating your payment method. ${e.message || 'An unknown error occurred'}`
                                        })
                                    }
                                }
                            }],
                            children: (
                                <View style={{
                                    alignItems: 'center',
                                    marginTop: 5
                                }}>
                                    <Text style={{
                                        ...Appearance.textStyles.panelTitle(),
                                        marginBottom: 6
                                    }}>{'Payment Method'}</Text>
                                    <Text style={{
                                        ...Appearance.textStyles.subTitle(),
                                        marginBottom: 20,
                                        textAlign: 'center'
                                    }}>{`We were unable to process the payment method on file for this ${channel.name} Order. Please choose another card to continue booking Orders`}</Text>
                                    <PaymentMethodManager
                                    utils={utils}
                                    onChange={method => tempCard = method}/>
                                </View>
                            )
                        })
                    })
                }
            }
        });
    }

    const onUserPress = user => {

        let owner = abstract.object.edits ? abstract.object.edits.customer : abstract.object.customer;
        utils.sheet.show({
            title: user.full_name,
            message: `How would you like to contact ${user.first_name}?`,
            items: [{
                key: 'call',
                title: 'Call',
                style: 'default'
            },{
                key: 'text',
                title: 'Text Message',
                style: 'default'
            },{
                key: 'message',
                title: 'In App Message',
                style: 'default'
            },{
                key: 'remove',
                title: `Remove from Ride`,
                style: 'destructive',
                visible: user.user_id !== owner && owner === utils.user.get().user_id
            }]
        }, async key => {

            if(key === 'call') {
                try {
                    await Call(user.phone_number, true);
                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue setting up your call. ${e.message || 'An unknown error occurred'}`
                    })
                }

            } else if(key === 'text') {
                try {
                    await TextMessage(user.phone_number);
                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue setting up your text message. ${e.message || 'An unknown error occurred'}`
                    })
                }

            } else if(key === 'message') {
                setLayerState('close');
                setTimeout(() => {
                    utils.layer.messaging(abstract)
                }, 1000);

            } else if(key === 'remove') {

                let prevUsers = order.other_users;
                try {
                    abstract.object.set({
                        other_users: update(prevUsers, {
                            $apply: users => users.filter(u => u.user_id !== user.user_id)
                        })
                    });
                    setOrder({ ... abstract.object.edits });
                    await abstract.object.update(utils);

                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue removing ${user.first_name} from your ${channel.name} Order. ${e.message || 'An unknown error occurred'}`,
                        onPress: () => {
                            // Revert to previous users list
                            abstract.object.set({ other_users: prevUsers });
                            setOrder({ ... abstract.object.edits });
                        }
                    });
                }
            }
        })
    }

    const canEditOrder = () => {
        if(order.customer.user_id === utils.user.get().user_id) {
            return true;
        }
        return order.other_users ? order.other_users.find(user => user.user_id === utils.user.get().user_id) : false;
    }

    const canShowTripNavigator = () => {
        if(abstract.object && abstract.object.customer) {
            let isOtherUser = abstract.object.other_users ? abstract.object.other_users.find(user => user.user_id == utils.user.get().user_id) : false;
            if(isOtherUser || abstract.object.customer.user_id === utils.user.get().user_id) {
                return true;
            }
        }
        return false;
    }

    const checkBreakdownWarnings = item => {
        let warning = breakdownWarnings.find(warning => {
            return (item ? warning.key === item.key : true) && warning.messages.length > 0
        });
        if(warning) {
            utils.alert.show({
                title: warning.title,
                message: warning.messages[0],
                buttons: [{
                    key: 'change',
                    title: 'Change',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Dismiss',
                    style: 'cancel'
                }],
                onPress: (key) => {
                    if(key === 'change') {

                        if(item) {
                            showEditOptions(item);
                            return
                        }

                        for(var i in lineItems){
                            for(var ii in lineItems[i].items) {
                                if(lineItems[i].items[ii].key === warning.key) {
                                    showEditOptions(lineItems[i].items[ii]);
                                }
                            }
                        }
                    }
                }
            })
            return true;
        }
        return false;
    }

    const isOtherUser = () => {
        try {
            if(!order.other_users) {
                return false;
            }
            return order.other_users.find(user => {
                return user.user_id === utils.user.get().user_id;
            })
        } catch(e) {
            return false;
        }
    }

    const showEditOptions = item => {

        if(!item || !item.on_edit) {
            return;
        }

        if(item.on_edit.component === 'driver_tip') {
            utils.layer.open({
                id: 'driver-tip-new',
                Component: DriverTip.bind(this, {
                    shouldProcess: false,
                    onAddTip: amount => {
                        onItemUpdate(item, {
                            driver_tip: {
                                amount: amount
                            }
                        })
                    },
                    onRemoveTip: order.driver_tip ? () => {
                        onItemUpdate(item, { driver_tip: false })
                    } : null
                })
            })
            return;
        }

        if(item.on_edit.component === 'order_cart') {
            utils.layer.open({
                id: 'view-order',
                abstract: abstract,
                Component: OrderCartDetails.bind(this, {
                    isNewTarget: isNewTarget
                })
            })
            return;
        }

        utils.layer.open({
            id: `line-item-${item.key}`,
            Component: LineItem.bind(this, {
                item: item,
                onChange: onItemUpdate
            })
        })
    }

    const getButtons = () => {

        // No buttons available for "other users"
        if(abstract.object.customer.user_id !== utils.user.get().user_id && utils.user.level > User.level.driver) {
            return null;
        }

        // Unpaid or failed charge orders
        if(abstract.object.status && [
            StatusCodes.orders.unpaid,
            StatusCodes.orders.chargeIssue,
            StatusCodes.orders.chargeFailed
        ].includes(abstract.object.status.code)) {
            if(abstract.object.customer && abstract.object.customer.user_id === utils.user.get().user_id) {
                return [{
                    key: 'updatePayment',
                    text: 'Update Payment Method',
                    color: Appearance.colors.red,
                    onPress: onUpdatePaymentMethod
                }]
            }
        }

        // Pending orders
        if(!onBookOrder && utils.user.get().level <= User.level.admin) {
            if(!abstract.object.status || [
                StatusCodes.orders.pending,
                StatusCodes.orders.adminUpdated,
                StatusCodes.orders.driverUpdated,
                StatusCodes.orders.customerEdited
            ].includes(abstract.object.status.code)) {
                return [{
                    key: 'decline',
                    text: 'Decline',
                    color: Appearance.colors.red,
                    loading: loading === 'decline',
                    onPress: onSetStatus.bind(this, StatusCodes.orders.rejected)
                },{
                    key: 'approve',
                    text: 'Approve',
                    color: Appearance.colors.primary(),
                    loading: loading === 'approve',
                    onPress: onSetStatus.bind(this, StatusCodes.orders.approved)
                }]
            }
        }

        // Standard orders
        let buttons = [];
        if(abstract.getID() && abstract.object.customer.user_id == utils.user.get().user_id) {
            if(!abstract.object.status || [
                StatusCodes.orders.pending,
                StatusCodes.orders.approved,
                StatusCodes.orders.returned,
                StatusCodes.orders.rejected,
                StatusCodes.orders.adminUpdated,
                StatusCodes.orders.driverUpdated,
                StatusCodes.orders.customerEdited,
                StatusCodes.orders.preauthorized,
                StatusCodes.orders.preauthorizationFailed,
                StatusCodes.orders.preauthorizationRevoked
            ].includes(abstract.object.status.code)) {
                buttons = [{
                    key: 'onReschedule',
                    text: 'Reschedule',
                    color: Appearance.colors.grey(),
                    onPress: () => {
                        (lineItems || []).forEach(section => {
                            let item = section.items.find(item => item.key === 'drop_off_date');
                            if(item) {
                                showEditOptions({
                                    ...item,
                                    can_reschedule: true
                                });
                            }
                        })
                    }
                },{
                    key: 'onCancel',
                    text: 'Cancel',
                    loading: loadingCancellation,
                    color: Appearance.colors.red,
                    onPress: onCancelOrder
                }];
            }
        }

        if(onBookOrder) {
            buttons.push({
                key: 'onBook',
                text: runningTotal ? `Confirm ${Utils.toCurrency(runningTotal)}` : 'Confirm',
                color: 'primary',
                loading: loading,
                onPress: onSubmit
            })
            if(runningTotal && Platform.OS === 'ios') {
                buttons.push({
                    key: 'apple_pay',
                    type: 'order_with_apple_pay',
                    onPress: onOrderWithApplePay
                })
            }
        }

        if(abstract.object.customer && abstract.object.customer.user_id !== utils.user.get().user_id) {
            if(onStartOrder) {
                buttons.push({
                    key: 'onStart',
                    text: buttons.length > 0 ? 'Start' : 'Start Order',
                    color: 'primary',
                    onPress: async () => {
                        try {
                            setLoading(true);
                            await onStartOrder(utils, abstract);
                            setLayerState('close');

                        } catch(e) {
                            setLoading(false);
                            utils.alert.show({
                                title: 'Oops!',
                                message: `There was an issue starting your Order. ${e.message || 'An unknown error occurred'}`
                            })
                        }
                    }
                });
            }

            let { driver } = abstract.object;
            if(onResumeOrder && driver && driver.user_id === utils.user.get().user_id) {
                buttons.push({
                    key: 'onResume',
                    text: buttons.length > 0 ? 'Resume' : 'Resume Order',
                    color: Appearance.colors.blue,
                    onPress: async () => {

                        try {
                            setLoading(true);
                            await onResumeOrder(utils, abstract);
                            setLayerState('close');

                        } catch(e) {
                            setLoading(false);
                            utils.alert.show({
                                title: 'Oops!',
                                message: `There was an issue starting your Order. ${e.message || 'An unknown error occurred'}`
                            })
                        }
                    }
                });
            }
        }

        return buttons.length > 0 ? buttons : null;
    }

    const getContent = () => {
        if(!order) {
            return null;
        }
        return (
            <>
            {getOverview()}
            {getModularContent()}
            {getCustomer()}
            {getCompany()}
            {getOtherUsers()}
            {getLineItems()}
            </>
        )
    }

    const getCompany = () => {
        if(!abstract.object.company || utils.user.get().user_id === abstract.object.customer.user_id) {
            return null;
        }
        return (
            <View style={{
                paddingHorizontal: 15
            }}>
                <LayerItem title={'Company'}>
                    {Views.entry({
                        title: abstract.object.company.name,
                        subTitle: abstract.object.company.address || 'Address not available',
                        icon: {
                            path: abstract.object.company.image
                        },
                        bottomBorder: false
                    })}
                </LayerItem>
            </View>

        )
    }

    const getCustomer = () => {
        if(utils.user.get().user_id === abstract.object.customer.user_id) {
            return null;
        }
        return (
            <View style={{
                paddingHorizontal: 15
            }}>
                <LayerItem title={utils.user.get().level <= User.level.driver ? 'Customer' : 'Booked By'}>
                    {Views.entry({
                        title: abstract.object.customer.full_name,
                        subTitle: abstract.object.customer.phone_number,
                        icon: {
                            path: abstract.object.customer.avatar
                        },
                        onPress: onUserPress.bind(this, abstract.object.customer),
                        bottomBorder: false
                    })}
                </LayerItem>
            </View>
        )
    }

    const getHeaderComponents = () => {
        let hasVisibility = order.customer.user_id === utils.user.get().user_id || isOtherUser() ? true : false;
        if(!abstract.getID() || hasVisibility === false) {
            return null;
        }
        let { toDestination, toPickup } = StatusCodes.orders;
        if(canShowTripNavigator() === true && abstract.object.status && [toDestination, toPickup].includes(abstract.object.status.code)) {
            return (
                <View style={{
                    width: '100%',
                    marginBottom: 12
                }}>
                    <ActiveAbstractHeader
                    utils={utils}
                    driver={driver}
                    abstract={abstract} />
                </View>
            )
        }
        return (
            <View style={{
                ...Appearance.styles.panel(),
                marginBottom: 12
            }}>
                {Views.entry({
                    title: 'Message Your Driver',
                    subTitle: `Please let your driver know if you have any special requests or questions about your upcoming delivery`,
                    bottomBorder: false,
                    icon: {
                        path: require('eCarra/images/message-icon-clear-small.png'),
                        style: {
                            backgroundColor: Appearance.colors.primary()
                        }
                    },
                    propStyles: {
                        subTitle: {
                            numberOfLines: 2
                        }
                    },
                    onPress: onMessageDriver
                })}
            </View>
        )
    }

    const getLineItems = () => {
        if(!lineItems || lineItems.length === 0) {
            return (
                <View style={{
                    padding: 15
                }}>
                    <View style={{
                        ...Appearance.styles.panel(),
                        padding: 20,
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <LottieView
                        autoPlay={true}
                        loop={true}
                        source={Appearance.themeStyle() === 'dark' ? require('eCarra/files/lottie/dots-white.json') : require('eCarra/files/lottie/dots-grey.json')}
                        duration={2500}
                        style={{
                            width: 50
                        }}/>
                    </View>
                </View>
            )
        }
        return (
            <View style={{
                paddingHorizontal: 15
            }}>
                {lineItems.filter(lineItem => {
                    return lineItem.items && lineItem.items.length > 0;
                }).map((lineItem, index) => {
                    return (
                        <LayerItem
                        key={index}
                        title={lineItem.title}>
                            {lineItem.items.map((item, index, items) => {
                                if(isNewTarget && item.key === 'id') {
                                    return null;
                                }
                                let showLeftContent = item.required && !item.value || (breakdownWarnings || []).find(warning => warning.key === item.key) ? true : false;
                                return  (
                                    Views.entry({
                                        key: item.key,
                                        title: item.title,
                                        subTitle: item.formatted || item.null_value,
                                        loading: item.loading,
                                        hideIcon: true,
                                        bottomBorder: index !== items.length - 1,
                                        onPress: item.on_edit && item.on_edit.enabled ? onItemPress.bind(this, item) : null,
                                        propStyles: {
                                            ...item.prop_styles,
                                            subTitle: {
                                                numberOfLines: 2,
                                                ...item.prop_styles && item.prop_styles.subTitle
                                            }
                                        },
                                        prepend: showLeftContent && (
                                            <View style={{
                                                width: 12,
                                                height: 12,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginRight: 8
                                            }}>
                                                <View style={{
                                                    width: 8,
                                                    height: 8,
                                                    borderRadius: 4,
                                                    backgroundColor: Appearance.colors.red
                                                }}/>
                                            </View>
                                        )
                                    })
                                )
                            })}
                        </LayerItem>
                    )
                })}
            </View>
        )
    }

    const getMapOverview = () => {
        return (
            <View style={{
                ...Appearance.styles.panel(),
                marginBottom: 25
            }}>
                <View style={{
                    width: '100%',
                    borderRadius: 8,
                    overflow: 'hidden'
                }}>
                    <Map
                    driver={driver}
                    utils={utils}
                    overlays={overlays}
                    annotations={annotations}
                    style={{
                        height: Platform.OS === 'web' || Utils.isMobile() === false ? 400 : 200,
                        width: Screen.layer.maxWidth - 24,
                        marginBottom: 4,
                        borderBottomWidth: 1,
                        borderBottomColor: Appearance.colors.divider()
                    }}/>
                    {Views.entry({
                        title: order.destination ? (order.destination.name || order.destination.address) : 'Destination Not Selected',
                        subTitle: order.drop_off_date ? moment(order.drop_off_date).format('MMMM Do, YYYY [at] h:mma') : 'Drop Off Date Not Selected',
                        hideIcon: true,
                        textStyles: {
                            title: Appearance.textStyles.panelTitle(),
                            subTitle: {
                                ...Appearance.textStyles.title(),
                                color: Appearance.colors.subText()
                            }
                        }
                    })}
                    {getPaymentLineItems()}
                </View>
            </View>
        )
    }

    const getModularContent = () => {
        if(!modularContent || modularContent.length === 0) {
            return null;
        }
        return (
            <ModularContentLogic
            utils={utils}
            content={modularContent}
            width={Screen.layer.maxWidth}
            category={isNewTarget ? 'book_order_confirm' : 'order_details'}
            style={{
                marginBottom: 15
            }}
            onRequestLogic={{
                prevUsers: order.other_users,
                abstract: abstract,
                onChange: onUpdateOtherUsers
            }}/>
        )
    }

    const getOtherUsers = () => {

        if(utils.user.get().user_id !== abstract.object.customer.user_id && utils.user.get().level > User.level.driver) {
            return null;
        }
        if(!order.other_users || order.other_users.length === 0) {
            return null;
        }
        return (
            <View style={{
                paddingHorizontal: 15
            }}>
                <LayerItem title={'Friends and Family'}>
                    {order.other_users.map((user, index, users) => {
                        return (
                            Views.entry({
                                key: index,
                                title: user.full_name,
                                subTitle: user.phone_number,
                                icon: {
                                    path: user.avatar
                                },
                                onPress: onUserPress.bind(this, user),
                                bottomBorder: index !== users.length - 1
                            })
                        )
                    })}
                </LayerItem>
            </View>
        )
    }

    const getOverview = () => {
        return (
            <View style={{
                paddingTop: LayerHeaderSpacing.get(),
                paddingHorizontal: 15
            }}>
                {getHeaderComponents()}
                {getMapOverview()}
            </View>
        )
    }

    const getPaymentLineItems = () => {
        if(!paymentLineItems || paymentLineItems.length === 0) {
            return null;
        }
        if(utils.user.get().level > User.level.admin && abstract.object.customer.user_id !== utils.user.get().user_id) {
            return null;
        }
        return (
            <>
            {paymentLineItems.map((item, index, items) => {
                return (
                    <View
                    key={index}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        paddingHorizontal: 12,
                        paddingVertical: 8,
                        borderBottomWidth: 1,
                        borderBottomColor: Appearance.colors.divider()
                    }}>
                        <Text style={Appearance.textStyles.key()}>{item.title}</Text>
                        <Text style={Appearance.textStyles.value()}>{item.message}</Text>
                    </View>
                )
            })}
            {order.service && typeof(order.service.disclaimer) === 'string' && (
                <Text style={{
                    ...Appearance.textStyles.small(),
                    padding: 12
                }}>{order.service.disclaimer}</Text>
            )}
            </>
        )
    }

    const fetchCostBreakdown = async () => {
        try {
            // fetch cost breakdown
            setStatus('line_items', false);
            let { breakdown, route } = await abstract.object.costBreakdown(utils);
            setRunningTotal(breakdown.total);
            setPaymentLineItems(breakdown ? breakdown.line_items : []);

            // update overlays from target locations
            // overlays may have changed if a stop or location was modified
            setOverlays(abstract.object.getOverlays());

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue calculating your cost estimate. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchLineItems = () => {
        // prevent moving forward if no manager props are present or changes are not pending
        if(!order || !manager.line_items || manager.line_items.pending === false) {
            return;
        }
        fetchCostBreakdown();
        fetchPaymentLineItems();
    }

    const fetchPaymentLineItems = async () => {
        try {
            // fetch order line items
            let { line_items } = await fetchOrderLineItems(utils, {
                ...abstract.getID() && {
                    order_id: abstract.getID()
                },
                ...!abstract.getID() && {
                    customer: order.customer ? order.customer.user_id : null,
                    drop_off_date: order.drop_off_date ? moment(order.drop_off_date).format('YYYY-MM-DD HH:mm:ss') : null,
                    company: order.company ? order.company.id : null,
                    service: order.service ? order.service.id : null,
                    promo_code: order.promo_code ? order.promo_code.id : null,
                    credits: order.credits,
                    driver_tip: order.driver_tip,
                    requests: order.requests,
                    host: order.host.id,
                    service: order.service.id,
                    options: order.options ? order.options.map(opt => OrderOption.toJSON(opt)) : [],
                    subscription: order.subscription ? order.subscription.id : null,
                    origin: order.origin && {
                        name: order.origin.name,
                        address: order.origin.address,
                        lat: order.origin.location.latitude,
                        long: order.origin.location.longitude
                    },
                    destination: order.destination && {
                        name: order.destination.name,
                        address: order.destination.address,
                        lat: order.destination.location.latitude,
                        long: order.destination.location.longitude
                    },
                    stops: order.stops && order.stops.locations && {
                        ...order.stops,
                        locations: order.stops.locations.map(stop => ({
                            name: stop.name,
                            address: stop.address,
                            lat: stop.lat || stop.location.latitude,
                            long: stop.long || stop.location.longitude
                        }))
                    },
                }
            });

            // update line items
            setLineItems(line_items.filter(section => section.items.length > 0));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the details for this order. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const setupModularContent = async () => {
        try {
            let { content} = await Utils.fetchModularContent(utils, isNewTarget ? 'book_order_confirm' : 'order_details');
            if(content && content.length > 0) {
                setModularContent(content.filter(item => {
                    if(item.mobileAction === 'layer.friends-and-family.show' && utils.user.get().user_id !== abstract.object.customer.user_id) {
                        return false;
                    }
                    return true;
                }));
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setOrder(edits);
    }

    useEffect(() => {
        if(order) {
            setManager('routing', {
                destination: order.destination,
                driver: order.driver,
                origin: order.origin,
                stops: order.stops,
            });
            setManager('line_items', {
                credits: order.credits,
                destination: order.destination,
                driver_tip: order.driver_tip,
                drop_off_date: order.drop_off_date,
                origin: order.origin,
                promo_code: order.promo_code,
                service: order.service,
                stops: order.stops,
                subscription: order.subscription
            });
        }
    }, [order]);

    useEffect(() => {
        fetchLineItems();
    }, [manager.line_items]);

    useEffect(() => {
        onUpdateMapElements();
    }, [manager.routing]);

    useEffect(() => {

        setupTarget();
        setupModularContent();

        let orderID = abstract.getID();
        if(orderID) {
            utils.sockets.on('orders', `on_active_update_${orderID}`, onLocationUpdate);
            utils.content.subscribe(layerID, 'orders', {
                onUpdate: next => {
                    setOrder(abstract.compare(next));
                }
            });
            return () => {
                utils.content.unsubscribe(layerID);
                utils.sockets.off('orders', `on_active_update_${orderID}`, onLocationUpdate);
            }
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`${channel.name} Order Details`}
        utils={utils}
        index={index}
        buttons={getButtons()}
        contentStyle={{
            paddingBottom: FloatingLayerMinimizedHeight + 15
        }}
        options={{
            ...options,
            loading: loading === true,
            layerState: layerState,
            removePadding: true,
            stackButtons: onBookOrder ? true : false
        }}>
            {getContent()}
        </Layer>
    )
}

export const OrderOptionDetails = ({ channel, isEditing, onAddToOrder, onUpdateOrder }, { abstract, index, utils, options }) => {

    const layerID = `order-${channel.id}-option-${abstract.object.copy_id}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [target, setTarget] = useState(abstract.object);

    const onCheckboxListChange = (index, options) => {
        abstract.object.options = update(abstract.object.options, {
            [index]: {
                options: {
                    $set: options
                }
            }
        });
        setTarget(abstract.object);
    }

    const onOrderAddition = () => {
        Keyboard.dismiss();
        let required = target.options.find(opt => {
            if(opt.required !== true) {
                return false;
            }
            return opt.options ? !opt.options.find(opt => opt.selected) : !opt.value;
        });
        if(required) {
            utils.alert.show({
                title: 'Just a Second',
                message: `Please make a selection for "${required.title}" before adding "${target.name}" to your ${channel.name} Order`
            })
            return;
        }
        console.log(abstract.object);
        onAddToOrder(abstract.object);
        setLayerState('close');
    }

    const onOrderUpdate = () => {
        Keyboard.dismiss();
        let required = target.options.find(opt => {
            if(opt.required !== true) {
                return false;
            }
            return opt.options ? !opt.options.find(opt => opt.selected) : !opt.value;
        });
        if(required) {
            utils.alert.show({
                title: 'Just a Second',
                message: `Please make a selection for "${required.title}" before adding "${target.name}" to your ${channel.name} Order`
            })
            return;
        }
        onUpdateOrder(abstract.object);
        setLayerState('close');
    }

    const onRequestsChange = text => {
        abstract.object.requests = text;
        setTarget(abstract.object);
    }

    const onTextAreaChange = (index, text) => {
        abstract.object.options = update(abstract.object.options, {
            [index]: {
                value: {
                    $set: text
                }
            }
        });
        setTarget(abstract.object);
    }

    const getCarouselProps = () => {
        let target = abstract.object.images;
        return {
            sliderWidth: Screen.layer.maxWidth - 30,
            itemWidth: Screen.layer.maxWidth - 60,
            ...Platform.OS !== 'web' && {
                layoutCardOffset: 0,
                loop: target.length > 1
            },
            ...(Platform.OS === 'web' || Utils.isMobile() === false) && {
                sliderWidth: Screen.layer.maxWidth - 30,
                itemWidth: Screen.layer.maxWidth - 45,
                ...target.length >= 3 && {
                    itemWidth: Screen.layer.maxWidth / 3
                }
            }
        }
    }

    const getCustomizations = () => {
        return abstract.object.options.map((option, index) => {
            return (
                <View
                key={index}
                style={{
                    ...Appearance.styles.panel(),
                    width: '100%',
                    marginBottom: 15,
                    padding: 15
                }}>
                    <View style={{
                        width: '100%',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <Text
                        ellipsizeMode={'tail'}
                        style={{
                            ...Appearance.textStyles.title(),
                            maxWidth: option.required ? Screen.panel.maxWidth() - 120 : '100%'
                        }}>{option.title}</Text>
                        {option.required && (
                            <AltBadge content={{
                                text: 'Required',
                                color: Appearance.colors.grey()
                            }}/>
                        )}
                    </View>
                    {typeof(option.description) === 'string' && (
                        <Text style={{
                            ...Appearance.textStyles.subTitle(),
                            marginTop: 4
                        }}>{option.description}</Text>
                    )}
                    {option.type === 'text-area' && (
                        <TextView
                        value={option.value}
                        onChange={onTextAreaChange.bind(this, index)}
                        containerStyle={{
                            marginTop: 12,
                            backgroundColor: Appearance.colors.divider()
                        }} />
                    )}
                    {option.options && option.options.length > 0 && (
                        <View style={{
                            marginTop: 15,
                            width: '100%'
                        }}>
                            <CheckboxList
                            items={option.options.filter(option => option.type === 'checkbox')}
                            max={option.restrictions && option.restrictions.max ? option.restrictions.max : null}
                            onChange={onCheckboxListChange.bind(this, index)} />
                            {option.options.filter(option => option.type !== 'checkbox').map((opt, i) => (
                                <Text>{opt.type}</Text>
                            ))}
                        </View>
                    )}
                </View>
            )
        })
    }

    const getOverview = () => {
        return (
            <View style={{
                ...Appearance.styles.panel(),
                marginTop: 45,
                marginBottom: 15,
                alignItems: 'center'
            }}>
                <View style={{
                    width: '100%',
                    height: 250,
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    overflow: 'hidden'
                }}>
                    <Carousel
                    data={abstract.object.images}
                    enableSnap={true}
                    activeSlideAlignment={'center'}
                    {...getCarouselProps()}
                    renderItem={({ item, index }) => {
                        return (
                            <Image
                            key={index}
                            source={item}
                            style={{
                                width: '100%',
                                height: '100%',
                                resizeMode: 'cover',
                                backgroundColor: Appearance.colors.grey()
                            }}/>
                        )
                    }} />
                </View>

                <View style={{
                    padding: 15,
                    width: '100%'
                }}>
                    <Text style={{
                        ...Appearance.textStyles.panelTitle(),
                        marginBottom: 8,
                        textAlign: 'center'
                    }}>{abstract.object.name}</Text>
                    <Text style={{
                        ...Appearance.textStyles.subTitle(),
                        fontSize: 14,
                        textAlign: 'center',
                        marginBottom: 15
                    }}>{abstract.object.description}</Text>

                    <View style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <AltBadge content={{
                            text: 'Featured',
                            color: Appearance.colors.primary()
                        }} style={{
                            marginRight: 8
                        }} />
                        <AltBadge content={{
                            text: `${abstract.object.options.length > 0 ? 'Starting at ' : ''}${Utils.toCurrency(abstract.object.cost)}`,
                            color: Appearance.colors.grey()
                        }} style={{
                            marginRight: 8
                        }} />
                    </View>
                </View>
            </View>
        )
    }

    const getSpecialRequests = () => {
        if(abstract.object.parameters.special_requests === false && abstract.object.options.length > 0) {
            return (
                <View style={{
                    ...Appearance.styles.panel(),
                    padding: 15
                }}>
                    <Text style={{
                        ...Appearance.textStyles.subTitle(),
                        textAlign: 'center'
                    }}>{`${abstract.object.category.host.name} is not accepting customizations for this item. Please reach out to your driver if you have questions or concerns about this item`}</Text>
                </View>
            );
        }
        return (
            <View style={{
                ...Appearance.styles.panel(),
                padding: 15
            }}>
                <Text style={{
                    ...Appearance.textStyles.title(),
                    marginBottom: 8
                }}>{'Special Requests'}</Text>
                <TextView
                placeholder={`Anything special that we should know about your order for "${abstract.object.name}"?`}
                onChange={onRequestsChange}
                value={abstract.object.requests}
                containerStyle={{
                    backgroundColor: Appearance.colors.divider()
                }}/>
            </View>
        )
    }

    return (
        <Layer
        id={layerID}
        title={abstract.object.name}
        utils={utils}
        index={index}
        options={{
            ...options,
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: isEditing ? 'Update Order' : 'Add To Order',
            color: 'primary',
            onPress: isEditing ? onOrderUpdate : onOrderAddition
        }]}>
            {getOverview()}
            {getCustomizations()}
            {getSpecialRequests()}
        </Layer>
    )
}
