import React, { useState, useEffect, useRef } from 'react';
import { Animated, Image, Keyboard, Platform, Text, TouchableWithoutFeedback, View } from 'react-native';
import moment from 'moment';

import API from 'eCarra/files/api.js';
import Abstract from 'eCarra/classes/Abstract.js';
import AddressLookupField from 'eCarra/views/AddressLookupField.js';
import Appearance from 'eCarra/styles/Appearance.js';
import * as AppleAuthentication from 'eCarra/files/AppleAuthentication/';
import AsyncStorage from '@react-native-community/async-storage';
import Button from 'eCarra/views/Button.js';
import { CodeField, Cursor } from 'react-native-confirmation-code-field';
import DeviceInfo from 'react-native-device-info';
import FBSDK from 'eCarra/files/FBSDK/';
import { GoogleSignin, statusCodes } from 'eCarra/files/Google/';
import KeyboardManager from 'eCarra/files/KeyboardManager.js';
import Layer from 'eCarra/structure/Layer.js';
import LottieView from 'eCarra/views/Lottie/';
import Permissions, { PERMISSIONS, RESULTS } from 'eCarra/files/Permissions/';
import ReactNativeBiometrics from 'react-native-biometrics';
import Request from 'eCarra/files/Request/';
import Screen from 'eCarra/files/Screen.js';
import TextField from 'eCarra/views/TextField.js';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import User from 'eCarra/classes/User.js';
import Utils from 'eCarra/files/Utils.js';
import VersionInfo from 'react-native-version-info';
import Video from 'eCarra/views/Video/';

const FBManager = new FBSDK.GraphRequestManager();

const LoginCard = ({ utils, onLogin }) => {

    const viewID = 'loginCard';

    const backButton = useRef(null);
    const tabletContainer = useRef(null);

    const [animations, setAnimations] = useState({
        logo: {
            top: new Animated.Value(-125),
            opacity: new Animated.Value(0)
        },
        lottie: {
            top: new Animated.Value(25),
            opacity: new Animated.Value(0)
        },
        manual: {
            container: {
                bottom: new Animated.Value(0)
            },
            fields: {
                username: {
                    top: new Animated.Value(-25),
                    opacity: new Animated.Value(0)
                },
                password: {
                    top: new Animated.Value(-25),
                    opacity: new Animated.Value(0)
                },
                button: {
                    top: new Animated.Value(-25),
                    opacity: new Animated.Value(0)
                }
            }
        },
        buttons: {
            apple: {
                top: new Animated.Value(-25),
                opacity: new Animated.Value(0)
            },
            google: {
                top: new Animated.Value(-25),
                opacity: new Animated.Value(0)
            },
            facebook: {
                top: new Animated.Value(-25),
                opacity: new Animated.Value(0)
            },
            username: {
                top: new Animated.Value(-25),
                opacity: new Animated.Value(0)
            }
        }
    })
    const [canUseAppleAuth, setCanUseAppleAuth] = useState(true);
    const [canUseGoogleAuth, setCanUseGoogleAuth] = useState(true);
    const [channel, setChannel] = useState('social');
    const [loading, setLoading] = useState(false);
    const [logoAnimation, setLogoAnimation] = useState(null);
    const [password, setPassword] = useState(null);
    const [username, setUsername] = useState(null);

    const onAnimateOutOfView = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                await Utils.sleep(0.5);
                Animated.timing(animations.logo.opacity, {
                    toValue: 0,
                    duration: 500,
                    useNativeDriver: false
                }).start();
                Animated.spring(animations.logo.top, {
                    toValue: -125,
                    friction: 10,
                    duration: 1000,
                    useNativeDriver: false
                }).start();
                Animated.spring(animations.lottie.top, {
                    toValue: 25,
                    friction: 10,
                    duration: 2500,
                    useNativeDriver: false
                }).start();
                Animated.timing(animations.lottie.opacity, {
                    toValue: 0,
                    duration: 500,
                    useNativeDriver: false
                }).start();
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    const onAppStorePress = () => {
        window.open(utils.client.get().apps.ios);
    }

    const onBackButtonPress = () => {
        if(channel === 'manual') {
            onUpdateButtons('social');
        }
    }

    const onGooglePlayPress = () => {
        window.open(utils.client.get().apps.android);
    }

    const onLoginComplete = async json => {
        try {
            // show loader
            onUpdateButtons();
            setLoading(false);

            // animation components out of view
            await onAnimateOutOfView()

            // save jwt and return user props to root
            await Utils.sleep(1);
            onUpdateTwoFactorJWT(json);
            if(json.login_token) {
                await AsyncStorage.setItem(`${utils.client.get().id}_login`, json.login_token);
            }

            let user = User.create(json);
            if(typeof(onLogin) === 'function') {
                onLogin(user);
                return;
            }

        } catch(e) {
            setLoading(false);
            onStartAnimations();
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue logging into your account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onLoginPress = async props => {

        Keyboard.dismiss();
        let _username = props && props.username ? props.username : username;
        let _password = props && props.password ? props.password : password;
        if(!_username || !_password) {
            utils.alert.show({
                title: 'Oops!',
                message: 'Please enter your username and password to continue'
            })
            return;
        }

        try {
            setLoading(true);
            let deviceInfo = await getDeviceInfo();
            let response = await Request.post(utils, '/user/', {
                type: 'login',
                username: _username,
                password: _password,
                ...deviceInfo
            });

            if(response.event === '2fa') {
                onRun2fa(response);
                return;
            }
            onLoginComplete(response);

        } catch(e) {
            setLoading(false);
            onStartAnimations();
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue logging into your account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onLoginWithToken = async token => {
        try {

            // start loader
            utils.loader.show();

            // send token to server for validation
            await Utils.sleep(0.5);
            let deviceInfo = await getDeviceInfo();
            let response = await Request.post(utils, '/user/', {
                type: 'login_with_token',
                token: token,
                ...deviceInfo
            });

            // check for 2fa requirement
            if(response.event === '2fa') {
                onRun2fa({ ...response, token: token });
                onStartAnimations();
                return;
            }

            // create user account
            let user = User.create(response);
            if(typeof(onLogin) === 'function') {
                onLogin(user);
                onUpdateTwoFactorJWT(response);
            }

        } catch(e) {
            setLoading(false);
            onStartAnimations();
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue logging into your account. ${e.message || 'An unknown error occurred'}`,
                onPress: onStartAnimations
            })
        }
    }

    const onResetPassword = () => {
        utils.layer.webView({
            id: 'reset-password',
            title: 'Reset your Password',
            closeButton: true,
            url: `${API.server}/users/reset-password/index.html?version=${API.version}&app_id=${API.get_client_id()}&nonce=${moment().unix()}`
        })
    }

    const onRun2fa = async json => {

        Keyboard.dismiss();
        try {
            // Update mobile JWT
            let { tokens } = json;
            if(tokens && tokens.two_factor_jwt) {
                await AsyncStorage.setItem('two_factor_jwt', tokens.two_factor_jwt);
            }
        } catch(e) {
            console.log(`Uable to update two factor jwt. ${e.message || 'An unknown error occurred'}`);
        }

        // Request auth code
        setLoading(false);
        utils.alert.show({
            title: 'Two Factor Authentication',
            message: `It looks like we may not recognize this device. We've sent a verification code to the phone number on your account ending in ${json.phone_number_short}. Please enter the verification code below.`,
            twoFactor: true,
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onPress: async response => {

                if(response.twoFactor) {
                    try {
                        setLoading(true);
                        await onVerify2fa({
                            ...json, // token, social service, etc
                            username: username,
                            password: password,
                            code: response.twoFactor
                        });

                    } catch(e) {
                        setLoading(false);
                        utils.alert.show({
                            title: 'Oops!',
                            message: `There was an issue verifying your login. ${e.message || 'An unknown error occurred'}`
                        });
                    }
                }
            }
        })
    }

    const onSetupClientIcon = () => {
        setLogoAnimation(Utils.getClientLottieLogo(utils));
    }

    const onSignInWithApple = async () => {
        try {
            let { email, full_name, user }  = await AppleAuthentication.signInAsync({
                requestedScopes: [
                    AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                    AppleAuthentication.AppleAuthenticationScope.EMAIL
                ],
                configure: {
                    clientId: utils.client.get().apps.bundle.web,
                    redirectURI: 'https://ecarra.app',
                    state: 'origin:web',
                    nonce: `${moment().unix()}_${Utils.randomString()}`,
                    usePopup: true
                }
            });

            let token = user;
            let first_name = full_name ? full_name.givenName : null;
            let last_name = full_name ? full_name.familyName : null;
            let longName = first_name && last_name ? `${first_name} ${last_name}` : null;

            // decode authorization token if platform is web
            if(Platform.OS === 'web') {
                let response = await Request.post(utils, '/user/', {
                    type: 'decode_sign_in_with_apple_token',
                    token: token
                });
                token = response.sub;
            }

            // Set temp apple record to account for interupted or cancelled signups
            if(token && first_name && last_name && email) {
                await Request.post(utils, '/user/', {
                    type: 'set_temp_apple_auth_signup',
                    token: token,
                    first_name: first_name,
                    last_name: last_name,
                    email_address: email
                });
            }

            onSignInWithSocial({
                service: 'apple',
                token: token,
                email_address: email
            }, onStartSignup.bind(this, {
                token: token,
                service: 'apple',
                first_name: first_name,
                last_name: last_name,
                full_name: longName,
                email_address: email
            }));

        } catch(e) {
            setLoading(false);
            console.error(e.message);
        }
    }

    const onSignInWithGoogle = async () => {
        try {
            let response = await GoogleSignin.signIn();
            let token = response.user.id;
            let first_name = response.user.givenName;
            let last_name = response.user.familyName;
            let full_name = response.user.name;
            let avatar = response.user.photo;
            let email_address = response.user.email;

            onSignInWithSocial({
                service: 'google',
                token: token,
                email_address: email_address
            }, onStartSignup.bind(this, {
                token: token,
                service: 'google',
                first_name: first_name,
                last_name: last_name,
                full_name: full_name,
                email_address: email_address,
                avatar: avatar && {
                    type: 'url',
                    data: avatar
                }
            }));

        } catch(e) {
            setLoading(false);
            switch(e.code) {
                case statusCodes.SIGN_IN_CANCELLED:
                break;

                case statusCodes.WEB_POPUP_BLOCKED:
                utils.alert.show({
                    title: 'Oops!',
                    message: 'We tried to sign you in using your Google account but your browser blocked the popup'
                });
                break;

                default:
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue using Sign in with Google. ${e.message || 'An unknown error occurred'}`
                })
            }
        }
    }

    const onSignInWithFacebook = async () => {
        try {
            let { isCancelled } = await FBSDK.LoginManager.logInWithPermissions([ 'public_profile' ]);
            if(isCancelled) {
                return;
            }

            let request = new FBSDK.GraphRequest('/me', {
                parameters: {
                    fields: {
                        string: 'id, first_name, last_name, email, picture',
                    }
                }
            }, (error, response) => {

                if(error) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue signing in with Facebook. ${error.message || 'An unknown error occurred'}`
                    });
                    return;
                }

                let token = response.id;
                let first_name = response.first_name;
                let last_name = response.last_name;
                let full_name = `${first_name} ${last_name}`;
                let avatar = response.picture && response.picture.data ? response.picture.data.url : null;
                let email_address = response.email;

                onSignInWithSocial({
                    service: 'facebook',
                    token: token,
                    email_address: email_address
                }, onStartSignup.bind(this, {
                    token: token,
                    service: 'facebook',
                    first_name: first_name,
                    last_name: last_name,
                    full_name: full_name,
                    email_address: email_address,
                    avatar: avatar ? {
                        type: 'url',
                        data: avatar
                    } : null
                }))
            });
            FBManager.addRequest(request).start();

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue signing in with Facebook. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSignInWithSocial = async (props, onRequestSignupStart) => {
        try {
            setLoading(props.service);
            let deviceInfo = await getDeviceInfo();
            let response = await Request.post(utils, '/user/', {
                type: 'sign_in_with_social',
                ...deviceInfo,
                ...props
            });

            if(response.invalid) {
                setLoading(false);
                onRequestSignupStart();
                return;
            }

            if(response.event === '2fa') {
                onRun2fa({
                    ...response,
                    social_token: props.token,
                    social_service: props.service
                });
                return;
            }

            // 2fa validation for new or unknown devices
            if(response.validation) {
                utils.alert.show({
                    title: 'Verify your Account',
                    message: `Let's make sure this is you before we move any further. Please confirm the phone number on your account to continue`,
                    textFields: [{
                        key: 'phone_number',
                        placeholder: 'Phone Number',
                        format: 'phone_number'
                    }],
                    buttons: [{
                        key: 'confirm',
                        title: 'Confirm',
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'Maybe Later',
                        style: 'destructive'
                    }],
                    onPress: result => {

                        if(result.phone_number) {
                            onValidateSignIn({
                                ...props,
                                user_id: response.user_id,
                                phone_number : result.phone_number.replace(/\D/g,'')
                            });
                            return;
                        }
                        setLoading(false);
                    }
                })
                return;
            }
            onLoginComplete(response);

        } catch(e) {
            setLoading(false);
            onStartAnimations();
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue logging into your account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSignupPress = props => {

        Keyboard.dismiss();
        utils.layer.open({
            id: 'signup',
            Component: Signup.bind(this, {
                preSignupProps: props,
                onSignup: props => onLoginPress(props)
            })
        })
    }

    const onSignUpWithApple = async props => {
        try {
             setLoading('apple');
             let response = await Request.post(utils, '/user/', {
                 type: 'signup_with_apple',
                 ...props
             });
             onLoginPress(response);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating your account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onStartAnimations = async () => {
        try {

            // end loading if applicable
            utils.loader.hide();

            // change animation order for logo and lottie if platform is web or device is tablet
            if(Platform.OS === 'web' || Utils.isMobile() === false) {
                utils.loader.hide();
                await Utils.sleep(Platform.OS === 'web' ? 1 : 0.5);
                Animated.spring(animations.lottie.top, {
                    toValue: 0,
                    friction: 10,
                    duration: 2500,
                    useNativeDriver: false
                }).start();
                Animated.timing(animations.lottie.opacity, {
                    toValue: 1,
                    duration: 500,
                    useNativeDriver: false
                }).start();

                onUpdateButtons(channel);
                await Utils.sleep(0.5);
                Animated.timing(animations.logo.opacity, {
                    toValue: 1,
                    duration: 1000,
                    useNativeDriver: false
                }).start();
                Animated.spring(animations.logo.top, {
                    toValue: 0,
                    friction: 10,
                    duration: 1000,
                    useNativeDriver: false
                }).start();
                return;
            }

            // mobile animations
            await Utils.sleep(0.5);
            Animated.timing(animations.logo.opacity, {
                toValue: 1,
                duration: 1000,
                useNativeDriver: false
            }).start();
            Animated.spring(animations.logo.top, {
                toValue: 0,
                friction: 10,
                duration: 1000,
                useNativeDriver: false
            }).start();

            await Utils.sleep(0.5);
            Animated.spring(animations.lottie.top, {
                toValue: 0,
                friction: 10,
                duration: 2500,
                useNativeDriver: false
            }).start();
            Animated.timing(animations.lottie.opacity, {
                toValue: 1,
                duration: 500,
                useNativeDriver: false
            }).start();
            onUpdateButtons(channel);

        } catch(e) {
            console.error(e.message);
        }
    }

    const onStartSignup = async props => {
        if(props.service === 'apple') {

            let { first_name, last_name, full_name, email_address } = props;
            if(!first_name || !last_name || !full_name || !email_address) {

                // attempt to find a temp record that was previously started but not completed
                try {
                    let { user } = await Request.get(utils, '/user/', {
                        type: 'temp_apple_auth_signup',
                        token: props.token
                    });
                    if(user) {
                        onSignUpWithApple({
                            ...props,
                            ...user
                        });
                        return;
                    }
                } catch(e) {
                    console.error(e.message);
                }

                // Fallback to manual signup
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue retrieving your name and email address using Sign in with Apple. Do you want to signup manually?`,
                    buttons: [{
                        key: 'confirm',
                        title: 'Yes',
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'Maybe Later',
                        style: 'cancel'
                    }],
                    onPress: key => {
                        if(key === 'confirm') {
                            onSignupPress(props);
                            return;
                        }
                    }
                });
                return;
            }

            // signup with default Sign in with Apple props
            onSignUpWithApple({
                token: props.token,
                first_name:  props.first_name,
                last_name: props.last_name,
                email_address: props.email_address
            });
            return;
        }
        onSignupPress(props);
    };

    const onUpdateButtons = newChannel => {

        // Hide current buttons
        switch(channel) {
            case 'social':
            Object.keys(animations.buttons).forEach((key, index) => {
                setTimeout(() => {
                    Animated.spring(animations.buttons[key].top, {
                        toValue: -25,
                        friction: 10,
                        duration: 1000,
                        useNativeDriver: false
                    }).start();
                    Animated.timing(animations.buttons[key].opacity, {
                        toValue: 0,
                        duration: 250,
                        useNativeDriver: false
                    }).start();
                }, index * 150)
            });
            break;

            case 'manual':
            backButton.current.reset();
            Object.keys(animations.manual.fields).forEach((key, index) => {
                setTimeout(() => {
                    Animated.spring(animations.manual.fields[key].top, {
                        toValue: -25,
                        friction: 10,
                        duration: 1000,
                        useNativeDriver: false
                    }).start();
                    Animated.timing(animations.manual.fields[key].opacity, {
                        toValue: 0,
                        duration: 250,
                        useNativeDriver: false
                    }).start();
                }, index * 150)
            });
        }

        // Show next buttons
        setTimeout(() => {

            setChannel(newChannel);
            switch(newChannel) {
                case 'social':
                Object.keys(animations.buttons).forEach((key, index) => {
                    setTimeout(() => {
                        Animated.spring(animations.buttons[key].top, {
                            toValue: 0,
                            friction: 10,
                            duration: 1000,
                            useNativeDriver: false
                        }).start();
                        Animated.timing(animations.buttons[key].opacity, {
                            toValue: 1,
                            duration: 250,
                            useNativeDriver: false
                        }).start();
                    }, index * 150)
                });
                break;

                case 'manual':
                if(backButton.current) {
                    backButton.current.play();
                }
                Object.keys(animations.manual.fields).forEach((key, index) => {
                    setTimeout(() => {
                        Animated.spring(animations.manual.fields[key].top, {
                            toValue: 0,
                            friction: 10,
                            duration: 1000,
                            useNativeDriver: false
                        }).start();
                        Animated.timing(animations.manual.fields[key].opacity, {
                            toValue: 1,
                            duration: 250,
                            useNativeDriver: false
                        }).start();
                    }, index * 150)
                });
                break;
            }
        }, 750)
    }

    const onUpdateTwoFactorJWT = async ({ tokens }) => {
        if(tokens && tokens.two_factor_jwt) {
            try {
                await AsyncStorage.setItem('two_factor_jwt', tokens.two_factor_jwt);
            } catch(e){
                console.error(e.message);
            }
        }
    }

    const onValidateSignIn = async props => {
        try {
            let deviceInfo = await getDeviceInfo();
            let response = await Request.post(utils, '/user/', {
                type: 'validate_signin',
                ...deviceInfo,
                ...props
            });

            if(response.event === '2fa') {
                onRun2fa({
                    ...response,
                    social_token: props.token,
                    social_service: props.service
                });
                return;
            }
            onLoginComplete(response);

        } catch(e) {
            setLoading(false);
            onStartAnimations();
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue logging into your account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onVerify2fa = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let deviceInfo = await getDeviceInfo();
                let response = await Request.post(utils, '/user/', {
                    type: 'sign_in_with_2fa',
                    ...deviceInfo,
                    ...props
                });

                onLoginComplete(response);
                resolve(response);

            } catch(e) {
                reject(e);
            }
        })
    }

    const getAppleButton = () => {
        if(canUseAppleAuth !== true) {
            return null;
        }
        return (
            <Animated.View style={{
                position: 'relative',
                top: animations.buttons.apple.top,
                opacity: animations.buttons.apple.opacity
            }}>
                {loading === 'apple'
                    ?
                    <Button
                    color={Appearance.themeStyle() === 'dark' ? 'white' : 'black'}
                    loading={true}
                    label={'Continue with Apple'}
                    labelStyle={{
                        fontSize: 13.5
                    }}
                    style={{
                        marginBottom: 8,
                        borderRadius: 25,
                        width: '100%'
                    }}/>
                    :
                    <AppleAuthentication.AppleAuthenticationButton
                    onPress={onSignInWithApple}
                    buttonType={AppleAuthentication.AppleAuthenticationButtonType.CONTINUE}
                    buttonStyle={Appearance.themeStyle() === 'dark' ? AppleAuthentication.AppleAuthenticationButtonStyle.WHITE : AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
                    cornerRadius={17.5}
                    style={{
                        width: '100%',
                        height: 35,
                        marginBottom: 8,
                        borderRadius: 17.5,
                        overflow: 'hidden'
                    }} />
                }
            </Animated.View>
        )
    }

    const getContent = () => {

        if(Platform.OS === 'web' && Utils.isMobile() === true) {
            return (
                <View style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    backgroundColor: Appearance.colors.background()
                }}>
                    <Animated.View style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        top: animations.logo.top,
                        opacity: animations.logo.opacity,
                        padding: 20
                    }}>
                        <Image
                        source={utils.client.get().logos[Appearance.themeStyle()]}
                        style={{
                            height: 35,
                            width: '100%',
                            resizeMode: 'contain',
                            marginBottom: 8
                        }}/>
                        <Text style={{
                            ...Appearance.textStyles.title(),
                            display: 'flex',
                            ...Appearance.fontWeight.get(600),
                            color: Appearance.colors.subText(),
                            marginBottom: 25
                        }}>{utils.client.get().tagline}</Text>

                        <TouchableOpacity
                        activeOpacity={0.6}
                        onPress={onAppStorePress}
                        style={{
                            width: 225,
                            height: 40,
                            marginBottom: 8
                        }}>
                            <Image
                            source={require('eCarra/images/app-store-badge.png')}
                            style={{
                                width: '100%',
                                height: '100%',
                                resizeMode: 'contain'
                            }} />
                        </TouchableOpacity>
                        <TouchableOpacity
                        activeOpacity={0.6}
                        onPress={onGooglePlayPress}
                        style={{
                            width: 225,
                            height: 40
                        }}>
                            <Image
                            source={require('eCarra/images/google-play-badge.png')}
                            style={{
                                width: '100%',
                                height: '100%',
                                resizeMode: 'contain'
                            }} />
                        </TouchableOpacity>
                    </Animated.View>
                </View>
            )
        }

        if(Platform.OS === 'web' || Utils.isMobile() === false) {
            return (
                <TouchableWithoutFeedback
                onPress={() => Keyboard.dismiss()}
                style={{
                    height: '100%',
                    width: Screen.width()
                }}>
                    <View style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%'
                    }}>
                        <Animated.View
                        renderToHardwareTextureAndroid={true}
                        style={{
                            position: 'relative',
                            top: animations.lottie.top,
                            opacity: animations.lottie.opacity,
                            backgroundColor: Appearance.colors.background()
                        }}>
                            {logoAnimation && (
                                <LottieView
                                loop={true}
                                autoPlay={true}
                                duration={3500}
                                imageAssetsFolder={'lottie/logo-pulse'}
                                source={logoAnimation}
                                style={{
                                    ...getLogoProps(),
                                    maxWidth: Screen.layer.maxWidth,
                                    ...Platform.OS !== 'web' && {
                                        backgroundColor: Appearance.colors.background()
                                    }
                                }}/>
                            )}
                        </Animated.View>
                        <Animated.View
                        ref={tabletContainer}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            position: 'relative',
                            width: '100%',
                            maxWidth: 450,
                            top: animations.logo.top,
                            opacity: animations.logo.opacity,
                            backgroundColor: Appearance.colors.layerBackground(),
                            borderRadius: 20,
                            padding: 30
                        }}>
                            <View style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                marginBottom: 8,
                                marginTop: 10
                            }}>
                                <Image
                                source={utils.client.get().logos[Appearance.themeStyle()]}
                                style={{
                                    height: 40,
                                    width: '100%',
                                    resizeMode: 'contain',
                                    ...API.get_client_id() === 'ecarra' && {
                                        marginRight: 12,
                                        height: 35
                                    }
                                }}/>
                                <TouchableOpacity
                                activeOpacity={0.6}
                                onPress={onBackButtonPress}
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    width: 25,
                                    height: 25
                                }}>
                                    <LottieView
                                    ref={backButton}
                                    autoPlay={false}
                                    loop={false}
                                    source={Appearance.themeStyle() === 'dark' ? require('eCarra/files/lottie/back-button-white.json') : require('eCarra/files/lottie/back-button-grey.json')}
                                    duration={2500}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        opacity: channel === 'manual' ? 1:0
                                    }}/>
                                </TouchableOpacity>
                            </View>
                            <Text style={{
                                ...Appearance.textStyles.title(),
                                ...Appearance.fontWeight.get(700),
                                color: Appearance.colors.subText(),
                                marginTop: 2,
                            }}>{utils.client.get().tagline}</Text>
                            <View style={{
                                position: 'relative',
                                marginTop: 20,
                                width: '100%'
                            }}>
                                {getLoginComponents()}
                            </View>
                        </Animated.View>
                    </View>
                </TouchableWithoutFeedback>
            )
        }

        return (
            <TouchableWithoutFeedback
            onPress={() => Keyboard.dismiss()}
            style={{
                height: '100%',
                width: Screen.width()
            }}>
                <View style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    backgroundColor: Appearance.colors.background(),
                    paddingHorizontal: 15,
                    paddingTop: 15 + Screen.safeArea.top,
                    paddingBottom: 15
                }}>
                    <Animated.View
                    renderToHardwareTextureAndroid={true}
                    style={{
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        top: animations.lottie.top,
                        opacity: animations.lottie.opacity,
                        height: Screen.height(),
                        paddingBottom: 25,
                        backgroundColor: Appearance.colors.background()
                    }}>
                        {logoAnimation && (
                            <LottieView
                            loop={true}
                            autoPlay={true}
                            duration={3500}
                            imageAssetsFolder={'lottie/logo-pulse'}
                            source={logoAnimation}
                            style={{
                                ...getLogoProps(),
                                maxWidth: Screen.layer.maxWidth,
                                ...Platform.OS !== 'web' && {
                                    backgroundColor: Appearance.colors.background()
                                }
                            }}/>
                        )}
                    </Animated.View>

                    <View style={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}>
                        <Animated.View style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            top: animations.logo.top,
                            marginTop: 15,
                            width: '100%'
                        }}>
                            <View style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                alignItems: 'center',
                                height: 25
                            }}>
                                <TouchableOpacity
                                activeOpacity={0.6}
                                onPress={onBackButtonPress}
                                style={{
                                    width: 25,
                                    height: 25,
                                    marginLeft: 8
                                }}>
                                    <LottieView
                                    ref={backButton}
                                    autoPlay={false}
                                    loop={false}
                                    source={Appearance.themeStyle() === 'dark' ? require('eCarra/files/lottie/back-button-white.json') : require('eCarra/files/lottie/back-button-grey.json')}
                                    duration={2500}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        opacity: channel === 'manual' ? 1:0
                                    }}/>
                                </TouchableOpacity>

                                <View style={{
                                    flexGrow: 1
                                }}>
                                    <Image source={utils.client.get().logos[Appearance.themeStyle()]}
                                    style={{
                                        width: '100%',
                                        height: API.get_client_id() == 'ecarra' ? 25 : 30,
                                        resizeMode: 'contain',
                                        marginRight: 8
                                    }}/>
                                </View>

                                <View style={{
                                    width: 25,
                                    height: 25,
                                    marginLeft: 8
                                }} />

                            </View>
                            <Text style={{
                                fontSize: 13.5,
                                ...Appearance.fontWeight.get(700),
                                color: Appearance.colors.subText(),
                                marginTop: 6,

                            }}>{utils.client.get().tagline}</Text>
                        </Animated.View>
                    </View>
                    {getLoginComponents()}
                </View>
            </TouchableWithoutFeedback>
        )
    }

    const getDeviceInfo = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let deviceName = await DeviceInfo.getDeviceName();
                resolve({
                    version: VersionInfo.appVersion,
                    platform: Platform.OS === 'android' ? 'Android' : 'iOS',
                    system_version: DeviceInfo.getSystemVersion(),
                    device: {
                        id: DeviceInfo.getUniqueId(),
                        type: DeviceInfo.getModel(),
                        name: deviceName
                    }
                });
            } catch(e) {
                reject(e);
            }
        })
    }

    const getFacebookButton = () => {
        return (
            <Animated.View style={{
                position: 'relative',
                top: animations.buttons.facebook.top,
                opacity: animations.buttons.facebook.opacity
            }}>
                <Button
                color={'#4267B2'}
                onPress={onSignInWithFacebook}
                loading={loading === 'facebook'}
                label={'Continue with Facebook'}
                style={{
                    marginBottom: 8,
                    borderRadius: 5,
                    borderRadius: 17.5,
                    width: '100%'
                }}
                labelStyle={{
                    fontSize: 13.5
                }} />
            </Animated.View>
        )
    }

    const getGoogleButton = () => {
        if(canUseGoogleAuth !== true) {
            return null;
        }
        return (
            <Animated.View style={{
                position: 'relative',
                top: animations.buttons.google.top,
                opacity: animations.buttons.google.opacity
            }}>
                <Button
                color={'#4285F4'}
                onPress={onSignInWithGoogle}
                loading={loading === 'google'}
                label={'Continue with Google'}
                style={{
                    marginBottom: 8,
                    borderRadius: 5,
                    borderRadius: 17.5,
                    width: '100%'
                }}
                labelStyle={{
                    fontSize: 13.5
                }} />
            </Animated.View>
        )
    }

    const getLoginComponents = () => {

        if(channel === 'social') {
            if(Platform.OS === 'web' || Utils.isMobile() === false) {
                return (
                    <View style={{
                        width: '100%',
                        marginTop: 8
                    }}>
                        {getAppleButton()}
                        {getGoogleButton()}
                        {getFacebookButton()}
                        {getManualLoginButton()}
                    </View>
                )
            }
            return (
                <View style={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    paddingVertical: 15,
                    paddingBottom: Screen.safeArea.bottom + 15,
                    maxWidth: Screen.component.maxWidth
                }}>
                    {getAppleButton()}
                    {getGoogleButton()}
                    {getFacebookButton()}
                    {getManualLoginButton()}
                </View>
            )
        }

        if(Platform.OS === 'web' || Utils.isMobile() === false) {
            return (
                <Animated.View style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                }}>
                    {getManualLoginComponents()}
                </Animated.View>
            )
        }
        return (
            <Animated.View style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                position: 'absolute',
                bottom: animations.manual.container.bottom,
                backgroundColor: Appearance.colors.background(),
                paddingBottom: Screen.safeArea.bottom + 15,
                maxWidth: Screen.component.maxWidth
            }}>
                {getManualLoginComponents()}
            </Animated.View>
        )
    }

    const getLogoProps = () => {
        let width = Screen.width() - 30;
        let val = width > 300 ? 300 : width;
        return {
            height: val,
            width: val
        }
    }

    const getManualLoginButton = () => {
        return (
            <Animated.View style={{
                position: 'relative',
                top: animations.buttons.username.top,
                opacity: animations.buttons.username.opacity
            }}>
                <Button
                label={'Continue with Email Address'}
                color={Appearance.colors.grey()}
                onPress={() => onUpdateButtons('manual')}
                width={Screen.width() - 30}
                labelStyle={{
                    fontSize: 13.5
                }}
                style={{
                    width: '100%',
                    borderRadius: 17.5
                }} />
            </Animated.View>
        )
    }

    const getManualLoginComponents = () => {
        return (
            <>
            <Animated.View style={{
                top: animations.manual.fields.username.top,
                opacity: animations.manual.fields.username.opacity
            }}>
                <TextField
                icon={'user'}
                autoCapitalize={false}
                placeholder={'Username'}
                value={username}
                onChange={text => setUsername(text)}
                fieldStyle={{
                    color: Appearance.colors.text(),
                    fontSize: 12,
                    ...Appearance.fontWeight.get(500)
                }}/>
            </Animated.View>

            <Animated.View style={{
                top: animations.manual.fields.password.top,
                opacity: animations.manual.fields.password.opacity
            }}>
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    height: 35,
                    marginTop: 8
                }}>
                    <TextField
                    icon={'lock'}
                    isSecure={true}
                    placeholder={'Password'}
                    value={password}
                    onChange={text => setPassword(text)}
                    fieldStyle={{
                        color: Appearance.colors.text(),
                        fontSize: 12,
                        ...Appearance.fontWeight.get(500)
                    }}
                    containerStyle={{
                        flex: 1,
                        flexGrow: 1
                    }}/>
                    <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={onResetPassword}
                    style={{
                        width: 25,
                        height: 25,
                        marginLeft: 8
                    }}>
                        <Image
                        source={require('eCarra/images/help-button-grey.png')}
                        style={{
                            width: '100%',
                            height: '100%',
                            resizeMode: 'contain',
                            borderRadius: 12.5
                        }}/>
                    </TouchableOpacity>
                </View>
            </Animated.View>
            <Animated.View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 15,
                top: animations.manual.fields.button.top,
                opacity: animations.manual.fields.button.opacity
            }}>
                <View style={{
                    width: '50%',
                    paddingRight: 4
                }}>
                    <Button
                    label={'Sign Up'}
                    type={'large'}
                    color={'grey'}
                    onPress={onSignupPress}
                    labelStyle={{
                        fontSize: 13.5
                    }} />
                </View>
                <View style={{
                    width: '50%',
                    paddingLeft: 4
                }}>
                    <Button
                    label={'Login'}
                    type={'large'}
                    color={'primary'}
                    loading={loading}
                    onPress={onLoginPress}
                    labelStyle={{
                        fontSize: 13.5
                    }} />
                </View>
            </Animated.View>
            </>
        )
    }

    const runAutoLogin = async () => {
        try {

            // auto login if development environment is enabled
            if(API.dev_env) {
                let { device } = await getDeviceInfo();
                onLoginPress({
                    username: device.name === 'iPhone 12' ? 'mikecarp' : 'ecarpenter',
                    password: device.name === 'iPhone 12' ? 'redhouse10' : 'e504532'
                });
                return;
            }

            // automatically login using previous token if applicable
            // TODO => refresh token if applicable
            let token = await AsyncStorage.getItem(`${utils.client.get().id}_login`);
            if(!token) {
                onStartAnimations();
                return;
            }

            // do not present face id authentication if platform is not ios
            if(Platform.OS !== 'ios') {
                onLoginWithToken(token);
                return;
            }

            // validate login attempt using face id if applicable
            let result = await Permissions.check(PERMISSIONS.IOS.FACE_ID);
            if(result !== RESULTS.GRANTED) {
                onLoginWithToken(token);
                return;
            }

            // verify current user with face id if applicable
            let { success } = await ReactNativeBiometrics.simplePrompt({ promptMessage: 'Use Face ID to login to your account' });
            if(success) {
                onLoginWithToken(token);
                return;
            }

            // fallback to default login view
            onStartAnimations();

        } catch(e) {
            console.error(e.message);
        }
    }

    const setupAuthOptions = async () => {
        try {

            // set sign in with apple availability flag
            let apple = await AppleAuthentication.isAvailableAsync();
            setCanUseAppleAuth(apple);

            // set sign in with google availability flag and configure
            let google = await GoogleSignin.hasPlayServices();
            setCanUseGoogleAuth(google);
            GoogleSignin.configure({
                iosClientId: utils.client.get().apps.google.ios,
                ...Platform.OS === 'web' && {
                    api_key: 'AIzaSyCGHuD2zHJQI1ZUBy3LRlqT4FAVLiyCS2Q',
                    clientId: utils.client.get().apps.google.web,
                    webClientId: utils.client.get().apps.google.web,
                    scope: 'profile',
                    cookiePolicy: 'single_host_origin'
                }
            });

        } catch(e) {
            console.error(e.message);
            onStartAnimations();
        }
    }

    const setupKeyboardEvents = () => {
        utils.keyboard.subscribe(viewID, {
            onShow: (e) => {
                Animated.timing(animations.lottie.opacity, {
                    toValue: 0,
                    duration: 250,
                    useNativeDriver: false
                }).start();
                Animated.spring(animations.lottie.top, {
                    toValue: 25,
                    friction: 10,
                    duration: 250,
                    useNativeDriver: false
                }).start();
                if(Utils.isMobile() === false) {
                    tabletContainer.current.measure((ox, oy, width, height, px, py) => {
                        Animated.spring(animations.logo.top, {
                            toValue: (((Screen.height() / 2) - (e.endCoordinates.height / 2)) / 2) - height - (300 / 2),
                            duration: 100,
                            useNativeDriver: false
                        }).start();
                    });
                    return;
                }
                Animated.spring(animations.manual.container.bottom, {
                    toValue: e.endCoordinates.height - Screen.safeArea.bottom,
                    duration: 100,
                    useNativeDriver: false
                }).start();
            },
            onChange: (e) => {
                Animated.timing(animations.lottie.opacity, {
                    toValue: 0,
                    duration: 250,
                    useNativeDriver: false
                }).start();
                Animated.spring(animations.lottie.top, {
                    toValue: 25,
                    friction: 10,
                    duration: 250,
                    useNativeDriver: false
                }).start();
                if(Utils.isMobile() === false) {
                    tabletContainer.current.measure((ox, oy, width, height, px, py) => {
                        Animated.spring(animations.logo.top, {
                            toValue: (((Screen.height() / 2) - (e.endCoordinates.height / 2)) / 2) - height - (300 / 2),
                            duration: 100,
                            useNativeDriver: false
                        }).start();
                    });
                    return;
                }
                Animated.spring(animations.manual.container.bottom, {
                    toValue: e.endCoordinates.height - Screen.safeArea.bottom,
                    duration: 100,
                    useNativeDriver: false
                }).start();
            },
            onHide: (e) => {
                Animated.timing(animations.lottie.opacity, {
                    toValue: 1,
                    duration: 250,
                    useNativeDriver: false
                }).start();
                Animated.spring(animations.lottie.top, {
                    toValue: 0,
                    friction: 10,
                    duration: 250,
                    useNativeDriver: false
                }).start();
                if(Utils.isMobile() === false) {
                    Animated.spring(animations.logo.top, {
                        toValue: 0,
                        duration: 100,
                        useNativeDriver: false
                    }).start();
                    return;
                }
                Animated.spring(animations.manual.container.bottom, {
                    toValue: 0,
                    duration: 100,
                    useNativeDriver: false
                }).start();
            }
        });
    }

    useEffect(() => {
        runAutoLogin();
        onSetupClientIcon();
        setupAuthOptions();
        setupKeyboardEvents();
        return () => {
            utils.keyboard.unsubscribe(viewID);
        }
    }, [])

    return getContent()
}

const Signup = ({ preSignupProps, onSignup }, { index, options, utils }) => {

    const layerID = 'signup';
    const [abstract, setAbstract] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [primaryButton, setPrimaryButton] = useState('Continue');
    const [twoFactorText, setTwoFactorText] = useState(null);
    const [user, setUser] = useState(null);

    const onBeforeCloseOnTapPress = callback => {
        utils.alert.show({
            title: 'Just a Second',
            message: `It looks like you aren't finishsed setting up your new account. Are you sure you want to stop the signup process? Any unsaved account information will be lost.`,
            buttons: [{
                key: 'confirm',
                title: 'Leave Signup',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Continue with Signup',
                style: 'default'
            }],
            onPress: (key) => {
                if(key === 'confirm') {
                    if(typeof(callback) === 'function') {
                        callback();
                    }
                }
            }
        })
    }

    const onPrimaryButtonPress = async () => {

        // dismiss keyboard regardless of the next index
        Keyboard.dismiss();

        if(activeIndex === 0) {

            // require that first and last name were provided
            if(!user.first_name || !user.last_name) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: 'Please enter your first and last name before moving on.'
                });
                return;
            }

            // validate sign up code if a sign up code was provided
            if(user.tmp_signup_code) {
                try {
                    // fetch matching target for signup code
                    let { company, referred_by } = await onValidateSignupCode();
                    if(!company && !referred_by) {
                        utils.alert.show({
                            title: 'Oops!',
                            message: 'We were unable to find something associated with your signup code. Please check that you used the correct code or do not provide a signup code to continue.'
                        });
                        return;
                    }

                    // update target with referral results and advance to next index
                    let edits = abstract.object.set({
                        company: company,
                        referred_by: referred_by
                    });
                    setUser(edits);
                    setActiveIndex(activeIndex => activeIndex + 1);

                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue verifying your sign up code. ${e.message || 'An unknown error occurred'}`
                    });
                }
                return;
            }

            setActiveIndex(activeIndex => activeIndex + 1);
            return;
        }

        if(activeIndex === 1) {
            try {
                // require that username and password were provided
                if(!user.username || !user.password) {
                    utils.alert.show({
                        title: 'Just a Second',
                        message: 'Please choose a username and password before moving on.'
                    });
                    return;
                }

                // verify that desired username is available
                let available = await isUsernameAvailable();
                if(!available) {
                    utils.alert.show({
                        title: 'Username Not Available',
                        message: `It looks like someone has already chosen the username "${user.username.toLowerCase()}"`
                    });
                    return;
                }

                // advance to next index
                setActiveIndex(activeIndex => activeIndex + 1);

            } catch(e) {
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue checking if your username is available. ${e.message || 'An unknown error occurred'}`
                });
            }
            return;
        }

        if(activeIndex === 2) {
            try {
                // require that email address and phone number were provided
                if(!user.email_address || !user.phone_number) {
                    utils.alert.show({
                        title: 'Just a Second',
                        message: 'Please enter your email address and phone number before moving on'
                    });
                    return;
                }

                // verify that a valid address and location was provided
                if(!user.address || !user.city || !user.state || !user.zipcode || !user.location) {
                    utils.alert.show({
                        title: 'Just a Second',
                        message: `Please enter your address before moving on. We'll show you a list of matches once you type in your address. Choose a match from the results to continue setting up your account.`
                    });
                    return;
                }

                // send verification email and advance index
                await onSendVerificationEmail();
                setPrimaryButton('Done');
                setActiveIndex(activeIndex => activeIndex + 1);

            } catch(e) {
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue sending your email verification. ${e.message || 'An unknown error occurred'}`
                })
            }
            return;
        }

        if(activeIndex === 3) {

            // require that a verification code was provided before moving on
            if(!twoFactorText || twoFactorText.length !== 6) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: `Please enter your verification code before moving on.`
                });
                return;
            }

            // check if verification code is valid
            try {
                await onVerifyNewUserCode();
            } catch(e) {
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue verifying your code. ${e.message || 'An unknown error occurred'}`
                });
                return;
            }

            // prepare token props from social signup if applicable
            let socialProps = {};
            if(preSignupProps && preSignupProps.service && preSignupProps.token) {
                switch(preSignupProps.service) {
                    case 'apple':
                    socialProps.apple_token = preSignupProps.token;
                    break;

                    case 'google':
                    socialProps.google_token = preSignupProps.token;
                    break;

                    case 'facebook':
                    socialProps.facebook_token = preSignupProps.token;
                    break;
                }
            }

            // submit user details to server for acconut creation
            try {
                await onSubmitSignup(socialProps);
                setLayerState('close');
                if(typeof(onSignup) === 'function') {
                    onSignup({
                        ...socialProps,
                        username: user.username,
                        password: user.password
                    });
                    return;
                }

            } catch(e) {
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue completing your sign up. ${e.message || 'An unknown error occurred'}`
                });
            }
        }
    }

    const onScanSignupCode = () => {
        utils.qrCamera.show({
            onScan: response => {
                if(!response || !response.referral_code) {
                    return;
                }
                onUpdateTarget('tmp_signup_code', response.referral_code);
            }
        })
    }

    const onSecondaryButtonPress = () => {
        if(activeIndex === 0) {
            return;
        }
        setLoading(false);
        setPrimaryButton('Continue');
        setActiveIndex(activeIndex => activeIndex - 1);
    }

    const onSendVerificationEmail = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let response = await Request.post(utils, '/user/', {
                    type: 'new_user_code',
                    email_address: user.email_address
                });

                setLoading(false);
                resolve(response);

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const onSubmitSignup = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let response = await Request.post(utils, '/user/', {
                    type: 'new',
                    first_name: user.first_name,
                    last_name: user.last_name,
                    company: user.company ? user.company.id : null,
                    username: user.username,
                    password: user.password,
                    level: user.level,
                    location: user.location && {
                        lat: user.location.latitude,
                        long: user.location.longitude
                    },
                    address: user.address,
                    city: user.city,
                    state: user.state,
                    zipcode: user.zipcode,
                    email_address: user.email_address,
                    phone_number: user.phone_number,
                    referred_by: user.referred_by,
                    ...props
                });

                setLoading(false);
                abstract.object.user_id = response.user_id;
                abstract.object.api_key = response.api_key;
                resolve();

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const onUpdateLocation = async place => {
        try {
            let { result } = await Utils.geocode(utils, place.location);
            let edits = abstract.object.set({
                ...result,
                location: place.location
            });
            setUser(edits);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue location some information for your address. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = (key, value) => {
        let edits = abstract.object.set({ [key]: value })
        setUser(edits);
    }

    const onValidateSignupCode = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let response = await Request.get(utils, '/user/', {
                    type: 'validate_signup_code',
                    code: user.tmp_signup_code
                });
                setLoading(false);
                resolve(response);

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const onVerifyNewUserCode = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { verified } = await Request.post(utils, '/user/', {
                    type: 'verify_new_user_code',
                    code: twoFactorText,
                    email_address: user.email_address
                });

                if(!verified) {
                    throw new Error('Please check that you used the correct code');
                }
                setLoading(false);
                resolve(verified);

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const isUsernameAvailable = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { available } = await Request.get(utils, '/user/', {
                    type: 'username_available_quiet',
                    username: user.username
                });
                setLoading(false);
                resolve(available);

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const getButtons = () => {
        if(activeIndex > 0) {
            return [{
               key: 'secondary',
               text: 'Back',
               color: 'grey',
               onPress: onSecondaryButtonPress
           },{
               key: 'primary',
               text: primaryButton,
               color: 'primary',
               loading: loading,
               onPress: onPrimaryButtonPress
           }]
        }

        return [{
            key: 'primary',
            text: primaryButton,
            color: 'primary',
            loading: loading,
            onPress: onPrimaryButtonPress
        }]
    }

    const getFields = () => {
        return [{
            title: `Welcome to ${utils.client.get().name}`,
            message: `Let's get to know each other. \nWhat's your name and were you given a signup code by a friend or colleague?`,
            fields: [{
                key: 'first_name',
                label: 'First Name',
                icon: 'user',
                textContentType: 'givenName',
                autoCompleteType: 'name'
            },{
                key: 'last_name',
                label: 'Last Name',
                icon: 'user',
                textContentType: 'familyName',
                autoCompleteType: 'name'
            },{
                key: 'tmp_signup_code',
                label: 'Signup Code (optional)',
                icon: 'lock',
                textContentType: 'none',
                autoCorrect: false,
                autoCapitalize: false,
                accessory: 'qrCamera'
            }]
        },{
            title: `Nice to Meet You ${user.first_name}!`,
            message: `Please choose your username and password. \nThis will be your login for all things ${utils.client.get().name}.`,
            fields: [{
                key: 'username',
                label: 'Username',
                icon: 'user',
                autoCapitalize: false,
                textContentType: 'username',
                autoCompleteType: 'username'
            },{
                key: 'password',
                label: 'Password',
                icon: 'lock',
                secure: true,
                textContentType: 'newPassword',
                autoCompleteType: 'password'
            }]
        },{
            title: 'How Do We Contact You?',
            message: `What's your email address, phone number, and physical address? \nWe promise we won't bug you.`,
            fields: [{
                key: 'email_address',
                label: 'Email Address',
                icon: 'email',
                keyboardType: 'email-address',
                autoCompleteType: 'email',
                textContentType: 'emailAddress',
                autoCapitalize: false
            },{
                key: 'phone_number',
                label: 'Phone Number',
                icon: 'phone',
                format: 'phone_number',
                autoCompleteType: 'tel',
                textContentType: 'telephoneNumber'
            },{
                key: 'address',
                label: 'Address',
                icon: 'location',
                textContentType: 'streetAddressLine1',
                autoCompleteType: 'street-address'
            }]
        },{
            title: 'Verify your Account',
            message: `We have sent a verification code to "${user ? user.email_address : 'email address not available'}". \nPlease enter the verification code below to finish setting up your account. Check you junk or spam folder if you do not see an email from ${utils.client.get().name}.`,
            fields: [{
                key: 'verificationCode',
                label: 'Verification Code',
                icon: 'lock',
                textContentType: 'oneTimeCode'
            }]
        }];
    }

    const getFieldComponents = () => {

        // field key is used as the component key to ensure that autofill options do not persist through cycle updates
        // using the index may cause a component in the next render with the same index to have incorrect autofill props

        let targets = getFields();
        return targets[activeIndex].fields.map((field, index, fields) => {
            if(field.key === 'verificationCode') {
                return (
                    <View
                    key={field.key}
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        paddingHorizontal: 12,
                        marginBottom: 20
                    }}>
                        {getTwoFactorCodeField()}
                    </View>
                )
            }
            if(field.key === 'address') {
                return (
                    <AddressLookupField
                    key={field.key}
                    icon={field.icon}
                    utils={utils}
                    autoCorrect={false}
                    autoComplete={field.autoComplete}
                    spellCheck={false}
                    placeholder={'Address'}
                    value={Utils.formatAddress(user)}
                    onChange={onUpdateLocation}
                    fieldStyle={{
                        fontSize: 12,
                        fontWeight: 500,
                    }}/>
                )
            }
            return (
                <View
                key={field.key}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    marginBottom: index !== fields.length - 1 ? 8 : 0
                }}>
                    <TextField
                    {...field}
                    isSecure={field.secure}
                    autoComplete={true}
                    placeholder={field.label}
                    value={user[field.key]}
                    onChange={text => onUpdateTarget(field.key, text)}
                    containerStyle={{
                        minWidth: 0,
                        flexGrow: 1,
                        width: 'auto'
                    }}/>
                    {Platform.OS !== 'web' && field.accessory === 'qrCamera' && (
                        <TouchableOpacity
                        activeOpacity={0.6}
                        onPress={onScanSignupCode}
                        style={{
                            width: 30,
                            height: 30,
                            backgroundColor: Appearance.colors.grey(),
                            borderRadius: 15,
                            overflow: 'hidden',
                            padding: 4,
                            marginLeft: 8
                        }}>
                            <LottieView
                            autoPlay={true}
                            loop={true}
                            duration={2500}
                            source={require('eCarra/files/lottie/quick-scan-white.json')}
                            style={{
                                width: '100%',
                                height: '100%'
                            }}/>
                        </TouchableOpacity>
                    )}
                </View>
            )
        })
    }

    const getTwoFactorCodeField = () => {
        if(Platform.OS === 'web') {
            return (
                <TextField
                value={twoFactorText}
                placeholder={'Type your code here...'}
                onChange={text => setTwoFactorText(text)}
                keyboardType={'number-pad'}
                textContentType={'oneTimeCode'} />
            )
        }
        return (
            <CodeField
            cellCount={6}
            value={twoFactorText}
            onChangeText={text => setTwoFactorText(text)}
            keyboardType={'number-pad'}
            textContentType={'oneTimeCode'}
            rootStyle={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            renderCell={({index, symbol, isFocused}) => (
                <View key={index} style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginHorizontal: 4,
                    backgroundColor: Appearance.colors.textField(),
                    borderRadius: 8,
                    borderWidth: 1,
                    borderColor: 'rgba(175,175,175,0)',
                    height: 40,
                    width: 35,
                    minWidth: 35,
                    overflow: 'hidden'
                }}>
                    <Text style={{
                        width: 35,
                        fontSize: 18,
                        ...Appearance.fontWeight.get(600),
                        color: Appearance.colors.text(),
                        textAlign: 'center'
                    }}>
                        {symbol || (isFocused ? <Cursor /> : null)}
                    </Text>
                </View>
            )} />
        )
    }

    const setupTarget = async () => {
        try {
            let abstract = Abstract.create({
                type: 'users',
                object: User.new()
            });
            setAbstract(abstract);

            abstract.object.open();
            let edits = abstract.object.set({ ...preSignupProps });
            setUser(edits);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue starting the signup process. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setupTarget();
    }, [])

    return (
        <Layer
        id={layerID}
        title={'Signup'}
        index={index}
        utils={utils}
        buttons={getButtons()}
        options={{
            ...options,
            bottomCard: true,
            beforeCloseOnTap: onBeforeCloseOnTapPress,
            layerState: layerState
        }}>
            {user && (
                <>
                <View style={{
                    position: 'relative',
                    width: '100%',
                    marginBottom: 20
                }}>
                    <Text style={{
                        ...Appearance.textStyles.panelTitle(),
                        marginBottom: 8,
                        textAlign: 'center'
                    }}>
                        {getFields()[activeIndex].title}
                    </Text>
                    <Text style={{
                        ...Appearance.textStyles.subTitle(),
                        textAlign: 'center',
                    }}>
                        {getFields()[activeIndex].message}
                    </Text>
                </View>
                <View style={{
                    position: 'relative',
                    width: '100%'
                }}>
                    {getFieldComponents()}
                </View>
                </>
            )}
        </Layer>
    )
}

export default LoginCard;
