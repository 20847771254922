import moment from 'moment';

import API from 'eCarra/files/api.js'
import Abstract from 'eCarra/classes/Abstract.js';
import Appearance from 'eCarra/styles/Appearance.js';
import { DriverTip } from 'eCarra/managers/Payments.js';
import { Messaging } from 'eCarra/managers/Messages.js';
import Order from 'eCarra/classes/Order.js';
import { Platform } from 'react-native';
import Request from 'eCarra/files/Request/';
import Reservation from 'eCarra/classes/Reservation.js';
import StatusCodes from 'eCarra/files/StatusCodes.js';
import { TripNavigator } from 'eCarra/managers/Reservations.js';
import User from 'eCarra/classes/User.js';
import Utils from 'eCarra/files/Utils.js';

class NotificationClass {

    id = null;
    title = null;
    message = null;
    date = null;
    read = null;
    globally_read = null;
    needs_approval = null;
    category = null;
    user_info = null;
    for_level = null;
    from_user = null;
    attachment = null;

    reservation = null;
    tmp_reservation = null;

    order = null;
    tmp_order = null;

    subscription = null;
    tmp_subscription = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.id = props.id;
        this.title = props.title;
        this.message = props.message;
        this.date = props.date ? moment(props.date) : null;
        this.read = props.read;
        this.for_level = props.for_level;
        this.globally_read = props.globally_read;
        this.needs_approval = props.needs_approval;
        this.category = props.category;
        this.attachment = props.attachment;
        this.tmp_order = props.user_info ? props.user_info.order_id : null;
        this.tmp_reservation = props.user_info ? props.user_info.reservation_id : null;
        this.tmp_subscription = props.user_info ? props.user_info.subscription_id : null;
        this.from_user = props.from_user ? User.create(props.from_user) : null;

        // setup user info props
        // android props are sent as json string due to firebase restrictions
        this.user_info = props.user_info;
        if(typeof(props.user_info) === 'string') {
            try {
                this.user_info = JSON.parse(props.user_info);
            } catch(e) {
                console.error(e.message);
            }
        }

        // from user details may be sent in the user_info payload
        if(this.user_info && this.user_info.from_user) {
            this.from_user = User.create(this.user_info.from_user);
        }

        return this;
    }

    createReservation = async utils => {
        return new Promise(async (resolve ,reject) => {
            try {
                if(this.reservation) {
                    resolve(this.reservation);
                    return;
                }

                if(!this.tmp_reservation) {
                    throw new Error('No reservation was found for this notification');
                    return;
                }

                this.reservation = await Reservation.get(utils, this.tmp_reservation);
                resolve(this.reservation);

            } catch(e) {
                reject(e);
            }
        })
    }

    createOrder = async utils => {
        return new Promise(async (resolve ,reject) => {
            try {
                if(this.order) {
                    resolve(this.order);
                    return;
                }

                if(!this.tmp_order) {
                    throw new Error('No order was found for this notification');
                    return;
                }

                this.order = await Order.get(utils, this.tmp_order);
                resolve(this.order);

            } catch(e) {
                reject(e);
            }
        })
    }

    markAsHidden = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/user/', {
                    type: 'notification_hide',
                    notification_id: this.id
                })
            } catch(e) {
                reject(e);
            }
        })
    }

    overview = () => {

        if(['NEW_RIDE', 'UPDATED_RIDE'].includes(this.category)) {
            return !this.needs_approval ? {
                title: this.tmp_reservation.status ? 'Approved' : 'Rejected',
                message: 'This Reservation has already been ' + (this.tmp_reservation.status ? 'approved' : 'rejected') + ' by the system or another Administrative user',
                items: [{
                    key: 'view',
                    title: 'View Reservation',
                    style: 'default'
                }]
            } : {
                title: 'Needs Approval',
                message: 'This Reservation is currently pending admin approval',
                items: [{
                    key: 'approve',
                    title: 'Approve',
                    style: 'default'
                },{
                    key: 'decline',
                    title: 'Decline',
                    style: 'destructive'
                },{
                    key: 'view',
                    title: 'View Reservation',
                    style: 'default'
                }]
            }
        }

        return {
            items: [{
                key: 'view',
                title: 'View Reservation',
                style: 'default'
            }]
        }
    }

    contentOptions = (utils, type) => {

        let order_id = this.user_info ? this.user_info.order_id : null;
        let reservation_id = this.user_info ? this.user_info.reservation_id : null;

        // setup for alert or sheet
        let buttons = [];
        if(this.user_info && this.user_info.support_options) {
            buttons.push({
                key: 'support',
                title: 'Get Support',
                style: 'default'
            });
        }

        let category = this.category || (this.user_info ? this.user_info.push_category : null);
        switch(category) {
            case 'DRIVER_IN_ROUTE_TO_PICKUP':
            buttons.push({
                key: 'trip-navigator',
                title: 'Open Trip Navigator',
                style: 'default'
            });
            break;

            case 'DRIVER_TIP_REMINDER':
            buttons.push({
                key: 'driver_tip',
                title: 'Add a Tip',
                style: 'default'
            });
            break;

            case 'NEW_MESSAGE':
            buttons.push({
                key: 'reply',
                title: 'Reply',
                style: 'default'
            });
            break;
        }

        if(this.tmp_order) {
            buttons.push({
                key: 'order',
                title: 'View Order',
                style: 'default'
            })
        }
        if(this.tmp_reservation) {
            buttons.push({
                key: 'reservation',
                title: 'View Reservation',
                style: 'default'
            })
        }
        if(this.tmp_subscription) {
            buttons.push({
                key: 'subscription',
                title: 'View Subscription',
                style: 'default'
            })
        }
        if(this.user_info && this.user_info.data) {
            if(this.user_info.data.url) {
                buttons.push({
                    key: 'url',
                    title: `Open Link`,
                    style: 'default'
                })
            }
        }
        if(type === 'alert') {
            buttons = buttons.concat([{
                key: 'hide',
                title: 'Mark as Hidden',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Dismiss',
                style: 'cancel'
            }])
        }

        let callbacks = async key => {
            if(key === 'hide') {
                try {
                    await this.markAsHidden(utils);
                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue hiding this notification. ${e.message || 'An unknown error occurred'}`
                    });
                }
                return;
            }
            if(key === 'support') {
                Utils.showSupportOptions(utils, {
                    message: `I have a question about a notification that I received. The notification was called "${this.title}" and I received it on ${moment(this.date).format('MMMM Do, YYYY [at] h:mma')}. Notification ID N${this.id}`
                });
                return;
            }
            if(key === 'driver_tip') {
                let driverID = this.user_info ? this.user_info.driver_id : null;
                if(!driverID) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue locating the driver for this ${reservation_id ? 'Reservation' : 'Order'}`
                    });
                    return;
                }
                try {
                    let { abstract } = await Utils.create(utils, {
                        id: reservation_id || order_id,
                        type: reservation_id ? 'reservations' : 'orders'
                    });
                    utils.layer.open({
                        id: `driver-tip-${driverID}`,
                        abstract: abstract,
                        Component: DriverTip.bind(this, {
                            driverID: driverID,
                            shouldProcess: true
                        })
                    })
                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue locating this ${reservation_id ? 'Reservation' : 'Order'}. ${e.message || 'An unknown error occurred'}`
                    })
                }
                return;
            }
            if(key === 'feedback') {
                try {
                    let { abstract } = await Utils.create(utils, {
                        id: reservation_id || order_id,
                        type: reservation_id ? 'reservations' : 'orders'
                    });
                    utils.quickFeedback.show({ abstract: abstract });

                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue locating this ${reservation_id ? 'Reservation' : 'Order'}. ${e.message || 'An unknown error occurred'}`
                    })
                }
                return;
            }
            if(key === 'reply') {
                try {
                    let object = null;
                    if(this.user_info.order_id) {
                        let target = await this.createOrder(utils);
                        object = Abstract.create({
                            type: 'orders',
                            object: target
                        });
                    }
                    if(this.user_info.reservation_id) {
                        let target = await this.createReservation(utils);
                        object = Abstract.create({
                            type: 'reservations',
                            object: target
                        });
                    }
                    if(!object) {
                        throw new Error('Missing object information in the request');
                    }
                    utils.layer.openFloating({
                        id: `messages-${object.getTag()}`,
                        abstract: object,
                        Component: Messaging
                    });

                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue preparing your messages. ${e.message || 'An unknown error occurred'}`
                    })
                }
                return;
            }
            if(key === 'trip-navigator') {
                try {
                    if(!order_id && !reservation_id) {
                        throw new Error('No order or reservation found');
                        return;
                    }
                    let { abstract } = await Utils.create(utils, {
                        id: reservation_id || order_id,
                        type: reservation_id ? 'reservations' : 'orders'
                    });
                    utils.layer.openFloating({
                        id: `trip-navigator-${abstract.getTag()}`,
                        abstract: abstract,
                        Component: TripNavigator
                    });

                } catch(e) {
                    console.error(e.message);
                }
                return;
            }
            if(key === 'url') {
                utils.layer.webView({
                    id: `notification-${this.id}`,
                    title: this.title,
                    url: this.user_info.data.url
                });
                return;

            }
            if([ 'order', 'reservation', 'subscription' ].includes(key)) {
                try {
                    let { order, reservation, subscription } = await Utils.createMultiple(utils, {
                        order_id: this.tmp_order,
                        reservation_id: this.tmp_reservation,
                        subscriptionID: this.tmp_subscription
                    });

                    if(order) {
                        utils.layer.order.details(order);
                        return;
                    }
                    if(reservation) {
                        utils.layer.reservation.details(reservation);
                        return;
                    }
                    if(subscription) {
                        utils.layer.subscription.details(subscription);
                        return;
                    }

                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue retrieving your notification details. ${e.message || 'An unknown error occurred'}`
                    })
                }
            }
            return
        };

        switch(type) {
            case 'notification':
            return {
                title: this.title,
                message: this.message,
                icon: {
                    path: this.from_user && this.from_user.user_id !== 1 ? this.from_user.avatar : this.getIcon(),
                    style: {
                        backgroundColor: Appearance.colors.primary()
                    }
                },
                onPress: async key => {
                    setTimeout(callbacks.bind(this, key), 0.25);
                }
            }

            case 'alert':
            utils.alert.show({
                title: this.title,
                message: this.message,
                icon: {
                    path: this.from_user && this.from_user.user_id !== 1 ? this.from_user.avatar : this.getIcon(),
                    style: {
                        backgroundColor: Appearance.colors.primary()
                    }
                },
                buttons: buttons,
                onPress: callbacks
            })
            break;

            case 'sheet':
            utils.sheet.show({
                title: this.title,
                message: this.message,
                icon: {
                    path: this.from_user && this.from_user.user_id !== 1 ? this.from_user.avatar : this.getIcon(),
                    style: {
                        backgroundColor: Appearance.colors.primary()
                    }
                },
                items: buttons
            }, callbacks);
            break;
        }
    }

    getIcon = () => {

        let category = this.category || (this.user_info ? this.user_info.push_category : null);
        let icon = require('eCarra/images/notification-icon-grey-small.png');
        if(this.user_info && this.user_info.icon) {
            icon = { uri: this.user_info.icon };

        } else if(this.from_user && this.from_user.user_id !== 1) {
            icon = this.from_user.avatar;

        } else if(category === 'NEW_RIDE' || category === 'UPDATED_RIDE') {
            icon = require('eCarra/images/checkmark-button-clear-small.png');

        } else if(category === 'DRIVER_TIP_REMINDER') {
            icon = this.from_user ? this.from_user.avatar : require('eCarra/images/payment-icon-clear-small.png');

        } else if(category === 'DRIVER_IN_ROUTE_TO_PICKUP') {
            icon = require('eCarra/images/active-icon-blue-small.png');

        } else if(category === 'ORDER_STATUS_CHANGE') {
            icon = this.user_info.approved ? require('eCarra/images/checkmark-button-clear-small.png') : require('eCarra/images/rejected-red-small.png');

        } else if(category === 'RESERVATION_STATUS_CHANGE') {
            icon = this.user_info.approved ? require('eCarra/images/checkmark-button-clear-small.png') : require('eCarra/images/rejected-red-small.png');

        } else if(category === 'SUBSCRIPTION_STATUS_CHANGE') {
            icon = this.user_info.approved && this.user_info.status !== StatusCodes.subscriptions.cancelled ? require('eCarra/images/checkmark-button-clear-small.png') : require('eCarra/images/rejected-red-small.png');

        } else if(category === 'VEHICLE_APPLICATION_STATUS_CHANGE') {
            if(this.user_info.status !== StatusCodes.voApp.infoNeeded) {
                icon = this.user_info.approved ? require('eCarra/images/checkmark-button-clear-small.png') : require('eCarra/images/rejected-red-small.png');
            }
        } else if(category === 'DRIVER_APPLICATION_STATUS_CHANGE') {
            if(this.user_info.status !== StatusCodes.doApp.infoNeeded) {
                icon = this.user_info.approved  ? require('eCarra/images/checkmark-button-clear-small.png') : require('eCarra/images/rejected-red-small.png');
            }

        } else if(category === 'FEEDBACK_RIDE') {
            icon = require('eCarra/images/message-icon-clear-small.png');

        } else if(category === 'FEEDBACK_REMINDER') {
            icon = require('eCarra/images/feedback-icon-grey-small.png');

        } else if(category === 'COMPANY_GENERAL' || category === 'COMPANY_ADDED') {
            icon = require('eCarra/images/company-icon-clear-small.png');

        } else if(category === 'DRIVER_TIP_ADDED') {
            icon = require('eCarra/images/payment-icon-clear-small.png');

        } else if(category === 'RESERVATION_AUTHORIZATION_ERROR') {
            icon = require('eCarra/images/rejected-red-small.png');

        } else if(category === 'GENERAL') {
            icon = require('eCarra/images/notification-icon-grey-small.png');

        } else if(category === 'NEW_ORDER') {
            icon = require('eCarra/images/order-category-icon.png');

        } else if(category === 'CHARGE_ISSUE') {
            icon = require('eCarra/images/rejected-red-small.png');

        } else if(category === 'CHARGE_PROCESSED') {
            icon = require('eCarra/images/payment-icon-clear-small.png');

        } else if(category === 'NEW_DRIVER_APPLICATION' || category === 'DRIVER_APPLICATION') {
            icon = require('eCarra/images/driver-application-icon.png');

        } else if(category === 'NEW_VEHICLE_APPLICATION' || category === 'VEHICLE_APPLICATION') {
            icon = require('eCarra/images/vehicle-application-icon.png');
        }

        return icon;
    }
}
export default {
    create: props => new NotificationClass().create(props)
};
