import React from 'react';
import API from 'eCarra/files/api.js';

const Request = {
    get: async (utils, url, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await fetch(buildRequest(`${API.url}${url}`, props), {
                    method: 'GET',
                    withCredentials: true,
                    headers: utils.api.headers()
                });

                if(response.status !== 200) {
                    let text = await response.text();
                    throw new Error(text || 'An unknown error occurred');
                    return;
                }

                let json = await response.json();
                resolve(json);

            } catch(e) {
                reject(e);
            }
        })
    },
    post: async (utils, url, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await fetch(`${API.url}${url}`, {
                    method: 'POST',
                    withCredentials: true,
                    headers: utils.api.headers(),
                    body: JSON.stringify(props)
                });

                if(response.status !== 200) {
                    let text = await response.text();
                    throw new Error(text || 'An unknown error occurred');
                    return;
                }

                let json = await response.json();
                resolve(json);

            } catch(e) {
                reject(e);
            }
        })
    }
}
export default Request;

export const buildRequest = (url, props) => {
    let next = `${url}?type=${props.type}`;
    for(var i in props) {
        if(i == 'type') {
            continue;
        }
        next += `&${i}=${typeof(props[i]) === 'object' ? JSON.stringify(props[i]) : encodeURIComponent(props[i])}`;
    }
    return next;
}
