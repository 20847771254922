import React from 'react';
import { View, Platform } from 'react-native';

import Appearance from 'eCarra/styles/Appearance.js';
import InsetShadow from 'react-native-inset-shadow';

const InnerShadow = ({ children, light, dark, style }) => {

    return Platform.OS === 'android' ? children : (
        <InsetShadow
        top={false}
        left={false}
        shadowOpacity={dark && dark.opacity ? dark.opacity : 0.25}
        shadowColor={dark && dark.color ? dark.color : 'black'}
        shadowOffset={dark && dark.offset ? dark.offset : 15}
        shadowRadius={dark && dark.radius ? dark.radius : 10}>
            <InsetShadow
            right={false}
            bottom={false}
            shadowOpacity={light && light.opacity ? light.opacity : (Appearance.themeStyle() === 'dark' ? 0.25 : 0.50)}
            shadowColor={light && light.color ? light.color : 'white'}
            shadowOffset={light && light.offset ? light.offset : 15}
            shadowRadius={light && light.radius ? light.radius : 10}>
                {children}
            </InsetShadow>
        </InsetShadow>
    )
}

export default InnerShadow;
