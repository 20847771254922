export default {
    onNotification: () => {},
    onRegister: () => {},
    checkNotificationPermission: async () => {
        return new Promise((resolve, reject) => {
            console.warn('Push notifications are not implemented for web');
            resolve();
        })
    },
    requestPermissions: async () => {
        return new Promise((resolve, reject) => {
            console.warn('Push notifications are not implemented for web');
            resolve();
        })
    }
}
