import React, { useEffect, useState } from 'react';
import { Animated, Image, ScrollView, Text, View } from 'react-native';

import { onStartOrder } from 'eCarra/managers/Orders.js';
import { onStartRide } from 'eCarra/managers/Reservations.js';
import update from 'immutability-helper';

import Appearance from 'eCarra/styles/Appearance';
import Abstract from 'eCarra/classes/Abstract.js';
import Dots from 'eCarra/files/lottie/dots-white.json';
import LottieView from 'eCarra/views/Lottie/';
import { Map } from 'eCarra/views/Maps/';
import Request from 'eCarra/files/Request/';
import Screen from 'eCarra/files/Screen.js';
import SocketHelper from 'eCarra/files/SocketHelper.js';
import TouchableHighlight from 'eCarra/views/TouchableHighlight/';
import Utils from 'eCarra/files/Utils.js';

const OnDemandNew = ({ customer, id, location, onClose, target_type, utils }) => {

    const [bottom, setBottom] = useState(new Animated.Value(0));
    const [opacity, setOpacity] = useState(new Animated.Value(0));
    const [scale, setScale] = useState(new Animated.Value(0));

    const [abstract, setAbstract] = useState(null);
    const [distance, setDistance] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingSource, setLoadingSource] = useState(Dots);
    const [visible, setVisible] = useState(false);

    const alertButtons = [{
        key: 'start',
        title: 'Start Ride',
        style: 'default'
    },{
        key: 'view',
        title: 'View Order',
        style: 'default',
        visible: target_type === 'orders'
    },{
        key: 'view',
        title: 'View Reservation',
        style: 'default',
        visible: target_type === 'reservations'
    },{
        key: 'cancel',
        title: 'Dismiss',
        style: 'cancel'
    }]

    const closeAlert = callback => {
        Animated.spring(scale, {
            toValue: 0,
            duration: 150,
            useNativeDriver: false
        }).start();
        Animated.timing(opacity, {
            toValue: 0,
            duration: 150,
            useNativeDriver: false
        }).start(() => {
            setVisible(false);
            if(typeof(onClose) === 'function') {
                onClose();
            }
        });
    }

    const startTarget = async () => {
        return new Promise(async (resolve, reject) => {
            try {

                // notify customer that ride has been started
                await Request.post(utils, '/reservation/', {
                    type: 'start_on_demand_ride',
                    ...abstract.type === 'orders' && {
                        order_id: abstract.getID()
                    },
                    ...abstract.type === 'reservations' && {
                        reservation_id: abstract.getID()
                    }
                });

                // start target object
                switch(target_type) {
                    case 'orders':
                    await onStartOrder(utils, abstract);
                    break;

                    case 'reservations':
                    await onStartRide(utils, abstract);
                    break;

                    default:
                    throw new Error(`Unsupported abstract type "${abstract.type}"`);
                }

                closeAlert();
                resolve();

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const onButtonPress = async key => {
        try {
            if(key === 'start') {
                setLoading(key);
                await startTarget();
                return;
            }
            if(key === 'view') {
                closeAlert();
                switch(target_type) {
                    case 'orders':
                    utils.layer.order.details(abstract.object, {
                        onStartOrder: startTarget
                    });
                    break;

                    case 'reservations':
                    utils.layer.reservation.details(abstract.object, {
                        onStartRide: startTarget
                    });
                    break;
                }
                return;
            }
            closeAlert();

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue starting this on demand ride. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const setupAbstract = async () => {
        try {
            if(!customer || customer.user_id === utils.user.get().user_id) {
                throw new Error('target booked by user');
            }
            let { order, reservation } = await Utils.create(utils, {
                id: id,
                type: target_type
            });
            let target = Abstract.create({
                type: target_type,
                object: order || reservation
            });
            setAbstract(target);

        } catch(e) {
            closeAlert();
            console.error(e.message);
        }
    }

    const setupLottieSource = () => {
        let source = Utils.replaceLottieColor(Dots, '1,1,1', Appearance.colors.primary());
        setLoadingSource(source);
    }

    useEffect(() => {
        if(abstract) {
            setVisible(true);
            setTimeout(() => {
                Animated.spring(scale, {
                    toValue: 1,
                    duration: 500,
                    useNativeDriver: false
                }).start();
                Animated.timing(opacity, {
                    toValue: 1,
                    duration: 250,
                    useNativeDriver: false
                }).start();
            }, 300);
        }
    }, [abstract]);

    useEffect(() => {
        let driverLocation = utils.location.last();
        setDistance(driverLocation ? Utils.linearDistance(location, driverLocation) : null);
    }, [location]);

    useEffect(() => {
        setupAbstract();
        setupLottieSource();
    }, []);

    return visible && abstract ? (
        <View style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            width: Screen.width(),
            height: Screen.height(),
            backgroundColor: Appearance.colors.transparent,
            zIndex: 9950
        }}>
            <Animated.View style={{
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                opacity: opacity,
                position: 'absolute',
                backgroundColor: Appearance.colors.dim
            }} />

            <Animated.View style={{
                borderRadius: 10,
                backgroundColor: Appearance.colors.alert(),
                overflow:'hidden',
                width: '100%',
                maxHeight: Screen.height() - 30,
                maxWidth: Screen.layer.maxWidth - 30,
                alignItems: 'center',
                zIndex: 9999,
                opacity: opacity,
                bottom: bottom,
                transform: [{
                    scale: scale
                }]
            }}>
                <Map
                utils={utils}
                annotations={[{
                    id: 'location',
                    anchor: {
                        x: 0.5,
                        y: 0.5
                    },
                    location: location && {
                        latitude: location.lat,
                        longitude: location.long
                    },
                    content: (
                        <LottieView
                        autoPlay={true}
                        loop={true}
                        source={require('eCarra/files/lottie/location-broadcast-blue.json')}
                        duration={2500}
                        style={{
                            width: 65,
                            height: 65,
                        }}/>
                    )
                }]}
                style={{
                    width: '100%',
                    height: Screen.width() * 0.65,
                    maxHeight: 350,
                    borderBottomWidth: 1,
                    borderBottomColor: Appearance.colors.divider()
                }} />

                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    paddingTop: 15,
                    paddingLeft: 15,
                    paddingRight: 15,
                    marginBottom: 8,
                    textAlign: 'center',
                }}>{Utils.apply(target_type, {
                    orders: () => 'New Order Requested',
                    reservations: () => 'New Ride Requested'
                })}</Text>

                <ScrollView style={{
                    flexGrow: 1,
                    width: '100%',
                    maxHeight: Screen.height() / 2,
                    marginBottom: 15
                }}>
                    <View style={{
                        flex: 1,
                        width: '100%'
                    }}>
                        <Text style={{
                            ...Appearance.textStyles.subTitle(),
                            width: '100%',
                            height: '100%',
                            paddingLeft: 15,
                            paddingRight: 15,
                            textAlign: 'center',
                        }}>{Utils.apply(target_type, {

                            orders: () => `${customer.full_name} has placed an Order for immediate pickup${distance ? ` around ${Utils.distanceConversion(distance)} away from your location` : ''}. Would you like to start ${customer.first_name}'s Order?`,

                            reservations: () => `${customer.full_name} has requested a ride for immediate pickup${distance ? ` around ${Utils.distanceConversion(distance)} away from your location` : ''}. Would you like to start ${customer.first_name}'s Reservation?`

                        })}</Text>
                    </View>
                </ScrollView>

                {alertButtons.filter(item => {
                    return item.visible !== false;
                }).map(item => {
                    return (
                        <TouchableHighlight
                        key={item.key}
                        activeOpacity={0.9}
                        underlayColor={Appearance.colors.divider()}
                        onPress={onButtonPress.bind(this, item.key)}
                        style={{
                            height: 50,
                            width: '100%'
                        }}>
                            <View style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                height: 50,
                                borderTopWidth: 1,
                                borderTopColor: Appearance.colors.divider()
                            }}>
                                {loading === item.key && (
                                    <LottieView
                                    autoPlay={true}
                                    loop={true}
                                    source={loadingSource}
                                    duration={2500}
                                    style={{
                                        width: '100%',
                                        height: 25
                                    }}/>
                                )}
                                {loading !== item.key && (
                                    <Text style={{
                                        ...Appearance.textStyles.subTitle(),
                                        textAlign: 'center',
                                        color: (item.style == 'cancel' || item.style == 'destructive') ? Appearance.colors.text() : Appearance.colors.primary()
                                    }}>{item.title}</Text>
                                )}
                            </View>
                        </TouchableHighlight>
                    )
                })}
            </Animated.View>
        </View>
    ) : null
}

export default OnDemandNew;
