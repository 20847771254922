import React, { useRef, useState, useEffect } from 'react';
import { Image, Keyboard, Platform, Text, View } from 'react-native';

import moment from 'moment';
import update from 'immutability-helper';

import API from 'eCarra/files/api.js';
import AddressLookupField from 'eCarra/views/AddressLookupField.js';
import Appearance from 'eCarra/styles/Appearance.js';
import AsyncStorage from '@react-native-community/async-storage';
import BoolToggle from 'eCarra/views/BoolToggle.js';
import DatePicker from 'eCarra/views/DatePicker/';
import ImagePicker from 'eCarra/files/ImagePicker/';
import Layer from 'eCarra/structure/Layer.js';
import List from 'eCarra/views/List.js';
import NumberStepper from 'eCarra/views/NumberStepper.js';
import Permissions, { PERMISSIONS, RESULTS } from 'eCarra/files/Permissions/';
import Picker from 'eCarra/views/Picker/';
import Request from 'eCarra/files/Request/';
import Screen from 'eCarra/files/Screen.js';
import TextField from 'eCarra/views/TextField.js';
import TextView from 'eCarra/views/TextView.js';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import Utils from 'eCarra/files/Utils.js';
import Video from 'eCarra/views/Video/';
import Views from 'eCarra/views/Main.js';

// Layers
export const DriverOnboardingItem = ({ children, item, onChange }, { index, options, utils }) => {

    const layerID = `driver-onboarding-${item.key}`;
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const [layerState, setLayerState] = useState(null);

    useEffect(() => {
        utils.keyboard.subscribe(layerID, {
            onVisibility: visible => setKeyboardOpen(visible)
        });

        return () => {
            utils.keyboard.unsubscribe(layerID);
        }
    }, [])

    return (
        <Layer
            id={layerID}
            title={item.title}
            utils={utils}
            index={index}
            options={{
                ...options,
                bottomCard: true,
                layerState: layerState
            }}
            buttons={[{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onPress: () => {
                    Keyboard.dismiss();
                    setLayerState('close');
                    if(typeof(onChange) === 'function') {
                        onChange();
                    }
                }
            }]}>

            <View style={{
                alignItems: 'center',
                marginTop: 5
            }}>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 6
                }}>{item.title}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 20,
                    textAlign: 'center'
                }}>{item.on_edit.description}</Text>
                {children()}
            </View>
        </Layer>
    )
}

export const DriverOnboarding = ({ abstract }, { index, options, utils }) => {

    const layerID = abstract ? `driver-application-${abstract.getID()}` : 'driver-onboarding';
    const [loading, setLoading] = useState(false);
    const [header, setHeader] = useState(null);
    const [lineItems, setLineItems] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [images, setImages] = useState({});
    const [application, setApplication] = useState(abstract ? {
        ...abstract.object.application,
        ...abstract.object.contact
    } : {});

    const fetchOnboardingLineItems = async () => {
        try {
            let { header, line_items } = await Request.get(utils, '/driver/', {
                type: 'onboarding_line_items',
                application_id: abstract ? abstract.getID() : null,
                ...application
            });
            setHeader(header);
            setLineItems(line_items ? line_items.sections : null);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the onboarding options. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSubmitApplication = async () => {

        let requiredItems = lineItems.filter(lineItem => {
            return lineItem.items.filter(item => {
                return item.value === null && item.required === true
            }).length > 0
        });
        if(requiredItems.length > 0) {
            utils.alert.show({
                title: 'Just a Second',
                message: `Please fill out all the required areas in the "${requiredItems[0].title}" section before submitting your application`
            });
            return;
        }

        try {
            setLoading(true);
            await Request.post(utils, '/driver/', {
                type: 'new_onboarding_application',
                user_id: utils.user.get().user_id,
                ...application,
                ...images
            });

            setLoading(false);
            utils.alert.show({
                title: 'Application Submitted',
                message: `Thank you for submitting your application. We'll contact you with any questions or concerns while we review the information that you submitted`,
                onPress: () => setLayerState('close')
            });
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting your application. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onCancelApplication = () => {

        utils.alert.show({
            title: 'Cancel Application',
            message: 'Are you sure that you want to cancel this application? This can not be undone',
            buttons: [{
                key: 'confirm',
                title: 'Cancel',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Cancel',
                style: 'default'
            }],
            onPress: (key) => {
                if(key === 'confirm') {
                    cancelApplicationConfirm();
                }
            }
        })
    }

    const cancelApplicationConfirm = async () => {
        try {
            setLoading(true);
            await Request.post(utils, '/driver/', {
                type: 'cancel_onboarding_application',
                application_id: abstract.getID()
            });

            setLoading(false);
            utils.alert.show({
                title: 'Application Cancelled',
                message: `Your application has been cancelled. You can submit a new application if you wish to continue through the applicant process`,
                onPress: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue cancelling your application. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    let tempContent = undefined;
    const onItemChange = (item, value) => {
        tempContent = { [item.key]: value };
        console.log(tempContent);
    }

    const onItemUpdate = () => {
        setApplication(application => {
            return {
                ...application,
                ...tempContent
            }
        });
    }

    const onItemPress = async item => {

        if(item.on_edit && item.on_edit.component === 'image_picker') {
            try {
                let response = await ImagePicker.openPicker({
                    mediaType: 'photo',
                    includeExif: true,
                    compressImageQuality: 1,
                    includeBase64: true
                });

                onItemChange(item, true);
                onItemUpdate();
                setImages(images => update(images, {
                    [item.key]: {
                        $set: {
                            uri: response.path,
                            type: response.mime,
                            data: response.data
                        }
                    }
                }))

            } catch(e) {
                console.error(e.message);
            }
            return;
        }

        utils.layer.open({
            id: `driver-onboarding-${item.key}`,
            Component: DriverOnboardingItem.bind(this, {
                item: item,
                onChange: () => onItemUpdate(item),
                children: () => {

                    switch(item.on_edit.component) {
                        case 'picker':
                        // onChange not fired if default selection matches actual selection
                        onItemChange(item, item.value || item.on_edit.items[0]);
                        return (
                            <Picker
                            items={item.on_edit.items}
                            selected={item.value ? item.value.toString() : null}
                            onChange={value => onItemChange(item, value)}/>
                        )

                        case 'address_lookup':
                        return (
                            <AddressLookupField
                            value={item.value}
                            utils={utils}
                            onChange={location => onItemChange(item, location)}/>
                        )

                        case 'bool_toggle':
                        // onChange not fired if default selection matches actual selection
                        onItemChange(item, typeof(item.value) === 'boolean' ? item.value : false);
                        return (
                            <BoolToggle
                            isEnabled={item.value}
                            disabled={'No'}
                            enabled={'Yes'}
                            onChange={enabled => onItemChange(item, enabled)}/>
                        )

                        case 'number_stepper':
                        if(item.on_edit.items) {
                            return (
                                item.on_edit.items.map((item, index) => {
                                    return (
                                        <View key={index}>
                                            <Text style={{
                                                ...Appearance.textStyles.title(),
                                                marginBottom: 8,
                                                marginTop: 20,
                                                textAlign: 'center'
                                            }}>{item.title}</Text>
                                            <NumberStepper
                                            min={item.min}
                                            max={item.max}
                                            startingValue={item.value}
                                            onChange={value => onItemChange(item, value)}/>
                                        </View>
                                    )
                                })
                            )
                        }
                        return (
                            <NumberStepper
                            min={0}
                            startingValue={item.value}
                            onChange={value => onItemChange(item, locations)}/>
                        )

                        case 'text_field':
                        return (
                            <TextField
                            value={item.value}
                            {...item.on_edit.prop_styles}
                            onChange={text => onItemChange(item, text)}/>
                        )

                        case 'text_view':
                        return (
                            <TextView
                            value={item.value}
                            onChange={text => onItemChange(item, text)}/>
                        )

                        case 'list':
                        return (
                            <List items={item.on_edit.items}
                            onChange={selected => onItemChange(item, selected.id)}
                            nullable={item.on_edit.nullable}
                            defaultValue={item.value} />
                        )

                        default:
                        return null;
                    }
                }
            })
        })
    }

    useEffect(() => {
        fetchOnboardingLineItems();
    }, [application])

    return (
        <Layer
            id={layerID}
            title={'Driver Onboarding'}
            utils={utils}
            index={index}
            options={{
                ...options,
                layerState: layerState,
                removePadding: true
            }}
            buttons={[ abstract ? {
                key: 'cancel',
                text: 'Cancel',
                loading: loading,
                color: 'danger',
                onPress: onCancelApplication
            }:{
                key: 'done',
                text: 'Submit',
                loading: loading,
                color: 'primary',
                onPress: onSubmitApplication
            }]}>

            <View style={{
                alignItems: 'center',
                marginTop: 20,
                padding: 15,
                borderBottomWidth: 1,
                borderBottomColor: Appearance.colors.divider()
            }}>
                <Video
                muted={true}
                repeat={true}
                autoPlay={true}
                disableFocus={true}
                mixWithOthers={'mix'}
                playWhenInactive={true}
                resizeMode={'contain'}
                source={Appearance.themeStyle() === 'dark' ? require('eCarra/files/vehicle-onboarding-dark.mp4') : require('eCarra/files/vehicle-onboarding-light.mp4')}
                style={{
                    height: (Screen.layer.maxWidth / 1500) * 1100,
                    width: Screen.layer.maxWidth,
                    marginBottom: 20
                }}/>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 6
                }}>{header ? header.title : null}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    textAlign: 'center'
                }}>{header ? header.description : null}</Text>
            </View>
            <View style={{
                padding: 15
            }}>
                {lineItems && lineItems.length > 0 && (
                    lineItems.filter(lineItem => {
                        return lineItem.items && lineItem.items.length > 0;
                    }).map((lineItem, index) => {
                        return (
                            <View
                            key={lineItem.key}
                            style={{
                                marginTop: index === 0 ? 0 : 20
                            }}>
                                <Text
                                ellipsizeMode={'tail'}
                                numberOfLines={1}
                                style={{
                                    ...Appearance.textStyles.title(),
                                    fontSize: 16,
                                    marginBottom: 12
                                }}>
                                    {lineItem.title}
                                </Text>
                                <View style={Appearance.styles.panel()}>
                                    {lineItem.items.map((item, index, items) => {

                                        return  (
                                            Views.entry({
                                                key: item.key,
                                                title: item.title,
                                                subTitle: item.formatted || item.null_value,
                                                loading: item.loading,
                                                hideIcon: item.url || images[item.key] ? false : true,
                                                icon: (item.url || images[item.key]) && {
                                                    path: { uri: item.url || images[item.key].uri },
                                                    style: {
                                                        ...Appearance.icons.standard,
                                                        borderRadius: 5
                                                    }
                                                },
                                                bottomBorder: index !== items.length - 1,
                                                onPress: item.on_edit && item.on_edit.enabled ? onItemPress.bind(this, item) : null,
                                                propStyles: {
                                                    subTitle: {
                                                        numberOfLines: 2
                                                    }
                                                },
                                                rightContent: item.required && item.value === null && (
                                                    <View style={{
                                                        width: 12,
                                                        height: 12,
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <View style={{
                                                            width: 8,
                                                            height: 8,
                                                            borderRadius: 4,
                                                            backgroundColor: Appearance.colors.red
                                                        }}/>
                                                    </View>
                                                )
                                            })
                                        )
                                    })}
                                </View>
                            </View>
                        )
                    })
                )}
            </View>
        </Layer>
    )
}
