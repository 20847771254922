import { Platform } from 'react-native';
import API from 'eCarra/files/api.js';
import Abstract from 'eCarra/classes/Abstract.js';
import { CreditsList,  PaymentsCalendar, PaymentMethods, RecentPayments, SubscriptionsList } from 'eCarra/managers/Payments.js';
import { CustomerVehicles, CustomerVehicleApplications, CustomerVehicleRecentActivity, VehicleUsageTrends } from 'eCarra/managers/Vehicles.js';
import { DriverOrdersList, OnTheWayOrders, OrdersCalendar, UpcomingOrders, fetchOrderChannels } from 'eCarra/managers/Orders.js';
import { DriverReservationsList, DriverTimecardOverview, DriverVehicle, OnTheWayReservations, ReservationsCalendar, UpcomingReservations,   } from 'eCarra/managers/Reservations.js';
import { DriverRoutesList } from 'eCarra/managers/Routes.js';
import { EmissionsChart, EmissionsPotential, EmissionsRewardEvents, EmissionsTopMembers, EmissionsTopTreesPlanted } from 'eCarra/managers/Emissions.js';
import { MessageThreadsList } from 'eCarra/managers/Messages.js';
import { ModularContentPanel } from 'eCarra/files/Utils.js';
import { NewsList, fetchNewsChannels } from 'eCarra/managers/News.js';
import { NotificationsList } from 'eCarra/managers/Notifications.js';
import Order from 'eCarra/classes/Order.js';
import { OrderBooking } from 'eCarra/managers/Orders.js';
import { Profile } from 'eCarra/managers/Settings.js';
import Reservation from 'eCarra/classes/Reservation.js';
import { RideBooking } from 'eCarra/managers/RideBooking.js';
import Screen from 'eCarra/files/Screen.js';
import { SearchList } from 'eCarra/managers/ContentManager.js';
import User from 'eCarra/classes/User.js';

export const getContent = async utils => {
    return new Promise(async (resolve, reject) => {
        try {

            // Order channels
            // Empty order created to start each panel
            // New orders are created as needed within the panel
            let orderChannels = await fetchOrderChannels(utils);
            let newOrderChannels = orderChannels.reduce((object, channel) => {
                return {
                    ...object,
                    [`new_order_${channel.id}`]: {
                        key: `new_order_${channel.id}`,
                        title: `New ${channel.name} Order`,
                        icon: {
                            active: require('eCarra/images/orders-icon-white.png'),
                            inactive: require('eCarra/images/orders-icon-grey.png'),
                            ...channel.icon.mobile && {
                                active: { uri: channel.icon.mobile.active },
                                inactive: { uri: channel.icon.mobile.inactive }
                            },
                        },
                        panels: [{
                            id: 'modularContent',
                            Component: ModularContentPanel.bind(this, {
                                category: `new_order_${channel.id}`
                            })
                        },{
                            id: `searchListNewOrders${channel.id}`,
                            Component: SearchList.bind(this, {
                                type: 'newOrders',
                                channel: channel,
                                panelID: `searchListNewOrders${channel.id}`
                            })
                        },{
                            id: `order${channel.id}Booking`,
                            Component: OrderBooking.bind(this, {
                                channel: channel,
                                abstract: Abstract.create({
                                    type: 'orders',
                                    object: Order.new()
                                })
                            })
                        }]
                    }
                }
            }, {});

            // Order channels interaction panels
            let myOrderChannels = orderChannels.reduce((object, channel) => {
                return {
                    ...object,
                    ...((utils.user.get().level >= User.level.customer || Platform.OS === 'web') ? {
                        [`orders_${channel.id}`]: {
                            key: `orders_${channel.id}`,
                            title: `${channel.name} Orders`,
                            icon: {
                                active: require('eCarra/images/orders-icon-white.png'),
                                inactive: require('eCarra/images/orders-icon-grey.png')
                            },
                            panels: [{
                                id: 'modularContent',
                                Component: ModularContentPanel.bind(this, {
                                    category: `orders_${channel.id}`
                                })
                            },{
                                id: `searchListOrders${channel.id}`,
                                Component: SearchList.bind(this, {
                                    type: 'orders',
                                    channel: channel,
                                    panelID: `searchListOrders${channel.id}`
                                })
                            },{
                                id: `onTheWayOrders${channel.id}`,
                                Component: OnTheWayOrders.bind(this, {
                                    channel: channel
                                })
                            },{
                                id: 'upcomingOrders',
                                Component: UpcomingOrders.bind(this, {
                                    channel: channel
                                })
                            },{
                                id: 'ordersCalendar',
                                Component: OrdersCalendar.bind(this, {
                                    channel: channel
                                })
                            }]
                        }
                    } : {
                        [`orders_${channel.id}`]: {
                            key: `orders_${channel.id}`,
                            title: `${channel.name} Orders`,
                            icon: {
                                active: require('eCarra/images/orders-icon-white.png'),
                                inactive: require('eCarra/images/orders-icon-grey.png')
                            },
                            panels: [{
                                id: 'modularContent',
                                Component: ModularContentPanel.bind(this, {
                                    category: `driver_orders_${channel.id}`
                                })
                            },{
                                id: `searchListDriverOrders${channel.id}`,
                                Component: SearchList.bind(this, {
                                    type: 'driverOrders',
                                    channel: channel,
                                    panelID: `searchListDriverOrders${channel.id}`
                                })
                            },{
                                id: 'driverVehicle',
                                Component: DriverVehicle
                            },{
                                id: 'driverOrderAssignmentsList',
                                Component: DriverOrdersList.bind(this, {
                                    channel: channel,
                                    showAssignments: true
                                })
                            },{
                                id: 'driverOrdersList',
                                Component: DriverOrdersList.bind(this, {
                                    channel: channel,
                                    showAssignments: false
                                })
                            }]
                        }
                    })
                }
            }, {});

            // News channels
            let newsChannels = await fetchNewsChannels(utils);
            let formattedNewsChannels = newsChannels.reduce((object, channel) => {
                return {
                    ...object,
                    [`news_${channel.id}`]: {
                        key: `news_${channel.id}`,
                        title: channel.name,
                        subTitle: channel.description,
                        icon: {
                            active: require('eCarra/images/news-icon-white.png'),
                            inactive: require('eCarra/images/news-icon-grey.png'),
                            ...channel.icon.mobile && {
                                active: { uri: channel.icon.mobile.active },
                                inactive: { uri: channel.icon.mobile.inactive }
                            },
                        },
                        panels: [{
                            id: 'modularContent',
                            Component: ModularContentPanel.bind(this, {
                                category: `news_${channel.id}`
                            })
                        },{
                            id: `searchListNews${channel.id}`,
                            Component: SearchList.bind(this, {
                                type: 'news',
                                channel: channel,
                                panelID: `searchListNews${channel.id}`
                            })
                        },{
                            id: `newsList${channel.id}`,
                            Component: NewsList.bind(this, {
                                channel: channel
                            })
                        }]
                    }
                }

            }, {});

            // Standard navigation entries
            // Empty reservation created to start booking panels
            // New reservations are created as needed within the panel
            resolve({
                bookRide: {
                    key: 'bookRide',
                    title: 'Book a Ride',
                    icon: {
                        active: require('eCarra/images/book-ride-icon-white.png'),
                        inactive: require('eCarra/images/book-ride-icon-grey.png')
                    },
                    panels: [{
                        id: 'rideBooking',
                        fullscreen: true,
                        Component: RideBooking.bind(this, {
                            initAbstract: Abstract.create({
                                type: 'reservations',
                                object: Reservation.new()
                            }) // needed to initialize panel => new objects are made inside of the panel when needed
                        })
                    }]
                },
                ...newOrderChannels,
                ...(utils.user.get().level >= User.level.customer || Platform.OS === 'web') ? {
                    reservations: {
                        key: 'reservations',
                        title: 'Reservations',
                        icon: {
                            active: require('eCarra/images/reservations-icon-white.png'),
                            inactive: require('eCarra/images/reservations-icon-grey.png')
                        },
                        panels: [{
                            id: 'modularContent',
                            Component: ModularContentPanel.bind(this, {
                                category: 'reservations'
                            })
                        },{
                            id: 'searchListReservations',
                            Component: SearchList.bind(this, {
                                type: 'reservations',
                                panelID: 'searchListReservations'
                            })
                        },{
                            id: 'onTheWayReservations',
                            Component: OnTheWayReservations
                        },{
                            id: 'upcomingReservations',
                            Component: UpcomingReservations
                        },{
                            id: 'reservationsCalendar',
                            Component: ReservationsCalendar
                        }]
                    }
                } : {
                    driverReservations: {
                        key: 'driverReservations',
                        title: 'Reservations',
                        icon: {
                            active: require('eCarra/images/reservations-icon-white.png'),
                            inactive: require('eCarra/images/reservations-icon-grey.png')
                        },
                        panels: [{
                            id: 'modularContent',
                            Component: ModularContentPanel.bind(this, {
                                category: 'driver_reservations'
                            })
                        },{
                            id: 'searchListDriverReservations',
                            Component: SearchList.bind(this, {
                                type: 'driverReservations',
                                panelID: 'searchListDriverReservations'
                            })
                        },{
                            id: 'driverVehicle',
                            Component: DriverVehicle
                        },{
                            id: 'driverAssignmentsList',
                            Component: DriverReservationsList.bind(this, {
                                showAssignments: true
                            })
                        },{
                            id: 'driverReservationsList',
                            Component: DriverReservationsList.bind(this, {
                                showAssignments: false
                            })
                        },{
                            id: 'driverRoutesList',
                            Component: DriverRoutesList
                        }]
                    }
                },
                ...myOrderChannels,
                ...formattedNewsChannels,
                messages: {
                    key: 'messages',
                    title: 'Messages',
                    icon: {
                        active: require('eCarra/images/messages-icon-white.png'),
                        inactive: require('eCarra/images/messages-icon-grey.png')
                    },
                    panels: [{
                        id: 'modularContent',
                        Component: ModularContentPanel.bind(this, {
                            category: 'messages'
                        })
                    },{
                        id: 'searchListMessages',
                        Component: SearchList.bind(this, {
                            type: 'messages',
                            panelID: 'searchListMessages'
                        })
                    },{
                        id: 'messageThreads',
                        Component: MessageThreadsList
                    }]
                },
                notifications: {
                    key: 'notifications',
                    title: 'Notifications',
                    icon: {
                        active: require('eCarra/images/notifications-icon-white.png'),
                        inactive: require('eCarra/images/notifications-icon-grey.png')
                    },
                    panels: [{
                        id: 'modularContent',
                        Component: ModularContentPanel.bind(this, {
                            category: `notifications`
                        })
                    },{
                        id: 'searchListNotifications',
                        Component: SearchList.bind(this, {
                            type: 'notifications',
                            panelID: 'searchListNotifications'
                        })
                    },{
                        id: 'todayNotifications',
                        Component: NotificationsList.bind(this, {
                            today: true
                        })
                    }]
                },
                payments: {
                    key: 'payments',
                    title: 'Payments',
                    icon: {
                        active: require('eCarra/images/payments-icon-white.png'),
                        inactive: require('eCarra/images/payments-icon-grey.png')
                    },
                    panels: [{
                        id: 'modularContent',
                        Component: ModularContentPanel.bind(this, {
                            category: 'payments'
                        })
                    },{
                        id: 'searchList-payments',
                        Component: SearchList.bind(this, {
                            type: 'payments',
                            panelID: 'searchListPayments'
                        })
                    },{
                        id: 'payment_methods',
                        Component: PaymentMethods
                    },{
                        id: 'recentPayments',
                        Component: RecentPayments
                    },{
                        id: 'paymentsCalendar',
                        Component: PaymentsCalendar
                    },{
                        id: 'creditsList',
                        Component: CreditsList
                    },{
                        id: 'subscriptionsList',
                        Component: SubscriptionsList
                    }]
                },
                emissions: {
                    key: 'emissions',
                    title: 'Emissions',
                    icon: {
                        active: require('eCarra/images/emissions-icon-white.png'),
                        inactive: require('eCarra/images/emissions-icon-grey.png')
                    },
                    panels: [{
                        id: 'modularContent',
                        Component: ModularContentPanel.bind(this, {
                            category: 'emissions'
                        })
                    },{
                        id: 'emissionsChart',
                        Component: EmissionsChart
                    },{
                        id: 'emissionsPotential',
                        Component: EmissionsPotential
                    },{
                        id: 'emissionsTopMembers',
                        Component: EmissionsTopMembers
                    },{
                        id: 'emissionsTopTreesPlanted',
                        Component: EmissionsTopTreesPlanted
                    },{
                        id: 'emissionsRewardEvents',
                        Component: EmissionsRewardEvents
                    }]
                },
                ...API.get_client_id() === 'ecarra' && utils.user.get().shouldShowVehicles() && {
                    vehicles: {
                        key: 'vehicles',
                        title: 'Vehicles',
                        icon: {
                            active: require('eCarra/images/vehicles-icon-white.png'),
                            inactive: require('eCarra/images/vehicles-icon-grey.png')
                        },
                        panels: [{
                            id: 'modularContent',
                            Component: ModularContentPanel.bind(this, {
                                category: 'vehicles'
                            })
                        },{
                            id: 'customerVehicles',
                            Component: CustomerVehicles
                        },{
                            id: 'vehicleUsageTrends',
                            Component: VehicleUsageTrends
                        },{
                            id: 'customerVehicleRecentActivity',
                            Component: CustomerVehicleRecentActivity
                        },{
                            id: 'customerVehicleApplications',
                            Component: CustomerVehicleApplications
                        }]
                    }
                },
                settings: {
                    key: 'settings',
                    title: 'My Account',
                    subTitle: 'Manage your profile and privacy settings',
                    icon: {
                        active: require('eCarra/images/settings-icon-white.png'),
                        inactive: require('eCarra/images/settings-icon-grey.png')
                    },
                    panels: [{
                        id: 'modularContent',
                        Component: ModularContentPanel.bind(this, {
                            category: 'settings'
                        })
                    },{
                        id: 'userProfile',
                        Component: Profile
                    }]
                }
            });

        } catch(e) {
            reject(e);
        }
    })
}
