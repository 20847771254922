import React, { useRef, useState, useEffect } from 'react';
import { Animated, Image, Keyboard, FlatList, Platform, ScrollView, Text, View } from 'react-native';

import moment from 'moment-timezone';
import update from 'immutability-helper';
import { getLevel } from 'eCarra/classes/User.js';

import API from 'eCarra/files/api.js';
import Appearance from 'eCarra/styles/Appearance.js';
import { AppearanceProvider } from 'react-native-appearance';
import AsyncStorage from '@react-native-community/async-storage';
import Button from 'eCarra/views/Button.js';
import DeviceInfo from 'react-native-device-info';
import ImagePicker from 'eCarra/files/ImagePicker/';
import InnerShadow from 'eCarra/views/InnerShadow.js';
import LottieView from 'eCarra/views/Lottie/';
import Request from 'eCarra/files/Request/';
import * as RNLocalize from 'react-native-localize';
import Screen from 'eCarra/files/Screen.js';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import Utils, { ModularContentLogic } from 'eCarra/files/Utils.js';
import Views from 'eCarra/views/Main.js';
import WebView from 'eCarra/views/WebView/';

const Sidebar = ({ animate, activeView, collapsed, content, items, onClose, onLogout, onCollapsePress, onNavigationPress, onSettingsClick, style, user, utils, workspace }) => {

    const closeButton = useRef(null);
    const feedbackButton = useRef(null);
    const scrollView = useRef(null);

    const [avatar, setAvatar] = useState(user.avatar);
    const [avatarLoading, setAvatarLoading] = useState(false);
    const [buttonState, setButtonState] = useState('visible');
    const [left, setLeft] = useState(new Animated.Value(-Screen.sidebar.maxWidth));
    const [modularContent, setModularContent] = useState([]);
    const [open, setOpen] = useState([]);
    const [summary, setSummary] = useState(null);
    const [top, setTop] = useState(new Animated.Value(Screen.safeArea.top));

    const socialIcons = [{
        key: 'twitter',
        title: 'Twitter',
        icon: require('eCarra/images/twitter.png'),
        url: utils.client.get().twitter
    },{
        key: 'facebook',
        title: 'Facebook',
        icon: require('eCarra/images/facebook.png'),
        url: utils.client.get().facebook
    },{
        key: 'instagram',
        title: 'Instagram',
        icon: require('eCarra/images/instagram.png'),
        url: utils.client.get().instagram
    }]

    const onAvatarPress = async () => {
        try {
            setAvatarLoading(true);
            let nextImage = await ImagePicker.openPicker({
                mediaType: 'photo',
                includeExif: true,
                compressImageQuality: 1,
                includeBase64: true
            });

            if(!nextImage) {
                setAvatarLoading(false);
                return;
            }

            let { url } = await Request.post(utils, '/user/', {
                type: 'set_avatar',
                avatar: {
                    file_type: nextImage.type,
                    data: nextImage.data
                }
            })

            setAvatarLoading(false);
            setAvatar({ uri: url });

        } catch(e) {
            setAvatarLoading(false);
            if(e.message && e.message.includes('cancel')) {
                return;
            }
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating your photo. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onFeedbackPress = () => {
        utils.sheet.show({
            title: 'How Can We Help?',
            message: 'What can we do to make your experience even better?',
            items: [{
                key: 'support',
                title: 'Get Support',
                style: 'default'
            },{
                key: 'feedback',
                title: 'Leave Feedback',
                style: 'default'
            }]
        }, key => {
            if(key !== 'cancel') {
                onSidebarClose();
            }
            setTimeout(() => {
                if(key === 'support') {
                    Utils.showSupportOptions(utils);
                    return;
                }
                if(key === 'feedback') {
                    utils.quickFeedback.show();
                    return;
                }
            }, 500);
        })
    }

    const onLogoutPress = () => {
        if(Platform.OS === 'web' || Utils.isMobile() === false) {
            if(typeof(onLogout) === 'function') {
                onLogout();
            }
            return;
        }
        Animated.timing(left, {
            toValue: -Screen.sidebar.maxWidth,
            duration: 250,
            friction: 7,
            useNativeDriver: false
        }).start(onLogout);
    }

    const onPrivacyPress = async () => {
        try {
            if(activeView === 'bookRide' || Utils.isMobile() === true) {
                onSidebarClose();
                await Utils.sleep(0.75);
            }
            utils.layer.webView({
                id: 'privacy-policy',
                title: 'Privacy Policy and Terms of Service',
                url: utils.client.get().privacyPolicy
            });
        } catch(e) {
            console.error(e.message);
        }
    }

    const onSidebarClose = () => {
        setOpen([]);
        if(typeof(onClose) === 'function') {
            onClose();
        }
    }

    const onSocialPress = async item => {
        try {
            if(activeView === 'bookRide' || Utils.isMobile() === true) {
                onSidebarClose();
                await Utils.sleep(0.75);
            }
            if(Platform.OS === 'web') {
                window.open(item.url);
                return;
            }
            utils.layer.open({
                id: 'webview',
                Component: WebView.bind(this, {
                    url: item.url,
                    title: item.title
                })
            })
        } catch(e) {
            console.error(e.message);
        }
    }

    const onToggleMenu = key => {

        setOpen(update(open, open.includes(key) ? {
            $splice: [
                [open.indexOf(key), 1]
            ]
        } : {
            $push: [key]
        }))
        if(onCollapsePress && typeof(onCollapsePress) === 'function') {
            onCollapsePress(false);
        }
    }

    const getCloseButton = () => {
        if(Platform.OS === 'web' || Utils.isMobile() === false) {
            return null;
        }
        return (
            <TouchableOpacity
            activeOpacity={0.6}
            onPress={onSidebarClose}
            style={{
                width: 50,
                height: 50,
                padding: 7
            }}>
                <LottieView
                ref={closeButton}
                autoPlay={false}
                loop={false}
                duration={2500}
                source={Appearance.themeStyle() === 'dark' ? require('eCarra/files/lottie/close-button-white.json') : require('eCarra/files/lottie/close-button-grey.json')}
                style={{
                    width: '100%',
                    height: '100%',
                    opacity: Appearance.themeStyle() === 'dark' ? 1 : 0.75
                }}/>
            </TouchableOpacity>
        )
    }

    const getContentComponents = () => {
        if(Platform.OS === 'web' || Utils.isMobile() === false) {
            return (
                <View style={{
                    width: '100%',
                    height: '100%',
                    maxHeight: Screen.height(),
                    overflowY: 'scroll',
                    paddingHorizontal: 12,
                    paddingTop: 12 + Screen.safeArea.top,
                    paddingBottom: 12,
                    marginBottom: 125
                }}>
                    <View style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        <TouchableOpacity
                        activeOpacity={0.6}
                        onPress={onAvatarPress}
                        style={{
                            width: 100,
                            height: 100,
                            minWidth: 100,
                            minHeight: 100,
                            borderRadius: 50,
                            borderWidth: 3,
                            borderColor: 'white',
                            overflow: 'hidden',
                            marginTop: 5
                        }}>
                            <View style={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <View style={{
                                    width: 100,
                                    height: 100,
                                    borderRadius: 50,
                                    overflow: 'hidden'
                                }}>
                                    <Image
                                    source={avatar}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        resizeMode: 'cover'
                                    }} />
                                </View>
                                {avatarLoading === true && (
                                    <>
                                    <View style={{
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: 50,
                                        resizeMode: 'cover',
                                        overflow: 'hidden',
                                        backgroundColor: 'rgba(0,0,0,0.5)',
                                        position: 'absolute'
                                    }} />
                                    <View style={{
                                        position: 'absolute',
                                        padding: 30
                                    }}>
                                        <LottieView
                                        autoPlay={true}
                                        loop={true}
                                        source={require('eCarra/files/lottie/dots-white.json')}
                                        duration={2500}
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}/>
                                    </View>
                                    </>
                                )}
                            </View>
                        </TouchableOpacity>

                        <View style={{
                            padding: 15,
                            paddingTop: 10,
                            width: '100%',
                            alignItems: 'center'
                        }}>
                            <Text style={{
                                ...Appearance.textStyles.panelTitle(),
                                marginBottom: 4,
                                textAlign: 'center'
                            }}>{user.full_name}</Text>
                            <Text style={{
                                color: Appearance.colors.subText(),
                                fontSize: 16,
                                marginBottom: 10,
                                textAlign: 'center'
                            }}>{user.referral_code ? `@${user.referral_code}` : null}</Text>
                        </View>
                        <View style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            flexGrow: 1,
                            width: '100%'
                        }}>
                            {Object.values(content).filter(item => {
                                if(item.level && user.level > item.level) {
                                    return false;
                                }
                                if(typeof(item.visible) === 'function' && item.visible() === false) {
                                    return false;
                                }
                                return true;
                            }).map((item, index, items) => {
                                if(!item.panels || item.panels.length === 0) {
                                    return null;
                                }
                                return getItem({
                                    key: item.key,
                                    index: index,
                                    title: item.key === 'workspace' && workspace ? workspace.title : item.title,
                                    subTitle: item.subTitle,
                                    icon: item.icon,
                                    active: activeView && item.key === activeView.view,
                                    bottomBorder: true,
                                    onPress: onNavigationPress.bind(this, {
                                        view: item.key
                                    })
                                })
                            })}
                            <View style={{
                                display: 'flex',
                                padding: 15,
                                alignItems: 'center',
                                position: 'relative',
                                bottom: 0
                            }}>
                                <Button
                                label={'Log Out'}
                                color={'grey'}
                                onPress={onLogoutPress}
                                style={{
                                    width: 180
                                }}/>
                                <TouchableOpacity
                                activeOpacity={0.6}
                                onPress={onPrivacyPress}>
                                    <Text style={{
                                        ...Appearance.textStyles.supporting(),
                                        display: 'flex',
                                        textAlign: 'center',
                                        marginTop: 8
                                    }}>{'Privacy Policy and Terms of Service'}</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={{
                                padding: 15,
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row'
                            }}>
                                {socialIcons.map(i => {
                                    if(!i.url) {
                                        return null;
                                    }
                                    return (
                                        <TouchableOpacity
                                        key={i.key}
                                        activeOpacity={0.6}
                                        onPress={onSocialPress.bind(this, i)}
                                        style={{
                                            ...Appearance.styles.panel(),
                                            width: 35,
                                            height: 35,
                                            padding: 8,
                                            marginLeft: 8,
                                            marginRight: 8,
                                            borderRadius: 17.5
                                        }}>
                                            <Image
                                            source={i.icon}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                resizeMode: 'contain'
                                            }}/>
                                        </TouchableOpacity>
                                    )
                                })}
                            </View>
                        </View>
                    </View>
                </View>
            )
        }

        return (
            <View style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                paddingTop: 15 + Screen.safeArea.top,
                paddingBottom: Screen.safeArea.bottom,
                backgroundColor: Appearance.colors.transparent
            }}>
                <TouchableOpacity
                activeOpacity={0.6}
                onPress={onAvatarPress}
                style={{
                    width: 100,
                    height: 100,
                    borderRadius: 50,
                    borderWidth: 4,
                    borderColor: 'white',
                    overflow: 'hidden',
                    ...Platform.OS === 'ios' && Appearance.boxShadow({
                        radius: 15,
                        opacity: 0.35,
                        color: 'rgba(150,150,150)',
                        offset: {
                            width: 6,
                            height: 6
                        }
                    })
                }}>
                    <View style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <View style={{
                            width: 100,
                            height: 100,
                            borderRadius: 50,
                            overflow: 'hidden'
                        }}>
                            <Image
                            source={avatar}
                            style={{
                                width: '100%',
                                height: '100%',
                                resizeMode: 'cover'
                            }} />
                        </View>
                        {avatarLoading === true && (
                            <>
                            <View style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: 50,
                                resizeMode: 'cover',
                                overflow: 'hidden',
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                position: 'absolute'
                            }} />
                            <View style={{
                                position: 'absolute',
                                padding: 30
                            }}>
                                <LottieView
                                autoPlay={true}
                                loop={true}
                                source={require('eCarra/files/lottie/dots-white.json')}
                                duration={2500}
                                style={{
                                    width: '100%',
                                    height: '100%'
                                }}/>
                            </View>
                            </>
                        )}
                    </View>
                </TouchableOpacity>

                <View style={{
                    padding: 15,
                    paddingTop: 10,
                    width: '100%',
                    alignItems: 'center'
                }}>
                    <Text style={{
                        ...Appearance.textStyles.panelTitle(),
                        marginBottom: 4,
                        textAlign: 'center'
                    }}>{user.full_name}</Text>
                    <Text style={{
                        color: Appearance.colors.subText(),
                        fontSize: 16,
                        marginBottom: 10,
                        textAlign: 'center'
                    }}>{user.referral_code ? `@${user.referral_code}` : null}</Text>
                </View>

                <ModularContentLogic
                utils={utils}
                category={'sidebar'}
                content={modularContent}
                onPress={onSidebarClose}
                width={Screen.sidebar.maxWidth}/>

                <View style={{
                    flexGrow: 1,
                    width: '100%',
                    padding: 15,
                    marginTop: modularContent && modularContent.length > 0 ? 10 : 0
                }}>
                    <View style={{
                        ...Appearance.styles.panel(),
                        width: '100%'
                    }}>
                        {Object.values(content).filter(item => {
                            if(item.level && user.level > item.level) {
                                return false;
                            }
                            if(typeof(item.visible) === 'function' && item.visible() === false) {
                                return false;
                            }
                            return true;
                        }).map((item, index, items) => {
                            if(!item.panels || item.panels.length === 0) {
                                return null;
                            }
                            return getItem({
                                key: item.key,
                                index: index,
                                title: item.key === 'workspace' && workspace ? workspace.title : item.title,
                                subTitle: item.subTitle,
                                icon: item.icon,
                                active: activeView && item.key === activeView.view,
                                bottomBorder: index != items.length - 1,
                                onPress: onNavigationPress.bind(this, {
                                    view: item.key
                                })
                            })
                        })}
                    </View>
                </View>

                <View style={{
                    padding: 15,
                    alignItems: 'center'
                }}>
                    <Button
                    label={'Log Out'}
                    color={'grey'}
                    onPress={onLogoutPress}
                    style={{
                        width: 180
                    }}/>
                    <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={onPrivacyPress}>
                        <Text style={{
                            ...Appearance.textStyles.supporting(),
                            textAlign: 'center',
                            marginTop: 8
                        }}>{'Privacy Policy and Terms of Service'}</Text>
                    </TouchableOpacity>
                </View>
                <View style={{
                    padding: 15,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row'
                }}>
                    {socialIcons.map(i => {
                        if(!i.url) {
                            return null;
                        }
                        return (
                            <TouchableOpacity
                            key={i.key}
                            activeOpacity={0.6}
                            onPress={onSocialPress.bind(this, i)}
                            style={{
                                ...Appearance.styles.panel(),
                                width: 35,
                                height: 35,
                                padding: 8,
                                marginLeft: 8,
                                marginRight: 8,
                                borderRadius: 17.5
                            }}>
                                <Image
                                source={i.icon}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    resizeMode: 'contain',
                                    tintColor: Appearance.themeStyle() === 'dark' ? 'white' : Appearance.colors.grey()
                                }}/>
                            </TouchableOpacity>
                        )
                    })}
                </View>
            </View>
        )
    }

    const getItem = ({ active, icon, index, key, onPress, title, subTitle, bottomBorder }) => {

        if(Platform.OS === 'web' || Utils.isMobile() === false) {
            return (
                <TouchableOpacity
                key={key}
                activeOpacity={0.6}
                onPress={() => {
                    setOpen([]);
                    onPress(key);
                }}
                style={{
                    width: (Screen.sidebar.maxWidth - 24) / 2,
                    height: (Screen.sidebar.maxWidth - 24) / 3,
                    marginBottom: 12,
                    paddingLeft: index % 2 === 0 ? 0 : 6,
                    paddingRight: index % 2 === 0 ? 6 : 0,
                    ...Platform.OS === 'ios' && Utils.isMobile() === true && {
                        ...Appearance.boxShadow()
                    }
                }}>
                    <View style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 12,
                        width: '100%',
                        height: '100%',
                        borderRadius: 15,
                        overflow: 'hidden',
                        textAlign: 'center',
                        backgroundColor: active ? Appearance.colors.primary() : Appearance.colors.background()
                    }}>
                        <Image
                        source={active ? icon.active : icon.inactive}
                        style={{
                            width: 30,
                            height: 30,
                            resizeMode: 'contain'
                        }}/>
                        <Text style={{
                            ...Appearance.textStyles.subTitle(),
                            textAlign: 'center',
                            color: active ? 'white' : Appearance.colors.text(),
                            marginTop: 8
                        }}>{title}</Text>
                    </View>
                </TouchableOpacity>
            )
        }

        return (
            <TouchableOpacity
            key={key}
            activeOpacity={0.6}
            onPress={() => {
                setOpen([]);
                onPress(key);
            }}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: 12,
                width: '100%',
                borderBottomWidth: bottomBorder ? 1 : 0,
                borderBottomColor: Appearance.colors.divider()
            }}>
                <View style={{
                    width: 35,
                    height: 35,
                    padding: 4
                }}>
                    <Image
                    source={active ? icon.active : icon.inactive}
                    style={{
                        width: '100%',
                        height: '100%',
                        resizeMode: 'contain',
                        tintColor: Appearance.colors.sidebarIcon(),
                        ...active && {
                            tintColor: Appearance.colors.primary()
                        }
                    }}/>
                </View>
                <View style={{
                    flex: 1,
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: 12,
                    paddingRight: 12
                }}>
                    <Text style={{
                        ...Appearance.textStyles.title(),
                        color: active ? Appearance.colors.primary() : Appearance.colors.text()
                    }}>{title}</Text>
                </View>
                <Image
                source={require('eCarra/images/next-arrow-grey-small.png')}
                style={{
                    width: 15,
                    height: 15,
                    resizeMode: 'contain',
                    tintColor: Appearance.themeStyle() === 'dark' ? 'white' : Appearance.colors.grey()
                }}/>
            </TouchableOpacity>
        )
    }

    const getContent = () => {
        if(!user) {
            return null;
        }
        return (
            <Animated.View style={{
                position: 'absolute',
                left: left,
                top: 0,
                bottom: 0,
                width: Screen.width(),
                maxWidth: Screen.sidebar.maxWidth,
                backgroundColor: Utils.isMobile() === true ? Appearance.colors.background() : Appearance.colors.transparent
            }}>
                <AppearanceProvider>
                    <ScrollView
                    ref={scrollView}
                    scrollEventThrottle={16}
                    showsVerticalScrollIndicator={false}
                    style={{
                        backgroundColor: Appearance.colors.layerBackground()
                    }}
                    onScroll={({ nativeEvent })=> {
                        if(buttonState === 'hidden' && nativeEvent.contentOffset.y < 100) {
                            setButtonState('visible');
                        }
                        if(buttonState === 'visible' && nativeEvent.contentOffset.y > 100) {
                            setButtonState('hidden');
                        }
                    }}>
                        {getContentComponents()}
                    </ScrollView>
                    {getNavigationButtons()}
                </AppearanceProvider>
            </Animated.View>
        )
    }

    const getNavigationButtons = () => {
        if(Platform.OS === 'web' || Utils.isMobile() === false) {
            return null;
        }
        return (
            <Animated.View style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                position: 'absolute',
                top: top,
                left: -12,
                right: -12,
                height: 50,
                padding: 15
            }}>
                <TouchableOpacity
                activeOpacity={0.6}
                onPress={onFeedbackPress}
                style={{
                    width: 50,
                    height: 50,
                    padding: 5
                }}>
                    <LottieView
                    ref={feedbackButton}
                    autoPlay={false}
                    loop={false}
                    duration={2500}
                    source={Appearance.themeStyle() === 'dark' ? require('eCarra/files/lottie/feedback-button-white.json') : require('eCarra/files/lottie/feedback-button-grey.json')}
                    style={{
                        width: '100%',
                        height: '100%',
                        opacity: Appearance.themeStyle() === 'dark' ? 1 : 0.75
                    }}/>
                </TouchableOpacity>
                {getCloseButton()}
            </Animated.View>
        )
    }

    const setupModularContent = async () => {
        try {
            let { content } = await Utils.fetchModularContent(utils, 'sidebar');
            setModularContent(content);
        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        if(buttonState === 'visible') {
            if(feedbackButton.current) {
                feedbackButton.current.play();
            }
            if(closeButton.current) {
                closeButton.current.play();
            }
        }

        Animated.timing(top, {
            toValue: buttonState === 'visible' ? Screen.safeArea.top : -125,
            duration: 250,
            useNativeDriver: false
        }).start(() => {
            if(buttonState === 'hidden') {
                if(feedbackButton.current) {
                    feedbackButton.current.reset();
                }
                if(closeButton.current) {
                    closeButton.current.reset();
                }
            }
        });
    }, [buttonState]);

    useEffect(() => {

        Keyboard.dismiss();
        setupModularContent();

        // set avatar
        let user = utils.user.get();
        setAvatar(user ? user.avatar : null);

        setTimeout(() => {
            if(feedbackButton.current){
                feedbackButton.current[animate === 0 ? 'play' : 'reset']();
            }
            if(closeButton.current){
                closeButton.current[animate === 0 ? 'play' : 'reset']();
            }
        }, 250)

        if(animate === 0 && scrollView.current) {
            scrollView.current.scrollTo({
                x: 0,
                y: 0,
                animated: false
            })
        }
        Animated.spring(left, {
            toValue: animate,
            duration: 250,
            friction: 7,
            useNativeDriver: false
        }).start();
    }, [animate]);

    return getContent();
}
export default Sidebar;
