import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import update from 'immutability-helper';

import Appearance from 'eCarra/styles/Appearance';
import Layer from 'eCarra/structure/Layer.js';
import User from 'eCarra/classes/User.js';
import Utils from 'eCarra/files/Utils.js';
import TextField from 'eCarra/views/TextField.js';
import { VelocityComponent } from 'velocity-react';

const DatePickerAlert = ({ adminOveride, id, blockStyle, date, utils, filterDate, filterDateValue, highlightDates, onClose, onDateChange, onRemoveDate, removeable }) => {

    const headers = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];
    const weeks = [ 0, 1, 2, 3, 4 ];

    const [alertButtons, setAlertButtons] = useState([]);
    const [days, setDays] = useState([]);
    const [dimOpacity, setDimOpacity] = useState(0);
    const [offset, setOffset] = useState(0);
    const [opacity, setOpacity] = useState(0);
    const [scale, setScale] = useState(0);
    const [selectedDate, setSelectedDate] = useState(date ? moment(date) : moment());
    const [size, setSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const [target, setTarget] = useState(date ? moment(date) : moment());
    const [visible, setVisible] = useState(false);

    const onConfirmDate = key => {

        if(key === 'remove') {
            if(typeof(onRemoveDate) === 'function') {
                onRemoveDate(null);
            }
            onHideAlert();
            return;
        }

        let dateTarget = moment(selectedDate);
        if(typeof(filterDate) === 'function' && filterDate(dateTarget) === false) {
            if(!(adminOveride === true && utils.userLoggedIn().level <= User.level.admin)) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: `The date you have selected is invalid. ${filterDateValue ? `Please select a date after ${moment(filterDateValue).format('MMMM Do, YYYY [at] h:mma')}` : ''}`
                });
                return;
            }
        }

        if(typeof(onDateChange) === 'function') {
            onDateChange(dateTarget);
        }
        onHideAlert();
    }

    const onHideAlert = callback => {
        setScale(0);
        setOpacity(0);
        setDimOpacity(0);
        setTimeout(() => {
            setVisible(false);
            if(typeof(onClose) === 'function') {
                onClose();
            }
            if(typeof(callback) === 'function') {
                callback();
            }
        }, 250);
    }

    const onWindowSizeChange = () => {
        setSize({
            width: window.innerWidth,
            height: window.innerHeight
        })
    }

    const isDateAvailable = newDate => {
        if(typeof(filterDate) === 'function') {
            if(!(adminOveride === true && utils.userLoggedIn().level <= User.level.admin)) {
                return filterDate(newDate.endOf('day'));
            }
        }
        return newDate.isSame(moment(target).add(offset, 'months'), 'month');
    }

    const shouldHighlightDate = date => {
        if(!highlightDates) {
            return false;
        }
        if(!blockStyle) {
            return highlightDates.find(d => moment(d).unix() === moment(date).unix())
        }
        return moment(date).unix() >= moment(selectedDate).startOf(blockStyle).unix() && moment(date).unix() <= moment(selectedDate).endOf(blockStyle).unix()
    }

    const getDateStyles = (i, length, date) => {

        let highlightDate = shouldHighlightDate(date);
        if(highlightDate && blockStyle) {
            return {
                opacity: 1,
                backgroundColor: Appearance.colors.primary(),
                borderTopLeftRadius: i === 0 ? 10 : 0,
                borderBottomLeftRadius: i === 0 ? 10 : 0,
                borderTopRightRadius: i === length - 1 ? 10 : 0,
                borderBottomRightRadius: i === length - 1 ? 10 : 0
            };
        }
        let available = isDateAvailable(date);
        return {
            opacity: available ? 1 : 0.25
        }
    }

    const getDateTextStyles = date => {

        let highlightDate = shouldHighlightDate(date);
        if(highlightDate && blockStyle) {
            return {
                ...Appearance.textStyles.title(),
                ...Appearance.fontWeight.get(500),
                textAlign: 'center',
                width: '100%',
                fontSize: 14,
                color: 'white'
            }
        }
        return {
            ...Appearance.textStyles.title(),
            fontWeight: highlightDate ? '600' : '500',
            textAlign: 'center',
            width: '100%',
            fontSize: 14,
            color: selectedDate && date.isSame(moment(selectedDate), 'day') ? 'white' : (highlightDate ? Appearance.colors.primary() : Appearance.textStyles.title().color)
        }
    }

    const getMonth = () => {
        return moment(target).startOf('month').add(offset, 'months').format('MMMM YYYY')
    }

    useEffect(() => {

        if(!target) {
            return;
        }

        // Dates
        // 42 days accounts for 6 full rows of 7 day weeks if first of the month is thursday or later
        let startOfMonth = parseInt(moment(target).startOf('month').add(offset, 'months').format('e'));
        let totalDays = startOfMonth >= 5 ? 42 : 35;

        let days = [...new Array(totalDays)].map((_, day) => {
            return day < startOfMonth ? moment(target).startOf('month').add(offset, 'months').subtract(startOfMonth - day, 'days') : moment(target).startOf('month').add(offset, 'months').add(day - startOfMonth, 'days');
        });

        let weeks = days.reduce((array, date, index) => {
            let i = Math.floor(index / headers.length);
            if(!array[i]) {
                array[i] = [];
            }
            array[i].push({ date: date });
            return array;
        }, []);
        setDays(weeks);

    }, [target, offset]);

    useEffect(() => {

        setAlertButtons([{
            key: 'done',
            title: removeable ? 'Use Date' : 'Done',
            style: 'default'
        },{
            key: 'remove',
            title: 'Remove Date',
            style: 'destructive',
            visible: removeable ? true : false
        }]);

        setVisible(true);
        setTimeout(() => {
            setScale(1);
            setOpacity(1);
            setDimOpacity(1);
        }, 100)

        window.addEventListener('resize', onWindowSizeChange);
        return () => {
            window.removeEventListener('resize', onWindowSizeChange);
        }
    }, []);

    return visible ? (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: Appearance.colors.transparent,
            zIndex: 9950
        }}>
            <VelocityComponent
            duration={250}
            animation={{
                opacity: dimOpacity
            }}>
                <div style={{
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    position: 'absolute',
                    backgroundColor: Appearance.colors.dim
                }} />
            </VelocityComponent>

            <VelocityComponent
            easing={[250, 20]}
            duration={500}
            animation={{
                opacity: opacity,
                scale: scale
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 10,
                    backgroundColor: Appearance.colors.alert(),
                    overflow: 'hidden',
                    maxHeight: size.height - 30,
                    width: '100%',
                    maxWidth: size.width > 400 ? 400 : (size.width - 30),
                    textAlign: 'center',
                    zIndex: 9999
                }}>
                    <div style={{
                        display: 'flex',
                        padding: 15,
                        width: '100%'
                    }}>
                        <div style={{
                            padding: 15,
                            width: '100%',
                            borderRadius: 10,
                            border: `1px solid ${Appearance.colors.divider()}`
                        }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingLeft: 8,
                                paddingRight: 8
                            }}>
                                <img
                                className={'text-button'}
                                onClick={() => setOffset(offset => offset - 12)}
                                src={require('eCarra/images/back-double-arrow-light-grey-small.png')}
                                style={{
                                    width: 15,
                                    height: 15,
                                    minWidth: 15,
                                    minHeight: 15,
                                    objectFit: 'contain',
                                    marginRight: 4,
                                }} />

                                <img
                                className={'text-button'}
                                onClick={() => setOffset(offset => offset - 1)}
                                src={require('eCarra/images/back-arrow-light-grey-small.png')}
                                style={{
                                    width: 15,
                                    height: 15,
                                    minWidth: 15,
                                    minHeight: 15,
                                    objectFit: 'contain',
                                    marginRight: 8,
                                }} />

                                <span style={{
                                    ...Appearance.textStyles.title(),
                                    ...Appearance.fontWeight.get(600),
                                    textAlign: 'center',
                                    width: '100%',
                                    fontSize: 16,
                                }}>{getMonth()}</span>

                                <img
                                className={'text-button'}
                                onClick={() => setOffset(offset => offset + 1)}
                                src={require('eCarra/images/next-arrow-light-grey-small.png')}
                                style={{
                                    width: 15,
                                    height: 15,
                                    minWidth: 15,
                                    minHeight: 15,
                                    objectFit: 'contain',
                                    marginLeft: 8,
                                }} />

                                <img
                                className={'text-button'}
                                onClick={() => setOffset(offset => offset + 12)}
                                src={require('eCarra/images/next-double-arrow-light-grey-small.png')}
                                style={{
                                    width: 15,
                                    height: 15,
                                    minWidth: 15,
                                    minHeight: 15,
                                    objectFit: 'contain',
                                    marginLeft: 4,
                                }} />

                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                textAlign: 'center',
                                height: 40,
                                marginBottom: 8
                            }}>
                                {/* header days */}
                                {headers.map((d, i) => (
                                    <span key={i}
                                    style={{
                                        ...Appearance.textStyles.supporting(),
                                        textAlign: 'center',
                                        ...Appearance.fontWeight.get(600),
                                        color: Appearance.colors.lightGrey,
                                        flexGrow: 1,
                                        width: '100%'
                                    }}>{d.toUpperCase()}</span>
                                ))}
                            </div>
                            {days.map((week, index) => {
                                return (
                                    <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                        justifyContent: 'space-around',
                                        alignItems: 'center',
                                        marginBottom: index !== days.length - 1 ? 4 : 0,
                                    }}>
                                        {week.map((entry, i) => {
                                            return (
                                                <div
                                                key={i}
                                                className={Utils.isMobile() || !entry.date.isSame(target, 'month') ? '' : 'text-button'}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    flexGrow: 1,
                                                    justifyContent: 'center',
                                                    height: '100%',
                                                    width: '100%',
                                                    paddingLeft: 5,
                                                    paddingRight: 5,
                                                    ...getDateStyles(i, week.length, entry.date)
                                                }}>
                                                    <div onClick={entry.date.isSame(moment(target).add(offset, 'months'), 'month') ? () => {
                                                        setSelectedDate(entry.date.format('YYYY-MM-DD'));
                                                        if(typeof(onDateChange) === 'function') {
                                                            onDateChange(entry.date);
                                                        }
                                                    } : null}
                                                    style={{
                                                        display: 'flex',
                                                        flexGrow: 1,
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        height: '100%',
                                                        width: '100%'
                                                    }}>
                                                        <div
                                                        className={'cursor-pointer'}
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            borderRadius: 8,
                                                            width: 30,
                                                            height: 30,
                                                            padding: 4,
                                                            backgroundColor: selectedDate && entry.date.isSame(moment(selectedDate), 'day') ? Appearance.colors.primary() : null
                                                        }}>
                                                            <span style={getDateTextStyles(entry.date)}>{entry.date.format('D')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    {alertButtons.map(item => {

                        if(item.visible === false) {
                            return null;
                        }
                        return (
                            <div
                            key={item.key}
                            className={`alert-item ${window.theme}`}
                            onClick={onConfirmDate.bind(this, item.key)}
                            style={{
                                height: 50,
                                width: '100%'
                            }}>
                                <div style={{
                                    height: 50
                                }}>
                                    <div style={{
                                        width: '100%',
                                        height: 1,
                                        backgroundColor: Appearance.colors.divider()
                                    }}/>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 50
                                    }}>
                                        <span style={{
                                            fontSize: 13,
                                            ...Appearance.fontWeight.get(400),
                                            textAlign:'center',
                                            color: (item.style == 'cancel' || item.style == 'destructive') ? Appearance.colors.text() : Appearance.colors.primary()
                                        }}>{item.title}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </VelocityComponent>
        </div>
    ) : null
}

export default DatePickerAlert;
