const Clipboard = {
    setString: text => {
        try {
            let textArea = document.createElement('textarea');
            textArea.value = text;
            textArea.style.top = 0;
            textArea.style.left = 0;
            textArea.style.position = 'fixed';
            textArea.style.opacity = 0;
            document.body.appendChild(textArea);

            textArea.focus();
            textArea.select();

            let copied = document.execCommand('copy');
            document.body.removeChild(textArea);

        } catch(e) {
            console.error(e.message);
        }
    }
}
export default Clipboard
