import React, { useRef, useState, useEffect } from 'react';
import { Image, Platform, ScrollView, Text, View } from 'react-native';

import moment from 'moment';
import update from 'immutability-helper';

import API from 'eCarra/files/api.js';
import Appearance from 'eCarra/styles/Appearance.js';
import AsyncStorage from '@react-native-community/async-storage';
import { BubbleHeader } from 'eCarra/structure/Navigation.js';
import Button from 'eCarra/views/Button.js';
import Carousel from 'react-native-snap-carousel';
import DropdownMenu from 'eCarra/views/DropdownMenu.js';
import LikeButton from 'eCarra/views/LikeButton.js';
import NewsCategory from 'eCarra/classes/NewsCategory.js';
import NewsChannel from 'eCarra/classes/NewsChannel.js';
import NewsItem from 'eCarra/classes/NewsItem.js';
import Panel from 'eCarra/structure/Panel.js';
import Request from 'eCarra/files/Request/';
import Screen from 'eCarra/files/Screen.js';
import Service from 'eCarra/classes/Service.js';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import Utils from 'eCarra/files/Utils.js';
import Views, { AltBadge } from 'eCarra/views/Main.js';

export const fetchNewsChannels = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { channels } = await Request.get(utils, '/news/', {
                type: 'channels',
                ...props
            });
            resolve(channels.map(c => NewsChannel.create(c)));
        } catch(e) {
            reject(e);
        }
    })
}

export const fetchNewsCategories = (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { categories } = await Request.get(utils, '/news/', {
                type: 'categories',
                ...props
            });
            resolve(categories.map(c => NewsCategory.create(c)));
        } catch(e) {
            reject(e);
        }
    })
}

export const fetchNewsItems = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { items, featured_items, popular_items } = await Request.get(utils, '/news/', {
                type: 'items',
                ...props
            });
            resolve({
                items: items.map(i => {
                    let item = NewsItem.create(i);
                    item.category = i.category ? NewsCategory.create(i.category) : null;
                    return item;
                }),
                featuredItems: featured_items.map(i => {
                    let item = NewsItem.create(i);
                    item.category = i.category ? NewsCategory.create(i.category) : null;
                    return item;
                }),
                popularItems: popular_items.map(i => {
                    let item = NewsItem.create(i);
                    item.category = i.category ? NewsCategory.create(i.category) : null;
                    return item;
                })
            })

        } catch(e) {
            reject(e);
        }
    })
}

export const NewsList = ({ channel }, { utils }) => {

    const panelID = `newsList${channel.id}`;
    const featuredItemsRef = useRef(null);

    const [category, setCategory] = useState(null);
    const [categories, setCategories] = useState([]);
    const [favorites, setFavorites] = useState([]);
    const [featuredItems, setFeaturedItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [popularItems, setPopularItems] = useState([]);
    const [scrollOffsets, setScrollOffsets] = useState({ featured_items: 0 })

    const onDropdownChange = item => {
        setLoading(true);
        if(item.key === 'favorites') {
            setCategory(item);
            return;
        }
        setCategory(categories.find(category => category.id === item.key));
    }

    const onFavoriteItemPress = item => {
        onUpdateFavoriteItems(item, item.favorite ? 'remove' : 'add');
        setItems(items => update(items, {
            $apply: items => items.map(i => {
                i.favorite = i.id === item.id ? !i.favorite : i.favorite;
                return i;
            })
        }));
        setPopularItems(popularItems => update(popularItems, {
            $apply: items => items.map(i => {
                i.favorite = i.id === item.id ? !i.favorite : i.favorite;
                return i;
            })
        }));
        setFeaturedItems(featuredItems => update(featuredItems, {
            $apply: items => items.map(i => {
                i.favorite = i.id === item.id ? !i.favorite : i.favorite;
                return i;
            })
        }));
    }

    const onItemPress = async item => {

        utils.layer.webView({
            id: `news-${item.id}`,
            title: item.title,
            url: item.url,
            overrideFrame: true
        });

        // update views total for item
        try {
            let location = utils.location.last();
            await Request.post(utils, '/news/', {
                type: 'new_item_view',
                id: item.id,
                news_channel: channel.id,
                location: location && {
                    lat: location.latitude,
                    long: location.longitude
                }
            })
        } catch(e) {
            console.error(e.message);
        }
    }

    const onUpdateFavoriteItems = async (item, action) => {
        try {
            let result = await AsyncStorage.getItem('favoriteNewsItems');
            let favorites = result ? JSON.parse(result) : [];

            switch(action) {
                case 'add':
                favorites.push(item.id);
                break;

                case 'remove':
                favorites = favorites.filter(id => id !== item.id);
                break;
            }

            setFavorites(favorites);
            await AsyncStorage.setItem('favoriteNewsItems', JSON.stringify(favorites));

        } catch(e) {
            console.error(e.message);
        }
    }

    const getChannelHeader = () => {
        return (
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                paddingHorizontal: 15
            }}>
                <View style={{
                    flex: 1,
                    flexDirection: 'column'
                }}>
                    <Text
                    ellipsizeMode={'tail'}
                    numberOfLines={1}
                    style={{
                        ...Appearance.textStyles.panelTitle()
                    }}>
                        {channel.name}
                    </Text>
                    <Text
                    ellipsizeMode={'tail'}
                    numberOfLines={1}
                    style={{
                        ...Appearance.textStyles.title(),
                        color: Appearance.colors.subText(),
                    }}>
                        {channel.description}
                    </Text>
                </View>
                <DropdownMenu
                utils={utils}
                label={category ? category.title : 'All'}
                onChange={onDropdownChange}
                items={getDropdownItems()}
                style={{
                    marginLeft: 12
                }} />
            </View>
        )
    }

    const getCarouselItemStyles = () => {
        return {
            paddingTop: 8,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 20,
            ...(Platform.OS === 'web' || Utils.isMobile() === false) && {
                paddingLeft: 15
            }
        }
    }

    const getCarouselProps = target => {
        return {
            sliderWidth: Screen.panel.maxWidth(),
            itemWidth: Screen.panel.maxWidth() - 60,
            ...Platform.OS !== 'web' && {
                layoutCardOffset: 0,
                loop: target.length > 1
            },
            ...(Platform.OS === 'web' || Utils.isMobile() === false) && {
                sliderWidth: Screen.panel.maxWidth() - 15,
                itemWidth: Screen.panel.maxWidth() - 30,
                ...target.length >= 3 && {
                    itemWidth: Screen.panel.maxWidth() / 2
                }
            }
        }
    }

    const getContent = () => {
        if(loading === true) {
            return (
                <View style={{
                    paddingHorizontal: 15,
                    marginTop: 12
                }}>
                    <View style={Appearance.styles.panel()}>
                        {Views.loader()}
                    </View>
                </View>
            )
        }
        return (
            <>
            {getFeaturedItems()}
            {getPopularItems()}
            {getPastContent()}
            </>
        )
    }

    const getDateProps = item => {

        let date = false;
        let total = items.filter(prev => moment(prev.date).isSame(moment(item.date), 'day'));

        if(moment(item.date).isSame(moment(), 'day')) {
            date = moment(item.date).format('[Today, ] MMMM Do');
        } else if(moment(item.date).isSame(moment().subtract(1, 'days'), 'day')) {
            date = moment(item.date).format('[Yesterday, ] MMMM Do');
        }

        let posted = moment(item.date).format('MMMM Do [at] h:mma');
        if(moment().isSame(item.date, 'day')) {
            let duration = parseInt(moment.duration(moment().diff(moment(item.date))).asSeconds());
            posted = `${Utils.parseDuration(duration)} ago`;

        } else if(moment().subtract(1, 'days').isSame(moment(item.date), 'day')) {
            posted = moment(item.date).format('[Yesterday at] h:mma');
        }
        return {
            date: date,
            posted: posted,
            total: total
         };
    }

    const getDropdownItems = () => {
        return [{
            key: 'all',
            title: 'All'
        },{
            key: 'favorites',
            title: 'Favorites'
        }].concat(categories.map(category => ({
            key: category.id,
            title: category.title
        })));
    }

    const getFavorites = () => {
        let favoriteItems = items.concat(featuredItems || []).concat(popularItems || []).filter(item => {
            return item.favorite;
        }).reduce((array, item) => {
            if(!array.find(i => i.id === item.id)) {
                array.push(item);
            }
            return array;
        }, []).sort((a, b) => {
            return moment(a.date).unix() - moment(b.date).unix() ? -1 : 1;
        });
        return favoriteItems.map((item, index, items) => getMinimalItem(item, index))
    }

    const getFeaturedItems = () => {
        if(category || featuredItems.length === 0) {
            return null;
        }
        if(Platform.OS === 'web' || Utils.isMobile() === false) {
            return (
                <View style={{
                    paddingHorizontal: 15,
                    marginBottom: 15
                }}>
                    <View style={{
                        ...Appearance.styles.panel(),
                        width: '100%',
                        marginBottom: 12
                    }}>
                        {getFullSizeItem(featuredItems[0])}
                    </View>
                    {featuredItems.map((item, index) => {
                        if(index === 0) {
                            return null;
                        }
                        return getMinimalItem(item, index)
                    })}
                </View>
            )
        }
        return (
            <Carousel
            ref={featuredItemsRef}
            data={featuredItems}
            enableSnap={true}
            activeSlideAlignment={'center'}
            {...getCarouselProps(featuredItems)}
            {...featuredItems.length >= 3 && (Platform.OS === 'web' || Utils.isMobile() === false) && {
                itemWidth: Screen.panel.maxWidth() / 3
            }}
            style={{
                width: '100%',
                marginBottom: 10
            }}
            renderItem={({ item, index }) => {
                return (
                    <View style={getCarouselItemStyles()}>
                        <View style={{
                            ...Appearance.styles.panel(),
                            width: '100%'
                        }}>
                            {getFullSizeItem(item)}
                        </View>
                    </View>
                )
            }}/>
        )
    }

    const getFullSizeItem = item => {
        return (
            <>
            <TouchableOpacity
            activeOpacity={0.6}
            onPress={onItemPress.bind(this, item)}
            style={{
                width: '100%',
                height: Platform.OS === 'web' || Utils.isMobile() === false ? 350 : 150,
                borderBottomWidth: 1,
                borderBottomColor: Appearance.colors.divider(),
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                overflow: 'hidden'
            }}>
                <Image
                source={item.image}
                style={{
                    width: '100%',
                    height: '100%',
                    resizeMode: 'cover'
                }}/>
            </TouchableOpacity>
            {getItemContent(item)}
            </>
        )
    }

    const getItemContent = item => {
        return (
            <TouchableOpacity
            activeOpacity={0.6}
            onPress={onItemPress.bind(this, item)}
            style={{
                padding: 12
            }}>
                <Text style={{
                    ...Appearance.textStyles.title(),
                    marginBottom: 4
                }}>
                    {item.title}
                </Text>
                <Text
                numberOfLines={3}
                style={{
                    ...Appearance.textStyles.subTitle()
                }}>
                    {item.description}
                </Text>
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    marginTop: 12
                }}>
                    <LikeButton
                    active={item.favorite}
                    style={{
                        width: 21,
                        height: 21,
                        borderRadius: 11,
                        marginRight: 8
                    }}
                    onChange={() => {
                        onUpdateFavoriteItems(item, item.favorite ? 'remove' : 'add');
                        setFeaturedItems(featuredItems => update(featuredItems, {
                            [index]: {
                                favorite: {
                                    $set: !item.favorite
                                }
                            }
                        }));
                    }} />
                    {item.featured && (
                        <AltBadge content={{
                            text: 'Featured',
                            color: Appearance.colors.primary()
                        }} style={{
                            marginRight: 8
                        }} />
                    )}
                    <AltBadge content={{
                        text: Utils.formatDate(item.date),
                        color: Appearance.colors.grey()
                    }} style={{
                        marginRight: 8
                    }} />
                </View>
            </TouchableOpacity>
        )
    }

    const getMinimalItem = (item, index) => {
        return (
            <View
            key={index}
            style={{
                ...Appearance.styles.panel(),
                marginBottom: 12
            }}>
                <TouchableOpacity
                activeOpacity={0.6}
                onPress={onItemPress.bind(this, item)}>
                    <View style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    }}>
                        {Views.entry({
                            title: item.title,
                            subTitle: item.description,
                            useTouchable: false,
                            propStyles: {
                                subTitle: {
                                    numberOfLines: 2
                                }
                            },
                            icon: {
                                path: item.image,
                                style: {
                                    width: 50,
                                    height: 50,
                                    borderRadius: 8,
                                    overflow: 'hidden',
                                    borderWidth: 1,
                                    borderColor: Appearance.colors.divider()
                                }
                            }
                        })}
                        <View style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            padding: 12
                        }}>
                            <LikeButton
                            active={item.favorite}
                            style={{
                                width: 21,
                                height: 21,
                                borderRadius: 11,
                                marginRight: 8
                            }}
                            onChange={onFavoriteItemPress.bind(this, item)} />
                            <AltBadge content={{
                                text: Utils.formatDate(item.date),
                                color: Appearance.colors.grey()
                            }} style={{
                                marginRight: 8
                            }} />
                        </View>
                    </View>
                </TouchableOpacity>
            </View>
        )
    }

    const getPagingComponent = (key, target) => {
        if(!target || target.length <= 1) {
            return null;
        }
        return (
            <View style={{
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%',
                marginBottom: 5
            }}>
                {target.map((_, index) => {
                    return (
                        <View
                        key={index}
                        style={{
                            width: 15,
                            height: 3,
                            borderRadius: 1.5,
                            backgroundColor: scrollOffsets[key] === index ? Appearance.colors.primary() : Appearance.colors.grey(),
                            marginHorizontal: 4
                        }}/>
                    )
                })}
            </View>
        )
    }

    const getPastContent = () => {
        if(category && category.key === 'favorites') {
            return (
                <View style={{
                    marginTop: 12,
                    paddingHorizontal: 15
                }}>
                    {getFavorites() || (
                        <View style={Appearance.styles.panel()}>
                            {Views.entry({
                                title: 'Nothing to see here',
                                subTitle: `No favorited ${channel.name} articles were found`,
                                hideIcon: true,
                                bottomBorder: false
                            })}
                        </View>
                    )}
                </View>
            )
        }
        return (
            <View style={{
                paddingHorizontal: 15,
                marginTop: category ? 12 : 0
            }}>
                {items.map((item, index, items) => {
                    let prevItem = index > 0 ? items[index - 1] : null;
                    let showHeader = (!category && (!prevItem || !moment(prevItem.date).isSame(moment(item.date), 'day'))) ? true : false;
                    return (
                        <View key={index}>
                            {showHeader === true && getSectionHeader(item, index)}
                            {showHeader === false && getMinimalItem(item, index)}
                        </View>
                    )
                })}
            </View>
        )
    }

    const getPopularItems = () => {
        if(category || popularItems.length === 0) {
            return null;
        }
        return (
            <View style={{
                paddingHorizontal: 15,
                marginBottom: 20,
                ...Utils.isMobile() === true && featuredItems.length > 1 && {
                    marginTop: 12
                }
            }}>
                <View style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Text
                    ellipsizeMode={'tail'}
                    numberOfLines={1}
                    style={{
                        ...Appearance.textStyles.panelTitle()
                    }}>
                        {`Trending Now`}
                    </Text>
                    <Text
                    ellipsizeMode={'tail'}
                    numberOfLines={1}
                    style={{
                        ...Appearance.textStyles.title(),
                        color: Appearance.colors.subText(),
                    }}>
                        {`Popular in the ${utils.client.get().name} community`}
                    </Text>
                </View>
                <View style={{
                    marginTop: 8,
                    ...Appearance.styles.panel()
                }}>
                    {popularItems.map((item, index, items) => (
                        <View
                        key={index}
                        style={{
                            borderBottomColor: Appearance.colors.divider(),
                            borderBottomWidth: index !== items.length - 1 ? 1 : 0
                        }}>
                            {getItemContent(item)}
                        </View>
                    ))}
                </View>
            </View>
        )
    }

    const getSectionHeader = (item, index) => {

        let { date, posted, total } = getDateProps(item);
        if(Platform.OS == 'web' || Utils.isMobile() === false) {
            return (
                <>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginTop: index === 0 ? 0 : 12
                }}>{date || moment(item.date).format(`[${channel.name} for] MMMM Do`)}</Text>
                <Text
                ellipsizeMode={'tail'}
                numberOfLines={1}
                style={{
                    ...Appearance.textStyles.title(),
                    color: Appearance.colors.subText(),
                    marginBottom: 8
                }}>
                    {`${total.length} ${total.length === 1 ? 'Article' : 'Articles'}`}
                </Text>
                <View style={{
                    ...Appearance.styles.panel(),
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    height: 350,
                    marginBottom: 12
                }}>
                    <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={onItemPress.bind(this, item)}
                    style={{
                        flexGrow: 1,
                        height: '100%'
                    }}>
                        <Image
                        source={item.image}
                        style={{
                            width: '100%',
                            height: '100%',
                            resizeMode: 'cover'
                        }}/>
                    </TouchableOpacity>
                    <View style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: 450,
                        padding: 15,
                        position: 'relative'
                    }}>
                        <Text
                        numberOfLines={2}
                        style={{
                            ...Appearance.textStyles.title(),
                            marginBottom: 8,
                            ...Platform.OS === 'web' && {
                                whiteSpace: 'normal'
                            }
                        }}>
                            {item.title}
                        </Text>
                        <Text style={{
                            ...Appearance.textStyles.subTitle(),
                            marginBottom: 12
                        }}>
                            {posted}
                        </Text>

                        <Text style={{
                            ...Appearance.textStyles.title(),
                            color: Appearance.colors.subText(),
                            ...Platform.OS === 'web' && {
                                whiteSpace: 'normal'
                            }
                        }}>
                            {item.description}
                        </Text>

                        <View style={{
                            position: 'absolute',
                            right: 15,
                            bottom: 15
                        }}>
                            <Button
                            label={'Continue Reading'}
                            color={'grey'}
                            onPress={onItemPress.bind(this, item)}
                            style={{
                                width: 175
                            }}/>
                        </View>
                    </View>
                </View>
                </>
            )
        }

        return (
            <>
            <Text style={{
                ...Appearance.textStyles.panelTitle(),
                marginTop: index === 0 ? 0 : 12
            }}>{date || moment(item.date).format(`[${channel.name} for] MMMM Do`)}</Text>
            <Text
            ellipsizeMode={'tail'}
            numberOfLines={1}
            style={{
                ...Appearance.textStyles.title(),
                color: Appearance.colors.subText(),
                marginBottom: 8
            }}>
                {`${total.length} ${total.length === 1 ? 'Article' : 'Articles'}`}
            </Text>
            <View style={{
                marginBottom: 12
            }}>
                <View style={{
                    ...Appearance.styles.panel(),
                    width: '100%'
                }}>
                    {getFullSizeItem(item)}
                </View>
            </View>
            </>
        )
    }

    const fetchCategories = async () => {
        try {
            let categories = await fetchNewsCategories(utils, {
                news_channel: channel.id
            });
            setCategories(categories || []);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue fetching the categories list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchItems = async () => {
        if(category && category.key === 'favorites' && favorites.length === 0) {
            setItems([]);
            setLoading(false);
            return;
        }
        try {
            setLoading(true);
            let { items, featuredItems, popularItems } = await fetchNewsItems(utils, {
                news_channel: channel.id,
                category_id: category ? category.id : null,
                favorites: category ? null : favorites
            });

            try {
                let result = await AsyncStorage.getItem('favoriteNewsItems');
                let favorites = result ? JSON.parse(result) : [];

                setFavorites(favorites);
                setItems(items.map(item => {
                    item.favorite = favorites.includes(item.id);
                    return item;
                }));
                setFeaturedItems(featuredItems.map(item => {
                    item.favorite = favorites.includes(item.id);
                    return item;
                }));
                setPopularItems(popularItems.map(item => {
                    item.favorite = favorites.includes(item.id);
                    return item;
                }));
                setLoading(false);

            } catch(e) {
                // dont report error
                console.error(e.message);
                setLoading(false);
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the News Items list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchItems();
    }, [category]);

    useEffect(() => {
        if(featuredItemsRef.current && featuredItems.length >= 3) {
            featuredItemsRef.current.snapToItem(1, true);
        }
    }, [featuredItemsRef.current, featuredItems]);

    useEffect(() => {
        fetchCategories();
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        options={{
            removeOverflow: true,
            removePadding: true,
            shouldStyle: false
        }}>
            {getChannelHeader()}
            {getContent()}
        </Panel>
    );
}
