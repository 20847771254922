import React, { useRef } from 'react';
import { loadStripe } from '@stripe/stripe-js';

import API from 'eCarra/files/api.js';
import Appearance from 'eCarra/styles/Appearance.js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';

const apiProps = loadStripe(API.stripe);

export const PaymentCardTextField = ({ onTokenChange, style, utils }) => {

    const cardElement = useRef(null);
    const stripeProps = useRef({});

    const onCardElementChange = async props => {
        try {
            let element = stripeProps.current.elements.getElement(CardElement);
            let { token } = await stripeProps.current.stripe.createToken(element);
            onTokenChange(token);
        } catch(e) {
            onTokenError(e);
        }
    }

    return (
        <Elements stripe={apiProps}>
            <ElementsConsumer>
                {props => {
                    stripeProps.current = props;
                    return (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%',
                            padding: 10,
                            ...style
                        }}>
                            <div style={{
                                flexGrow: 1
                            }}>
                                <CardElement
                                onChange={onCardElementChange}
                                options={{
                                    hidePostalCode: true,
                                    style: {
                                        invalid: {
                                            color: Appearance.colors.red,
                                        },
                                        base: {
                                            fontSize: '12px',
                                            color: Appearance.colors.text(),
                                            '::placeholder': {
                                                color: Appearance.colors.subText(),
                                            }
                                        }
                                    }
                                }} />
                            </div>
                        </div>
                    )
                }}
            </ElementsConsumer>
        </Elements>
    )
}

export default {
    setOptions: () => {},
    cancelNativePayRequest: async () => {
        return new Promise((resolve, reject) => {
            console.warn('Stripe cancelNativePayRequest has not yet been implemented for web');
            resolve();
        })
    },
    createTokenWithCard: async () => {
        return new Promise((resolve, reject) => {
            console.warn('Stripe createTokenWithCard has not yet been implemented for web');
            resolve();
        })
    },
    completeNativePayRequest: async () => {
        return new Promise((resolve, reject) => {
            console.warn('Stripe completeNativePayRequest has not yet been implemented for web');
            resolve();
        })
    },
    deviceSupportsNativePay: async () => {
        return new Promise(resolve => {
            resolve(window.ApplePaySession ? true : false);
        })
    },
    paymentRequestWithNativePay: async (props, lineItems) => {
        return new Promise((resolve, reject) => {
            let session = new ApplePaySession(3, {
                countryCode: 'US',
                currencyCode: 'USD',
                lineItems: lineItems,
                total: {
                    label: 'Total',
                    type: 'final',
                    amount: lineItems.reduce((total, item) => {
                        return total += parseFloat(item.amount);
                    }, 0)
                },
                merchantCapabilities: [
                    'supports3DS'
                ],
                supportedNetworks: [
                    'visa',
                    'masterCard',
                    'amex',
                    'discover'
                ]
            });
            session.onpaymentauthorized = event => {
                const result = {
                    "status": ApplePaySession.STATUS_SUCCESS
                };
                session.completePayment(result);
            };

            session.oncancel = event => {
                console.log(event);
            };
            session.begin();
        })
    }
}
