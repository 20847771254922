import React, { useEffect, useState } from 'react';
import { FlatList, Image, Keyboard, Linking, Text, View } from 'react-native';

import Appearance from 'eCarra/styles/Appearance.js';
import * as Contacts from 'expo-contacts';
import Layer, { LayerHeaderSpacing } from 'eCarra/structure/Layer.js';
import LottieView from 'eCarra/views/Lottie/';
import Screen from 'eCarra/files/Screen.js';
import TextField from 'eCarra/views/TextField.js';
import Utils from 'eCarra/files/Utils.js';
import Views from 'eCarra/views/Main.js';

const ContactsPicker = ({ onChange }, { index, options, utils }) => {

    const layerID = 'contacts-picker';
    const [selected, setSelected] = useState(null);
    const [contacts, setContacts] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [loading, setLoading] = useState(true);
    const [layerState, setLayerState] = useState(null);

    const onContactPress = contact => {
        setSelected(contact);
    }

    const getFlatList = () => {
        return {
            data: contacts && contacts.filter(contact => {
                return searchText && !contact.full_name.toLowerCase().includes(searchText) ? false : true;
            }),
            renderItem: ({ item }, index) => {
                return (
                    Views.entry({
                        key: index,
                        title: item.full_name,
                        subTitle: item.phone_number ? Utils.formatPhoneNumber(item.phone_number) : 'No information found',
                        icon: item.icon,
                        bottomBorder: true,
                        onPress: onContactPress.bind(this, item),
                        rightContent: selected && selected.id === item.id && (
                            <Image
                            source={require('eCarra/images/checkmark-green.png')}
                            style={{
                                width: 25,
                                height: 25,
                                resizeMode: 'contain'
                            }}/>
                        )
                    })
                )
            }
        }
    }

    const fetchContacts = async () => {
        try {
            let { status } = await Contacts.requestPermissionsAsync();
            if(status !== 'granted') {

                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `Contacts permission is needed before we can access your contacts. Please check that Contacts Permissions have been granted to ${utils.client.get().name} in the Settings app on your device`,
                    buttons: [{
                        key: 'settings',
                        title: 'Settings',
                        style: 'destructive'
                    },{
                        key: 'cancel',
                        title: 'Maybe Later',
                        style: 'default'
                    }],
                    onPress: (key) => {
                        setLayerState('close');
                        if(key === 'settings') {
                            Linking.openSettings();
                        }
                    }
                });
                return;
            }

            let { data } = await Contacts.getContactsAsync({
                fields: [
                    Contacts.Fields.FirstName,
                    Contacts.Fields.LastName,
                    Contacts.Fields.Emails,
                    Contacts.Fields.PhoneNumbers,
                    Contacts.Fields.Image
                ],
            });

            let contacts = (data || []).map(({ id, first_name, last_name, emails, phone_numbers, image }) => {
                return {
                    id: id,
                    first_name: first_name,
                    last_name: last_name,
                    full_name: `${first_name ? `${first_name} `:''}${last_name || ''}`,
                    email_address: emails && emails.length > 0 ? emails[0].email : null,
                    phone_number: phone_numbers && phone_numbers.length > 0 ? phone_numbers[0].number : null,
                    icon: {
                        path: image ? { uri: image.uri } : require('eCarra/images/profile-full-name-clear.png'),
                        style: {
                            ...Appearance.icons.user,
                            backgroundColor: Appearance.colors.grey()
                        }
                    }
                }
            }).filter(contact => {
                return contact.first_name || contact.last_name
            }).sort((a, b) => {
                let aTarg = a.last_name || a.first_name;
                let bTarg = b.last_name || b.first_name;
                return aTarg.localeCompare(bTarg);
            })

            setLoading(false);
            setContacts(contacts);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your contacts. ${e.message || 'An unknown error occurred'}`,
                onPress: () => setLayerState('close')
            });
        }
    }

    useEffect(() => {
        fetchContacts();
    }, []);

    return (
        <Layer
        id={layerID}
        title={'Contacts'}
        utils={utils}
        index={index}
        options={{
            ...options,
            layerState: layerState,
            removePadding: true,
            removeScrollView: true
        }}
        buttons={[{
            key: 'done',
            text: 'Done',
            color: 'primary',
            onPress: () => {
                Keyboard.dismiss();
                setLayerState('close');
                if(typeof(onChange) === 'function') {
                    onChange(selected);
                }
            }
        }]}>
            <View style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: Screen.safeArea.bottom + 15
            }}>
                <View style={{
                    paddingHorizontal: 15,
                    marginTop: LayerHeaderSpacing.get(),
                    borderBottomWidth: 1,
                    borderBottomColor: Appearance.colors.divider()
                }}>
                    <TextField
                    icon={'search'}
                    placeholder={'Search by first or last name...'}
                    onChange={text => setSearchText(text.toLowerCase())}/>
                </View>
                {loading && (
                    <View style={{
                        paddingHorizontal: 15
                    }}>
                        <View style={{
                            ...Appearance.styles.panel(),
                            padding: 20,
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <LottieView
                            autoPlay={true}
                            loop={true}
                            source={Appearance.themeStyle() === 'dark' ? require('eCarra/files/lottie/dots-white.json') : require('eCarra/files/lottie/dots-grey.json')}
                            duration={2500}
                            style={{
                                width: 50
                            }}/>
                        </View>
                    </View>
                )}
                <View style={{
                    flexGrow: 1,
                    width: '100%',
                    padding: 15
                }}>
                    <FlatList
                    {...getFlatList()}
                    showsVerticalScrollIndicator={false}
                    onScroll={() => Keyboard.dismiss()}
                    style={{
                        ...Appearance.styles.panel(),
                        width: '100%'
                    }} />
                </View>
            </View>
        </Layer>
    )
}
export default ContactsPicker;
