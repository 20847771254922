import React, { useRef, useState, useEffect } from 'react';
import { Animated, AppState, Image, Keyboard, Linking, Platform, ScrollView, Share, Text, View } from 'react-native';

import moment from 'moment-timezone';
import update from 'immutability-helper';

import API from 'eCarra/files/api.js';
import Abstract from 'eCarra/classes/Abstract.js';
import AddressLookupField from 'eCarra/views/AddressLookupField.js';
import Appearance from 'eCarra/styles/Appearance.js';
import AsyncStorage from '@react-native-community/async-storage';
import BoolToggle from 'eCarra/views/BoolToggle.js';
import Button from 'eCarra/views/Button.js';
import Company from 'eCarra/classes/Company.js';
import DatePicker from 'eCarra/views/DatePicker/';
import DeviceInfo from 'react-native-device-info';
import DropdownHeader from 'eCarra/views/DropdownHeader.js';
import DualDatePicker from 'eCarra/views/DualDatePicker.js';
import { GoogleSignin, statusCodes } from 'eCarra/files/Google/';
import ImagePicker from 'eCarra/files/ImagePicker/';
import Layer, { LayerShell } from 'eCarra/structure/Layer.js';
import List from 'eCarra/views/List.js';
import LottieView from 'eCarra/views/Lottie/';
import Order from 'eCarra/classes/Order.js';
import Panel from 'eCarra/structure/Panel.js';
import Permissions, { PERMISSIONS, RESULTS } from 'eCarra/files/Permissions/';
import PushNotification from 'eCarra/files/PushNotification/';
import { QRCode } from 'eCarra/views/QRCode/';
import ReactNativeBiometrics from 'react-native-biometrics';
import Request from 'eCarra/files/Request/';
import Reservation from 'eCarra/classes/Reservation.js';
import RNFetchBlob from 'eCarra/files/FetchBlob/';
import * as RNLocalize from 'react-native-localize';
import Screen from 'eCarra/files/Screen.js';
import { ShareContent } from 'eCarra/managers/Emissions.js';
import Svg, { G, Path, Polyline, Rect, Use, Defs, Mask, Polygon, Filter, Circle } from 'react-native-svg';
import TextField from 'eCarra/views/TextField.js';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import TouchableHighlight from 'eCarra/views/TouchableHighlight/';
import User from 'eCarra/classes/User.js';
import Utils from 'eCarra/files/Utils.js';
import Views, { AltBadge } from 'eCarra/views/Main.js';

export const privacyOptions = [{
    key: 'faceID',
    title: 'Face ID',
    message: 'Use Face ID to quickly and securely login to your account without having to remember or type in your username or password. We will never share your biometric data with third parties or advertisers.',
    visible: Platform.OS === 'ios',
    lottie: require('eCarra/files/lottie/permissions-face-id.json'),
    icon: {
        path: require('eCarra/images/profile-privacy-face-id-clear.png'),
        style: {
            backgroundColor: Appearance.colors.grey()
        }
    }
},{
    key: 'location',
    title: 'Location',
    message: `We use your location to connect you with nearby drivers and suggest relevant destinations around you. We use your background location, when the app is closed, to provide navigation based services when you share your location with friends and family.`,
    lottie: require('eCarra/files/lottie/permissions-location.json'),
    icon: {
        path: require('eCarra/images/profile-privacy-location-clear.png'),
        style: {
            backgroundColor: Appearance.colors.grey()
        }
    }
},{
    key: 'notifications',
    title: 'Notifications',
    message: 'We use notifications you keep you up to date with your Reservations and Orders. We also use notifications to share discounts, help you communicate with your driver, and keep you up to date with new features',
    lottie: require('eCarra/files/lottie/permissions-notifications.json'),
    icon: {
        path: require('eCarra/images/profile-privacy-notifications-clear.png'),
        style: {
            backgroundColor: Appearance.colors.grey()
        }
    }
},{
    key: 'bluetooth',
    title: 'Bluetooth',
    message: 'We use Bluetooth to connect to Seedpod, our specialty in-car sensor that provides an elevated driver and customer experience. Please contact your eCarra representative to learn more about SeedPod.',
    lottie: () => {
        return Appearance.themeStyle() === 'dark' ? require('eCarra/files/lottie/permissions-bluetooth-dark.json') : require('eCarra/files/lottie/permissions-bluetooth.json')
    },
    icon: {
        path: require('eCarra/images/profile-privacy-bluetooth-clear.png'),
        style: {
            backgroundColor: Appearance.colors.grey()
        }
    }
}];

export const getCurrentPermissions = async utils => {
    return new Promise(async (resolve, reject) => {
        try {
            let { foreground } = await checkLocationPermission(utils);
            let results = {
                faceID: await checkFaceIDPermission(),
                location: foreground,
                notifications: await checkNotificationPermission()
            };
            resolve(results);
        } catch(e) {
            reject(e);
        }
    })
}

export const checkLocationPermission = async utils => {
    return new Promise(async (resolve, reject) => {
        try {
            let result = await utils.location.check();
            resolve(result);
        } catch(e) {
            reject(e);
        }
    })
}

export const requestLocationPermission = async utils => {
    return new Promise(async (resolve, reject) => {
        try {
            let result = await utils.location.request();
            resolve(result);
        } catch(e) {
            reject(e);
        }
    })
}

export const checkFaceIDPermission = async () => {
    return new Promise(async (resolve, reject) => {
        if(Platform.OS === 'web' || Platform.OS === 'android') {
            resolve(true);
            return;
        }
        try {
            let result = await Permissions.check(PERMISSIONS.IOS.FACE_ID);
            resolve(result === RESULTS.GRANTED);
        } catch(e) {
            reject(e);
        }
    })
}

export const requestFaceIDPermission = async utils => {
    return new Promise(async (resolve, reject) => {
        if(Platform.OS === 'web' || Platform.OS === 'android') {
            resolve(true);
            return;
        }
        try {
            let result = await Permissions.request(PERMISSIONS.IOS.FACE_ID);
            resolve(result === RESULTS.GRANTED);
        } catch(e) {
            reject(e);
        }
    })
}

export const checkBluetoothPermission = async utils => {
    return new Promise(async (resolve, reject) => {
        if(Platform.OS === 'web' || Platform.OS === 'android') {
            resolve(true);
            return;
        }
        try {
            let result = await Permissions.check(PERMISSIONS.IOS.BLUETOOTH_PERIPHERAL);
            resolve(result === RESULTS.GRANTED);
        } catch(e) {
            reject(false);
        }
    })
}

export const requestBluetoothPermission = async utils => {
    return new Promise(async (resolve, reject) => {
        if(Platform.OS === 'web' || Platform.OS === 'android') {
            resolve(true);
            return;
        }
        try {
            let result = await Permissions.request(PERMISSIONS.IOS.BLUETOOTH_PERIPHERAL);
            resolve(result === RESULTS.GRANTED);
        } catch(e) {
            reject(false);
        }
    })
}

export const checkNotificationPermission = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            if(Platform.OS === 'web') {
                resolve(true);
                return;
            }
            let { status } = await Permissions.checkNotifications();
            resolve(status === RESULTS.GRANTED);
        } catch(e) {
            reject(false);
        }
    })
}

export const requestNotificationPermission = async utils => {
    return new Promise(async (resolve, reject) => {
        try {
            if(Platform.OS === 'web') {
                resolve(true);
                return;
            }
            let result = await PushNotification.requestPermissions();
            if(typeof(result) === 'object') {
                resolve(result.alert === true && result.sound === true && result.badge === true);
                return;
            }
            resolve(result);

        } catch(e) {
            reject(e);
        }
    })
}

// Layers
export const ResetPassword = ({ index, options, utils }) => {

    const layerID = 'reset-password';
    const [layerState, setLayerState] = useState(null);
    const [password, setPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [newPasswordConfirm, setNewPasswordConfirm] = useState(null);

    const fields = [{
        key: 'original',
        placeholder: 'Current Password',
        icon: 'lock',
        onChange: text => setPassword(text)
    },{
        key: 'new',
        placeholder: 'New Password',
        icon: 'lock',
        onChange: text => setNewPassword(text)
    },{
        key: 'confirm',
        placeholder: 'Confirm New Password',
        icon: 'lock',
        onChange: text => setNewPasswordConfirm(text)
    }]

    const onSubmitPassword = async () => {

        Keyboard.dismiss();
        if(!password || !newPassword || !newPasswordConfirm) {
            utils.alert.show({
                title: 'Just a Second',
                message: `Please provide your current password and your new password before moving on`
            });
            return;
        }

        try {
            let { token } = await Request.post(utils, '/user/', {
                type: 'reset_password',
                user_id: utils.user.get().user_id,
                password: password,
                new_password: newPassword,
                new_password_confirm: newPasswordConfirm,
                phone_number: utils.user.get().phone_number // second layer of validation
            });

            if(token) {
                await AsyncStorage.setItem('login', token)
            }
            utils.alert.show({
                title: 'All Done!',
                message: 'Your password has been reset',
                onPress: () => setLayerState('close')
            });
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue resetting your password. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onBeforeClose = (validate) => {
        if(!newPassword && !newPasswordConfirm) {
            validate();
            return;
        }
        utils.alert.show({
            title: 'Just a Second',
            message: `It looks like you started resetting your password but didn't finish. Do you want to continue changing your password?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: 'cancel'
            }],
            onPress: (key) => {
                if(key !== 'confirm') {
                    validate();
                }
            }
        })
    }

    return (
        <Layer
        id={layerID}
        title={'Reset Password'}
        utils={utils}
        index={index}
        options={{
            ...options,
            bottomCard: true,
            layerState: layerState,
            beforeCloseOnTap: onBeforeClose
        }}
        buttons={[{
            key: 'done',
            text: 'Done',
            color: 'primary',
            onPress: onSubmitPassword
        }]}>
            <View style={{
                alignItems: 'center',
                marginTop: 5,
                width: '100%'
            }}>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 6
                }}>{'Reset Password'}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 20,
                    textAlign: 'center'
                }}>{'Please enter your current password below and the password you would like you use moving forward. Your password must be at least 8 characters long, contain at least one capital letter, and at least one number.'}</Text>

                {fields.map((field, index) => {
                    return (
                        <TextField
                        key={index}
                        isSecure={true}
                        placeholder={field.placeholder}
                        icon={field.icon}
                        onChange={field.onChange}
                        containerStyle={{
                            marginBottom: index !== fields.length - 1 ? 8:0
                        }}/>
                    )
                })}
            </View>
        </Layer>
    )
}

export const EditProfileItem = ({ title, message, header, children, item, onChange, onChangeAsync, onRemove }, { index, options, utils }) => {

    const layerID = `edit-profile-${item.key}`;
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onDonePress = async () => {
        try {
            if(typeof(onChangeAsync) === 'function') {
                setLoading(true);
                await onChangeAsync();
                setLoading(false);

                Keyboard.dismiss();
                setLayerState('close');
                return;
            }

            Keyboard.dismiss();
            await Utils.sleep(keyboardOpen ? 0.1 : 0.5);
            if(typeof(onChange) === 'function') {
                onChange(() => setLayerState('close'));
            }
        } catch(e) {
            setLoading(false);
            console.error(e.message);
        }
    }

    const onRemovePress = async () => {
        try {
            Keyboard.dismiss();
            if(typeof(onRemove) === 'function') {
                await Utils.sleep(keyboardOpen ? 0.1 : 0.5);
                onRemove(() => setLayerState('close'));
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    const getButtons = () => {
        let buttons = [{
            key: 'done',
            text: 'Done',
            color: 'primary',
            loading: loading,
            onPress: onDonePress
        }]
        if(typeof(onRemove) === 'function') {
            buttons.unshift({
                key: 'remove',
                text: 'Remove',
                color: 'grey',
                visible: item.key === 'company',
                onPress: onRemovePress
            })
        }
        return buttons;
    }

    useEffect(() => {
        utils.keyboard.subscribe(layerID, {
            onVisibility: visible => setKeyboardOpen(visible)
        });

        return () => {
            utils.keyboard.unsubscribe(layerID);
        }
    }, [])

    return (
        <Layer
        id={layerID}
        title={title}
        utils={utils}
        index={index}
        buttons={getButtons()}
        options={{
            ...options,
            bottomCard: true,
            layerState: layerState
        }}>
            <View style={{
                alignItems: 'center',
                marginTop: 5
            }}>
                {header}
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 6
                }}>{title}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 20,
                    textAlign: 'center'
                }}>{message}</Text>
                {children()}
            </View>
        </Layer>
    )
}

export const DriverTimeCards = ({ abstract, index, options, utils }) => {

    const layerID = `driver-timecards-${abstract.getID()}`;
    const [timecards, setTimecards] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(moment());

    const onTimecardPress = timecard => {
        utils.alert.show({
            title: 'Timecard Details',
            message: `This timecard started on ${moment(timecard.startDate).format('MMMM Do, YYYY [at] h:mm:ssa')}${timecard.endDate ? `, ended on ${moment(timecard.endDate).format('MMMM Do, YYYY [at] h:mm:ssa')}, and accumulated ${timecard.endDate ? Utils.parseDuration(moment(timecard.endDate).unix() - moment(timecard.startDate).unix()) : 'an unknown amount of time'}` : ' and is currently in-progress. More information will be available once you end this driving session'}.`
        })
    }

    const onCustomDate = () => {

        let tempDate = null;

        utils.layer.open({
            id: 'calendar-custom-date',
            Component: LayerShell.bind(this, {
                layerID: 'calendar-custom-date',
                extendedOptions: {
                    bottomCard: true
                },
                buttons: [{
                    key: 'done',
                    text: 'Done',
                    color: 'primary',
                    onPress: () => {
                        utils.events.emit('onLayerAction', {
                            action: 'close',
                            layerID: 'calendar-custom-date'
                        })
                        setDate(tempDate ? moment(tempDate) : date)
                    }
                }],
                children: (
                    <View style={{
                        alignItems: 'center',
                        marginTop: 5
                    }}>
                        <Text style={{
                            ...Appearance.textStyles.panelTitle(),
                            marginBottom: 6
                        }}>{'Browse Other Dates'}</Text>
                        <Text style={{
                            ...Appearance.textStyles.subTitle(),
                            marginBottom: 20,
                            textAlign: 'center'
                    }}>{'Choose a date to view past timecards'}</Text>
                        <DatePicker
                        utils={utils}
                        date={date}
                        onChange={date => tempDate = date}/>
                    </View>
                )
            })
        })
    }

    const formatHeader = () => {

        if(moment(date).isSame(moment(), 'day')) {
            return `Today's Timecards`;
        }

        if(moment(date).isSame(moment().add(1, 'days'), 'day')) {
            return `Tomorrow's Timecards`;
        }

        if(moment(date).isSame(moment().subtract(1, 'days'), 'day')) {
            return `Yesterday's Timecards`;
        }

        return `Timecards for ${moment(date).format('MMM Do')}`;
    }

    const fetchTimecards = async download => {
        try {
            setLoading(true);
            let { url, timecards } = await Request.get(utils, '/driver/', {
                type: 'timecards',
                date: moment(date).format('YYYY-MM-DD'),
                user_id: abstract.getID(),
                download: download
            });

            setLoading(false);
            if(url) {
                let resource = await RNFetchBlob.config({
                    fileCache: true,
                    path: `${RNFetchBlob.fs.dirs.CacheDir}/driver-timecard-${utils.user.get().user_id}.xls`
                }).fetch('GET', url);

                Share.share({
                    url: resource.path(), // iOS
                    message: Platform.OS === 'ios' ? null : resource.path(),  // Android
                    title: Platform.OS === 'ios' ? null : 'Driver Timecard' // Android
                })
                return;
            }

            setTimecards(timecards.map(timecard => ({
                id: timecard.id,
                user_id: timecard.user_id,
                startDate: timecard.start_date,
                endDate: timecard.end_date,
                vehicle: timecard.vehicle
            })));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the timecards. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchTimecards();
    }, [date]);

    return (
        <Layer
        id={layerID}
        title={'Timecards'}
        utils={utils}
        index={index}
        options={{
            ...options,
            layerState: layerState,
            bottomCard: true,
            useTouchableForKeyboard : false
        }}
        buttons={[{
            key: 'download',
            text: 'Download',
            loading: loading,
            color: 'grey',
            onPress: fetchTimecards.bind(this, true)
        }]}>

            <DropdownHeader
            label={formatHeader()}
            onPress={onCustomDate}
            style={{
                justifyContent: 'center',
                marginBottom: 8
            }}/>
            <Text style={{
                ...Appearance.textStyles.subTitle(),
                textAlign: 'center',
                marginBottom: 15
            }}>{`Timecards are automatically started when you add your vehicle in the ${utils.client.get().name} app. Timecards are automatically closed when you return your vehicle (or if more than 24 hours have passed since the timecard start date).`}</Text>

            <View style={Appearance.styles.panel()}>
                <ScrollView
                showsVerticalScrollIndicator={false}
                style={{
                    width: '100%',
                    maxHeight: Screen.height() / 2
                }}
                contentContainerStyle={{
                    paddingBottom: 15
                }}>
                    {timecards.length === 0
                        ?
                        Views.entry({
                            title: 'No Timecards Found',
                            subTitle: `There are no timecards for ${moment(date).format('MMM Do, YYYY')}`,
                            propStyles: {
                                subTitle: {
                                    numberOfLines: 2
                                }
                            },
                            icon: {
                                path: require('eCarra/images/tesla-model-x.png'),
                                style: {
                                    ...Appearance.icons.vehicle(),
                                    borderWidth: 1,
                                    borderColor: Appearance.colors.softBorder()
                                }
                            },
                            bottomBorder: false
                        })
                        :
                        timecards.map((timecard, index) => {
                            return (
                                Views.entry({
                                    key: index,
                                    title: `${timecard.vehicle.name} (${timecard.vehicle.short_vin})`,
                                    subTitle: moment(timecard.startDate).format('MMMM Do, YYYY [at] h:mma'),
                                    badge: {
                                        text: timecard.endDate ? Utils.parseDuration(moment(timecard.endDate).unix() - moment(timecard.startDate).unix()) : null,
                                        color: Appearance.colors.primary()
                                    },
                                    bottomBorder: index !== timecards.length - 1,
                                    onPress: onTimecardPress.bind(this, timecard),
                                    icon: {
                                        path: require('eCarra/images/tesla-model-x.png'),
                                        style: {
                                            ...Appearance.icons.vehicle(),
                                            borderWidth: 1,
                                            borderColor: Appearance.colors.softBorder(),
                                            ...!timecard.endDate && {
                                                backgroundColor: Appearance.colors.primary()
                                            }
                                        }
                                    }
                                })
                            )
                        })
                    }
                </ScrollView>
            </View>
        </Layer>
    )
}

export const DriverSchedule = ({ abstract, index, options, utils }) => {

    const layerID = `driver-schedule-${abstract.getID()}`;
    const [schedule, setSchedule] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(moment());

    const onCustomDate = () => {

        let tempDate = null;

        utils.layer.open({
            id: 'calendar-custom-date',
            Component: LayerShell.bind(this, {
                layerID: 'calendar-custom-date',
                extendedOptions: {
                    bottomCard: true
                },
                buttons: [{
                    key: 'done',
                    text: 'Done',
                    color: 'primary',
                    onPress: () => {
                        utils.events.emit('onLayerAction', {
                            action: 'close',
                            layerID: 'calendar-custom-date'
                        })
                        setDate(tempDate ? moment(tempDate) : date)
                    }
                }],
                children: (
                    <View style={{
                        alignItems: 'center',
                        marginTop: 5
                    }}>
                        <Text style={{
                            ...Appearance.textStyles.panelTitle(),
                            marginBottom: 6
                        }}>{'Browse Other Dates'}</Text>
                        <Text style={{
                            ...Appearance.textStyles.subTitle(),
                            marginBottom: 20,
                            textAlign: 'center'
                    }}>{'Choose a date to view past or future schedules'}</Text>
                        <DatePicker
                        utils={utils}
                        date={date}
                        onChange={date => tempDate = date}/>
                    </View>
                )
            })
        })
    }

    const formatHeader = () => {

        if(moment(date).isSame(moment(), 'week')) {
            return `This Week's Schedule`;
        }

        if(moment(date).isSame(moment().add(1, 'weeks'), 'week')) {
            return `Last Week's Schedule`;
        }

        if(moment(date).isSame(moment().subtract(1, 'weeks'), 'week')) {
            return `Last Week's Schedule`;
        }

        return `Schedule for Week of ${moment(date).format('MMM Do')}`;
    }

    const onScheduleEntryPress = (entry, time) => {
        utils.sheet.show({
            title: moment(entry.date).format('dddd, MMM Do'),
            message: `Timeslot for ${moment(time.start).format('h:mma')} to ${moment(time.end).format('h:mma')}`,
            items: [{
                key: 'support',
                title: 'Get Support',
                style: 'default'
            }]
        }, (key) => {
            if(key === 'support') {
                Utils.showSupportOptions(utils, {
                    message: `I have a question about my driving schedule. The timeslot is for ${moment(time.start).format('h:mma')} to ${moment(time.end).format('h:mma')} on ${moment(entry.date).format('dddd, MMMM Do, YYYY')}.`
                });
            }
        })
    }

    const fetchSchedule = async download => {
        try {
            setLoading(true);
            let { url, schedule } = await Request.get(utils, '/driver/', {
                type: 'schedule',
                start_date: moment(date).startOf('week').format('YYYY-MM-DD'),
                end_date: moment(date).endOf('week').format('YYYY-MM-DD'),
                download: download
            });

            setLoading(false);
            if(url) {
                let resource = await RNFetchBlob.config({
                    fileCache: true,
                    path: `${RNFetchBlob.fs.dirs.CacheDir}/driver-schedule-${utils.user.get().user_id}.xls`
                }).fetch('GET', url);

                Share.share({
                    url: resource.path(), // iOS
                    message: Platform.OS === 'ios' ? null : resource.path(),  // Android
                    title: Platform.OS === 'ios' ? null : 'Driver Schedule' // Android
                })
                return;
            }
            setSchedule(schedule);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the schedule items. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchSchedule();
    }, [date]);

    return (
        <Layer
        id={layerID}
        title={'Schedule'}
        utils={utils}
        index={index}
        options={{
            ...options,
            layerState: layerState,
            bottomCard: true,
            removePadding: true,
            useTouchableForKeyboard : false
        }}
        buttons={[{
            key: 'download',
            text: 'Download',
            loading: loading,
            color: 'grey',
            onPress: fetchSchedule.bind(this, true)
        }]}>

            <View style={{
                paddingTop: 15,
                paddingHorizontal: 15
            }}>
                <DropdownHeader
                label={formatHeader()}
                onPress={onCustomDate}
                style={{
                    justifyContent: 'center',
                    marginBottom: 8
                }}/>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    textAlign: 'center',
                    marginBottom: 15
                }}>{`Your schedule will become available as it is posted. Your schedule is broken up into individual weeks and lists driver timeslots for each day that you are scheduled to drive.`}</Text>
            </View>

            <ScrollView
            showsVerticalScrollIndicator={false}
            style={{
                width: '100%',
                maxHeight: Screen.height() / 2,
                paddingHorizontal: 15
            }}contentContainerStyle={{
                paddingBottom: 15
            }}>
                {schedule.length === 0
                    ?
                    <View style={Appearance.styles.panel()}>
                        {Views.entry({
                            title: 'No Schedule Found',
                            subTitle: `There are no schedule timeslots for ${moment(date).format('MMM Do')}`,
                            propStyles: {
                                subTitle: {
                                    numberOfLines: 2
                                }
                            },
                            icon: {
                                path: require('eCarra/images/tesla-model-x.png'),
                                style: {
                                    ...Appearance.icons.vehicle(),
                                    borderWidth: 1,
                                    borderColor: Appearance.colors.softBorder()
                                }
                            },
                            bottomBorder: false
                        })}
                    </View>
                    :
                    schedule.map((entry, index) => {
                        return (
                            <View
                            key={index}
                            style={{
                                ...Appearance.styles.panel(),
                                flexDirection: 'column',
                                width: '100%',
                                marginBottom: index !== schedule.length - 1 ? 15 : 0
                            }}>
                                <View style={{
                                    paddingVertical: 8,
                                    paddingHorizontal: 12,
                                    borderBottomWidth: 1,
                                    borderBottomColor: Appearance.colors.divider()
                                }}>
                                    <Text style={Appearance.textStyles.title()}>{
                                        moment(entry.date).format('dddd, MMM Do')
                                    }</Text>
                                </View>
                                {entry.times.map((time, index) => {
                                    return (
                                        <TouchableHighlight
                                        key={index}
                                        activeOpacity={0.9}
                                        underlayColor={Appearance.colors.divider()}
                                        onPress={onScheduleEntryPress.bind(this, entry, time)}>
                                            <View style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                paddingVertical: 8,
                                                paddingHorizontal: 12,
                                                borderBottomWidth: index !== entry.times.length - 1 ? 1 : 0,
                                                borderBottomColor: Appearance.colors.divider()
                                            }}>
                                                <Image source={require('eCarra/images/driver-schedule-icon-small.png')} style={{
                                                    width: 20,
                                                    height: 20,
                                                    resizeMode: 'contain',
                                                    tintColor: Appearance.colors.grey(),
                                                    marginRight: 8
                                                }} />
                                                <Text style={{
                                                    ...Appearance.textStyles.subTitle(),
                                                    flexGrow: 1
                                                }}>{
                                                    `${moment(time.start).format('h:mma')} to ${moment(time.end).format('h:mma')}`
                                                }</Text>
                                                <Image source={require('eCarra/images/next-arrow-small.png')} style={{
                                                    width: 12,
                                                    height: 12,
                                                    resizeMode: 'contain',
                                                    tintColor: Appearance.colors.subText(),
                                                    marginLeft: 8
                                                }}/>
                                            </View>
                                        </TouchableHighlight>
                                    )
                                })}
                            </View>
                        )
                    })
                }
            </ScrollView>
        </Layer>
    )
}

export const DriverAvailability = ({ abstract, index, options, utils }) => {

    const layerID = `driver-availability-${abstract.getID()}`;
    const [availability, setAvailability] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onEntryPress = (type, entry, time) => {

        const supportOptions = {
            buttons: [{
                key: 'support',
                title: 'Get Support',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Dismiss',
                style: 'cancel'
            }],
            onPress: (key) => {
                if(key === 'support') {
                    switch(type) {
                        case 'available':
                        Utils.showSupportOptions(utils, {
                            message: `I have a question about my availability. The timeslot is for ${moment(time.start, 'HH:mm:ss').format('h:mma')} to ${moment(time.end, 'HH:mm:ss').format('h:mma')} on ${moment(entry.date).format('dddd, MMMM Do, YYYY')}.`
                        });
                        break;

                        case 'not_available':
                        Utils.showSupportOptions(utils, {
                            message: `I have a question about a time where I am not available. The timeslot is for ${moment(time.start, 'HH:mm:ss').format('h:mma')} to ${moment(time.end, 'HH:mm:ss').format('h:mma')} on ${moment(entry.date).format('dddd, MMMM Do, YYYY')}.`
                        });
                        break;

                        case 'preferred':
                        Utils.showSupportOptions(utils, {
                            message: `I have a question about my preferred availability. The timeslot is for ${moment(time.start, 'HH:mm:ss').format('h:mma')} to ${moment(time.end, 'HH:mm:ss').format('h:mma')} on ${moment(entry.date).format('dddd, MMMM Do, YYYY')}.`
                        });
                        break;
                    }
                }
            }
        }

        switch(type) {
            case 'available':
            utils.alert.show({
                title: moment(entry.day, 'd').format('dddd'),
                message: `Your availability shows that you are normally available on ${moment(entry.day, 'd').format('dddd')} from ${moment(time.start, 'HH:mm:ss').format('h:mma')} to ${moment(time.end, 'HH:mm:ss').format('h:mma')}. Please submit new preferred availability if this timeslot no longer works with your schedule.`,
                ...supportOptions
            });
            break;

            case 'not_available':
            utils.alert.show({
                title: moment(entry.day, 'd').format('dddd'),
                message: `Your availability shows that you are normally not available on ${moment(entry.day, 'd').format('dddd')} from ${moment(time.start, 'HH:mm:ss').format('h:mma')} to ${moment(time.end, 'HH:mm:ss').format('h:mma')}. We will do our best to honor this non-available timeslot. Please submit new preferred availability if this timeslot becomes available.`,
                ...supportOptions
            });
            break;

            case 'preferred':
            utils.alert.show({
                title: moment(entry.day, 'd').format('dddd'),
                message: `Your preferred availability shows that you would prefer to drive on ${moment(entry.day, 'd').format('dddd')} from ${moment(time.start, 'HH:mm:ss').format('h:mma')} to ${moment(time.end, 'HH:mm:ss').format('h:mma')}. We will do our best to honor this preferred timeslot. Please submit new preferred availability if your preferences change.`,
                ...supportOptions
            });
            break;
        }
    }

    const onOptionPress = () => {
        utils.sheet.show({
            items: [{
                key: 'new',
                title: 'New Preferred Availability',
                style: 'default'
            }]
        }, (key) => {
            if(key === 'new') {
                utils.layer.open({
                    id: 'driver-new-preferred-availability',
                    Component: DriverNewPreferredAvailability
                })
            }
        })
    }

    const fetchAvailability = async download => {
        try {
            setLoading(true);
            let { url, availability } = await Request.get(utils, '/driver/', {
                type: 'availability',
                download: download
            });

            setLoading(false);
            if(url) {
                let resource = await RNFetchBlob.config({
                    fileCache: true,
                    path: `${RNFetchBlob.fs.dirs.CacheDir}/driver-schedule-${utils.user.get().user_id}.xls`
                }).fetch('GET', url);

                Share.share({
                    url: resource.path(), // iOS
                    message: Platform.OS === 'ios' ? null : resource.path(),  // Android
                    title: Platform.OS === 'ios' ? null : 'Driver Schedule' // Android
                })
                return;
            }
            setAvailability(availability);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the availability information. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getAvailable = () => {
        return availability.available ? (
            <View style={{
                ...Appearance.styles.panel(),
                flexDirection: 'column',
                width: '100%',
                marginBottom: 15
            }}>
                <View style={{
                    paddingVertical: 8,
                    paddingHorizontal: 12,
                    borderBottomWidth: 1,
                    borderBottomColor: Appearance.colors.divider()
                }}>
                    <Text style={Appearance.textStyles.title()}>{'Current Availability'}</Text>
                </View>
                {availability.available.map((entry, topIndex) => {
                    return (
                        <View key={topIndex}>
                            <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingVertical: 8,
                                paddingHorizontal: 12,
                                borderBottomWidth: 1,
                                borderBottomColor: Appearance.colors.divider()
                            }}>
                                <Image source={require('eCarra/images/driver-schedule-icon-small.png')} style={{
                                    width: 20,
                                    height: 20,
                                    resizeMode: 'contain',
                                    tintColor: Appearance.colors.grey(),
                                    marginRight: 8
                                }} />
                                <Text style={{
                                    ...Appearance.textStyles.subTitle(),
                                    color: Appearance.colors.text(),
                                    flexGrow: 1
                                }}>{
                                    moment(entry.day, 'd').format('dddd')
                                }</Text>
                            </View>

                            {entry.times.map((time, index) => {
                                return (
                                    <TouchableHighlight
                                    key={index}
                                    activeOpacity={0.9}
                                    underlayColor={Appearance.colors.divider()}
                                    onPress={onEntryPress.bind(this, 'available', entry, time)}>
                                        <View style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            paddingVertical: 8,
                                            paddingHorizontal: 12,
                                            borderBottomColor: Appearance.colors.divider(),
                                            borderBottomWidth: index === entry.times.length - 1 && topIndex === availability.available.length - 1 ? 0 : 1
                                        }}>
                                            <View style={{
                                                width: 20,
                                                height: 20,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginRight: 8
                                            }}>
                                                <View style={{
                                                    width: 5,
                                                    height: 5,
                                                    backgroundColor: Appearance.colors.grey(),
                                                    borderRadius: 2.5,
                                                    overflow: 'hidden'
                                                }} />
                                            </View>
                                            <Text style={{
                                                ...Appearance.textStyles.subTitle(),
                                                flexGrow: 1
                                            }}>{
                                                `${moment(time.start, 'HH:mm:ss').format('h:mma')} to ${moment(time.end, 'HH:mm:ss').format('h:mma')}`
                                            }</Text>

                                            <Image source={require('eCarra/images/next-arrow-small.png')} style={{
                                                width: 12,
                                                height: 12,
                                                resizeMode: 'contain',
                                                tintColor: Appearance.colors.subText(),
                                                marginLeft: 8
                                            }}/>
                                        </View>
                                    </TouchableHighlight>
                                )
                            })}
                        </View>
                    )
                })}
            </View>
        ) : null;
    }

    const getNotAvailable = () => {
        return availability.not_available ? (
            <View style={{
                ...Appearance.styles.panel(),
                flexDirection: 'column',
                width: '100%',
                marginBottom: 15
            }}>
                <View style={{
                    paddingVertical: 8,
                    paddingHorizontal: 12,
                    borderBottomWidth: 1,
                    borderBottomColor: Appearance.colors.divider()
                }}>
                    <Text style={Appearance.textStyles.title()}>{'Not Available'}</Text>
                </View>
                {availability.not_available.days ?
                    availability.not_available.days.map((day, index) => {
                        return (
                            <View key={index}>
                                <View style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    paddingVertical: 8,
                                    paddingHorizontal: 12,
                                    borderBottomWidth: 1,
                                    borderBottomColor: Appearance.colors.divider()
                                }}>
                                    <Image source={require('eCarra/images/driver-schedule-icon-small.png')} style={{
                                        width: 20,
                                        height: 20,
                                        resizeMode: 'contain',
                                        tintColor: Appearance.colors.grey(),
                                        marginRight: 8
                                    }} />
                                    <Text style={{
                                        ...Appearance.textStyles.subTitle(),
                                        color: Appearance.colors.text(),
                                        flexGrow: 1
                                    }}>{
                                        `${moment(day, 'd').format('dddd')} - All Day`
                                    }</Text>
                                </View>
                            </View>
                        )
                    })
                    :
                    null
                }
                {availability.not_available.timeslots
                    ?
                    availability.not_available.timeslots.map((entry, topIndex) => {
                        return (
                            <View key={topIndex}>
                                <View style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    paddingVertical: 8,
                                    paddingHorizontal: 12,
                                    borderBottomWidth: 1,
                                    borderBottomColor: Appearance.colors.divider()
                                }}>
                                    <Image source={require('eCarra/images/driver-schedule-icon-small.png')} style={{
                                        width: 20,
                                        height: 20,
                                        resizeMode: 'contain',
                                        tintColor: Appearance.colors.grey(),
                                        marginRight: 8
                                    }} />
                                    <Text style={{
                                        ...Appearance.textStyles.subTitle(),
                                        color: Appearance.colors.text(),
                                        flexGrow: 1
                                    }}>{
                                        moment(entry.day, 'd').format('dddd')
                                    }</Text>
                                </View>

                                {entry.times.map((time, index) => {
                                    return (
                                        <TouchableHighlight
                                        key={index}
                                        activeOpacity={0.9}
                                        underlayColor={Appearance.colors.divider()}
                                        onPress={onEntryPress.bind(this, 'not_available', entry, time)}>
                                            <View style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                paddingVertical: 8,
                                                paddingHorizontal: 12,
                                                borderBottomColor: Appearance.colors.divider(),
                                                borderBottomWidth: index === entry.times.length - 1 && topIndex === availability.not_available.timeslots.length - 1 ? 0 : 1
                                            }}>
                                                <View style={{
                                                    width: 20,
                                                    height: 20,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginRight: 8
                                                }}>
                                                    <View style={{
                                                        width: 5,
                                                        height: 5,
                                                        backgroundColor: Appearance.colors.grey(),
                                                        borderRadius: 2.5,
                                                        overflow: 'hidden'
                                                    }} />
                                                </View>
                                                <Text style={{
                                                    ...Appearance.textStyles.subTitle(),
                                                    flexGrow: 1
                                                }}>{
                                                    `${moment(time.start, 'HH:mm:ss').format('h:mma')} to ${moment(time.end, 'HH:mm:ss').format('h:mma')}`
                                                }</Text>

                                                <Image source={require('eCarra/images/next-arrow-small.png')} style={{
                                                    width: 12,
                                                    height: 12,
                                                    resizeMode: 'contain',
                                                    tintColor: Appearance.colors.subText(),
                                                    marginLeft: 8
                                                }}/>
                                            </View>
                                        </TouchableHighlight>
                                    )
                                })}
                            </View>
                        )
                    })
                    :
                    null
                }
            </View>
        ) : null;
    }

    const getPreferredAvailable = () => {
        return availability.preferred ? (
            <View style={{
                ...Appearance.styles.panel(),
                flexDirection: 'column',
                width: '100%',
                marginBottom: 15
            }}>
                <View style={{
                    paddingVertical: 8,
                    paddingHorizontal: 12,
                    borderBottomWidth: 1,
                    borderBottomColor: Appearance.colors.divider()
                }}>
                    <Text style={Appearance.textStyles.title()}>{'Preferred Availability'}</Text>
                </View>
                {availability.preferred.map((entry, topIndex) => {
                    return (
                        <View key={topIndex}>
                            <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingVertical: 8,
                                paddingHorizontal: 12,
                                borderBottomWidth: 1,
                                borderBottomColor: Appearance.colors.divider()
                            }}>
                                <Image source={require('eCarra/images/driver-schedule-icon-small.png')} style={{
                                    width: 20,
                                    height: 20,
                                    resizeMode: 'contain',
                                    tintColor: Appearance.colors.grey(),
                                    marginRight: 8
                                }} />
                                <Text style={{
                                    ...Appearance.textStyles.subTitle(),
                                    color: Appearance.colors.text(),
                                    flexGrow: 1
                                }}>{
                                    moment(entry.day, 'd').format('dddd')
                                }</Text>
                            </View>

                            {entry.times.map((time, index) => {
                                return (
                                    <TouchableHighlight
                                    key={index}
                                    activeOpacity={0.9}
                                    underlayColor={Appearance.colors.divider()}
                                    onPress={onEntryPress.bind(this, 'preferred', entry, time)}>
                                        <View style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            paddingVertical: 8,
                                            paddingHorizontal: 12,
                                            borderBottomColor: Appearance.colors.divider(),
                                            borderBottomWidth: index === entry.times.length - 1 && topIndex === availability.preferred.length - 1 ? 0 : 1
                                        }}>
                                            <View style={{
                                                width: 20,
                                                height: 20,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginRight: 8
                                            }}>
                                                <View style={{
                                                    width: 5,
                                                    height: 5,
                                                    backgroundColor: Appearance.colors.grey(),
                                                    borderRadius: 2.5,
                                                    overflow: 'hidden'
                                                }} />
                                            </View>
                                            <Text style={{
                                                ...Appearance.textStyles.subTitle(),
                                                flexGrow: 1
                                            }}>{
                                                `${moment(time.start, 'HH:mm:ss').format('h:mma')} to ${moment(time.end, 'HH:mm:ss').format('h:mma')}`
                                            }</Text>

                                            <Image source={require('eCarra/images/next-arrow-small.png')} style={{
                                                width: 12,
                                                height: 12,
                                                resizeMode: 'contain',
                                                tintColor: Appearance.colors.subText(),
                                                marginLeft: 8
                                            }}/>
                                        </View>
                                    </TouchableHighlight>
                                )
                            })}
                        </View>
                    )
                })}
            </View>
        ) : null;
    }

    useEffect(() => {
        fetchAvailability();
    }, []);

    return (
        <Layer
        id={layerID}
        title={'Availability'}
        utils={utils}
        index={index}
        options={{
            ...options,
            layerState: layerState,
            bottomCard: true,
            removePadding: true,
            useTouchableForKeyboard : false
        }}
        buttons={[{
            key: 'download',
            text: 'Download',
            loading: loading,
            color: 'grey',
            onPress: fetchAvailability.bind(this, true)
        },{
            key: 'options',
            text: 'Options',
            color: 'primary',
            onPress: onOptionPress
        }]}>

            <View style={{
                paddingTop: 15,
                paddingHorizontal: 15
            }}>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 8,
                    textAlign: 'center',
                    width: '100%'
                }}>{'My Availability'}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    textAlign: 'center',
                    marginBottom: 15
                }}>{`Your current availability is listed below. If needed, you can submit a request to change your availability for future scheduling.`}</Text>
            </View>

            <ScrollView
            showsVerticalScrollIndicator={false}
            style={{
                width: '100%',
                maxHeight: Screen.height() / 2,
                paddingHorizontal: 15
            }}contentContainerStyle={{
                paddingBottom: 15
            }}>
                {/* availble timeslots */}
                {availability
                    ?
                    <>
                    {getAvailable()}
                    {getNotAvailable()}
                    {getPreferredAvailable()}
                    </>
                    :
                    <View styles={Appearance.styles.panel()}>
                        {Views.entry({
                            title: 'No Availability Found',
                            subTitle: 'Driver availability has not been setup',
                            bottomBorder: false,
                            hideIcon: true
                        })}
                    </View>

                }

            </ScrollView>
        </Layer>
    )
}

export const DriverNewPreferredAvailability = ({ abstract, index, options, utils }) => {

    const layerID = 'driver-new-preferred-availability';
    const [availability, setAvailability] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [preferred, setPreferred] = useState([{
        day: 0,
        times: []
    },{
        day: 1,
        times: []
    },{
        day: 2,
        times: []
    },{
        day: 3,
        times: []
    },{
        day: 4,
        times: []
    },{
        day: 5,
        times: []
    },{
        day: 6,
        times: []
    }])

    const onSubmitRequest = async () => {

    }

    const onNewTimeslot = (day) => {
        utils.layer.open({
            id: 'dual-date-picker',
            Component: DualDatePicker.bind(this, {
                onChange: ({ startDate, endDate }) => {
                    if(!startDate || !endDate) {
                        return;
                    }
                    setPreferred(preferred => {
                        return preferred.map(entry => {
                            if(entry.day === day) {
                                entry.times.push({
                                    id: `${moment(startDate).unix()}-${moment(endDate).unix()}`,
                                    start: moment(startDate).format('HH:mm:ss'),
                                    end: moment(endDate).format('HH:mm:ss')
                                })
                                entry.times.sort((a, b) => {
                                    return moment(a).unix() > moment(b).unix() ? 1 : -1
                                })
                            }
                            return entry;
                        })
                    })
                }
            })
        })
    }

    return (
        <Layer
        id={layerID}
        title={'New Preferred Availability'}
        utils={utils}
        index={index}
        options={{
            ...options,
            layerState: layerState,
            bottomCard: true,
            removePadding: true,
            useTouchableForKeyboard : false
        }}
        buttons={[{
            key: 'cancel',
            text: 'Cancel',
            color: 'grey',
            onPress: () => setLayerState('close')
        },{
            key: 'done',
            text: 'Submit',
            color: 'primary',
            onPress: onSubmitRequest
        }]}>
            <View style={{
                paddingTop: 15,
                paddingHorizontal: 15
            }}>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 8,
                    textAlign: 'center',
                    width: '100%'
                }}>{'New Preferred Availability'}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    textAlign: 'center',
                    marginBottom: 15
                }}>{`Let us know which days and times you would prefer to drive. We will take a look at your request and let you know if there are any issues with the days or times.`}</Text>
            </View>

            <ScrollView
            showsVerticalScrollIndicator={false}
            style={{
                width: '100%',
                maxHeight: Screen.height() / 2,
                paddingHorizontal: 15
            }}
            contentContainerStyle={{
                paddingBottom: 15
            }}>
                <View style={{
                    ...Appearance.styles.panel(),
                    flexDirection: 'column',
                    width: '100%',
                    marginBottom: 15
                }}>
                    {preferred.map((entry, topIndex) => {
                        return (
                            <View key={topIndex}>
                                <View style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    paddingVertical: 8,
                                    paddingHorizontal: 12,
                                    borderBottomWidth: 1,
                                    borderBottomColor: Appearance.colors.divider()
                                }}>
                                    <Image source={require('eCarra/images/driver-schedule-icon-small.png')} style={{
                                        width: 20,
                                        height: 20,
                                        resizeMode: 'contain',
                                        tintColor: Appearance.colors.grey(),
                                        marginRight: 8
                                    }} />
                                    <Text style={{
                                        ...Appearance.textStyles.subTitle(),
                                        color: Appearance.colors.text(),
                                        flexGrow: 1
                                    }}>{
                                        moment(entry.day, 'd').format('dddd')
                                    }</Text>
                                    <TouchableOpacity
                                    activeOpacity={0.6}
                                    onPress={onNewTimeslot.bind(this, entry.day)}
                                    style={{
                                        marginLeft: 8
                                    }}>
                                        <AltBadge content={{
                                            text: 'New',
                                            color: Appearance.colors.grey()
                                        }} />
                                    </TouchableOpacity>
                                </View>

                                {entry.times.map((time, index) => {
                                    return (
                                        <TouchableHighlight
                                        key={index}
                                        activeOpacity={0.9}
                                        underlayColor={Appearance.colors.divider()}
                                        //onPress={onEntryPress.bind(this, 'preferred', entry, time)}
                                        >
                                            <View style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                paddingVertical: 8,
                                                paddingHorizontal: 12,
                                                borderBottomColor: Appearance.colors.divider(),
                                                borderBottomWidth: index === entry.times.length - 1 && topIndex === preferred.length - 1 ? 0 : 1
                                            }}>
                                                <View style={{
                                                    width: 20,
                                                    height: 20,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginRight: 8
                                                }}>
                                                    <View style={{
                                                        width: 5,
                                                        height: 5,
                                                        backgroundColor: Appearance.colors.grey(),
                                                        borderRadius: 2.5,
                                                        overflow: 'hidden'
                                                    }} />
                                                </View>
                                                <Text style={{
                                                    ...Appearance.textStyles.subTitle(),
                                                    flexGrow: 1
                                                }}>{
                                                    `${moment(time.start, 'HH:mm:ss').format('h:mma')} to ${moment(time.end, 'HH:mm:ss').format('h:mma')}`
                                                }</Text>

                                                <Image source={require('eCarra/images/next-arrow-small.png')} style={{
                                                    width: 12,
                                                    height: 12,
                                                    resizeMode: 'contain',
                                                    tintColor: Appearance.colors.subText(),
                                                    marginLeft: 8
                                                }}/>
                                            </View>
                                        </TouchableHighlight>
                                    )
                                })}
                            </View>
                        )
                    })}
                </View>
            </ScrollView>
        </Layer>
    )
}

// Panels
export const Profile = ({ utils }) => {

    const panelID = 'userProfile';
    const [avatar, setAvatar] = useState(utils.user.get().avatar);
    const [avatarLoading, setAvatarLoading] = useState(false);
    const [emissions, setEmissions] = useState(null);
    const [genders, setGenders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [user, setUser] = useState(null);

    const onAvatarPress = async () => {
        try {
            setAvatarLoading(true);
            let nextImage = await ImagePicker.openPicker({
                mediaType: 'photo',
                includeExif: true,
                compressImageQuality: 1,
                includeBase64: true
            });

            if(!nextImage) {
                setAvatarLoading(false);
                return;
            }

            let { url } = await Request.post(utils, '/user/', {
                type: 'set_avatar',
                avatar: {
                    file_type: nextImage.type,
                    data: nextImage.data
                }
            })

            setAvatarLoading(false);
            setAvatar({ uri: url });

        } catch(e) {
            setAvatarLoading(false);
            if(e.message && e.message.includes('cancel')) {
                return;
            }
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating your photo. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onItemPress = (section, item) => {

        let tempContent = undefined;
        if(section.key === 'drivers') {
            switch(item.key) {
                case 'timecards':
                utils.layer.open({
                    id: `driver-timecards-${utils.user.get().user_id}`,
                    abstract: Abstract.create({
                        type: 'users',
                        object: utils.user.get()
                    }),
                    Component: DriverTimeCards
                })
                break;

                case 'schedule':
                utils.layer.open({
                    id: `driver-schedule-${utils.user.get().user_id}`,
                    abstract: Abstract.create({
                        type: 'users',
                        object: utils.user.get()
                    }),
                    Component: DriverSchedule
                })
                break;

                case 'availability':
                utils.layer.open({
                    id: `driver-availability-${utils.user.get().user_id}`,
                    abstract: Abstract.create({
                        type: 'users',
                        object: utils.user.get()
                    }),
                    Component: DriverAvailability
                })
                break;
            }
            return;
        }

        if(section.key === 'calendar') {
            switch(item.key) {
                case 'shared':
                getCalendarLink();
                break;

                case 'google':
                utils.layer.open({
                    id: 'calendar-manager',
                    Component: CalendarManager.bind(this, {
                        type: 'google'
                    })
                })
                break;
            }
            return;
        }

        if(section.key === 'privacy') {
            utils.layer.open({
                id: `edit-privacy-${item.key}`,
                Component: LayerShell.bind(this, {
                    layerID: `edit-privacy-${item.key}`,
                    extendedOptions: {
                        bottomCard: true,
                    },
                    children: (
                        <View style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: 5
                        }}>
                            <Text style={{
                                ...Appearance.textStyles.panelTitle(),
                                marginBottom: 6
                            }}>{item.title}</Text>
                            <Text style={{
                                ...Appearance.textStyles.subTitle(),
                                marginBottom: 20,
                                textAlign: 'center'
                            }}>{item.message}</Text>

                            <BoolToggle
                            isEnabled={item.enabled}
                            disabled={'Disable'}
                            enabled={'Enable'}
                            onChange={enabled => onPermissionChange(item, enabled)}/>
                        </View>
                    )
                })
            })
            return;
        }
        if(section.key === 'profile') {
            utils.layer.open({
                id: `edit-profile-${item.key}`,
                Component: EditProfileItem.bind(this, {
                    item: item,
                    title: item.key === 'company' && user.company ? user.company.name : item.title,
                    message: item.message,
                    onChange: validate => onItemUpdate(item, tempContent, validate),
                    ...item.key === 'company' && {
                        onChangeAsync: validate => onItemUpdateAsync(item, tempContent, validate),
                        ...user.company && {
                            onRemove: validate => onItemRemove(item, validate)
                        }
                    },
                    header: item.key === 'company' && user.company && (
                        <View style={{
                            marginBottom: 15,
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                            <View style={{
                                width: 75,
                                height: 75,
                                ...Platform.OS === 'ios' && Appearance.boxShadow({
                                    radius: 10,
                                    opacity: 0.25,
                                    color: 'rgba(150,150,150)',
                                    offset: {
                                        width: 4,
                                        height: 4
                                    }
                                })
                            }}>
                                <Image
                                source={user.company.image}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: 50,
                                    resizeMode: 'cover',
                                    overflow: 'hidden',
                                }} />
                            </View>
                        </View>
                    ),
                    children: () => {
                        switch(item.key) {
                            case 'full_name':
                            return (
                                <>
                                <TextField
                                value={user.first_name}
                                placeholder={'First Name'}
                                onChange={text => tempContent = {
                                    ...tempContent,
                                    first_name: text
                                }}
                                containerStyle={{
                                    marginBottom: 8
                                }}/>
                                <TextField
                                value={user.last_name}
                                placeholder={'Last Name'}
                                onChange={text => tempContent = {
                                    ...tempContent,
                                    last_name: text
                                }}/>
                                </>
                            );

                            case 'phone_number':
                            return (
                                <TextField
                                value={item.value}
                                format={'phone_number'}
                                placeholder={'Phone Number'}
                                onChange={text => tempContent = {
                                    phone_number: text
                                }}/>
                            );

                            case 'address':
                            return (
                                <AddressLookupField
                                utils={utils}
                                geocode={true}
                                value={item.value}
                                onChange={place => tempContent = place}/>
                            );

                            case 'gender':
                            return (
                                <List
                                items={genders}
                                nullable={false}
                                defaultValue={item.value ? item.value.code : null}
                                noneFound={{
                                    title: 'No Genders Found',
                                    subTitle: 'There are no available genders at this time',
                                }}
                                onChange={gender => tempContent = {
                                    gender: {
                                        code: gender.id,
                                        text: gender.title
                                    }
                                }} />
                            );

                            case 'company':
                            return (
                                <TextField
                                autoCorrect={false}
                                autoComplete={false}
                                autoCapitalize={false}
                                placeholder={'Signup Code'}
                                value={user.company ? user.company.key : null}
                                onChange={text => tempContent = {
                                    companyKey: text
                                }}/>
                            )

                            default:
                            return (
                                <TextField
                                value={item.value}
                                placeholder={item.title}
                                onChange={text => tempContent = {
                                    [item.key]: text
                                }}/>
                            )
                        }
                    }
                })
            })
            return;
        }

        switch(section.key) {
            case 'profile':
            break;
        }
    }

    const onItemRemove = (item, validate) => {
        switch(item.key) {
            case 'company':
                utils.alert.show({
                    title: 'Remove Company',
                    message: `Are you sure that you want to remove ${user.company.name} from your profile? You'll no longer receive discounts associated with ${user.company.name}`,
                    buttons: [{
                        key: 'confirm',
                        title: 'Yes',
                        style: 'destructive'
                    },{
                        key: 'cancel',
                        title: 'Do Not Remove',
                        style: 'default'
                    }],
                    onPress: (key) => {
                        if(key === 'confirm') {
                            utils.user.get().edits.company = undefined;
                            validate();
                            onUpdateProfile();
                        }
                    }
                })
                break;
        }
    }

    const onItemUpdate = async (item, tempContent, validate) => {
        if(!tempContent) {
            validate();
            return;
        }
        setLoading(item.key);
        if(item.key === 'referral_code' && tempContent.referral_code) {
            if(user.referral_code === tempContent.referral_code) {
                validate();
                return;
            }
            try {
                let { available } = await Request.post(utils, '/user/', {
                    type: 'referral_code_available',
                    code: tempContent.referral_code
                });
                if(!available) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: 'It looks like the referral code that requsted is already taken'
                    });
                    return;
                }
                onUpdateProfile(tempContent);
                validate(); // let layer know that it's time to close

            } catch(e) {
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue checking if your referral code is available. ${e.message || 'An unknown error occurred'}`
                });
            }
            return;
        }
        onUpdateProfile(tempContent);
        validate(); // let layer know that it's time to close
    }

    const onItemUpdateAsync = async (item, tempContent) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { company } = await Request.post(utils, '/companies/', {
                    type: 'verify_signup_code',
                    code: tempContent.companyKey
                });
                onUpdateProfile({ company: company ? Company.create(company) : null });
                resolve();

            } catch(e) {
                reject();
                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue verifying your signup code. ${e.message || 'An unknown error occurred'}`
                });
            }
        })
    }

    const onPermissionChange = async (item, enabled) => {
        const sendToSettings = () => {
            utils.alert.show({
                title: item.title,
                message: `We'll need to direct you to your Settings app to ${enabled ? 'enable' : 'disable'} the ${item.title} permission`,
                buttons: [{
                    key: 'settings',
                    title: 'Settings',
                    style: 'destructive'
                },{
                    key: 'cancel',
                    title: 'Maybe Later',
                    style: 'default'
                }],
                onPress: key => {
                    if(key === 'settings') {
                        Linking.openSettings();
                        return;
                    }
                    utils.events.emit('onLayerAction', {
                        layerID: `edit-privacy-${item.key}`,
                        action: 'close'
                    });
                }
            })
        }

        if(enabled === false) {
            sendToSettings();
            return;
        }

        if(enabled) {

            switch(item.key) {
                case 'bluetooth':
                try {
                    let enabled = await requestBluetoothPermission(utils);
                    if(enabled === false) {
                        sendToSettings();
                    }

                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue enabling Bluetooth for your device. ${e.message}`
                    });
                }
                break;

                case 'faceID':
                try {
                    let enabled = await requestFaceIDPermission(utils);
                    if(enabled === false) {
                        sendToSettings();
                    }

                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue enabling Face ID for your device. ${e.message}`
                    });
                }
                break;

                case 'location':
                try {
                    let enabled = await requestLocationPermission(utils);
                    if(enabled === false) {
                        sendToSettings();
                    }

                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue enabling location sharing for your device. ${e.message}`
                    });
                }
                break;

                case 'notifications':
                try {
                    let enabled = await requestNotificationPermission(utils);
                    if(enabled === false) {
                        sendToSettings();
                    }

                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue enabling notifications for your device. ${e.message}`
                    });
                }
                break;
            }
        }
    }

    const onUpdateProfile = async content => {
        try {
            let user = utils.user.get();
            let edits = user.set(content);
            await user.update(utils);

            setUser(edits);
            setLoading(false);
            onUpdateRequestOptions();

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating your profile. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUpdateRequestOptions = async () => {
        try {

            let edits = utils.user.get().open();
            setUser(edits);

            let results = await getCurrentPermissions(utils);
            let options = [{
                key: 'profile',
                items: [{
                    key: 'full_name',
                    title: 'Full Name',
                    subTitle: edits.full_name,
                    message: 'Your name helps us personalize your experience across our service and identify Reservation and Orders that you book',
                    icon: {
                        path: require('eCarra/images/profile-full-name-clear.png'),
                        style: {
                            backgroundColor: Appearance.colors.grey()
                        }
                    }
                },{
                    key: 'email_address',
                    title: 'Email Address',
                    value: edits.email_address,
                    subTitle: edits.email_address,
                    message: 'Your email address helps us keep you up to date with Reservation and Orders that you book',
                    icon: {
                        path: require('eCarra/images/profile-email-address-clear.png'),
                        style: {
                            backgroundColor: Appearance.colors.grey()
                        }
                    }
                },{
                    key: 'phone_number',
                    title: 'Phone Number',
                    value: edits.phone_number,
                    subTitle: edits.phone_number,
                    message: 'Your phone number helps us provide customer support and account verification if you get locked out of your account',
                    icon: {
                        path: require('eCarra/images/profile-phone-number-clear.png'),
                        style: {
                            backgroundColor: edits.phone_number ? Appearance.colors.grey() : Appearance.colors.red
                        }
                    }
                },{
                    key: 'address',
                    title: 'Address',
                    value: Utils.formatAddress(edits),
                    subTitle: Utils.formatAddress(edits),
                    message: 'Your physical address helps us suggest relevant destinations near your location',
                    icon: {
                        path: require('eCarra/images/profile-address-clear.png'),
                        style: {
                            backgroundColor: edits.address && edits.city && edits.state && edits.zipcode ? Appearance.colors.grey() : Appearance.colors.red
                        }
                    }
                },{
                    key: 'gender',
                    title: 'Gender',
                    subTitle: edits.gender ? edits.gender.text : null,
                    value: edits.gender,
                    message: 'Providing your gender is optional but it can help us personalize your experience',
                    icon: {
                        path: require('eCarra/images/profile-full-name-clear.png'),
                        style: {
                            backgroundColor: edits.gender ? Appearance.colors.grey() : Appearance.colors.red
                        }
                    }
                },{
                    key: 'company',
                    title: 'Company',
                    value: edits.company ? edits.company.key : null,
                    subTitle: edits.company ? edits.company.name : 'Not Added',
                    message: `At ${utils.client.get().name}, we partner with select third parties to offer special employee discounts on Reservations and Orders. You can enter your company signup code below to connect with your company`,
                    icon: {
                        path: edits.company && edits.company.image ? edits.company.image : require('eCarra/images/profile-company-clear.png'),
                        style: {
                            backgroundColor: Appearance.colors.grey()
                        }
                    }
                },{
                    key: 'referral_code',
                    title: 'Referral Code',
                    value: edits.referral_code,
                    subTitle: edits.referral_code,
                    message: 'Your referral code is a unique signup code that helps us identify who you bring to our community. We use this information to give out rewards and discounts on Reservations and Orders',
                    icon: {
                        path: require('eCarra/images/profile-referral-code-clear.png'),
                        style: {
                            backgroundColor: Appearance.colors.grey()
                        }
                    }
                }]
            },{
                key: 'calendar',
                title: 'Calendar',
                items: [{
                    key: 'shared',
                    title: 'Shared Calendar',
                    message: 'Get a link to share your calendar with friends and family',
                    icon: {
                        path: require('eCarra/images/profile-calendar-share-clear.png'),
                        style: {
                            backgroundColor: Appearance.colors.grey()
                        }
                    }
                }]
            }];

            // privacy settings are not needed on the web
            if(Platform.OS !== 'web') {
                options.push({
                    key: 'privacy',
                    title: 'Privacy',
                    items: privacyOptions.filter(option => {
                        return option.visible !== false
                    }).map(option => {
                        return {
                            ...option,
                            enabled: results[option.key]
                        }
                    })
                })
            }

            if(Platform.OS === 'android' || Platform.OS === 'web') {
                setOptions(options);
                return;
            }

            // Check for Face ID
            let { biometryType } = await ReactNativeBiometrics.isSensorAvailable();
            setOptions(options.map(option => {
                if(option.key === 'faceID') {
                    option.visible = biometryType === ReactNativeBiometrics.FaceID;
                }
                return option;
            }).filter(option => {
                return option.visible !== false;
            }));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue checking your device for one or more permissions. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getCalendarLink = async () => {
        try {
            let { url } = await Request.get(utils, '/calendar/', {
                type: 'shared_calendar'
            });
            utils.alert.show({
                title: 'Share with Others',
                message: `We've created a link that you can share with friends and family members. This link will load all of your Orders and Reservations into their calendar`,
                buttons: [{
                    key: 'share',
                    title: 'Share Link',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Cancel',
                    style: 'cancel'
                }],
                onPress: key => {
                    if(key === 'share') {
                        Share.share({
                            url: url, // iOS
                            message: Platform.OS === 'ios' ? null : url,  // Android
                            title: 'Shared Calendar' // Android
                        })
                    }
                }
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your shared calendar. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getEmissionsEntry = key => {

        if(!emissions || !emissions[key]) {
            return;
        }

        switch(key) {
            case 'carbon':
            return {
                text: 'Grams of Carbon',
                value: Utils.numberFormat(emissions[key].all_time || 0)
            };
            case 'gas':
            return {
                text: `${emissions[key].all_time === 1 ? 'Gallon' : 'Gallons'}`,
                value: Utils.numberFormat(emissions[key].all_time || 0)
            }
            case 'trees':
            return {
                text: `${emissions[key].all_time === 1 ? 'Tree' : 'Trees'} Planted`,
                value: emissions[key].all_time
            };
        }

        return null;
    }

    const getHeaderContent = () => {
        if(Platform.OS === 'web' || Utils.isMobile() === false) {
            return null;
        }
        return (
            <>
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center'
            }}>
                <TouchableOpacity
                activeOpacity={0.6}
                onPress={onAvatarPress}
                style={{
                    width: 75,
                    height: 75,
                    ...Platform.OS === 'ios' && Appearance.boxShadow({
                        radius: 10,
                        opacity: 0.25,
                        color: 'rgba(150,150,150)',
                        offset: {
                            width: 4,
                            height: 4
                        }
                    })
                }}>
                    <View style={{
                        display: 'flex',
                        position: 'relative',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Image
                        source={avatar}
                        style={{
                            width: 75,
                            height: 75,
                            borderRadius: 35.5,
                            resizeMode: 'cover',
                            overflow: 'hidden',
                            borderWidth: 2,
                            borderColor: 'white',
                            borderRadius: 37.5
                        }} />
                        {avatarLoading === true && (
                            <>
                            <View style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: 50,
                                resizeMode: 'cover',
                                overflow: 'hidden',
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                position: 'absolute'
                            }} />
                            <View style={{
                                position: 'absolute',
                                padding: 20
                            }}>
                                <LottieView
                                autoPlay={true}
                                loop={true}
                                source={require('eCarra/files/lottie/dots-white.json')}
                                duration={2500}
                                style={{
                                    width: '100%',
                                    height: '100%'
                                }}/>
                            </View>
                            </>
                        )}
                    </View>
                </TouchableOpacity>
                <View style={{
                    flex: 1,
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    justifyContent: 'center',
                    marginLeft: 20
                }}>
                    <Text style={{
                        ...Appearance.textStyles.panelTitle(),
                    }}>{utils.user.get().full_name}</Text>
                    <Text style={{
                        ...Appearance.textStyles.subTitle(),
                        marginTop: 3
                    }}>{utils.user.get().address || (utils.user.get().city && utils.user.get().state ? `${utils.user.get().city} ${utils.user.get().state}` : 'Location Not Available')}</Text>
                    <Text style={{
                        ...Appearance.textStyles.subTitle(),
                        marginTop: 3
                    }}>{utils.user.get().referral_code ? `@${utils.user.get().referral_code}` : 'Referral Code Not Found'}</Text>
                </View>
            </View>
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                marginVertical: 20,
                borderTopWidth: 1,
                borderTopColor: Appearance.colors.softBorder(),
                borderBottomWidth: 1,
                borderBottomColor: Appearance.colors.softBorder(),
                paddingVertical: 20,
                paddingHorizontal: 8
            }}>
                {[ 'gas', 'carbon', 'trees' ].map((key, index) => {
                    let entry = getEmissionsEntry(key);
                    return (
                        <View
                        key={key}
                        style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: index === 1 ? '40%' : '30%',
                            paddingHorizontal: 4
                        }}>
                            <Text style={{
                                ...Appearance.textStyles.panelTitle(),
                                fontSize: 16
                            }}>{entry ? entry.value : '...'}</Text>
                            {entry && (
                                <Text
                                numberOfLines={1}
                                style={{
                                    ...Appearance.textStyles.subTitle(),
                                    marginTop: 2
                                }}>{entry.text}</Text>
                            )}
                        </View>
                    )
                })}
            </View>
            </>
        )
    }

    const fetchEmissions = async () => {
        try {
            let response = await Request.get(utils, '/user/', {
                type: 'emissions',
                user_id: utils.user.get().user_id
            });
            setLoading(false);
            setEmissions(response);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your emissions breakdown. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchGenderOptions = async () => {
        try {
            let { genders } = await Request.get(utils, '/resources/', {
                type: 'genders'
            });
            setGenders(genders.map(gender => ({
                id: gender.code,
                title: gender.text
            })));

        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {

        fetchEmissions();
        fetchGenderOptions();
        onUpdateRequestOptions();
        utils.structure.navigation.set({
            onRightPress: () => {
                utils.sheet.show({
                    items: [{
                        key: 'referral',
                        title: 'Refer a Friend',
                        style: 'default'
                    },{
                        key: 'reset-password',
                        title: 'Reset Password',
                        style: 'default'
                    },{
                        key: 'share',
                        title: 'Share Your Experience',
                        style: 'default'
                    }]
                }, key => {

                    if(key === 'reset-password') {
                        utils.layer.open({
                            id: 'reset-password',
                            Component: ResetPassword
                        })

                    } else if(key === 'share') {
                        utils.layer.open({
                            id: 'share-content',
                            Component: ShareContent.bind(this, {
                                title: 'Share Your Experience',
                                message: `Let your friends and family know about the positive change you are making by choosing to ride electric. We'll help you share a link to download ${utils.client.get().name} and your referral code for easy signup`,
                                channels: [ 'facebook', 'twitter', 'email', 'text' ]
                            })
                        })
                    }

                    if(key === 'referral') {
                        utils.alert.show({
                            title: 'Refer a Friend',
                            message: 'Have your friend scan your referral code during signup to unlock special rewards and discounts.',
                            content: (
                                <View style={{
                                    padding: 15
                                }}>
                                    <QRCode
                                    color={'white'}
                                    size={Screen.layer.maxWidth / 2}
                                    backgroundColor={Appearance.colors.primary()}
                                    content={JSON.stringify({
                                        client_id: API.get_client_id(),
                                        user_id: utils.user.get().user_id,
                                        referral_code: utils.user.get().referral_code
                                    })} />
                                </View>
                            )
                        })
                    }
                })
            }
        })

        // App State changes
        AppState.addEventListener('change', onUpdateRequestOptions);
        return () => {
            AppState.removeEventListener('change', onUpdateRequestOptions);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        options={{
            loading: loading === true,
            removeOverflow: true,
            shouldStyle: false
        }}>
            {getHeaderContent()}
            {options.map((option, index) => {
                return (
                    <View key={index}>
                        {option.title && (
                            <View style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: 10,
                                marginBottom: 12
                            }}>
                                <Text style={{
                                    ...Appearance.textStyles.panelTitle(),
                                    fontSize: 16
                                }}>{option.title}</Text>
                            </View>
                        )}
                        <View style={{
                            ...Appearance.styles.panel(),
                            marginBottom: 20
                        }}>
                            {option.items.map((item, index) => {
                                return (
                                    Views.entry({
                                        key: index,
                                        ...item,
                                        loading: loading === item.key,
                                        bottomBorder: index !== option.items.length - 1,
                                        onPress: onItemPress.bind(this, option, item),
                                    })
                                )
                            })}
                        </View>
                    </View>
                )
            })}
        </Panel>
    )
}

export const CalendarManager = ({ type }, { utils, options, index }) => {

    const layerID = 'calendar-manager';
    const [loading, setLoading] = useState('setup');
    const [enabled, setEnabled] = useState(false);
    const [layerState, setLayerState] = useState(null);

    const getTitle = () => {
        switch(type) {
            case 'google':
                return 'Google Calendar';

            default:
                return '';
        }
    }

    const getButtons = () => {
        if(enabled) {
            return [{
                key: 'remove',
                loading: loading === 'remove',
                color: 'danger',
                text: 'Remove Calendar',
                onPress: removeCalendar
            }]
        }
        return [{
            key: 'setup',
            loading: loading === 'setup',
            color: 'primary',
            text: 'Setup Calendar',
            onPress: setupCalendar
        }]
    }

    const getDescription = () => {
        switch(type) {
            case 'google':
            return `We use your Google account to setup and maintain a calendar for ${utils.client.get().name} that holds your Orders and Reservations. These events are created when you book an Order or Reservation and updated whenever you make changes in the ${utils.client.get().name} app`

            default:
            return '';
        }
    }

    const getEnabledStatus = async () => {
        try {
            let { enabled } = await Request.get(utils, '/calendar/', {
                type: 'is_google_setup'
            });
            setEnabled(enabled);
            setLoading(false);

        } catch(e) {
            setLoading(false);
            console.error(e.message);
        }
    }

    const syncEvents = async () => {
        try {
            setLoading('sync');
            let { count } = await Request.post(utils, '/calendar/', {
                type: 'sync_events'
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `${count} ${count === 1 ? 'event has' : 'events have'} been synced to your calendar`
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue syncing your calendar. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const setupCalendar = async authCode => {
        try {
            setLoading('setup');
            let { auth } = await Request.post(utils, '/calendar/', {
                type: 'setup_google_calendar',
                auth_code: authCode
            });

            if(auth) {
                try {
                    GoogleSignin.configure({
                        scopes: ['https://www.googleapis.com/auth/calendar'],
                        offlineAccess: true,
                        webClientId: utils.client.get().apps.google.web,
                        iosClientId: utils.client.get().apps.google.ios
                    });

                    let { serverAuthCode } = await GoogleSignin.signIn();
                    setupCalendar(serverAuthCode);

                } catch(e) {
                    setLoading(false);
                    console.error(e.message);
                }
                return;
            }
            setEnabled(true);
            syncEvents();

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up your calendar. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const removeCalendar = () => {

        utils.alert.show({
            title: 'Remove Calendar',
            message: `Are you sure that you want to remove your calendar? This will remove the calendar and events from your account`,
            buttons: [{
                key: 'confirm',
                title: 'Remove',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'default'
            }],
            onPress: async key => {
                if(key !== 'confirm') {
                    return;
                }
                try {
                    setLoading('remove');
                    await Request.post(utils, '/calendar/', {
                        type: 'remove_calendar',
                        category: type
                    });
                    utils.alert.show({
                        title: 'All Done!',
                        message: 'You calendar has been removed and will no longer sync with your Orders and Reservations',
                        onPress: () => setLayerState('close')
                    })

                } catch(e) {
                    setLoading(false);
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue removing your calendar. ${e.message || 'An unknown error occurred'}`
                    })
                }
            }
        })
    }

    useEffect(() => {
        getEnabledStatus();
    }, []);

    return (
        <Layer
        id={layerID}
        title={getTitle()}
        utils={utils}
        index={index}
        buttons={getButtons()}
        options={{
            ...options,
            bottomCard: true,
            layerState: layerState
        }}>
            <View style={{
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 5
            }}>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 6
                }}>{getTitle()}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    textAlign: 'center'
                }}>{getDescription()}</Text>
            </View>
        </Layer>
    )
}
