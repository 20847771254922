import React, { useEffect, useState } from 'react';
import { View, Animated } from 'react-native';

import Alert from 'eCarra/views/Alert.js';
import Appearance from 'eCarra/styles/Appearance.js';
import Screen from 'eCarra/files/Screen.js';

const AlertStack = ({ alerts, utils, onClose }) => {

    const [visible, setVisible] = useState(false);
    const [opacity, setOpacity] = useState(new Animated.Value(0));

    const hide = () => {
        Animated.timing(opacity, {
            toValue: 0,
            duration: 250
        }).start(() => {
            setVisible(false);
        })
    }

    useEffect(() => {
        if(visible) {
            Animated.timing(opacity, {
                toValue: 1,
                duration: 250
            }).start()
        }
    }, [visible])

    useEffect(() => {
        if(alerts.length > 0) {
            setVisible(true);
            return;
        }
        hide();
    }, [alerts]);

    return visible ? (
        <View style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: Appearance.colors.transparent,
            zIndex: 9950
        }}>
            <Animated.View style={{
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                opacity: opacity,
                position: 'absolute',
                backgroundColor: Appearance.colors.dim
            }} />
            {alerts && alerts.length > 0
                ?
                alerts.map((alert, index) => (
                    <Alert {...alert}
                    key={index}
                    index={(alerts.length - 1) - index}
                    utils={utils}
                    onClose={id => onClose(id)} />
                ))
                :
                null
            }
        </View>
    ) : null;
}
export default AlertStack;
