import React, { useRef, useState, useEffect } from 'react';
import { View } from 'react-native';
import lottie from 'lottie-web';

const LottieView = React.forwardRef(({ autoPlay, className, loop, onAnimationFinish, onClick, onMouseEnter, onMouseLeave, preserveAspectRatio, source, style }, ref) => {

    const animation = useRef(null);
    const container = useRef(null);

    const play = (start, end) => {
        if(!isNaN(start) && !isNaN(end)) {
            animation.current.playSegments([start, end], true);
            return;
        }
        animation.current.play();
    }

    const reset = () => {
        animation.current.goToAndStop(0, true);
    }

    const reverse = props => {
        animation.current.setDirection(-1);
        play(props);
    }

    const getInnerStyles = () => {
        if(!style) {
            return null;
        }
        return {
            width: style.width || '100%',
            height: style.height || '100%'
        }
    }

    useEffect(() => {
        if(ref && ref.current) {
            ref.current.play = play;
            ref.current.reset = reset;
            ref.current.reverse = reverse;
        }
    }, [ref]);

    useEffect(() => {
        if(animation.current) {
            animation.current.destroy();
        }
        animation.current = lottie.loadAnimation({
            container: container.current,
            renderer: 'svg',
            loop: loop,
            autoplay: autoPlay,
            animationData: source,
            rendererSettings: { preserveAspectRatio: preserveAspectRatio || 'xMidYMid meet' },
        });
        animation.current.onComplete = onAnimationFinish;
    }, [source])

    return (
        <View
        ref={ref}
        className={className}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            ...style
        }}>
            <View
            ref={container}
            style={{
                width: '100%',
                height: '100%',
                ...getInnerStyles()
            }}/>
        </View>
    )
})
export default LottieView;
