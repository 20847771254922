import React, { useEffect, useState } from 'react';
import { View, Text, Image, TextInput, ScrollView } from 'react-native';

import { getIcon } from 'eCarra/views/TextField.js';

import API from 'eCarra/files/api.js';
import Appearance from 'eCarra/styles/Appearance.js';
import Request from 'eCarra/files/Request/';
import Screen from 'eCarra/files/Screen.js';
import TextField from 'eCarra/views/TextField.js';
import Utils from 'eCarra/files/Utils.js';
import Views from 'eCarra/views/Main.js';

const AddressLookupField = ({ className, containerStyle, icon, onChange, placeholder, showAddressBook, utils, value }) => {

    const [addressBook, setAddressBook] = useState(null);
    const [nonce, setNonce] = useState(Utils.randomString());
    const [results, setResults] = useState([]);
    const [searching, setSearching] = useState(false);
    const [selected, setSelected] = useState(false);
    const [textTimeout, setTextTimeout] = useState(false);
    const [text, setText] = useState(value !== null && value !== undefined ? value : '');

    const hideResults = () => {
        setTimeout(() => {
            setResults([]);
        }, 100)
    }

    const setupAddressBook = async () => {
        try {
            // check if user object is available
            // user may not be available if this object is used during signup
            let user = utils.user.get();
            if(!user) {
                return;
            }
            // fetch address book entries
            let entries = await user.getAddressBook(utils);
            setAddressBook(entries.map(entry => {
                return {
                    ...entry,
                    location: {
                        latitude: entry.location.lat,
                        longitude: entry.location.long
                    }
                }
            }));

        } catch(e) {
            // dont report error
            console.error(e.message);
        }
    }

    const onAddressBookPress = async location => {
        setResults([]);
        setSelected(location);
        setText(location.address);
        if(typeof(onChange) === 'function') {
            onChange(location);
        }
    }

    const onPress = async location => {

        setResults([]);
        setSelected(location);
        setText(location.address);

        try {
            setSearching(true);
            let { result } = await Utils.geocode(utils, location, nonce);

            setSearching(false);
            if(typeof(onChange) === 'function') {
                onChange({
                    ...location,
                    ...result,
                    ...result.location && {
                        location: {
                            latitude: result.location.lat,
                            longitude: result.location.long
                        }
                    }
                });
            }
        } catch(e) {
            setSearching(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue locating the information for this address. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onTextChange = text => {

        setSearching(true);
        if(textTimeout) {
            clearTimeout(textTimeout);
        }

        setText(text);
        setSelected(false);

        setTextTimeout(setTimeout(async () => {
            if(text.length < 3) {
                setResults([]);
                setSearching(false);
                return;
            }
            try {
                let results = await Utils.addressLookup(utils, text, nonce);
                setSearching(false);
                setResults(results)
            } catch(e) {
                setSearching(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue searching for your location. ${e.message || 'An unknown error occurred'}`
                });
            }
        }, 250));
    }

    const getAddressBookEntries = () => {

        if(!addressBook || addressBook.length === 0) {
            return null;
        }

        return addressBook.map(entry => {
            let location = utils.location.last();
            let distance = location ? Utils.linearDistance(location, entry.location) : null;
            entry.nearby = distance && distance < 5;
            return entry;
        }).sort((a, b) => {
            if(a.nearby === b.nearby) {
                return a.name.localeCompare(b.name);
            }
            return a.nearby ? -1 : 1;
        }).map((entry, index) => {
            return (
                Views.entry({
                    key: index,
                    title: entry.name,
                    subTitle: entry.address,
                    hideIcon: true,
                    loading: entry.loading,
                    badge: entry.nearby  && {
                        text: 'Nearby',
                        color: Appearance.colors.primary()
                    },
                    propStyles: {
                        subTitle: {
                            numberOfLines: entry.expanded ? 10 : 1
                        }
                    },
                    bottomBorder: index !== addressBook.length - 1,
                    onLongPress: () => {
                        setAddressBook(addressBook => update(addressBook, {
                            $apply: addressBook => addressBook.map(e => {
                                e.expanded = e.id === entry.id;
                                return e;
                            })
                        }))
                    },
                    onPress: onAddressBookPress.bind(this, entry)
                })
            )
        })
    }

    useEffect(() => {
        setText(value !== null && value !== undefined ? value : '');
    }, [value]);

    useEffect(() => {
        setupAddressBook();
    }, []);

    return (
        <View style={{
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            <TextField
            value={text}
            icon={icon}
            containerStyle={containerStyle}
            loading={searching}
            placeholder={placeholder}
            autoComplete={false}
            autoCorrect={false}
            autoCapitalize={'sentences'}
            onChange={onTextChange}
            style={{
                flexGrow: 1,
                ...Appearance.textStyles.title()
            }}/>
            {results && results.length > 0 && (
                <View style={{
                    ...Appearance.styles.panel(),
                    marginTop: 12
                }}>
                    {results.map((location, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: location.name,
                                subTitle: location.address,
                                hideIcon: true,
                                bottomBorder: index !== results.length - 1,
                                onPress: onPress.bind(this, location)
                            })
                        )
                    })}
                </View>
            )}
            {results.length === 0 && addressBook && addressBook.length > 0 && showAddressBook !== false && (
                <View style={{
                    ...Appearance.styles.panel(),
                    marginTop: 12
                }}>
                    <View style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: 37,
                        paddingHorizontal: 12,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        backgroundColor: Appearance.colors.divider(),
                        overflow: 'hidden'
                    }}>
                        <Text style={{
                            ...Appearance.textStyles.title(),
                            color: Appearance.colors.subText()
                        }}>{'Address Book'}</Text>
                    </View>
                    <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{
                        width: '100%',
                        maxHeight: 56.7 * 3.5
                    }}>
                        {getAddressBookEntries()}
                    </ScrollView>
                </View>
            )}
        </View>
    )
}
export default AddressLookupField;
