import React, { useEffect, useState } from 'react';
import { Image, Platform, Text, View } from 'react-native';

import moment from 'moment';
import { fetchNewsItems } from 'eCarra/managers/News.js';

import API from 'eCarra/files/api.js';
import Abstract from 'eCarra/classes/Abstract.js';
import Appearance from 'eCarra/styles/Appearance.js';
import DirectionalButtons from 'eCarra/views/DirectionalButtons.js';
import LottieView from 'eCarra/views/Lottie/';
import { Messaging, MessageComponent } from 'eCarra/managers/Messages.js';
import Message from 'eCarra/classes/Message.js';
import Notification from 'eCarra/classes/Notification.js';
import Order from 'eCarra/classes/Order.js';
import OrderHost from 'eCarra/classes/OrderHost.js';
import Payment from 'eCarra/classes/Payment.js';
import Panel from 'eCarra/structure/Panel.js';
import Request from 'eCarra/files/Request/';
import Reservation from 'eCarra/classes/Reservation.js';
import StatusCodes from 'eCarra/files/StatusCodes.js';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import Utils from 'eCarra/files/Utils.js';
import Views, { AltBadge } from 'eCarra/views/Main.js';

class ContentManager {

    subscribers = {};

    exists = async (type, id) => {
        return new Promise(async (resolve, reject) => {
            try {
                let subscribers = Object.values(this.subscribers).filter(subscriber => {
                    // check for valid callback for subscriber
                    let { onFetch } = subscriber.callbacks;
                    if(typeof(onFetch) !== 'function') {
                        return false;
                    }

                    // find types for subscriber
                    // subscriber type could be string of array of strings
                    let subscriber_types = Array.isArray(subscriber.type) ? subscriber.type : [ subscriber.type ];
                    subscriber_types.forEach(subscriber_type => {

                        // find types for emitter
                        // emitter type could be string of array of strings
                        let emitter_types = Array.isArray(type) ? type : [ type ];
                        if(emitter_types.includes(subscriber_type)) {
                            return true;
                        }
                    })
                    return false;
                });

                if(subscribers.length === 0) {
                    resolve(null);
                }
                for(var i in subscribers) {
                    let object = await subscribers[i].callbacks.onExists(type, id);
                    if(object) {
                        resolve(object);
                        return;
                    }
                }
                resolve(null);

            } catch(e) {
                reject(e);
            }
        })
    }

    fetch = type => {
        Object.values(this.subscribers).forEach(subscriber => {

            // check for valid callback for subscriber
            let { onFetch } = subscriber.callbacks;
            if(typeof(onFetch) !== 'function') {
                return;
            }

            // find types for subscriber
            // subscriber type could be string of array of strings
            let subscriber_types = Array.isArray(subscriber.type) ? subscriber.type : [ subscriber.type ];
            subscriber_types.forEach(subscriber_type => {

                // find types for emitter
                // emitter type could be string of array of strings
                let emitter_types = Array.isArray(type) ? type : [ type ];
                if(emitter_types.includes(subscriber_type)) {
                    onFetch(subscriber_type);
                }
            })
        })
    }

    refresh = type => {
        Object.values(this.subscribers).forEach(subscriber => {

            // check for valid callback for subscriber
            let { onRefresh } = subscriber.callbacks;
            if(typeof(onRefresh) !== 'function') {
                return;
            }

            // find types for subscriber
            // subscriber type could be string of array of strings
            let subscriber_types = Array.isArray(subscriber.type) ? subscriber.type : [ subscriber.type ];
            subscriber_types.forEach(subscriber_type => {

                // find types for emitter
                // emitter type could be string of array of strings
                let emitter_types = Array.isArray(type) ? type : [ type ];
                if(emitter_types.includes(subscriber_type)) {
                    onRefresh(subscriber_type);
                }
            })
        })
    }

    remove = abstract => {
        Object.values(this.subscribers).forEach(subscriber => {

            // check for valid callback for subscriber
            let { onRemove } = subscriber.callbacks;
            if(typeof(onRemove) !== 'function') {
                return;
            }

            // find types for subscriber
            // subscriber type could be string of array of strings
            let subscriber_types = Array.isArray(subscriber.type) ? subscriber.type : [ subscriber.type ];
            subscriber_types.forEach(subscriber_type => {

                // find types for emitter
                // emitter type could be string of array of strings
                if(abstract.type === subscriber_type) {
                    onRemove(abstract);
                }
            })
        })
    }

    set = abstract => {
        Object.values(this.subscribers).forEach(subscriber => {

            // check for valid callback for subscriber
            let { onSet } = subscriber.callbacks;
            if(typeof(onSet) !== 'function') {
                return;
            }

            // find types for subscriber
            // subscriber type could be string of array of strings
            let subscriber_types = Array.isArray(subscriber.type) ? subscriber.type : [ subscriber.type ];
            subscriber_types.forEach(subscriber_type => {

                // find types for emitter
                // emitter type could be string of array of strings
                if(abstract.type === subscriber_type) {
                    onSet(abstract);
                }
            })
        })
    }

    subscribe = (id, type, callbacks) => {
        this.subscribers[id] = {
            type: type,
            callbacks: callbacks
        };
    }

    unsubscribe = id => {
        delete this.subscribers[id];
    }

    update = abstract => {
        Object.values(this.subscribers).forEach(subscriber => {

            // check for valid callback for subscriber
            let { onUpdate } = subscriber.callbacks;
            if(typeof(onUpdate) !== 'function') {
                return;
            }

            // find types for subscriber
            // subscriber type could be string of array of strings
            let subscriber_types = Array.isArray(subscriber.type) ? subscriber.type : [ subscriber.type ];
            subscriber_types.forEach(subscriber_type => {

                // find types for emitter
                // emitter type could be string of array of strings
                if(abstract.type === subscriber_type) {
                    onUpdate(Abstract.create({ ...abstract }));
                }
            })
        })
    }

    upsert = abstract => {
        Object.values(this.subscribers).forEach(subscriber => {

            // check for valid callback for subscriber
            let { onUpsert } = subscriber.callbacks;
            if(typeof(onUpsert) !== 'function') {
                return;
            }

            // find types for subscriber
            // subscriber type could be string of array of strings
            let subscriber_types = Array.isArray(subscriber.type) ? subscriber.type : [ subscriber.type ];
            subscriber_types.forEach(subscriber_type => {

                // find types for emitter
                // emitter type could be string of array of strings
                if(abstract.type === subscriber_type) {
                    onUpsert(abstract);
                }
            })
        })
        return this;
    }
}
export default {
    new: () => new ContentManager()
};


export const SearchList = ({ panelID, type, channel }, { utils, options, index }) => {

    const [results, setResults] = useState(null);
    const [offset, setOffset] = useState(0);
    const [searching, setSearching] = useState(false);
    const [searchText, setSearchText] = useState(null);

    const setPagingOffset = (value) => {
        let newOffset = offset + value;
        if(newOffset < 0) {
            utils.alert.show({
                title: 'All Done!',
                message: 'We are back at the beginning of the search list'
            });
            return;
        }
        if(newOffset >= (results.length / 5)) {
            utils.alert.show({
                title: `All Done!`,
                message: `You have reached the end of the search results. We hope you found what you were looking for!`
            });
            return;
        }
        setOffset(newOffset);
    }

    const onSearch = async () => {
        console.log(type);
        if(searchText.length < 3 && isNaN(searchText)) {
            setSearching(false);
            return;
        }
        try {
            let props = false;
            let endpoint = false;
            switch(type) {
                case 'orders':
                endpoint = 'orders';
                props = {
                    type: 'all_orders',
                    order_channel: channel.id
                }
                break;

                case 'newOrders':
                endpoint = 'orders';
                props = {
                    type: 'all_hosts',
                    order_channel: channel.id,
                    ...utils.location.last()
                }
                break;

                case 'driverOrders':
                endpoint = 'orders';
                props = {
                    type: 'driver_orders',
                    limit: 100,
                    order_channel: channel.id,
                    search_text: searchText
                }
                break;

                case 'reservations':
                endpoint = 'reservations';
                props = {
                    type: 'all'
                }
                break;

                case 'driverReservations':
                endpoint = 'reservations';
                props = {
                    type: 'driver_reservations',
                    limit: 100,
                    search_text: searchText
                }
                break;

                case 'payments':
                endpoint = 'payments';
                props = {
                    type: 'all'
                }
                break;

                case 'messages':
                endpoint = 'messages';
                props = {
                    type: 'threads',
                    search_text: searchText
                };
                break;

                case 'notifications':
                endpoint = 'notifications';
                props = {
                    type: 'all'
                }
                break;

                case 'news':
                endpoint = 'news';
                let { items, featuredItems } = await fetchNewsItems(utils, {
                    news_channel: channel.id,
                    search_text: searchText,
                });
                let targets = items.concat(featuredItems);
                let newsItems = targets.filter(item => {
                    return [
                        item.title,
                        item.description,
                    ].find(target => target && target.toString().toLowerCase().includes(searchText));
                });
                setSearching(false);
                setResults(newsItems);
                return;

                default:
                return;
            }

            // fetch search results
            let response = await Request.get(utils, `/${endpoint}/`, props);
            switch(type) {
                case 'orders':
                case 'driverOrders':
                let orders = response.orders.map(o => {
                    return Order.create(o)
                }).filter(order => {
                    return [
                        order.id,
                        order.customer.full_name,
                        order.host.name,
                        order.origin.name,
                        order.origin.address,
                        order.destination.name,
                        order.destination.address
                    ].find(target => target && target.toString().toLowerCase().includes(searchText));
                });
                setResults(orders);
                break;

                case 'newOrders':
                let hosts = response.hosts.map(host => {
                    return OrderHost.create(host)
                }).filter(host => {
                    return [
                        host.name,
                        host.description
                    ].find(target => target && target.toString().toLowerCase().includes(searchText));
                });
                setResults(hosts);
                break;

                case 'reservations':
                case 'driverReservations':
                let reservations = response.reservations.map(r => {
                    return Reservation.create(r)
                }).filter(reservation => {
                    return [
                        reservation.id,
                        reservation.customer.full_name,
                        reservation.origin.name,
                        reservation.origin.address,
                        reservation.destination.name,
                        reservation.destination.address
                    ].find(target => target && target.toString().toLowerCase().includes(searchText));
                });
                setResults(reservations);
                break;

                case 'payments':
                let payments = response.payments.map(p => {
                    return Payment.create(p)
                }).filter(payment => {
                    return [
                        payment.id,
                        payment.amount,
                        payment.customer.full_name,
                        payment.company ? payment.company.name : null,
                        payment.order ? payment.order.id : null,
                        payment.order ? payment.order.channel.name : null,
                        payment.order ? payment.order.host.name : null,
                        payment.order ? payment.order.origin.name : null,
                        payment.order ? payment.order.origin.address : null,
                        payment.order ? payment.order.destination.name : null,
                        payment.order ? payment.order.destination.address : null,
                        payment.reservation ? payment.reservation.id : null,
                        payment.reservation ? payment.reservation.origin.name : null,
                        payment.reservation ? payment.reservation.origin.address : null,
                        payment.reservation ? payment.reservation.destination.name : null,
                        payment.reservation ? payment.reservation.destination.address : null,
                    ].find(target => target && target.toString().toLowerCase().includes(searchText));
                });
                setResults(payments);
                break;

                case 'messages':
                // threads are filtered server side
                setResults(response.threads.map(thread => Message.Thread.create(thread)));
                break;

                case 'notifications':
                let notifications = Object.values(response).reduce((array, entry) => {
                    return array.concat(entry.notifications.map(notification => {
                        return Notification.create(notification)
                    }))
                }, []).filter(notification => {
                    return [
                        notification.id,
                        notification.title,
                        notification.message,
                        notification.from_user ? notification.from_user.full_name : null
                    ].find(target => target && target.toString().toLowerCase().includes(searchText));
                });
                setResults(notifications);
                break;
            }
            setSearching(false);

        } catch(e) {
            setSearching(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the search results. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onThreadPress = async ({ order_id, reservation_id }) => {
        try {
            let { order, reservation } = await Utils.createMultiple(utils, {
                order_id: order_id,
                reservation_id: reservation_id
            });
            if(order_id && !order) {
                throw new Error('Order not found');
                return;
            }
            if(reservation_id && !reservation) {
                throw new Error('Reservation not found');
                return;
            }
            let abstract = Abstract.create({
                type: order ? 'orders' : 'reservations',
                object: order || reservation
            });
            utils.layer.openFloating({
                id: `messages-${abstract.getTag()}`,
                abstract: abstract,
                Component: Messaging
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your messages. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const formatDate = (date) => {

        if(moment(date).isSame(moment(), 'day')) {
            return `Today at ${moment(date).format('h:mma')}`;
        }

        if(moment(date).isSame(moment().subtract(1, 'days'), 'day')) {
            return `Yesterday at ${moment(date).format('h:mma')}`;
        }

        return moment(date).format('MMM Do [at] h:mma');
    }

    const formatPickupDate = (pickup_date) => {

        if(moment(pickup_date).isSame(moment(), 'day')) {
            return moment(pickup_date).format('h:mma');
        }

        if(moment(pickup_date).isSame(moment().add(1, 'days'), 'day')) {
            return `Tomorrow[\n]${moment(pickup_date).format('h:mma')}`;
        }

        if(moment(pickup_date).isSame(moment().subtract(1, 'days'), 'day')) {
            return `Yesterday[\n]${moment(pickup_date).format('h:mma')}`;
        }

        return moment(pickup_date).format(`MMM Do[\n]h:mma`);
    }

    const newsItemEntry = (item, index) => {

        let hours = parseInt(moment.duration(moment().diff(moment(item.date))).asHours());
        let date = moment().isSame(item.date, 'day') && hours < 24 ?
            `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`
            :
            (moment().subtract(1, 'days').isSame(moment(item.date), 'day')
                ?
                moment(item.date).format('[Yesterday at] h:mma')
                :
                moment(item.date).format('MMMM Do [at] h:mma'));

        return (
            <View
            key={item.id}
            style={{
                ...Appearance.styles.panel(),
                marginBottom: 12
            }}>
                <TouchableOpacity
                activeOpacity={0.6}
                onPress={() => {
                    utils.layer.webView({
                        id: `news-${item.id}`,
                        title: item.title,
                        url: item.url
                    })
                }}
                style={{
                    padding: 12
                }}>
                    <View style={{
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center'
                    }}>
                        <View style={{
                            flex: 1,
                            flexGrow: 1,
                            paddingRight: 12
                        }}>
                            <Text style={{
                                ...Appearance.textStyles.title(),
                                fontSize: 12
                            }}>
                                {item.title}
                            </Text>
                            <View style={{
                                flexDirection: 'row',
                                width: '100%',
                                alignItems: 'center',
                                marginTop: 8
                            }}>
                                <AltBadge content={{
                                    text: date,
                                    color: Appearance.colors.grey()
                                }} style={{
                                    marginRight: 8
                                }} />
                            </View>
                        </View>
                        <View style={{
                            width: 50,
                            height: 50,
                            borderRadius: 8,
                            overflow: 'hidden',
                            borderWidth: 1,
                            borderColor: Appearance.colors.divider()
                        }}>
                            <Image source={item.image} style={{
                                width: '100%',
                                height: '100%',
                                resizeMode: 'cover'
                            }}/>
                        </View>
                    </View>
                </TouchableOpacity>
            </View>
        )
    }

    const getHeader = () => {
        return (
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: results && results.length > 5 ? 0 : 4
            }}>
                <View style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingRight: 8
                }}>
                    <Text
                    ellipsizeMode={'tail'}
                    numberOfLines={1}
                    style={Appearance.textStyles.panelTitle()}>
                        {'Search Results'}
                    </Text>
                    {/*<Text
                    ellipsizeMode={'tail'}
                    numberOfLines={1}
                    style={{
                        ...Appearance.textStyles.title(),
                        color: Appearance.colors.subText(),
                    }}>
                        {Utils.apply(type, {
                            orders: () => results ? `${results.length} ${channel.name} ${results.length === 1 ? 'Order' : 'Orders'} Found` : `Looking for ${channel.name} Orders...`,
                            newOrders: () => results ? `${results.length} ${channel.name} ${results.length === 1 ? 'Option' : 'Options'} Found` : `Looking for ${channel.name} Options`,
                            driverOrders: () => results ? `${results.length} ${channel.name} ${results.length === 1 ? 'Order' : 'Orders'} Found` : `Looking for ${channel.name} Orders...`,
                            reservations: () => results ? `${results.length} ${results.length === 1 ? 'Reservation' : 'Reservations'} Found` : 'Looking for Reservations...',
                            driverReservations: () => results ? `${results.length} ${results.length === 1 ? 'Reservation' : 'Reservations'} Found` : 'Looking for Reservations...',
                            messages: () => results ? `${results.length} Message ${results.length === 1 ? 'Thread' : 'Threads'} Found` : 'Looking for Message Threads...',
                            payments: () => results ? `${results.length} ${results.length === 1 ? 'Payment' : 'Payments'} Found` : 'Looking for Payments...',
                            news: () => results ? `${results.length} ${channel.name} ${results.length === 1 ? 'Article' : 'Articles'} Found` : `Looking for ${channel.name}...`,
                            notifications: () => results ? `${results.length} ${results.length === 1 ? 'Notification' : 'Notifications'} Found` : 'Looking for Notifications...'
                        })}
                    </Text>*/}
                </View>
                {results && results.length > 5 && (
                    <DirectionalButtons
                    onNext={() => setPagingOffset(1)}
                    onBack={() => setPagingOffset(-1)} />
                )}
            </View>
        )
    }

    const getMessageDate = message => {
        let date = moment(message.date);
        if(moment().isSame(date, 'day')) {
            return date.format('h:mma');
        }
        if(date > moment().subtract(7, 'days')) {
            return date.format('dddd');
        }
        return date.format('M/D/YY');
    }

    const getMessageText = message => {
        if(!message.from_user) {
            return message.text;
        }
        if(message.from_user.user_id == utils.user.get().user_id) {
            return message.attachment ? `You ${message.text.toLowerCase()}` : `You said "${message.text}"`;
        }
        return message.attachment ? `${message.from_user.first_name} ${message.text.toLowerCase()}` : `${message.from_user.first_name} said "${message.text}"`;
    }

    const getResults = () => {

        let rightContent = (
            <Image
            source={require('eCarra/images/next-arrow-small.png')}
            style={{
                width: 12,
                height: 12,
                resizeMode: 'contain',
                tintColor: Appearance.colors.subText(),
                marginLeft: 12
            }}/>
        );

        if(!results || results.length === 0) {
            return null;
        }

        return (
            <View style={{
                ...![ 'messages', 'news' ].includes(type) && Appearance.styles.panel()
            }}>
                {results.map((result, index) => {

                    let page = parseInt(index / 5);
                    if(page !== offset) {
                        return null;
                    }
                    switch(type) {
                        case 'orders':
                        return (
                            Views.entry({
                                key: index,
                                title: result.destination.name || result.destination.address,
                                subTitle: `Ordered from ${result.host.name}`,
                                icon: {
                                    path: result.host.image
                                },
                                bottomBorder: index !== results.length - 1,
                                onPress: utils.layer.order.details.bind(this, result),
                                rightContent: rightContent
                            })
                        )

                        case 'newOrders':
                        return (
                            Views.entry({
                                key: index,
                                title: result.name,
                                subTitle: result.description,
                                icon: {
                                    path: result.image
                                },
                                bottomBorder: index !== results.length - 1,
                                onPress: utils.layer.orderHost.details.bind(this, result),
                                rightContent: rightContent
                            })
                        )

                        case 'driverOrders':
                        return (
                            Views.entry({
                                key: index,
                                title: result.customer.full_name,
                                subTitle: `Ordered from ${result.host.name}`,
                                badge: result.status.code !== StatusCodes.orders.approved && result.status.code !== StatusCodes.orders.returned ? {
                                    text: result.status.text,
                                    color: result.status.color
                                } : {
                                    text: Utils.formatDate(result.drop_off_date),
                                    color: Appearance.colors.grey()
                                },
                                icon: {
                                    path: result.customer.avatar
                                },
                                bottomBorder: index !== results.length - 1,
                                onPress: utils.layer.order.details.bind(this, result),
                                rightContent: rightContent
                            })
                        )

                        case 'reservations':
                        return (
                            Views.entry({
                                key: index,
                                title: result.destination.name || result.destination.address || 'Destination Not Chosen',
                                subTitle: moment(result.pickup_date).format('MMMM Do, YYYY [at] h:mma'),
                                bottomBorder: index !== results.length - 1,
                                hideIcon: true,
                                onPress: utils.layer.reservation.details.bind(this, result),
                                rightContent: rightContent
                            })
                        )

                        case 'driverReservations':
                        return (
                            Views.entry({
                                key: index,
                                title: result.customer.full_name,
                                subTitle: `From ${result.origin.address}`,
                                badge: result.status.code !== StatusCodes.reservations.approved && result.status.code !== StatusCodes.reservations.returned ? {
                                    text: result.status.text,
                                    color: result.status.color
                                } : {
                                    text: formatPickupDate(result.pickup_date),
                                    color: Appearance.colors.grey(),
                                    style: {
                                        paddingHorizontal: 12
                                    }
                                },
                                icon: {
                                    path: result.customer.avatar
                                },
                                bottomBorder: index !== results.length - 1,
                                onPress: utils.layer.reservation.details.bind(this, result),
                                rightContent: rightContent
                            })
                        )

                        case 'payments':
                        return (
                            Views.entry({
                                key: index,
                                ...result.getTargetSummary(),
                                bottomBorder: index !== results.length - 1,
                                hideIcon: true,
                                onPress: utils.layer.payment.details.bind(this, result),
                                rightContent: rightContent
                            })
                        )

                        case 'news':
                        return newsItemEntry(result, index);

                        case 'messages':
                        if(!result.lastMessage) {
                            return null;
                        }
                        let title = 'New Message';
                        if(result.order_id) {
                            title = `Order #${result.order_id}`
                        } else if(result.reservation_id) {
                            title = `Reservation #${result.reservation_id}`
                        }
                        return (
                            <View
                            key={index}
                            style={{
                                ...Appearance.styles.panel(),
                                marginBottom: 8
                            }}>
                                {Views.entry({
                                    title: title,
                                    subTitle: getMessageText(result.lastMessage),
                                    icon: {
                                        path: result.lastMessage.from_user && result.lastMessage.from_user.avatar
                                    },
                                    bottomBorder: false,
                                    onPress: onThreadPress.bind(this, result),
                                    rightContent: (
                                        <View style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}>
                                            {(Platform.OS === 'web' || Utils.isMobile() === false) && (
                                                <Text style={{
                                                    ...Appearance.textStyles.subTitle(),
                                                    marginLeft: 12
                                                }}>{getMessageDate(result)}</Text>
                                            )}
                                            <Image
                                            source={require('eCarra/images/next-arrow-small.png')}
                                            style={{
                                                width: 12,
                                                height: 12,
                                                resizeMode: 'contain',
                                                tintColor: Appearance.colors.subText(),
                                                marginLeft: 12
                                            }}/>
                                        </View>
                                    ),
                                })}
                            </View>
                        )
                        break;

                        case 'notifications':
                        return (
                            Views.entry({
                                key: index,
                                title: result.title,
                                subTitle: result.message,
                                icon: {
                                    path: result.from_user ? result.from_user.avatar : result.getIcon()
                                },
                                badge: {
                                    text: moment(result.date).format('h:mma'),
                                    color: Appearance.colors.grey()
                                },
                                propStyles: {
                                    subTitle: {
                                        numberOfLines: 2
                                    }
                                },
                                bottomBorder: index !== results.length - 1,
                                onPress: result.contentOptions.bind(this, utils, 'alert')
                            })
                        )

                        default:
                        return null;
                    }
                })}
            </View>
        )
    }

    useEffect(() => {
        if(searchText) {
            onSearch();
        }
    }, [searchText])

    useEffect(() => {
        utils.structure.navigation.subscribe(panelID, {
            onSearch: text => {
                setOffset(0);
                setResults(null);
                setSearching(true);
                setSearchText(text.toLowerCase());
            }
        })
        return () => {
            utils.structure.navigation.unsubscribe(panelID);
        }
    }, [results]);

    return searchText ? (
        <Panel
        key={panelID}
        panelID={panelID}
        header={getHeader()}
        style={{
            marginBottom: 12
        }}
        options={{
            removeOverflow: true,
            shouldStyle: false
        }}>
            {searching
                ?
                <View style={{
                    ...Appearance.styles.panel(),
                    padding: 20,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={Appearance.themeStyle() === 'dark' ? require('eCarra/files/lottie/dots-white.json') : require('eCarra/files/lottie/dots-grey.json')}
                    duration={2500}
                    style={{
                        width: 50
                    }}/>
                </View>
                :
                getResults() || (
                    <View style={Appearance.styles.panel()}>
                        {Views.entry({
                            title: 'Nothing to see here',
                            subTitle: 'No results were found from your search',
                            hideIcon: true,
                            bottomBorder: false
                        })}
                    </View>
                )
            }
        </Panel>
    ) : null
}
