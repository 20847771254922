import React from 'react';
import moment from 'moment';

import Company from 'eCarra/classes/Company.js';
import StatusCodes from 'eCarra/files/StatusCodes.js';
import User from 'eCarra/classes/User.js';
import Utils from 'eCarra/files/Utils.js';

class CreditsCardClass {

    id = null;
    type = null;
    card = null;
    user = null;
    company = null;
    date = null;
    active = null;
    isCompanyCreditsCard = false;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.id = props.id;
        this.card = props.card;
        this.type = props.type;
        this.date = props.date_created ? moment(props.date_created) : null;
        this.active = Boolean(props.card.active);
        this.isCompanyCreditsCard = this.type === 'company';
        this.user = props.user ? User.create(props.user) : null;
        this.company = props.company ? Company.create(props.company) : null;
        return this;
    }

    getSummary = () => {
        return `${this.type === 'company' ? this.company.name : ''} Credits (${Utils.toCurrency(this.card.balance)})`;
    }

    getType = () => {
        return `${this.type === 'company' ? this.company.name : 'Personal Credits'}`;
    }

    balance = () => {
        return this.card ? this.card.balance : 0;
    }
}
export default {
    create: props => new CreditsCardClass().create(props)
};
