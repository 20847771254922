import React, { useRef, useState, useEffect } from 'react';
import { View, TouchableOpacity } from 'react-native';

import Appearance from 'eCarra/styles/Appearance.js';
import LottieView from 'eCarra/views/Lottie/';

const LottieButton = ({ type, onPress, style }) => {

    const ref = useRef(null);

    const getSource = () => {
        switch(type) {
            case 'new':
            return require('eCarra/files/lottie/new-button-circle-grey.json');
        }
    }

    const onLottiePress = () => {
        switch(type) {
            case 'new':
            if(ref.current) {
                ref.current.play(40, 100);
            }
            break
        }
        if(typeof(onPress) === 'function') {
            onPress();
        }
    }

    useEffect(() => {
        if(ref.current) {
            ref.current.play(0, 40);
        }
    }, [ref]);

    return (
        <TouchableOpacity
        activeOpacity={1}
        onPress={onLottiePress}
        style={{
            width: 30,
            height: 30,
            ...style
        }}>
            <LottieView
            ref={ref}
            autoPlay={false}
            loop={false}
            duration={2500}
            source={getSource()}
            style={{
                width: '100%',
                height: '100%'
            }}/>
        </TouchableOpacity>
    )
}
export default LottieButton;
