import React from 'react';
import moment from 'moment';

import Appearance from 'eCarra/styles/Appearance.js';
import Company from 'eCarra/classes/Company.js';
import Order from 'eCarra/classes/Order.js';
import Reservation from 'eCarra/classes/Reservation.js';
import Subscription from 'eCarra/classes/Subscription.js';
import User from 'eCarra/classes/User.js';
import Utils from 'eCarra/files/Utils.js';
import Vehicle from 'eCarra/classes/Vehicle.js';

class PaymentClass {

    id = null;
    cardID = null;
    stripeID = null;
    user_id = null;
    customer = null;
    userData = null;
    company = null;
    type = null;
    amount = null;
    order = null;
    reservation = null;
    subscription = null;
    invoice_url = null;
    vehicle = null;
    date = null;
    approved = null;
    refunded = null;
    cancelled = null;
    data = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.stripeID = props.stripe_id;
        this.user_id = props.user_id;
        this.customer = props.customer ? User.create(props.customer) : null;
        this.company = props.company_data ? Company.create(props.company_data) : null;
        this.type = props.type;
        this.amount = props.amount;
        this.credits = props.credits;
        this.date = props.date ? moment(props.date) : null;
        this.data = props.data;
        this.userData = props.user_data;
        this.approved = props.approved ? moment(props.approved) : null;
        this.refunded = props.refunded ? moment(props.refunded) : null;
        this.cancelled = props.cancelled ? moment(props.cancelled) : null;
        this.invoice_url = props.invoice_url;

        if(props.order && typeof(props.order) === 'object') {
            this.order = Order.create(props.order);
        }

        if(props.reservation && typeof(props.reservation) === 'object') {
            this.reservation = Reservation.create(props.reservation);
        }

        if(props.subscription && typeof(props.subscription) === 'object') {
            this.subscription = Subscription.create(props.subscription);
        }
        return this;
    }

    getOwner = () => {
        return (this.customer || this.company) ? {
            type: this.customer === undefined ? 'company' : 'user',
            object: this.customer === undefined ? this.company : this.customer
        } : null;
    }

    getType = () => {

        switch(this.type) {
            case 'standard':
            return 'Standard';

            case 'overage':
            return 'Post Ride Overages';

            case 'standard_mixed':
            return 'Credits and Physical Card';

            case 'standard_credits':
            return 'Only Credits';

            case 'reservation_fee':
            return 'Reservation Fee';

            case 'cancellation':
            return 'Cancellation Fee';

            case 'driver_tip':
            return 'Driver Tip';

            case 'custom_payment':
            return 'Custom Payment';

            case 'covered':
            return 'No Cost Applied';

            default:
            return 'Unknown Payment Type'
        }
    }

    getTargetSummary = () => {
        let info = {
            title: 'Custom Payment',
            subTitle: moment(this.date).format('MMMM Do, YYYY [at] h:mma'),
            badge: {
                text: this.refunded ? 'Refunded' : (this.amount <= 0 ? 'No Cost' : Utils.toCurrency(this.amount)),
                color: this.refunded ? Appearance.colors.red : Appearance.colors.grey()
            }
        }
        if(this.type === 'credits') {
            info.title = 'Credits Purchase';
        }
        if(this.order) {
            info.title = `${this.order.channel.name} Order #${this.order.id} from ${this.order.host.name}`;
        }
        if(this.order) {
            info.title = `${this.order.channel.name} Order #${this.order.id} from ${this.order.host.name}`;
        }
        if(this.reservation) {
            info.title = `${this.reservation.destination.name && this.reservation.destination.name !== 'Current Location' ? this.reservation.destination.name : this.reservation.destination.address}`;
        }
        if(this.subscription) {
            info.title = `${this.subscription.plan.name} Subscription`;
        }
        return info;
    }
}
export default {
    create: props => new PaymentClass().create(props)
};
