import React from 'react';
import moment from 'moment';

import API from 'eCarra/files/api.js';
import Request from 'eCarra/files/Request/';
import Utils from 'eCarra/files/Utils.js';

class ClientParametersClass {

    id = null;
    appID = null;
    name = null;
    tagline = null;
    website = null;
    phone_number = null;
    email_address = null;
    facebook = null;
    twitter = null;
    instagram = null;
    privacy = null;
    logos = {};
    colors = null;
    active = false;

    constructor() {
        return this;
    }

    create = props => {
        this.id = props.id;
        this.appID = props.app_id;
        this.name = props.name;
        this.tagline = props.tagline;
        this.website = props.website;
        this.phone_number = props.contact.phone_number;
        this.email_address = props.contact.email_address;
        this.facebook = props.social.facebook;
        this.twitter = props.social.twitter;
        this.instagram = props.social.instagram;
        this.privacy = props.privacy;
        this.apps = props.apps;
        this.logos = {
            light: { uri: props.logo.light },
            dark: { uri: props.logo.dark },
            icon: { uri: props.logo.icon },
            mobile: { uri: props.logo.mobile }
        };
        this.colors = props.colors;
        this.active = Boolean(props.active);
        return this;
    }
}

const getClientParameters = async utils => {
    return new Promise(async (resolve, reject) => {
        try {
            let { parameters } = await Request.get(utils, '/saas/', {
                type: 'client_parameters',
                app_id: API.get_client_id()
            });
            resolve(new ClientParametersClass().create(parameters));

        } catch(e) {
            reject(e);
        }
    })
}

export default {
    ClientParameters: {
        create: props => new ClientParametersClass().create(props),
        get: utils => getClientParameters(utils)
    }
};
