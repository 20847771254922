import React, { useCallback, useEffect, useState } from 'react';
import { Animated, Image, Keyboard, Linking, Platform, ScrollView, Text, View } from 'react-native';

import moment from 'moment';
import update from 'immutability-helper';

import API from 'eCarra/files/api.js';
import Abstract from 'eCarra/classes/Abstract.js';
import AddressLookupField from 'eCarra/views/AddressLookupField.js';
import Appearance from 'eCarra/styles/Appearance.js';
import BoolToggle from 'eCarra/views/BoolToggle.js';
import { Call, Email, Text as TextMessage } from 'react-native-openanything';
import Carousel from 'react-native-snap-carousel';
import CreditsManager from 'eCarra/views/CreditsManager.js';
import DatePicker from 'eCarra/views/DatePicker/';
import DatePickerOnDemand from 'eCarra/views/DatePickerOnDemand.js';
import { DriverTip } from 'eCarra/managers/Payments.js';
import ImagePicker from 'eCarra/files/ImagePicker/';
import Layer from 'eCarra/structure/Layer.js';
import { LayerManifest } from 'eCarra/structure/Layer.js';
import List from 'eCarra/views/List.js';
import Logo from 'eCarra/files/lottie/logo-pulse.json';
import ModularContent from 'eCarra/classes/ModularContent.js';
import MultipleAddressLookupField from 'eCarra/views/MultipleAddressLookupField.js';
import MusicPlayer from 'eCarra/views/MusicPlayer.js';
import NumberStepper from 'eCarra/views/NumberStepper.js';
import Order from 'eCarra/classes/Order.js';
import Panel from 'eCarra/structure/Panel.js';
import PaymentMethodManager from 'eCarra/views/PaymentMethodManager.js';
import Permissions, { PERMISSIONS, RESULTS } from 'eCarra/files/Permissions/';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import Picker from 'eCarra/views/Picker/';
import Polyline from '@mapbox/polyline';
import PromoCodeLookup from 'eCarra/views/PromoCodeLookup.js';
import Reservation from 'eCarra/classes/Reservation.js';
import Request from 'eCarra/files/Request/';
import Route from 'eCarra/classes/Route.js';
import Screen, { isMobile, widthThreshold } from 'eCarra/files/Screen.js';
import Subscription from 'eCarra/classes/Subscription.js';
import TextField from 'eCarra/views/TextField.js';
import TextView from 'eCarra/views/TextView.js';
import User from 'eCarra/classes/User.js';
import Views from 'eCarra/views/Main.js'

const Utils = {
    addressLookup: async (utils, text, session) => {
        return new Promise(async (resolve, reject) => {
            try {
                let location = utils.location.last();
                let { places } = await Request.get(utils, '/resources/', {
                    type: 'address_lookup',
                    search_text: text,
                    session: session,
                    autocomplete: true,
                    ...location && {
                        lat: location.latitude,
                        long: location.longitude
                    }
                })

                resolve(places.map((result, index) => {
                    return {
                        key: index,
                        place_id: result.place_id,
                        name: result.name || 'Name Unavailable',
                        address: result.address
                    }
                }))

            } catch(e) {
                reject(e);
            }
        })
    },
    animateAsync: {
        timing: async (target, props) => {
            return new Promise(resolve => {
                Animated.timing(target, props).start(resolve);
            })
        }
    },
    apply: (target, object) => {
        let key = Object.keys(object).find(k => isNaN(k) ? k === target : parseFloat(k) === target);
        return key && typeof(object[key]) === 'function' ? object[key]() : (typeof(object.default) === 'function' ? object.default() : null);
    },
    applyFirst: (key, array, useObject) => {
        if(useObject === true) {
            let keys = Object.keys(array);
            let values = Object.values(array);
            for(var i in values) {
                if(values[i] === key) {
                    return keys[i];
                }
            }
            return null;
        }

        if(Array.isArray(array) !== true) {
            return null;
        }
        return array.find(item => item === key);
    },
    attributeForKey: {
        select: (e, key) => {
            let optionElement = e.target.childNodes[e.target.selectedIndex];
            return optionElement.getAttribute(key);
        }
    },
    camelToUnderscore: string => {
        return string.split(/(?=[A-Z])/).join('_').toLowerCase();
    },
    changeHue: (rgb, degree) => {
        var hsl = Utils.rgbToHSL(rgb);
        hsl.h += degree;
        if (hsl.h > 360) {
            hsl.h -= 360;
        }
        else if (hsl.h < 0) {
            hsl.h += 360;
        }
        return Utils.hslToRGB(hsl);
    },
    conformDate: (date, interval, nearest_neighbor) => {
        let targetDate = date ? moment(date) : moment();
        let minutes = parseInt(moment(targetDate).format('mm'));
        if(minutes % interval === 0) {
            return moment(targetDate);
            return;
        }

        // force date to interval offset and zero out seconds
        let decimal = minutes % interval;
        let offset = interval - decimal;
        if(!nearest_neighbor || interval / 2 > offset) {
            return moment(`${targetDate.format('YYYY-MM-DD HH:mm')}:00`).add(offset, 'minutes');
        }

        // round down to interval when nearest_neighbor is enabled
        return moment(`${targetDate.format('YYYY-MM-DD HH:mm')}:00`).subtract(interval - offset, 'minutes');
    },
    convertWirelessSignal: (signal, type) => {
        if(signal === 'full' || signal >= -30) {
            return {
                strength: 5,
                message: 'Strong signal strength',
                icon: type === 'wifi' ? require('eCarra/images/wifi-strong.png') : require('eCarra/images/signal-strong.png')
            }
        }
        if(signal >= -67) {
            return {
                strength: 4,
                message: 'Good signal strength',
                icon: type === 'wifi' ? require('eCarra/images/wifi-strong.png') : require('eCarra/images/signal-strong.png')
            }
        }
        if(signal >= -70) {
            return {
                strength: 3,
                message: 'Decent signal strength',
                icon: type === 'wifi' ? require('eCarra/images/wifi-good.png') : require('eCarra/images/signal-good.png')
            }
        }
        if(signal >= -80) {
            return {
                strength: 2,
                message: 'Poor signal strength',
                icon: type === 'wifi' ? require('eCarra/images/wifi-poor.png') : require('eCarra/images/signal-poor.png')
            }
        }
        if(signal >= -90) {
            return {
                strength: 1,
                message: 'Weak signal strength',
                icon: type === 'wifi' ? require('eCarra/images/wifi-weak.png') : require('eCarra/images/signal-weak.png')
            }
        }
        return {
            strength: 0,
            message: 'Not Usable',
            icon: type === 'wifi' ? require('eCarra/images/wifi-none.png') : require('eCarra/images/signal-none.png')
        }
    },
    create: async (utils, { id, type }) => {
        return new Promise(async (resolve, reject) => {
            try {
                switch(type) {
                    case 'orders':
                    let order = await Order.get(utils, id);
                    resolve({
                        order: order,
                        abstract: Abstract.create({
                            type: type,
                            object: order
                        })
                    });
                    break;

                    case 'routes':
                    let route = await Route.get(utils, id);
                    resolve({
                        route: route,
                        abstract: Abstract.create({
                            type: type,
                            object: route
                        })
                    });
                    break;

                    case 'reservations':
                    let reservation = await Reservation.get(utils, id);
                    resolve({
                        reservation: reservation,
                        abstract: Abstract.create({
                            type: type,
                            object: reservation
                        })
                    });
                    break;
                }

            } catch(e) {
                reject(e);
            }
        })
    },
    createMultiple: async (utils, { order_id, route_id, reservation_id, subscription_id }) => {
        return new Promise(async (resolve,reject) => {
            try {
                let { order, route, reservation, subscription } = await Request.get(utils, '/resources/', {
                    type: 'details_multiple',
                    order_id: order_id,
                    route_id: route_id,
                    reservation_id: reservation_id,
                    subscription_id: subscription_id
                });
                resolve({
                    ...order && {
                        order: Order.create(order)
                    },
                    ...route && {
                        route: Route.create(route)
                    },
                    ...reservation && {
                        reservation: Reservation.create(reservation)
                    },
                    ...subscription && {
                        subscription: Subscription.create(subscription)
                    }
                });

            } catch(e) {
                reject(e);
            }
        })
    },
    decodePolyline: (str, precision) => {
        return Polyline.decode(str, precision)
    },
    decodeValhallaPolyline: (str, precision) => {
        var index = 0,
            lat = 0,
            lng = 0,
            coordinates = [],
            shift = 0,
            result = 0,
            byte = null,
            latitude_change,
            longitude_change,
            factor = Math.pow(10, precision || 6);

        // Coordinates have variable length when encoded, so just keep
        // track of whether we've hit the end of the string. In each
        // loop iteration, a single coordinate is decoded.
        while (index < str.length) {

            // Reset shift, result, and byte
            byte = null;
            shift = 0;
            result = 0;

            do {
                byte = str.charCodeAt(index++) - 63;
                result |= (byte & 0x1f) << shift;
                shift += 5;
            } while (byte >= 0x20);

            latitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));

            shift = result = 0;

            do {
                byte = str.charCodeAt(index++) - 63;
                result |= (byte & 0x1f) << shift;
                shift += 5;
            } while (byte >= 0x20);

            longitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));

            lat += latitude_change;
            lng += longitude_change;

            coordinates.push([lat / factor, lng / factor]);
        }

        return coordinates;
    },
    deviceType: () => {
        if(Platform.OS !== 'web') {
            return Platform.OS;
        }
        if([ 'iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod' ].includes(navigator.platform)) {
            return 'ios';
        }
        if(navigator.platform.includes('Mac')) {
            return 'macos';
        }
        return 'ontouchend' in document ? 'ios' : 'android';
    },
    distanceConversion: (value, abbreviation) => {
        let feet = parseFloat(value) * 5280;
        if(abbreviation) {
            return feet > 999 ? (parseFloat(value).toFixed(1) + (value === 1 ? ' mi' : ' mi')) : (feet.toFixed(0) + (feet === 1 ? ' ft' : ' ft'));
        }
        return feet > 999 ? (parseFloat(value).toFixed(1) + (value === 1 ? ' mile' : ' miles')) : (feet.toFixed(0) + (feet === 1 ? ' foot' : ' feet'));
    },
    encryptedURL: async (utils, props, callback) => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/resources/', {
                    type: 'encrypted_url',
                    ...props
                });
                resolve(response);
            } catch(e) {
                reject(e);
            }
        })
    },
    formatAddress: props => {
        // return formatted address in accordance with google auto complete formatting
        let { address, country, city, name, state, zipcode } = props || {};
        if(address && city && state && zipcode) {
            return `${address}, ${city}, ${state} ${zipcode}${country ? `, ${country}` : ''}`
        }
        // return ala-carte address components as they are found
        return [ name, address, city, state, zipcode, country ].filter(val => {
            return val && val.toString().length > 1 ? true : false;
        }).join(', ');
    },
    formatDate: date => {
        if(!date) {
            return 'Unknown';
        }
        let next_date = moment(date);
        if(moment().isSame(next_date, 'day')) {
            return moment(date).format('[Today at] h:mma');
        }
        if(moment().subtract(1, 'days').isSame(next_date, 'day')) {
            return moment(date).format('[Yesterday at] h:mma');
        }
        if(moment().add(1, 'days').isSame(next_date, 'day')) {
            return moment(date).format('[Tomorrow at] h:mma');
        }
        if(next_date >= moment().subtract(6, 'days') && next_date <= moment()) {
            return moment(date).format('dddd [at] h:mma');
        }
        if(moment().isSame(next_date, 'year')) {
            return moment(date).format('MMM Do [at] h:mma');
        }
        return moment(date).format('MM/DD/YYYY');
    },
    formatPhoneNumber: (phone_number, countryCode = 'US') => {
        try {
            let instance = PhoneNumberUtil.getInstance();
            let result = instance.parse(phone_number, countryCode);
            return instance.format(result, PhoneNumberFormat.INTERNATIONAL);
        } catch(e) {
            return phone_number;
        }
    },
    fetchModularContent: async (utils, category) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { content } = await Request.get(utils, '/resources/', {
                    type: 'modular_content',
                    category: category
                });
                resolve({ content: content.map(content => ModularContent.create(content)) });
            } catch(e) {
                reject(e);
            }
        })
    },
    geocode: async (utils, location, session) => {
        return new Promise(async (resolve,reject) => {
            try {
                let response = await Request.get(utils, '/resources/', {
                    type: 'geocode_location',
                    session: session,
                    ...location
                });
                resolve(response);
            } catch(e) {
                reject(e);
            }
        })
    },
    getClientLottieLogo: utils => {
        if(utils.client.get().id === 'ecarra' && Platform.OS !== 'web') {
            return Logo;
        }
        return {
            ...Logo,
            assets: Logo.assets.map(asset => {
                // replace enclosure icon if applicable
                if(asset.id === 'enclosure' && Platform.OS === 'web') {
                    asset.u = '';
                    asset.p = `${API.server}/images/enclosure.png`;
                }
                return asset;
            }),
            layers: Logo.layers.map(layer => {
                if(utils.client.get().id === 'ecarra') {
                    return layer;
                }
                // replace colors in gradient
                if(layer.nm !== 'gradient-layer') {
                    return layer;
                }
                try {
                    let { dark, light, regular } = utils.client.get().colors;
                    let [r1, g1, b1] = Utils.hexToRGBComponents(dark);
                    let [r2, g2, b2] = Utils.hexToRGBComponents(regular);
                    let [r3, g3, b3] = Utils.hexToRGBComponents(light);
                    layer.shapes[0].it[1].g.k.k = [ 0, r1, g1, b1, 0.5, r2, g2, b2, 1, r3, g3, b3 ];
                } catch(e) {
                    console.error(e.message);
                }
                return layer;
            })
        }
    },
    getPagingOffset: (offset, direction) => {

        var newOffset = 0;
        if(direction == 'next') {
            newOffset = offset + 5;
        } else if(direction == 'back') {
            newOffset = offset - 5;
        } else if(!isNaN(direction)) {
            newOffset = (direction - 1) * 5;
        }
        return newOffset < 0 ? 0 : newOffset;
    },
    getRegionFromAnnotations: coordinates => {

        let northWest = {
            latitude: -90,
            longitude: 180
        };
        let southEast = {
            latitude: 90,
            longitude: -180
        };

        coordinates.forEach((coordinate) => {
            northWest.longitude = Math.min(northWest.longitude, isNaN(coordinate[1]) ? coordinate.location.longitude : coordinate[1]);
            northWest.latitude = Math.max(northWest.latitude, isNaN(coordinate[0]) ? coordinate.location.latitude : coordinate[0]);

            southEast.longitude = Math.max(southEast.longitude, isNaN(coordinate[1]) ? coordinate.location.longitude : coordinate[1]);
            southEast.latitude = Math.min(southEast.latitude, isNaN(coordinate[0]) ? coordinate.location.latitude : coordinate[0]);
        })

        return [
            [ northWest.longitude, northWest.latitude ],
            [ southEast.longitude, southEast.latitude ]
        ];
    },
    hexToRGBA: (hex, alpha = 1) => {

        var c;
    	if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    		c= hex.substring(1).split('');
    		if(c.length === 3){
    			c= [c[0], c[0], c[1], c[1], c[2], c[2]];
    		}
    		c= '0x'+c.join('');
    		return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',' + alpha + ')';
    	}
    	return hex;
    },
    hexToRGBComponents: hex => {
        var c;
    	if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    		c= hex.substring(1).split('');
    		if(c.length === 3){
    			c= [c[0], c[0], c[1], c[1], c[2], c[2]];
    		}
    		c= '0x'+c.join('');
            let components = [(c>>16)&255, (c>>8)&255, c&255]
    		return [components[0] / 255, components[1] / 255, components[2] / 255];
    	}
    	return [];
    },
    hslToRGB: (hsl) => {
        var h = hsl.h,
            s = hsl.s,
            l = hsl.l,
            c = (1 - Math.abs(2*l - 1)) * s,
            x = c * ( 1 - Math.abs((h / 60 ) % 2 - 1 )),
            m = l - c/ 2,
            r, g, b;

        if (h < 60) {
            r = c;
            g = x;
            b = 0;
        } else if (h < 120) {
            r = x;
            g = c;
            b = 0;
        } else if (h < 180) {
            r = 0;
            g = c;
            b = x;
        } else if (h < 240) {
            r = 0;
            g = x;
            b = c;
        } else if (h < 300) {
            r = x;
            g = 0;
            b = c;
        } else {
            r = c;
            g = 0;
            b = x;
        }

        r = Utils.normalizeRGBValue(r, m);
        g = Utils.normalizeRGBValue(g, m);
        b = Utils.normalizeRGBValue(b, m);

        return Utils.rgbToHex(r,g,b);
    },
    integerToOrdinal: i => {

        let j = i % 10;
        let k = i % 100;

        if(j === 1 && k !== 11) {
            return i + 'st';
        } else if (j === 2 && k !== 12) {
            return i + 'nd';
        } else if (j === 3 && k !== 13) {
            return i + 'rd';
        }
        return i + 'th';
    },
    isMobile: isMobile,
    linearDistance: (coordinate, center) => {
        if(!coordinate || !center) {
            return null;
        }
        var radlat1 = Math.PI * (coordinate.lat || coordinate.latitude) / 180;
        var radlat2 = Math.PI * (center.lat || center.latitude) / 180;
        var theta = (coordinate.long || coordinate.longitude) - (center.long || center.longitude);
        var radtheta = Math.PI * theta/180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        return dist
    },
    metersToMiles: value => {
        return parseFloat(value) / 1609;
    },
    normalizeRGBValue: (color, m) => {
        color = Math.floor((color + m) * 255);
        if (color < 0) {
            color = 0;
        }
        return color;
    },
    numberFormat: value => {
        if(value > 1000000) {
            return `${(parseFloat(value) / 1000000).toFixed(2)}M`;
        }
        if(value > 1000) {
            return `${(parseFloat(value) / 1000).toFixed(1)}K`;
        }
        return parseInt(value);
    },
    objectRemove: (object, camelCase) => {
        return Object.keys(object).reduce((o, key) => {
            return {
                ...o,
                ...(object[key] !== null && object[key] !== undefined ? {
                    [camelCase === false ? Utils.camelToUnderscore(key) : key]: object[key]
                } : null)
            }
        }, {});
    },
    oxfordImplode: items => {
        if(!items || items.length === 0) {
            return null;
        }
        if(items.length === 1) {
            return items[0];
        }
        if(items.length === 2) {
            return `${items[0]} and ${items[1]}`;
        }
        let string = '';
        for(var i in items) {
            if(i > 0) {
                string += parseInt(i) === items.length - 1 ? ', and ' : ', ';
            }
            string += items[i];
        }
        return string;
    },
    parseBytes: bytes => {
        let thresh = 1000;
        if(Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }
        let units = ['kB','MB','GB','TB','PB','EB','ZB','YB'];
        let u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while(Math.abs(bytes) >= thresh && u < units.length - 1);
        return bytes.toFixed(1) + ' ' + units[u];
    },
    parseDuration: (duration, extended) => {

        let d = parseInt(duration);
        let h = Math.floor(d / 3600);
        let m = Math.floor(d % 3600 / 60);
        let s = Math.floor(d % 3600 % 60);

        let hours = h > 0 ? h + (h === 1 ? ' hour' : ' hours') : null;
        let minutes = m > 0 ? m + (m === 1 ? ' minute' : ' minutes') : null;
        let seconds = s + (s === 1 ? ' second' : ' seconds');
        if(hours && minutes) {
            return `${hours} and ${minutes}`;
        } else if(hours) {
            return `${hours}${extended ? `, ${minutes}, and ${seconds}` : ''}`;
        } else if(m > 1) {
            return `${minutes}${extended ? ` ${seconds}` : ''}`;
        }
        return seconds;
    },
    promptMobileDownload: utils => {
        utils.alert.show({
            title: 'Just a Second',
            message: `QuickScan is not available on the web. Please download the ${utils.client.get().name} app from the Apple App Store or Google Play to continue.`,
            buttons: [{
                key: 'app_store',
                title: 'Apple App Store',
                style: 'default',
                visible: Utils.isMobile() === false || [ 'ios', 'macos' ].includes(Utils.deviceType())
            },{
                key: 'play_store',
                title: 'Google Play Store',
                style: 'default',
                visible: Utils.isMobile() === false || Utils.deviceType() === 'android'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: 'cancel'
            }],
            onPress: key => {
                if(key === 'app_store') {
                    window.open(utils.client.get().apps.ios);
                    return;
                }
                if(key === 'play_store') {
                    window.open(utils.client.get().apps.android);
                    return;
                }
            }
        })
    },
    randomString: () => {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    },
    replaceLottieColor: (source, search, replace) => {
        let content = JSON.stringify(source);
        content = content.replace(new RegExp(search, 'g'), Utils.hexToRGBComponents(replace).join(','));
        return JSON.parse(content);
    },
    rgbToHex: (r, g, b) => {
        return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    },
    rgbToHSL: rgb => {
        rgb = rgb.replace(/^\s*#|\s*$/g, '');
        if(rgb.length == 3){
            rgb = rgb.replace(/(.)/g, '$1$1');
        }

        var r = parseInt(rgb.substr(0, 2), 16) / 255,
            g = parseInt(rgb.substr(2, 2), 16) / 255,
            b = parseInt(rgb.substr(4, 2), 16) / 255,
            cMax = Math.max(r, g, b),
            cMin = Math.min(r, g, b),
            delta = cMax - cMin,
            l = (cMax + cMin) / 2,
            h = 0,
            s = 0;

        if (delta == 0) {
            h = 0;
        } else if (cMax == r) {
            h = 60 * (((g - b) / delta) % 6);
        } else if (cMax == g) {
            h = 60 * (((b - r) / delta) + 2);
        } else {
            h = 60 * (((r - g) / delta) + 4);
        }

        if (delta == 0) {
            s = 0;
        } else {
            s = (delta/(1-Math.abs(2*l - 1)))
        }

        return {
            h: h,
            s: s,
            l: l
        }
    },
    setRequiredLevels: (level, object) => {
        let key = Object.keys(object).find(k => {
            switch(k) {
                case 'admin':
                return level <= User.level.admin

                case 'driver':
                return level <= User.level.driver

                case 'customer':
                return level === User.level.customer

                case 'company':
                return level > User.level.customer;

                default:
                return false
            }
        });
        return key && typeof(object[key]) === 'function' ? object[key]() : null;
    },
    showSupportOptions: (utils, props) => {

        utils.sheet.show({
            title: 'Get Support',
            message: `We're here to help if you have any questions or concerns about your time with us. Please choose an option below to get in touch with our support team.`,
            items: [{
                key: 'call',
                title: 'Call',
                style: 'default',
                visible: Platform.OS !== 'web' || Utils.deviceType() === 'macos'
            },{
                key: 'email',
                title: 'Email',
                style: 'default'
            },{
                key: 'text',
                title: 'Text Message',
                style: 'default',
                visible: Platform.OS !== 'web' || Utils.deviceType() === 'macos'
            }]
        }, (key) => {
            if(key === 'call') {
                if(Platform.OS === 'web') {
                    window.open(`facetime-audio:${utils.client.get().phone_number}`);
                    return;
                }
                Call(utils.client.get().phone_number, true).catch(e => {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue setting up your call. ${e.message || 'An unknown error occurred'}`
                    })
                })
                return;
            }
            if(key === 'text') {
                if(Platform.OS === 'web') {
                    window.open(`sms:${utils.client.get().phone_number}`);
                    return;
                }
                TextMessage(utils.client.get().phone_number, props ? props.message : null, false).catch(e => {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue setting up your text message. ${e.message || 'An unknown error occurred'}`
                    })
                })
                return;
            }
            if(key === 'email') {
                Email(utils.client.get().email_address, `${utils.client.get().name} Support`, props ? props.message : null).catch(e => {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue setting up your email. ${e.message || 'An unknown error occurred'}`
                    })
                });
                return;
            }
        })
    },
    simulateSocket: async (utils, action) => {
        return new Promise(async (resolve, reject) => {
            try {
                let request = await Request.post(utils, '/sockets/', {
                    type: 'simulate',
                    action: action
                });
                resolve(json);
            } catch(e) {
                reject(e);
            }
        })
    },
    sleep: async seconds => {
        return new Promise(resolve => {
            setTimeout(resolve, (seconds || 0) * 1000);
        })
    },
    softNumberFormat: (val, digits = 0) => {
        return parseFloat(val).toLocaleString('en-US', { minimumFractionDigits: digits })
    },
    timecode: duration => {

        let value = parseInt(duration);
        let sec_num = parseInt(value, 10); // don't forget the second param
        let hours   = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        let seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (seconds < 10) {seconds = "0"+seconds;}
        return minutes + ' : ' + seconds;
    },
    toCurrency: (value, currency) => {
        return parseFloat(value || 0).toLocaleString('en-US', {
            style: 'currency',
            currency: currency || 'USD'
        })
    },
    transformStylesForWeb: styles => {
        if(!styles) {
            return null;
        }
        return {
            ...styles,
            ...styles.paddingVertical && {
                paddingTop: styles.paddingVertical,
                paddingBottom: styles.paddingVertical
            },
            ...styles.paddingHorizontal && {
                paddingLeft: styles.paddingHorizontal,
                paddingRight: styles.paddingHorizontal
            },
            ...styles.marginVertical && {
                marginTop: styles.marginVertical,
                marginBottom: styles.marginVertical
            },
            ...styles.marginHorizontal && {
                marginLeft: styles.marginHorizontal,
                marginRight: styles.marginHorizontal
            }
        }
    },
    ucFirst: text => {
        return text ? (text.charAt(0).toUpperCase() + text.substring(1)) : '';
    },
    yAxisFormat: value => {
        if(value > 1000000) {
            return `${(parseInt(value) / 1000000).toFixed(2)}M`;
        }
        if(value > 1000) {
            return `${(parseInt(value) / 1000).toFixed(2)}K`;
        }
        return parseInt(value);
    }
}
export default Utils;

export const ModularContentLogic = ({ content, category, onPress, onRequestLogic, style, utils, width }) => {

    const [scrollOffset, setScrollOffset] = useState(0);
    const maxWidth = width || (Utils.isMobile() === true ? Screen.width() : (Screen.panel.maxWidth() - Screen.sidebar.maxWidth));

    const onContentPress = content => {
        if(content.url) {
            utils.layer.webView({
                id: `webview-${content.id}`,
                title: content.title,
                url: content.url
            });
            return;
        }
        utils.alert.show({
            title: content.title,
            message: content.description,
            buttons: [{
                key: 'learn',
                title: 'Learn More',
                style: 'default',
                visible: content.mobileAction ? true : false
            },{
                key: 'cancel',
                title: 'Dismiss',
                style: 'cancel'
            }],
            onPress: (key) => {
                if(key === 'learn') {
                    runMobileAction(content.mobileAction);
                    if(typeof(onPress) === 'function') {
                        onPress();
                    }
                }
            }
        })
    }

    const runMobileAction = (action) => {

        let components = action.split('.');
        switch(components[0]) {
            case 'layer':
            runMobileLayerAction(components);
            break;

            case 'view':
            parseViewComponents(components);
            break;
        }
    }

    // Views
    const parseViewComponents = (components) => {
        switch(components[2]) {
            case 'panel':
                runMobilePanelAction(components);
                break;
        }
    }

    // Panels
    const runMobilePanelAction = components => {

        let view = components[1];
        let panel = components[3];
        let action = components[4];

        let activeView = utils.structure.workspace.activeView();
        if(activeView !== view) {
            utils.structure.workspace.set(view);
        }

        setTimeout(() => {
            switch(action) {
                case 'scroll_to':
                utils.structure.workspace.scrollTo(view, panel);
                break;
            }
        }, activeView === view ? 0 : 1000);
        //view.emissions.panel.futurePotential.scroll_to
    }

    // Layers
    const runMobileLayerAction = components => {

        switch(components[2]) {
            case 'show':
            setLayerProps(components);
            break;

            case 'hide':
            utils.layer.close(components[1]);
            break;
        }
    }

    const setLayerProps = components => {

        let layer = LayerManifest.get().find(layer => layer.key === components[1]);
        if(!layer) {
            utils.alert.show({
                title: 'Oops!',
                message: 'There was an issue loading this content. An unknown error occurred'
            })
            return;
        }

        if(layer.requestLogic && !onRequestLogic) {
            utils.alert.show({
                title: 'Oops!',
                message: 'There was an issue loading this content. An unknown error occurred'
            })
            return;
        }

        // validate keys for logic
        if(layer.requestLogic || layer.optionalRequestLogic) {

            if(layer.requestLogic) {
                let matches = layer.requestLogic.filter(key => Object.keys(onRequestLogic).includes(key));
                if(matches.length !== layer.requestLogic.length) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: 'There was an issue loading this content. An unknown error occurred'
                    })
                    return;
                }
            }

            // look for optional keys in request logic
            // fallback to optionalRequestLogic values if not found
            let optionalLogic = {};
            if(layer.optionalRequestLogic) {
                optionalLogic = Object.keys(layer.optionalRequestLogic).reduce((object, key) => {

                    let itemKey = Object.keys(layer.requestLogic || {}).find(k => k === key);
                    if(itemKey && layer.requestLogic) {
                        object[key] = layer.requestLogic[itemKey];
                    }
                    return object;
                }, layer.optionalRequestLogic);
            }

            utils.layer.open({
                id: layer.layerID,
                Component: layer.Component.bind(this, {
                    ...onRequestLogic,
                    ...optionalLogic
                })
            });
            return;
        }

        utils.layer.open({
            id: layer.layerID,
            Component: layer.Component
        });
    }

    const getCarouselItemStyles = () => {
        return {
            paddingTop: 8,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 15
        }
    }

    const getCarouselProps = target => {
        return {
            sliderWidth: maxWidth,
            itemWidth: maxWidth - 30,
            ...target.length > 1 && {
                itemWidth: maxWidth - 80,
            },
            ...Platform.OS !== 'web' && {
                layoutCardOffset: 0,
                loop: target.length > 1
            },
            ...(Platform.OS === 'web' || Utils.isMobile() === false) && {
                sliderWidth: maxWidth,
                itemWidth: maxWidth - 30,
                ...target.length > 1 && {
                    itemWidth: maxWidth / 2
                }
            }
        }
    }

    return content && content.length > 0
        ?
        <View style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            ...style
        }}>
            <Carousel
            data={content}
            {...getCarouselProps(content)}
            onScrollIndexChanged={index => setScrollOffset(index)}
            renderItem={({ item, index }) => {
                return (
                    <View
                    key={index}
                    style={getCarouselItemStyles()}>
                        <View style={{
                            ...Appearance.styles.panel(),
                            shadowOpacity: 0,
                            borderWidth: 1,
                            borderColor: Appearance.colors.primary()
                        }}>
                            {Views.entry({
                                title: item.title,
                                subTitle: item.message,
                                bottomBorder: false,
                                hideIcon: category === 'sidebar',
                                onPressArrow: category !== 'sidebar',
                                icon: {
                                    path: require('eCarra/images/notifications-icon-grey.png'),
                                    style: {
                                        borderRadius: 0,
                                        backgroundColor: Appearance.colors.transparent
                                    },
                                    imageStyle: {
                                        resizeMode: 'contain',
                                        tintColor: Appearance.colors.primary()
                                    }
                                },
                                propStyles: {
                                    subTitle: {
                                        numberOfLines: 2
                                    }
                                },
                                textStyles: {
                                    title: {
                                        ...category === 'sidebar' && {
                                            textAlign: 'center'
                                        }
                                    },
                                    subTitle: {
                                        ...category === 'sidebar' && {
                                            textAlign: 'center'
                                        }
                                    }
                                },
                                onPress: onContentPress.bind(this, item)
                            })}
                        </View>
                    </View>
                )
            }} />
            {content && content.length > 1 && (
                <View style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '100%',
                    height: 3,
                    marginBottom: 12
                }}>
                    {content.map((_, index) => {
                        return (
                            <View
                            key={index}
                            style={{
                                width: 15,
                                height: 3,
                                borderRadius: 1.5,
                                backgroundColor: scrollOffset === index ? Appearance.colors.primary() : Appearance.colors.grey(),
                                marginHorizontal: 4
                            }}/>
                        )
                    })}
                </View>
            )}
        </View>
        :
        null
}

export const ModularContentPanel = ({ category, style }, { utils, options }) => {

    const panelID = 'modularContent';
    const [content, setContent] = useState(null);

    const setupTarget = async () => {
        try {
            let { content } = await Utils.fetchModularContent(utils, category);
            setContent(content);
        } catch(e) {
            console.error(e.message);
        }
    }
    useEffect(() => {
        setupTarget();
    }, []);

    return content && content.length > 0 ? (
        <Panel
        key={panelID}
        panelID={panelID}
        options={{
            removePadding: true,
            removeOverflow: true,
            shouldStyle: false,
        }}>
            <ModularContentLogic
            utils={utils}
            category={category}
            content={content}
            style={{
                ...style,
                maxWidth: Screen.panel.maxWidth() - Screen.sidebar.maxWidth,
                marginBottom: content.length > 1 ? 0 : 15
            }}/>
        </Panel>
    ) : null
}

export const NoDataFound = ({ fill, message, title }) => {

    return (
        <View style={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: 'absolute',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: fill,
            borderRadius: 10
        }}>
            <View style={{
                width: 45,
                height: 45,
                borderRadius: 25,
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 8,
                paddingTop: 8,
                backgroundColor: Appearance.colors.primary()
            }}>
                <Image source={require('eCarra/images/icon-logo.png')} style={{
                    width: '100%',
                    height: '100%',
                    resizeMode: 'contain',
                    //boxShadow: '3px 3px 7px #c9c8ca, -3px -3px 5px #ffffff'
                }} />
            </View>
            <Text style={{
                ...Appearance.textStyles.standard(),
                marginTop: 8
            }}>{title || 'Nothing to see here'}</Text>
            <Text style={{
                ...Appearance.textStyles.supporting(),
                maxWidth: 250,
                textAlign: 'center'
            }}>{message}</Text>
        </View>
    )
}

export const LineItem = ({ item, preferredKey, onChange }, { utils, index, options }) => {

    const layerID = `line-item-${item.key}`;
    const [invalidDate, setInvalidDate] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const [tempContent, setTempContent] = useState(null);

    const onDonePress = () => {
        if(invalidDate) {
            let { options = {} } = item.on_edit || {};
            let minDate = options.offset ? Utils.conformDate(moment().add(options.offset || 0, 'seconds'), 15) : moment();
            utils.alert.show({
                title: 'Just a Second',
                message: `Please choose a date after ${Utils.formatDate(minDate)} before moving on`
            });
            return;
        }

        Keyboard.dismiss();
        if(typeof(onChange) === 'function') {
            setTimeout(() => {
                setLayerState('close');
                if(tempContent && Object.keys(tempContent).length > 1) {
                    onChange(item, tempContent);
                }
                onChange(item, tempContent);
            }, keyboardOpen ? 750 : 0);
        }
    }

    const onInvalidDateChange = val => {
        setInvalidDate(val);
    }

    const onItemChange = (value, _item) => {
        setTempContent(tempContent => {

            if(!tempContent) {
                tempContent = {};
            }

            // Used for specialty items that don't conform to standard formatting
            if(item.on_edit.structure) {
                let { key, replace, format } = item.on_edit.structure;
                tempContent[key] = {
                    ...format,
                    [replace]: value
                }
                return tempContent;
            }

            // Standard formatting
            tempContent[preferredKey || (_item ? _item.key : item.key)] = value;
            return tempContent;
        })
    }

    const onItemDeselect = () => {
        setTempContent(tempContent => {
            if(!tempContent) {
                tempContent = {};
            }
            tempContent[item.key] = false;
            return tempContent;
        })
    }

    const onRemovePress = () => {
        Keyboard.dismiss();
        if(typeof(onChange) === 'function') {
            setTimeout(() => {
                setLayerState('close');
                onChange(item, { [item.key]: false });
            }, keyboardOpen ? 750 : 0);
        }
    }

    const getButtons = () => {
        let buttons = [];
        if(item.value && item.on_edit && item.on_edit.removable) {
            buttons.push({
                key: 'remove',
                text: 'Remove',
                color: 'danger',
                onPress: onRemovePress
            });
        }
        return buttons.concat([{
            key: 'done',
            text: 'Done',
            color: 'primary',
            onPress: onDonePress
        }]);
    }

    const getChildren = () => {

        let { options = {} } = item.on_edit || {};
        switch(item.on_edit.component) {
            case 'date_time_picker':
            return (
                <DatePicker
                utils={utils}
                showTime={true}
                date={item.value}
                onInvalidChange={onInvalidDateChange}
                minDate={options.offset ? Utils.conformDate(moment().add(options.offset || 0, 'seconds'), 15) : null}
                onChange={date => onItemChange(moment(date).format('YYYY-MM-DD HH:mm:ss'))} />
            )

            case 'date_time_picker_on_demand':
            let on_demand = options.on_demand || {};
            let minDate = options.offset ? Utils.conformDate(moment().add(options.offset || 0, 'seconds'), 15) : null;
            let itemKey = Utils.apply(item.key, {
                pickup_date: () => 'pickup_date',
                drop_off_date: () => 'drop_off_date',
                default: () => item.key
            });
            return (
                <DatePickerOnDemand
                utils={utils}
                showTime={true}
                date={item.value}
                itemKey={itemKey}
                minDate={minDate}
                enabled={on_demand ? on_demand.enabled : false}
                onInvalidChange={onInvalidDateChange}
                enabledContent={{
                    title: on_demand ? on_demand.title : null,
                    subTitle: on_demand ? on_demand.sub_title : null
                }}
                onChange={response => {
                    setTempContent(response);
                }} />
            )

            case 'address_lookup':
            return (
                <AddressLookupField
                value={item.value}
                utils={utils}
                onChange={onItemChange}/>
            )

            case 'multiple_address_lookup':
            return (
                <MultipleAddressLookupField
                utils={utils}
                locations={item.value}
                label={'My Stops'}
                onChange={onItemChange} />
            )

            case 'picker':
            return (
                <Picker
                items={item.on_edit.items}
                selected={item.value ? item.value.toString() : null}
                onChange={onItemChange}/>
            )

            case 'bool_toggle':
            return (
                <BoolToggle
                isEnabled={item.value}
                disabled={'No'}
                enabled={'Yes'}
                onChange={onItemChange}/>
            )

            case 'credits':
            return (
                <CreditsManager
                utils={utils}
                defaultCardID={item.value}
                defaultAmount={item.sub_value}
                nullable={item.on_edit.nullable}
                onChange={onItemChange}
                onRemove={onItemDeselect} />
            )

            case 'payment_method':
            return (
                <PaymentMethodManager
                utils={utils}
                defaultCardID={item.value}
                onChange={({ method }) => onItemChange(method)}/>
            )

            case 'promo_code':
            return (
                <PromoCodeLookup
                utils={utils}
                defaultPromoCode={item.formatted}
                onChange={onItemChange}/>
            )

            case 'number_stepper':
            if(item.on_edit.items) {
                return (
                    item.on_edit.items.map((item, index) => {
                        return (
                            <View key={index}>
                                <Text style={{
                                    ...Appearance.textStyles.title(),
                                    marginBottom: 8,
                                    marginTop: 20,
                                    textAlign: 'center'
                                }}>{item.title}</Text>
                                <NumberStepper
                                min={item.min}
                                max={item.max}
                                startingValue={item.value}
                                onChange={value => onItemChange(value, item)}/>
                            </View>
                        )
                    })
                )
            }

            return (
                <NumberStepper min={0}
                startingValue={item.value}
                onChange={onItemChange}/>
            )

            case 'text_field':
            return (
                <TextField
                value={item.value}
                onChange={onItemChange}/>
            )

            case 'login_field':
            return (
                <View style={{
                    width: '100%'
                }}>
                    <TextField
                    placeholder={'Username'}
                    keyboardType={'email-address'}
                    autoCompleteType={'email'}
                    textContentType={'email_address'}
                    autoCapitalize={false}
                    onChange={text => {
                        setTempContent(tempContent => {
                            tempContent = {
                                ...tempContent,
                                username: text
                            }
                            return tempContent
                        })
                    }}
                    containerStyle={{
                        marginBottom: 8
                    }}/>
                    <TextField
                    isSecure={true}
                    placeholder={'Password'}
                    onChange={text => {
                        setTempContent(tempContent => {
                            tempContent = {
                                ...tempContent,
                                password: text
                            }
                            return tempContent
                        })
                    }}/>
                </View>
            )

            case 'text_view':
            return (
                <TextView
                value={item.value}
                onChange={onItemChange} />
            )

            case 'list':
            return (
                <List
                items={item.on_edit.items}
                onChange={onItemChange}
                onDeselect={onItemDeselect}
                nullable={item.on_edit.nullable}
                defaultValue={item.value}
                noneFound={{
                    title: item.on_edit.none_found.title,
                    subTitle: item.on_edit.none_found.sub_title,
                }}  />
            )

            default:
            return null;
        }
    }

    useEffect(() => {
        utils.keyboard.subscribe(layerID, {
            onVisibility: visible => setKeyboardOpen(visible)
        });

        return () => {
            utils.keyboard.unsubscribe(layerID);
        }
    }, [])

    return (
        <Layer
        id={layerID}
        title={item.title}
        utils={utils}
        index={index}
        options={{
            ...options,
            bottomCard: true,
            layerState: layerState
        }}
        buttons={getButtons()}>

            <View style={{
                alignItems: 'center',
                marginVertical: 5
            }}>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 6,
                }}>{item.title}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 20,
                    textAlign: 'center'
                }}>{item.on_edit.description}</Text>
                {getChildren()}
            </View>
        </Layer>
    )
}

export const useLoading = () => {
    const [state, setState] = useState('init');
    const onSetState = useCallback(val => {
        setState(val);
    }, []);
    return [state, onSetState]
}

export const useResultsManager = initialState => {

    const [state, setState] = useState({
        ...initialState,
        offset: 0
    });

    // state update accepts a props objects or a key, value pair
    const onSetState = useCallback((key, value) => {
        if(typeof(key) === 'string') {
            setState(props => update(props, {
                [key]: {
                    $set: value
                }
            }));
            return;
        }
        setState(key);
    }, []);

    // optional function to format results for query
    const formatResults = callback => {
        let results = {
            ...state,
            ...state.end_date && {
                end_date: moment(state.end_date).format('YYYY-MM-DD HH:mm:ss')
            },
            ...state.start_date && {
                start_date: moment(state.start_date).format('YYYY-MM-DD HH:mm:ss')
            }
        }
        // check if callback was provided for specialty formatting
        if(typeof(callback) === 'function') {
            results = callback(results);
        }
        return results;
    }

    return [state, onSetState, formatResults];
}

export const useRequestManager = (keys = []) => {

    const [state, setState] = useState(keys.reduce((object, key) => {
        object[key] = { pending: false };
        return object;
    }, {}));

    const onSetState = useCallback((key, props = {}) => {
        setState(collections => {

            // start with a key length comparrision
            // fallback to deep comparission of top level keys
            let pending = Object.keys(collections).length !== Object.keys(props).length;
            if(pending === false) {
                pending = Object.keys(collections[key] || []).find(inner_key => {
                    return props[inner_key] !== collections[inner_key];
                }) ? true : false;
            }
            // return previous state if no pending changes were submitted
            if(!pending) {
                return collections;
            }
            // update collections if pending changes were submitted
            return update(collections, {
                [key]: {
                    $set: {
                        ...collections[key],
                        ...props,
                        pending: pending
                    }
                }
            });
        });
    }, []);

    const onSetStatus = useCallback((key, val) => {
        setState(collections => {
            return update(collections, {
                [key]: {
                    $set: {
                        ...collections[key],
                        pending: val
                    }
                }
            });
        });
    }, []);
    return [state, onSetState, onSetStatus];
}
