import React from 'react';
import moment from 'moment';

import Appearance from 'eCarra/styles/Appearance.js';
import Company from 'eCarra/classes/Company.js';
import Driver from 'eCarra/classes/Driver.js';
import Request from 'eCarra/files/Request/';
import StatusCodes from 'eCarra/files/StatusCodes.js';
import User from 'eCarra/classes/User.js';

class VehicleClass {

    id = null;
    driver = null;
    customer = null;
    company = null;
    category = null;
    address = null;
    active = null;
	vin = null;
    plate = null;
    color = null;
    year = null;
    location = null;
    mileage = null;

    deepLinkEnabled = null;
    prefs = null;

    // communication when a vehicle is being actively used for an Order or Reservation
    // stores order_id or reservation_id for socket mongo entries
    targets = {};

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.id = parseInt(props.id);
        this.name = props.name;
        this.information = props.information;
        this.vin = props.vin;
        this.plate = props.plate;
        this.make = props.make;
        this.model = props.model;
        this.color = props.color;
        this.year = props.year;
        this.mileage = props.mileage;
        this.location = props.location && props.location.lat && props.location.long && {
            latitude: parseFloat(props.location.lat),
            longitude: parseFloat(props.location.long)
        };

        this.prefs = props.prefs;
        this.deepLinkEnabled = props.deep_link_enabled;
        this.driver = props.driver ? Driver.create(props.driver) : null;
        this.customer = props.customer ? User.create(props.customer) : null;
        this.company = props.company ? Company.create(props.company) : null;
        this.category = props.category ? new VehicleCategoryClass().create(props.category) : null;
        return this;
    }

    hasLocation = () => {
        return (this.location && this.location.latitude && this.location.longitude) ? true : false;
    }

    return = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let location = await utils.location.get();
                if(!location) {
                    throw new Error('We were unable to retrieve your current location. Your location is needed to log when and where you dropped of your vehicle.');
                    return;
                }

                await Request.post(utils, '/vehicle/', {
                    type: 'return',
                    vehicle_id: this.id,
                    user_id: utils.user.get().user_id,
                    lat: location.latitude,
                    long: location.longitude
                });
                this.available = false;
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }
}

class VehicleApplicationClass {

    id = null;
    user_id = null;
    contact = null;
    date = null;
    application = null;
    images = null;
    status = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.user_id = props.user_id;
        this.contact = props.contact;
        this.date = props.date ? moment(props.date) : null;
        this.customer = props.customer ? User.create(props.customer) : null;
        this.application = props.application;
        this.images = props.images;
        this.status = props.status ? getStatus(props.status.code) : null;
        return this;
    }
}

class VehicleCategoryClass {

    id = null;
	name = null;
	information = null;
    make = null;
    model = null;
    rate = 0.0;
	baseRate = 0.0;
    hourlyRate = 0.0;
    durationRate = 0.0;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        if(!props) {
            return;
        }

        this.id = parseInt(props.id);
        this.name = props.name;
        this.information = props.information;
        this.make = props.make;
        this.model = props.model;
        this.rate = props.rate;
        this.hourlyRate = props.hourly_rate;
        this.baseRate = props.base_rate;
        this.durationRate = props.duration_rate;

        return this;
    }
}

const getStatus = (c) => {

    // Appearance module could be null at runtime
    let code = c === null ? c : parseInt(c);
    let status = {
        code: code
    };

    if(code === StatusCodes.voApp.rejected) {
        status.text = 'Rejected';
        status.realText = 'Rejected';
        status.color = Appearance ? Appearance.colors.red : null;

    } else if(code === StatusCodes.voApp.approved) {
        status.text = 'Approved';
        status.realText = 'Approved';
        status.color = Appearance ? Appearance.colors.primary() : null;

    } else if(code === StatusCodes.voApp.cancelled) {
        status.text = 'Cancelled';
        status.realText = 'Cancelled';
        status.color = '#812222';

    } else if(code === StatusCodes.voApp.infoNeeded) {
        status.text = 'Information Needed';
        status.realText = 'Information Needed';
        status.color = Appearance ? Appearance.colors.grey() : null;

    } else {
        status.text = 'Pending';
        status.realText = 'Pending';
        status.color = Appearance ? Appearance.colors.grey() : null;
    }

    return status;
}

export default {
    create: props => new VehicleClass().create(props),
    Application: {
        create: props => new VehicleApplicationClass().create(props),
        getStatus: getStatus
    },
    Category: {
        create: props => new VehicleCategoryClass().create(props)
    }
};
