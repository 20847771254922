import React, { useEffect, useState } from 'react';
import { View, Text, Image } from 'react-native';
import moment from 'moment';
import DatePicker from 'eCarra/views/DatePicker/';
import Layer from 'eCarra/structure/Layer.js';

const DualDatePicker = ({ defaultStartDate, defaultEndDate, onChange }, { abstract, index, options, utils }) => {

    const layerID = 'dual-date-picker';
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [layerState, setLayerState] = useState(false);

    const onDateChange = (field, date) => {
        if(field === 'start') {
            setStart(date);
        }
        if(field === 'end') {
            setEnd(date);
        }
    }

    const onDonePress = () => {
        if(!start || !end) {
            utils.alert.show({
                title: 'Oops!',
                message: 'Please choose a start and end date to continue'
            });
            return;
        }
        if(start > end) {
            utils.alert.show({
                title: 'Oops!',
                message: 'The start time can not be after the end time'
            });
            return;
        }
        setLayerState('close');
        if(typeof(onChange) === 'function') {
            onChange({
                startDate: start,
                endDate: end
            })
        }
    }

    useEffect(() => {
        setStart(defaultStartDate ? moment(defaultStartDate) : moment());
        setEnd(defaultEndDate ? moment(defaultEndDate) : moment());
    }, [defaultStartDate, defaultEndDate])

    return (
        <Layer
        id={layerID}
        title={'Date Picker'}
        utils={utils}
        index={index}
        options={{
            ...options,
            layerState: layerState,
            bottomCard: true
        }}
        buttons={[{
            key: 'done',
            text: 'Done',
            color: 'primary',
            onPress: onDonePress
        }]}>
            <View style={{
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <DatePicker
                utils={utils}
                date={start}
                onlyTime={true}
                onChange={date => onDateChange('start', date)} />

                <DatePicker
                utils={utils}
                date={end}
                onlyTime={true}
                onChange={date => onDateChange('end', date)} />
            </View>
        </Layer>
    )
}
export default DualDatePicker;
