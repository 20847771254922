import React from 'react';

import API from 'eCarra/files/api.js';
import Company from 'eCarra/classes/Company.js';
import Request from 'eCarra/files/Request/';
import Utils from 'eCarra/files/Utils.js';

class PaymentMethodClass {

    id = null;
    brand = null;
    last4 = null;
    funding = null;
    balance = null;
    expiration = null;
    default = false;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.id = props.id;
        this.token = props.token;
        this.balance = props.balance || 0;
        this.brand = props.brand;
        this.last4 = props.last4;
        this.funding = props.funding;
        this.creditsType = props.type;
        this.default = props.default || false;
        this.expiration = {
            month: props.exp_month,
            year: props.exp_year
        }
        return this;
    }

    description = () => {
        return `${this.brand} ${Utils.ucFirst(this.funding)} Ending in ${this.last4}`;
    }

    summary = () => {
        return this.brand === 'eCarra' ? ((this.creditsType === 'company' ? 'Company ' : '') + 'Credits (' + Utils.toCurrency(this.balance) + ')') : (this.brand + ' (' + this.last4 + ')');
    }

    type = () => {
        return this.brand === 'eCarra' ? ((this.creditsType === 'company' ? 'Company ' : '') + 'Credits Card') : (this.brand + ' ' + Utils.ucFirst(this.funding) + ' (' + this.last4 + ')');
    }

    subType = () => {
        return this.brand === 'eCarra' ? ('Balance: ' + Utils.toCurrency(this.balance)) : ('Expires on ' + this.expiration.month + '/' + this.expiration.year);
    }

    icon = () => {
        switch(this.brand) {
            case 'Visa':
            return 'fab fa-cc-visa';

            case 'American Express':
            return 'fab fa-cc-amex';

            case 'Discover':
            return 'fab fa-cc-discover';

            case 'MasterCard':
            return 'fab fa-cc-mastercard';

            default:
            return 'far fa-credit-card';
        }
    }

    setAsDefault = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/payment/', {
                    type: 'set_default_source',
                    source_id: this.id,
                    stripe_id: utils.user.get().stripe_customer_id
                });
                this.default = true;
                resolve();
            } catch(e) {
                reject(e);
            }
        })
    }

    removeMethod = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/payment/', {
                    type: 'remove_card',
                    card_id: this.id,
                    stripe_id: utils.user.get().stripe_customer_id
                });
                resolve({
                    methods: utils.user.get().payment_methods.filter(method => {
                        return method.id !== this.id
                    })
                });
            } catch(e) {
                reject(e);
            }
        })
    }
}
export default {
    create: props => new PaymentMethodClass().create(props)
};
