import React, { useEffect, useState } from 'react';
import { View, ScrollView, TouchableHighlight } from 'react-native';

import Appearance from 'eCarra/styles/Appearance.js';
import Service from 'eCarra/classes/Service.js';
import Subscription from 'eCarra/classes/Subscription.js';
import Screen from 'eCarra/files/Screen.js';
import Vehicle from 'eCarra/classes/Vehicle.js';
import Views from 'eCarra/views/Main.js';

const List = ({ defaultValue, items, onChange, onDeselect, noneFound, nullable }) => {

    const [selected, setSelected] = useState(defaultValue);

    const onItemPress = item => {
        let selectedItem = selected === item.id ? null : item.id;
        setSelected(selectedItem);
        if(selectedItem && typeof(onChange) === 'function') {
            onChange(item)
        }
        if(!selectedItem && typeof(onDeselect) === 'function') {
            onDeselect(item);
        }
    }

    const getIconProps = item => {
        if(!item.icon || !item.icon.style) {
            return null;
        }
        let { style } = item.icon;
        if(typeof(style) === 'string' && typeof(Appearance.icons[style]) === 'function') {
            return Appearance.icons[style]();
        }
        return Appearance.icons[style];
    }

    return (
        <View style={{
            ...Appearance.styles.panel(),
            width: '100%'
        }}>
            <ScrollView
            showsVerticalScrollIndicator={false}
            style={{
                width: '100%',
                maxHeight: Screen.height() / 3
            }}>
                {items && items.length > 0
                    ?
                    items.map((item, index, items) => {
                        let object = null;
                        switch(item.type) {
                            case 'vehicle':
                            object = Vehicle.Category.create(item.object)
                            break;

                            case 'service':
                            object = Service.create(item.object)
                            break;

                            case 'subscription_history':
                            object = Subscription.create(item.object)
                            break;
                        }
                        return (
                            Views.entry({
                                key: index,
                                title: item.title,
                                subTitle: item.description,
                                propStyles: item.prop_styles,
                                bottomBorder: index !== items.length - 1,
                                onPress: onItemPress.bind(this, item),
                                icon: {
                                    path: item.icon ? { uri: item.icon.path } : require('eCarra/images/list-icon-clear.png'),
                                    style: {
                                        ...getIconProps(item),
                                        backgroundColor: item.id === selected ? (item.color || Appearance.colors.primary()) : Appearance.colors.grey(),
                                        ...!item.description && {
                                            width: 20,
                                            height: 20,
                                        }
                                    }
                                }
                            })
                        )
                    })
                    :
                    noneFound && (
                        Views.entry({
                            title: noneFound.title,
                            subTitle: noneFound.subTitle,
                            icon: {
                                path: require('eCarra/images/warning-button-grey-small.png')
                            },
                            bottomBorder: false
                        })
                    )
                }
            </ScrollView>
        </View>
    )
}
export default List;
