import React, { useEffect, useRef, useState } from 'react';
import { Animated, Image, ScrollView, Text, View } from 'react-native';

import update from 'immutability-helper';

import API from 'eCarra/files/api.js';
import Appearance from 'eCarra/styles/Appearance';
import FeedbackStar from 'eCarra/files/lottie/feedback-star.json';
import LottieView from 'eCarra/views/Lottie/';
import Request from 'eCarra/files/Request/';
import Screen from 'eCarra/files/Screen.js';
import TextField from 'eCarra/views/TextField.js';
import TouchableHighlight from 'eCarra/views/TouchableHighlight/';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import Utils from 'eCarra/files/Utils.js';

const QuickFeedback = ({ buttons, icon, utils, message, onPress, onClose, textFields, title, showSurveyOption, abstract }) => {

    const [alertButtons, setAlertButtons] = useState([]);
    const [opacity, setOpacity] = useState(new Animated.Value(0));
    const ratingOptions = [1,2,3,4,5];
    const [scale, setScale] = useState(new Animated.Value(0));
    const [selectedRating, setSelectedRating] = useState(0);
    const [selectedStar, setSelectedStar] = useState(FeedbackStar);
    const [visible, setVisible] = useState(false);

    const onButtonPress = async key => {
        if(selectedRating > 0) {
            try {
                await Request.post(utils, '/rating/', {
                    type: 'new',
                    rating: selectedRating
                });
            } catch(e) {
                console.error(e.message);
            }
        }
        if(key === 'more') {
            try {
                let { url } = await Utils.encryptedURL(utils, {
                    category: 'standard_feedback',
                    user_id: utils.user.get().user_id,
                    theme_style: Appearance.themeStyle(),
                    ...abstract && Utils.apply(abstract.type, {
                        orders: () => {
                            return { order_id: abstract.getID() }
                        },
                        reservations: () => {
                            return { reservation_id: abstract.getID() }
                        }
                    })
                });
                utils.layer.webView({
                    id: `standard-feedback`,
                    url: url
                })

            } catch(e) {
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue preparing your feedback form. ${error}`
                });
            }
        }
        onHideAlert();
    }

    const onHideAlert = () => {
        Animated.spring(scale, {
            toValue: 0,
            duration: 150,
            useNativeDriver: false
        }).start();
        Animated.timing(opacity, {
            toValue: 0,
            duration: 150,
            useNativeDriver: false
        }).start();

        setTimeout(() => {
            setVisible(false);
            if(typeof(onClose) === 'function') {
                onClose();
            }
            if(typeof(onPress) === 'function') {
                onPress(key);
            }
        }, 250)
    }

    const getLottieSource = number => {
        if(number <= selectedRating) {
            return selectedStar;
        }
        return FeedbackStar;
    }

    const getStarComponent = number => {
        return (
            <TouchableOpacity
            key={number}
            activeOpacity={1}
            onPress={() => setSelectedRating(number)}>
                <LottieView
                loop={false}
                autoPlay={true}
                duration={1500}
                source={getLottieSource(number)}
                progress={number <= selectedRating ? null : 1}
                style={{
                    width: 40,
                    height: 40
                }}/>
            </TouchableOpacity>
        )
    }

    const setupButtons = () => {
        let targets = buttons || [{
            key: 'more',
            title: 'Tell Us More',
            style: 'default',
            visible: showSurveyOption !== false
        },{
            key: 'cancel',
            title: 'Done',
            style: 'cancel'
        }];
        setAlertButtons(targets);
    }

    const setupLottieSource = () => {
        try {
            let source = Utils.replaceLottieColor(FeedbackStar, '0.607843160629,0.607843160629,0.607843160629', Appearance.colors.primary());
            setSelectedStar(source);
        } catch(e) {
            console.error(e.message);
            setSelectedStar(FeedbackStar);
        }
    }

    useEffect(() => {
        setupButtons();
        setupLottieSource();
    }, []);

    useEffect(() => {
        setVisible(true);
        setTimeout(() => {
            Animated.spring(scale, {
                toValue: 1,
                duration: 500,
                useNativeDriver: false
            }).start();
            Animated.timing(opacity, {
                toValue: 1,
                duration: 250,
                useNativeDriver: false
            }).start();
        }, 300)
    }, [])

    return visible ? (
        <View style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            width: Screen.width(),
            height: Screen.height(),
            backgroundColor: Appearance.colors.transparent,
            zIndex: 9950
        }}>
            <Animated.View style={{
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                opacity: opacity,
                position: 'absolute',
                backgroundColor: Appearance.colors.dim
            }} />

            <Animated.View style={{
                flexDirection: 'column',
                borderRadius: 10,
                backgroundColor: Appearance.colors.alert(),
                overflow:'hidden',
                maxWidth: 400,
                maxHeight: Screen.height() - 30,
                width: Screen.width() - 60,
                alignItems: 'center',
                zIndex: 9999,
                opacity: opacity,
                transform: [{
                    scale: scale
                }]
            }}>
                {icon && (
                    <View style={{
                        marginTop: 20,
                        borderRadius: 25,
                        backgroundColor: icon.background_color,
                        overflow: 'hidden',
                        width: 50,
                        height: 50
                    }}>
                        <Image style={{
                            width: '100%',
                            height: '100%',
                            resizeMode: 'contain'
                        }}/>
                    </View>
                )}
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    paddingTop: icon ? 8:15,
                    paddingLeft: 15,
                    paddingRight: 15,
                    marginBottom: 8,
                    textAlign: 'center',
                }}>{title || 'How Are We Doing?'}</Text>

                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    width: '100%',
                    paddingLeft: 15,
                    paddingRight: 15,
                    textAlign: 'center',
                }}>{message || `How would you rate your overall experience with ${utils.client.get().name}?`}</Text>

                <View style={{
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    maxWidth: 225,
                    marginVertical: 12
                }}>
                    {ratingOptions.map(number => {
                        return getStarComponent(number);
                    })}
                </View>

                {alertButtons.filter(item => item.visible !== false).map(item => {
                    return (
                        <TouchableHighlight
                        key={item.key}
                        activeOpacity={0.9}
                        underlayColor={Appearance.colors.divider()}
                        onPress={onButtonPress.bind(this, item.key)}
                        style={{
                            height: 50,
                            width: '100%'
                        }}>
                            <View style={{ height: 50 }}>
                                <View style={{
                                    width: '100%',
                                    height: 1,
                                    backgroundColor: Appearance.colors.divider()
                                }}/>
                                <View style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 50
                                }}>
                                    <Text style={{
                                        fontSize: 16,
                                        ...Appearance.fontWeight.get(400),
                                        textAlign: 'center',
                                        color: (item.style == 'cancel' || item.style == 'destructive') ? Appearance.colors.text() : Appearance.colors.primary()
                                    }}>{item.title}</Text>
                                </View>
                            </View>
                        </TouchableHighlight>
                    )
                })}
            </Animated.View>
        </View>
    ) : null
}

export default QuickFeedback;
