import API from 'eCarra/files/api.js';
import OrderChannel from 'eCarra/classes/OrderChannel.js';

class ServiceClass {

    id = null;
    name = null;
    information = null;
    billing = null;
    disclaimer = null;
    preauthorize = null;
    payment_methods = [];
    options = [];
    requiredOptions = [];
    channel = null;
    orderChannel = null;
    defaultForChannel = false;
    active = null;

    delivery = null;
    bookForLater = {};
    shouldTipDriver = false;
    shouldBookReturnTrip = false;
    shouldPreauthorize = false;
    shouldDeclineOnPreauthFailure = false;

    // Fixed Cost
    fixedAmount = 0.0;

    // Restrictions
    maxDistance = null;
    minDistance = null;
    minBookingTime = null;
    maxRadius = null;
    radiusCenter = null;
    waitTime = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.id = parseInt(props.id);
        this.name = props.name;
        this.information = props.information;
        this.active = Boolean(props.active);
        this.channel = props.channel || 'standard';
        this.orderChannel = props.order_channel ? OrderChannel.create(props.order_channel) : null;
        this.defaultForChannel = props.default_for_channel ? Boolean(props.default_for_channel) : false;

        // Booking Options
        let parameters = props.parameters;
        if(!parameters) { return }

        this.delivery = parameters.delivery;

        let options = parameters.options;
        if(!options) { return }

        this.bookForLater = options.book_for_later;
        this.preauthorize = options.preauthorize;
        this.disclaimer = options.disclaimer;
        this.requiredOptions = options.required_items;
        this.shouldTipDriver = options.should_tip_driver || false;
        this.shouldBookReturnTrip = options.should_book_return_trip || false;
        this.shouldPreauthorize = options.should_preauthorize;

        // Options Type
        let optionsType = options.type;
        if(optionsType === 'all') {

            this.options = ['id', 'service', 'vehicle', 'origin', 'destination', 'pickup_date', 'passengers_and_luggage', 'payment', 'mood_and_music', 'flight_number', 'requests', 'promo_code'];

        } else if(optionsType === 'variable') {

            let items = options.items;
            if(!items) { return }
            this.options = items || [];
        }

        // Accepted Payment Methods
        let billing = parameters.billing;
        if(!billing) { return }

        let billingType = billing.type;
        if(!billingType) { return }

        this.payment_methods = billing.allowed || [];
        this.billing = billingType

        // Optional Restrictions
        if(parameters.restrictions) {

            let restrictions = parameters.restrictions;
            if(restrictions.min_distance) {
                this.minDistance = restrictions.min_distance;
            }
            if(restrictions.max_distance) {
                this.maxDistance = restrictions.max_distance;
            }
            if(restrictions.min_booking_time) {
                this.minBookingTime = restrictions.min_booking_time;
            }

            if(restrictions.radius) {
                let center = restrictions.radius.center;
                this.radiusCenter = center && center.lat && center.long ? {
                    latitude: center.lat,
                    longitude: center.long
                } : null;
            }

            if(restrictions.wait_time) {
                this.waitTime = restrictions.wait_time
            }

            if(billingType === 'fixed') {
                if(billing.amount) {
                    this.fixedAmount = billing.amount;
                }
            }
        }
        return this;
    }

    optionText = type => {
        switch(type) {
            case 'id':
            return 'Reservation ID';

            case 'service':
            return 'Service';

            case 'vehicle':
            return 'Vehicle';

            case 'pickup_date':
            return 'Pickup Time and Date';

            case 'origin':
            return 'Pickup Location';

            case 'destination':
            return 'Drop-Off Location';

            case 'passengers_and_luggage':
            return 'Passengers and Luggage';

            case 'payment':
            return 'Payment Method';

            case 'mood_and_music':
            return 'Mood and Music';

            case 'flight_number':
            return 'Flight Number';

            case 'requests':
            return 'Special Requests';

            case 'promo_code':
            return 'Promo Code';

            case 'driver_tip':
            return 'Driver Tip';

            default:
            return type || 'Unknown Booking Option';
        }
    }
}

export default {
    create: props => new ServiceClass().create(props)
};
