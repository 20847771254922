import React, { useRef, useState, useEffect } from 'react';
import { Animated, Image, Keyboard, ScrollView, Text, View } from 'react-native';

import moment from 'moment';
import update from 'immutability-helper';

import API from 'eCarra/files/api.js';
import Appearance from 'eCarra/styles/Appearance.js';
import { DotsLoader } from 'eCarra/views/Loader.js';
import KeyboardManager from 'eCarra/files/KeyboardManager.js';
import Layer, { LayerShell, LayerToolbar } from 'eCarra/structure/Layer.js';
import LottieView from 'eCarra/views/Lottie/';
import Request from 'eCarra/files/Request/';
import Screen from 'eCarra/files/Screen.js';
import SoundPlayer from 'react-native-sound-player';
import TextField from 'eCarra/views/TextField.js';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import Utils from 'eCarra/files/Utils.js';
import Views from 'eCarra/views/Main.js';

export const getAssetDetails = (utils, category, asset) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { song } = await Request.get(utils, '/music/', {
                type: 'asset_details',
                category: category,
                asset_id: asset.id,
                asset_type: asset.type
            });
            resolve(songs);
        } catch(e) {
            reject(e);
        }
    })
}

export const PlaylistSong = ({ selected, song, onPlaylistToggle, utils }) => {

    const playlistButton = useRef(null);

    const onPlayPreview = (song) => {

        utils.layer.open({
            id: `music-media-player-${song.id}`,
            Component: MusicMediaPlayer.bind(this, {
                song: song
            })
        })
    }

    const onSongPress = () => {
        utils.sheet.show({
            items: [{
                key: 'play',
                title: 'Preview Song',
                style: 'default',
                visible: song.preview ? true : false
            },{
                key: 'add',
                title: 'Add to Playlist',
                style: 'default',
                visible: !selected
            },{
                key: 'remove',
                title: 'Remove from Playlist',
                style: 'destructive',
                visible: selected
            }]
        }, (key) => {

            if(key === 'play') {
                onPlayPreview(song);

            } else if(key === 'add' || key === 'remove') {
                if(typeof(onPlaylistToggle) === 'function') {
                    onPlaylistToggle(song);
                }
            }
        })
    }

    useEffect(() => {
        if(playlistButton.current) {
            playlistButton.current.play(selected ? 0 : 50, selected ? 50 : 90)
        }
    }, [selected, playlistButton]);

    return (
        Views.entry({
            title: song.name,
            subTitle: song.artist_name,
            icon: {
                path: { uri: song.artwork.url },
                style: {
                    borderRadius: 5
                }
            },
            onPress: onSongPress,
            rightContent: (
                <TouchableOpacity
                ativeOpacity={0.6}
                onPress={onPlaylistToggle.bind(this, song)}>
                    <LottieView
                    ref={playlistButton}
                    autoPlay={false}
                    loop={false}
                    source={require('eCarra/files/lottie/playlist-toggle-light.json')}
                    duration={1500}
                    style={{
                        width: 25,
                        height: 25
                    }}/>
                </TouchableOpacity>
            )
        })
    )
}

// Layers
export const MusicPicker = ({ category, prevSongs, onChange }, { index, options, utils }) => {

    const layerID = 'music-picker';
    const [albums, setAlbums] = useState([]);
    const [artists, setArtists] = useState([]);
    const [songs, setSongs] = useState([]);
    const [searchText, setSearchText] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const [playlist, setPlaylist] = useState(prevSongs || []);
    const [addedAlbums, setAddedAlbums] = useState([]);

    const search = () => {
        switch(category) {
            case 'apple_music':
                searchAppleMusic();
                break;
        }
    }

    const getCharts = () => {
        switch(category) {
            case 'apple_music':
                getAppleMusicCharts();
                break;
        }
    }

    const searchAppleMusic = async () => {
        if(!searchText) {
            getCharts();
            return;
        }
        try {
            let { songs, albums, artists } = await Request.get(utils, '/music/', {
                type: 'search',
                category: category,
                search_text: searchText
            });
            setLoading(false);
            setSongs(songs || []);
            setAlbums(albums || []);
            setArtists(artists || []);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue completing your search. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getAppleMusicCharts = async () => {
        try {
            let { songs, albums, artists } = await Request.get(utils, '/music/', {
                type: 'charts',
                category: category
            });
            setLoading(false);
            setSongs(songs || []);
            setAlbums(albums || []);
            setArtists(artists || []);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue gathering popular music options. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onPlaylistPress = () => {

        utils.layer.open({
            id: 'music-playlist-details',
            Component: MusicPlaylistDetails.bind(this, {
                playlist: playlist,
                onPlaylistToggle: onPlaylistToggle
            })
        })
    }

    const onAlbumPress = (album) => {
        utils.sheet.show({
            items: [{
                key: 'view',
                title: 'View Album',
                style: 'default'
            }, {
                key: 'add',
                title: 'Add to Playlist',
                style: 'default',
                visible: !addedAlbums.includes(album.id)
            }, {
                key: 'remove',
                title: 'Remove from Playlist',
                style: 'destructive',
                visible: addedAlbums.includes(album.id)
            }]
        }, async key => {

            if(key === 'cancel') {
                return;
            }

            if(key === 'view') {
                utils.layer.open({
                    id: `music-album-details-${album.id}`,
                    Component: MusicAlbumDetails.bind(this, {
                        category: category,
                        album: album,
                        playlist: playlist,
                        onPlaylistToggle: onPlaylistToggle
                    })
                })

            }

            if(key === 'add' || key === 'remove') {
                try {
                    let songs = await getAssetDetails(utils, category, album);
                    if(key === 'add') {
                        setAddedAlbums(update(addedAlbums, {
                            $push: [album.id]
                        }))
                        setPlaylist(update(playlist, {
                            $push: songs || []
                        }))
                    }
                    if(key === 'remove') {
                        setAddedAlbums(update(addedAlbums, {
                            $apply: albums => albums.filter(id => id !== album.id)
                        }))
                        setPlaylist(update(playlist, {
                            $apply: playlist => playlist.filter(item => !songs.find(song => song.id === item.id))
                        }))
                    }
                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue fetching this asset. ${e.message || 'An unknown error occurred'}`
                    })
                }
            }
        })
    }

    const onPlaylistToggle = (song) => {
        if(playlist.find(item => item.id === song.id)) {
            setPlaylist(playlist => update(playlist, {
                $apply: playlist => playlist.filter(item => item.id !== song.id)
            }))
            return;
        }
        setPlaylist(playlist => update(playlist, {
            $push: [song]
        }))
    }

    useEffect(() => {
        if(!searchText) {
            getCharts();
            return;
        }
        search();
    }, [searchText]);

    useEffect(() => {
        utils.keyboard.subscribe(layerID, {
            onVisibility: visible => setKeyboardOpen(visible)
        });

        return () => {
            utils.keyboard.unsubscribe(layerID);
        }
    })

    return (
        <Layer
            id={layerID}
            title={'Music'}
            utils={utils}
            index={index}
            options={{
                ...options,
                layerState: layerState,
                removePadding: true,
                onScroll: () => Keyboard.dismiss()
            }}
            buttons={[{
                key: 'done',
                text: 'Done',
                color: 'primary',
                loading: loading,
                onPress: () => {
                    Keyboard.dismiss();
                    if(typeof(onChange) === 'function') {
                        setTimeout(() => {
                            setLayerState('close')
                            onChange({
                                music: {
                                    category: category,
                                    songs: playlist.map(item => item.id)
                                }
                            });
                        }, keyboardOpen ? 750 : 0);
                    }
                }
            }]}>
            <View style={{
                marginTop: 22,
                borderBottomColor: Appearance.colors.divider(),
                borderBottomWidth: 1,
                padding: 12
            }}>
                <TextField
                icon={'search'}
                useDelay={true}
                placeholder={'Search by artist, album, or song name...'}
                onChange={text => setSearchText(text)} />
            </View>

            {playlist && playlist.length > 0
                ?
                <View style={{
                    padding: 12,
                    borderBottomColor: Appearance.colors.divider(),
                    borderBottomWidth: 1
                }}>
                    <View style={Appearance.styles.panel()}>
                        {Views.entry({
                            title: 'My Playlist',
                            subTitle: `${playlist.length} ${playlist.length === 1 ? 'Song' : 'Songs'}`,
                            icon: {
                                path: { uri: playlist[0].artwork.url },
                                style: {
                                    borderRadius: 5
                                }
                            },
                            bottomBorder: false,
                            onPress: onPlaylistPress,
                            rightContent: (
                                <Image source={require('eCarra/images/next-arrow-small.png')} style={{
                                    width: 12,
                                    height: 12,
                                    resizeMode: 'contain',
                                    tintColor: Appearance.colors.subText(),
                                    marginLeft: 12
                                }}/>
                            )
                        })}
                    </View>
                </View>
                :
                null
            }

            {albums && albums.length > 0
                ?
                <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                style={{
                    width: '100%'
                }}
                contentContainerStyle={{
                    padding: 12
                }}>
                    {albums.map((album, index) => {
                        return (
                            <TouchableOpacity
                            key={index}
                            activeOpacity={0.6}
                            onPress={onAlbumPress.bind(this, album)}
                            style={{
                                marginRight: index !== albums.length - 1 ? 12 : 0,
                                maxWidth: 256,
                                width: Screen.width() / 2.5,
                            }}>
                                <Image source={{ uri: album.artwork.url }} style={{
                                    maxWidth: 256,
                                    height: 256,
                                    width: Screen.width() / 2.5,
                                    height: Screen.width() / 2.5,
                                    borderRadius: 5,
                                    overflow: 'hidden',
                                    resizeMode: 'cover',
                                    marginBottom: 8
                                }}/>
                                <Text numberOfLines={1}
                                style={{
                                    ...Appearance.textStyles.title(),
                                    marginBottom: 2
                                }}>{album.name}</Text>
                                <Text numberOfLines={1}
                                style={Appearance.textStyles.subTitle()}>{album.artist_name}</Text>
                            </TouchableOpacity>
                        )
                    })}
                </ScrollView>
                :
                null
            }

            {songs && songs.length > 0
                ?
                <>
                <View style={{
                    backgroundColor: Appearance.headerBackgroundColor(),
                    paddingVertical: 8,
                    paddingHorizontal: 12
                }}>
                    <Text style={{
                        fontSize: 12,
                        ...Appearance.fontWeight.get(600),
                        color: Appearance.colors.text()
                    }}>{(searchText ? 'Songs' : 'Popular Songs').toUpperCase()}</Text>
                </View>
                {songs.map((song, index) => {
                    return (
                        <PlaylistSong
                        key={index}
                        song={song}
                        utils={utils}
                        selected={playlist.find(item => item.id === song.id) ? true : false}
                        onPlaylistToggle={onPlaylistToggle} />
                    )
                })}
                </>
                :
                null
            }
        </Layer>
    )
}

export const MusicMediaPlayer = ({ song }, { index, options, utils }) => {

    const layerID = `music-media-player-${song.id}`;
    const [layerState, setLayerState] = useState(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [remainingTime, setRemainingTime] = useState(30);

    const getCurrentTime = () => {
        SoundPlayer.getInfo().then(({ duration, currentTime }) => {
            setCurrentTime(currentTime);
            setRemainingTime(duration - currentTime);
        }).catch(e => {
            console.error(e.message);
        })
    }

    const getPlaybackPosition = () => {
        return `${(100 / 30) * currentTime}%`;
    }

    useEffect(() => {

        onFinishedPlayingSubscription = SoundPlayer.addEventListener('FinishedPlaying', ({ success }) => {
            setLayerState('close');
        })
        onFinishedLoadingSubscription = SoundPlayer.addEventListener('FinishedLoading', ({ success }) => {
            // Required or else a warning is thrown for not implementing event listener
        })
        onFinishedLoadingURLSubscription = SoundPlayer.addEventListener('FinishedLoadingURL', ({ success, url }) => {
            // Required or else a warning is thrown for not implementing event listener
        })

        let interval = setInterval(getCurrentTime, 250);
        try {
            SoundPlayer.playUrl(song.preview)
        } catch(e) {
            clearInterval(interval);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue playing the preview for this song. ${e.message || 'An unknown error occurred'}`
            })
        }

        return () => {

            SoundPlayer.stop();
            clearInterval(interval);

            onFinishedPlayingSubscription.remove();
            onFinishedLoadingSubscription.remove();
            onFinishedLoadingURLSubscription.remove();
        }
    }, [])

    return (
        <Layer
            id={layerID}
            title={'Music Player'}
            utils={utils}
            index={index}
            options={{
                ...options,
                layerState: layerState,
                bottomCard: true
            }}>
            <View style={{
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Image source={{ uri: song.artwork.url }} style={{
                    maxWidth: 256,
                    height: 256,
                    width: Screen.width() / 2.5,
                    height: Screen.width() / 2.5,
                    resizeMode: 'cover',
                    borderRadius: 5,
                    overflow: 'hidden',
                    marginBottom: 20
                }} />
                <Text style={{
                    marginBottom: 2,
                    ...Appearance.textStyles.title()
                }}>{song.name}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 12
                }}>{`${song.artist_name} - ${song.album_name}`}</Text>
            </View>

            <View style={{
                position: 'relative',
                width: '100%'
            }}>
                <View style={{
                    width: '100%',
                    height: 5,
                    borderRadius: 2.5,
                    backgroundColor: Appearance.colors.divider(),
                    overflow: 'hidden',
                    marginBottom: 2,
                    alignItems: 'center'
                }} />
                <View style={{
                    position: 'absolute',
                    width: 7,
                    height: 7,
                    borderRadius: 3.5,
                    backgroundColor: Appearance.colors.grey(),
                    overflow: 'hidden',
                    top: -1,
                    left: getPlaybackPosition()
                }} />
            </View>
            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%'
            }}>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    fontSize: 10
                }}>{Utils.timecode(currentTime)}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    fontSize: 10
                }}>{`-${Utils.timecode(remainingTime)}`}</Text>
            </View>

        </Layer>
    )
}

export const MusicAlbumDetails = ({ category, playlist, album, onPlaylistToggle }, { index, options, utils }) => {

    const layerID = `music-album-details-${album.id}`;
    const [songs, setSongs] = useState([]);
    const [_playlist, _setPlaylist] = useState(playlist);
    const [layerState, setLayerState] = useState(null);

    // Require for consistency inside of layer
    const _onPlaylistToggle = (song) => {

        if(typeof(onPlaylistToggle) === 'function') {
            onPlaylistToggle(song);
        }

        if(_playlist.find(item => item.id === song.id)) {
            _setPlaylist(playlist => update(playlist, {
                $apply: playlist => playlist.filter(item => item.id !== song.id)
            }))
            return;
        }
        _setPlaylist(playlist => update(playlist, {
            $push: [song]
        }))
    }

    const fetchSongs = async () => {
        try {
            let songs = await getAssetDetails(utils, category, album);
            setSongs(songs);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue fetching this asset. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchSongs();
    }, [])

    return (
        <Layer
        id={layerID}
        title={'Music'}
        utils={utils}
        index={index}
        options={{
            ...options,
            layerState: layerState,
            removePadding: true
        }}>
            <View style={{
                marginTop: 22,
                borderBottomColor: Appearance.colors.divider(),
                borderBottomWidth: 1,
                padding: 12,
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Image source={{ uri: album.artwork.url }} style={{
                    maxWidth: 256,
                    height: 256,
                    width: Screen.width() / 2.5,
                    height: Screen.width() / 2.5,
                    resizeMode: 'cover',
                    borderRadius: 5,
                    overflow: 'hidden',
                    marginBottom: 20
                }} />
                <Text style={{
                    marginBottom: 2,
                    ...Appearance.textStyles.title()
                }}>{album.name}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 12
                }}>{album.artist_name}</Text>

            </View>

            {songs && songs.length > 0
                ?
                songs.map((song, index) => {
                    return (
                        <PlaylistSong
                        key={index}
                        song={song}
                        utils={utils}
                        selected={_playlist.find(item => item.id === song.id) ? true : false}
                        onPlaylistToggle={_onPlaylistToggle} />
                    )
                })
                :
                null
            }

        </Layer>
    )
}

export const MusicPlaylistDetails = ({ playlist, onPlaylistToggle }, { index, options, utils }) => {

    const layerID = 'music-playlist-details';
    const [_playlist, _setPlaylist] = useState(playlist);
    const [layerState, setLayerState] = useState(null);

    // Require for consistency inside of layer
    const _onPlaylistToggle = (song) => {

        if(typeof(onPlaylistToggle) === 'function') {
            onPlaylistToggle(song);
        }

        if(_playlist.find(item => item.id === song.id)) {
            _setPlaylist(playlist => update(playlist, {
                $apply: playlist => playlist.filter(item => item.id !== song.id)
            }))
            return;
        }
        _setPlaylist(playlist => update(playlist, {
            $push: [song]
        }))
    }

    return (
        <Layer
            id={layerID}
            title={'Music Playlist'}
            utils={utils}
            index={index}
            options={{
                ...options,
                layerState: layerState,
                removePadding: true
            }}>
            <View style={{
                marginTop: 22,
                borderBottomColor: Appearance.colors.divider(),
                borderBottomWidth: 1,
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                style={{
                    width: '100%'
                }}
                contentContainerStyle={{
                    paddingTop: 12
                }}>
                    <View style={{
                        minWidth: '100%',
                        flexDirection: 'row',
                        justifyContent: 'center'
                    }}>
                        {_playlist.reduce((array, item) => {
                            if(!array.includes(item.artwork.url)) {
                                array.push(item.artwork.url);
                            }
                            return array;
                        }, []).map((url, index, images) => {
                            return (
                                <Image
                                key={index}
                                source={{ uri: url }}
                                style={{
                                    maxWidth: 256,
                                    height: 256,
                                    width: Screen.width() / 2.5,
                                    height: Screen.width() / 2.5,
                                    resizeMode: 'cover',
                                    borderRadius: 5,
                                    overflow: 'hidden',
                                    marginLeft: images.length === 1 ? 0 : (index === 0 ? 12 : 4),
                                    marginRight: images.length === 1 ? 0 : (index !== images.length - 1 ? 4 : 12)
                                }} />
                            )
                        })}
                    </View>
                </ScrollView>
                <View style={{
                    padding: 12
                }}>
                    <Text style={{
                        textAlign: 'center',
                        marginBottom: 2,
                        ...Appearance.textStyles.title()
                    }}>{`${_playlist.length} ${_playlist.length === 1 ? 'Song' : 'Songs'}`}</Text>
                    <Text style={{
                        ...Appearance.textStyles.subTitle(),
                        textAlign: 'center'
                    }}>{_playlist.reduce((array, item) => {
                        if(!array.includes(item.artist_name)) {
                            array.push(item.artist_name);
                        }
                        return array;
                    }, []).join(', ')}</Text>
                </View>
            </View>

            {_playlist && _playlist.length > 0
                ?
                _playlist.map((song, index) => {
                    return (
                        <PlaylistSong
                        key={index}
                        song={song}
                        utils={utils}
                        selected={_playlist.find(item => item.id === song.id) ? true : false}
                        onPlaylistToggle={_onPlaylistToggle} />
                    )
                })
                :
                null
            }

        </Layer>
    )
}
