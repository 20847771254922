import React, { useRef, useState, useEffect } from 'react';
import { Animated, Image, Platform, Text, View } from 'react-native';

import moment from 'moment';
import update from 'immutability-helper';

import API from 'eCarra/files/api.js';
import Appearance from 'eCarra/styles/Appearance.js';
import Button from 'eCarra/views/Button.js';
import Notification from 'eCarra/classes/Notification.js';
import Panel from 'eCarra/structure/Panel.js';
import PushNotificationIOS from '@react-native-community/push-notification-ios';
import Request from 'eCarra/files/Request/';
import Screen from 'eCarra/files/Screen.js';
import TouchableHighlight from 'eCarra/views/TouchableHighlight/';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import Utils from 'eCarra/files/Utils.js';
import Views from 'eCarra/views/Main.js';

export const NotificationsList = ({ today }, { utils }) => {

    const panelID = `${today ? 'today' : 'past'}Notifications`;
    const [loading, setLoading] = useState(true);
    const [notifications, setNotifications] = useState([]);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [editing, setEditing] = useState(false);

    const onNotificationPress = (notification) => {
        notification.contentOptions(utils, 'alert');
    }

    const onEditingSelect = (notification, index, i) => {
        setNotifications(update(notifications, {
            [index]: {
                notifications: {
                    [i]: {
                        selected: {
                            $set: notification.selected ? false : true
                        }
                    }
                }
            }
        }))
    }

    const getContent = () => {
        if(notifications.length === 0) {
            return (
                <View style={Appearance.styles.panel()}>
                    {Views.entry({
                        title: 'Nothing to see here',
                        subTitle: 'Your account has not received any notifications',
                        hideIcon: true,
                        bottomBorder: false
                    })}
                </View>
            )
        }
        return notifications.map((entry, index) => {
            return (
                <View
                key={index}
                style={{
                    marginTop: index === 0 ? 0 : 20,
                    marginBottom: 8
                }}>
                    <Text style={{
                        ...Appearance.textStyles.panelTitle(),
                        marginBottom: 8
                    }}>{moment().isSame(entry.date, 'day') ? moment(entry.date).format('[Today,] MMMM Do') : (moment().isSame(entry.date, 'year') ? moment(entry.date).format('MMMM Do') : moment(entry.date).format('MMMM Do, YYYY'))}</Text>

                    <View style={Appearance.styles.panel()}>
                        {entry.notifications.map((n, i) => {
                            return (
                                Views.entry({
                                    key: i,
                                    title: n.title,
                                    subTitle: n.message,
                                    icon: {
                                        path: n.from_user ? n.from_user.avatar : n.getIcon()
                                    },
                                    badge: {
                                        text: moment(n.date).format('h:mma'),
                                        color: Appearance.colors.grey()
                                    },
                                    propStyles: {
                                        subTitle: {
                                            numberOfLines: 2
                                        }
                                    },
                                    editing: {
                                        visible: editing,
                                        selected: n.selected,
                                        onPress: onEditingSelect.bind(this, n, index, i)
                                    },
                                    bottomBorder: i !== entry.notifications.length - 1,
                                    onPress: onNotificationPress.bind(this, n)
                                })
                            )
                        })}
                    </View>
                </View>
            )
        })
    }

    const fetchNotifications = async () => {
        try {
            let { entries } = await Request.get(utils, '/user/', {
                type: 'notifications',
                date: today ? moment().format('YYYY-MM-DD') : null,
                exclude_date: today ? null : moment().format('YYYY-MM-DD')
            })
            if(Platform.OS === 'ios') {
                PushNotificationIOS.setApplicationIconBadgeNumber(0);
            }

            setLoading(false);
            setNotifications(entries.map(entry => ({
                date: entry.date,
                notifications: entry.notifications.map(notification => Notification.create(notification))
            })));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your notifications. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {

        fetchNotifications();
        utils.structure.navigation.set({
            onRightPress: () => {
                utils.sheet.show({
                    items: [{
                        key: 'edit',
                        title: 'Edit Notifications',
                        style: 'default',
                        visible: false // hidden until decision is made on how to handle this feature
                    },{
                        key: 'settings',
                        title: 'Notification Settings',
                        style: 'default'
                    }]
                }, (key) => {
                    if(key === 'edit') {
                        setEditing(true);
                    } else if(key === 'settings') {
                        utils.structure.workspace.set('settings');
                    }
                })
            }
        })

    }, [offset]);

    useEffect(() => {

        utils.content.subscribe(panelID, 'notifications', {
            onFetch: fetchNotifications,
            onRemove: abstract => {
                setNotifications(notifications => update(notifications, {
                    $apply: notifications => notifications.filter(section => {
                        section.notifications = section.notifications.filter(n => n.id !== abstract.getID())
                        return section;
                    })
                }))
            },
            onUpdate: abstract => {
                let index = (notifications || []).findIndex(n => n.id === abstract.getID());
                if(index >= 0) {
                    setNotifications(notifications => update(notifications, {
                        [index]: {
                            $set: abstract.object
                        }
                    }))
                }
            }
        })

        return () => {
            utils.content.unsubscribe(panelID);
            utils.structure.navigation.unsubscribe(panelID);
        }
    }, [notifications]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        options={{
            loading: loading,
            removeOverflow: true,
            shouldStyle: false
        }}>
            {getContent()}
        </Panel>
    )
}
