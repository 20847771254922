class AbstractClass {

    type = null;
    object = null;

    constructor(props) {
        if(props) {
            this.create(props);
        }
        return this;
    }

    create = (props = {}) => {
        this.type = props.type;
        this.object = props.object;
        return this;
    }

    compare = (target, callback) => {

        // compare two astract objects if applicable
        if(typeof(target.getID) === 'function') {
            if(this.getTag() === target.getTag()) {
                if(typeof(callback) === 'function') {
                    callback();
                }
                return this.object;
            }
            return target.object;
        }

        // compare and abstract object and a standard javascript object
        if(this.getID() !== target.id) {
            return target;
        }
        if(typeof(callback) === 'function') {
            callback();
        }
        return this.object;
    }

    getID = () => {
        if(!this.object) {
            return null;
        }
        return this.type === 'users' ? this.object.user_id : (this.object.id || this.object.tmpID);
    }

    getTag = () => {
        return `${this.type}-${this.getID()}`;
    }

    getType = () => this.type;

    getTitle = () => {

        switch(this.type) {
            case 'companies':
            return this.object.name || 'this company';

            case 'payments':
            return `Payment #${this.object.id} for ${this.object.customer ? this.object.customer.full_name : 'Company'}`;

            case 'users':
            return this.object.full_name || 'this user';

            case 'saas':
            return this.object.name || 'this account';

            case 'reservations':
            return `Reservation #${this.object.id}`;

            case 'routes':
            return this.object.name ? ('"' + this.object.name + '"' + ' Route') : ('Route #' + this.object.id)

            case 'routeCategories':
            return this.object.name;

            case 'routeOptions':
            return this.object.name;

            case 'vehicles':
            return `${this.object.name} #${this.object.id}`;

            case 'vehicleCategories':
            return this.object.name;

            case 'services':
            return this.object.name;

            case 'promo_codes':
            return this.object.title;

            case 'messages':
            return this.object.text;

            case 'notifications':
            return this.object.title;

            case 'campaigns':
            return this.object.title;

            case 'credits':
            return (this.object.type === 'company' && this.object.company ? this.object.company.name : (this.object.user ? this.object.user.full_name : 'Customer')) + '\'s Credits Card';

            case 'ichMethod':
            return this.object.summary();

            case 'feedback':
            return 'Feedback from ' + (this.object.user ? this.object.user.full_name : 'an Anonymous User');

            case 'surveys':
            return this.object.title;

            case 'surveyQuestions':
            return this.object.title;

            case 'restrictedDateTimes':
            return this.object.title;

            case 'moods':
            return this.object.title;

            case 'emails':
            return this.object.title;

            case 'supportTickets':
            return this.object.title;

            case 'orders':
            return this.object.id ? `Order #${this.object.id}` : 'New Order';

            case 'orderHosts':
            return this.object.name;

            case 'orderCategories':
            return this.object.title;

            case 'orderOptions':
            return this.object.name;

            case 'orderOptionItems':
            return this.object.title;

            case 'subscriptions':
            return this.object.plan.name;

            case 'voApplications':
            return `Application #VO${this.object.id}`;

            case 'doApplications':
            return `Application #DO${this.object.id}`;

            default:
            return 'Unknown Object';
        }
    }
}
export default {
    create: props => new AbstractClass().create(props)
};

export const buildTag = (props = {}) => {
    let so = new AbstractClass().create();
    so.type = props.type;
    so.object = props.object;
    if(!so.object) {
        so.object = {
            ...props.type !== 'users' && {
                id: props.id
            },
            ...props.type === 'users' && {
                user_id: props.id
            },
        }
    }
    return so.getTag();
}
