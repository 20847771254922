import React from 'react';
import moment from 'moment';

import API from 'eCarra/files/api.js';
import Abstract from 'eCarra/classes/Abstract.js';
import Appearance from 'eCarra/styles/Appearance.js';
import Company from 'eCarra/classes/Company';
import Driver from 'eCarra/classes/Driver.js';
import Message from 'eCarra/classes/Message.js';
import Note from 'eCarra/classes/Note.js';
import OrderHost from 'eCarra/classes/OrderHost.js';
import OrderOption from 'eCarra/classes/OrderOption.js';
import OrderChannel from 'eCarra/classes/OrderChannel.js';
import PromoCode from 'eCarra/classes/PromoCode.js';
import Request from 'eCarra/files/Request/';
import Service from 'eCarra/classes/Service.js';
import StatusCodes from 'eCarra/files/StatusCodes.js';
import Subscription from 'eCarra/classes/Subscription.js';
import User from 'eCarra/classes/User.js';
import Utils from 'eCarra/files/Utils.js';
import Valhalla from 'eCarra/classes/Valhalla.js';

class OrderClass {

    id = null;
    user_id = null;
    other_users = null;
    customer = null;
    company = null;
    service = null;
    host = null;
    origin = null;
    stops = null;
    destination = null;
    options = [];
    drop_off_date = null;
    date_submitted = null;
    options = null;
    status = null;
    data = null;
    driver = null;
    requests = null;
    driver_tip = null;
    promo_code = null;
    messages = null;
    emissions = null;
    cost_line_items = null;
    subscription = null;
    polyline = null;

    overlays = [];
    distance = {
        real: null,
        estimate: null
    };
    duration = {
        real: null,
        estimate: null
    };
    seeds = {};
    valhalla = {};

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.id = props.id;
        this.user_id = props.user_id;
        this.other_users = props.other_users ? props.other_users.map(u => User.create(u)) : null;
        this.customer = props.customer ? User.create(props.customer) : null;
        this.company = props.company ? Company.create(props.company) : null;
        this.channel = props.channel ? OrderChannel.create(props.channel) : null;
        this.host = props.host ? OrderHost.create(props.host) : null;
        this.service = props.service ? Service.create(props.service) : null;
        this.options = props.options;
        this.polyline = props.polyline && Utils.decodePolyline(props.polyline, 6);
        this.multi_leg_polyline = props.multi_leg_polyline && props.multi_leg_polyline.map(shape => Utils.decodePolyline(shape, 6));
        this.distance = props.distance;
        this.duration = props.duration;
        this.options = props.options ? props.options.map(o => OrderOption.create(o)) : [];
        this.drop_off_date = props.drop_off_date ? moment(props.drop_off_date) : null;
        this.date_submitted = props.date_submitted ? moment(props.date_submitted) : null;
        this.status = getStatus(props.status);
        this.promo_code = props.promo_code ? PromoCode.create(props.promo_code) : null;
        this.subscription = props.subscription ? Subscription.create(props.subscription) : null;
        this.driver = props.driver ? Driver.create(props.driver) : null;
        this.driver_tip = props.driver_tip;
        this.requests = props.requests;
        this.emissions = props.emissions;
        this.cost_line_items = props.cost;
        this.data = props.data;
        this.credits = props.credits;

        this.origin = {
            name: props.origin.name,
            address: props.origin.address,
            location: {
                latitude: props.origin.lat,
                longitude: props.origin.long
            }
        };
    	this.destination = {
            name: props.destination.name,
            address: props.destination.address,
            location: props.destination.lat && props.destination.long && {
                latitude: props.destination.lat,
                longitude: props.destination.long
            }
        };

        this.stops = props.stops && {
            ...props.stops,
            locations: props.stops.locations && props.stops.locations.map(stop => ({
                id: stop.id,
                name: stop.name,
                address: stop.address,
                location: {
                    latitude: stop.lat,
                    longitude: stop.long
                }
            }))
        };
        return this;
    }

    getOverlays = () => {
        try {
            if(this.polyline) {
                return [{
                    id: 'order-route',
                    coordinates: this.polyline
                }];
            }
            if(this.multi_leg_polyline) {
                return this.multi_leg_polyline.map((shape, index) => ({
                    id: `order-route-${index}`,
                    coordinates: shape
                }))
            }
            return null;

        } catch(e) {
            console.error(e.message);
        }
    }

    getLocations = () => {
        let order = this.edits || this;
        let locations = [{
            id: 'origin',
            ...order.origin
        }];
        if(order.stops && order.stops.locations && order.stops.locations.length > 0) {
            order.stops.locations.forEach((stop, index) => {
                locations.push({
                    ...stop,
                    icon: { type: 'grey-broadcast' }
                })
            });
        }
        locations.push({
            id: 'destination',
            ...order.destination
        });
        return locations;
    }

    setStopAsCompleted = async (utils, index) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id } = await Request.post(utils, '/order/', {
                    type: 'set_stop_as_completed',
                    order_id: this.id,
                    stop_id: this.stops.locations[index].id
                });
                this.stops.locations[index].completed = id;
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    getNavigationLocations = () => {

        let locations = [];
        if(this.stops && this.stops.locations && this.stops.locations.length > 0) {
            locations = locations.concat(this.stops.locations.filter(stop => {
                return stop.completed ? false : true;
            }).reduce((array, stop, index) => array.concat({
                key: `stop-${index}`,
                ...stop
            }), []))
        }

        if(this.destination && this.destination.address) {
            locations.push({
                key: 'destination',
                ...this.destination
            })
        }

        return locations;
    }

    isOnDemandOrder = () => this.requests && this.requests.on_demand === true;

    currentPaymentMethod = () => {
        if(this.customer.payment_methods) {

            let cardID = this.requests ? this.requests.card_id : null;
            for(var i in this.customer.payment_methods) {
                if((cardID && this.customer.payment_methods[i].id === cardID) || (!cardID && this.customer.payment_methods[i].default)) {
                    return this.customer.payment_methods[i];
                }
            }
         }
        return null;
    }

    translatePaymentMethod = async (utils, callback) => {

        try {
            let order = this.edits || this;
            const findMatch = ({ methods }) => {
                let m = methods || [];
                let cardID = order.requests ? order.requests.card_id : null;
                callback(cardID ? m.find(method => method.id === cardID) : m.find(method => method.default))
            }

            if(!order.customer.payment_methods || order.customer.payment_methods.length === 0) {
                await order.customer.getPaymentMethods(utils, findMatch);
                return;
            }
            findMatch({ methods: order.customer.payment_methods });

        } catch(e) {
            console.error(e.message);
        }
    }

    costBreakdown = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let order = this.edits || this;
                let { calculations, multi_leg_polyline, polyline, route } = await Request.post(utils, '/order/', {
                    type: 'cost',
                    order_id: this.id,
                    company: order.company ? order.company.id : null,
                    service: order.service ? order.service.id : null,
                    promo_code: order.promo_code ? order.promo_code.id : null,
                    driver_tip: order.driver_tip ? order.driver_tip.amount : null,
                    credits: order.credits,
                    driver_tip: order.driver_tip,
                    options: order.options ? order.options.map(opt => OrderOption.toJSON(opt)) : [],
                    subscription: order.subscription ? order.subscription.id : null,
                    origin: order.origin && order.origin.location && {
                        lat: order.origin.location.latitude,
                        long: order.origin.location.longitude
                    },
                    destination: order.destination && order.destination.location && {
                        lat: order.destination.location.latitude,
                        long: order.destination.location.longitude
                    },
                    stops: order.stops && order.stops.locations && {
                        ...order.stops,
                        locations: order.stops.locations.map(stop => ({
                            id: stop.id,
                            name: stop.name,
                            address: stop.address,
                            lat: stop.lat || stop.location.latitude,
                            long: stop.long || stop.location.longitude
                        }))
                    },
                    ...props
                })

                // update route variables if applicable
                if(route) {
                    this.route = route;
                    order.distance = {
                        ...order.distance,
                        estimate: route.distance
                    };
                    order.duration = {
                        ...order.duration,
                        estimate: route.duration
                    };
                }

                // update polylines if applicable
                this.polyline = polyline && Utils.decodePolyline(polyline, 6);
                this.multi_leg_polyline = multi_leg_polyline && multi_leg_polyline.map(shape => Utils.decodePolyline(shape, 6));

                // format calculations
                if(calculations && calculations.transaction_data) {
                    let { cost, transaction_data, warnings } = calculations;
                    resolve({
                        route: this.route,
                        warnings: warnings,
                        breakdown: {
                            total: cost,
                            service: cost,
                            credits: transaction_data.credits || 0,
                            driver_tip: transaction_data.driver_tip || 0,
                            promo_code: transaction_data.promo_code || 0,
                            line_items: transaction_data.line_items || [],
                            company_discount: transaction_data.company_discount || 0
                        }
                    });
                    return;
                }
                throw new Error('An unknown error occurred');

            } catch(e) {
                reject(e);
            }
        })
    }

    updateStatus = async (utils, code) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { status } = await Request.post(utils, '/order/', {
                    type: 'set_status',
                    order_id: this.id,
                    status: code
                });

                this.status = getStatus(status);
                utils.content.update({
                    type: 'orders',
                    object: this
                });
                resolve(this.status);

            } catch(e) {
                reject(e);
            }
        })
    }

    open = () => {
        // return previously created edits if order is a temporary target
        if(this.edits && !this.id) {
            return this.edits;
        }
        // return newly created edits if order has already been created
        this.edits = {
            customer: this.customer,
            channel: this.channel,
            other_users: this.other_users,
            company: this.company,
            service: this.service,
            host: this.host,
            origin: this.origin,
            destination: this.destination,
            stops: this.stops,
            options: this.options || [],
            drop_off_date: this.drop_off_date,
            distance: this.distance,
            duration: this.duration,
            promo_code: this.promo_code,
            driver_tip: this.driver_tip,
            credits: this.credits,
            payment_method: this.payment_method,
            subscription: this.subscription,
            requests: this.requests
        }
        return this.edits;
    }

    close = () => {
        let order = this.edits || this;
        this.customer = order.customer;
        this.company = order.company;
        this.channel = order.channel;
        this.service = order.service;
        this.host = order.host;
        this.drop_off_date = order.drop_off_date;
        this.distance = order.distance;
        this.duration = order.duration;
        this.origin = order.origin;
        this.stops = order.stops;
        this.destination = order.destination;
        this.driver_tip = order.driver_tip;
        this.options = order.options;
        this.credits = order.credits;
        this.requests = order.requests;
        this.other_users = order.other_users;
        this.promo_code = order.promo_code;
        this.subscription = order.subscription;
        this.edits = null;
    }

    set = (props = {}) => {
        if(!this.edits) {
            this.open();
        }
        this.edits = {
            customer: props.customer || this.edits.customer,
            channel: props.channel || this.edits.channel,
            other_users: props.other_users || this.edits.other_users,
            company: props.company || this.edits.company,
            service: props.service || this.edits.service,
            host: props.host || this.edits.host,
            origin: props.origin !== undefined ? props.origin : this.edits.origin,
            destination: props.destination !== undefined ? props.destination : this.edits.destination,
            options: props.options || this.edits.options,
            drop_off_date: props.drop_off_date || this.edits.drop_off_date,
            distance: props.distance || this.edits.distance,
            duration: props.duration || this.edits.duration,
            promo_code: props.promo_code !== undefined ? props.promo_code : this.edits.promo_code,
            driver_tip: props.driver_tip !== undefined ? props.driver_tip : this.edits.driver_tip,
            credits: props.credits !== undefined ? props.credits : this.edits.credits,
            subscription: props.subscription !== undefined ? props.subscription : this.edits.subscription,
            payment_method: props.payment_method || this.edits.payment_method,
            requests: props.requests || this.edits.requests || {},
            stops: (props.stops || this.edits.stops) && {
                ...this.edits.stops,
                ...props.stops && {
                    ...props.stops,
                    locations: props.stops.locations.map(stop => ({
                        ...stop,
                        location: stop.location || {
                            latitude: stop.lat,
                            longitude: stop.long
                        }
                    }))
                }
            }
        }

        // set new driver message
        if(props.requests) {
            this.edits.requests = {
                ...this.edits.requests,
                message: props.requests
            };
        }

        // attach customer company if applicable
        if(props.customer && props.customer.company) {
            this.edits.company = props.customer.company;
        }

        // set new payment method
        if(props.payment_method || props.payment_method) {
            this.edits.payment_method = props.payment_method || props.payment_method;
            this.edits.requests = {
                ...this.edits.requests,
                card_id: props.payment_method ? props.payment_method.id : props.payment_method.id
            };
        }
        return this.edits;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let order = this.edits;
                let { id } = await Request.post(utils, '/order/', {
                    type: 'new',
                    user_id: order.customer.user_id,
                    other_users: order.other_users,
                    order_channel: order.channel.id,
                    company: order.company ? order.company.id : null,
                    service: order.service ? order.service.id : null,
                    host: order.host ? order.host.id : null,
                    drop_off_date: moment(order.drop_off_date).format('YYYY-MM-DD HH:mm:ss'),
                    options: order.options.map(opt => OrderOption.toJSON(opt)),
                    promo_code: order.promo_code ? order.promo_code.id : null,
                    subscription: order.subscription ? order.subscription.id : null,
                    driver_tip: order.driver_tip,
                    credits: order.credits,
                    requests: order.requests,
                    origin: {
                        name: order.origin.name,
                        address: order.origin.address,
                        lat: order.origin.location.latitude,
                        long: order.origin.location.longitude
                    },
                    destination: {
                        name: order.destination.name,
                        address: order.destination.address,
                        lat: order.destination.location.latitude,
                        long: order.destination.location.longitude
                    },
                    stops: order.stops && order.stops.locations && {
                        ...order.stops,
                        locations: order.stops.locations.map(stop => {
                            return {
                                id: stop.id,
                                name: stop.name,
                                address: stop.address,
                                lat: stop.lat || stop.location.latitude,
                                long: stop.long || stop.location.longitude
                            }
                        })
                    },
                });

                this.id = id;
                this.close();

                // Join object room
                utils.sockets.emit('messages', 'join', {
                    id: id,
                    type: 'orders'
                });

                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let order = this.edits;
                let { multi_leg_polyline, polyline } = await Request.post(utils, '/order/', {
                    type: 'update',
                    id: this.id,
                    user_id: order.customer.user_id,
                    order_channel: order.channel.id,
                    other_users: order.other_users,
                    company: order.company ? order.company.id : null,
                    service: order.service ? order.service.id : null,
                    host: order.host ? order.host.id : null,
                    options: order.options.map(opt => OrderOption.toJSON(opt)),
                    drop_off_date: moment(order.drop_off_date).format('YYYY-MM-DD HH:mm:ss'),
                    promo_code: order.promo_code ? order.promo_code.id : null,
                    subscription: order.subscription ? order.subscription.id : null,
                    driver_tip: order.driver_tip,
                    credits: order.credits,
                    requests: order.requests,
                    origin: {
                        name: order.origin.name,
                        address: order.origin.address,
                        lat: order.origin.location.latitude,
                        long: order.origin.location.longitude
                    },
                    destination: {
                        name: order.destination.name,
                        address: order.destination.address,
                        lat: order.destination.location.latitude,
                        long: order.destination.location.longitude
                    },
                    stops: order.stops && order.stops.locations && {
                        ...order.stops,
                        locations: order.stops.locations.map(stop => {
                            return {
                                id: stop.id,
                                name: stop.name,
                                address: stop.address,
                                lat: stop.lat || stop.location.latitude,
                                long: stop.long || stop.location.longitude
                            }
                        })
                    },
                });

                this.close();
                this.polyline = polyline ? Utils.decodePolyline(polyline, 6) : this.polyline;
                this.multi_leg_polyline = multi_leg_polyline && multi_leg_polyline.map(shape => Utils.decodePolyline(shape, 6));
                resolve();

            } catch(e){
                reject(e);
            }
        })
    }
}

const getStatus = c => {

    // Appearance module could be null at runtime
    let code = isNaN(c) ? null : parseInt(c);
    let status = {
        code: code
    };

    if(code === StatusCodes.orders.rejected) {
        status.text = 'Declined';
        status.realText = 'Rejected';
        status.color = Appearance ? Appearance.colors.red : null;

    } else if(code === StatusCodes.orders.approved) {
        status.text = 'Approved';
        status.realText = 'Approved';
        status.color = Appearance ? Appearance.colors.primary() : null;

    } else if(code === StatusCodes.orders.returned) {
        status.text = 'Returned to Queue';
        status.realText = 'Returned to Queue';
        status.color = Appearance ? Appearance.colors.primary() : null;

    } else if(code === StatusCodes.orders.cancelled) {
        status.text = 'Cancelled';
        status.realText = 'Cancelled';
        status.color = '#812222';

    } else if(code === StatusCodes.orders.toPickup || code === StatusCodes.orders.arrivedAtHost || code === StatusCodes.orders.toDestination) {
        status.text = 'In Progress';
        status.realText = code === StatusCodes.orders.toPickup ? 'In Route To Pickup':(code === StatusCodes.orders.arrivedAtHost ? 'Arrived at Host' : 'In Route To Destination');
        status.color = Appearance ? Appearance.colors.blue : null;

    } else if(code === StatusCodes.orders.completed) {
        status.text = 'Completed';
        status.realText = 'Completed';
        status.color = Appearance ? Appearance.colors.darkGrey : null;

    } else if(code === StatusCodes.orders.unpaid) {
        status.text = 'Unpaid';
        status.realText = 'Unpaid';
        status.color = Appearance ? Appearance.colors.lightGrey : null;

    } else if(code === StatusCodes.orders.preauthorized) {
        status.text = 'Preauthorized';
        status.realText = 'Preauthorized';
        status.color = Appearance ? Appearance.colors.lightGrey : null;

    } else if(code === StatusCodes.orders.preauthorizationFailed) {
        status.text = 'Preauthorization Failed';
        status.realText = 'Preauthorization Failed';
        status.color = Appearance ? Appearance.colors.red : null;

    } else if(code === StatusCodes.orders.preauthorizationRevoked) {
        status.text = 'Preauthorization Revoked';
        status.realText = 'Preauthorization Revoked';
        status.color = Appearance ? Appearance.colors.red : null;

    } else if(code === StatusCodes.orders.adminUpdated) {
        status.text = 'Admin Updated';
        status.realText = 'Admin Updated';
        status.color = Appearance ? Appearance.colors.grey() : null;

    } else if(code === StatusCodes.orders.driverUpdated) {
        status.text = 'Driver Updated';
        status.realText = 'Driver Updated';
        status.color = Appearance ? Appearance.colors.grey() : null;

    } else if(code === StatusCodes.orders.customerEdited) {
        status.text = 'Customer Edited';
        status.realText = 'Customer Edited';
        status.color = Appearance ? Appearance.colors.grey() : null;

    } else if(code === StatusCodes.orders.chargeProcessing) {
        status.text = 'Charge Processing';
        status.realText = 'Charge Processing';
        status.color = Appearance ? Appearance.colors.grey() : null;

    } else if(code === StatusCodes.orders.chargePosted) {
        status.text = 'Charge Posted';
        status.realText = 'Charge Posted';
        status.color = Appearance ? Appearance.colors.darkGrey : null;

    } else if(code === StatusCodes.orders.chargeFailed) {
        status.text = 'Charge Failed';
        status.realText = 'Charge Failed';
        status.color = Appearance ? Appearance.colors.red : null;

    } else if(code === StatusCodes.orders.chargeIssue) {
        status.text = 'Charge Issue';
        status.realText = 'Charge Issue';
        status.color = Appearance ? Appearance.colors.red : null;

    } else {
        status.text = 'Pending';
        status.realText = 'Pending';
        status.color = Appearance ? Appearance.colors.grey() : null;
    }

    return status;
}

const fetchOrder = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { order } = await Request.get(utils, '/order/', {
                type: 'details',
                id: id
            });
            let target = new OrderClass().create(order);
            resolve(target);
        } catch(e) {
            reject(e);
        }
    })
}

export default {
    new: () => new OrderClass(),
    get: fetchOrder,
    create: props => new OrderClass().create(props),
    getStatus: getStatus
}
