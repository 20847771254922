import React from 'react';
import moment from 'moment';

import API from 'eCarra/files/api.js';
import NewsChannel from 'eCarra/classes/NewsChannel.js';
import Utils from 'eCarra/files/Utils.js';

class NewsItemClass {

    id = null;
    copy_id = null;
    title = null;
    description = null;
    date = null;
    channel = null;
    category = null;
    url = null;
    content = null;
    featured = null;
    favorite = false;
    startDate = null;
    endDate = null;
    image = null;
    active = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.id = props.id;
        this.copy_id = `${props.id}-${moment().unix()}`;
        this.title = props.title;
        this.description = props.description;
        this.date = props.date ? moment(props.date) : null;
        this.channel = props.channel ? NewsChannel.create(props.channel) : null;
        this.category = props.category;
        this.url = props.url;
        this.content = props.content;
        this.image = props.image ? { uri: props.image } : null;
        this.featured = Boolean(props.featured);
        this.startDate = props.start_date ? moment(props.start_date) : null;
        this.endDate = props.end_date ? moment(props.end_date) : null;
        this.active = Boolean(props.active);
        return this;
    }
}
export default {
    create: props => new NewsItemClass().create(props)
};
