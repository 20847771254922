import React, { useRef, useState, useEffect } from 'react';
import { Animated, Image, Linking, Platform, Text, View } from 'react-native';

import Appearance from 'eCarra/styles/Appearance.js';
import Layer, { LayerSpacing, FloatingLayerMinimizedHeight } from 'eCarra/structure/Layer.js';
import Screen from 'eCarra/files/Screen.js';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import { WebView as NativeWebView } from 'react-native-webview';

const MusicPlayer = ({ floatingLayerState, setFloatingLayerState, index, options, utils }) => {

    const layerID = 'music-player';
    const webView = useRef(null);
    const [loading, setLoading] = useState(false);
    const [opacity, setOpacity] = useState(new Animated.Value(0));
    const [height, setHeight] = useState(new Animated.Value(floatingLayerState === 'minimized' ? FloatingLayerMinimizedHeight : (Screen.height() - Screen.safeArea.top)));

    const musicUserToken = encodeURIComponent('AjJrgFwg8+Fu63EsvsUf1nTJoqZD+GL+Dn+5/yJjEU9bqaC9gfHMNZ3+0BqIF74nfO2oqsEjdKG0jpAiSP+IzV/endhyWI8UkYcJfcVvn+DBFDgr2tV1AnFH5dxMKYv0c84Yih6JOVD7MNtA7P5P2OQdNduKyqEkj+9JTw4TR7AcKApdZc+aOM5OFJs/zNoLlHLt4xGTbZ1/1YtchJj3LFDKdg6WEo/SHW+jiQR7ZwA8G2Ip2g==');

    const onLoadStart = () => {
        Animated.timing(opacity, {
            toValue: 0,
            duration: 500,
            useNativeDriver: false
        }).start()
    }

    const onLoadEnd = ()=> {

        Animated.timing(opacity, {
            toValue: 1,
            duration: 1500,
            useNativeDriver: false
        }).start();
    }

    const onControlMessage = (e) => {
        try {
            let json = JSON.parse(e.nativeEvent.data);
            if(json.floatingLayerState) {

                if(json.floatingLayerState === 'closed') {
                    setFloatingLayerState('close');
                    return;
                }

                setFloatingLayerState(json.floatingLayerState);
                Animated.spring(height, {
                    duration: 750,
                    friction: 10,
                    toValue: json.floatingLayerState === 'minimized' ? FloatingLayerMinimizedHeight : (Screen.height() - Screen.safeArea.top),
                    useNativeDriver: false
                }).start()
            }
        } catch(e) {
            console.log(e)
        }
    }

    const sendFloatingLayerState = (state) => {
        webView.current.injectJavaScript(`
            window.dispatchEvent(new CustomEvent('onFloatingLayerStateChange', {
                detail: {
                    state: '${state}'
                }
            }));
        `);
    }

    const setupMusicPlayer = async () => {
         try {
             let token = await AsyncStorage.getItem('login');
             setToken(token);
         } catch(e) {
             utils.alert.show({
                 title: 'Oops!',
                 message: `There was an issue setting up your music player. ${e.message || 'An unknown error occurred'}`
             });
         }
    }

    useEffect(() => {
        sendFloatingLayerState(floatingLayerState);
    }, [floatingLayerState]);

    return (
        <View style={{
            backgroundColor: Appearance.colors.background()
        }}>
            <Animated.View style={{
                width: Screen.width(),
                height: height,
                overflow: 'hidden',
                opacity: opacity,
                backgroundColor: Appearance.colors.background()
            }}>
                {/* FIX THIS => remove static token and fetch token from backend or through Apple Auth for driver */}
                <NativeWebView
                ref={webView}
                source={token ? { uri: `http://192.168.7.37:3000/?channel=music&floating_layer_state=${floatingLayerState}&sab=${Screen.safeArea.bottom}&music_user_token=${musicUserToken}&user_token=${token}` } : null}
                domStorageEnabled={true}
                javaScriptEnabled={true}
                onLoadEnd={onLoadEnd}
                ignoreSilentHardwareSwitch={true}
                onShouldStartLoadWithRequest={() => true}
                onMessage={onControlMessage}
                style={{
                    width: '100%',
                    height: '100%',
                    borderTopLeftRadius: 15,
                    borderTopRightRadius: 15
                }} />
            </Animated.View>
        </View>
    )
}

export default MusicPlayer;
