import React, { useEffect, useState } from 'react';
import Utils from 'eCarra/files/Utils.js';

const TouchableOpacity = ({ activeOpacity, children, onPress, style }) => {

    const [hover, setHover] = useState(false);

    return (
        <div
        className={'touchable-opacity'}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={onPress}
        style={{
            ...Utils.transformStylesForWeb(style),
            ...hover && {
                cursor: 'pointer',
                opacity: activeOpacity
            }
        }}>
            {children}
        </div>
    )
}
export default TouchableOpacity;
