import React from 'react';
import moment from 'moment';

import API from 'eCarra/files/api.js';
import CreditsCard from 'eCarra/classes/CreditsCard.js';
import Note from 'eCarra/classes/Note.js';
import PaymentMethod from 'eCarra/classes/PaymentMethod.js';
import Request from 'eCarra/files/Request/';
import User from 'eCarra/classes/User.js';

class CompanyClass {

    id = null;
	key = null;
	name = null;
    stripe_customer_id = null;
	information = null;
	address = null;
    email_address = null;
	discountRate = null;
	image = null;
	totalAdmins = null;
	member_since = null;
    location = {};
    addedBy = null;
    totalUsers = null;
    active = null;
    credits = null;
    emissions = null;
    seeds = null;

    edits = null;
    creditMethods = [];
    payment_methods = [];

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.id = parseInt(props.id);
        this.key = props.key;
        this.name = props.name;
        this.stripe_customer_id = props.stripe_customer_id;
        this.information = props.information;
        this.address = props.address;
        this.email_address = props.email_address;
        this.discountRate = parseFloat(props.discount_rate);
        this.location = props.location && {
            latitude: props.location.lat,
            longitude: props.location.long
        };
        this.member_since = props.member_since ? moment(props.member_since) : null;
        this.image = props.image ? { uri: props.image } : require('eCarra/images/company-placeholder-clear.png');
        this.totalUsers = props.total_users || 0;
        this.totalAdmins = props.total_admins || 0;
        this.active = props.active;
        this.credits = props.credits;

        return this;
    }
}

export default {
    create: props => new CompanyClass().create(props)
};
