import React, { useEffect, useState } from 'react';
import { View, Text, Animated, TouchableWithoutFeedback, PanResponder, Platform } from 'react-native';

import Appearance from 'eCarra/styles/Appearance.js';
import LinearGradient from 'eCarra/views/LinearGradient/';
import Screen from 'eCarra/files/Screen.js';
import Sound from 'eCarra/files/Sound/';
import Utils from 'eCarra/files/Utils.js';
import Views from 'eCarra/views/Main.js';

const MobileNotification = ({ category, from_user, icon, message, onClose, onPress, title, user_info}) =>  {

    const [top, setTop] = useState(new Animated.Value(-250));
    const [panTop, setPanTop] = useState(new Animated.Value(0));
    const [opacity, setOpacity] = useState(new Animated.Value(0));

    const panResponder = PanResponder.create({
        onMoveShouldSetPanResponder: (evt, { moveY }) => {
            return moveY > 10
        },
        onPanResponderMove: (evt, { dy }) => {
            panTop.setValue(dy > 0 ? 0 : dy);
        },
        onPanResponderRelease: (evt, { dy }) => {
            if(dy < -15) {
                onHideNotification();
            } else {
                Animated.spring(panTop, {
                    toValue: 0,
                    duration: 150,
                    useNativeDriver: false
                }).start();
            }
        }
    });

    const onHideNotification = callback => {
        Animated.timing(opacity, {
            toValue: 0,
            duration: 250,
            useNativeDriver: false
        }).start();

        Animated.spring(top, {
            toValue: -250,
            duration: 1000,
            friction: 10,
            useNativeDriver: false
        }).start();
        setTimeout(() => {
            if(typeof(callback) === 'function') {
                callback();
            }
            if(typeof(onClose) === 'function') {
                onClose();
            }
        }, 500);
    }

    const getIcon = () => {
        if(icon) {
            return typeof(icon) === 'string' ? { path: icon } : icon;
        }
        if(from_user && from_user.avatar) {
            return { path: from_user.avatar };
        }
        return null;
    }

    useEffect(() => {

        Sound.Message.Received.play();
        Animated.timing(opacity, {
            toValue: 1,
            duration: 250,
            useNativeDriver: false
        }).start();

        Animated.spring(top, {
            toValue: 0,
            duration: 1000,
            friction: 10,
            useNativeDriver: false
        }).start(() => {
            setTimeout(() => {
                onHideNotification();
            }, 4000);
        });

    }, []);

    return (
        <Animated.View style={{
            position: 'absolute',
            width: Screen.width(),
            top: 0,
            left: 0,
            right: 0,
            opacity: opacity,
            overflow: 'hidden'
        }}>
            <LinearGradient colors={['rgba(0,0,0,0.35)', 'rgba(0,0,0,0)']}>
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '100%',
                    paddingHorizontal: 15,
                    paddingTop: 15 + Screen.safeArea.top,
                    paddingBottom: 50
                }}>
                    <Animated.View style={{
                        position: 'relative',
                        top: top,
                        opacity: opacity,
                        maxWidth: Screen.width() - 30,
                        ...(Platform.OS === 'web' || Utils.isMobile() === false) && {
                            maxWidth: Screen.component.maxWidth
                        }
                    }}>
                        <Animated.View style={{
                            padding: 5,
                            backgroundColor: Appearance.colors.layerBackground(),
                            borderRadius: 15,
                            overflow: 'hidden',
                            top: panTop
                        }}>
                            {Views.entry({
                                title: title,
                                subTitle: message,
                                hideIcon: getIcon() ? false : true,
                                icon: getIcon(),
                                bottomBorder: false,
                                onPress: onHideNotification.bind(this, onPress),
                                propStyles: {
                                    subTitle: {
                                        numberOfLines: 5
                                    }
                                }
                            })}
                        </Animated.View>
                    </Animated.View>
                </View>
            </LinearGradient>
        </Animated.View>
    )
}
export default MobileNotification;
