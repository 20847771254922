import React from 'react';
import moment from 'moment';

import PromoCodes from 'eCarra/files/PromoCodes.js';
import User from 'eCarra/classes/User.js';
import Utils from 'eCarra/files/Utils.js';

class PromoCodeClass {

    id = null;
    title = null;
    description = null;
    company = null;
    code = null;
    discount = null;
    discountType = null;
    image = null;
    startDate = null;
    endDate = null;
    submitted_by = null;
    submission_date = null;
    redeemed = null;
    redeemedBy = [];
    maxRedemption = null;
    maxRedemptionPerUser = null;
    public = null;
    forUsers = null;
    active = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.title = props.title;
        this.description = props.description;
        this.company = props.company;
        this.code = props.code ? props.code.toUpperCase() : null;
        this.discount = props.discount;
        this.discountType = props.discount_type;
        this.image = props.image;
        this.startDate = props.start_date;
        this.endDate = props.end_date;
        this.submissionDate = props.submission_date;
        this.redeemed = props.redeemed;
        this.redeemedBy = props.redeemd_by;
        this.maxRedemption = props.max_redemption;
        this.maxRedemptionPerUser = props.max_redemption_per_user;
        this.public = props.public;
        this.active = Boolean(props.active || false);
        this.notes = props.notes;
        this.submittedBy = props.submitted_by ? User.create(props.submitted_by) : null;
        this.forUsers = props.for_users ? props.for_users.map(u => User.create(u)) : null;
        return this;
    }

    summary = () => {
        return this.title + ': ' + this.discountSummary();
    }

    discountSummary = () => {
        switch(this.discountType) {
            case PromoCodes.standard.fixedCost:
            return `${Utils.toCurrency(this.discount)} Discount`;

            case PromoCodes.standard.percentage:
            return `${(parseFloat(this.discount) * 100).toFixed(2)}% Discount`;

            case PromoCodes.standard.fullCost:
            return '100% Discount';

            case PromoCodes.standard.credits:
            return `${Utils.toCurrency(this.discount)} worth of Credits`;

            default:
            return 'Unknown promo code type';
        }
    }

    discountInformation = () => {

        let promo_code = this.edits || this;
        switch(this.discountType) {

            case PromoCodes.standard.fixedCost:
            return {
                text: 'Fixed Cost',
                amount: Utils.toCurrency(promo_code.discount)
            }

            case PromoCodes.standard.percentage:
            return {
                text: 'Precentage',
                amount: `${(parseFloat(promo_code.discount) * 100).toFixed(2)}%`
            }

            case PromoCodes.standard.fullCost:
            return {
                text: 'Full Cost',
                amount: '100%'
            }

            case PromoCodes.standard.credits:
            return {
                text: 'Credits Redemption',
                amount: Utils.toCurrency(promo_code.discount)
            }

            default:
            return 'Unknown promo code discount';
        }
    }
}

export default {
    create: props => new PromoCodeClass().create(props)
}
