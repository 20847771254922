import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'eCarra/styles/slider.css';

import Appearance from 'eCarra/styles/Appearance.js';
import Slider from '@material-ui/core/Slider';

const CustomSlider = ({ initialLowValue, initialHighValue, min, max, onValueChanged, selectionColor, step, thumbBorderColor, thumbColor, thumbRadius }) => {

    const onChange = (evt, value) => {
        if(typeof(onValueChanged) === 'function') {
            if(Array.isArray(value)) {
                onValueChanged(value[0], value[1]);
                return;
            }
            onValueChanged(value);
        }
    }

    const getStyles = makeStyles(() => ({
        root: {
            color: Appearance.colors.primary(),
            height: 2,
            padding: '15px 0'
        },
        thumb: {
            height: 28,
            width: 28,
            marginTop: -14,
            marginLeft: -14,
            backgroundColor: thumbColor || '#FFFFFF',
            border: `2px solid ${thumbBorderColor || Appearance.colors.softBorder()}`,
            '&:focus, &:hover, &$active': {
                boxShadow: 'none'
            },
            ...thumbRadius && {
                height: thumbRadius * 2,
                width: thumbRadius * 2,
                marginTop: -(thumbRadius),
                marginLeft: -(thumbRadius / 2),
            }
        },
        active: {},
        track: {
            height: 3
        },
        rail: {
            height: 3,
            opacity: 1,
            backgroundColor: Appearance.colors.softBorder()
        },
        mark: {
            backgroundColor: selectionColor || Appearance.colors.primary(),
            height: 8,
            width: 1,
            marginTop: -3
        },
        markActive: {
            opacity: 1,
            backgroundColor: selectionColor || Appearance.colors.green
        }
    }));
    const getValue = () => {
        if(initialLowValue >= 0 && initialHighValue >= 0) {
            return [initialLowValue, initialHighValue];
        }
        return initialLowValue;
    }

    return (
        <Slider
        classes={getStyles()}
        step={step}
        min={min}
        max={max}
        value={getValue()}
        onChange={onChange}/>
    )
}

export default CustomSlider;
