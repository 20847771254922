import React, { useEffect, useState } from 'react';
import { Platform, View } from 'react-native';

import moment from 'moment';

import Appearance from 'eCarra/styles/Appearance.js';
import BoolToggle from 'eCarra/views/BoolToggle.js';
import DatePicker from 'eCarra/views/DatePicker/';
import Views from 'eCarra/views/Main.js';

const DatePickerOnDemand = ({ itemKey, date, enabled, enabledContent, minDate, onChange, onInvalidChange, style, showTime, utils }) => {

    const [_date, _setDate] = useState(date);
    const [_enabled, _setEnabled] = useState(enabled);

    const onDateChange = date => {
        _setDate(date);
        if(typeof(onChange) === 'function') {
            onChange({
                [itemKey]: minDate && moment(date) < moment(minDate) ? moment(minDate) : moment(date),
                on_demand: _enabled
            });
        }
    }

    const on_demandChange = enabled => {
        _setEnabled(enabled);
        if(typeof(onChange) === 'function') {
            onChange({
                [itemKey]: minDate && moment(_date) < moment(minDate) ? moment(minDate) : moment(_date),
                on_demand: enabled
            });
        }
    }

    return (
        <>
        <BoolToggle
        isEnabled={enabled}
        disabled={'Scheduled'}
        enabled={'Right Now'}
        onChange={on_demandChange}/>
        {_enabled !== true
            ?
            <DatePicker
            utils={utils}
            date={date}
            minDate={minDate}
            showTime={showTime}
            onInvalidChange={onInvalidChange}
            fadeToColor={Appearance.colors.layerBackground()}
            onChange={onDateChange}
            style={{
                ...Platform.OS === 'web' && {
                    marginTop: 12
                }
            }}/>
            :
            <View style={{
                width: '100%',
                marginTop: 20,
                ...Appearance.styles.panel()
            }}>
                {Views.entry({
                    icon: {
                        path: require('eCarra/images/active-icon-blue-small.png')
                    },
                    propStyles: {
                        subTitle: {
                            numberOfLines: 3
                        }
                    },
                    ...enabledContent
                })}
            </View>
        }
        </>
    )
}

export default DatePickerOnDemand
