import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import Appearance from 'eCarra/styles/Appearance.js';
import Svg, { G, Polygon, Path, Stop, LinearGradient } from 'react-native-svg';

const BezierLine = ({ fromZero, data, width, height, style }) => {

    const paddingTop = 5;
    const paddingRight = 0;
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        setDatas(data ? data.reduce((acc, item) => (item.data ? [...acc, ...item.data] : acc), []) : []);
    }, [data]);

    const calcScaler = data => {
        if(fromZero) {
            return Math.max(...data, 0) - Math.min(...data, 0) || 1;
        } else {
            return Math.max(...data) - Math.min(...data) || 1;
        }
    };

    const calcHeight = (val, data, height) => {
        const max = Math.max(...data);
        const min = Math.min(...data);
        if (min < 0 && max > 0) {
            return height * (val / calcScaler(data));
        } else if (min >= 0 && max >= 0) {
            return fromZero ? height * (val / calcScaler(data)) : height * ((val - min) / calcScaler(data));
        } else if (min < 0 && max <= 0) {
            return fromZero ? height * (val / calcScaler(data)) : height * ((val - max) / calcScaler(data));
        }
    };

    const calcBaseHeight = (data, height) => {
        const min = Math.min(...data);
        const max = Math.max(...data);
        if (min >= 0 && max >= 0) {
            return height;
        } else if (min < 0 && max <= 0) {
            return 0;
        } else if (min < 0 && max > 0) {
            return (height * max) / calcScaler(data);
        }
    };

    const getBezierLinePoints = (dataset) => {

        if(dataset.data.length === 0) {
            return 'M0,0';
        }

        const x = i => Math.floor(paddingRight + (i * (width - paddingRight)) / dataset.data.length);
        const baseHeight = calcBaseHeight(datas, height);
        const y = i => {
            const yHeight = calcHeight(dataset.data[i], datas, height);
            return Math.floor(((baseHeight - yHeight) / 4) * 3 + paddingTop);
        };

        let points = dataset.data.slice(0, -1).map((_, i) => {
            const x_mid = (x(i) + x(i + 1)) / 2;
            const y_mid = (y(i) + y(i + 1)) / 2;
            const cp_x1 = (x_mid + x(i)) / 2;
            const cp_x2 = (x_mid + x(i + 1)) / 2;
            return x_mid && y_mid ? (
                `Q ${cp_x1}, ${y(i)}, ${x_mid}, ${y_mid}` +
                ` Q ${cp_x2}, ${y(i + 1)}, ${x(i + 1)}, ${y(i + 1)}`
            ) : false;
        }).filter(d => d !== false);
        return points.length > 0 ? [`M${x(0)},${y(0)}`].concat(points).join(' ') : 'M0,0';
    };

    return (
        <View style={{
            height: height,
            width: width,
            ...style
        }}>
            <Svg width={width} height={height}>
                {data.map((dataset, index) => {
                    const result = getBezierLinePoints(dataset);
                    return (
                        <Path
                        key={index}
                        d={result}
                        fill={dataset.gradientFill ? 'url(#grad)' : (dataset.fill || 'none')}
                        stroke={dataset.strokeColor || Appearance.colors.darkGrey}
                        strokeWidth={dataset.strokeWidth || 2} />
                    );
                })}
            </Svg>
        </View>
    )
}

export default BezierLine;
