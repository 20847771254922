import React from 'react';
import moment from 'moment';

import User from 'eCarra/classes/User.js';

class OrderChannelClass {

    id = null;
    name = null;
    description = null;
    icon = null;
    addedBy = null;
    date = null;
    active = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.name = props.name;
        this.description = props.description;
        this.icon = props.icon;
        this.addedBy = props.added_by ? User.create(props.added_by) : null;
        this.date = props.date ? moment(props.date) : null;
        this.active = Boolean(props.active);
        return this;
    }
}
export default {
    create: props => new OrderChannelClass().create(props)
};
