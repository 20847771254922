import React, { useRef, useState, useEffect } from 'react';
import { Image, Platform, Text, View } from 'react-native';
import moment from 'moment';

import Appearance from 'eCarra/styles/Appearance.js';
import Button from 'eCarra/views/Button.js';
import LottieView from 'eCarra/views/Lottie/';
import ProgressBar from 'eCarra/views/ProgressBar/';
import Screen from 'eCarra/files/Screen.js';
import TouchableHighlight from 'eCarra/views/TouchableHighlight/';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import Utils from 'eCarra/files/Utils.js';

export const AltBadge = ({ content, style, onPress }) => {
    return Array.isArray(content) ?
        content.map(({ text, color, tag }, index) => {
            if(!text || !color) {
                return;
            }
            return (
                <TouchableOpacity
                key={index}
                activeOpacity={onPress ? 0.6 : 1}
                onPress={onPress}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingVertical: 3,
                    paddingHorizontal: 10,
                    borderRadius: 20,
                    backgroundColor: Appearance.themeStyle() === 'dark' ? color : Utils.hexToRGBA(color, 0.25),
                    alignItems: 'center',
                    ...style
                }}>
                    <Text
                    numberOfLines={1}
                    style={{
                        fontSize: 10,
                        color: Appearance.themeStyle() === 'dark' ? 'white' : color,
                        ...Appearance.fontWeight.get(800)
                    }}>{text.toUpperCase()}</Text>
                </TouchableOpacity>
            )
        })
        :
        content && content.text && content.color
            ?
            <TouchableOpacity
            activeOpacity={onPress ? 0.6 : 1}
            onPress={onPress}
            style={{
                display: 'flex',
                flexDirection: 'row',
                paddingVertical: 4,
                paddingHorizontal: 10,
                borderRadius: 20,
                backgroundColor: Appearance.themeStyle() === 'dark' ? content.color : Utils.hexToRGBA(content.color, 0.25),
                alignItems: 'center',
                ...style
            }}>
                <Text
                numberOfLines={1}
                style={{
                    fontSize: 10,
                    color: Appearance.themeStyle() === 'dark' ? 'white' : content.color,
                    ...Appearance.fontWeight.get(800)
                }}>{content.text ? content.text.toUpperCase() : ''}</Text>
            </TouchableOpacity>
            :
            null
}

export default {
    loader: props => (
        <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            padding: 20,
            ...props
        }}>
            <LottieView
            autoPlay={true}
            loop={true}
            source={Appearance.themeStyle() === 'dark' ? require('eCarra/files/lottie/dots-white.json') : require('eCarra/files/lottie/dots-grey.json')}
            duration={2500}
            style={{
                width: 50
            }}/>
        </View>
    ),
    entry: props => {

        let icon = props.icon && typeof(props.icon) === 'object' ? props.icon : null;
        let content = (
            <>
                <View style={{
                    position: 'relative',
                    minHeight: 50,
                    paddingHorizontal: 9,
                    paddingVertical: 7,
                    borderBottomWidth: props.bottomBorder === false ? 0:1,
                    borderBottomColor: Appearance.colors.divider()
                }}>
                    <View style={{
                        flex: 1,
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center'
                    }}>
                        {props.prepend}
                        {props.hideIcon !== true && (
                            typeof(props.icon) === 'function'
                                ?
                                props.icon()
                                :
                                <TouchableOpacity
                                activeOpacity={icon && icon.onPress ? 0.6 : 1}
                                onPress={icon ? icon.onPress : null}
                                style={{
                                    position: 'relative'
                                }}>
                                    <View style={{
                                        ...Appearance.icons.standard,
                                        minWidth: Appearance.icons.standard.width,
                                        minHeight: Appearance.icons.standard.height,
                                        borderRadius: Appearance.icons.standard.width / 2,
                                        overflow: 'hidden',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        ...(icon ? icon.style : null)
                                    }}>
                                        {props.loading
                                            ?
                                            <LottieView
                                            autoPlay={true}
                                            loop={true}
                                            source={require('eCarra/files/lottie/dots-white.json')}
                                            duration={2500}
                                            style={{
                                                width: 15,
                                                height: 15
                                            }}/>
                                            :
                                            <Image
                                            source={props.icon && props.icon.path ? props.icon.path : require('eCarra/images/star-icon-clear.png')}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                resizeMode: 'cover',
                                                ...(props.icon ? props.icon.imageStyle : null),
                                            }}/>
                                        }
                                    </View>
                                    {props.icon && props.icon.badge && (
                                        <View style={{
                                            width: 20,
                                            height: 20,
                                            minWidth: 20,
                                            minHeight: 20,
                                            paddingHorizontal: 5,
                                            borderRadius: 10,
                                            overflow: 'hidden',
                                            position: 'absolute',
                                            top: -2,
                                            right: -6,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: typeof(props.icon.badge) === 'object' && props.icon.badge.color ? props.icon.badge.color : Appearance.colors.primary()
                                        }}>
                                            {typeof(props.icon.badge) === 'object' && props.icon.badge.text
                                                ?
                                                <Text style={{
                                                    width: '100%',
                                                    fontSize: 10,
                                                    ...Appearance.fontWeight.get(900),
                                                    color: 'white',
                                                    textAlign: 'center'
                                                }}>{props.icon.badge.text}</Text>
                                                :
                                                <View style={{
                                                    width: 5,
                                                    height: 5,
                                                    backgroundColor: 'white',
                                                    borderRadius: 2.5,
                                                    overflow: 'hidden'
                                                }}/>
                                            }
                                        </View>
                                    )}
                                </TouchableOpacity>
                        )}

                        <View style={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                            paddingLeft: props.hideIcon === true ? 2 : 12,
                            flexShrink: 1,
                            width: '100%'
                        }}>
                            {props.badge && (Array.isArray(props.badge) || props.badge.color)
                                ?
                                <View style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    <Text
                                    numberOfLines={1}
                                    {...props.propStyles && props.propStyles.title}
                                    style={{
                                        ...Appearance.textStyles.title(),
                                        ...props.textStyles && props.textStyles.title,
                                        paddingRight: 8,
                                        flexShrink: 1
                                    }} >{props.title}</Text>
                                    <AltBadge content={props.badge} />
                                </View>
                                :
                                <Text
                                numberOfLines={1}
                                {...props.propStyles && props.propStyles.title}
                                style={{
                                    ...Appearance.textStyles.title(),
                                    ...props.textStyles && props.textStyles.title
                                }}>{props.title}</Text>
                            }

                            {typeof(props.subTitle) === 'string' && (
                                <Text
                                numberOfLines={props.multiline ? 5 : 1}
                                {...props.propStyles && props.propStyles.subTitle}
                                style={{
                                    ...Appearance.textStyles.subTitle(),
                                    ...props.textStyles && props.textStyles.subTitle,
                                    marginTop: Platform.OS === 'android' ? 0 : 2
                                }}>{props.subTitle}</Text>
                            )}

                            {typeof(props.supportingTitle) === 'string' && (
                                <Text
                                {...props.propStyles && props.propStyles.supportingTitle}
                                style={{
                                    ...Appearance.textStyles.subTitle(),
                                    ...props.textStyles && props.textStyles.supportingTitle,
                                    marginTop: Platform.OS === 'android' ? 0 : 2
                                }}>{props.supportingTitle}</Text>
                            )}

                        </View>
                        {props.rightIcon && (
                            <Image
                            source={props.rightIcon.path}
                            style={{
                                ...props.rightIcon.style,
                                minWidth: 25,
                                minHeight: 25,
                                width: 25,
                                height: 25
                            }}/>
                        )}
                        {props.rightContent || (props.onPress && props.onPressArrow !== false && (
                            <Image
                            source={require('eCarra/images/next-arrow-grey-small.png')}
                            style={{
                                width: 15,
                                height: 15,
                                resizeMode: 'contain',
                                marginLeft: 8,
                                opacity: 1,
                                tintColor: Appearance.themeStyle() === 'dark' ? 'white' : Appearance.colors.grey()
                            }} />
                        ))}
                    </View>
                    {props.hideIcon && (
                        <ProgressBar animate={props.loading} />
                    )}
                </View>
                {props.bottomContent}
            </>
        )

        return props.useTouchable === false ? (
            <View
            key={props.key}
            style={{
                overflow: 'hidden',
                ...props.style
            }}>
                {content}
            </View>
        ) : (
            <TouchableHighlight
            key={props.key}
            activeOpacity={0.9}
            underlayColor={Appearance.colors.divider()}
            onPress={props.onPress}
            style={{
                overflow: 'hidden',
                borderRadius: 10,
                ...props.style
            }}>
                {content}
            </TouchableHighlight>
        )
    }
}
