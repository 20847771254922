import React from 'react';
import { Platform } from 'react-native';

import API from 'eCarra/files/api.js';
import AsyncStorage from '@react-native-community/async-storage';
import { DallasCenterCoordinate } from 'eCarra/views/Maps/';
import Permissions, { PERMISSIONS, RESULTS } from 'eCarra/files/Permissions/';
import RNLocation from 'react-native-location';

class LocationManager {

    active = false;
    enabled = { background: false, foreground: false };
    location = null;
    subscribers = {};
    subscription = false;

    constructor() {
        return this;
    }

    check = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let enabled = await AsyncStorage.getItem('use_location');
                this.enabled.foreground = enabled === true;
                resolve(this.enabled);
            } catch(e) {
                console.error(e.message);
                reject(false);
            }
        })
    }

    request = async () => {
        return new Promise(async (resolve, reject) => {
            try {

                // check that browser supports location based services
                if(!navigator.geolocation) {
                    await AsyncStorage.setItem('use_location', false);
                    this.enabled.foreground = false;

                    // notify permissions listener of change
                    Object.values(this.subscribers).forEach(({ id, callbacks }) => {
                        let { onPermissionChange } = callbacks;
                        if(typeof(onPermissionChange) === 'function') {
                            onPermissionChange(this.enabled);
                        }
                    });

                    throw new Error('Your browser does not support location services');
                    return;
                }

                // request current location from navigator
                await this.requestFromNavigator();
                await AsyncStorage.setItem('use_location', true);
                this.enabled.foreground = true;

                // notify permissions listener of change
                Object.values(this.subscribers).forEach(({ id, callbacks }) => {
                    let { onPermissionChange } = callbacks;
                    if(typeof(onPermissionChange) === 'function') {
                        onPermissionChange(this.enabled);
                    }
                });

                resolve(true);

            } catch(e) {
                reject(false);
            }
        })
    }
    requestFromNavigator = async () => {
        return new Promise((resolve, reject) => {

            // return static location is dev_env is enabled and http protocol is insecure
            if(API.dev_env && !window.location.protocol.includes('https')) {
                console.warn('Location manager is in development mode');
                resolve({
                    latitude: DallasCenterCoordinate[1],
                    longitude: DallasCenterCoordinate[0]
                });
                return;
            }

            // request real location from browser
            navigator.geolocation.getCurrentPosition(async ({ coords }) => {
                resolve(coords);
            }, async error => {
                reject(error);
            });
        })
    }

    last = () => {
        return this.lastLocaton;
    }

    start = async () => {
        try {
            // check that browser supports location based services
            if(!navigator.geolocation) {
                console.warn('Location services not available');
                return;
            }

            // request new location every 60 seconds
            let getLocation = async () => {
                try {
                    let coordinates = await this.requestFromNavigator();
                    this.lastLocaton = {
                        name: 'Current Location',
                        latitude: coordinates.latitude,
                        longitude: coordinates.longitude,
                        heading: coordinates.heading
                    }
                    this.onUpdate(this.lastLocaton);
                } catch(e) {
                    console.error(e.message);
                }
            };
            let interval = setInterval(getLocation, 60000);
            getLocation();

            // setup subscription cancellation
            this.cancelSubscription = () => {
                clearInterval(interval);
            }

        } catch(e) {
            console.error(e.message);
        }
    }

    stop = () => {
        if(this.cancelSubscription) {
            this.onStop();
            this.cancelSubscription();
        }
    }

    get = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                // check that browser supports location based services
                if(!navigator.geolocation) {
                    throw new Error('Location services are not available in this browser');
                    return;
                }

                // request new location every two seconds
                let coordinates = await this.requestFromNavigator();
                this.lastLocaton = {
                    name: 'Current Location',
                    latitude: coordinates.latitude,
                    longitude: coordinates.longitude,
                    heading: coordinates.heading
                }
                resolve(this.lastLocaton);

            } catch(e) {
                reject(e);
            }
        })
    }

    onStart = () => {
        this.active = true;
        Object.values(this.subscribers).forEach(({ id, callbacks }) => {
            if(callbacks.onStart && typeof(callbacks.onStart) === 'function') {
                callbacks.onStart();
            }
        })
    }

    onUpdate = (props) => {

        // Layer and Panel subscribers
        Object.values(this.subscribers).forEach(({ id, callbacks }) => {
            if(callbacks.onUpdate && typeof(callbacks.onUpdate) === 'function') {
                callbacks.onUpdate(props);
            }
        });
    }

    onStop = () => {
        this.active = false;
        Object.values(this.subscribers).forEach(({ id, callbacks }) => {
            if(callbacks.onStop && typeof(callbacks.onStop) === 'function') {
                callbacks.onStop(props);
            }
        })
    }

    subscribe = (id, callbacks) => {
        this.subscribers[id] = {
            id: id,
            callbacks: callbacks
        };
        return this;
    }

    unsubscribe = id => {
        delete this.subscribers[id];
        return this;
    }
}
export default LocationManager;
