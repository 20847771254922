import React from 'react';
import Company from 'eCarra/classes/Company.js';

class SubscriptionClass {

    id = null;
    user_id = null;
    plan = null;
    date = null;
    active = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.user_id = props.user_id;
        this.date = props.date;
        this.plan = props.plan ? new SubscriptionPlanClass().create(props.plan) : null;
        this.active = props.active;
        return this;
    }
}

class SubscriptionPlanClass {

    id = null;
    name = null;
    description = null;
    companies = null;
    cost = null;
    billing = null;
    options = null;
    image = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.name = props.name;
        this.description = props.description;
        this.companies = props.companies && props.companies.map(c => Company.create(c));
        this.cost = props.cost;
        this.billing = props.billing;
        this.options = props.options;
        this.image = props.image ? { uri: props.image } : null;
        return this;
    }

    billingToText = prefix => {
        switch(this.billing) {
            case planTypes.daily:
            return `${prefix ? 'per ' : ''}Day`;

            case planTypes.weekly:
            return `${prefix ? 'per ' : ''}Week`;

            case planTypes.biWeekly:
            return `${prefix ? 'every ' : ''}Two Weeks`;

            case planTypes.monthly:
            return `${prefix ? 'per ' : ''}Month`;

            case planTypes.quarterly:
            return `${prefix ? 'every ' : ''}Quarter`;

            case planTypes.yearly:
            return `${prefix ? 'per ' : ''}Year`;
        }
    }
}

const planTypes = {
    daily: 1,
    weekly: 2,
    biWeekly: 3,
    monthly: 4,
    quarterly: 5,
    yearly: 6
}

export default {
    create: props => new SubscriptionClass().create(props),
    Plan: {
        create: props => new SubscriptionPlanClass().create(props),
        types: planTypes
    }
};
