import React, { useRef, useState, useEffect } from 'react';
import { Image, Linking, Platform, Text, TouchableOpacity, View } from 'react-native';

import Appearance from 'eCarra/styles/Appearance.js';
import Layer from 'eCarra/structure/Layer.js';
import Screen from 'eCarra/files/Screen.js';

const WebView = ({ id, title, url, onClose }, { index, options, utils }) => {

    const layerID = id || 'webview';
    const webView = useRef(null);

    const [requestURL, setRequestURL] = useState(url);
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);

    const onOptionPress = () => {
        utils.sheet.show({
            items: [{
                key: 'reload',
                title: `Reload Page`,
                style: 'default'
            },{
                key: 'open',
                title: `Open in New Window`,
                style: 'default'
            }]
        }, key => {
            if(key === 'reload') {
                webView.current.reload();
                return;
            }
            if(key === 'open') {
                setLayerState('close');
                setTimeout(() => {
                    window.open(url);
                }, 500);
            }
        })
    }

    useEffect(() => {
        let request = url || '';
        request += `${request.includes('?') ? '&' : '?'}sat=${Screen.safeArea.top}`;
        request += `&sal=${Screen.safeArea.left}`;
        request += `&sab=${Screen.safeArea.bottom}`;
        request += `&sar=${Screen.safeArea.right}`;
        request += `&theme=${Appearance.themeStyle()}`;
        setRequestURL(request);
    }, [url])

    return (
        <Layer
        id={layerID}
        title={title}
        index={index}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            removePadding: true,
            onCloseLayer: onClose,
            useScrollView: false
        }}>
            <iframe
            height={'100vh'}
            src={requestURL}
            style={{
                display: 'flex',
                height: '100vh',
                width: '100%',
                overflow: 'hidden',
                borderWidth: 0,
                borderColor: Appearance.colors.transparent
            }} />
        </Layer>
    )
}
export default WebView;
