import API from 'eCarra/files/api.js';
let alert_error = new Audio(require('eCarra/sounds/alert_error.m4a'));
let message_sent = new Audio(require('eCarra/sounds/message_sent.m4a'));
let message_received = new Audio(require('eCarra/sounds/message_received.m4a'));
let message_deleted = new Audio(require('eCarra/sounds/message_deleted.m4a'));

export default {
    Alert: {
        Error: alert_error
    },
    Message: {
        Deleted: message_deleted,
        Sent: message_sent,
        Received: message_received
    }
}
