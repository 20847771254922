import React, { useState, useEffect, ReactNode } from 'react';
import { View, Image, TouchableOpacity } from 'react-native';

import Appearance from 'eCarra/styles/Appearance.js';
import Utils from 'eCarra/files/Utils.js';

const LikeButton = ({ active, style, onChange }) => {

    return (
        <TouchableOpacity
        activeOpacity={0.6}
        onPress={onChange}
        style={{
            width: 21,
            height: 21,
            borderRadius: 11,
            overflow: 'hidden',
            backgroundColor: Utils.hexToRGBA(Appearance.colors.red, 0.25),
            padding: 6,
            ...style
        }}>
            <Image source={require('eCarra/images/heart-icon-white-small.png')} style={{
                width: '100%',
                height: '100%',
                resizeMode: 'contain',
                tintColor: active ? Appearance.colors.red : 'white'
            }}/>
        </TouchableOpacity>
    )
}
export default LikeButton;
