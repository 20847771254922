import React from 'react';

import API from 'eCarra/files/api.js';
import Company from 'eCarra/classes/Company';
import NewsChannel from 'eCarra/classes/NewsChannel.js';
import NewsItem from 'eCarra/classes/NewsItem.js';
import Request from 'eCarra/files/Request/';

class NewsCategoryClass {

    id = null;
    title = null;
    description = null;
    channel = null;
    items = null;
    active = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.id = props.id;
        this.title = props.title;
        this.description = props.description;
        this.channel = props.channel ? NewsChannel.create(props.channel) : null;
        this.items = props.items ? props.items.map(i => NewsItem.create(i)) : null;
        this.active = Boolean(props.active);

        return this;
    }

    getItems = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { items } = await Request.get(utils, '/news/', {
                    type: 'items',
                    category_id: this.id,
                    news_channel: this.channel.id
                });
                resolve(items.map(i => NewsItem.create(i)));
            } catch(e) {
                reject(e);
            }
        });
    }
}
export default {
    create: props => new NewsCategoryClass().create(props)
};
