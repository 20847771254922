import React, { useRef, useState, useEffect } from 'react';
import { View, Text, Image, Animated } from 'react-native';

import Appearance from 'eCarra/styles/Appearance';
import AnimatedLoader from 'eCarra/views/AnimatedLoader.js';
import LottieView from 'eCarra/views/Lottie/';

const Loader = ({ children, onComplete, utils, visible }) => {

    const onCompleteRef = useRef(onComplete);
    const [opacity, setOpacity] = useState(new Animated.Value(0));
    const [shouldRender, setShouldRender] = useState(false);

    const onLoadComplete = onComplete => {
        setShouldRender(false);
        utils.events.emit('onLoadComplete');
        if(typeof(onCompleteRef.current) === 'function') {
            onCompleteRef.current();
        }
    }

    useEffect(() => {
        Animated.timing(opacity, {
            toValue: visible ? 0 : 1,
            duration: 500,
            useNativeDriver: false
        }).start()
    }, [shouldRender]);

    useEffect(() => {
        onCompleteRef.current = onComplete;
    }, [onComplete])

    useEffect(() => {
        if(visible) {
            setShouldRender(true);
        }
    }, [visible]);

    return (
        <View style={{
            width: '100%',
            height: '100%'
        }}>
            <Animated.View style={{
                width: '100%',
                height: '100%',
                opacity: opacity
            }}>
                {children}
            </Animated.View>

            {shouldRender && (
                <View style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                }}>
                    <AnimatedLoader
                    loading={visible}
                    onLoadComplete={onLoadComplete.bind(this, onComplete)} />
                </View>
            )}
        </View>
    )
}

export const DotsLoader = ({ loading, style }) => {

    return (
        <View style={{
            alignItems: 'center',
            justifyContent: 'center',
            ...style
        }}>
            {loading
                ?
                <LottieView
                autoPlay={true}
                loop={true}
                source={require('eCarra/files/lottie/dots-white.json')}
                duration={2500}
                style={{
                    width: '100%',
                    height: 25
                }}/>
                :
                null}
        </View>
    )
}

export default Loader;
