class GraphRequest {

    url = null;
    props = null;
    callback = null;

    constructor(url, props, callback) {
        this.url = url;
        this.props = { fields: props.parameters.fields.string };
        this.callback = callback;
        return this;
    }
}

class GraphRequestManager {

    requests = [];

    constructor() {
        return this;
    }

    addRequest = request => {
        this.requests.push(request);
        return this;
    }

    start = () => {
        if(this.requests.length === 0) {
            return;
        }
        this.requests.forEach(request => {
            window.FB.api(request.url, request.props, response => {
                request.callback(response.error, response);
            });
        })
    }
}

export default {
    LoginManager: {
        logInWithPermissions: permissions => {
            return new Promise((resolve, reject) => {
                window.FB.login(({ authResponse }) => {
                    if(!authResponse) {
                        resolve({ isCancelled: true });
                        return;
                    }
                    resolve(authResponse);
                },{
                    scope: permissions.join(','),
                    return_scopes: true
                });
            })
        }
    },
    GraphRequest: GraphRequest,
    GraphRequestManager: GraphRequestManager,
    ShareDialog: {
        canShow: async () => {
            return new Promise(resolve => {
                resolve(true);
            })
        },
        show: async (props, utils) => {
            return new Promise((resolve, reject) => {
                window.FB.ui({
                    app_id: utils.client.get().apps.facebook,
                    method: 'share',
                    href: props.contentUrl,
                    quote: props.contentDescription
                }, response => {
                    if(response && response.error_message) {
                        reject(response.error_message);
                        return;
                    }
                    resolve();
                });
            })
        }
    }
}
