import React from 'react';
const LinearGradient = ({ children, colors, style }) => {
    const getGradient = () => {
        return `linear-gradient(180deg, ${colors.join(', ')})`;
    }
    return (
        <div style={{
            width: '100%',
            background: getGradient(),
            ...style
        }}>
            {children}
        </div>
    )
}
export default LinearGradient;
