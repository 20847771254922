import React, { useEffect, useState } from 'react';
import { ScrollView, Text, View } from 'react-native';

import Appearance from 'eCarra/styles/Appearance.js';
import AddNewCard from 'eCarra/views/AddNewCard.js';
import ProgressBar from 'eCarra/views/ProgressBar/';
import Screen from 'eCarra/files/Screen.js';
import TextField from 'eCarra/views/TextField.js';
import Utils from 'eCarra/files/Utils';
import Views from 'eCarra/views/Main.js';

const PaymentMethodManager = ({ defaultCardID, utils, onChange, style }) => {

    const [customerMethods, setCustomerMethods] = useState(null);
    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState([]);
    const [selected, setSelected] = useState(null);
    const [userMethods, setUserMethods] = useState(null);

    const fetchMethods = async () => {
        try {
            let { methods } = await utils.user.get().getPaymentMethods(utils);
            setLoading(false);
            setUserMethods(methods || []);
            (methods || []).forEach(m => {
                if(defaultCardID === m.id) {
                    setSelected(m);
                }
            });

        } catch(e) {
            console.error(e.message);
        }
    }

    const onAddCard = () => {
        utils.layer.open({
            id: 'add-new-card',
            Component: AddNewCard.bind(this, {
                onAddCard: fetchMethods
            })
        })
    }

    const onPress = method => {
        setSelected(method);
        if(typeof(onChange) === 'function') {
            onChange({
                method: method,
                card_id: method.id
             });
        }
    }

    useEffect(() => {
        fetchMethods();
    }, [])

    return (
        <View style={{
            position: 'relative',
            width: '100%',
            ...style
        }}>
            <View style={{
                ...Appearance.styles.panel(),
                overflow: 'hidden'
            }}>
                <ScrollView
                showsVerticalScrollIndicator={false}
                style={{
                    width: '100%',
                    maxHeight: 54 * 3.5
                }}>
                    {userMethods && userMethods.length > 0
                        ?
                        <>
                        {userMethods.map((method, index, methods) => {
                            return Views.entry({
                                key: method.id,
                                title: method.type(),
                                subTitle: method.subType(),
                                icon: {
                                    style: {
                                        ...Appearance.icons.standard,
                                        backgroundColor: selected && selected.id === method.id ? Appearance.colors.primary() : Appearance.colors.grey()
                                    },
                                    path: require('eCarra/images/payment-icon-clear-small.png')
                                },
                                bottomBorder: index !== methods.length - 1,
                                onPress: onPress.bind(this, method)
                            })
                        })}
                        {Views.entry({
                            title: 'New Payment Method',
                            subTitle: 'Tap to add a credit or debit card...',
                            icon: {
                                style: {
                                    ...Appearance.icons.standard,
                                    backgroundColor: Appearance.colors.grey()
                                },
                                path: require('eCarra/images/payment-icon-clear-small.png')
                            },
                            bottomBorder: false,
                            onPress: onAddCard
                        })}
                        </>
                        :
                        Views.entry({
                            title: loading ? 'Loading Payment Methods...' : 'No Cards Found',
                            subTitle: loading ? 'This may take a moment' : 'Tap to add your first card...',
                            icon: {
                                style: {
                                    ...Appearance.icons.standard,
                                    backgroundColor: Appearance.colors.primary()
                                },
                                path: require('eCarra/images/payment-icon-clear-small.png')
                            },
                            bottomBorder: false,
                            onPress: onAddCard
                        })
                    }
                    </ScrollView>
                <ProgressBar animate={loading} />
            </View>
        </View>
    )
}
export default PaymentMethodManager;
