import React, { useEffect, useRef, useState } from 'react';
import { Keyboard, Platform, Text, View } from 'react-native';

import API from 'eCarra/files/api.js';
import Appearance from 'eCarra/styles/Appearance';
import Layer from 'eCarra/structure/Layer.js';
import Request from 'eCarra/files/Request/';
import Screen from 'eCarra/files/Screen';
import Stripe, { PaymentCardTextField } from 'eCarra/files/Stripe/';
import Utils from 'eCarra/files/Utils.js';

const AddNewCard = ({ onAddCard }, { index, options, utils }) => {

    const layerID = 'add-new-card';
    const token = useRef(null);

    const [isValid, setIsValid] = useState(false);
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const [card, setCard] = useState({
        number: null,
        cvc: null,
        expiration: {
            month: null,
            year: null
        }
    })

    const onCancelPress = async () => {
        try {
            Keyboard.dismiss();
            await Utils.sleep(keyboardOpen ? 0.5 : 0);
            setLayerState('close');
        } catch(e) {
            console.error(e.message);
        }
    }

    const onConfirmPress = async () => {
        try {
            // start loading and dismiss keyboard is applicable
            setLoading(true);
            Keyboard.dismiss();

            // generate token if not already present
            // token is automaticaly generated on web but not for native
            if(!token.current) {
                if(!card.number || !card.expiration.month || !card.expiration.year || !card.cvc) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: 'Please fill out your card number, expiration date, and security code before moving on'
                    })
                    return;
                }
                token.current = await Stripe.createTokenWithCard({
                    number: card.number,
                    expMonth: card.expiration.month,
                    expYear: card.expiration.year,
                    cvc: card.cvc
                })
            }

            // send token details to server
            let customer = utils.user.get();
            let { stripe_id, stripe_data } = await Request.post(utils, '/payment/', {
                ...customer.stripe_customer_id && {
                    type: 'add_card',
                    stripe_id: customer.stripe_customer_id
                },
                ...!customer.stripe_customer_id && {
                    type: 'new_customer',
                    user_id: customer.user_id,
                    email_address: customer.email_address,
                    full_name: customer.full_name
                },
                ...Platform.OS !== 'web' && {
                    token: token.current.tokenId,
                },
                ...Platform.OS === 'web' && {
                    token: token.current.id,
                    source_id: token.current.card.id,
                }
            });

            // update user at app root
            utils.user.set({
                stripe_customer_id: stripe_id,
                stripe_customer_data: stripe_data
            })

            // end loading and notify listeners
            setLoading(false);
            setLayerState('close');
            if(typeof(onAddCard) === 'function') {
                onAddCard();
            }

        } catch(e) {
            Keyboard.dismiss();
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating your payment methods. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onTextChange = (valid, data) => {
        setCard({
            number: data.number,
            cvc: data.cvc,
            expiration: {
                month: data.expMonth,
                year: data.expYear
            }
        })
    }

    const onTokenError = error => {
        utils.alert.show({
            title: 'Oops!',
            message: `There was an issue preparing your card information. ${e.message || 'An unknown error occurred'}`
        })
    }

    const onTokenChange = val => {
        token.current = val;
    }

    useEffect(() => {
        utils.keyboard.subscribe(layerID, {
            onVisibility: visible => setKeyboardOpen(visible)
        })
        return () => {
            utils.keyboard.unsubscribe(layerID);
        }
    });

    return (
        <Layer
        id={layerID}
        title={'Add New Card'}
        index={index}
        utils={utils}
        options={{
            ...options,
            bottomCard: true,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Cancel',
            color: 'grey',
            onPress: onCancelPress
        },{
            key: 'done',
            text: 'Done',
            color: 'primary',
            loading: loading,
            onPress: onConfirmPress
        }]}>

            <View style={{
                alignItems: 'center',
                marginTop: 5
            }}>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 6
                }}>{'Add New Card'}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 20,
                    textAlign: 'center'
                }}>{'You can add a new card to your account below. You\'ll need the card number, expiration date, and security code on the back.'}</Text>

                <PaymentCardTextField
                accessible={false}
                setEnabled={false}
                onParamsChange={onTextChange}
                onTokenChange={onTokenChange}
                onTokenError={onTokenError}
                placeholderColor={Appearance.colors.subText()}
                numberPlaceholder={'4242 4242 4242 4242'}
                expirationPlaceholder={'MM/YY'}
                cvcPlaceholder={'CVC'}
                style={{
                    height: 40,
                    width: '100%',
                    paddingLeft: 10,
                    paddingRight: 10,
                    textAlign: 'center',
                    color: Appearance.colors.text(),
                    fontSize: 14,
                    backgroundColor: Appearance.colors.textField(),
                    borderRadius: 8
                }} />
            </View>
        </Layer>
    )
}

export default AddNewCard;
