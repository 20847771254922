import React, { useRef, useState, useEffect, useContext } from 'react';
import { Animated, FlatList, Image, Keyboard, PanResponder, Platform, ScrollView, Text, TouchableWithoutFeedback, View } from 'react-native';

import { animated, useSpring } from '@react-spring/native';
import moment from 'moment';
import update from 'immutability-helper';

import AddNewCard from 'eCarra/views/AddNewCard.js';
import Appearance from 'eCarra/styles/Appearance.js';
import Button from 'eCarra/views/Button.js';
import Carousel from 'react-native-snap-carousel';
import { CreditsMethodInvoice, DriverTip, PaymentDetails, PaymentMethodInvoice, SubscriptionDetails, SubscriptionPicker } from 'eCarra/managers/Payments.js';
import { CustomerVehiclesDeepLink, VehicleOnboarding } from 'eCarra/managers/Vehicles.js';
import { DriverOnboarding } from 'eCarra/managers/Users.js';
import { DriverTimeCards } from 'eCarra/managers/Settings.js';
import { FriendsAndFamily, ReservationDetails } from 'eCarra/managers/Reservations.js';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import KeyboardManager from 'eCarra/files/KeyboardManager.js';
import LayerDropDown from 'eCarra/views/LayerDropDown.js';
import { LineChart } from 'react-native-chart-kit';
import LottieView from 'eCarra/views/Lottie/';
import { MobilePageControl } from 'eCarra/structure/Panel.js';
import { NewQuickScanRoute, NewQuickScanCustomerRoute, RouteDetails } from 'eCarra/managers/Routes.js';
import { OrderDetails } from 'eCarra/managers/Orders.js';
import Screen, { isMobile } from 'eCarra/files/Screen.js';
import { ShareContent } from 'eCarra/managers/Emissions.js';
import TextField from 'eCarra/views/TextField.js';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import Utils from 'eCarra/files/Utils.js';
import Views, { AltBadge } from 'eCarra/views/Main.js';
import WebView from 'eCarra/views/WebView/';

export const FrontLayerIndex = LayerEndIndex + 10;
export const FloatingLayerMinimizedHeight = (60 + Screen.safeArea.bottom);
export const LayerEndIndex = 4990;
export const LayerSpacing = 15;
export const TopSpacing = Screen.safeArea.top + 15;
export const LayerToolbarHeight = 60;
export const LayerHeaderSpacing = {
    get: () => {
        return Platform.OS === 'web' || isMobile() === false ? 52 : 40;
    }
}

const Layer = ({ buttons, children, contentStyle, headerItems, index, id, title, options = {}, position, abstract, style, utils }) => {

    const { beforeCloseOnTap, bottomCard, canCloseOnTap, closeButton, flatList, keyboard, layerState, loading, onClose, onCloseAttempt, onCloseLayer, onHeightChange, onScroll, order, removePadding, removeSafeArea, removeScrollView, stackButtons, sticky, useFlatList, useTouchableForKeyboard, webView } = options;

    const layerRef = useRef(null);
    const ref = useRef(null);
    const scrollView = useRef(null);
    const startIndex = Screen.safeArea.top + 15 + (index * LayerSpacing);

    const [animate, setAnimate] = useState(null);
    const [bottom, setBottom] = useState(new Animated.Value(bottomCard === true ? -Screen.height() : 0));
    const [bottomCardAnimations, setBottomCardAnimations] = useSpring(() => ({
        bottom: bottomCard ? -(Screen.height() / 2) : 0,
        config: { mass: 1, tension: 180, friction: 22 }
    }))
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const [layerAnimations, setLayerAnimations] = useSpring(() => ({
        bottom: 0,
        top: Screen.height(),
        config: { mass: 1, tension: 180, friction: 16 }
    }))
    const [layerPosition, setLayerPosition] = useState(position);
    const [onHover, setOnHover] = useState(null);
    const [opacity, setOpacity] = useState(new Animated.Value(0));
    const [scale, setScale] = useState(new Animated.Value(1));
    const [scrollViewPadding, setScrollViewPadding] = useState(0);
    const [top, setTop] = useState(new Animated.Value(Screen.height()));
    const [windowState, setWindowState] = useState({ action: null, frame: null });

    const onBackgroundPress = () => {
        if(keyboardOpen) {
            Keyboard.dismiss();
            return
        }
        if(canCloseOnTap === false) {
            return;
        }
        if(typeof(beforeCloseOnTap) === 'function') {
            beforeCloseOnTap(() => onWindowActionChange('close'))
            return;
        }
        onWindowActionChange('close');
    }

    const onLayerActionEvent = props => {
        if(props && props.layerID === id) {
            onWindowActionChange(props.action);
        }
    }

    const onLayerStateChange = async () => {
        switch(layerState) {
            case 'show':
            Animated.timing(opacity, {
                toValue: 1,
                duration: 500,
                useNativeDriver: false
            }).start();
            onUpdateAnimations({
                top: startIndex,
                bottom: 0
            });
            break;

            case 'hide':
            case 'close':
            if(keyboardOpen) {
                Keyboard.dismiss();
                await Utils.sleep(0.35);
            }
            Animated.timing(opacity, {
                toValue: 0,
                duration: 500,
                useNativeDriver: false
            }).start();
            onUpdateAnimations({
                top: Screen.height(),
                bottom: -Screen.height()
            });
            onRunCloseActions();
            break;
        }
    }

    const onMeasureView = evt => {
        let { layout } = evt.nativeEvent || {};
        let height = layout.height || 0;
        if(typeof(onHeightChange) === 'function') {
            onHeightChange(height);
        }
    }

    const onRunCloseActions = async () => {
        try {
            // allow time for previous logic to complete
            await Utils.sleep(0.35);

            // check for callback on the application level
            if(typeof(onClose) === 'function') {
                onClose(id);
            }
            // check for callback on the layer level
            if(typeof(onCloseLayer) === 'function') {
                onCloseLayer();
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    const onRunAnimations = async () => {
        try {
            // stop animations from starting if layer is closing
            if(layerState === 'close') {
                console.log('animations stopped');
                return;
            }
            //check that ref has posted before moving forward
            await Utils.sleep(0.25);
            if(!layerRef.current) {
                onRunAnimations();
                return;
            }
            // bottom cards are vertically centered if platform is web
            let bottom = 0;
            if(Platform.OS === 'web' && Utils.isMobile() === false) {
                bottom = (window.innerHeight / 2) - (layerRef.current.clientHeight / 2);
            }
            // animate into view
            Animated.timing(opacity, {
                toValue: 1,
                duration: 500,
                useNativeDriver: false
            }).start();
            onUpdateAnimations({
                top: startIndex,
                bottom: bottom
            });

        } catch(e) {
            console.error(e.message);
        }
    }

    const onUpdateAnimations = ({ top, bottom }) => {

        // web and tablet
        if(Platform.OS === 'web' || Utils.isMobile() === false) {
            if(bottomCard) {
                setBottomCardAnimations({ bottom: bottom === 0 ? (Screen.safeArea.bottom + 15) : bottom });
                return;
            }
            setLayerAnimations({
                top: top,
                bottom: top === startIndex ? (Screen.safeArea.bottom + 15) : 0
            });
            return;
        }

        // mobile devices
        if(bottomCard) {
            setBottomCardAnimations({ bottom: bottom });
            return;
        }
        setLayerAnimations({ top: top });
    }

    const onWindowActionChange = async action => {
        if(action === 'close') {
            if(keyboardOpen) {
                Keyboard.dismiss();
                await Utils.sleep(0.35);
            }
            Animated.timing(opacity, {
                toValue: 0,
                duration: 500,
                useNativeDriver: false
            }).start();
            onUpdateAnimations({
                top: Screen.height(),
                bottom: -Screen.height()
            });
            onRunCloseActions();
        }
        setWindowState(windowState => update(windowState, {
            action: {
                $set: action === windowState.action ? null : action
            }
        }));
    }

    const getAnimations = () => {
        return bottomCard ? bottomCardAnimations : layerAnimations;
    }

    const getButtons = () => {
        if(!buttons || buttons.length === 0) {
            return null;
        }
        return (
            <View style={{
                padding: 15,
                flexDirection: stackButtons ? 'column' : 'row',
                width: '100%',
                marginBottom: Utils.isMobile() === true ? Screen.safeArea.bottom : 0,
                ...utils.layer.isFloatingLayerOpen() && {
                    marginBottom: FloatingLayerMinimizedHeight
                }
            }}>
                {buttons.filter(b => {
                    return b.visible !== false;
                }).map((button, index, buttons) => {
                    return (
                        <View
                        key={index}
                        style={{
                            width: stackButtons ? '100%' : `${parseInt(100 / buttons.length)}%`,
                            paddingRight: buttons.length > 1 && stackButtons !== true ? 4 : 0,
                            paddingLeft: buttons.length > 1 && stackButtons !== true ? 4 : 0,
                            marginBottom:stackButtons && index !== buttons.length - 1 ? 8 : 0
                        }}>
                            <Button
                            label={button.text}
                            type={'large'}
                            loading={button.loading || loading === button.key}
                            {...button} />
                        </View>
                    )
                })}
            </View>
        )
    }

    const getContent = () => {
        if(useFlatList) {
            return (
                <>
                <TouchableWithoutFeedback
                onPress={Keyboard.dismiss}>
                    <View style={{
                        width: '100%'
                    }}>
                        <View style={{
                            padding: removePadding ? 0 : 15,
                            ...contentStyle
                        }}>
                            {children}
                        </View>
                    </View>
                </TouchableWithoutFeedback>
                <FlatList
                {...flatList}
                showsVerticalScrollIndicator={false}
                onScroll={onScroll}
                scrollEventThrottle={onScroll ? 1 : null}
                style={{
                    flexGrow: 1,
                    width: '100%',
                    ...flatList.style
                }}
                contentContainerStyle={{
                    paddingBottom: buttons && buttons.length > 0 || removeSafeArea === true ? 0 : Screen.safeArea.bottom
                }} />
                </>
            )
        }

        if(bottomCard || removeScrollView) {
            return (
                <View style={{
                    flexGrow: 1,
                    width: '100%',
                    padding: removePadding ? 0 : 15,
                    marginBottom: buttons && buttons.length > 0 || removeSafeArea === true ? 0 : Screen.safeArea.bottom,
                    ...Platform.OS === 'web' && {
                        paddingTop: 55
                    },
                    ...contentStyle
                }}>
                    {children}
                </View>
            )
        }

        return (
            <KeyboardAwareScrollView
            ref={scrollView}
            extraHeight={15}
            enableOnAndroid={true}
            onScroll={onScroll}
            scrollEventThrottle={onScroll ? 1 : null}
            scrollEnabled={webView !== true && bottomCard !== true}
            keyboardShouldPersistTaps={'always'}
            contentContainerStyle={{
                paddingBottom: buttons && buttons.length > 0 || removeSafeArea === true ? 0 : Screen.safeArea.bottom + 15
            }}
            style={{
                flexGrow: 1,
                width: '100%',
                ...bottomCard && Platform.OS === 'web' && {
                    paddingTop: 5
                }
            }}>
                {getScrollComponents()}
            </KeyboardAwareScrollView>
        )
    }

    const getLayerWidth = () => {
        // return standard, max size for all layers if platform is web or device is a tablet
        if(Platform.OS === 'web' || Utils.isMobile() === false) {
            return bottomCard === true ? Screen.layer.maxWidth - 60 : Screen.layer.maxWidth;
        }
        // mobile devices use the full width of the display for each layer
        return Screen.width();
    }

    const getPanResponderProps = PanResponder.create({
        onMoveShouldSetPanResponder: () => true,
        onPanResponderMove: (evt, { moveY }) => {
            if(keyboardOpen) {
                Keyboard.dismiss()
            }
            if(moveY < Screen.safeArea.top) {
                onUpdateAnimations({ top: Screen.safeArea.top });
                return true;
            }
            let opVal = 1 - (((100 / Screen.height()) * moveY) / 100);
            opacity.setValue(opVal);
            onUpdateAnimations({ top: moveY });
        },
        onPanResponderTerminationRequest: () => true,
        onPanResponderRelease: (evt, { moveY }) => {
            if(moveY < Screen.height() / 3) {
                Animated.timing(opacity, {
                    toValue: 1,
                    duration: 250,
                    useNativeDriver: false
                }).start();
                onUpdateAnimations({ top: startIndex });
                return;
            }
            onWindowActionChange('close');
        }
    });

    const getScrollComponents = () => {

        // touch through not supported on android
        if(Platform.OS === 'android' || useTouchableForKeyboard === false) {
            return (
                <View style={{
                    width: '100%'
                }}>
                    <View style={{
                        padding: removePadding ? 0 : 15,
                        paddingBottom: 0,
                        ...contentStyle
                    }}>
                        {children}
                    </View>
                </View>
            )
        }
        return (
            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                <View style={{
                    width: '100%'
                }}>
                    <View style={{
                        padding: removePadding ? 0 : 15,
                        paddingBottom: 0,
                        ...contentStyle
                    }}>
                        {children}
                    </View>
                </View>
            </TouchableWithoutFeedback>
        )
    }

    const getToolbarItems = () => {
        if(closeButton === true || Platform.OS === 'web') {
            return (
                <View style={{
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    top: 0,
                    right: 0,
                    left: 0,
                    padding: 12,
                    width: '100%',
                    marginBottom: 12,
                    backgroundColor: Appearance.colors.layerBackground()
                }}>
                    <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={onWindowActionChange.bind(this, 'close')}>
                        <AltBadge content={{
                            text: 'Close',
                            color: Appearance.colors.grey()
                        }} />
                    </TouchableOpacity>
                </View>

            )
        }
        if(!headerItems && bottomCard !== true) {
            return (
                <View
                {...getPanResponderProps.panHandlers}
                style={{
                    position: 'absolute',
                    top: 0,
                    height: 34,
                    flexDirection: 'row',
                    alignItems: 'flex-start'
                }}>
                    <Image
                    source={require('eCarra/images/notch-curve-left.png')}
                    style={{
                        width: 10,
                        height: 10,
                        resizeMode: 'contain',
                        tintColor: Appearance.colors.background()
                    }} />
                    <View style={{
                        padding: 15,
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                        backgroundColor: Appearance.colors.background()
                    }}>
                        <Image
                        source={require('eCarra/images/pan-gesture-anchor.png')}
                        style={{
                            width: 45,
                            height: 9,
                            resizeMode: 'contain',
                            tintColor: Appearance.colors.grey()
                        }} />
                    </View>
                    <Image
                    source={require('eCarra/images/notch-curve-right.png')}
                    style={{
                        width: 10,
                        height: 10,
                        resizeMode: 'contain',
                        tintColor: Appearance.colors.background()
                    }} />
                </View>
            )
        }
        return null;
    }

    useEffect(() => {
        setLayerPosition(position);
    }, [position]);

    useEffect(() => {
        onLayerStateChange();
    }, [layerState]);

    useEffect(() => {
        onRunAnimations();
        utils.events.addListener('onLayerAction', onLayerActionEvent);
        return () => {
            setAnimate(null);
            utils.events.removeListener('onLayerAction', onLayerActionEvent);
        }

    }, []);

    useEffect(() => {

        // scroll to active text input or text view offset if keyboard refs are present
        let refs = options.keyboard ? options.keyboard.refs : [];
        refs.forEach(ref => {
            if(ref.current && ref.current.props && ref.current.props.setOnFocusEvent) {
                ref.current.props.setOnFocusEvent(nativeEvent => {

                    // Measure for page Y offset
                    ref.current.measure((x, y, width, height, pageX, pageY) => {
                        scrollView.current.scrollTo({
                            y: pageY,
                            animated: true
                        });
                    })
                })
            }
        })

        // setup keyboard change listeners
        utils.keyboard.subscribe(`${id}-layer`, {
            onVisibility: visible => setKeyboardOpen(visible),
            onShow: e => {
                if(options.bottomCard === true) {
                    if(Utils.isMobile() === false) {
                        onUpdateAnimations({ bottom: e.endCoordinates.height + 15 });
                        return;
                    }
                    onUpdateAnimations({ bottom: e.endCoordinates.height - Screen.safeArea.bottom });
                }
            },
            onHide: () => {
                if(options.bottomCard === true) {
                    onUpdateAnimations({ bottom: 0 });
                }
            }
        }, refs);

        return () => {
            utils.keyboard.unsubscribe(`${id}-layer`);
        }

    }, [keyboard])

    useEffect(() => {
        if(typeof(animate) === 'function') {
            animate();
        }
    }, [animate]);

    return (
        <>
        {/* touchable requires at least one child */}
        {bottomCard === true && (
            <TouchableWithoutFeedback
            onPress={onBackgroundPress}>
                <Animated.View style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    height: '100%',
                    opacity: opacity,
                    backgroundColor: Appearance.colors.dim
                }}/>
            </TouchableWithoutFeedback>
        )}
        <animated.View
        ref={layerRef}
        collapsable={false}
        onLayout={onMeasureView}
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'absolute',
            width: '100%',
            maxWidth: getLayerWidth(),
            backgroundColor: Appearance.colors.background(),
            overflow: 'hidden',
            borderTopRightRadius: 15,
            borderTopLeftRadius: 15,
            ...(Platform.OS === 'web' || Utils.isMobile() === false) && {
                borderBottomRightRadius: 15,
                borderBottomLeftRadius: 15,
            },
            ...Appearance.boxShadow({
                outer: {
                    offset: {
                        width: 2.5,
                        height: 2.5
                    }
                }
            }),
            ...getAnimations(),
            ...style
        }}>
            {headerItems}
            {sticky && sticky.top}
            {getContent()}
            {getToolbarItems()}
            {sticky && sticky.bottom}
            {getButtons()}
        </animated.View>
        </>
    )
}

export const LayerToolbar = ({ title, subTitle, middleContent, floatingLayerState, onFloatingLayerStateChange, onClose, onLeftPress, style }) => {

    return (
        <TouchableOpacity
        activeOpacity={floatingLayerState === 'open' ? 1 : 0.6}
        onPress={floatingLayerState === 'open' ? null : () => {
            if(typeof(onFloatingLayerStateChange) === 'function') {
                onFloatingLayerStateChange(floatingLayerState === 'open' ? 'minimized' : 'open')
            }
        }}
        style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            minHeight: LayerToolbarHeight,
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingHorizontal: 12,
            borderBottomColor: Appearance.colors.divider(),
            borderBottomWidth: 1,
            ...style
        }}>
            {onLeftPress
                ?
                <TouchableOpacity
                activeOpacity={0.6}
                onPress={onLeftPress}
                style={{
                    width: 25,
                    height: 25,
                    padding: 3,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={false}
                    duration={2500}
                    source={Appearance.themeStyle() === 'dark' ? require('eCarra/files/lottie/vertical-details-white.json') : require('eCarra/files/lottie/vertical-details-grey.json')}
                    style={{
                        width: '100%',
                        height: '100%'
                    }}/>
                </TouchableOpacity>
                :
                <TouchableOpacity
                activeOpacity={0.6}
                onPress={() => onFloatingLayerStateChange(floatingLayerState === 'open' ? 'minimized' : 'open')}
                style={{
                    width: 25,
                    height: 25,
                    padding: 3,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Image
                    source={floatingLayerState === 'open' ? require('eCarra/images/down-arrow-grey-small.png') : require('eCarra/images/up-arrow-grey-small.png')}
                    style={{
                        width: '100%',
                        height: '100%',
                        resizeMode: 'contain',
                        tintColor: Appearance.colors.text()
                    }} />
                </TouchableOpacity>
            }

            <View style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'center',
                paddingHorizontal: 12
            }}>
                {middleContent || (typeof(title) === 'string' && (
                    <Text style={{
                        ...Appearance.textStyles.panelTitle(),
                        textAlign: 'center'
                    }}>{title}</Text>
                ))}

                {typeof(subTitle) === 'string' && (
                    <Text
                    numberOfLines={1}
                    style={{
                        ...Appearance.textStyles.subTitle(),
                        textAlign: 'center',
                        marginTop: 2
                    }}>{subTitle}</Text>
                )}
            </View>

            <TouchableOpacity
            onPress={onClose}
            activeOpacity={0.6}
            style={{
                width: 25,
                height: 25,
                padding: 3,
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Image
                source={Appearance.themeStyle() === 'dark' ? require('eCarra/images/x-icon-white-small.png') : require('eCarra/images/x-icon-grey-small.png')}
                style={{
                    width: '100%',
                    height: '100%',
                    tintColor: Appearance.colors.text()
                }}/>
            </TouchableOpacity>
        </TouchableOpacity>
    )
}

export const FloatingLayers = ({ children, utils, options, style }) => {

    const scrollRef = useRef(null);
    const [animate, setAnimate] = useState(null);
    const [bottom, setBottom] = useState(new Animated.Value(-Screen.height()));
    const [floatingLayerState, setFloatingLayerState] = useState('minimized');
    const [layerState, setLayerState] = useState(null);
    const [onHover, setOnHover] = useState(null);
    const [opacity, setOpacity] = useState(new Animated.Value(0));
    const [scrollOffset, setScrollOffset] = useState(0);
    const [showDimView, setShowDimView] = useState(false);

    const onCloseLayer = async layerID => {

        // minimize last layer
        if(getChildrenLength() === 1 && floatingLayerState === 'open') {
            setFloatingLayerState('minimized');
            await Utils.sleep(0.5);
            Animated.timing(opacity, {
                toValue: 0,
                duration: 500,
                useNativeDriver: false
            }).start(() => {
                Animated.timing(bottom, {
                    toValue: -Screen.height(),
                    duration: 150,
                    useNativeDriver: false
                }).start(() => {
                    utils.layer.closeFloating(layerID);
                });
            });
            return;
        }

        // Find current layer, filter out non-visible layers, and set index
        let index = null;
        React.Children.map(children, child => {
            return child;
        }).filter(child => {
            return child.props && child.props.options.visible !== false;
        }).forEach((child, i) => {
            if(child.type.layerID === layerID) {
                index = i;
            }
        });

        // scroll to previous layer
        if(Platform.OS === 'web' || Utils.isMobile() === false) {
            scrollRef.current.snapToItem(index);
        } else {
            scrollRef.current.scrollTo({
                animated: true,
                x: Screen.width() * (index === 0 ? (index + 1) : (index - 1))
            });
        }

        // Close current layer
        setTimeout(() => {
            utils.layer.closeFloating(layerID);
        }, 250)
    }

    const onFloatingLayerStateChange = (state) => {
        if(state !== floatingLayerState) {
            setFloatingLayerState(state);
        }
    }

    const onLayerEvent = ({ layerID, event, scrollToIndex }) => {

        switch(event) {
            case 'close':
            onCloseLayer(layerID);
            break;

            case 'scrollTo':
            if(floatingLayerState === 'minimized') {
                setFloatingLayerState('open');
            }
            setTimeout(() => {
                if(Platform.OS === 'web' || Utils.isMobile() === false) {
                    scrollRef.current.snapToItem(scrollToIndex);
                } else {
                    scrollRef.current.scrollTo({
                        animated: true,
                        x: Screen.width() * (scrollToIndex || 0)
                    });
                }
            }, floatingLayerState === 'minimized' ? 250 : 0)
            break;
        }
    }

    const onLayerStateEvent = props => {
        setLayerState(props.action);
    }

    const onLayerStatus = ({ layerID, index }) => {
        // return whether or not the floating layer is front and center
        utils.events.emit('onFloatingLayerStatus', {
            visible: (scrollOffset / Screen.width()) === index && floatingLayerState === 'open'
        });
    }

    const onRunBackgroundAnimations = async () => {
        try {
            if(floatingLayerState === 'open') {
                setShowDimView(true);
                await Utils.sleep(0.25);
            }
            Animated.timing(opacity, {
                toValue: floatingLayerState === 'open' ? 1 : 0,
                duration: 500,
                useNativeDriver: false
            }).start(() => {
                setShowDimView(floatingLayerState === 'open');
            });
        } catch(e) {
            console.error(e.message);
        }
    }

    const onRunLayerAnimations = async () => {
        try {
            await Utils.sleep(0.25);
            Animated.spring(bottom, {
                toValue: 0,
                duration: 150,
                friction: 10,
                useNativeDriver: false
            }).start();
        } catch(e) {
            console.error(e.message);
        }
    }

    const getChildrenLength = () => {
        return children.filter(child => child.props && child.props.options.visible !== false).length
    }

    const getContent = () => {

        if(Platform.OS === 'web' || Utils.isMobile() === false) {
            let items = React.Children.map(children, child => child);
            return (
                <Carousel
                ref={ref => scrollRef.current = ref}
                data={items}
                useScrollView={true}
                hasParallaxImages={false}
                sliderWidth={Screen.width() - 30}
                sliderHeight={Screen.height() - 30}
                inactiveSlideOpacity={1}
                inactiveSlideScale={1}
                itemWidth={Screen.layer.maxWidth}
                renderItem={({ item, index }) => {

                    let child = item;
                    if(child.props && child.props.options.visible === false) {
                        return null;  // don't filter at root so layers don't render on visiblity change
                    }
                    return (
                        <View style={{
                            ...Appearance.styles.panel(),
                            position: 'relative',
                            overflow: 'hidden',
                            width: Screen.layer.maxWidth - 30,
                            marginLeft: 15
                        }}>
                            {React.cloneElement(child, {
                                closeLayer: onCloseLayer,
                                floatingLayerState: floatingLayerState,
                                setFloatingLayerState: onFloatingLayerStateChange
                            })}
                        </View>
                    )
                }} />
            )
        }

        return (
            <ScrollView
            ref={scrollRef}
            pagingEnabled={true}
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            scrollEnabled={getChildrenLength() > 1}
            keyboardShouldPersistTaps={'always'}
            scrollEventThrottle={1}
            onScroll={({ nativeEvent }) => {
                Keyboard.dismiss();
                setScrollOffset(nativeEvent.contentOffset.x);
            }}
            style={{
                flexGrow: 1,
                width: Screen.width()
            }}>
                {React.Children.map(children, (child, index) => {
                    if(child.props && child.props.options.visible === false) {
                        return null;  // don't filter at root so layers don't render on visiblity change
                    }
                    return (
                        <View style={{
                            position: 'relative',
                            width: Screen.layer.maxWidth,
                            borderTopLeftRadius: 15,
                            borderTopRightRadius: 15,
                            overflow: 'hidden',
                            borderRightWidth: getChildrenLength() - 1 ? 1:0,
                            borderRightColor: Appearance.colors.divider()
                        }}>
                            {React.cloneElement(child, {
                                closeLayer: onCloseLayer,
                                floatingLayerState: floatingLayerState,
                                setFloatingLayerState: onFloatingLayerStateChange
                            })}
                        </View>
                    )
                })}
            </ScrollView>
        )
    }

    useEffect(() => {

        // scroll to most recent layer
        if(Platform.OS === 'web' || Utils.isMobile() === false) {
            scrollRef.current.snapToItem(children.length - 1);
        } else {
            scrollRef.current.scrollToEnd({ animated: true });
        }

        // layer action events
        utils.events.addListener('onFloatingLayerEvent', onLayerEvent);
        utils.events.addListener('onFloatingLayerAction', onLayerStateEvent);

        // run layer animations
        onRunLayerAnimations();

        return () => {
            setAnimate(null);
            utils.events.removeListener('onFloatingLayerEvent', onLayerEvent);
            utils.events.removeListener('onFloatingLayerAction', onLayerStateEvent);
        }

    }, [children]);


    useEffect(() => {

        // run animations
        onRunBackgroundAnimations();

        // reset listeners
        // required for updated state data for events
        utils.events.addListener('getFloatingLayerStatus', onLayerStatus);
        return () => {
            utils.events.removeListener('getFloatingLayerStatus', onLayerStatus);
        }
    }, [scrollOffset, floatingLayerState])

    useEffect(() => {
        switch(layerState) {

            case 'addLayer':
            if(Platform.OS === 'web' || Utils.isMobile() === false) {
                scrollRef.current.snapToItem(children.length - 1);
            } else {
                scrollRef.current.scrollToEnd({ animated: true });
            }
            break;

            case 'close':
            Animated.timing(opacity, {
                toValue: 0,
                duration: 500,
                useNativeDriver: false
            }).start(() => {
                Animated.spring(bottom, {
                    toValue: -(Screen.height() + FloatingLayerMinimizedHeight),
                    duration: 500,
                    useNativeDriver: false
                }).start(() => {
                    if(options && options.onClose && typeof(options.onClose) === 'function') {
                        options.onClose(id);
                    }
                });
            });
            break;
        }

    }, [layerState])

    useEffect(() => {
        if(typeof(animate) === 'function') {
            animate();
        }
    }, [animate]);

    return (
        <>
        {showDimView && (
            <Animated.View style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                opacity: opacity,
                backgroundColor: Appearance.colors.dim
            }} />
        )}
        <Animated.View style={{
            position: 'absolute',
            bottom: bottom,
            width: Screen.width(),
            backgroundColor: Appearance.colors.transparent,
            ...(Platform.OS === 'web' || Utils.isMobile() === false) && {
                padding: 15
            },
            ...style
        }}>
            {getContent()}
        </Animated.View>
        </>
    )
}

export const LayerShell = ({ layerID, buttons, children, title, extendedOptions }, { index, utils, options }) => {

    return (
        <Layer
        id={layerID}
        title={title}
        utils={utils}
        index={index}
        buttons={buttons}
        options={{
            ...options,
            ...extendedOptions
        }}>
            {children}
        </Layer>
    )
}

export const LayerManifest = {
    get: () => {
        return [{
            key: 'share-content',
            layerID: 'share-content',
            Component: ShareContent
        },{
            key: 'new-order',
            layerID: 'new-order-{channel_id}',
            Component: OrderDetails,
            requestLogic: true
        },{
            key: 'order-details',
            layerID: 'order-{order_id}-{channel_id}',
            Component: OrderDetails,
            requestLogic: true
        },{
            key: 'payment-details',
            layerID: 'payment-{payment_id}',
            Component: PaymentDetails,
            requestLogic: true
        },{
            key: 'subscription-details',
            layerID: 'subscription-{subscription_id}',
            Component: SubscriptionDetails,
            requestLogic: true
        },{
            key: 'subscription-picker',
            layerID: 'subscription-picker',
            Component: SubscriptionPicker
        },{
            key: 'payment-method-invoice',
            layerID: 'payment-invoice-{method_id}',
            Component: PaymentMethodInvoice,
            requestLogic: true
        },{
            key: 'credits-method-invoice',
            layerID: 'credits-invoice-{method_id}',
            Component: CreditsMethodInvoice,
            requestLogic: true
        },{
            key: 'driver-tip',
            layerID: 'driver-tip-{driver_id}',
            Component: DriverTip,
            requestLogic: true
        },{
            key: 'new-reservation',
            layerID: 'new-reservation',
            Component: ReservationDetails,
            requestLogic: true
        },{
            key: 'new-quick-scan-route',
            layerID: 'new-quick-scan-route',
            Component: NewQuickScanRoute
        },{
            key: 'new-quick-scan',
            layerID: 'new-quick-scan-customer-route',
            Component: NewQuickScanCustomerRoute
        },{
            key: 'quick-scan-route-details',
            layerID: 'route-{route_id}',
            Component: RouteDetails,
            requestLogic: true
        },{
            key: 'driver-timecards',
            layerID: 'driver-timecards-{driver_id}',
            Component: DriverTimeCards,
            requestLogic: true
        },{
            key: 'add-new-card',
            layerID: 'add-new-card',
            Component: AddNewCard
        },{
            key: 'webview',
            layerID: 'webview-{content_id}',
            Component: WebView,
            requestLogic: true
        },{
            key: 'vehicle-onboarding',
            layerID: 'vehicle-onboarding',
            Component: VehicleOnboarding,
            optionalRequestLogic: {
                abstract: null
            }
        },{
            key: 'driver-onboarding',
            layerID: 'driver-onboarding',
            Component: DriverOnboarding,
            optionalRequestLogic: {
                abstract: null
            }
        },{
            key: 'friends-and-family',
            layerID: 'friends-and-family',
            Component: FriendsAndFamily,
            requestLogic: [ 'abstract', 'onChange' ]
        },{
            key: 'customer-vehicles-deep-link',
            layerID: 'customer-vehicles-deep-link',
            Component: CustomerVehiclesDeepLink
        }]
    }
}

export default Layer;

export const LayerItem = ({ badge, children, childrenStyle, collapsed, headerStyle, lastItem, onVisibilityChange, required, rightContent, shouldStyle, style, subTitle, title }) => {

    return (
        <View style={{
            width: '100%',
            marginBottom: lastItem ? 0 : 25,
            ...style
        }}>
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                textAlign: 'left'
            }}>
                {required && (
                    <div style={{
                        width: 8,
                        height: 8,
                        minWidth: 8,
                        minHeight: 8,
                        borderRadius: 4,
                        overflow: 'hidden',
                        backgroundColor: Appearance.colors.red,
                        marginRight: 8
                    }} />
                )}
                <View style={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    jusitfyContent: 'space-between',
                    alignItems: 'center',
                    minWidth: 0,
                    ...headerStyle
                }}>
                    <View style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1
                    }}>
                        <Text
                        numberOfLines={1}
                        style={{
                            ...Appearance.textStyles.panelTitle()
                        }}>{title}</Text>
                        {typeof(subTitle) === 'string' && (
                            <Text style={{
                                ...Appearance.fontWeight.get(600),
                                color: Appearance.colors.subText(),
                                fontSize: 14
                            }}>{subTitle}</Text>
                        )}
                    </View>
                    {badge && (
                        <AltBadge
                        content={badge}
                        style={{
                            top: 0,
                            marginLeft: 8,
                            marginRight: 0
                        }} />
                    )}
                </View>
                {rightContent}
            </View>
            <View style={{
                width: '100%',
                marginTop: 8,
                ...shouldStyle !== false && {
                    ...Appearance.styles.panel(),
                    borderRadius: 15
                },
                ...childrenStyle
            }}>
                {children}
            </View>
        </View>
    )
}
