import React from 'react'
import moment from 'moment';

import Utils from 'eCarra/files/Utils.js';

class DriverClass {

    user_id = null;
    level = null;
    first_name = null;
    last_name = null;
    full_name = null;
    gender = null;
    email_address = null;
    phone_number = null;
    member_since = null;
    avatar = null;
    navigation = {};

    constructor() {
        return this;
    }

	create = (props = {}) => {

		this.user_id = parseInt(props.user_id);
		this.level = parseInt(props.level);
		this.first_name = props.first_name;
		this.last_name = props.last_name;
		this.full_name = props.full_name || (props.first_name && props.last_name ? `${props.first_name} ${props.last_name}` : 'Name Not Provided');
		this.gender = props.gender;
		this.email_address = props.email_address;
		this.phone_number = props.phone_number ? Utils.formatPhoneNumber(props.phone_number) : null;
		this.member_since = moment(props.member_since);
		this.avatar = props.avatar ? { uri: props.avatar } : null;
        return this;
	}
}
export default {
    create: props => new DriverClass().create(props)
};
