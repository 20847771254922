import React from 'react';
import moment from 'moment';

import API from 'eCarra/files/api.js';
import Abstract from 'eCarra/classes/Abstract.js';
import Appearance from 'eCarra/styles/Appearance.js';
import Request from 'eCarra/files/Request/';
import Reservation from 'eCarra/classes/Reservation.js';
import RouteCategory from 'eCarra/classes/RouteCategory.js';
import RouteOption from 'eCarra/classes/RouteOption.js';
import Service from 'eCarra/classes/Service.js';
import StatusCodes from 'eCarra/files/StatusCodes.js';
import User from 'eCarra/classes/User.js';
import Vehicle from 'eCarra/classes/Vehicle.js';

class RouteClass {

    id = null;
    driver = null;
    name = null;
    service = null;
    vehicle = null;
    emissions = null;
    categories = null;
    date = null;
    routing = null;
    status = null;
    seeds = {};

    reservations = [];
    reservationIndex = 0;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.name = props.name;
        this.date = props.date ? moment(props.date) : null;
        this.status = getStatus(props.status);
        this.emissions = props.emissions;
        this.routing = props.routing;
        this.driver = props.driver ? User.create(props.driver) : null;
        this.service = props.service ? Service.create(props.service) : null;
        this.vehicle = props.vehicle ? Vehicle.Category.create(props.vehicle) : null;

        if(props.categories && Array.isArray(props.categories)) {
            this.categories = props.categories.map(category => RouteCategory.create(category));
        }
        if(props.reservations) {
            this.reservations = props.reservations.map(reservation => Reservation.create(reservation));
        }
        return this;
    }

    updateStatus = async (utils, code) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { status, updated_reservations } = await Request.post(utils, '/quick_scan/', {
                    type: 'set_status',
                    route_id: this.id,
                    status: code
                });

                this.status = getStatus(status.status);
                utils.content.update(Abstract.create({
                    type: 'routes',
                    object: this
                }))

                // update child reservations if applicable
                // contains new status event data if changes were made server side
                if(updated_reservations && Array.isArray(updated_reservations)) {
                    updated_reservations.forEach(res => {
                        let reservation = Reservation.create(res);
                        utils.content.update(Abstract.create({
                            type: 'reservations',
                            object: reservation
                        }))
                    })
                }
                resolve(this.status);

            } catch(e) {
                reject(e);
            }
        })
    }

    getFirstReservation = () => {

        let ordered = this.dropOffOrder();
        if(!ordered) {
            return null;
        }

        // start with already in-progress rides if found
        let active = ordered.reservations.find(res => {
            return res.status.code === StatusCodes.reservations.toDestination;
        });
        if(active) {
            return active;
        }

        return ordered.reservations.find(res => {
            return [
                StatusCodes.reservations.approved,
                StatusCodes.reservations.returned
            ].includes(res.status.code);
        });
    }
    setCurrentReservation = (reservation) => {
        let ordered = this.dropOffOrder();
        this.reservationIndex = ordered ? ordered.reservations.findIndex(res => res.id === reservation.id) : 0;
    }
    getCurrentReservation = () => {
        let ordered = this.dropOffOrder();
        return ordered ? ordered.reservations.find((res, index) => index === this.reservationIndex) : null;
    }
    getNextReservation = () => {
        let ordered = this.dropOffOrder();
        return ordered ? ordered.reservations.find((res, index) => index > this.reservationIndex) : null;
    }
    getRemainingReservations = () => {
        let ordered = this.dropOffOrder();
        return ordered ? ordered.reservations.filter((res, index) => index >= this.reservationIndex) : null;
    }

    startCurrentReservation = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let reservation = this.getCurrentReservation();
                if(!reservation) {
                    throw new Error('Unable to locate current Reeservation');
                    return;
                }
                if(reservation.status.code === StatusCodes.reservations.toDestination) {
                    resolve(StatusCodes.reservations.toDestination);
                    return; // prevent double status sets
                }

                await reservation.updateStatus(utils, StatusCodes.reservations.toDestination);
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    distanceEstimate = () => {
        if(this.reservations.length === 1) {
            return this.reservations[0].distanceEstimate;
        }
        return this.routing && this.routing.optimized && this.routing.optimized.summary ? this.routing.optimized.summary.length : null;
    }

    durationEstimate = () => {
        if(this.reservations.length === 1) {
            return this.reservations[0].durationEstimate;
        }
        return this.routing && this.routing.optimized && this.routing.optimized.summary ? this.routing.optimized.summary.time : null;
    }

    dropOffOrder = () => {

        if(this.routing && this.routing.optimized && this.routing.optimized.summary) {

            // First and last locations are driver current location
            let targets = [{
                key: 'location'
            }].concat(this.reservations).concat({
                key: 'location'
            })
            let reservations = this.routing.optimized.locations.map(location => {
                return targets[location.original_index];
            }).filter(reservation => {
                return reservation && reservation.key !== 'location' ? true:false // filter out potentially deleted reservations
            });

            return {
                reservations: reservations,
                summary: reservations.reduce((string, reservation, index, reservations) => {
                    return string + (index === reservations.length - 1 ? 'then ' : '') + reservation.customer.full_name + (reservations.length < 3 ? ' ' : '') + (index === reservations.length - 1 || reservations.length < 3 ? '' : ', ')
                }, '')
            }
        };
        return {
            reservations: this.reservations
        };
    }

    includesReservation = (id) => {
        return this.reservation.find(reservation => reservation.id === id) ? true:false
    }
}

const getStatus = status => {

    // Appearance module could be null at runtime
    switch(status) {
        case StatusCodes.routes.approved:
        return {
            code: status,
            text: 'Approved',
            realText: 'Approved',
            color: Appearance ? Appearance.colors.primary() : null,
            image: 'images/status-approved.png'
        };

        case StatusCodes.routes.returned:
        return {
            code: status,
            text: 'Returned',
            realText: 'Returned to Queue',
            color: Appearance ? Appearance.colors.primary() : null,
            image: 'images/status-approved.png'
        };

        case StatusCodes.routes.cancelled:
        return {
            code: status,
            text: 'Cancelled',
            realText: 'Cancelled',
            color: Appearance ? Appearance.colors.red : null,
            image: 'images/status-cancelled.png'
        };

        case StatusCodes.routes.inProgress:
        return {
            code: status,
            text: 'Active',
            realText: 'In Progress',
            color: Appearance ? Appearance.colors.blue : null,
            image: 'images/status-active.png'
        };

        case StatusCodes.routes.completed:
        return {
            code: status,
            text: 'Completed',
            realText: 'Completed',
            color: Appearance ? Appearance.colors.darkGrey : null,
            image: 'images/status-completed.png'
        };

        case StatusCodes.routes.unpaid:
        return {
            code: status,
            text: 'Unpaid',
            realText: 'Unpaid',
            color: Appearance ? Appearance.colors.lightGrey : null,
            image: 'images/status-unpaid.png'
        };

        default:
        return {
            code: null,
            text: 'Pending',
            realText: 'Pending',
            color: Appearance ? Appearance.colors.lightGrey : null,
            image: 'images/status-pending.png'
        };
    }
}

const fetchRoute = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let location = await utils.location.get();
            if(!location) {
                throw new Error('Unable to retieve current location');
            }
            let { route } = await Request.get(utils, '/quick_scan/', {
                type: 'get_route',
                id: id,
                latitude: location.latitude,
                longitude: location.longitude
            });
            let target = new RouteClass().create(route);
            resolve(target);

        } catch(e) {
            reject(e);
        }
    })
}

export default {
    new: () => new RouteClass(),
    get: fetchRoute,
    create: props => new RouteClass().create(props),
    getStatus: getStatus
}
