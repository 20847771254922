import React, { useRef, useState, useEffect } from 'react';
import { Image, Keyboard, Platform, Text, View } from 'react-native';

import { animated, useSpring } from '@react-spring/native';
import { privacyOptions, requestBluetoothPermission, requestFaceIDPermission, requestLocationPermission, requestNotificationPermission } from 'eCarra/managers/Settings.js';
import update from 'immutability-helper';

import Appearance from 'eCarra/styles/Appearance';
import Button from 'eCarra/views/Button.js';
import Carousel from 'react-native-snap-carousel';
import LottieView from 'eCarra/views/Lottie/';
import ReactNativeBiometrics from 'react-native-biometrics';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import Screen from 'eCarra/files/Screen.js';
import User from 'eCarra/classes/User.js';
import Utils from 'eCarra/files/Utils.js';

const PermissionsRequest = ({ onClose, onPermissionChange, utils }) => {

    const scrollView = useRef(null);

    const [activeIndex, setActiveIndex] = useState(0);
    const [animations, setAnimations] = useSpring(() => ({
        opacity: 0,
        scale: 0,
        config: { mass: 1, tension: 180, friction: 16 }
    }));
    const [options, setOptions] = useState([]);
    const [visible, setVisible] = useState(false);

    const onApprovePermission = async () => {
        try {
            let permission = options[activeIndex];
            switch(permission.key) {
                case 'bluetooth':
                await requestBluetoothPermission(utils);
                break;

                case 'faceID':
                await requestFaceIDPermission(utils);
                break;

                case 'location':
                console.log('request location permission');
                await requestLocationPermission(utils);
                break;

                case 'notifications':
                await requestNotificationPermission(utils);
                break;

                default:
                return;
            }
            onMoveToNext();
            if(typeof(onPermissionChange) === 'function') {
                onPermissionChange(options[activeIndex]);
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue allowing the ${permission.title} permission. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onMoveToNext = () => {
        if(activeIndex === options.length - 1) {
            onHideAlert();
            return;
        }
        let next = activeIndex + 1;
        setActiveIndex(next);
        if(scrollView.current) {
            scrollView.current.snapToItem(next, true);
        }
    }

    const onHideAlert = async () => {
        try {
            setAnimations({
                opacity: 0,
                scale: 0
            });
            await Utils.sleep(0.5);
            setVisible(false);

            if(typeof(onClose) === 'function') {
                onClose();
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    const onSetupAlert = async () => {
        try {

            // filter out non-applicable requests for android and web
            // do not ask for bluetooth permission on android
            if(Platform.OS === 'web') {
                let options = privacyOptions.filter(option => {
                    return option.visible !== false && option.key !== 'bluetooth';
                });
                setOptions(options);
                return;
            }

            // check for face id capabilities for ios
            // filter faceID option out of request options if not available on device
            let { biometryType } = await ReactNativeBiometrics.isSensorAvailable();
            let options = privacyOptions.map(option => {
                if(option.key === 'faceID') {
                    option.visible = Platform.OS === 'ios' && biometryType === ReactNativeBiometrics.FaceID;
                }
                return option;
            });

            // filter out non-applicable request options
            // only ask for bluetooth permission if platform is ios and user is a driver or admin
            options = options.filter(option => {
                if(option.key === 'bluetooth' && (utils.user.get().level > User.level.driver || Platform.OS === 'android')) {
                    return false;
                }
                return option.visible !== false;
            });
            setOptions(options);

            // animate components into view
            setVisible(true);
            await Utils.sleep(0.5);
            setAnimations({
                opacity: 1,
                scale: 1
            });

            if(Platform.OS === 'ios') {
                ReactNativeHapticFeedback.trigger('notificationSuccess', {
                    enableVibrateFallback: false,
                    ignoreAndroidSystemSettings: false
                });
            }

        } catch(e) {
            console.error(e.message);
        }
    }

    const getContent = () => {
        return (
            <animated.View style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: Appearance.colors.dim,
                zIndex: 9950,
                elevation: 6,
                opacity: animations.opacity
            }}>
                <animated.View style={{
                    marginTop: Screen.safeArea.top,
                    borderRadius: 15,
                    backgroundColor: Appearance.colors.alert(),
                    overflow: 'hidden',
                    width: Screen.layer.maxWidth - 60,
                    maxHeight: Screen.height() - 30,
                    alignItems: 'center',
                    zIndex: 9999,
                    opacity: animations.opacity,
                    transform: [{ scale: animations.scale }]
                }}>
                    <Carousel
                    ref={scrollView}
                    data={options}
                    layoutCardOffset={0}
                    scrollEnabled={false}
                    sliderWidth={Screen.layer.maxWidth - 60}
                    itemWidth={Screen.layer.maxWidth - 84}
                    renderItem={({ item, index }) => {
                        return (
                            <View
                            key={index}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: 15
                            }}>
                                {item.lottie && (
                                    <LottieView
                                    autoPlay={true}
                                    loop={true}
                                    source={typeof(item.lottie) === 'function' ? item.lottie() : item.lottie}
                                    style={{
                                        width: 75,
                                        height: 75,
                                        marginBottom: 12,
                                        resizeMode: 'contain'
                                    }} />
                                )}
                                <Text style={{
                                    ...Appearance.textStyles.panelTitle(),
                                    marginBottom: 8
                                }}>{item.title}</Text>
                                <Text style={{
                                    ...Appearance.textStyles.subTitle(),
                                    textAlign: 'center'
                                }}>{item.message}</Text>
                            </View>
                        )
                    }} />

                    <View style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: 15,
                        paddingHorizontal: 15,
                        width: '100%'
                    }}>
                        <View style={{
                            width: '50%',
                            paddingLeft: 0,
                            paddingRight: 4
                        }}>
                            <Button
                            label={'Maybe Later'}
                            color={Appearance.colors.grey()}
                            onPress={onMoveToNext} />
                        </View>
                        <View style={{
                            width: '50%',
                            paddingLeft: 4,
                            paddingRight: 0
                        }}>
                            <Button
                            label={'Allow'}
                            color={Appearance.colors.primary()}
                            onPress={onApprovePermission} />
                        </View>
                    </View>
                </animated.View>
            </animated.View>
        )
    }

    useEffect(() => {
        Keyboard.dismiss();
        onSetupAlert();
    }, []);

    return getContent();
}

export default PermissionsRequest;
