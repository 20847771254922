import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Animated, Image, Keyboard, Linking, Platform, ScrollView, Share, Text, View } from 'react-native';

import moment from 'moment';
import stripe from 'eCarra/files/Stripe/';
import update from 'immutability-helper';

import API from 'eCarra/files/api.js';
import Abstract from 'eCarra/classes/Abstract.js';
import { ActiveAbstractHeader } from 'eCarra/managers/Vehicles.js';
import Appearance from 'eCarra/styles/Appearance.js';
import AsyncStorage from '@react-native-community/async-storage';
import Ble from 'eCarra/files/Ble.js';
import { BubbleHeader } from 'eCarra/structure/Navigation.js';
import Button from 'eCarra/views/Button.js';
import Calendar from 'eCarra/views/Calendar.js';
import { Call, Text as TextMessage } from 'react-native-openanything';
import Carousel from 'react-native-snap-carousel';
import ContactsPicker from 'eCarra/views/ContactsPicker.js';
import DatePicker, { MonthYearPicker } from 'eCarra/views/DatePicker/';
import DirectionalButtons from 'eCarra/views/DirectionalButtons.js';
import { DriverTip } from 'eCarra/managers/Payments.js';
import DropdownHeader from 'eCarra/views/DropdownHeader.js';
import Layer, { FloatingLayerMinimizedHeight, LayerHeaderSpacing, LayerItem, LayerShell, LayerSpacing, LayerToolbar, LayerToolbarHeight, TopSpacing } from 'eCarra/structure/Layer.js';
import LottieView from 'eCarra/views/Lottie/';
import HeaderWithButton from 'eCarra/views/HeaderWithButton.js';
import Here from 'eCarra/classes/Here.js';
import { Annotation, Follow, FollowWithCourse, Map, getMapStyleURL } from 'eCarra/views/Maps/';
import MapboxNavigation from 'eCarra/views/MapboxNavigation/';
import { Messaging } from 'eCarra/managers/Messages.js';
import MusicPlayer from 'eCarra/views/MusicPlayer.js';
import { MusicPicker } from 'eCarra/managers/Music.js';
import { NewQuickScanRoute } from 'eCarra/managers/Routes.js';
import Panel from 'eCarra/structure/Panel.js';
import PaymentMethod from 'eCarra/classes/PaymentMethod.js';
import PaymentMethodManager from 'eCarra/views/PaymentMethodManager.js';
import ProgressBar from 'eCarra/views/ProgressBar/';
import Reservation from 'eCarra/classes/Reservation.js';
import Request from 'eCarra/files/Request/';
import Route from 'eCarra/classes/Route.js';
import Screen from 'eCarra/files/Screen.js';
import Service from 'eCarra/classes/Service.js';
import { SeedPodConfig, fetchDriverVehicle, removeDriverVehicle, driverVehicleMileageRequest } from 'eCarra/managers/Vehicles.js';
import StatusCodes, { Drivers } from 'eCarra/files/StatusCodes.js';
import Stripe from 'eCarra/files/Stripe/';
import TextField from 'eCarra/views/TextField.js';
import TextView from 'eCarra/views/TextView.js';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import TouchableHighlight from 'eCarra/views/TouchableHighlight/';
import User from 'eCarra/classes/User.js';
import Vehicle from 'eCarra/classes/Vehicle.js';
import Views from 'eCarra/views/Main.js';
import Utils, { LineItem, ModularContentLogic, useRequestManager } from 'eCarra/files/Utils.js';

export const fetchServices = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { services } = await Request.get(utils, '/reservations/', {
                type: 'services',
                ...props
            });
            resolve(services.map(s => Service.create(s)));
        } catch(e) {
            reject(e);
        }
    })
}

export const fetchReservationLineItems = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { payment, line_items, updated_items } = await Request.post(utils, '/reservation/', {
                type: 'line_items',
                ...props
            });
            resolve({
                payment: payment,
                line_items: line_items || [],
                updated_items: updated_items
            });
        } catch(e) {
            reject(e);
        }
    })
}

export const onStartRide = async (utils, abstract) => {
    return new Promise(async (resolve, reject) => {
        try {
            if(!utils.driver.vehicle.get()) {
                throw new Error('You need to add a vehicle before you start this reservation');
                return;
            }
            if(abstract.object.driver && abstract.object.driver.user_id !== utils.user.get().user_id) {
                throw new Error(`This reservation has already been started by ${abstract.object.driver.full_name}`);
                return;
            }

            let status = await abstract.object.updateStatus(utils, StatusCodes.reservations.toPickup);
            utils.sockets.emit('messages', 'join', {
                id: abstract.getID(),
                type: abstract.type
            });
            resolve(abstract);

            setTimeout(() => {
                abstract.object.status = status;
                utils.layer.openFloating({
                    id: `driver-navigation-${abstract.getTag()}`,
                    abstract: abstract,
                    Component: DriverNavigation.bind(this, {
                        resuming: false
                    })
                });
            }, 1000);

        } catch(e) {
            reject(e);
        }
    })
}

export const onResumeRide = async (utils, abstract) => {
    return new Promise(async (resolve, reject) => {
        try {
            if(!utils.driver.vehicle.get()) {
                throw new Error('You need to add a vehicle before you start this Reservation');
                return;
            }
            if(abstract.object.driver && abstract.object.driver.user_id !== utils.user.get().user_id) {
                throw new Error(`This reservation has been started by ${abstract.object.driver.full_name}`);
                return;
            }

            resolve();
            setTimeout(() => {
                utils.layer.openFloating({
                    id: `driver-navigation-${abstract.getTag()}`,
                    abstract: abstract,
                    Component: DriverNavigation.bind(this, {
                        resuming: true
                    })
                });
            }, 1000);

        } catch(e) {
            reject(e);
        }
    })
}

// Panels
export const OnTheWayReservations = ({ utils }) => {

    const panelID = `onTheWayReservations`;
    const [driver, setDriver] = useState(null);
    const [loading, setLoading] = useState(true);
    const [overlays, setOverlays] = useState(null);
    const [target, setTarget] = useState(null);

    const onDetailsPress = () => {
        utils.sheet.show({
            items: [{
                key: 'contact',
                title: 'Contact Driver',
                style: 'default'
            },{
                key: 'trip-navigator',
                title: 'Open Trip Navigator',
                style: 'default'
            }]
        }, key => {
            if(key === 'contact') {
                utils.layer.messaging(target);
                return;
            }
            if(key === 'trip-navigator') {
                utils.layer.openFloating({
                    id: `trip-navigator-${target.getTag()}`,
                    abstract: target,
                    Component: TripNavigator
                });
            }
        })
    }

    const onLocationUpdate = data => {
        try {
            let { reservation } = JSON.parse(data);
            let { location } = reservation.driver;
            if(location.polyline) {
                let coordinates = Utils.decodePolyline(location.polyline)
                setOverlays([{
                    key: 'driver_route',
                    coordinates: coordinates.map(c => [ c[1], c[0] ])
                }])
            }

            setDriver(driver => update(driver, {
                heading: {
                    $set: location.heading
                },
                time_to_arrival: {
                    $set: location.time_to_arrival
                },
                location: {
                    $set: {
                        latitude: location.lat,
                        longitude: location.long
                    }
                }
            }))

        } catch(e) {
            console.error(e.message)
        }
    }

    const onTripNavigatorPress = () => {
        utils.layer.openFloating({
            id: `trip-navigator-${target.getTag()}`,
            abstract: target,
            Component: TripNavigator
        });
    }

    const getContent = () => {
        if(!target) {
            return null;
        }
        return (
            <View style={{
                paddingHorizontal: 15
            }}>
                <ActiveAbstractHeader
                utils={utils}
                driver={driver}
                abstract={target} />

                <View style={{
                    ...Appearance.styles.panel(),
                    marginTop: 8
                }}>
                    {Views.entry({
                        title: driver ? driver.full_name : null,
                        subTitle: getTimeToArrival(),
                        icon: {
                            path: driver ? driver.avatar : null
                        },
                        bottomBorder: false,
                        onPress: onTripNavigatorPress,
                        propStyles: {
                            subTitle: {
                                numberOfLines: 2
                            }
                        }
                    })}
                </View>
            </View>
        )
    }

    const getTimeToArrival = () => {
        if(!driver || !driver.time_to_arrival) {
            return `Arriving at ${moment(target.object.pickup_date).format('h:mma')}`
        }
        return `Arriving at ${moment().add(driver.time_to_arrival, 'seconds').format('h:mma')}`
    }

    const fetchReservations = async () => {
        try {
            let { reservations } = await Request.get(utils, '/reservations/', {
                type: 'active'
            });
            setLoading(false);
            let reservation = reservations.map(reservation => {
                return Reservation.create(reservation);
            }).find(reservation => {
                return reservation.driver ? true : false;
            });
            if(reservation) {
                let target = Abstract.create({
                    type: 'reservations',
                    object: reservation
                });
                setTarget(target);
                setDriver(target.object.driver);
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your active reservations. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchReservations();
        utils.content.subscribe(panelID, 'reservations', {
            onFetch: fetchReservations,
            onUpdate: abstract => {

                // check for freshly ended rides
                let { toPickup, toDestination } = StatusCodes.reservations;
                setTarget(target => {
                    if(!target) {
                        return null;
                    }
                    if(target.getID() === abstract.getID() && [toPickup, toDestination].includes(abstract.object.status.code)) {
                        return abstract;
                    }
                    return null;
                })

                // fetch reservations if status is an active status
                if([toPickup, toDestination].includes(abstract.object.status.code)) {
                    fetchReservations();
                }
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        style={{
            marginBottom: target ? 32 : 0
        }}
        options={{
            loading: loading,
            removeOverflow: true,
            shouldStyle: false,
            removePadding: true
        }}>
            {getContent()}
        </Panel>
    )
}

export const ReservationsCalendar = ({ utils }) => {

    const panelID = 'reservationsCalendar';
    const [loading, setLoading] = useState(true);
    const [reservations, setReservations] = useState([]);
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [searchText, setSearchText] = useState(null);

    const onChooseDate = () => {

        let tempDate = null;
        utils.layer.open({
            id: 'calendar-custom-date',
            Component: LayerShell.bind(this, {
                layerID: 'calendar-custom-date',
                extendedOptions: {
                    bottomCard: true
                },
                buttons: [{
                    key: 'done',
                    text: 'Done',
                    color: 'primary',
                    onPress: () => {
                        utils.events.emit('onLayerAction', {
                            action: 'close',
                            layerID: 'calendar-custom-date'
                        })
                        setDate(tempDate ? moment(tempDate).format('YYYY-MM-DD') : date)
                    }
                }],
                children: (
                    <View style={{
                        alignItems: 'center',
                        marginTop: 5
                    }}>
                        <Text style={{
                            ...Appearance.textStyles.panelTitle(),
                            marginBottom: 6
                        }}>{'Browse Other Dates'}</Text>
                        <Text style={{
                            ...Appearance.textStyles.subTitle(),
                            marginBottom: 20,
                            textAlign: 'center'
                        }}>{'Choose a month and year from the list below to view more Reservations'}</Text>
                        <MonthYearPicker
                        utils={utils}
                        date={date}
                        onChange={date => tempDate = date} />
                    </View>
                )
            })
        })
    }

    const getContent = () => {

        let targets = getReservations();
        if(Platform.OS === 'web' || Utils.isMobile() === false) {
            return (
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    paddingRight: 12
                }}>
                    <View style={{
                        ...Appearance.styles.panel(),
                        flexGrow: 1,
                        width: '50%',
                        paddingTop: 12
                    }}>
                        <Calendar
                        activeDate={date}
                        events={reservations}
                        onDateChange={date => setDate(date.format('YYYY-MM-DD'))}
                        eventFilter={(day, reservation) => day.isSame(reservation.pickup_date, 'day')}/>
                    </View>
                    <View style={{
                        ...Appearance.styles.panel(),
                        width: '50%',
                        marginLeft: 12,
                        marginRight: 12,
                        padding: 12
                    }}>
                        <View style={{
                            borderRadius: 10,
                            overflow: 'hidden'
                        }}>
                            {getReservationComponents(targets)}
                        </View>
                    </View>
                </View>
            )
        }

        return (
            <>
            <View style={Appearance.styles.panel()}>
                <Calendar
                activeDate={date}
                events={reservations}
                onDateChange={date => setDate(date.format('YYYY-MM-DD'))}
                eventFilter={(day, reservation) => day.isSame(reservation.pickup_date, 'day')}/>
            </View>
            {targets.length > 0 && (
                <View style={{
                    ...Appearance.styles.panel(),
                    marginTop: 8
                }}>
                    {getReservationComponents(targets)}
                </View>
            )}
            </>
        )
    }

    const getReservations = () => {
        return reservations.filter(reservation => {
            if(searchText) {
                let searchTargets = [
                    reservation.id,
                    reservation.customer.full_name,
                    reservation.origin.name,
                    reservation.origin.address,
                    reservation.destination.name,
                    reservation.destination.address
                ];
                if(!searchTargets.find(target => {
                    return target && target.toString().toLowerCase().includes(searchText);
                })) {
                    return false;
                }
            }
            return moment(date).isSame(reservation.pickup_date, 'day');

        }).sort((a, b) => {
            return moment(a.pickup_date).unix() - moment(b.pickup_date).unix();
        });
    }

    const getReservationComponents = targets => {

        // return components showing that no reservations were found if platform is web or device is not mobile
        if(targets.length === 0) {
            if(Platform.OS === 'web' || Utils.isMobile() === false) {
                return (
                    Views.entry({
                        title: 'No Reservations Found',
                        subTitle: `There are no reservations scheduled for ${moment(date).format('MMMM Do, YYYY')}`,
                        hideIcon: true,
                        bottomBorder: false
                    })
                )
            }
            return null;
        }

        // return all matching reservations
        return targets.map((reservation, index, reservations) => {
            return (
                Views.entry({
                    key: index,
                    title: reservation.destination.name && reservation.destination.name !== 'Current Location' ? reservation.destination.name : reservation.destination.address,
                    subTitle: moment(reservation.pickup_date).format('MMMM Do, YYYY [at] h:mma'),
                    bottomBorder: index !== reservations.length - 1,
                    hideIcon: true,
                    onPress: utils.layer.reservation.details.bind(this, reservation)
                })
            )
        })
    }

    const fetchReservations = async () => {
        try {
            let { reservations } = await Request.get(utils, '/reservations/', {
                type: 'all'
            });
            setLoading(false);
            setReservations(reservations.map(reservation => Reservation.create(reservation)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the reservations calendar. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {

        utils.content.subscribe(panelID, 'reservations', {
            onFetch: fetchReservations,
            onUpdate: abstract => {
                let index = (reservations || []).findIndex(reservation => reservation.id === abstract.getID());
                if(index >= 0) {
                    setReservations(update(reservations, {
                        [index]: {
                            $set: abstract.object
                        }
                    }))
                }
            }
        })

        return () => {
            utils.content.unsubscribe(panelID);
        }

    }, [reservations]);

    useEffect(() => {
        fetchReservations();
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Reservations Calendar'}
        header={(
            <DropdownHeader
            label={`${moment(date).isSame(moment(), 'year') ? moment(date).format('MMMM') : moment(date).format('MMMM YYYY')} Rides`}
            onPress={onChooseDate}/>
        )}
        rightContent={(
            <DirectionalButtons
            onNext={() => setDate(moment(date).add(1, 'months'))}
            onBack={() => setDate(moment(date).subtract(1, 'months'))} />
        )}
        options={{
            loading: loading,
            removeOverflow: true,
            shouldStyle: false
        }}>
            {getContent()}
        </Panel>
    )
}

export const UpcomingReservations = ({ utils }) => {

    const panelID = 'upcomingReservations';
    const [loading, setLoading] = useState(false);
    const [scrollOffset, setScrollOffset] = useState(0);
    const [reservations, setReservations] = useState([]);

    const fetchReservations = async () => {
        try {
            let { reservations } = await Request.get(utils, '/reservations/', {
                type: 'all',
                start_date: moment().format('YYYY-MM-DD HH:mm:ss')
            });
            setLoading(false);
            setReservations(reservations.map(r => Reservation.create(r)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your upcoming reservations. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getCarouselItemStyles = () => {
        return {
            paddingTop: 8,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 20,
            ...(Platform.OS === 'web' || Utils.isMobile() === false) && {
                paddingLeft: 15
            }
        }
    }

    const getCarouselProps = target => {
        return {
            sliderWidth: Screen.panel.maxWidth(),
            itemWidth: Screen.panel.maxWidth() - 30,
            ...Platform.OS !== 'web' && Utils.isMobile() === true && {
                layoutCardOffset: 0,
                loop: target.length > 1
            },
            ...(Platform.OS === 'web' || Utils.isMobile() === false) && {
                sliderWidth: Screen.panel.maxWidth() - 15,
                itemWidth: Screen.panel.maxWidth() - 30,
                ...target.length >= 3 && {
                    itemWidth: Screen.panel.maxWidth() / 3
                }
            }
        }
    }

    const getContent = () => {
        if(Platform.OS === 'web' || Utils.isMobile() === false) {
            return (
                <View style={{
                    paddingHorizontal: 15,
                    marginBottom: 15
                }}>
                    <View style={Appearance.styles.panel()}>
                        {reservations.map((reservation, index) => {
                            return (
                                Views.entry({
                                    key: index,
                                    title: reservation.destination.name && reservation.destination.name !== 'Current Location' ? reservation.destination.name : reservation.destination.address,
                                    subTitle: moment(reservation.pickup_date).format('MMMM Do, YYYY [at] h:mma'),
                                    bottomBorder: false,
                                    hideIcon: true,
                                    onPress: utils.layer.reservation.details.bind(this, reservation)
                                })
                            )
                        })}
                    </View>
                </View>
            )
        }
        return (
            <>
            <Carousel
            data={reservations}
            {...getCarouselProps(reservations)}
            onScrollIndexChanged={index => setScrollOffset(index)}
            renderItem={({ item, index }) => {
                let r = item;
                return (
                    <View style={getCarouselItemStyles()}>
                        <View style={{
                            ...Appearance.styles.panel()
                        }}>
                            <TouchableOpacity
                            activeOpacity={0.6}
                            onPress={utils.layer.reservation.details.bind(this, r)}
                            style={{
                                borderTopLeftRadius: 12,
                                borderTopRightRadius: 12,
                                overflow: 'hidden'
                            }}>
                                <Map
                                utils={utils}
                                overlays={r.getOverlays()}
                                annotations={r.getLocations()}
                                style={{
                                    height: Platform.OS === 'web' || Utils.isMobile() === false ? 400 : 200,
                                    width: Screen.panel.maxWidth() - 30,
                                    marginBottom: 4,
                                    borderBottomWidth: 1,
                                    borderBottomColor: Appearance.colors.divider()
                                }}/>
                            </TouchableOpacity>
                            <View style={{
                                paddingTop: 3,
                                paddingHorizontal: 8,
                                paddingBottom: 8
                            }}>
                                {Views.entry({
                                    title: r.destination.name && r.destination.name !== 'Current Location' ? r.destination.name : r.destination.address,
                                    subTitle: moment(r.pickup_date).format('MMMM Do, YYYY [at] h:mma'),
                                    bottomBorder: false,
                                    hideIcon: true,
                                    onPress: utils.layer.reservation.details.bind(this, r)
                                })}
                            </View>
                        </View>
                    </View>
                )
            }} />
            {reservations && reservations.length > 1 && (
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '100%',
                    marginBottom: 25
                }}>
                    {reservations.map((_, index) => {
                        return (
                            <View key={index}
                            style={{
                                width: 15,
                                height: 3,
                                borderRadius: 1.5,
                                backgroundColor: scrollOffset === index ? Appearance.colors.primary() : Appearance.colors.grey(),
                                marginHorizontal: 4
                            }}/>
                        )
                    })}
                </View>
            )}
            </>
        )
    }

    useEffect(() => {
        utils.content.subscribe(panelID, 'reservations', {
            onFetch: fetchReservations,
            onUpdate: abstract => {
                let index = (reservations || []).findIndex(reservation => reservation.id === abstract.getID());
                if(index >= 0) {
                    setReservations(update(reservations, {
                        [index]: {
                            $set: abstract.object
                        }
                    }))
                }
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
            utils.structure.navigation.unsubscribe(panelID);
        }
    }, [reservations]);

    useEffect(() => {
        fetchReservations();
    }, []);

    return reservations.length > 0 ? (
        <Panel
        key={panelID}
        panelID={panelID}
        options={{
            removeOverflow: true,
            shouldStyle: false,
            removePadding: true
        }}>
            <Text
            numberOfLines={1}
            style={{
                ...Appearance.textStyles.panelTitle(),
                marginBottom: 6,
                paddingHorizontal: 15
            }}>
                {`Upcoming Rides`}
            </Text>
            {getContent()}
        </Panel>
    ) : null
}

// Drivers
export const BleDevice = ({ config, device, onConnect, onDisconnect, utils, vehicle }) => {

    const componentID = `ble_device_${device.id}`;
    const subscription = useRef(null);

    const [loading, setLoading] = useState(false);
    const [payloadText, setPayloadText] = useState(null);

    const onAQUpdate = async payload => {

        switch(payload.event) {
            case Ble.events.onUpdate:
            let { id, data } = payload;
            let network = Boolean(data[0]);
            let timestamp = data[1];
            let pm1 = data[2];
            let pm25 = data[3];
            let pm10 = data[4];
            setPayloadText(`PM1: ${pm1 || 0}, PM2.5: ${pm25}, PM10: ${pm10}`);

            if(network) {
                console.log('data sent over sensor network connection');
                return;
            }
            try {
                await utils.sockets.emit('sensors', 'active_update', {
                    id: id,
                    timestamp: timestamp,
                    location: {
                        lat: data[5][0],
                        long: data[5][1]
                    },
                    aq: {
                        pm1: pm1,
                        pm25: pm25,
                        pm10: pm10
                    }
                })
            } catch(e) {
                console.error(e.message);
            }
            break;
        }
    }

    const onConnectToDevice = async () => {
        try {
            setLoading(true);
            setupDeviceMonitor();

            setLoading(false);
            if(typeof(onConnect) === 'function') {
                onConnect();
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue connecting to ${device.name || 'your device'}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onDisconnectDevice = async () => {
        try {
            setLoading(true);
            if(subscription.current) {
                subscription.current.remove();
            }
            await utils.bluetooth.get().disconnect(device);
            setLoading(false);
            if(typeof(onDisconnect) === 'function') {
                onDisconnect();
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue disconnecting from ${device.name || 'your device'}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onDetailsPress = () => {
        utils.sheet.show({
            items: [{
                key: 'disconnect',
                title: 'Disconnect',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'disconnect') {
                onDisconnectDevice();
                return;
            }
        })
    }

    const onPress = () => {
        if(device.connected !== true) {
            onConnectToDevice();
            return;
        }
    }

    const getSubTitle = () => {
        if(device.connected !== true) {
            return 'Tap to connect...'
        }
        if(payloadText) {
            return payloadText;
        }
        return 'Connected';
    }

    const setupDeviceMonitor = async () => {
        try {
            if(API.bluetooth) {
                let monitor = await utils.bluetooth.get().subscribeToSeedPod(device.unit, data => {
                    switch(data.event) {
                        case Ble.events.onUpdate:
                        case Ble.events.onSensorsRead:
                        onAQUpdate(data);
                        break;
                    }
                });
                subscription.current = monitor;
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        if(device.connected) {
            setupDeviceMonitor();
        }
        return () => {
            if(subscription.current) {
                subscription.current.remove();
            }
        }
    }, [])

    return (
        <View>
            {Views.entry({
                title: device.name,
                subTitle: getSubTitle(),
                loading: loading,
                icon: {
                    path: require('eCarra/images/seedpod-icon-light.png'),
                    style: Appearance.icons.user
                },
                bottomBorder: config.bottomBorder,
                onPress: onPress,
                rightContent: device.connected && (
                    <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={onDetailsPress}
                    style={{
                        width: 20,
                        height: 20
                    }}>
                        <Image
                        source={require('eCarra/images/vertical-details-grey-small.png')}
                        style={{
                            width: 20,
                            height: 20,
                            resizeMode: 'contain',
                            tintColor: Appearance.colors.grey()
                        }} />
                    </TouchableOpacity>
                )
            })}
        </View>
    )
}

export const DriverTimecardOverview = ({ utils }) => {

    const panelID = 'driverTimecardOverview';
    const [loading, setLoading] = useState(false);
    const [vehicle, setVehicle] = useState(utils.driver.vehicle.get());
    const [timecard, setTimecard] = useState(null);
    const [location, setLocation] = useState(null);
    const [revenueOpts, setRevenueOpts] = useState(null);
    const [durationInterval, setDurationInterval] = useState(null);

    const onAddDriverVehicle = () => {
        utils.layer.open({
            id: 'add-driver-vehicle',
            Component: AddDriverVehicle.bind(this, {
                onAddVehicle: vehicle => setVehicle(vehicle)
            })
        })
    }

    const fetchTimecard = async () => {

        if(!vehicle) {
            setTimecard(null);
            return;
        }

        try {
            let { timecard, revenue } = await Request.get(utils, '/driver/', {
                type: 'get_timecard',
                line_items: true,
                user_id: utils.user.get().user_id,
                vehicle_id: vehicle.id
            });
            setLoading(false);
            setRevenueOpts(revenue);
            setTimecard(timecard);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your timecard. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onTimecardItemPress = item => {
        utils.alert.show({
            title: item.title,
            message: item.message
        });
    }

    const getRevenue = () => {
        if(!revenueOpts) {
            return;
        }

        let time = timecard ? timecard.find(entry => entry.key === 'time') : null;
        let { props, formatted } = timecard ? timecard.find(entry => entry.key === 'revenue') : {};
        let seconds = time ? moment.duration(moment().diff(moment(time.value))).asSeconds() : null;
        switch(revenueOpts.billing) {
            case Drivers.compensation.fixed:
            return Utils.toCurrency(revenueOpts.amount);

            case Drivers.compensation.hour:
            return Utils.toCurrency(revenueOpts.amount * (seconds / 60 / 60))

            case Drivers.compensation.minute:
            return Utils.toCurrency(revenueOpts.amount * (seconds / 60))

            case Drivers.compensation.mileage:
            // Calculate as the crow flies distance between last recorded location and next location
            // Starting mileage and location pulled from mongo vehicle entries
            let { billing, last_location, last_mileage } = props || {};
            if(location && last_location && last_mileage) {

                let miles = last_mileage + Utils.linearDistance(last_location, location);
                timecard.forEach(entry => {
                    if(entry.key === 'revenue') {
                        entry.props.last_location = location;
                        entry.props.last_mileage = isNaN(miles) ? last_mileage : miles;
                    }
                })
                return Utils.toCurrency((isNaN(miles) ? 1 : miles) * revenueOpts.amount)
            }
            return formatted;

            default:
            return formatted; // no matches found for billing => fallback to server format
        }
    }

    useEffect(() => {
        fetchTimecard();
    }, [vehicle])

    useEffect(() => {

        utils.events.addListener('on_driver_vehicle_change', ({ vehicle }) => setVehicle(vehicle));

        // Location updates
        utils.location.subscribe(panelID, {
            onUpdate: location => setLocation(location)
        })

        // Driver tips
        utils.sockets.subscribe(panelID, {
            on_trip_completed: ({ order_id, reservation_id }) => {
                setTimecard(timecard => {
                    if(timecard) {
                        return timecard.map(entry => {
                            if(entry.key === 'trips') {
                                entry.value.orders += order_id ? 1:0;
                                entry.value.reservations += reservation_id ? 1:0;
                                entry.formatted = `${entry.value.orders} ${entry.value.orders === 1 ? 'Order' : 'Orders'} and ${entry.value.reservations} ${entry.value.reservations === 1 ? 'Reservation' : 'Reservations'}`;
                            }
                            return entry;
                        })
                    }
                    return timecard
                })
            },
            on_driver_tip: ({ amount, customer }) => {
                setTimecard(timecard => {
                    if(timecard) {
                        return timecard.map(entry => {
                            if(entry.key === 'tips') {
                                entry.value = entry.value + (parseFloat(amount) || 0);
                                entry.formatted = Utils.toCurrency(entry.value);
                            }
                            return entry;
                        })
                    }
                    return timecard
                })
            }
        });

        // Increment elapsed time every second
        let interval = setInterval(() => {
            setTimecard(timecard => {
                if(timecard) {
                    return timecard.map(entry => {
                        if(entry.key === 'time') {
                            entry.formatted = Utils.parseDuration(moment.duration(moment().diff(moment(entry.value))).asSeconds(), true);
                        }
                        return entry;
                    })
                }
                return timecard
            })
        }, 1000);
        setDurationInterval(interval);

        return () => {
            utils.sockets.unsubscribe(panelID);
            utils.location.unsubscribe(panelID);
            utils.events.removeListener('on_driver_vehicle_change');
            clearInterval(interval);
        }

    }, [])

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'My Timecard'}
        options={{
            loading: loading,
            shouldStyle: false,
            removeOverflow: true
        }}>

            <View style={Appearance.styles.panel()}>
                {timecard
                    ?
                    timecard.map((item, index) => {

                        let localValue = item.key === 'revenue' ? getRevenue() : null;
                        return (
                            Views.entry({
                                key: index,
                                title: item.title,
                                subTitle: localValue || item.formatted,
                                icon: {
                                    path: { uri: item.icon },
                                    style: item.style
                                },
                                onPress: onTimecardItemPress.bind(this, item)
                            })
                        )
                    })
                    :
                    Views.entry({
                        title: 'Nothing to see here',
                        subTitle: 'Add a vehicle to start your timecard',
                        hideIcon: true,
                        bottomBorder: false,
                        onPress: onAddDriverVehicle
                    })
                }
            </View>
        </Panel>
    )
}

export const DriverVehicle = ({ utils }) => {

    const panelID = 'driverVehicle';
    const [searchText, setSearchText] = useState(null);
    const [loading, setLoading] = useState(false);
    const [vehicle, setVehicle] = useState(utils.driver.vehicle.get());
    const [devices, setDevices] = useState([]);
    const [driverLocation, setDriverLocation] = useState(utils.location.last());

    const onDeviceConnected = device => {
        setDevices(devices => {
            return devices.map(prevDevice => {
                if(prevDevice.id === device.id) {
                    prevDevice.connected = true;
                }
                return prevDevice;
            })
        })
    }

    const onDeviceDisconnected = device => {
        setDevices(devices => {
            return devices.map(prevDevice => {
                if(prevDevice.id === device.id) {
                    prevDevice.connected = false;
                }
                return prevDevice;
            })
        })
    }

    const onSearchNearbyDevices = async () => {
        try {
            console.log('searching');
            if(API.bluetooth) {
                console.log('ble enabled');
                await utils.bluetooth.get().request();
                utils.bluetooth.get().subscribe(panelID).start();
                utils.bluetooth.get().onDiscover = device => {
                    setDevices(devices => {
                        if(devices.find(prev_device => {
                            return prev_device.id === device.id;
                        })) {
                            return devices;
                        }
                        return update(devices, {
                            $push: [device]
                        });
                    })
                }
            }

        } catch(e) {
            console.error(e.message);
        }
    }

    const onShowVehicleOptions = () => {
        if(vehicle) {
            utils.sheet.show({
                title: vehicle.category.name,
                message: vehicle.vin,
                items: [{
                    key: 'return',
                    title: 'Return Vehicle',
                    style: 'default'
                }]
            }, (key) => {

                if(key === 'return') {
                    utils.alert.show({
                        title: 'Return Vehicle',
                        message: 'Are you sure that you want to return your vehicle? This will end your driving session for the day',
                        buttons: [{
                            key: 'confirm',
                            title: 'Yes',
                            style: 'destructive'
                        },{
                            key: 'cancel',
                            title: 'Do Not Return',
                            style: 'default'
                        }],
                        onPress: async key => {
                            if(key === 'confirm') {
                                try {
                                    await removeDriverVehicle(vehicle, utils);
                                    setVehicle(null);

                                } catch(e) {
                                    utils.alert.show({
                                        title: 'Oops!',
                                        message: `There was an issue returning your vehicle. ${e.message}`
                                    });
                                }
                            }
                        }
                    })
                }
            })
            return;
        }

        utils.layer.open({
            id: 'add-driver-vehicle',
            Component: AddDriverVehicle.bind(this, {
                onAddVehicle: vehicle => setVehicle(vehicle)
            })
        })
    }

    const getBleDevices = () => {
        if(!vehicle || devices.length === 0) {
            return null;
        }
        return devices.map((device, index) => {
            return (
                <BleDevice
                key={index}
                utils={utils}
                device={device}
                vehicle={vehicle}
                onConnect={onDeviceConnected.bind(this, device)}
                onDisconnect={onDeviceDisconnected.bind(this, device)}
                config={{
                    singleItem: devices.length === 1,
                    bottomBorder: index !== devices.length - 1
                }}/>
            )
        })
    }

    useEffect(() => {
        utils.location[vehicle ? 'start' : 'stop']();
        if(vehicle) {
            onSearchNearbyDevices();
        }
    }, [vehicle]);

    useEffect(() => {

        // external vehicle changes
        utils.events.addListener('on_driver_vehicle_change', ({ vehicle }) => {
            setVehicle(vehicle);
        });

        // location updates
        utils.location.subscribe(panelID, {
            onUpdate: location => {
                setDriverLocation(location);
            }
        })
        return () => {
            utils.location.unsubscribe(panelID);
            utils.events.removeListener('on_driver_vehicle_change');
            if(API.bluetooth) {
                utils.bluetooth.get().unsubscribe(panelID);
            }
        }
    }, [])

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'My Vehicle'}
        options={{
            loading: loading,
            shouldStyle: false,
            removeOverflow: true
        }}>
            <View style={Appearance.styles.panel()}>
                {vehicle && !searchText && (
                    <Map
                    utils={utils}
                    userLocationIcon={require('eCarra/images/tesla-model-s.png')}
                    {...driverLocation && {
                        userLocation: driverLocation,
                        userLocationIconStyle: {
                            iconSize: 0.4,
                            iconRotate: driverLocation.heading || 0
                        }
                    }}
                    onAnnotationChange={({ setCamera, annotations }) => {
                        setCamera({
                            zoomLevel: 12,
                            animationDuration: 100,
                            centerCoordinate: annotations[0]
                        })
                    }}
                    style={{
                        height: 250,
                        overflow: 'hidden',
                        borderTopRightRadius: 10,
                        borderTopLeftRadius: 10,
                        borderBottomWidth: 1,
                        borderBottomColor: Appearance.colors.divider()
                    }}/>
                )}
                {Views.entry({
                    title: vehicle ? vehicle.category.name : 'No Vehicle Selected',
                    subTitle: vehicle ? vehicle.vin : 'Choose a vehicle to get started',
                    onPress: onShowVehicleOptions,
                    bottomBorder: devices.length > 0,
                    icon: {
                        path: require('eCarra/images/vehicle-steering-wheel-grey-small.png'),
                        imageStyle: {
                            tintColor: 'white'
                        },
                        style: {
                            ...Appearance.icons.vehicle(),
                            borderColor: vehicle ? Appearance.colors.blue : Appearance.colors.red,
                            backgroundColor: vehicle ? Appearance.colors.blue : Appearance.colors.red
                        }
                    }
                })}
                {getBleDevices()}
            </View>
        </Panel>
    )
}

export const DriverReservationsList = ({ showAssignments }, { utils }) => {

    const panelID = showAssignments ? 'driverAssignmentsList' : 'driverReservationsList';
    const dateRef = useRef(null);

    const [date, setDate] = useState(moment());
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState(null);
    const [reservations, setReservations] = useState([]);

    const onChooseDate = () => {

        let tempDate = null;

        utils.layer.open({
            id: 'calendar-custom-date',
            Component: LayerShell.bind(this, {
                layerID: 'calendar-custom-date',
                extendedOptions: {
                    bottomCard: true
                },
                buttons: [{
                    key: 'done',
                    text: 'Done',
                    color: 'primary',
                    onPress: () => {
                        utils.events.emit('onLayerAction', {
                            action: 'close',
                            layerID: 'calendar-custom-date'
                        })
                        setDate(tempDate ? moment(tempDate) : date)
                    }
                }],
                children: (
                    <View style={{
                        alignItems: 'center',
                        marginTop: 5
                    }}>
                        <Text style={{
                            ...Appearance.textStyles.panelTitle(),
                            marginBottom: 6
                        }}>{'Browse Other Dates'}</Text>
                        <Text style={{
                            ...Appearance.textStyles.subTitle(),
                            marginBottom: 20,
                            textAlign: 'center'
                    }}>{'Choose a date from the list below to view more Reservations'}</Text>
                        <DatePicker
                        utils={utils}
                        date={date}
                        onChange={date => tempDate = date} />
                    </View>
                )
            })
        })
    }

    const onReservationUpdate = data => {
        try {
            let { current_pickup_date, original_pickup_date } = JSON.parse(data);
            if(moment().isSame(moment(current_pickup_date), 'day') || moment().isSame(moment(original_pickup_date), 'day')) {
                fetchReservations();
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    const getButtons = reservation => {

        let buttons = {};
        if(reservation.customer && reservation.customer.user_id !== utils.user.get().user_id) {
            if([
                StatusCodes.reservations.approved,
                StatusCodes.reservations.returned,
                StatusCodes.reservations.preauthorized,
                StatusCodes.reservations.adminUpdated,
                StatusCodes.reservations.driverUpdated
            ].includes(reservation.status.code)) {
                buttons.onStartRide = onStartRide;
            }
            if([
                StatusCodes.reservations.toPickup,
                StatusCodes.reservations.toDestination
            ].includes(reservation.status.code)) {
                buttons.onResumeRide = onResumeRide;
            }
        }
        return buttons;
    }

    const getLabelProps = () => {
        let target = moment(date);
        if(target.isSame(moment(), 'day')) {
            return {
                label: showAssignments ? 'Assignments' : 'Reservations'
            };
        }
        if(target.isSame(moment().add(1, 'days'), 'day')) {
            return {
                label: showAssignments ? 'Assignments' : 'Reservations',
                subLabel: `Tomorrow, ${target.format('MMM Do')}`
            };
        }
        if(target.isSame(moment().subtract(1, 'days'), 'day')) {
            return {
                label: showAssignments ? 'Assignments' : 'Reservations',
                subLabel: `Yesterday, ${target.format('MMM Do')}`
            };
        }
        if(target > moment() && target <= moment().add(6, 'days')) {
            return {
                label: showAssignments ? 'Assignments' : 'Reservations',
                subLabel: target.format('dddd, MMMM Do')
            };
        }
        if(target >= moment().subtract(6, 'days') && target <= moment()) {
            return {
                label: showAssignments ? 'Assignments' : 'Reservations',
                subLabel: target.format('dddd, MMMM Do')
            };
        }
        return {
            label: showAssignments ? 'Assignments' : 'Reservations',
            subLabel: target.format('MMMM Do, YYYY')
        };
    }

    const fetchReservations = async () => {
        try {
            await fetchReservationsAsync();
        } catch(e) {
            console.log(e.message);
        }
    }

    const fetchReservationsAsync = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { reservations } = await Request.get(utils, '/reservations/', {
                    type: 'all_admin',
                    assignments: showAssignments,
                    category: 'driver',
                    current_date: moment(dateRef.current).format('YYYY-MM-DD')
                });
                setLoading(false);
                setReservations(prev => {
                    prev.forEach(reservation => {
                        utils.sockets.off('reservations', `on_status_change_${prev.id}`, fetchReservations);
                    });
                    return reservations.map(props => {
                        let reservation = Reservation.create(props);
                        utils.sockets.on('reservations', `on_status_change_${reservation.id}`, fetchReservations);
                        return reservation;
                    });
                });
                resolve();

            } catch(e) {
                reject(e);
                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue loading the reservations list. ${e.message || 'An unknown error occurred'}`
                });
            }
        });
    }

    useEffect(() => {
        dateRef.current = date;
        fetchReservations();
    }, [date]);

    useEffect(() => {
        utils.sockets.on('reservations', 'on_new', fetchReservations);
        utils.sockets.on('reservations', 'on_update', onReservationUpdate);
        utils.sockets.on('reservations', `on_new_driver_assignment_${utils.user.get().user_id}`, fetchReservations);
        utils.sockets.on('reservations', `on_remove_driver_assignment_${utils.user.get().user_id}`, fetchReservations);
        utils.content.subscribe(panelID, 'reservations', {
            onFetch: fetchReservations,
            onUpdate: abstract => {
                setReservations(reservations => {
                    return reservations.map(reservation => abstract.compare(reservation));
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.structure.navigation.unsubscribe(panelID);
            utils.sockets.off('reservations', 'on_new', fetchReservations);
            utils.sockets.off('reservations', 'on_update', onReservationUpdate);
            utils.sockets.off('reservations', `on_new_driver_assignment_${utils.user.get().user_id}`, fetchReservations);
            utils.sockets.off('reservations', `on_remove_driver_assignment_${utils.user.get().user_id}`, fetchReservations);
            setReservations(prev => {
                prev.forEach(reservation => {
                    utils.sockets.off('reservations', `on_status_change_${prev.id}`, fetchReservations);
                });
                return prev;
            });
        }
    }, [])

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        header={(
            <HeaderWithButton
            {...getLabelProps()}
            button={'refresh'}
            loading={loading}
            onDropDownPress={onChooseDate}
            onAsyncPress={fetchReservationsAsync} />
        )}
        options={{
            removeOverflow: true,
            shouldStyle: false
        }}>
            <View style={Appearance.styles.panel()}>
                {reservations.length === 0
                    ?
                    Views.entry({
                        title: 'Nothing to see here',
                        subTitle: `No Reservations are ${showAssignments ? 'assigned to you' : 'booked'} for ${moment(date).format('MMM Do')}`,
                        hideIcon: true,
                        bottomBorder: false,
                        propStyles: {
                            subTitle: {
                                numberOfLines: 2
                            }
                        }
                    })
                    :
                    reservations.map((reservation, index) => {
                        if(searchText) {
                            let searchTargets = [
                                reservation.id,
                                reservation.customer.full_name,
                                reservation.origin.name,
                                reservation.origin.address,
                                reservation.destination.name,
                                reservation.destination.address
                            ];
                            if(!searchTargets.find(target => {
                                return target && target.toString().toLowerCase().includes(searchText);
                            })) {
                                return null;
                            }
                        }
                        return (
                            Views.entry({
                                key: index,
                                title: reservation.customer.full_name,
                                subTitle: `From ${reservation.origin.address}`,
                                badge: reservation.status.code !== StatusCodes.reservations.approved && reservation.status.code !== StatusCodes.reservations.returned ? {
                                    text: reservation.status.text,
                                    color: reservation.status.color
                                } : {
                                    text: moment(reservation.pickup_date).format('h:mma'),
                                    color: Appearance.colors.grey()
                                },
                                icon: {
                                    path: reservation.customer.avatar
                                },
                                propStyles: {
                                    subTitle: {
                                        numberOfLines: 2
                                    }
                                },
                                bottomBorder: index !== reservations.length - 1,
                                onPress: utils.layer.reservation.details.bind(this, reservation, getButtons(reservation))
                            })
                        )
                    })
                }
            </View>
        </Panel>
    )
}

// Layers
export const AddDriverVehicle = ({ onAddVehicle }, { utils, index, options }) => {

    const layerID = 'add-driver-vehicle';
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [vin, setVin] = useState(null);

    const onFetchVehicle = async () => {
        try {
            if(!vin) {
                throw new Error('Please type in the last 6 digits of your VIN to continue');
            }
            setLoading(true);
            Keyboard.dismiss();

            let { vehicle, mileageRequest } = await fetchDriverVehicle(utils, { vin: vin });
            if(mileageRequest) {
                await driverVehicleMileageRequest(vehicle, utils);
            }

            utils.driver.vehicle.set(vehicle);
            if(typeof(onAddVehicle) === 'function') {
                onAddVehicle(vehicle);
            }

            utils.alert.show({
                title: vehicle.category.name,
                message: `Your vehicle is setup to drive for the next 24 hours. Tap the details button (three dots icon) next to your vehicle and choose "Return Vehicle" when you are done driving for the day.`
            });
            setLoading(false);
            setLayerState('close');

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up your vehicle. ${e.message || 'An unknown error occurred'}`
            });
        }
    }
    return (
        <Layer
        id={layerID}
        title={'Add Driver Vehicle'}
        utils={utils}
        index={index}
        options={{
            ...options,
            bottomCard: true,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Cancel',
            color: 'grey',
            onPress: () => setLayerState('close')
        },{
            key: 'done',
            text: 'Done',
            color: 'primary',
            loading: loading,
            onPress: onFetchVehicle
        }]}>
            <View style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                marginTop: 5
            }}>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 6
                }}>{'Add a Vehicle'}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 20,
                    textAlign: 'center'
                }}>{'Please type the last 6 digits of your VIN below'}</Text>
                <TextField onChange={text => setVin(text)}/>
            </View>
        </Layer>
    )
}

export const DriverNavigation = ({ resuming }, { closeLayer, floatingLayerState, setFloatingLayerState, options, abstract, utils, index }) => {

    const layerID = `driver-navigation-${abstract.getTag()}`;

    const alerts = [];
    const toolbarHeight = 50;
    const progressRef = useRef(null);

    const [annotations, setAnnotations] = useState([]);
    const [audio, setAudio] = useState(false);
    const [chargers, setChargers] = useState(null);
    const [destination, setDestination] = useState(null);
    const [direction, setDirection] = useState(null);
    const [directionsTop, setDirectionsTop] = useState(new Animated.Value(-125));
    const [driverLocation, setDriverLocation] = useState(utils.location.last());
    const [driveToken, setDriveToken] = useState(null);
    const [flow, setFlow] = useState(null);
    const [followUserLocation, setFollowUserLocation] = useState(true);
    const [followUserMode, setFollowUserMode] = useState(Follow);
    const [incidents, setIncidents] = useState(null);
    const [layerContentHeight, setLayerContentHeight] = useState(new Animated.Value(0));
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(true);
    const [mapHeading, setMapHeading] = useState(0);
    const [placeIndex, setPlaceIndex] = useState(0);
    const [progress, setProgress] = useState(null);
    const [overlays, setOverlays] = useState([]);
    const [showChargers, setShowChargers] = useState(false);
    const [showFlow, setShowFlow] = useState(false);
    const [showIncidents, setShowIncidents] = useState(false);

    const onApplyLocationsForRoute = async () => {
        try {
            await onSetDriveTargets();
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your navigation locations. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onArriveAtWaypoint = () => {
        try {
            Utils.apply(abstract.type, {
                routes: onRouteDropOffPress.bind(this, true),
                orders: () => {
                    Utils.apply(abstract.object.status.code, {
                        [StatusCodes.orders.toPickup]: onOrderPickupPress.bind(this, true),
                        [StatusCodes.orders.toDestination]: onOrderDropOffPress.bind(this, true)
                    })
                },
                reservations: () => {
                    Utils.apply(abstract.object.status.code, {
                        [StatusCodes.reservations.toPickup]: onReservationPickupPress.bind(this, true),
                        [StatusCodes.reservations.toDestination]: onReservationDropOffPress.bind(this, true)
                    })
                }
            })

        } catch(e) {
            console.error(e.message);
        }
    }

    const onCalculatingDirections = async ({ nativeEvent }) => {
        setLoading(nativeEvent.loading);
    }

    const onCloseNavigation = () => {
        Utils.apply(abstract.type, {
            routes: () => {
                utils.alert.show({
                    title: 'Just a Second',
                    message: 'Are you sure that you want to close this QuickScan Route? It looks like you have not dropped off all of your customers.',
                    buttons: [{
                        key: 'confirm',
                        title: 'Mark as Completed',
                        style: 'default'
                    },{
                        key: 'return',
                        title: 'Return to Queue',
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'Cancel',
                        style: 'cancel'
                    }],
                    onPress: async key => {
                        if(key === 'return') {
                            onReturnToQueue();
                            return;
                        }
                        if(key === 'confirm') {
                            try {
                                await onUpdateStatus(StatusCodes.routes.completed);
                                utils.location.unsubscribe(layerID);
                                utils.content.update(abstract);
                                closeLayer(layerID);

                            } catch(e) {
                                console.error(e.message);
                            }
                        }
                    }
                })
            },
            orders: () => {
                utils.alert.show({
                    title: 'Just a Second',
                    message: `Do you want to mark this ${abstract.object.channel.name} Order as completed or return it to the queue for another driver? Completing this ${abstract.object.channel.name} Order will notify the customer and send the Order to payment processing`,
                    buttons: [{
                        key: 'confirm',
                        title: 'Mark as Completed',
                        style: 'default'
                    },{
                        key: 'return',
                        title: 'Return to Queue',
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'Cancel',
                        style: 'cancel'
                    }],
                    onPress: async key => {
                        if(key === 'return') {
                            onReturnToQueue();
                            return;
                        }
                        if(key === 'confirm') {
                            try {
                                await onUpdateStatus(StatusCodes.orders.completed);
                                utils.location.unsubscribe(layerID);
                                utils.content.update(abstract);
                                closeLayer(layerID);

                            } catch(e) {
                                console.error(e.message);
                            }
                        }
                    }
                })
            },
            reservations: () => {
                utils.alert.show({
                    title: 'Just a Second',
                    message: 'Do you want to mark this Reservation as completed or return it to the queue for another driver? Completing this Reservation will notify the customer and send the Reservation to payment processing',
                    buttons: [{
                        key: 'confirm',
                        title: 'Mark as Completed',
                        style: 'default'
                    },{
                        key: 'return',
                        title: 'Return to Queue',
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'Cancel',
                        style: 'cancel'
                    }],
                    onPress: async key => {
                        if(key === 'return') {
                            onReturnToQueue();
                            return;
                        }
                        if(key === 'confirm') {
                            try {
                                await onUpdateStatus(StatusCodes.reservations.completed);
                                utils.location.unsubscribe(layerID)
                                utils.content.update(abstract);
                                closeLayer(layerID);

                            } catch(e) {
                                console.error(e.message);
                            }
                        }
                    }
                })
            }
        })
    }

    const onConfigureNavigation = async () => {

        // set floating layer status
        setFloatingLayerState('open');

        // set driver to current user and update seeds object
        abstract.object.driver = utils.user.get();
        utils.content.update(abstract);

        // setup listener for external vehicle changes
        utils.events.addListener('on_driver_vehicle_change', ({ vehicle }) => {
            if(vehicle) {
                return;
            }
            utils.alert.show({
                title: 'Vehicle Change',
                message: `It looks like the vehicle attached to your account has been removed. Please contact your administrators if you did not request this change.`,
                onPress: onReturnToQueue
            });
        });

        // update vehicle id in driver vehicle targets
        utils.driver.vehicle.targets.add({
            ...abstract.type === 'orders' && {
                order_id: abstract.getID()
            },
            ...abstract.type === 'reservations' && {
                reservation_id: abstract.getID()
            },
            ...abstract.type === 'routes' && {
                route_id: abstract.getID()
            }
        })

        // setup target locations and drive token for current target
        try {
            await onSetDriveTargets();
            let token = await fetchDriveToken();
            setDriveToken(token);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up your credentials for this ${Utils.apply(abstract.type, {
                    orders: () => `${abstract.object.channel} Order`,
                    routes: () => 'QuickScan Route',
                    reservations: () => 'Reservation'
                })}. ${e.message}`,
                onPress: () => closeLayer(layerID)
            });
            try {
                await onUpdateStatus(StatusCodes[abstract.type].returned);
                utils.location.unsubscribe(layerID);
                utils.content.update(abstract);
                closeLayer(layerID);

            } catch(e) {
                console.error(e.message);
                closeLayer(layerID); // fallback to closed layer if try/catch fails
            }
        }
    }

    const onControlPress = () => {
        Utils.apply(abstract.type, {
            routes: () => {
                let reservation = abstract.object.getCurrentReservation();
                utils.sheet.show({
                    items: [{
                        key: 'route',
                        title: 'View Route',
                        style: 'default'
                    },{
                        key: 'reservation',
                        title: 'View Reservation',
                        style: 'default'
                    },{
                        key: 'music',
                        title: 'Start Music Playlist',
                        style: 'default',
                        visible: reservation.music ? true:false
                    }]
                }, async key => {
                    try {
                        if(key === 'route') {
                            setFloatingLayerState('minimized');
                            await Utils.sleep(1);
                            utils.layer.route.details(abstract.object)
                            return;
                        }
                        if(key === 'reservation' && reservation) {
                            setFloatingLayerState('minimized');
                            await Utils.sleep(1);
                            utils.layer.reservation.details(reservation);
                            return;
                        }
                        if(key === 'music') {
                            utils.layer.openFloating({
                                id: 'music-player',
                                abstract: Abstract.create({
                                    type: 'reservations',
                                    object: reservation
                                }),
                                Component: MusicPlayer
                            });
                            return;
                        }
                    } catch(e) {
                        console.error(e.message);
                    }
                })
            },
            orders: () => {
                utils.sheet.show({
                    items: [{
                        key: 'order',
                        title: `View ${abstract.object.channel.name} Order`,
                        style: 'default'
                    },{
                        key: 'contact',
                        title: `Contact ${abstract.object.customer.full_name}`,
                        style: 'default'
                    }]
                }, async key => {
                    try {
                        if(key === 'order') {
                            setFloatingLayerState('minimized');
                            await Utils.sleep(1);
                            utils.layer.order.details(abstract.object);
                            return;
                        }
                        if(key === 'contact') {
                            onContactUser();
                            return;
                        }
                    } catch(e) {
                        console.error(e.message);
                    }
                })
            },
            reservations: () => {
                utils.sheet.show({
                    items: [{
                        key: 'reservation',
                        title: 'View Reservation',
                        style: 'default'
                    },{
                        key: 'music',
                        title: 'Start Music Playlist',
                        style: 'default',
                        visible: abstract.object.music ? true:false
                    },{
                        key: 'contact',
                        title: `Contact ${abstract.object.customer.full_name}`,
                        style: 'default'
                    }]
                }, async key => {
                    try {
                        if(key === 'reservation') {
                            setFloatingLayerState('minimized');
                            await Utils.sleep(1);
                            utils.layer.reservation.details(abstract.object)
                            return;
                        }
                        if(key === 'music') {
                            utils.layer.openFloating({
                                id: 'music-player',
                                abstract: abstract,
                                Component: MusicPlayer
                            });
                            return;
                        }
                        if(key === 'contact') {
                            onContactUser();
                            return;
                        }
                    } catch(e) {
                        console.error(e.message);
                    }
                })
            }
        })
    }

    const onContactUser = () => {
        utils.sheet.show({
            title: abstract.object.customer.full_name,
            message: `How would you like to contact ${abstract.object.customer.first_name}?`,
            items: [{
                key: 'call',
                title: 'Call',
                style: 'default'
            },{
                key: 'text',
                title: 'Text Message',
                style: 'default'
            },{
                key: 'message',
                title: 'In App Message',
                style: 'default'
            }]
        }, (key) => {
            if(key === 'call') {
                Call(abstract.object.customer.phone_number, true).catch(e => {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue setting up your call. ${e.message || 'An unknown error occurred'}`
                    })
                })

            } else if(key === 'text') {
                TextMessage(abstract.object.customer.phone_number).catch(e => {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue setting up your text message. ${e.message || 'An unknown error occurred'}`
                    })
                })

            } else if(key === 'message') {
                setLayerState('close');
                setTimeout(() => {
                    utils.layer.messaging(abstract);
                }, 1000);
            }
        })
    }

    const onDirectionsError = error => {
        utils.alert.show({
            title: 'Oops!',
            message: `There was an issue finding your driving directions. ${error.message || 'An unknown error occurred'}`,
            buttons: [{
                key: 'close',
                title: 'Close Navigation',
                style: 'cancel'
            }],
            onPress: () => {
                closeLayer(layerID);
                utils.location.unsubscribe(layerID).stop();
            }
        })
    }

    const onReturnToQueue = async () => {
        try {
            switch(abstract.type) {
                case 'orders':
                await onUpdateStatus(StatusCodes.orders.returned);
                break;

                case 'reservations':
                await onUpdateStatus(StatusCodes.reservations.returned);
                break;

                case 'routes':
                await onUpdateStatus(StatusCodes.routes.returned);
                break;
            }
            utils.location.unsubscribe(layerID);
            utils.content.update(abstract);

            await Utils.sleep(1);
            closeLayer(layerID);

        } catch(e) {
            console.error(e.message);
        }
    }

    const onGetDirections = route => {
        navigation.startNavigation();
        setOverlays(() => {
            if(!route) {
                return [];
            }
            return [{
                key: `driver-route-${placeIndex}`,
                coordinates: route.polyline
            }]
        });
    }

    const onNavigationError = ({ nativeEvent }) => {
        utils.alert.show({
            title: 'Oops!',
            message: `There was an issue with your navigation. ${nativeEvent.message || 'An unknown error occurred'}`
        })
    }

    const onOrderDropOffPress = estimated => {

        let place = getPlace();
        let remainingStops = abstract.object.getNavigationLocations();
        utils.alert.show({
            title: 'Customer Drop-Off',
            message: `${estimated === true ? `It looks like you have arrived at ${place.name || place.address}. ` : ''}Have you dropped off the ${abstract.object.channel.name} Order for ${abstract.object.customer.full_name}?`,
            ...remainingStops.length > 1 && {
                title: 'Next Stop',
                message: `Have you arrived at ${place.name || place.address}?`,
            },
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Not Yet',
                style: 'cancel'
            }],
            onPress: async key => {
                if(key !== 'confirm') {
                    return;
                }

                //navigation.stopNavigation();
                if(remainingStops.length > 1) {
                    try {
                        setLoading(true);
                        await abstract.object.setStopAsCompleted(utils, placeIndex);
                        setPlaceIndex(placeIndex + 1);

                    } catch(e) {
                        setLoading(false);
                        utils.alert.show({
                            title: 'Oops!',
                            message: `There was an issue marking this stop as completed. ${e.message || 'An unknown error occurred'}`
                        })
                    }
                    return;
                }

                try {
                    await onUpdateStatus(StatusCodes.orders.completed);
                    utils.alert.show({
                        title: 'All Done!',
                        message: `${abstract.object.channel.name} Order #${abstract.getID()} for ${abstract.object.customer.full_name} has been completed`,
                        buttons: [{
                            key: 'close',
                            title: 'Close Navigation',
                            style: 'default'
                        }],
                        onPress: () => {
                            closeLayer(layerID);
                            utils.content.update(abstract);
                            utils.location.unsubscribe(layerID);
                        }
                    });

                } catch(e) {
                    console.error(e.message);
                }
            }
        })

    }

    const onOrderPickupPress = estimated => {
        let place = getPlace();
        utils.alert.show({
            title: 'Order Pickup',
            message: `${estimated === true ? `It looks like you have arrived at ${place.name || place.address}. ` : ''}Have you picked up the ${abstract.object.channel.name} Order for ${abstract.object.customer.full_name}?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Not Yet',
                style: 'cancel'
            }],
            onPress: async key => {
                if(key === 'confirm') {
                    try {
                        await onUpdateStatus(StatusCodes.orders.toDestination);
                    } catch(e) {
                        console.error(e.message);
                    }
                }
            }
        })
    }

    const onReservationDropOffPress = estimated => {

        let place = getPlace();
        let remainingStops = abstract.object.getNavigationLocations();
        utils.alert.show({
            title: 'Customer Drop-Off',
            message: `${estimated === true ? `It looks like you have arrived at ${place.name || place.address}. ` : ''}Have you dropped off ${abstract.object.customer.full_name}?`,
            ...remainingStops.length > 1 && {
                title: 'Next Stop',
                message: `Have you arrived at ${place.name || place.address}?`,
            },
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Not Yet',
                style: 'cancel'
            }],
            onPress: async key => {
                if(key === 'confirm') {
                    if(remainingStops.length > 1) {
                        try {
                            setLoading(true);
                            await abstract.object.setStopAsCompleted(utils, placeIndex);
                            setPlaceIndex(placeIndex + 1);

                        } catch(e) {
                            setLoading(false);
                            utils.alert.show({
                                title: 'Oops!',
                                message: `There was an issue marking this stop as completed. ${e.message || 'An unknown error occurred'}`
                            })
                        }
                        return;
                    }

                    try {
                        await onUpdateStatus(StatusCodes.reservations.completed);
                        utils.alert.show({
                            title: 'All Done!',
                            message: `Reservation #${abstract.getID()} for ${abstract.object.customer.full_name} has been completed`,
                            buttons: [{
                                key: 'close',
                                title: 'Close Navigation',
                                style: 'default'
                            }],
                            onPress: () => {
                                closeLayer(layerID);
                                utils.content.update(abstract);
                                utils.location.unsubscribe(layerID);
                            }
                        })
                    } catch(e) {
                        console.error(e.message);
                    }
                }
            }
        })
    }

    const onReservationPickupPress = estimated => {
        let place = getPlace();
        utils.alert.show({
            title: 'Customer Pickup',
            message: `${estimated === true ? `It looks like you have arrived at ${place.name || place.address}. ` : ''}Have you picked up ${abstract.object.customer.full_name}?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Not Yet',
                style: 'cancel'
            }],
            onPress: async key => {
                if(key === 'confirm') {
                    try {
                        await onUpdateStatus(StatusCodes.reservations.toDestination);
                    } catch(e) {
                        console.error(e.message);
                    }
                }
            }
        })
    }

    const onRouteDropOffPress = estimated => {

        let place = getPlace();
        let reservation = abstract.object.getCurrentReservation();

        utils.alert.show({
            title: 'Customer Drop-Off',
            message: `${estimated === true ? `It looks like you have arrived at ${place.name || place.address}. ` : ''}Have you dropped off ${reservation.customer.full_name}?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Not Yet',
                style: 'cancel'
            }],
            onPress: async key => {
                if(key === 'confirm') {
                    try {
                        // mark current reservation as completed
                        setLoading(true);
                        await reservation.updateStatus(utils, StatusCodes.reservations.completed)

                        // mark route as completed if no more reservations are found
                        let next = abstract.object.getNextReservation();
                        if(!next) {

                            await onUpdateStatus(StatusCodes.routes.completed);
                            setLoading(false);

                            utils.alert.show({
                                title: 'All Done!',
                                message: 'Your QuickScan Route has been completed',
                                buttons: [{
                                    key: 'close',
                                    title: 'Close Navigation',
                                    style: 'default'
                                }],
                                onPress: () => {
                                    closeLayer(layerID);
                                    utils.content.update(abstract);
                                    utils.location.unsubscribe(layerID);
                                }
                            })
                            return;
                        }

                        // move onto next reservation
                        abstract.object.setCurrentReservation(next);
                        let token = await fetchDriveToken();
                        setDriveToken(token);

                        setLoading(false);
                        onApplyLocationsForRoute();

                    } catch(e) {
                        setLoading(false);
                        utils.alert.show({
                            title: 'Oops!',
                            message: `There was an issue updating the status for this Reservation. ${e.message || 'An unknown error occurred'}`
                        })
                    }
                }
            }
        })
    }

    const onRouteProgressChange = ({ nativeEvent }) => {

        let { remainingDuration } = nativeEvent || {};

        // update progress values
        setProgress({ time_to_arrival: remainingDuration });

        // animate direction into view if this is the first reported direction
        setDirection(nativeEvent);
        if(!direction) {
            setLoading(false);
            Animated.spring(directionsTop, {
                toValue: 0,
                friction: 10,
                duration: 750,
                useNativeDriver: false
            }).start()
        }
    }

    const onRouteRecalculation = route => {
        setOverlays(route ? [{
            key: `driver-route-${placeIndex}`,
            coordinates: route.polyline
        }] : []);
    }

    const onSetDriveTargets = async () => {
        return new Promise(async (resolve, reject) => {
            try {

                // fetch current location for driver
                let location = await utils.location.get();

                // routes
                if(abstract.type === 'routes') {
                    try {

                        // fetch current reservation and remaining reservations in route
                        let reservation = abstract.object.getFirstReservation();
                        let reservations = abstract.object.getRemainingReservations();

                        // prevent moving forward if no additional reservations are available
                        if(!reservation || reservations.length === 0) {

                            //navigation.stopNavigation();
                            utils.alert.show({
                                title: 'Oops!',
                                message: `It looks like all the Reservations for this Route have been completed`,
                                buttons: [{
                                    key: 'close',
                                    title: 'Close Navigation',
                                    style: 'default'
                                }],
                                onPress: async () => {
                                    try {
                                        await onUpdateStatus(StatusCodes.routes.completed);
                                        utils.location.unsubscribe(layerID);
                                        utils.content.update(abstract);
                                        closeLayer(layerID);

                                    } catch(e) {
                                        console.error(e.message);
                                    }
                                }
                            });
                            return;
                        }

                        // set next reservation as the active reservation and start it
                        abstract.object.setCurrentReservation(reservation);
                        await abstract.object.startCurrentReservation(utils);

                        /*
                        navigation.getDirections(utils, location.heading, [
                            location,
                            reservation.destination.location
                        ]);
                        */

                        // set next reservation destination as next destination for navigation
                        setDestination(reservation.destination.location);

                        // update annotations for map
                        setAnnotations(reservations.map((reservation, index) => {
                            return {
                                id: `navigation-${index}`,
                                title: reservation.destination.name,
                                subTitle: reservation.destination.address,
                                location: reservation.destination.location
                            }
                        }));
                        resolve();

                    } catch(e) {
                        utils.alert.show({
                            title: 'Oops!',
                            message: `There was an issue preparing Reservation #${reservation.id} for ${reservation.customer.full_name}. ${e.message || 'An unknown error occurred'}`,
                            onPress: () => closeLayer(layerID)
                        });
                    }
                    return;
                }

                // orders
                if(abstract.type === 'orders') {

                    // set target origin as next navigation destination so driver can pickup the order
                    if(abstract.object.status.code === StatusCodes.orders.toPickup) {
                        setDestination(abstract.object.origin.location);
                        resolve();
                        return;
                    }

                    // set target destination as next navigation destination so driver can drop-off the order
                    if([StatusCodes.orders.arrivedAtHost, StatusCodes.orders.toDestination].includes(abstract.object.status.code)) {

                        // check if there are remaining stops in the order
                        let stops = abstract.object.getNavigationLocations();
                        if(stops.length === 0) {
                            utils.alert.show({
                                title: 'All Done!',
                                message: `It looks like all of the stops for this ${abstract.object.channel.name} Order have been completed. Do you want to end this ${abstract.object.channel.name} Order?`,
                                buttons: [{
                                    key: 'confirm',
                                    title: 'Yes',
                                    style: 'default'
                                },{
                                    key: 'cancel',
                                    title: 'Not Yet',
                                    style: 'cancel'
                                }],
                                onPress: async key => {
                                    if(key === 'confirm') {
                                        try {
                                            //navigation.stopNavigation();
                                            await onUpdateStatus(StatusCodes.orders.completed);

                                            utils.location.unsubscribe(layerID);
                                            utils.content.update(abstract);
                                            closeLayer(layerID);

                                        } catch(e) {
                                            console.error(e.message);
                                        }
                                    }
                                }
                            });
                            return;
                        }

                        // set next order stop destination as next navigation destination
                        setDestination(stops[0].location);

                        // update annotations for map
                        setAnnotations(stops.map((place, index) => {
                            return {
                                id: `navigation-${index}`,
                                title: place.name,
                                subTitle: place.address,
                                location: place.location
                            }
                        }));
                        resolve();
                    }
                }

                // reservations
                if(abstract.type === 'reservations') {

                    // set target origin as next navigation destination so driver can pickup the customer
                    if(abstract.object.status.code === StatusCodes.reservations.toPickup) {
                        setDestination(abstract.object.origin.location);
                        resolve();
                        return;
                    }

                    // check if there are remaining stops in the reservation
                    let stops = abstract.object.getNavigationLocations();
                    if(stops.length === 0) {
                        utils.alert.show({
                            title: 'All Done!',
                            message: `It looks like all of the stops for this Reservation have been completed. Do you want to end this Reservation?`,
                            buttons: [{
                                key: 'confirm',
                                title: 'Yes',
                                style: 'default'
                            },{
                                key: 'cancel',
                                title: 'Not Yet',
                                style: 'cancel'
                            }],
                            onPress: async key => {
                                if(key === 'confirm') {
                                    try {
                                        //navigation.stopNavigation();
                                        await onUpdateStatus(StatusCodes.reservations.completed);

                                        utils.location.unsubscribe(layerID);
                                        utils.content.update(abstract);
                                        closeLayer(layerID);

                                    } catch(e) {
                                        console.error(e.message);
                                    }
                                }
                            }
                        });
                        return;
                    }

                    // set next reservation stop destination as next navigation destination
                    setDestination(stops[0].location);

                    // update annotations for map
                    setAnnotations(stops.map((place, index) => {
                        return {
                            id: `navigation-${index}`,
                            title: place.name,
                            subTitle: place.address,
                            location: place.location
                        }
                    }));
                    resolve();
                }

            } catch(e) {
                reject(e);
            }
        })
    }

    const onStartNavigation = () => {
        setFollowUserLocation(true);
        setFollowUserMode(FollowWithCourse);
    }

    const onStopNavigation = () => {
        setFollowUserLocation(false);
        setFollowUserMode(null);
    }

    const onUpdateLocation = async locations => {
        try {

            // get driver vehicle details
            let vehicle = utils.driver.vehicle.get();
            if(!vehicle) {
                console.log('no vehicle');
                return;
            }

            // send order navigation details to socket server if applicable
            if(abstract.type === 'orders') {
                await utils.sockets.emit('orders', 'active_update', {
                    date: moment().format('YYYY-MM-DD HH:mm:ss'),
                    locations: locations,
                    token: driveToken,
                    vehicle_id: vehicle.id,
                    time_to_arrival: progressRef.current ? progressRef.current.time_to_arrival : null,
                    order: {
                        id: abstract.getID(),
                        status: abstract.object.status.code
                    }
                })
                return;

                driver, locations, order, token, vehicle_id
            }

            // send reservation navigation details to socket server if applicable
            if([ 'reservations', 'routes' ].includes(abstract.type)) {

                // get current reservation for route
                let reservation = abstract.object;
                if(abstract.type === 'routes') {
                    reservation = abstract.object.getCurrentReservation();
                }
                if(!reservation) {
                    console.log('no reservation found for active update');
                    return;
                }

                // send props to socket server
                await utils.sockets.emit('reservations', 'active_update', {
                    date: moment().format('YYYY-MM-DD HH:mm:ss'),
                    locations: locations,
                    token: driveToken,
                    vehicle_id: vehicle.id,
                    time_to_arrival: progressRef.current ? progressRef.current.time_to_arrival : null,
                    reservation: {
                        id: reservation.id,
                        status: reservation.status.code,
                        passengers: reservation.passengers
                    }
                })
            }

        } catch(e) {
            console.error(e.message);
        }
    }

    const onUpdateNavigationLocation = ({ nativeEvent }) => {
        let { heading, latitude, longitude, speed } = nativeEvent;
        setDriverLocation(props => update(props, {
            heading: {
                $set: heading
            },
            latitude: {
                $set: latitude
            },
            longitude: {
                $set: longitude
            },
            speed: {
                $set: speed
            }
        }))
    }

    const onUpdateStatus = async status => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                await abstract.object.updateStatus(utils, status);
                if(status !== StatusCodes[abstract.type].completed) {
                    onApplyLocationsForRoute();
                }

                setLoading(false);
                resolve();

            } catch(e) {

                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: Utils.apply(abstract.type, {
                        orders: () => `There was an issue updating the status for this ${abstract.object.channel.name} order. ${e.message || 'An unknown error occurred'}`,
                        default: () => `There was an issue updating the status for this reservation. ${e.message || 'An unknown error occurred'}`
                    })
                });
                reject(e);
            }
        })
    }

    const onUserCancelledNavigation = () => {
        console.warn('"onUserCancelledNavigation" not implemented');
    }

    const getActionButtons = () => {

        // Only show follow button is ride is currently in progress
        let objectInProgress = Utils.apply(abstract.type, {
            routes: () => {
                let reservation = abstract.object.getCurrentReservation();
                return [
                    StatusCodes.reservations.toPickup,
                    StatusCodes.reservations.toDestination
                ].includes(reservation.status.code)
            },
            orders: () => {
                return [
                    StatusCodes.orders.toPickup,
                    StatusCodes.orders.arrivedAtHost,
                    StatusCodes.orders.toDestination
                ].includes(abstract.object.status.code)
            },
            reservations: () => {
                return [
                    StatusCodes.reservations.toPickup,
                    StatusCodes.reservations.toDestination
                ].includes(abstract.object.status.code)
            },
            default: () => false
        });

        let followButton = objectInProgress && followUserMode !== FollowWithCourse && (
            <View style={{
                paddingRight: 4,
                width: '50%'
            }}>
                <Button
                label={'Follow'}
                color={Appearance.colors.blue}
                onPress={() => {
                    setFollowUserLocation(true);
                    setFollowUserMode(FollowWithCourse);
                }} />
            </View>
        )

        // Pickup and drop off buttons
        let buttons = null;
        let viewEntryProps = {};
        switch(abstract.type) {
            case 'routes':
            let reservation = abstract.object.getCurrentReservation();
            viewEntryProps = {
                title: reservation.customer.full_name,
                subTitle: reservation.customer.phone_number,
                icon: {
                    path: reservation.customer.avatar
                },
            }
            buttons = (
                <View style={{
                    width: followButton ? '50%' : '100%',
                    paddingLeft: followButton ? 4:0
                }}>
                    <Button
                    label={`Drop-Off ${reservation.customer.first_name}`}
                    color={'primary'}
                    onPress={onRouteDropOffPress} />
                </View>
            )
            break;

            case 'orders':
            viewEntryProps = {
                title: abstract.object.customer.full_name,
                subTitle: abstract.object.customer.phone_number,
                icon: {
                    path: abstract.object.customer.avatar
                }
            }
            buttons = Utils.apply(abstract.object.status.code, {
                [StatusCodes.orders.toPickup]: () => (
                    <View style={{
                        width: followButton ? '50%' : '100%',
                        paddingLeft: followButton ? 4:0
                    }}>
                        <Button
                        label={'Pickup'}
                        color={'primary'}
                        onPress={onOrderPickupPress} />
                    </View>
                ),
                [StatusCodes.orders.arrivedAtHost]: () => (
                    <View style={{
                        width: followButton ? '50%' : '100%',
                        paddingLeft: followButton ? 4:0
                    }}>
                        <Button
                        label={abstract.object.getNavigationLocations().length > 1 ? 'Next Stop' : 'Drop-Off'}
                        color={'primary'}
                        onPress={onOrderDropOffPress} />
                    </View>
                ),
                [StatusCodes.orders.toDestination]: () => (
                    <View style={{
                        width: followButton ? '50%' : '100%',
                        paddingLeft: followButton ? 4:0
                    }}>
                        <Button
                        label={abstract.object.getNavigationLocations().length > 1 ? 'Next Stop' : 'Drop-Off'}
                        color={'primary'}
                        onPress={onOrderDropOffPress} />
                    </View>
                )
            })
            break;

            case 'reservations':
            viewEntryProps = {
                title: abstract.object.customer.full_name,
                subTitle: abstract.object.customer.phone_number,
                icon: {
                    path: abstract.object.customer.avatar
                }
            }
            buttons = Utils.apply(abstract.object.status.code, {
                [StatusCodes.reservations.toPickup]: () => (
                    <View style={{
                        width: followButton ? '50%' : '100%',
                        paddingLeft: followButton ? 4:0
                    }}>
                        <Button
                        label={'Pickup'}
                        color={'primary'}
                        onPress={onReservationPickupPress} />
                    </View>
                ),
                [StatusCodes.reservations.toDestination]: () => (
                    <View style={{
                        width: followButton ? '50%' : '100%',
                        paddingLeft: followButton ? 4:0
                    }}>
                        <Button
                        label={abstract.object.getNavigationLocations().length > 1 ? 'Next Stop' : 'Drop-Off'}
                        color={'primary'}
                        onPress={onReservationDropOffPress} />
                    </View>
                )
            });
            break;
        }

        return (
            <View style={{
                position: 'relative',
                width: '100%'
            }}>
                <View style={{
                    ...Appearance.styles.panel(),
                    width: '100%'
                }}>
                    {Views.entry({
                        ...viewEntryProps,
                        bottomBorder: false,
                        rightContent: (
                            <TouchableOpacity
                            activeOpacity={0.6}
                            onPress={onControlPress}
                            style={{
                                width: 20,
                                height: 20
                            }}>
                                <Image
                                source={require('eCarra/images/vertical-details-grey-small.png')}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    resizeMode: 'contain',
                                    tintColor: Appearance.themeStyle() === 'dark' ? 'white' : Appearance.colors.grey()
                                }} />
                            </TouchableOpacity>
                        )
                    })}

                    {loading && (
                        <View style={{
                            position: 'relative',
                            height: 2,
                            width: '100%'
                        }}>
                            <ProgressBar animate={true}/>
                        </View>
                    )}
                    {(buttons || followButton) && (
                        <View style={{
                            padding: 12,
                            borderTopWidth: 1,
                            borderTopColor: Appearance.colors.divider(),
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                            {followButton}
                            {buttons}
                        </View>
                    )}
                </View>
            </View>
        )
    }

    const getControls = () => {
        return direction ? getActionButtons() : null;
    }

    const getDirections = () => {

        if(!direction || floatingLayerState === 'closed') {
            return null;
        }

        let { subTitle } = getTargetInformation();
        let directionImage = null;
        switch(direction.currentStep.direction) {
            case 'right':
            directionImage = require('eCarra/images/navigation-right.png');
            break;

            case 'left':
            directionImage = require('eCarra/images/navigation-left.png');
            break;

            case 'u-turn':
            directionImage = require('eCarra/images/navigation-u-turn.png');
            break;

            case 'sharp-left':
            directionImage = require('eCarra/images/navigation-sharp-left.png');
            break;

            case 'sharp-right':
            directionImage = require('eCarra/images/navigation-sharp-right.png');
            break;

            case 'slight-left':
            directionImage = require('eCarra/images/navigation-slight-left.png');
            break;

            case 'slight-right':
            directionImage = require('eCarra/images/navigation-slight-right.png');
            break;

            default:
            directionImage = require('eCarra/images/navigation-strait-ahead.png');
        }

        return (
            <>
            <TouchableOpacity
            activeOpacity={0.6}
            onPress={() => setFloatingLayerState('open')}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                padding: 15,
                backgroundColor: 'black',
                opacity: Appearance.themeStyle() === 'dark' ? 1 : 0.75,
                height: floatingLayerState === 'open' ? 'auto' : null,
                minHeight: FloatingLayerMinimizedHeight
            }}>
                <Image
                source={directionImage}
                style={{
                    width: 40,
                    height: 40,
                    resizeMode: 'contain',
                    tintColor: 'white',
                    marginRight: 15
                }}/>
                <View style={{
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: 0,
                    paddingHorizontal: 15,
                    width: Screen.layer.maxWidth - 70
                }}>
                    <Text
                    numberOfLines={floatingLayerState === 'open' ? 5 : 1}
                    style={{
                        ...Appearance.textStyles.panelTitle(),
                        fontSize: 22,
                        color: 'white',
                    }}>
                        {direction.currentStep && direction.currentStep.text ? Utils.ucFirst(direction.currentStep.text) : 'Waiting on next direction...'}
                    </Text>
                    <Text style={{
                        ...Appearance.textStyles.title(),
                        color: 'white'
                    }}>
                        {Utils.distanceConversion(direction.currentStep.distanceToEnd / 1609, true)}
                    </Text>
                </View>
                {floatingLayerState !== 'open' && (
                    <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={() => setFloatingLayerState('open')}
                    style={{
                        width: 25,
                        height: 25,
                        padding: 3,
                        marginHorizontal: 12
                    }}>
                        <Image
                        source={require('eCarra/images/up-arrow-grey-small.png')}
                        style={{
                            width: '100%',
                            height: '100%',
                            resizeMode: 'contain',
                            tintColor: 'white'
                        }} />
                    </TouchableOpacity>
                )}
            </TouchableOpacity>
            {floatingLayerState === 'open' && direction.remainingDistance > 0 && (
                <View style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: Appearance.colors.panelBackground(),
                    width: Screen.layer.maxWidth,
                    paddingHorizontal: 15,
                    paddingVertical: 12,
                    opacity: 0.9
                }}>
                    <Text
                    numberOfLines={1}
                    style={{
                        ...Appearance.textStyles.title(),
                        ...Appearance.fontWeight.get(700),
                        marginBottom: 4
                    }}>{subTitle || 'Destination Not Available'}</Text>
                    <Text style={{
                        ...Appearance.textStyles.subTitle(),
                        ...Appearance.fontWeight.get(500)
                    }}>{`Arriving at ${moment().add(direction.remainingDistance, 'seconds').format('h:mma')}`}</Text>
                </View>
            )}
            {floatingLayerState === 'open' && (
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%'
                }}>
                    <View style={{
                        ...Appearance.styles.panel(),
                        width: 75,
                        paddingHorizontal: 8,
                        paddingVertical: 12,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 12,
                        marginLeft: 12,
                        opacity: direction.speedLimit > 0 ? 1 : 0
                    }}>
                        <Text style={{
                            ...Appearance.textStyles.title()
                        }}>{'Speed'}</Text>
                        <Text style={{
                            ...Appearance.textStyles.title()
                        }}>{'Limit'}</Text>
                        <Text style={{
                            ...Appearance.textStyles.title(),
                            fontSize: 28,
                            ...Appearance.fontWeight.get(700)
                        }}>{parseInt(direction.speedLimit)}</Text>
                    </View>
                    {Platform.OS === 'ios' && (
                        <TouchableOpacity
                        activeOpacity={0.6}
                        onPress={() => setAudio(!audio)}
                        style={{
                            ...Appearance.styles.panel(),
                            borderRadius: 25,
                            width: 50,
                            height: 50,
                            padding: 5,
                            overflow: 'hidden',
                            marginTop: 12,
                            marginRight: 12,
                            backgroundColor: audio ? Appearance.colors.grey() : Appearance.colors.panelBackground()
                        }}>
                            <Image
                            source={require('eCarra/images/audio-button-clear.png')}
                            style={{
                                width: '100%',
                                height: '100%',
                                resizeMode: 'contain',
                                tintColor: audio ? 'white' : Appearance.colors.grey(),
                            }}/>
                        </TouchableOpacity>
                    )}
                </View>
            )}
            </>
        )
    }

    const getHeaderContent = () => {
        if(floatingLayerState === 'open') {

            let { title } = getTargetInformation();
            return (
                <View style={{
                    minHeight: LayerToolbarHeight + (floatingLayerState === 'open' ? 0 : Screen.safeArea.bottom)
                }}>
                    <LayerToolbar
                    title={title}
                    onClose={onCloseNavigation}
                    floatingLayerState={floatingLayerState}
                    onFloatingLayerStateChange={props => {
                        Keyboard.dismiss();
                        if(typeof(setFloatingLayerState) === 'function') {
                            setFloatingLayerState(props);
                        }
                    }}
                    style={{
                        paddingBottom: floatingLayerState === 'open' ? 0 : Screen.safeArea.bottom
                    }}/>
                </View>
            )
        }

        return getDirections();
    }

    const getNavigationOptions = () => {
        return (
            <View style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                width: 60,
                marginRight: 12,
                marginBottom: 12
            }}>
                <View style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={() => setAudio(!audio)}
                    style={{
                        ...Appearance.styles.panel(),
                        borderRadius: 25,
                        width: 50,
                        height: 50,
                        padding: 5,
                        overflow: 'hidden',
                        marginBottom: 5,
                        backgroundColor: audio ? Appearance.colors.grey() : Appearance.colors.panelBackground()
                    }}>
                        <Image
                        source={require('eCarra/images/audio-button-clear.png')}
                        style={{
                            width: '100%',
                            height: '100%',
                            resizeMode: 'contain',
                            tintColor: audio ? 'white' : Appearance.colors.grey(),
                        }}/>
                    </TouchableOpacity>
                    <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={() => setShowChargers(!showChargers)}
                    style={{
                        ...Appearance.styles.panel(),
                        borderRadius: 25,
                        width: 50,
                        height: 50,
                        padding: 5,
                        overflow: 'hidden',
                        marginBottom: 5,
                        backgroundColor: showChargers ? Appearance.colors.grey() : Appearance.colors.panelBackground()
                    }}>
                        <Image
                        source={require('eCarra/images/chargers-button-clear.png')}
                        style={{
                            width: '100%',
                            height: '100%',
                            resizeMode: 'contain',
                            tintColor: showChargers ? 'white' : Appearance.colors.grey(),
                        }}/>
                    </TouchableOpacity>
                    <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={() => setShowFlow(!showFlow)}
                    style={{
                        ...Appearance.styles.panel(),
                        borderRadius: 25,
                        width: 50,
                        height: 50,
                        padding: 5,
                        overflow: 'hidden',
                        marginBottom: 5,
                        backgroundColor: showFlow ? Appearance.colors.grey() : Appearance.colors.panelBackground()
                    }}>
                        <Image
                        source={require('eCarra/images/traffic-button-clear.png')}
                        style={{
                            width: '100%',
                            height: '100%',
                            resizeMode: 'contain',
                            tintColor: showFlow ? 'white' : Appearance.colors.grey(),
                        }}/>
                    </TouchableOpacity>
                    <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={() => setShowIncidents(!showIncidents)}
                    style={{
                        ...Appearance.styles.panel(),
                        borderRadius: 25,
                        width: 50,
                        height: 50,
                        padding: 5,
                        overflow: 'hidden',
                        backgroundColor: showIncidents ? Appearance.colors.grey() : Appearance.colors.panelBackground(),
                    }}>
                        <Image
                        source={require('eCarra/images/construction-button-clear.png')}
                        style={{
                            width: '100%',
                            height: '100%',
                            resizeMode: 'contain',
                            tintColor: showIncidents ? 'white' : Appearance.colors.grey()
                        }}/>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    const getTargetInformation = () => {

        return Utils.apply(abstract.type, {
            routes: () => {
                let reservation = abstract.object.getCurrentReservation();
                return {
                    title: `QuickScan Route #${abstract.getID()}`,
                    subTitle: reservation ? reservation.destination.address : null
                };
            },
            orders: () => {
                let place = getPlace();
                return {
                    title: `${abstract.object.channel.name} Order #${abstract.getID()}`,
                    subTitle: place ? place.address : null
                };
            },
            reservations: () => {
                let place = getPlace();
                return {
                    title: `Reservation #${abstract.getID()}`,
                    subTitle: place ? place.address : null
                };
            }
        })
    }

    const getLocations = useCallback(() => {
        // destination is only returned if the driver location coordinate AND the destination coordinate are set
        // native module navigation routing is triggered in the desination setter
        return {
            ...driverLocation && {
                origin: [ driverLocation.longitude, driverLocation.latitude ]
            },
            ...driverLocation && destination && {
                destination: [ destination.longitude, destination.latitude ]
            }
        }
    }, [ destination, driverLocation])

    const getPlace = () => {
        return Utils.apply(abstract.type, {
            routes: () => {
                let reservation = abstract.object.getCurrentReservation();
                return reservation ? reservation.destination : null;
            },
            orders: () => {
                let locations = abstract.object.status.code === StatusCodes.orders.toPickup ? [abstract.object.origin] : abstract.object.getNavigationLocations();
                return locations.length > 0 ? locations[0] : null;
            },
            reservations: () => {
                let locations = abstract.object.status.code === StatusCodes.reservations.toPickup ? [abstract.object.origin] : abstract.object.getNavigationLocations();
                return locations.length > 0 ? locations[0] : null;
            }
        })
    }

    const getLoader = () => {
        if(loading === false) {
            return null;
        }
        return (
            <View style={{
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: Appearance.colors.layerBackground()
            }}>
                <LottieView
                autoPlay={true}
                loop={true}
                source={Appearance.themeStyle() === 'dark' ? require('eCarra/files/lottie/dots-white.json') : require('eCarra/files/lottie/dots-grey.json')}
                duration={2500}
                style={{
                    width: 40,
                    height: 40
                }}/>
            </View>
        )
    }

    const fetchChargers = async () => {
        try {
            if(!showChargers || chargers) {
                return
            }
            let location = await utils.location.get();
            if(!location) {
                throw new Error('Unable to get current location');
            }

            let { chargers } = await Request.get(utils, '/resources/', {
                type: 'get_chargers',
                lat: location.latitude,
                long: location.longitude
            });
            setChargers(chargers);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading nearby chargers. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchDriveToken = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let { drive_token } = await Request.get(utils, '/driver/', {
                    type: 'request_drive_token',
                    resuming: resuming,
                    ...abstract.type === 'orders' && {
                        order_id: abstract.getID()
                    },
                    ...abstract.type === 'routes' && {
                        reservation_id: abstract.object.getCurrentReservation().id
                    },
                    ...abstract.type === 'reservations' && {
                        reservation_id: abstract.getID()
                    }
                });
                resolve(drive_token);

            } catch(e) {
                reject(e);
            }
        })
    }

    const fetchFlow = async () => {
        try {
            if(showFlow && !flow) {
                let results = await Here.flow(utils, utils.location.last());
                setFlow(results);
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    const fetchIndidents = async () => {
        try {
            if(showIncidents && !incidents) {
                let results = await Here.incidents(utils, utils.location.last());
                setIncidents(results);
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        onApplyLocationsForRoute();
    }, [placeIndex]);

    useEffect(() => {
        fetchFlow();
    }, [showFlow]);

    useEffect(() => {
        fetchIndidents();
    }, [showIncidents]);

    useEffect(() => {
        fetchChargers();
    }, [showChargers]);

    useEffect(() => {
        switch(floatingLayerState) {
            case 'open':
            Animated.spring(layerContentHeight, {
                toValue: Screen.height() - Screen.safeArea.top - toolbarHeight,
                friction: 10,
                duration: 750,
                useNativeDriver: false
            }).start()
            break;

            case 'closed':
            Animated.spring(layerContentHeight, {
                toValue: 0,
                friction: 10,
                duration: 750,
                useNativeDriver: false
            }).start()
            break;

            case 'minimized':
            Animated.spring(layerContentHeight, {
                toValue: 0,
                friction: 10,
                duration: 750,
                useNativeDriver: false
            }).start()
            break;
        }

    }, [floatingLayerState]);

    useEffect(() => {
        if(driveToken) {
            utils.location.subscribe(layerID, {
                onUpdate: location => setDriverLocation(location),
                onUpdateMany: onUpdateLocation
            })
        }
    }, [driveToken]);

    useEffect(() => {
        progressRef.current = progress;
    }, [progress]);

    useEffect(() => {

        onConfigureNavigation();
        return () => {
            //navigation.unsubscribe(layerID);
            utils.location.unsubscribe(layerID);

            // Update vehicle ID targets
            utils.driver.vehicle.targets.remove(abstract.type === 'orders' ? 'order_id' : 'reservation_id');

            utils.events.removeListener('on_driver_vehicle_change');
        }

    }, []);

    return (
        <View
        id={layerID}
        options={{
            layerState: layerState
        }}
        style={{
            backgroundColor: Appearance.themeStyle() === 'dark' ? '#181818' : '#E0E0E0'
        }}>
            <TouchableOpacity
            activeOpacity={floatingLayerState === 'open' ? 1:0.6}
            onPress={floatingLayerState === 'open' ? null : () => setFloatingLayerState('open')}>
                {getHeaderContent()}
            </TouchableOpacity>

            {/* navigation content */}
            <Animated.View style={{
                width: '100%',
                height: layerContentHeight,
                overflow: 'hidden'
            }}>
                <View style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                }}>
                    <MapboxNavigation
                    {...getLocations()}
                    shouldAnnounceDirections={audio}
                    shouldSimulateRoute={API.dev_env}
                    showsEndOfRouteFeedback={false}
                    onLocationChange={onUpdateNavigationLocation}
                    onRouteProgressChange={onRouteProgressChange}
                    onError={onNavigationError}
                    onCancelNavigation={onUserCancelledNavigation}
                    onCalculatingDirections={onCalculatingDirections}
                    onArrive={onArriveAtWaypoint}
                    styleURL={getMapStyleURL()}
                    chargers={chargers}
                    shouldShowTraffic={showFlow}
                    shouldFollowLocation={followUserMode === FollowWithCourse}
                    onFollowModeChange={({ nativeEvent }) => {
                        setFollowUserMode(nativeEvent.follow === true ? FollowWithCourse : Follow);
                    }} />
                    {getLoader()}
                </View>

                {/* directions and controls */}
                <Animated.View style={{
                    display: floatingLayerState === 'open' ? 'flex' : 'none',
                    position: 'absolute',
                    top: directionsTop,
                    left: 0,
                    right: 0
                }}>
                    {getDirections()}
                </Animated.View>
                <View style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: '100%',
                    paddingTop: 12,
                    paddingBottom: 12 + Screen.safeArea.bottom,
                    paddingHorizontal: 12
                }}>
                    {getControls()}
                </View>
            </Animated.View>
        </View>
    )
}

export const EditReservationItem = ({ children, item, onChange }, { index, options, utils }) => {

    const layerID = `edit-reservation-${item.key}`;
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const [layerState, setLayerState] = useState(null);

    useEffect(() => {
        utils.keyboard.subscribe(layerID, {
            onVisibility: visible => setKeyboardOpen(visible)
        });

        return () => {
            utils.keyboard.unsubscribe(layerID);
        }
    }, [])

    return (
        <Layer
            id={layerID}
            title={item.title}
            utils={utils}
            index={index}
            options={{
                ...options,
                bottomCard: true,
                layerState: layerState
            }}
            buttons={[{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onPress: () => {
                    Keyboard.dismiss();
                    if(typeof(onChange) === 'function') {
                        setTimeout(() => {
                            onChange(() => setLayerState('close')); // validated content
                        }, keyboardOpen ? 500 : 0);
                    }
                }
            }]}>

            <View style={{
                alignItems: 'center',
                marginTop: 5
            }}>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 6
                }}>{item.title}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 20,
                    textAlign: 'center'
                }}>{item.on_edit.description}</Text>
                {children()}
            </View>
        </Layer>
    )
}

export const FriendsAndFamily = ({ onChange, prevUsers, abstract }, { index, options, utils }) => {

    const layerID = 'friends-and-family';
    const [contact, setContact] = useState(null);
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState(prevUsers || []);

    const [fields, setFields] = useState([{
        key: 'first_name',
        placeholder: 'First Name',
        icon: 'user'
    },{
        key: 'last_name',
        placeholder: 'Last Name',
        icon: 'user'
    },{
        key: 'phone_number',
        placeholder: 'Phone Number',
        icon: 'phone',
        keyboardType: 'phone-pad',
        format: 'phone_number'
    }])

    const onContactsPress = () => {

        utils.layer.open({
            id: 'contacts-picker',
            Component: ContactsPicker.bind(this, {
                onChange: contact => {

                    if(!contact) {
                        return;
                    }

                    setContact(contact);
                    setFields(fields => update(fields, {
                        $apply: fields => fields.map(field => {

                            switch(field.key) {
                                case 'first_name':
                                    field.value = contact.first_name;
                                    break;
                                case 'last_name':
                                    field.value = contact.last_name;
                                    break;
                                case 'phone_number':
                                    field.value = contact.phone_number;
                                    break;
                            }
                            return field;
                        })
                    }))
                }
            })
        })
    }

    const onCreateAccount = async email_address => {
        try {
            setLoading(true);
            let { user } = await Request.post(utils, '/user/', {
                type: 'express_signup',
                email_address: email_address,
                ...fields.reduce((object, field) => ({
                    ...object,
                    [field.key]: field.value
                }), {})
            });

            setLoading(false);
            setUsers(users => update(users, {
                $push: [ User.create(user) ]
            }))

            // reset fields
            setFields(fields => fields.map(field => {
                field.value = null;
                return field;
            }))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating an account for ${props.first_name}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onLookupUser = async () => {

        Keyboard.dismiss();
        let props = fields.reduce((object, field) => {
            return {
                ...object,
                [field.key]: field.value
            }
        }, {})
        if(!props.first_name || !props.last_name || !props.phone_number) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'Please fill out all of the fields before adding a friend or family member'
            });
            return;
        }

        try {
            setLoading(true);
            let { user } = await Request.get(utils, '/user/', {
                type: 'lookup',
                ...props
            });

            // prompt user to create an account for the target user since they were not found in the system
            if(!user) {
                setLoading(false);
                utils.alert.show({
                    title: 'No Account Found',
                    message: `We were unable to find an account for ${props.first_name} ${props.last_name}. Would you like to automatically create an account for them? Please provide ${props.first_name}'s email address below so we can send them information about setting up their new account.`,
                    textFields: [{
                        key: 'email_address',
                        value: contact ? contact.email_address : null,
                        placeholder: `${props.first_name}'s Email Address`,
                        autoCapitalize: false,
                        textContentType: 'email_address',
                        autoCompleteType: 'email',
                        keyboardType: 'email-address'
                    }],
                    buttons: [{
                        key: 'confirm',
                        title: 'Create Account',
                        style: 'default'
                    },{
                        key: '_cancel',
                        title: 'Maybe Later',
                        style: 'destructive'
                    }],
                    onPress: response => {
                        if(!response || !response.email_address) {
                            setFields(fields => fields.map(field => {
                                field.value = null;
                                return field;
                            }))
                            return;
                        }
                        onCreateAccount(response.email_address);
                    }
                })
                return;
            }

            // prevent adding duplicates to the selected users list
            let target = User.create(user);
            if(users.find(u => {
                return target.user_id === u.user_id;
            })) {
                setLoading(false);
                utils.alert.show({
                    title: 'Just a Second',
                    message: `It looks like you have already added ${target.full_name}`,
                    onPress: () => {
                        setFields(fields => fields.map(field => {
                            field.value = null;
                            return field;
                        }))
                    }
                });
                return;
            }

            // add user to list since user was not found previously
            setLoading(false);
            setUsers(users => update(users, {
                $push: [target]
            }))

            // reset input fields
            setFields(fields => fields.map(field => {
                field.value = null;
                return field;
            }))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue locating ${props.first_name} ${props.last_name}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUserPress = user => {
        Keyboard.dismiss();
        utils.sheet.show({
            items: [{
                key: 'remove',
                title: `Remove ${user.first_name}`,
                style: 'destructive'
            }]
        }, (key) => {
            if(key === 'remove') {
                setUsers(users => update(users, {
                    $apply: users => users.filter(u => u.user_id !== user.user_id)
                }))
            }
        })
    }

    const onUserSelected = () => {
        Keyboard.dismiss();
        if(fields.find(field => field.value)) {
            onLookupUser();
            return;
        }
        setLayerState('close');
        if(typeof(onChange) === 'function') {
            onChange(users);
        }
    }

    const getButtons = () => {
        let user = fields.find(field => field.value);
        return [{
            key: 'contacts',
            text: 'Use Contacts',
            color: 'grey',
            visible: Platform.OS !== 'web',
            onPress: () => {
                Keyboard.dismiss();
                onContactsPress();
            }
        },{
            key: 'done',
            text: 'Done',
            ...user && {
                text: Utils.apply(abstract.type, {
                    orders: () => 'Add to Order',
                    reservations: () =>  'Add to Ride'
                })
            },
            loading: loading,
            color: 'primary',
            onPress: onUserSelected
        }]
    }

    useEffect(() => {
        utils.keyboard.subscribe(layerID, {
            onVisibility: visible => setKeyboardOpen(visible)
        });

        return () => {
            utils.keyboard.unsubscribe(layerID);
        }
    }, [])

    return (
        <Layer
        id={layerID}
        title={'Friends and Family'}
        index={index}
        utils={utils}
        buttons={getButtons()}
        options={{
            ...options,
            bottomCard: true,
            layerState: layerState
        }}>
            <View style={{
                alignItems: 'center',
                marginTop: 5,
                width: '100%'
            }}>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 6
                }}>{'Friends and Family'}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 20,
                    textAlign: 'center'
                }}>{Utils.apply(abstract.type, {
                    orders: () => `Adding a friend of family member to your ${abstract.object.channel.name} Order will help us keep them up to date with devliery information. Please provide their first name, last name, and phone number below`,
                    reservations: () => 'Adding a friend of family member to your Reservation will help us keep them up to date throughout the journey. Please provide their first name, last name, and phone number below'
                })}</Text>
                {fields.filter(field => field.visible !== false).map((field, index) => {
                    return (
                        <TextField
                        key={index}
                        placeholder={field.placeholder}
                        value={field.value}
                        icon={field.icon}
                        format={field.format}
                        keyboardType={field.keyboardType}
                        onChange={text => setFields(fields => update(fields, {
                            $apply: fields => fields.map(f => {
                                if(f.key === field.key) {
                                    f.value = text
                                }
                                return f;
                            })
                        }))}
                        containerStyle={{
                            marginBottom: 8
                        }}/>
                    )
                })}
                {users && users.length > 0 && (
                    <View style={{
                        ...Appearance.styles.panel(),
                        marginVertical: 15,
                        width: '100%'
                    }}>
                        {users.map((user, index) => {
                            return (
                                Views.entry({
                                    key: index,
                                    title: user.full_name,
                                    subTitle: user.phone_number,
                                    icon: {
                                        path: user.avatar,
                                        style: Appearance.icons.user
                                    },
                                    onPress: onUserPress.bind(this, user),
                                    bottomBorder: index !== users.length - 1
                                })
                            )
                        })}
                    </View>
                )}
            </View>
        </Layer>
    )
}

export const TripNavigator = ({ closeLayer, floatingLayerState, setFloatingLayerState, utils, abstract }) => {

    const layerID = `trip-navigator-${abstract.getTag()}`;
    const extraPadding = Platform.OS === 'web' || Utils.isMobile() === false ? 30 : 0;
    const mapCamera = useRef(null);

    const [driver, setDriver] = useState(abstract.object.driver);
    const [content, setContent] = useState([]);
    const [overlays, setOverlays] = useState([]);
    const [emissions, setEmissions] = useState(null);
    const [showEmissions, setShowEmissions] = useState(false);
    const [sharingLocation, setSharingLocation] = useState(false);
    const [maxHeight, setMaxHeight] = useState(new Animated.Value(0));
    const [paddingBottom, setPaddingBottom] = useState(new Animated.Value(15 + Screen.safeArea.bottom));

    const options = [{
        key: 'contact',
        title: `Contact Driver`,
        subTitle: abstract.object.driver ? abstract.object.driver.full_name : 'Name Not Available',
        icon: abstract.object.driver ? abstract.object.driver.avatar : utils.client.get().logos.mobile,
        iconStyle: {
            padding: abstract.object.driver ? 0 : 8
        }
    },{
        key: 'emissions',
        title: `Emissions`,
        subTitle: showEmissions ? 'Tap to Hide' : 'Follow Your Progress',
        icon: require('eCarra/images/trip-navigator-emissions-icon.png')
    },{
        key: 'location',
        title: sharingLocation ? 'Sharing Location' : 'Share Location',
        subTitle: sharingLocation ? 'Tap To Learn More' : 'Keep Friends and Family Updated',
        lottie: {
            source: sharingLocation ? require('eCarra/files/lottie/location-broadcast-white.json') : require('eCarra/files/lottie/location-icon-white.json'),
            style: {
                backgroundColor: Appearance.colors.blue
            }
        }
    },{
        key: 'about',
        title: Utils.apply(abstract.type, {
            orders: () => 'My Order',
            reservations: () => 'My Reservation'
        }),
        subTitle: Utils.apply(abstract.type, {
            orders: () => 'View Your Order Details',
            reservations: () => 'View Your Reservation Details'
        }),
        icon: Utils.apply(abstract.type, {
            orders: () => require('eCarra/images/trip-navigator-order-icon.png'),
            reservations: () => require('eCarra/images/trip-navigator-reservation-icon.png')
        }),
        iconStyle: {
            padding: 8
        }
    }];

    const onCameraReady = props => {
        mapCamera.current = props;
        onUpdateMapRegion();
    }

    const onClose = () => {

        if(sharingLocation) {
            utils.alert.show({
                title: 'Close Navigator',
                message: 'Are you sure that you want to close your Navigator? You are currently sharing your location but location sharing will end if your Navigator is closed',
                buttons: [{
                    key: 'minimize',
                    title: 'Minimize',
                    style: 'default'
                },{
                    key: 'close',
                    title: 'Close',
                    style: 'destructive'
                },{
                    key: 'cancel',
                    title: 'Dismiss',
                    style: 'cancel'
                }],
                onPress: (key) => {
                    if(key === 'minimize') {
                        setFloatingLayerState('minimized');
                        return;
                    }
                    if(key === 'close') {
                        closeLayer(layerID);
                        utils.location.unsubscribe(layerID).stop();
                        return;
                    }
                }
            })
            return;
        }
        closeLayer(layerID);
    }

    const onContentPress = content => {
        if(content.url) {
            utils.layer.webView({
                id: `webview-${content.id}`,
                title: content.title,
                url: content.url
            });
            return;
        }
        utils.alert.show({
            title: content.title,
            message: content.description
        })
    }

    const onLocationUpdate = data => {
        try {
            let { driver, emissions } = JSON.parse(data) || {};
            if(!driver.location) {
                throw new Error('missing driver location in payload');
            }
            if(driver.location.polyline) {
                let coordinates = Utils.decodePolyline(driver.location.polyline)
                setOverlays([{
                    key: 'driver_route',
                    coordinates: coordinates.map(entry => [ entry[1], entry[0] ])
                }])
            }

            setEmissions(emissions);
            setDriver(props => {
                if(!props) {
                    return props;
                }
                return update(props, {
                    heading: {
                        $set: driver.location.heading
                    },
                    time_to_arrival: {
                        $set: driver.location.time_to_arrival
                    },
                    location: {
                        $set: {
                            latitude: driver.location.lat,
                            longitude: driver.location.long
                        }
                    }
                });
            })

        } catch(e) {
            console.error(e.message)
        }
    }

    const onOptionPress = async option => {
        switch(option.key) {
            case 'about':
            try {
                switch(abstract.type) {
                    case 'orders':
                    setFloatingLayerState('minimized');
                    await Utils.sleep(1);
                    utils.layer.order.details(abstract.object);
                    break;

                    case 'reservations':
                    setFloatingLayerState('minimized');
                    await Utils.sleep(1);
                    utils.layer.reservation.details(abstract.object);
                    break;
                }
            } catch(e) {
                console.error(e.message);
            }
            break;

            case 'emissions':
            if(!emissions || emissions.length === 0) {
                utils.alert.show({
                    title: 'Emissions',
                    message: 'It looks like your emissions information is not currently available. Check back later to follow your progress'
                });
                return
            }
            setShowEmissions(!showEmissions);
            break;

            case 'contact':
            utils.sheet.show({
                items: [{
                    key: 'call',
                    title: 'Call',
                    style: 'default'
                },{
                    key: 'text',
                    title: 'Text Message',
                    style: 'default'
                },{
                    key: 'message',
                    title: 'In App Message',
                    style: 'default'
                }]
            }, (key) => {

                if(key === 'message') {
                   utils.layer.messaging(abstract)

               } else if(key === 'call') {

                    if(!abstract.object.driver) {
                        utils.alert.show({
                            title: 'Oops!',
                            message: `There was an issue setting up your customer call. We were unable to locate the driver's phone number`
                        })
                        return;
                    }

                    Call(abstract.object.driver.phone_number, true).catch(e => {
                        utils.alert.show({
                            title: 'Oops!',
                            message: `There was an issue setting up your call. ${e.message || 'An unknown error occurred'}`
                        })
                    })

                } else if(key === 'text') {
                    if(!abstract.object.driver) {
                        utils.alert.show({
                            title: 'Oops!',
                            message: `There was an issue setting up your text message. We were unable to locate the driver's phone number`
                        })
                        return;
                    }

                    TextMessage(abstract.object.driver.phone_number).catch(e => {
                        utils.alert.show({
                            title: 'Oops!',
                            message: `There was an issue setting up your text message. ${e.message || 'An unknown error occurred'}`
                        })
                    })
                }
            });
            break;

            case 'location':
            if(sharingLocation) {
                utils.alert.show({
                    title: option.title,
                    message: 'You are currently sharing your location with friends and family. You can share the link below for anyone to follow your progress on their mobile device, tablet, or computer',
                    buttons: [{
                        key: 'url',
                        title: 'Share Link',
                        style: 'default'
                    },{
                        key: 'confirm',
                        title: 'End Location Sharing',
                        style: 'destructive'
                    },{
                        key: 'cancel',
                        title: 'Dismiss',
                        style: 'cancel'
                    }],
                    onPress: (key) => {
                        if(key === 'confirm') {
                            setSharingLocation(false);
                            utils.location.unsubscribe(layerID).stop();
                            return;
                        }
                        if(key === 'url') {
                            getShareLocationURL();
                            return;
                        }
                    }
                })
                return;
            }

            utils.alert.show({
                title: option.title,
                message: 'Our top priority for our members is their safety. We offer a feature to share your real-time ride location with friends and family so they can follow your ride progress. Would you like to share your location?',
                buttons: [{
                    key: 'confirm',
                    title: 'Share',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Do Not Share',
                    style: 'destructive'
                }],
                onPress: key => {
                    if(key === 'confirm') {
                        onStartSharingLocation();
                        return;
                    }
                }
            })
            break;
        }
    }

    const onStartSharingLocation = () => {
        setSharingLocation(true);
        utils.location.subscribe(layerID, {
            onUpdateMany: async locations => {
                try {
                    await utils.sockets.emit('locations', 'active_user_update', {
                        user_id: utils.user.get().user_id,
                        locations: locations,
                        abstract: {
                            type: abstract.type,
                            id: abstract.getID()
                        }
                    })
                } catch(e) {
                    console.error(e.message);
                }
            }
        }).start();
    }

    const onUpdateMapRegion = () => {
        if(!mapCamera.current) {
            return;
        }
        let annotations = getAnnotations();
        if(annotations.length === 0) {
            return;
        }
        let region = Utils.getRegionFromAnnotations(annotations.map(annotation => {
            return {
                location: annotation.location || {
                    latitude: annotation.lat || annotation.latitude,
                    longitude: annotation.long || annotation.longitude
                }
            }
        }));
        mapCamera.current.fitBounds(region);
    }

    const getAnnotations = () => {
        let annotations = abstract.object.getLocations();
        if(driver && driver.location) {
            annotations.push(driver.location);
        }
        return annotations;
    }

    const getArrivalTime = () => {
        if(!driver || !driver.time_to_arrival) {
            return null;
        }
        return `Arriving at ${moment().add(driver.time_to_arrival, 'seconds').format('h:mma')}`;
    }

    const getCarouselItemStyles = () => {
        return {
            paddingTop: 12,
            paddingLeft: 12,
            paddingRight: 12,
            paddingBottom: 12
        }
    }

    const getCarouselProps = target => {
        return {
            loop: Platform.OS !== 'web',
            sliderWidth: Screen.layer.maxWidth,
            itemWidth: Screen.layer.maxWidth - 80,
            ...Utils.isMobile() === true && {
                layoutCardOffset: 0
            },
            ...(Platform.OS === 'web' || Utils.isMobile() === false) && {
                sliderWidth: Screen.layer.maxWidth - 15,
                itemWidth: Screen.layer.maxWidth / 2
            }
        }
    }

    const getOverlays = () => {
        let targets = abstract.object.getOverlays() || [];
        return targets.concat(overlays);
    }

    const getShareLocationURL = async () => {
        try {
            let { url } = await Request.get(utils, '/user/', {
                type: 'location_sharing_url',
                ...Utils.apply(abstract.type, {
                    orders: () => ({
                        order_id: abstract.getID()
                    }),
                    reservations: () => ({
                        reservation_id: abstract.getID()
                    })
                })
            })
            Share.share({
                url: url, // iOS
                message: Platform.OS === 'ios' ? null : url,  // Android
                title: Platform.OS === 'ios' ? null : Utils.apply(abstract.type, {
                    orders: () => `${utils.client.get().name} Order progress`,
                    reservations: () => `${utils.client.get().name} Reservation progress`
                }) // Android
            },{
                subject: Utils.apply(abstract.type, {
                    orders: () => `Follow my ${utils.client.get().name} Order progress`,
                    reservations: () => `Follow my ${utils.client.get().name} Reservation progress`
                }) // iOS
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the url. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const setupModularContent = async () => {
        try {
            let { content } = await Utils.fetchModularContent(utils, 'trip_navigator');
            setContent(content);
        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        Animated.spring(maxHeight, {
            toValue: floatingLayerState === 'open' ? (Screen.height() - Screen.safeArea.top - extraPadding) : (50 + Screen.safeArea.bottom),
            duration: 500,
            friction: 10,
            useNativeDriver: false
        }).start();
    }, [floatingLayerState]);

    useEffect(() => {

        setupModularContent();
        setTimeout(() => {
            setFloatingLayerState('open');
        }, 500);

        // keyboard events
        utils.keyboard.subscribe(layerID, {
            onShow: (e) => {
                Animated.spring(paddingBottom, {
                    toValue: 15 + e.endCoordinates.height,
                    friction: 10,
                    duration: 150,
                    useNativeDriver: false
                }).start()
            },
            onChange: (e) => {
                Animated.spring(paddingBottom, {
                    toValue: 15 + e.endCoordinates.height,
                    friction: 10,
                    duration: 150,
                    useNativeDriver: false
                }).start();
            },
            onHide: () => {
                Animated.spring(paddingBottom, {
                    toValue: 15 + Screen.safeArea.bottom,
                    friction: 10,
                    duration: 150,
                    useNativeDriver: false
                }).start();
            }
        })

        // subscribe to active updates for location
        utils.sockets.on(abstract.type, `on_active_update_${abstract.getID()}`, onLocationUpdate);

        // subscribe to status change events
        utils.sockets.subscribe(layerID, {
            on_status_change: response => {
                if(response.abstract.getTag() !== abstract.getTag()) {
                    return;
                }
                Utils.apply(response.status, {
                    [StatusCodes.reservations.returned]: () => {
                        utils.alert.show({
                            title: 'Driver Update',
                            message: `It looks like your driver was unable to complete your ${abstract.object.channel.name}. We'll connect you with the next available driver.`
                        });
                    },
                    default: () => {
                        if([
                            StatusCodes.reservations.unpaid,
                            StatusCodes.reservations.completed
                        ].includes(status)) {
                            closeLayer(layerID);
                            utils.content.update(abstract);
                            utils.location.unsubscribe(layerID).stop();
                            utils.quickFeedback.show({ abstract: abstract });
                        }
                    }
                })
            }
        });

        return () => {
            utils.sockets.unsubscribe(layerID);
            utils.keyboard.unsubscribe(layerID);
            utils.sockets.off(abstract.type, `on_active_update_${abstract.getID()}`, onLocationUpdate);
        }

    }, []);

    return (
        <Animated.View style={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: maxHeight,
            paddingBottom: paddingBottom,
            backgroundColor: Appearance.colors.layerBackground(),
            overflow: 'hidden',
            ...Platform.OS === 'web' && {
                height: '100vh'
            }
        }}>
            <View style={{
                minHeight: LayerToolbarHeight + (floatingLayerState === 'open' ? 0 : Screen.safeArea.bottom)
            }}>
                <LayerToolbar
                title={Utils.apply(abstract.type, {
                    orders: () => `${abstract.object.host.name} Order #${abstract.getID()}`,
                    reservations: () => `Reservation #${abstract.getID()}`
                })}
                subTitle={getArrivalTime() || 'Waiting on driver arrival time...'}
                onClose={onClose}
                floatingLayerState={floatingLayerState}
                onFloatingLayerStateChange={props => {
                    Keyboard.dismiss();
                    if(typeof(setFloatingLayerState) === 'function') {
                        setFloatingLayerState(props);
                    }
                }}
                style={{
                    paddingBottom: floatingLayerState === 'open' ? 0 : Screen.safeArea.bottom
                }}/>
            </View>

            <View style={{
                flexGrow: 1,
                position: 'relative',
                width: '100%',
                overflow: 'hidden'
            }}>
                <Map
                driver={driver}
                utils={utils}
                isScrollEnabled={true}
                isRotationEnabled={true}
                isZoomEnabled={true}
                annotations={getAnnotations()}
                overlays={getOverlays()}
                maxZoomLevel={17}
                onCameraReady={onCameraReady}
                onAnnotationChange={onUpdateMapRegion}
                cameraPadding={{
                    bottom: 165 + Screen.safeArea.bottom,
                    top: content && content.length > 0 ? 160 : 25
                }}
                style={{
                    width: Screen.layer.maxWidth,
                    height: Screen.height() - TopSpacing - LayerSpacing - 25
                }}/>

                <View style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0
                }}>
                    {content && content.length > 1 && (
                        <View style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            width: '100%',
                            marginTop: 15
                        }}>
                            {content.map((_, index) => {
                                return (
                                    <View
                                    key={index}
                                    style={{
                                        width: 15,
                                        height: 3,
                                        borderRadius: 1.5,
                                        backgroundColor: Appearance.colors.grey(),
                                        marginHorizontal: 4
                                    }}/>
                                )
                            })}
                        </View>
                    )}

                    <ScrollView
                    horizontal={true}
                    pagingEnabled={true}
                    showsHorizontalScrollIndicator={false}
                    style={{
                        width: '100%'
                    }}
                    contentContainerStyle={{
                        paddingTop: 12,
                        paddingBottom: 20
                    }}>
                        {content.map((content, index) => {
                            return (
                                <View key={index}
                                style={{
                                    width: Screen.layer.maxWidth - 60,
                                    paddingHorizontal: 20
                                }}>
                                    <View style={Appearance.styles.panel()}>
                                        {Views.entry({
                                            title: content.title,
                                            subTitle: content.message,
                                            bottomBorder: false,
                                            propStyles: {
                                                subTitle: {
                                                    numberOfLines: 3
                                                }
                                            },
                                            onPress: onContentPress.bind(this, content)
                                        })}
                                    </View>
                                </View>
                            )
                        })}
                    </ScrollView>
                </View>

                {/* emissions */}
                {showEmissions && emissions && emissions.length > 0 && (
                    <View style={{
                        position: 'absolute',
                        left: 15,
                        right: 15,
                        bottom: 155 + Screen.safeArea.bottom
                    }}>
                        <View style={{
                            ...Appearance.styles.panel(),
                            paddingHorizontal: 15,
                            paddingVertical: 10
                        }}>
                            <View style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'space-around',
                                alignItems: 'center'
                            }}>
                                {emissions.map((emission, index) => {
                                    return (
                                        <View
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}>
                                            <Text style={{
                                                ...Appearance.textStyles.title(),
                                                marginBottom: 2
                                            }}>{emission.title}</Text>
                                            <Text style={Appearance.textStyles.subTitle()}>{emission.value}</Text>
                                        </View>
                                    )
                                })}
                            </View>
                        </View>
                    </View>
                )}

                {/* navigator options */}
                <View style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: Screen.safeArea.bottom + 25,
                    width: '100%'
                }}>
                    <Carousel
                    data={options}
                    inactiveSlideOpacity={1}
                    {...getCarouselProps(options)}
                    renderItem={({ item, index }) => {
                        return (
                            <TouchableOpacity
                            key={index}
                            activeOpacity={0.6}
                            onPress={onOptionPress.bind(this, item)}
                            style={{
                                ...Appearance.styles.panel(),
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 12,
                                height: '100%'
                            }}>
                                {item.lottie
                                    ?
                                    <View style={{
                                        width: 45,
                                        height: 45,
                                        padding: 2,
                                        marginBottom: 12,
                                        borderRadius: 25,
                                        overflow: 'hidden',
                                        ...item.lottie.style
                                    }}>
                                        <LottieView
                                        autoPlay={true}
                                        loop={true}
                                        source={item.lottie.source}
                                        duration={2500}
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}/>
                                    </View>
                                    :
                                    <Image
                                    source={item.icon}
                                    style={{
                                        width: 45,
                                        height: 45,
                                        marginBottom: 12,
                                        borderRadius: 25,
                                        overflow: 'hidden',
                                        backgroundColor: Appearance.colors.primary(),
                                        ...item.iconStyle
                                    }} />
                                }
                                <Text style={{
                                    ...Appearance.textStyles.title(),
                                    textAlign: 'center',
                                    marginBottom: 2
                                }}>{item.title}</Text>
                                <Text
                                numberOfLines={3}
                                style={{
                                    ...Appearance.textStyles.subTitle(),
                                    textAlign: 'center'
                                }}>{item.subTitle}</Text>
                            </TouchableOpacity>
                        )
                    }} />
                </View>
            </View>
        </Animated.View>
    )
}

export const ReservationDetails = ({ isNewTarget, onClose, onBookRide, onStartRide, onResumeRide, onUpdateRide }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new-reservation' : `reservation-${abstract.getID()}`;

    const [annotations, setAnnotations] = useState([]);
    const [breakdownWarnings, setBreakdownWarnings] = useState([]);
    const [deviceSupportsApplePay, setDeviceSupportsApplePay] = useState(false);
    const [driver, setDriver] = useState(abstract.object.driver);
    const [layerState, setLayerState] = useState(null);
    const [lineItems, setLineItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingCancellation, setLoadingCancellation] = useState(false);
    const [manager, setManager, setStatus] = useRequestManager([ 'line_items', 'routing' ]);
    const [modularContent, setModularContent] = useState(null);
    const [overlays, setOverlays] = useState([]);
    const [paymentLineItems, setPaymentLineItems] = useState([]);
    const [reservation, setReservation] = useState(null);
    const [runningTotal, setRunningTotal] = useState(0);

    const onBookWithApplePay = async () => {
        try {

            // create apple pay token
            let supported = await stripe.deviceSupportsNativePay();
            if(!supported) {
                utils.alert.show({
                    title: 'Oops!',
                    message: `It looks like Apple Pay is not supported on your device. Please check that one have at least one card added in your Wallet app.`
                });
                return;
            }
            const token = await stripe.paymentRequestWithNativePay({ }, [{
                label: reservation.service.name,
                amount: runningTotal.toString(),
            }]);

            // create stripe payment method
            let { card } = await Request.post(utils, '/payment/', {
                type: 'add_apple_pay_method',
                user_id: utils.user.get().user_id,
                stripe_id: utils.user.get().stripe_customer_id,
                token: token
            });
            if(!card) {
                throw new Error('An unknown error occurred');
                return;
            }

            onItemUpdate({
                key: 'payment_method',
                title: 'Payment Method'
            }, {
                payment_method: PaymentMethod.create(card)
            });

            await stripe.completeNativePayRequest();
            onSubmit();

        } catch(e) {
            await stripe.cancelNativePayRequest();
            if(e.message.toString() === 'Cancelled by user') {
                return;
            }
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue booking your Reservation with Apple Pay. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onCancelRide = () => {

        const submitCancellation = async approveFees => {
            try {
                setLoadingCancellation(true);
                let { confirmation } = await Request.post(utils, '/reservation/', {
                    type: 'cancel',
                    approve_fees: approveFees || false,
                    reservation_id: abstract.getID()
                })
                if(confirmation) {
                    setLoadingCancellation(false);
                    utils.alert.show({
                        title: 'Are You Sure?',
                        message: confirmation,
                        buttons: [{
                            key: 'confirm',
                            title: `Yes`,
                            style: 'destructive'
                        },{
                            key: 'cancel',
                            title: 'Do Not Cancel',
                            style: 'default'
                        }],
                        onPress: key => {
                            if(key === 'confirm') {
                                submitCancellation(true);
                            }
                        }
                    })
                    return;
                }

                setLoadingCancellation(false);
                utils.alert.show({
                    title: 'All Done!',
                    message: `Your Reservation for ${abstract.object.formatPickupDate()} has been cancelled. Would you mind taking a second to let us know why you cancelled your ride?`,
                    buttons: [{
                        key: 'confirm',
                        title: `Let's Talk About It`,
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'Not Right Now',
                        style: 'destructive'
                    }],
                    onPress: key => {
                        setLayerState('close');
                        utils.content.fetch('reservations');
                        if(key === 'confirm') {
                            utils.quickFeedback.show();
                        }
                    }
                })

            } catch(e) {
                setLoadingCancellation(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue cancelling your Reservation. ${e.message || 'An unknown error occurred'}`
                })
            }
        }

        utils.alert.show({
            title: 'Cancel Reservation',
            message: 'Are you sure that you want to cancel this Reservation?',
            buttons: [{
                key: 'confirm',
                title: 'Cancel',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Cancel',
                style: 'default'
            }],
            onPress: (key) => {
                if(key === 'confirm') {
                    submitCancellation();
                }
            }
        })
    }

    const onCheckBreakdownWarnings = item => {
        let warning = (breakdownWarnings || []).find(warning => {
            return (item ? warning.key === item.key : true) && warning.messages.length > 0
        });
        if(!warning) {
            return false;
        }
        utils.alert.show({
            title: warning.title,
            message: warning.messages[0],
            buttons: [{
                key: 'change',
                title: 'Change',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Dismiss',
                style: 'cancel'
            }],
            onPress: (key) => {
                if(key === 'change') {

                    if(item) {
                        onShowEditOptions(item);
                        return
                    }

                    for(var i in lineItems){
                        for(var ii in lineItems[i].items) {
                            if(lineItems[i].items[ii].key === warning.key) {
                                onShowEditOptions(lineItems[i].items[ii]);
                            }
                        }
                    }
                }
            }
        })
        return true;
    }

    const onItemPress = item => {
        if(!item.on_edit) {
            return;
        }
        if(onCheckBreakdownWarnings(item) === false) {
            onShowEditOptions(item);
        }
    }

    const onItemUpdate = async (item, content) => {

        // prevent moving forward if no changes were submitted
        if(!content) {
            return;
        }

        // update local line item values
        setLineItems(lineItems => update(lineItems, {
            $apply: items => items.map(section => {
                section.items = section.items.map(i => {
                    i.loading = i.key === item.key;
                    return i;
                })
                return section;
            })
        }))

        // apply edits
        let edits = abstract.object.set(content);
        setReservation(edits);

        // fetch line items if target has not been submitted
        if(isNewTarget === true) {
            fetchLineItems();
            if(typeof(onUpdateRide) === 'function') {
                onUpdateRide(edits);
            }
            return;
        }

        // apply chanages to target and fetch line items
        try {
            let { message } = await abstract.object.update(utils);
            if(message) {
                utils.alert.show(message);
            }
            fetchLineItems();

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating "${item.title}". ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onLocationUpdate = data => {
        if(!driver) {
            return;
        }
        try {
            let { emissions, reservation } = JSON.parse(data);
            let { location } = reservation.driver;
            if(location.polyline) {
                let coordinates = Utils.decodePolyline(location.polyline)
                setOverlays([{
                    key: 'driver_route',
                    coordinates: coordinates.map(c => [ c[1], c[0] ])
                }])
            }
            setDriver(driver => update(driver, {
                heading: {
                    $set: location.heading
                },
                time_to_arrival: {
                    $set: location.time_to_arrival
                },
                location: {
                    $set: {
                        latitude: location.lat,
                        longitude: location.long
                    }
                }
            }))

        } catch(e) {
            console.error(e.message)
        }
    }

    const onMessageDriver = async () => {
        try {
            let { thread } = await Request.get(utils, '/messages/', {
                type: 'thread_for_target',
                target_id: abstract.getID(),
                target_type: abstract.type
            });
            if(!thread) {
                utils.alert.show({
                    title: 'Awaiting Driver',
                    message: `It looks like we have not assigned a driver to your ${abstract.type === 'orders' ? 'order' : 'reservation'} yet. Most rides and orders will have a driver assigned within a few hours of the pickup date`
                })
                return;
            }
            utils.layer.openFloating({
                id: `messages-${abstract.getTag()}`,
                abstract: abstract,
                Component: Messaging
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your messages. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSetStatus = status => {
        utils.alert.show({
            title: `${status === StatusCodes.reservations.approved ? 'Approve' : 'Decline'} Reservation`,
            message: `Are you sure that you want to ${status === StatusCodes.reservations.approved ? 'approve' : 'decline'} this Reservation? ${abstract.object.customer.full_name} will be notified with your decision`,
            buttons: [{
                key: 'confirm',
                title: status === StatusCodes.reservations.approved ? 'Approve' : 'Decline',
                style: status === StatusCodes.reservations.approved ? 'default' : 'destructive'
            },{
                key: 'cancel',
                title: status === StatusCodes.reservations.approved ? 'Do Not Approve' : 'Do Not Decline',
                style: status === StatusCodes.reservations.approved ? 'destructive' : 'default'
            }],
            onPress: async key => {
                if(key !== 'confirm') {
                    return;
                }

                try {
                    setLoading(status === StatusCodes.reservations.approved ? 'approve' : 'decline');
                    let response = await Request.post(utils, '/reservation/', {
                        type: 'set_status',
                        status: status,
                        reservation_id: abstract.getID()
                    });

                    abstract.object.status = Reservation.getStatus(response.status);
                    utils.content.update(abstract);
                    setReservation({ ...abstract.object.edits });

                    setLoading(false);
                    utils.alert.show({
                        title: 'All Done!',
                        message: `This reservation has been ${response.status === StatusCodes.reservations.approved ? 'approved' : 'declined'}`,
                        onPress: () => {
                            if(response.status !== StatusCodes.reservations.approved) {
                                setLayerState('close');
                            }
                        }
                    })

                } catch(e) {
                    setLoading(false);
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue ${status === StatusCodes.reservations.approved ? 'approving' : 'declining'} this Reservation. ${e.message || 'An unknown error occurred'}`
                    })
                }
            }
        })
    }

    const onShowEditOptions = item => {
        if(!item || !item.on_edit) {
            return;
        }
        if(item.on_edit.component === 'driver_tip') {
            utils.layer.open({
                id: 'driver-tip-new',
                Component: DriverTip.bind(this, {
                    shouldProcess: false,
                    onRemoveTip: reservation.driver_tip && (() => {
                        onItemUpdate(item, { driver_tip: null })
                    }),
                    onAddTip: amount => {
                        onItemUpdate(item, {
                            driver_tip: {
                                amount: amount
                            }
                        });
                    }
                })
            })
            return;
        }
        if(item.key === 'mood_and_music') {
            utils.sheet.show({
                items: [{
                    key: 'mood',
                    title: 'Mood',
                    style: 'default'
                },{
                    key: 'music',
                    title: 'Music',
                    style: 'default'
                }]
            }, (key) => {
                if(key === 'music') {
                    utils.layer.open({
                        id: 'music-picker',
                        Component: MusicPicker.bind(this, {
                            item: item,
                            category: item.value && item.value.music ? item.value.music.category : 'apple_music',
                            prevSongs: item.value && item.value.music ? item.value.music.songs : null,
                            onChange: content => onItemUpdate(item, content)
                        })
                    })
                }

                if(key === 'mood') {
                    utils.layer.open({
                        id: `line-item-${item.key}`,
                        Component: LineItem.bind(this, {
                            item: item,
                            preferredKey: 'mood',
                            onChange: onItemUpdate
                        })
                    })
                }
            })
            return;
        }

        // fallback to standard line item components
        utils.layer.open({
            id: `line-item-${item.key}`,
            Component: LineItem.bind(this, {
                item: item,
                onChange: onItemUpdate
            })
        })
    }

    const onSubmit = async () => {

        // Warnings and required field validation
        if(onCheckBreakdownWarnings()) {
            return;
        }

        let requirements = lineItems.reduce((array, lineItem) => {
            let items = lineItem.items.filter(item => {
                return item.required && (item.value === null || item.value === undefined);
            });
            if(items) {
                array = array.concat(items);
            }
            return array;
        }, [])

        if(requirements.length > 0) {
            utils.alert.show({
                title: requirements[0].title,
                message: `Please fill out the ${requirements[0].title.toLowerCase()} before submitting your Reservation. ${requirements[0].on_edit.description}`,
                buttons: [{
                    key: 'change',
                    title: 'Change',
                    style: 'default'
                },{
                    key: 'dismiss',
                    title: 'Dismiss',
                    style: 'cancel'
                }],
                onPress: (key) => {
                    if(key === 'change') {
                        onShowEditOptions(requirements[0]);
                    }
                }
            });
            return;
        }

        try {
            setLoading(true);
            await onBookRide(abstract);
            setLayerState('close');

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting your Reservation. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUpdateMapElements = async () => {
        try {
            // prevent moving forward if no manager props are present or changes are not pending
            if(!reservation || !manager.routing || manager.routing.pending === false) {
                return;
            }

            // set overlays from pre-calculated polylines
            setOverlays(abstract.object.getOverlays());

            // create annotations from reservation locations
            setAnnotations(annotations => update(annotations, {
                $apply: () => {
                    let annotations = [{
                        id: 'pickup',
                        title: `Pickup Location`,
                        subTitle: reservation.origin.address,
                        location: reservation.origin.location,
                        data: {
                            key: 'pickup',
                            reservation: reservation
                        }
                    },{
                        id: 'dropoff',
                        title: `Drop-Off Location`,
                        subTitle: reservation.destination.address,
                        location: reservation.destination.location,
                        data: {
                            key: 'drop-off',
                            reservation: reservation
                        }
                    }];
                    if(!reservation.stops || reservation.stops.locations.length === 0) {
                        return annotations;
                    }
                    return annotations.concat(reservation.stops.locations.map((stop, index) => {
                        return {
                            id: `stop-${index}`,
                            title: `${Utils.integerToOrdinal(index + 1)} Stop`,
                            subTitle: stop.address,
                            location: stop.location,
                            icon: { type: 'grey-broadcast' },
                            data: {
                                key: `stop-${index}`,
                                reservation: reservation
                            }
                        }
                    }))
                }
            }));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue calculating the route for this reservation. ${e.message || 'An unknown error occurred'}`,
                onPress: () => setLayerState('close')
            })
        }
    }

    const onUpdateOtherUsers = async users => {

        let prevUsers = reservation.other_users;
        try {
            abstract.object.set({ other_users: users });
            setReservation({ ... abstract.object.edits });
            if(!isNewTarget) {
                await abstract.object.update(utils);
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the friends and family for this Reservation. ${e.message || 'An unknown error occurred'}`,
                onPress: () => {
                    // Revert to previous users list
                    abstract.object.set({ other_users: prevUsers });
                    setReservation({ ... abstract.object.edits });
                }
            });
        }
    }

    const onUpdatePaymentMethod = () => {
        utils.alert.show({
            title: 'Unable to Process Reservation',
            message: `There was an issue with your payment method for this Reservation. Please update your card to continue booking rides`,
            buttons: [{
                key: 'update',
                title: 'Update Payment Method',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: 'cancel'
            }],
            onPress: (key) => {
                if(key === 'update') {

                    let tempCard = null;
                    let layerID = `edit-payment-method`;

                    utils.layer.open({
                        id: layerID,
                        Component: LayerShell.bind(this, {
                            layerID: layerID,
                            title: 'Payment Method',
                            extendedOptions: {
                                bottomCard: true
                            },
                            buttons: [{
                                key: 'confirm',
                                text: 'Update',
                                color: 'primary',
                                onPress: async () => {
                                    if(!tempCard) {
                                        utils.alert.show({
                                            title: 'Oops!',
                                            message: 'Please choose a payment method to continue'
                                        });
                                        return
                                    }
                                    utils.events.emit('onLayerAction', {
                                        action: 'close',
                                        layerID: layerID
                                    });

                                    try {
                                        let { status } = await Request.post(utils, '/payment/', {
                                            type: 'update_payment_method',
                                            id: abstract.getID(),
                                            target_type: abstract.type,
                                            card_id: tempCard.card_id
                                        })
                                        abstract.object.status = Reservation.getStatus(status);
                                        abstract.object.special_requests = {
                                            ...abstract.object.special_requests,
                                            card_id: tempCard.card_id
                                        }
                                        utils.content.update(abstract);
                                        utils.alert.show({
                                            title: 'All Done!',
                                            message: 'Thank you for updating your payment method',
                                            onPress: () => setLayerState('close')
                                        })

                                    } catch(e) {
                                        utils.alert.show({
                                            title: 'Oops!',
                                            message: `There was an issue updating your payment method. ${e.message || 'An unknown error occurred'}`
                                        })
                                    }
                                }
                            }],
                            children: (
                                <View style={{
                                    alignItems: 'center',
                                    marginTop: 5
                                }}>
                                    <Text style={{
                                        ...Appearance.textStyles.panelTitle(),
                                        marginBottom: 6
                                    }}>{'Payment Method'}</Text>
                                    <Text style={{
                                        ...Appearance.textStyles.subTitle(),
                                        marginBottom: 20,
                                        textAlign: 'center'
                                    }}>{`We were unable to process the payment method on file for this Reservation. Please choose another card to continue booking Orders`}</Text>
                                    <PaymentMethodManager
                                    utils={utils}
                                    onChange={method => tempCard = method}/>
                                </View>
                            )
                        })
                    })
                }
            }
        });
    }

    const onUserPress = user => {

        let owner = abstract.object.edits ? abstract.object.edits.customer : abstract.object.customer;
        utils.sheet.show({
            title: user.full_name,
            message: `How would you like to contact ${user.first_name}?`,
            items: [{
                key: 'call',
                title: 'Call',
                style: 'default'
            },{
                key: 'text',
                title: 'Text Message',
                style: 'default'
            },{
                key: 'message',
                title: 'In App Message',
                style: 'default'
            },{
                key: 'remove',
                title: `Remove from Ride`,
                style: 'destructive',
                visible: user.user_id !== owner && owner === utils.user.get().user_id
            }]
        }, async key => {

            if(key === 'call') {
                try {
                    await Call(user.phone_number, true);
                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue setting up your call. ${e.message || 'An unknown error occurred'}`
                    })
                }
                return;
            }
            if(key === 'text') {
                try {
                    await TextMessage(user.phone_number);
                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue setting up your text message. ${e.message || 'An unknown error occurred'}`
                    })
                }
                return;
            }
            if(key === 'message') {
                setLayerState('close');
                setTimeout(() => {
                    utils.layer.messaging(abstract)
                }, 1000);
                return;
            }
            if(key === 'remove') {
                let prevUsers = reservation.other_users;
                try {
                    abstract.object.set({
                        other_users: update(prevUsers, {
                            $apply: users => users.filter(u => u.user_id !== user.user_id)
                        })
                    });
                    setReservation({ ... abstract.object.edits });
                    await abstract.object.update(utils);

                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue removing ${user.first_name} from your Reservation. ${e.message || 'An unknown error occurred'}`,
                        onPress: () => {
                            // Revert to previous users list
                            abstract.object.set({ other_users: prevUsers });
                            setReservation({ ... abstract.object.edits });
                        }
                    });
                }
            }
        })
    }

    const getButtons = () => {

        // No buttons available for "other users"
        if(abstract.object.customer.user_id !== utils.user.get().user_id && utils.user.level > User.level.driver) {
            return null;
        }

        // Unpaid or failed charge reservations
        // Only shown for owner of the ride
        if(abstract.object.customer.user_id === utils.user.get().user_id) {
            if(abstract.getID() && abstract.object.status && [
                StatusCodes.reservations.unpaid,
                StatusCodes.reservations.chargeIssue,
                StatusCodes.reservations.chargeFailed
            ].includes(abstract.object.status.code)) {
                return [{
                    key: 'updatePayment',
                    text: 'Update Payment Method',
                    color: Appearance.colors.red,
                    onPress: onUpdatePaymentMethod
                }]
            }
        }

        // Pending rides
        // Approve and decline buttons only shown for admin users
        if(utils.user.get().level <= User.level.admin && abstract.object.isOnDemandRide() === false) {
            if(!onBookRide && abstract.object.status && [
                StatusCodes.reservations.pending,
                StatusCodes.reservations.adminUpdated,
                StatusCodes.reservations.driverUpdated,
                StatusCodes.reservations.customerEdited
            ].includes(abstract.object.status.code)) {
                return [{
                    key: 'decline',
                    text: 'Decline',
                    color: Appearance.colors.red,
                    loading: loading === 'decline',
                    onPress: onSetStatus.bind(this, StatusCodes.reservations.rejected)
                },{
                    key: 'approve',
                    text: 'Approve',
                    color: Appearance.colors.primary(),
                    loading: loading === 'approve',
                    onPress: onSetStatus.bind(this, StatusCodes.reservations.approved)
                }]
            }
        }

        // Start and resume buttons for rides that are not owned by the user logged in
        // Drivers can not start their own rides
        let buttons = [];
        if(abstract.object.customer && abstract.object.customer.user_id !== utils.user.get().user_id) {
            if(onStartRide) {
                buttons.push({
                    key: 'on_start',
                    text: buttons.length > 0 ? 'Start' : 'Start Reservation',
                    loading: loading === 'on_start',
                    color: 'primary',
                    onPress: async () => {
                        try {
                            setLoading('on_start');
                            await onStartRide(utils, abstract);
                            setLayerState('close');

                        } catch(e) {
                            setLoading(false);
                            utils.alert.show({
                                title: 'Oops!',
                                message: `There was an issue starting your reservation. ${e.message || 'An unknown error occurred'}`
                            })
                        }
                    }
                });
            }

            let { driver } = abstract.object;
            if(onResumeRide && driver && driver.user_id === utils.user.get().user_id) {
                buttons.push({
                    key: 'on_resume',
                    text: buttons.length > 0 ? 'Resume' : 'Resume Reservation',
                    loading: loading === 'on_start',
                    color: Appearance.colors.blue,
                    onPress: async () => {

                        try {
                            setLoading('on_resume');
                            await onResumeRide(utils, abstract);
                            setLayerState('close');

                        } catch(e) {
                            setLoading(false);
                            utils.alert.show({
                                title: 'Oops!',
                                message: `There was an issue resuming your reservation. ${e.message || 'An unknown error occurred'}`
                            });
                        }
                    }
                });
            }
        }

        // Standard rides
        // Only show reschedule and cancel buttons for owners of the ride
        // Buttons are not shown if the layer is presented for booking
        if(!onBookRide && abstract.object.customer.user_id == utils.user.get().user_id) {
            if(!abstract.object.status || [
                StatusCodes.reservations.pending,
                StatusCodes.reservations.approved,
                StatusCodes.reservations.returned,
                StatusCodes.reservations.rejected,
                StatusCodes.reservations.adminUpdated,
                StatusCodes.reservations.driverUpdated,
                StatusCodes.reservations.customerEdited,
                StatusCodes.reservations.preauthorized,
                StatusCodes.reservations.preauthorizationFailed,
                StatusCodes.reservations.preauthorizationRevoked
            ].includes(abstract.object.status.code)) {
                buttons = [{
                    key: 'onReschedule',
                    text: 'Reschedule',
                    color: Appearance.colors.grey(),
                    onPress: () => {
                        (lineItems || []).forEach(section => {
                            let item = section.items.find(item => item.key === 'pickup_date');
                            if(item) {
                                onShowEditOptions({
                                    ...item,
                                    can_reschedule: true
                                });
                            }
                        })
                    }
                },{
                    key: 'onCancel',
                    text: 'Cancel',
                    loading: loadingCancellation,
                    color: Appearance.colors.red,
                    onPress: onCancelRide
                }];
            }
        }

        if(onBookRide) {
            buttons.push({
                key: 'onBook',
                text: runningTotal ? `Confirm ${Utils.toCurrency(runningTotal)}` : 'Confirm',
                color: 'primary',
                loading: loading,
                onPress: onSubmit
            })
            if(runningTotal && deviceSupportsApplePay) {
                buttons.push({
                    key: 'apple_pay',
                    type: 'book_with_apple_pay',
                    onPress: onBookWithApplePay
                })
            }
        }

        return buttons.length > 0 ? buttons : null;
    }

    const getContent = () => {
        if(!reservation) {
            return null;
        }
        return (
            <>
            {getOverview()}
            {getModularContent()}
            {getCustomer()}
            {getCompany()}
            {getOtherUsers()}
            {getLineItems()}
            </>
        )
    }

    const getCompany = () => {
        if(!abstract.object.company || utils.user.get().user_id === abstract.object.customer.user_id) {
            return null;
        }
        return (
            <View style={{
                paddingHorizontal: 15
            }}>
                <LayerItem title={'Company'}>
                    {Views.entry({
                        title: abstract.object.company.name,
                        subTitle: abstract.object.company.address || 'Address not available',
                        icon: {
                            path: abstract.object.company.image
                        },
                        bottomBorder: false
                    })}
                </LayerItem>
            </View>

        )
    }

    const getCustomer = () => {
        if(utils.user.get().user_id === abstract.object.customer.user_id) {
            return null;
        }
        return (
            <View style={{
                paddingHorizontal: 15
            }}>
                <LayerItem title={utils.user.get().level <= User.level.driver ? 'Customer' : 'Booked By'}>
                    {Views.entry({
                        title: abstract.object.customer.full_name,
                        subTitle: abstract.object.customer.phone_number,
                        icon: {
                            path: abstract.object.customer.avatar
                        },
                        onPress: onUserPress.bind(this, abstract.object.customer),
                        bottomBorder: false
                    })}
                </LayerItem>
            </View>
        )
    }

    const getHeaderComponents = () => {
        let hasVisibility = reservation.customer.user_id === utils.user.get().user_id || isOtherUser() ? true : false;
        if(!abstract.getID() || hasVisibility === false) {
            return null;
        }
        let { toDestination, toPickup } = StatusCodes.reservations;
        if(canShowTripNavigator() === true && abstract.object.status && [toDestination, toPickup].includes(abstract.object.status.code)) {
            return (
                <View style={{
                    width: '100%',
                    marginBottom: 12
                }}>
                    <ActiveAbstractHeader
                    utils={utils}
                    driver={driver}
                    abstract={abstract} />
                </View>
            )
        }
        return (
            <View style={{
                ...Appearance.styles.panel(),
                marginBottom: 12
            }}>
                {Views.entry({
                    title: 'Message Your Driver',
                    subTitle: `Please let your driver know if you have any special requests or questions about your upcoming ride`,
                    bottomBorder: false,
                    icon: {
                        path: require('eCarra/images/message-icon-clear-small.png'),
                        style: {
                            backgroundColor: Appearance.colors.primary()
                        }
                    },
                    onPress: onMessageDriver,
                    propStyles: {
                        subTitle: {
                            numberOfLines: 2
                        }
                    }
                })}
            </View>
        )
    }

    const getLineItems = () => {
        if(!lineItems || lineItems.length === 0) {
            return (
                <View style={{
                    padding: 15
                }}>
                    <View style={{
                        ...Appearance.styles.panel(),
                        padding: 20,
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <LottieView
                        autoPlay={true}
                        loop={true}
                        source={Appearance.themeStyle() === 'dark' ? require('eCarra/files/lottie/dots-white.json') : require('eCarra/files/lottie/dots-grey.json')}
                        duration={2500}
                        style={{
                            width: 50
                        }}/>
                    </View>
                </View>
            )
        }
        return (
            <View style={{
                paddingHorizontal: 15
            }}>
                {lineItems.filter(lineItem => {
                    return lineItem.items && lineItem.items.length > 0;
                }).map((lineItem, index) => {
                    return (
                        <LayerItem
                        key={index}
                        title={lineItem.title}>
                            {lineItem.items.map((item, index, items) => {
                                if(isNewTarget && item.key === 'id') {
                                    return null;
                                }
                                let showLeftContent = item.required && !item.value || (breakdownWarnings || []).find(warning => warning.key === item.key) ? true : false;
                                return  (
                                    Views.entry({
                                        key: item.key,
                                        title: item.title,
                                        subTitle: item.formatted || item.null_value,
                                        loading: item.loading,
                                        hideIcon: true,
                                        bottomBorder: index !== items.length - 1,
                                        onPress: item.on_edit && item.on_edit.enabled ? onItemPress.bind(this, item) : null,
                                        propStyles: {
                                            ...item.prop_styles,
                                            subTitle: {
                                                numberOfLines: 2,
                                                ...item.prop_styles && item.prop_styles.subTitle
                                            }
                                        },
                                        prepend: showLeftContent && (
                                            <View style={{
                                                width: 12,
                                                height: 12,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginRight: 8
                                            }}>
                                                <View style={{
                                                    width: 8,
                                                    height: 8,
                                                    borderRadius: 4,
                                                    backgroundColor: Appearance.colors.red
                                                }}/>
                                            </View>
                                        )
                                    })
                                )
                            })}
                        </LayerItem>
                    )
                })}
            </View>
        )
    }

    const getModularContent = () => {
        if(!modularContent || modularContent.length === 0) {
            return null;
        }
        return (
            <ModularContentLogic
            utils={utils}
            content={modularContent}
            category={isNewTarget ? 'book_ride_confirm' : 'reservation_details'}
            width={Screen.layer.maxWidth}
            style={{
                marginBottom: 15
            }}
            onRequestLogic={{
                prevUsers: reservation.other_users,
                abstract: abstract,
                onChange: onUpdateOtherUsers
            }} />
        )
    }

    const getOtherUsers = () => {

        if(utils.user.get().user_id !== abstract.object.customer.user_id && utils.user.get().level > User.level.driver) {
            return null;
        }
        if(!reservation.other_users || reservation.other_users.length === 0) {
            return null;
        }
        return (
            <View style={{
                paddingHorizontal: 15
            }}>
                <LayerItem title={'Friends and Family'}>
                    {reservation.other_users.map((user, index, users) => {
                        return (
                            Views.entry({
                                key: index,
                                title: user.full_name,
                                subTitle: user.phone_number,
                                icon: {
                                    path: user.avatar
                                },
                                onPress: onUserPress.bind(this, user),
                                bottomBorder: index !== users.length - 1
                            })
                        )
                    })}
                </LayerItem>
            </View>
        )
    }

    const getOverview = () => {
        return (
            <View style={{
                paddingTop: LayerHeaderSpacing.get(),
                paddingHorizontal: 15,
                marginBottom: 25
            }}>
                {getHeaderComponents()}
                <View style={Appearance.styles.panel()}>
                    <Map
                    driver={driver}
                    utils={utils}
                    camera={{ animationDuration: 3000 }}
                    overlays={overlays}
                    annotations={driver && driver.location ? null : annotations}
                    style={{
                        height: Platform.OS === 'web' || Utils.isMobile() === false ? 400 : 200,
                        width: Screen.layer.maxWidth - 24,
                        marginBottom: 4,
                        borderBottomWidth: 1,
                        borderBottomColor: Appearance.colors.divider()
                    }}/>
                    {Views.entry({
                        title: reservation.destination ? (reservation.destination.name || reservation.destination.address) : 'Destination Not Selected',
                        subTitle: moment(reservation.pickup_date).format('MMMM Do, YYYY [at] h:mma'),
                        hideIcon: true,
                        textStyles: {
                            title: Appearance.textStyles.panelTitle(),
                            subTitle: {
                                ...Appearance.textStyles.title(),
                                color: Appearance.colors.subText()
                            }
                        }
                    })}
                    {getPaymentLineItems()}
                </View>
            </View>
        )
    }

    const getPaymentLineItems = () => {
        if(paymentLineItems.length === 0) {
            return null;
        }
        if(utils.user.get().level > User.level.admin && abstract.object.customer.user_id !== utils.user.get().user_id) {
            return null;
        }
        return (
            <>
            {paymentLineItems.map((item, index, items) => {
                return (
                    <View
                    key={index}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        paddingHorizontal: 12,
                        paddingVertical: 8,
                        borderBottomWidth: 1,
                        borderBottomColor: Appearance.colors.divider()
                    }}>
                        <Text style={Appearance.textStyles.key()}>{item.title}</Text>
                        <Text style={Appearance.textStyles.value()}>{item.message}</Text>
                    </View>
                )
            })}
            {reservation.service && typeof(reservation.service.disclaimer) === 'string' && (
                <Text style={{
                    ...Appearance.textStyles.small(),
                    padding: 12
                }}>{reservation.service.disclaimer}</Text>
            )}
            </>
        )
    }

    const canShowTripNavigator = () => {
        if(abstract.object && abstract.object.customer) {
            let isOtherUser = abstract.object.other_users ? abstract.object.other_users.find(user => user.user_id == utils.user.get().user_id) : false;
            if(isOtherUser || abstract.object.customer.user_id === utils.user.get().user_id) {
                return true;
            }
        }
        return false;
    }

    const isOtherUser = () => {
        try {
            if(!reservation.other_users) {
                return false;
            }
            return reservation.other_users.find(user => {
                return user.user_id === utils.user.get().user_id;
            })
        } catch(e) {
            return false;
        }
    }

    const fetchCostBreakdown = async () => {
        try {
            // fetch cost breakdown
            let { breakdown, route, warnings } = await abstract.object.costBreakdown(utils);
            setRunningTotal(breakdown.total || 0);
            setBreakdownWarnings(warnings);
            setPaymentLineItems(breakdown ? breakdown.line_items : []);

            // update overlays from target locations
            // overlays may have changed if a stop or location was modified
            setOverlays(abstract.object.getOverlays());

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue calculating your cost estimate. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchLineItems = () => {
        // prevent moving forward if no manager props are present or changes are not pending
        if(!reservation || !manager.line_items || manager.line_items.pending === false) {
            return;
        }
        fetchCostBreakdown();
        fetchPaymentLineItems();
    }

    const fetchPaymentLineItems = async () => {
        try {
            // fetch reservation line items
            let reservation = abstract.object.edits;
            let { line_items } = await fetchReservationLineItems(utils, {
                ...abstract.getID() && {
                    reservation_id: abstract.getID()
                },
                ...!abstract.getID() && {
                    route_id: abstract.object.quick_scan_route ? abstract.object.quick_scan_route.id : null,
                    customer: reservation.customer ? reservation.customer.user_id : null,
                    pickup_date: reservation.pickup_date ? moment(reservation.pickup_date).format('YYYY-MM-DD HH:mm:ss') : null,
                    company: reservation.company ? reservation.company.id : null,
                    service: reservation.service ? reservation.service.id : null,
                    vehicle: reservation.vehicle ? reservation.vehicle.id : null,
                    promo_code: reservation.promo_code ? reservation.promo_code.id : null,
                    driver_tip: reservation.driver_tip,
                    credits: reservation.credits,
                    driver_tip: reservation.driver_tip,
                    passengers: reservation.passengers,
                    luggage: reservation.luggage,
                    mood: reservation.mood ? reservation.mood.id : null,
                    music: reservation.music,
                    subscription: reservation.subscription ? reservation.subscription.id : null,
                    special_requests: reservation.special_requests,
                    origin: reservation.origin && {
                        name: reservation.origin.name,
                        address: reservation.origin.address,
                        lat: reservation.origin.location.latitude,
                        long: reservation.origin.location.longitude
                    },
                    destination: reservation.destination && {
                        name: reservation.destination.name,
                        address: reservation.destination.address,
                        lat: reservation.destination.location.latitude,
                        long: reservation.destination.location.longitude
                    },
                    stops: reservation.stops && reservation.stops.locations && {
                        ...reservation.stops,
                        locations: reservation.stops.locations.map(stop => ({
                            name: stop.name,
                            address: stop.address,
                            lat: stop.lat || stop.location.latitude,
                            long: stop.long || stop.location.longitude
                        }))
                    },
                }
            });
            setLineItems(line_items);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the information for this Reservation. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const setupApplePay = async () => {
        try {
            if(Platform.OS !== 'ios') {
                setDeviceSupportsApplePay(false);
                return;
            }
            let supported = await Stripe.deviceSupportsNativePay();
            setDeviceSupportsApplePay(supported);
        } catch(e) {
            console.error(e.message);
        }
    }

    const setupModularContent = async () => {
        try {
            let { content } = await Utils.fetchModularContent(utils, isNewTarget ? 'book_ride_confirm' : 'reservation_details');
            if(content && content.length > 0) {
                setModularContent(content.filter(item => {
                    if(item.mobileAction === 'layer.friends-and-family.show' && utils.user.get().user_id !== abstract.object.customer.user_id) {
                        return false;
                    }
                    return true;
                }));
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setReservation(edits);
    }

    useEffect(() => {
        if(reservation) {
            setManager('routing', {
                destination: reservation.destination,
                driver: reservation.driver,
                origin: reservation.origin,
                stops: reservation.stops,
            });
            setManager('line_items', {
                credits: reservation.credits,
                destination: reservation.destination,
                driver_tip: reservation.driver_tip,
                origin: reservation.origin,
                pickup_date: reservation.pickup_date,
                promo_code: reservation.promo_code,
                service: reservation.service,
                stops: reservation.stops,
                subscription: reservation.subscription
            });
        }
    }, [reservation]);

    useEffect(() => {
        fetchLineItems();
    }, [manager.line_items]);

    useEffect(() => {
        onUpdateMapElements();
    }, [manager.routing]);

    useEffect(() => {
        if(typeof(onDriverChange) === 'function') {
            onDriverChange(driver);
        }
    }, [driver])

    useEffect(() => {

        setupTarget();
        setupApplePay();
        setupModularContent();

        let reservationID = abstract.getID();
        if(reservationID) {
            utils.sockets.on('reservations', `on_active_update_${reservationID}`, onLocationUpdate);
            utils.content.subscribe(layerID, 'reservations', {
                onUpdate: next => {
                    setReservation(next.compare(abstract));
                }
            });
            return () => {
                utils.content.unsubscribe(layerID);
                utils.sockets.off('reservations', `on_active_update_${reservationID}`, onLocationUpdate);
            }
        }

    }, []);

    return (
        <Layer
        id={layerID}
        title={'Reservation Details'}
        utils={utils}
        index={index}
        buttons={getButtons()}
        contentStyle={{
            paddingBottom: FloatingLayerMinimizedHeight + 15
        }}
        options={{
            ...options,
            loading: loading === true,
            layerState: layerState,
            onCloseLayer: onClose,
            removePadding: true,
            stackButtons: onBookRide ? true : false
        }}>
            {getContent()}
        </Layer>
    )
}
