import React from 'react';

import API from 'eCarra/files/api.js';
import Company from 'eCarra/classes/Company';
import RouteOption from 'eCarra/classes/RouteOption.js';
import Service from 'eCarra/classes/Service.js';

class RouteCategoryClass {

    id = null;
    name = null;
    information = null;
    service = null;
    companies = null;
    options = null;
    active = null;
    seeds = {}

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.name = props.name;
        this.information = props.information;
        this.active = Boolean(props.active);
        this.service = props.service ? Service.create(props.service) : null;
        this.companies = props.companies && Array.isArray(props.companies) && props.companies.map(c => Company.create(c));
        this.options = props.options && Array.isArray(props.options) && props.options.map(o => {
            let option = RouteOption.create(o);
            option.category = {
                id: this.id,
                name: this.name,
                information: this.information,
                service: this.service,
                companies: this.companies,
                active: this.active
            };
            return option;
        });
        return this;
    }
}
export default {
    create: props => new RouteCategoryClass().create(props)
};
