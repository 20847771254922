import React, { PureComponent, useRef, useState, useEffect } from 'react';

import { easeCubic } from 'd3-ease';
import { lineString } from '@turf/helpers';
import update from 'immutability-helper';

import API from 'eCarra/files/api.js';
import Appearance from 'eCarra/styles/Appearance.js';
import LottieView from 'eCarra/views/Lottie/';
import ReactMapGL, { Source, Layer, Popup, Marker, FlyToInterpolator, WebMercatorViewport } from 'react-map-gl';
import Utils from 'eCarra/files/Utils.js';
import Views from 'eCarra/views/Main.js';

export const setAccessToken = () => {}
export const Follow = null;
export const FollowWithCourse = null;
export const FollowWithHeading = null;
export const DallasCenterCoordinate = [ -96.797342, 32.776424 ];
export const getMapStyleURL = () => {
    return Appearance.themeStyle() === 'dark' ? require('eCarra/styles/mobile_style_dark.json') : require('eCarra/styles/mobile_style_light.json');
}
export const Map = ({
    animationMode, animationDuration, annotations, approxUserLocation, cameraPadding, chargers, clusters, circles, center, driver, driverNavigation, features, followDriver, followPitch, followUserLocation, followUserMode, followZoomLevel, followPref, followPrefTarget, flow, heatmap, isScrollEnabled, isRotationEnabled, isZoomEnabled, incidents, minZoomLevel, maxZoomLevel, nearbyVehicles, overlays, onAnnotationChange, onCameraReady, onChargerPress, onIncidentPress, onOverlayChange, onHeadingChange, onRegionDidChange, onUserLocationUpdate, onUserTrackingModeChange, onPress, overlayStyle, showChargers, showFlow, showIncidents, style, userLocation, userLocationIcon, userLocationIconStyle, utils, visibleCallout, zoomLevel
}) => {

    const isCalculatingViewport = useRef(false);
    const mapRef = useRef(null);

    const [_annotations, _setAnnotations] = useState([]);
    const [_overlays, _setOverlays] = useState([]);
    const [hover, setHover] = useState(null);
    const [viewport, setViewport] = useState({
        zoom: 12,
        width: style && style.width ? style.width : window.innerWidth,
        height: style && style.height ? style.height : window.innerHeight,
        latitude: center ? center.latitude : DallasCenterCoordinate[1],
        longitude: center ? center.longitude : DallasCenterCoordinate[0]
    });

    const onSetCamera = ({ animationDuration, centerCoordinate, zoomLevel }) => {
        setViewport(viewport => update(viewport, {
            ...getViewportAnimations,
            ...animationDuration && {
                transitionDuration: {
                    $set: animationDuration
                },
            },
            ...centerCoordinate && {
                longitude: {
                    $set: centerCoordinate.location ? centerCoordinate.location.longitude : centerCoordinate.longitude
                },
                latitude: {
                    $set: centerCoordinate.location ? centerCoordinate.location.latitude : centerCoordinate.latitude
                }
            },
            zoom: {
                $set: zoomLevel || 16
            }
        }));
    }

    const onSetViewport = region => {
        try {
            // padding top is to account for the size of the marker
            // padding can throw error if there is not sufficient space for the map
            return new WebMercatorViewport(viewport).fitBounds(region, { padding: 24 })
        } catch(e) {
            console.error(e.message);
            return new WebMercatorViewport(viewport).fitBounds(region);
        }
    };

    const onSetViewportAsync = async region => {
        return new Promise((resolve, reject) => {

            let index = 0;
            isCalculatingViewport.current = true;

            let interval = setInterval(() => {
                try {
                    // only allow 5 attempts before continuing with out padding
                    if(index > 4) {
                        clearInterval(interval);
                        isCalculatingViewport.current = false;
                        console.warn('unable to generate viewport props with padding');

                        let props = new WebMercatorViewport(viewport).fitBounds(region);
                        resolve(props);
                        return;
                    }

                    // attempt to generate viewport with padding props
                    let props = new WebMercatorViewport(viewport).fitBounds(region, { padding: 50 });
                    console.log(`calculated viewport props with padding. ${props.latitude} ${props.longitude} ${props.zoom}`);

                    isCalculatingViewport.current = false;
                    clearInterval(interval);
                    resolve(props);

                } catch(e) {
                    index++;
                    console.error(e.message);
                }
            }, 250);
        })
    }

    const getViewportAnimations = {
        transitionDuration: {
            $set: 750
        },
        transitionInterpolator: {
            $set: new FlyToInterpolator()
        },
        transitionEasing: {
            $set: easeCubic
        }
    }

    const getUserLocation = () => {
        if(!userLocation) {
            return null;
        }
        if(userLocationIcon) {
            return (
                <Marker
                longitude={userLocation.longitude}
                latitude={userLocation.latitude}
                offsetTop={-30}
                offsetLeft={-30}>
                    <img
                    src={userLocationIcon}
                    style={{
                        width: 60,
                        height: 60,
                        transform: `rotate(${parseInt(userLocation.heading || 0)}deg)`,
                        ...userLocationIconStyle
                    }}/>
                </Marker>
            )
        }
        return (
            <Marker
            longitude={userLocation.longitude}
            latitude={userLocation.latitude}
            offsetTop={-30}
            offsetLeft={-30}>
                <LottieView
                autoPlay={true}
                loop={true}
                source={require('eCarra/files/lottie/location-broadcast-blue.json')}
                duration={2500}
                style={{
                    width: 60,
                    height: 60,
                }}/>
            </Marker>
        )
    }

    const onHoverChange = hover_event => {

        let props = hover_event.features;
        let { srcEvent: { offsetX, offsetY } } = hover_event;
        let selected = props && props[0];

        if(!selected || !features) {
            setHover(null);
            return;
        }

        let { id, onHover } = features;
        if(id === selected.source && typeof(onHover) === 'function') {
            setHover({
                ...onHover(selected),
                x: offsetX,
                y: offsetY
            })
        }
    }

    const onMapClick = hover_event => {

        let props = hover_event.features;
        let { srcEvent: { offsetX, offsetY } } = hover_event;
        let selected = props && props[0];

        if(!selected || !features) {
            return;
        }

        let { id, onClick, onHover } = features;
        if(id === selected.source) {
            if(typeof(onHover) === 'function') {
                setHover({
                    ...onHover(selected),
                    loading: true, // update loading props
                    x: offsetX,
                    y: offsetY
                })
            }
            if(typeof(onClick) === 'function') {
                onClick(selected.properties);
            }
        }
    }

    const onUpdateAnnotations = async () => {
        try {
            if(_annotations.length === 0 || isCalculatingViewport.current === true || _overlays.length > 0) {
                return;
            }

            // notify listeners of annotation changes
            let region = Utils.getRegionFromAnnotations(_annotations);
            if(typeof(onAnnotationChange) === 'function') {
                onAnnotationChange({
                    region: region,
                    setZoom: setZoom,
                    fitBounds: onSetViewport,
                    setCamera: onSetCamera,
                    annotations: _annotations
                });
                return;
            }

            // single annotation
            if(_annotations.length === 1) {
                setViewport(viewport => update(viewport, {
                    ...getViewportAnimations,
                    longitude: {
                        $set: _annotations[0].location.longitude
                    },
                    latitude: {
                        $set: _annotations[0].location.latitude
                    },
                    zoom: {
                        $set: 15
                    }
                }));
                return;
            }

            // multiple annotations
            const { longitude, latitude, zoom } = await onSetViewportAsync(region);
            setViewport(viewport => update(viewport, {
                ...getViewportAnimations,
                longitude: {
                    $set: isNaN(longitude) ? viewport.longitude : longitude
                },
                latitude: {
                    $set: isNaN(latitude) ? viewport.latitude : latitude
                },
                zoom: {
                    $set: _annotations.length === 1 ? 15 : zoom
                }
            }));

        } catch(e) {
            console.error(e.message);
        }
    }

    const onUpdateOverlays = async () => {
        try {
            if(_overlays.length === 0 || isCalculatingViewport.current === true) {
                return;
            }

            // calculate viewport for all overlays based on ne cordinate and sw coordinate
            let region = Utils.getRegionFromAnnotations(overlays.reduce((array, overlay) => {
                return array.concat(overlay.coordinates);
            }, []));

            // update viewport
            const { longitude, latitude, zoom } = await onSetViewportAsync(region);
            setViewport(viewport => update(viewport, {
                ...getViewportAnimations,
                longitude: {
                    $set: isNaN(longitude) ? viewport.longitude : longitude
                },
                latitude: {
                    $set: isNaN(latitude) ? viewport.latitude : latitude
                },
                zoom: {
                    $set: zoom
                }
            }));

        } catch(e) {
            console.error(e.message);
        }
    }

    const onUpdateUserLocation = async () => {
        try {
            if(!userLocation) {
                return;
            }
            setViewport(viewport => update(viewport, {
                ...getViewportAnimations,
                longitude: {
                    $set: userLocation.longitude
                },
                latitude: {
                    $set: userLocation.latitude
                },
                zoom: {
                    $set: 15
                }
            }));

        } catch(e) {
            console.error(e.message);
        }
    }

    const setFeatureProps = () => {
        if(!features || !mapRef.current) {
            return
        }
        let { data, id, icons, onClick } = features;
        const map = mapRef.current.getMap();
        if(icons) {
            icons.forEach(icon => {
                map.loadImage(icon.path, (error, image) => {
                    if(error) {
                        console.log(error.message);
                        return;
                    }
                    if(!map.hasImage(icon.key)) {
                        map.addImage(icon.key, image, { sdf: true });
                    }
                });
            })
        }
    }

    const setZoom = amount => {
        let zoom = viewport.zoom + amount;
        setViewport(viewport => update(viewport, {
            ...getViewportAnimations,
            zoom: {
                $set: parseInt(zoom <= 0 ? 0 : (zoom > 25 ? 25 : zoom))
            }
        }));
    }

    useEffect(() => {
        _setOverlays(overlays && Array.isArray(overlays) ? overlays.map(overlay => {
            return {
                ...overlay,
                coordinates: lineString(overlay.coordinates.map(o => [ o[1], o[0] ]))
            }
        }) : []);
    }, [overlays]);

    useEffect(() => {
        onUpdateOverlays();
    }, [_overlays]);

    useEffect(() => {
        if(!annotations || !Array.isArray(annotations)) {
            return;
        }
        _setAnnotations(annotations);

    }, [annotations]);

    useEffect(() => {
        onUpdateAnnotations();
    }, [_annotations, annotations ]);

    useEffect(() => {
        onUpdateUserLocation();
    }, [userLocation]);

    useEffect(() => {

        // notify listeners of annotation changes
        if(typeof(onAnnotationChange) === 'function') {
            onAnnotationChange({
                region: null,
                setZoom: setZoom,
                fitBounds: onSetViewport,
                setCamera: onSetCamera,
                annotations: [userLocation]
            });
        }
    }, [userLocation]);

    useEffect(() => {
        setFeatureProps()
    }, [features]);

    useEffect(() => {
        if(mapRef.current && typeof(onCameraReady) === 'function') {
            onCameraReady({
                ref: mapRef.current,
                setCamera: onSetCamera
            });
        }
    }, [mapRef.current]);

    return (
        <div style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            position: 'relative',
            border: '1px solid rgba(175,174,174,0.1)',
            ...style
        }}>
            <ReactMapGL
            {...viewport}
            ref={mapRef}
            scrollZoom={false}
            dragPan={isScrollEnabled || false}
            dragRotate={isRotationEnabled || false}
            doubleClickZoom={isZoomEnabled || false}
            touchRotate={isRotationEnabled || false}
            touchZoom={isZoomEnabled || false}
            attributionControl={false}
            mapStyle={getMapStyleURL()}
            mapboxApiAccessToken={API.mapbox}
            onViewportChange={setViewport}
            onClick={onMapClick}
            onHover={onHoverChange}
            width={'100%'}
            height={'100%'}
            style={{
                cursor: 'pointer'
            }}>
                <Annotations data={_annotations} />
                {_overlays.map((polyline, index) => {
                    return (
                        <Source
                        key={polyline.key}
                        type={'geojson'}
                        data={polyline.coordinates}>
                            <Layer
                            id={polyline.key}
                            type={'line'}
                            source={'route'}
                            layout={{
                                'line-join': 'round',
                                'line-cap': 'round'
                            }}
                            paint={{
                                'line-color': Appearance.colors.text(),
                                'line-width': 3
                            }}/>
                        </Source>
                    )
                })}
                {heatmap && heatmap.layer && heatmap.data && (
                    <Source
                    type={'geojson'}
                    data={heatmap.data}>
                        <Layer {...heatmap.layer} />
                    </Source>
                )}
                {features && features.layer && features.data && (
                    <Source
                    id={features.id}
                    type={'geojson'}
                    data={features.data}>
                        <Layer
                        id={features.id}
                        source={features.id}
                        {...features.layer} />
                    </Source>
                )}
                {hover && (
                    <div style={{
                        position: 'absolute',
                        left: hover.x,
                        top: hover.y,
                        minWidth: 200,
                        ...Appearance.styles.panel()
                    }}>
                        {Views.entry({
                            ...hover,
                            hideIcon: hover.icon ? false : true
                        })}
                    </div>
                )}
                {getUserLocation()}
            </ReactMapGL>
        </div>
    );
}

class Annotations extends PureComponent {

    state = {
        hover: false
    }

    getMarkerIcon = annotation => {

        let { icon } = annotation;
        let { onClick } = this.props;
        return (
            <LottieView
            autoPlay={true}
            loop={true}
            className={onClick ? 'image-button' : ''}
            onMouseEnter={() => this.setState({ hover: annotation.id })}
            onMouseLeave={() => this.setState({ hover: null })}
            onClick={onClick ? onClick.bind(this, annotation.id) : null}
            source={icon && icon.type === 'grey-broadcast' ? require('eCarra/files/lottie/grey-broadcast-marker.json') : require('eCarra/files/lottie/broadcast-marker.json')}
            duration={2500}
            style={{
                width: 60,
                height: 60
            }} />
        )
    }

    render() {

        const { data } = this.props;
        if(!data) {
            return null;
        }
        return data.map((annotation, index) => {
            if(!annotation.location) {
                return null;
            }
            return (
                <Marker
                key={index}
                captureClick={false}
                offsetTop={-30}
                offsetLeft={-30}
                {...annotation.location}
                {...annotation.icon && annotation.icon.style ? annotation.icon.style.offset : null}>
                    <div style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        {this.getMarkerIcon(annotation)}
                    </div>
                </Marker>
            )
        })
    }
}
