export const GoogleSignin = {
    configure: props => {
        let interval = setInterval(() => {
            if(!window.gapi) {
                return;
            }
            window.gapi.load('client', () => {
                window.gapi.client.init(props);
            });
            clearInterval(interval);
        }, 500);

    },
    hasPlayServices: () => {
        return new Promise(resolve => {
            resolve(true);
        })
    },
    signIn: async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let instance = window.gapi.auth2.getAuthInstance();
                instance.attachClickHandler();
                let result = await instance.signIn();
                let user = result.getBasicProfile();
                resolve({
                    user: {
                        id: user.getId(),
                        givenName: user.getGivenName(),
                        familyName: user.getFamilyName(),
                        name: user.getName(),
                        email: user.getEmail(),
                        googleToken: user.getId(),
                    }
                })

            } catch(e) {
                if(e.error === statusCodes.WEB_POPUP_BLOCKED) {
                    reject({ code: statusCodes.WEB_POPUP_BLOCKED });
                    return;
                }
                reject(e);
            }
        })
    }
}
export const statusCodes = {
    WEB_POPUP_BLOCKED: 'popup_blocked_by_browser'
}
