import React, { useEffect, useState } from 'react';
import { View, Image, TextInput, TouchableOpacity } from 'react-native';

import Appearance from 'eCarra/styles/Appearance.js';

const NumberStepper = ({ max, min, onChange, startingValue, style, utils, value }) => {

    const [text, setText] = useState(startingValue || min || '0');

    const onPress = value => {
        let newValue = parseInt(text) + parseInt(value);
        if(min !== undefined && newValue < min) {
            return;
        }
        if(max !== undefined && newValue > max) {
            return;
        }
        setText(newValue);
        if(typeof(onChange) === 'function') {
            onChange(newValue);
        }
    }

    const onTextChange = text => {
        setText(text);
        if(typeof(onChange) === 'function') {
            onChange(text);
        }
    }

    useEffect(() => {
        if(startingValue) {
            setText(parseInt(startingValue));
        }
    }, [startingValue]);

    return (
        <View style={{
            borderRadius: 15,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: Appearance.colors.softBorder(),
            overflow: 'hidden',
            width: 175,
            height: 30,
            ...style
        }}>
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
            }}>
                <TouchableOpacity
                activeOpacity={0.6}
                onPress={onPress.bind(this, -1)}
                style={{
                    padding: 9,
                    paddingLeft: 12,
                    width: 30,
                    height: 30,
                    borderRightWidth: 1,
                    borderRightColor: Appearance.colors.softBorder()
                }}>
                    <Image
                    source={require('eCarra/images/minus-icon-grey-small.png')}
                    style={{
                        width: '100%',
                        height: '100%',
                        resizeMode: 'contain'
                    }} />
                </TouchableOpacity>
                <View style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexGrow: 1,
                    textAlign: 'center',
                    backgroundColor: Appearance.colors.divider()
                }}>
                    <TextInput
                    value={`${text || 0}`}
                    onChangeText={onTextChange}
                    style={{
                        ...Appearance.textStyles.standard(),
                        borderWidth: 0,
                        textAlign: 'center',
                        width: 100,
                        height: '100%',
                        backgroundColor: Appearance.colors.divider()
                    }} />
                </View>
                <TouchableOpacity
                activeOpacity={0.6}
                onPress={onPress.bind(this, 1)}
                style={{
                    padding: 9,
                    paddingRight: 12,
                    width: 30,
                    height: 30,
                    borderLeftWidth: 1,
                    borderLeftColor: Appearance.colors.softBorder()
                }}>
                    <Image
                    source={require('eCarra/images/plus-icon-grey-small.png')}
                    style={{
                        width: '100%',
                        height: '100%',
                        resizeMode: 'contain'
                    }} />
                </TouchableOpacity>
            </View>
        </View>
    )
}
export default NumberStepper;
