import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Appearance from 'eCarra/styles/Appearance.js';
import LinearProgress from '@material-ui/core/LinearProgress';

const ProgressBar = ({ animate, style }) => {

    const ProgressBarComponent = withStyles({
        colorPrimary: {
            backgroundColor: Appearance.colors.primary(),
        },
        barColorPrimary: {
            backgroundColor: Appearance.colors.softBorder()
        }
    })(LinearProgress);

    return (
        <div style={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: 2,
            borderRadius: 1,
            overflow: 'hidden',
            ...style
        }}>
            {animate && (
                <ProgressBarComponent />
            )}
        </div>
    )
}
export default ProgressBar;
