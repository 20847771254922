import React from 'react'
import moment from 'moment';

import API from 'eCarra/files/api.js';
import Company from 'eCarra/classes/Company';
import CreditsCard from 'eCarra/classes/CreditsCard.js';
import PaymentMethod from 'eCarra/classes/PaymentMethod.js';
import Request from 'eCarra/files/Request/';
import Reservation from 'eCarra/classes/Reservation';
import Service from 'eCarra/classes/Service';
import Utils from 'eCarra/files/Utils.js';
import Vehicle from 'eCarra/classes/Vehicle';

class UserClass {

    user_id = null;
    username = null;
    password = null;
    api_key = null;
    level = null;
    first_name = null;
    last_name = null;
    full_name = null;
    services = [];
    company = null;
    gender = null;
    location = null;
    approximate_location = null;
    address = null;
    city = null;
    state = null;
    zipcode = null;
    country = null;
    email_address = null;
    phone_number = null;
    member_since = null;
    last_login = null;
    location = null;
    avatar = null;
    referral_code = null;

    accounts = [];
    referrals = null;
    version = null;

    availability = [];
    schedule = [];

	stripe_customer_id = null;
    stripe_customer_data = null;

	payments = [];
    credit_methods = [];
    payment_methods = [];
    vehicles = [];

    constructor() {
        return this;
    }

	create = (props = {}) => {

		this.user_id = props.user_id;
		this.username = props.username;
		this.password = props.password;
		this.api_key = props.api_key;
		this.level = props.level;
		this.first_name = props.first_name;
		this.last_name = props.last_name;
		this.full_name = props.full_name || (props.first_name && props.last_name ? `${props.first_name} ${props.last_name}` : 'Name Not Provided');
        this.company = props.company ? Company.create(props.company) : null;
        this.services = props.services ? props.services.map(s => Service.create(s)) : [];
		this.gender = props.gender;
        this.approximate_location = props.approximate_location;
        this.location = props.location && {
            latitude: props.location.lat,
            longitude: props.location.long
        };
        this.address = props.address;
		this.city = props.city;
		this.state = props.state;
		this.zipcode = props.zipcode;
		this.country = props.country;
		this.email_address = props.email_address;
		this.phone_number = props.phone_number ? Utils.formatPhoneNumber(props.phone_number) : null;
        this.last_login = props.last_login ? moment(props.last_login) : null;
		this.member_since = moment(props.member_since);
		this.avatar = props.avatar ? { uri: props.avatar } : null;
        this.referral_code = props.referral_code;

        this.availability = props.availability;
        this.schedule = props.schedule;
        this.vehicles = props.vehicles;

		this.stripe_customer_id = props.stripe_customer_id;
		this.stripe_customer_data = props.stripe_customer_data;

        return this;
	}

    shouldShowVehicles = () => {
        return (this.voApps && this.voApps.length > 0) || (this.vehicles && this.vehicles.length > 0)
    }

    getShortName = () => {
        return `${this.first_name} ${this.last_name.charAt(0).toUpperCase()}`
    }

    getAddressBook = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { entries } = await Request.get(utils, '/user/', {
                    type: 'address_book',
                    user_id: this.user_id
                })
                resolve(entries || []);
            } catch(e) {
                reject(e);
            }
        })
    }

    addToAddressBook = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id } = await Request.post(utils, '/user/', {
                    type: 'add_to_address_book',
                    user_id: this.user_id,
                    name: props.name,
                    address: props.address,
                    location: props.location && {
                        lat: props.location.latitude,
                        long: props.location.longitude
                    }
                })
                resolve(id);
            } catch(e) {
                reject(e);
            }
        })
    }

    getPaymentMethods = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                if(!this.stripe_customer_id) {
                    resolve({ methods: [], credits: [] })
                    return;
                }

                let { credits, default_source, sources } = await Request.get(utils, '/user/', {
                    type: 'payment_sources',
                    user_id: this.user_id,
                    stripe_id: this.stripe_customer_id
                });

                this.credit_methods = credits ? credits.map(c => CreditsCard.create(c)) : [];
                this.payment_methods = sources && sources.data ? sources.data.map(d => {
                    return PaymentMethod.create({
                        ...d,
                        default: default_source === d.id
                    });
                }) : [];
                resolve({
                    credits: this.credit_methods,
                    methods: this.payment_methods
                });

            } catch(e) {
                reject(e);
            }
        })
    }

    open = () => {
        this.edits = {
            username: this.username,
            password: this.password,
            first_name: this.first_name,
            last_name: this.last_name,
            full_name: this.full_name,
            level: this.level,
            company: this.company,
            gender: this.gender,
            location: this.location,
            address: this.address,
            city: this.city,
            state: this.state,
            zipcode: this.zipcode,
            country: this.country,
            email_address: this.email_address,
            phone_number: this.phone_number,
            avatar: this.avatar,
            referral_code: this.referral_code,
            payment_methods: this.payment_methods,
            tmp_signup_code: null
        }
        return this.edits;
    }

    set = (props = {}) => {
        this.edits = {
            ...this.edits,
            username: props.username || this.edits.username,
            password: props.password || this.edits.password,
            first_name: props.first_name || this.edits.first_name,
            last_name: props.last_name || this.edits.last_name,
            full_name: (props.first_name || this.edits.first_name || '') + ' ' + (props.last_name || this.edits.last_name || ''),
            level: props.level || this.edits.level,
            company: props.company !== undefined ? props.company : this.edits.company,
            gender: props.gender || this.edits.gender,
            location: props.location || this.edits.location,
            address: props.address || this.edits.address,
            city: props.city || this.edits.city,
            state: props.state || this.edits.state,
            zipcode: props.zipcode || this.edits.zipcode,
            country: props.country || this.edits.country,
            email_address: props.email_address || this.edits.email_address,
            phone_number: props.phone_number || this.edits.phone_number,
            avatar: props.image ? props.image.data : this.edits.avatar,
            referral_code: props.referral_code || this.edits.referral_code,
            referred_by: props.referred_by !== undefined ? props.referred_by : this.edits.referred_by
        }
        if(props.tmp_signup_code) {
            this.edits.tmp_signup_code = props.tmp_signup_code;
        }
        return this.edits;
    }

    close = props => {
        let target = props || {};
        let user = this.edits || this;
        this.username = target.username || user.username
        this.password = target.password || user.password
        this.first_name = target.first_name || user.first_name
        this.last_name = target.last_name || user.last_name
        this.full_name = target.full_name || user.full_name
        this.company = target.company || user.company
        this.gender = target.gender || user.gender
        this.location = target.location || user.location
        this.address = target.address || user.address
        this.city = target.city || user.city
        this.state = target.state || user.state
        this.zipcode = target.zipcode || user.zipcode
        this.country = target.country || user.country
        this.email_address = target.email_address || user.email_address
        this.phone_number = target.phone_number || user.phone_number
        this.avatar = target.avatar || user.avatar
        this.referral_code = target.referral_code || user.referral_code
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { location } = await Request.post(utils, '/user/', {
                    type: 'update',
                    user_id: this.user_id,
                    first_name: this.edits.first_name,
                    last_name: this.edits.last_name,
                    company: this.edits.company ? this.edits.company.id : null,
                    username: this.edits.username,
                    level: this.level,
                    location: this.edits.location && {
                        lat: this.edits.location.latitude,
                        long: this.edits.location.longitude
                    },
                    address: this.edits.address,
                    city: this.edits.city,
                    state: this.edits.state,
                    zipcode: this.edits.zipcode,
                    gender: this.edits.gender ? this.edits.gender.code : null,
                    email_address: this.edits.email_address,
                    phone_number: this.edits.phone_number,
                    referral_code: this.edits.referral_code
                });

                this.first_name = this.edits.first_name;
                this.last_name = this.edits.last_name;
                this.full_name = `${this.edits.first_name} ${this.edits.last_name}`;
                this.company = this.edits.company;
                this.username = this.edits.username;
                this.level = this.edits.level;
                this.location = location ? { latitude: location.lat, longitude: location.long } : this.edits.location;
                this.address = this.edits.address;
                this.city = this.edits.city;
                this.state = this.edits.state;
                this.zipcode = this.edits.zipcode;
                this.gender = this.edits.gender;
                this.email_address = this.edits.email_address;
                this.phone_number = this.edits.phone_number;
                this.referral_code = this.edits.referral_code;

                utils.content.update({
                    type: 'user',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

export default {
    new: () => new UserClass(),
    create: props => new UserClass().create(props),
    level: {
        system_admin: 1000,
        admin: 2000,
        company_admin: 2500,
        driver: 3000,
        customer: 4000
    }
};
