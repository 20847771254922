import React from 'react';
import { Platform } from 'react-native';
import base64 from 'react-native-base64';

import API from 'eCarra/files/api.js';
import AsyncStorage from '@react-native-community/async-storage';
import Ble from 'eCarra/files/Ble.js';
import Permissions, { PERMISSIONS, RESULTS } from 'eCarra/files/Permissions/';
import Utils from 'eCarra/files/Utils.js';

const { BleManager } = API.bluetooth ? require('react-native-ble-plx') : require('eCarra/files/DummyModules.js');
export const SensorsCharacteristic = '8a23444a-3f7b-4ee3-a042-5dc96008d207';
export const SystemCharacteristic = 'c31cc8fb-001e-4023-bdc3-a5330a18d0c8';
export const WifiCharacteristic = 'ad051c30-c06d-11ea-b3de-0242ac130004';

class BluetoothManager {

    devices = [];
    enabled = false;
    manager = false;
    serviceIDs = [];
    subscribers = {};

    constructor() {
        this.setupPermissions();
        return this;
    }

    setupPermissions = async () => {
        try {
            if(API.bluetooth === false) {
                throw new Error('bluetooth is not enabled for the app');
                return;
            }
            switch(Platform.OS) {
                case 'android':
                this.enabled = true;
                break;

                case 'ios':
                let result = await Permissions.check(PERMISSIONS.IOS.BLUETOOTH_PERIPHERAL);
                this.enabled = result === RESULTS.GRANTED;
                break;
            }
        } catch(e) {
            console.warn(e.message);
        }
    }

    start = async () => {
        try {
            this.enabled = await this.check();
            if(!this.enabled) {
                throw new Error('bluetooth permission not granted');
                return;
            }

            if(!this.manager) {
                this.manager = new BleManager();
                this.manager.onDeviceDisconnected((error, device) => {
                    if(error) {
                        console.log('ble disconnect error', error.message);
                        return;
                    }
                    // TODO add subscriber notify for disconnection
                })
            }

            const subscription = this.manager.onStateChange(state => {
                if(state === 'PoweredOn') {
                    subscription.remove();
                    this.scanForDevices();
                }
            }, true);

        } catch(e) {
            console.error(e.message);
        }
    }

    stopScanning = () => {
        if(this.manager) {
            console.log('ble scanning stopped')
            this.manager.stopDeviceScan();
        }
    }

    stop = async deviceID => {
        return new Promise(async (resolve, reject) => {
            try {
                let device = devices.find(device => device.id === 'all');
                if(!device) {
                    throw new Error('Device is no longer available');
                    return;
                }
                await device.cancelConnection(device);
                this.onStop({ id: deviceID });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    getSavedServices = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let ids = await AsyncStorage.getItem('ble_services');
                this.serviceIDs = ids ? JSON.parse(ids) : [];
                resolve(this.serviceIDs);
            } catch(e) {
                reject(e);
            }
        })
    }

    scanForDevices = async () => {
        try {
            let serviceIDs = await this.getSavedServices();
            let devices = await this.manager.connectedDevices(serviceIDs);
            if(devices.length > 0) {
                devices.forEach(device => this.parseDevice(device, true));
            }

            // Find devices
            this.manager.startDeviceScan(null, null, (error, device) => {
                if(error || !device.localName) {
                    return;
                }
                this.parseDevice(device, false);
            });
        } catch(e) {
            console.error(e.message);
        }
    }

    parseDevice = (device, isConnectable) => {

        let name = device.name || device.localName ? (device.name || device.localName).toLowerCase() : null;
        if(!name || (!name.includes('seedpod') && !name.includes('raspberrypi'))) {
            return;
        }

        this.onDiscover({
            id: device.id,
            name: 'SeedPod',
            signal: device.rssi,
            unit: device,
            connected: typeof(device.isConnectable) === 'boolean' ? !device.isConnectable : isConnectable,
        })
    }

    disconnect = async device => {
        return new Promise(async (resolve ,reject) => {
            try {
                await device.unit.cancelConnection();
                resolve();
            } catch(e) {
                // automatically remove devices that have already been disconnected
                // this can happen is a device drops connection
                if(e.message.includes('is not connected')) {
                    resolve();
                    return;
                }
                reject(e);
            }
        })
    }

    write = async (characteristic, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                let payload = this.encodePayload(data);
                await characteristic.writeWithResponse(payload);
                resolve();
            } catch(e) {
                reject(e);
            }
        })
    }

    subscribeToSeedPod = async (device, onUpdate) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { service, characteristics } = await this.readAllCharacteristics(device);
                this.updateServices(service); // add to saved devices

                if(!characteristics || characteristics.length === 0) {
                    throw new Error('no characteristics available for device');
                }
                console.log(characteristics);
                let { characteristic } = characteristics.find(({ characteristic }) => {
                    // ios uses "uuid"
                    // android uses "serviceUUID"
                    return characteristic.uuid === SensorsCharacteristic || characteristic.serviceUUID === SensorsCharacteristic;
                }) || {};
                if(!characteristic) {
                    throw new Error('sensor characteristic not available');
                }
                let subscription = characteristic.monitor(async (error, c) => {
                    try {
                        console.log(c);
                        if(!c.value) {
                            return;
                        }
                        let payload = this.decodePayload(c.value);
                        if(typeof(onUpdate) === 'function') {
                            onUpdate(payload);
                        }

                    } catch(e) {
                        console.error(e.message);
                    }
                });
                resolve(subscription);

            } catch(e) {
                reject(e);
            }
        })
    }

    getSerialNumber = async device => {
        return new Promise(async (resolve, reject) => {
            try {
                await device.connect();
                await device.discoverAllServicesAndCharacteristics();

                // Add to devices if not already present
                if(this.devices.findIndex(prevDevice => prevDevice.id === device.id) < 0) {
                    this.devices.push(device);
                }

                // Discover services
                let services = await device.services();
                if(!services || services.length === 0) {
                    throw new Error('Unable to discover bluetooth services');
                    return;
                }

                let characteristics = await services[0].characteristics();
                if(!characteristics || characteristics.length === 0) {
                    throw new Error('Unable to discover bluetooth characteristics');
                    return;
                }

                let system = characteristics.find(characteristic => characteristic.uuid === SystemCharacteristic);
                if(!system) {
                    throw new Error('Unable to locate system characteristic');
                    return;
                }

                let c = await system.read();
                let payload = this.decodePayload(c.value);
                resolve({
                    service: services[0],
                    characteristic: c,
                    serialNumber: payload.id
                });
                resolve({});

            } catch(e) {
                reject(e);
            }
        })
    }

    readAllCharacteristics = async device => {
        return new Promise(async (resolve, reject) => {
            try {
                await device.connect();
                await device.discoverAllServicesAndCharacteristics();

                // Add to devices if not already present
                if(this.devices.findIndex(prevDevice => prevDevice.id === device.id) < 0) {
                    this.devices.push(device);
                }

                // Discover services
                let services = await device.services();
                if(!services || services.length === 0) {
                    throw new Error('Unable to discover bluetooth services');
                    return;
                }

                let characteristics = await services[0].characteristics();
                if(!characteristics || characteristics.length === 0) {
                    throw new Error('Unable to discover bluetooth characteristics');
                    return;
                }

                let response = {
                    service: services[0],
                    characteristics: []
                }
                for (let i = 0; i < characteristics.length; i++) {
                    let c = await characteristics[i].read();
                    response.characteristics.push({
                        characteristic: c,
                        payload: this.decodePayload(c.value)
                    })
                }
                resolve(response);

            } catch(e) {
                reject(e);
            }
        })
    }

    check = async () => {
        return new Promise(async (resolve, reject) => {
            if(Platform.OS === 'android') {
                resolve(true);
                return
            }
            try {
                let result = await Permissions.check(PERMISSIONS.IOS.BLUETOOTH_PERIPHERAL)
                resolve(result === RESULTS.GRANTED);
            } catch(e) {
                reject(e);
            }
        })
    }

    request = async () => {
        return new Promise(async (resolve, reject) => {
            if(Platform.OS === 'android') {
                resolve(true);
                return
            }
            try {
                let result = await Permissions.request(PERMISSIONS.IOS.BLUETOOTH_PERIPHERAL)
                console.log(result);
                resolve(result === RESULTS.GRANTED);
            } catch(e) {
                reject(e);
            }
        })
    }

    updateServices = async service => {
        try {
            if(!service) {
                return
            }
            if(this.serviceIDs.filter(serviceID => service.uuid === serviceID).length === 0) {
                let uuids = [ service.uuid.toString() ].concat(this.serviceIDs);
                await AsyncStorage.setItem('ble_services', JSON.stringify(uuids));
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    onStart = (props) => {
        this.isActive = true;
        Object.values(this.subscribers).forEach(client => {
            if(client.onStart && typeof(client.onStart) === 'function') {
                client.onStart(props);
            }
        })
    }

    onDiscover = (props) => {
        console.log('not implemented');
    }

    onUpdate = (props) => {
        Object.values(this.subscribers).forEach(client => {
            if(client.onUpdate && typeof(client.onUpdate) === 'function') {
                client.onUpdate(props);
            }
        })
    }

    onStop = (props) => {
        this.isActive = false;
        Object.values(this.subscribers).forEach(client => {
            if(client.onStop && typeof(client.onStop) === 'function') {
                client.onStop(props);
            }
        })
    }

    subscribe = (id, client) => {
        this.subscribers[id] = client;
        return this;
    }

    unsubscribe = (id) => {
        delete this.subscribers[id];
        return this;
    }

    encodePayload = props => {
        if(!props) {
            return null;
        }
        let { id, device, event, data } = props;
        if(device) {
            return base64.encode(`${id};${event};${device};${data || 0}`);
        }
        return base64.encode(`${id};${event};${data || 0}`);
    }

    decodePayloadData = data => {
        const parsePieces = part => {
            try {
                let pieces = part.toString().split(' : ');
                if(pieces.length === 1) {
                    return isNaN(pieces) ? pieces : parseFloat(pieces);
                }
                return pieces.map(item => {
                    return isNaN(item) ? item : parseFloat(item);
                })

            } catch(e) {
                console.error(e);
                return null
            }
        }

        try {
            if(!data) {
                throw new Error('no data received in payload');
            }
            let parts = data.toString().split(',');
            if(parts.length === 1) {
                return parsePieces(data);
            }
            return parts.map(part => parsePieces(part));

        } catch(e) {
            console.error(e.message);
            return null;
        }
    }

    decodePayload = data => {
        try {
            if(!data) {
                return {};
            }
            let payload = base64.decode(data);
            if(!payload) {
                return {};
            }
            let components = payload.split(';');
            let response = {
                id: components[0],
                event: parseInt(components[1])
            }

            // payload without device identification
            if(components.length === 3) {
                response.data = this.decodePayloadData(components[2]);
                return response;
            }

            // payload with device identification
            response.device = parseInt(components[2]);
            response.data = this.decodePayloadData(components[3]);
            return response;

        } catch(e) {
            console.error(e.message);
            return {};
        }
    }
}
export default BluetoothManager;
