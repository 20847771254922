import { Platform } from 'react-native';
import QueryString from 'eCarra/files/QueryString/';

const client_id = 'ecarra';
export const dev_env = false;
export const version = 5;
export const server = dev_env ? 'http://ecarra.local.192.168.7.37.nip.io' : 'https://ecarra.app';
export const simulate_location = false;

export default {
    android_package: 'com.ecarra.android',
    apple_pay: 'merchant.com.ecarra.ios',
    bluetooth: true,
    bundle_identifier: 'com.ecarra.ios',
    dev_env: dev_env,
    firebase: {
        sender_id: '546052153171'
    },
    get_client_id: () => {
        if(Platform.OS === 'web') {
            let { app_id } = QueryString.parse(window.location.search);
            return app_id || client_id;
        }
        return client_id;
    },
    mapbox: 'pk.eyJ1IjoibWlrZWNhcnAiLCJhIjoiY2pvZXF5ZnVjMDFhejNwbW1yaDRoNnpoNSJ9.l0qpJf-6yGDYmTXE0JFfVg',
    server: server,
    simulate_location: simulate_location,
    sockets: dev_env ? `${server}:4000` : `https://websockets.ecarra.app`,
    stripe: 'pk_live_fY0CKaRY3MA4TgsZ4au9EFgF',
    stripe_test: 'pk_test_i1vG71cyf3PUv7KPoaa0gBGa',
    url: `${server}/api/v${version}`,
    valhalla: 'b434e2e3b2fda2b2581321d0eEoP1ff3b0',
    version: version
}
