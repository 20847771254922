import React from 'react';

import API from 'eCarra/files/api.js';
import RouteCategory from 'eCarra/classes/RouteCategory.js';
import Service from 'eCarra/classes/Service.js';

class RouteOptionClass {

    id = null;
    name = null;
    service = null;
    category = null;
    address = null;
    location = null;
    active = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.name = props.name;
        this.category = props.category;
        this.address = props.address;
        this.location = props.location && {
            latitude: props.location.lat,
            longitude: props.location.long
        };
        this.active = Boolean(props.active);
        this.service = props.service ? Service.create(props.service) : null;
        this.category = props.category ? RouteCategory.create(props.category) : null;
        return this;
    }
}
export default {
    create: props => new RouteOptionClass().create(props)
};
