import React, { useRef, useState, useEffect } from 'react';
import { Animated, Image, Text, View } from 'react-native';

import Appearance from 'eCarra/styles/Appearance.js';
import TouchableHighlight from 'eCarra/views/TouchableHighlight/';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';

const DropdownMenu = ({ utils, label, items, onChange, onOpenLabel, style }) => {

    const ref = useRef(null);
    const itemHeight = 35;

    const [expanded, setExpanded] = useState(false);
    const [height, setHeight] = useState(new Animated.Value(0));
    const [opacity, setOpacity] = useState(new Animated.Value(0));
    const [width, setWidth] = useState(0);

    const onPress = item => {
        setExpanded(!expanded);
        if(typeof(onChange) === 'function') {
            onChange(item)
        }
    }

    const onShowSheet = () => {
        setExpanded(true);
        utils.sheet.show({
            onClose: () => setExpanded(false),
            items: items.map(item => {
                return {
                    key: item.key,
                    title: item.title,
                    style: 'default'
                }
            })
        }, key => {
            setExpanded(false);
            if(key) {
                onPress(items.find(item => key === item.key))
            }
        });
    }

    useEffect(() => {
        if(ref && ref.current) {
            ref.current.measure((ox, oy, width, height, px, py) => {
                setWidth(width);
            })
        }
    }, [ref]);

    return (
        <View style={{
            alignItems: 'center',
            ...style
        }}>
            <TouchableOpacity
            activeOpacity={0.6}
            onPress={onShowSheet}
            style={{
                marginBottom: 8
            }}>
                <View ref={ref}
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderRadius: 25,
                    paddingHorizontal: 15,
                    paddingVertical: 8,
                    width: 'auto',
                    backgroundColor: Appearance.colors.dropdown()
                }}>
                    <View style={{
                        marginRight: 12,
                        borderRightWidth: 1,
                        borderRightColor: Appearance.colors.grey()
                    }}>
                    <Text style={{
                        ...Appearance.textStyles.title(),
                        fontSize: 16,
                        color: Appearance.colors.text(),
                        paddingRight: 12,
                        paddingLeft: 4
                    }}>{expanded ? (onOpenLabel || label ) : label}</Text>
                    </View>
                    <Image source={expanded ? require('eCarra/images/up-arrow-grey-small.png') : require('eCarra/images/down-arrow-grey-small.png')} style={{
                        width: 15,
                        height: 15,
                        resizeMode: 'contain',
                        tintColor: Appearance.colors.subText()
                    }} />
                </View>
            </TouchableOpacity>
        </View>
    )
}
export default DropdownMenu;
