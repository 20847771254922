import React, { useEffect, useState } from 'react';
import { Animated, Image, Text, View } from 'react-native';

import update from 'immutability-helper';

import Appearance from 'eCarra/styles/Appearance.js';
import { AltBadge } from 'eCarra/views/Main.js';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import Utils from 'eCarra/files/Utils.js';

const CheckboxList = ({ items, max, onChange }) => {

    const [enabled, setEnabled] = useState(true);
    const [selected, setSelected] = useState(items.filter(item => item.selected).map(item => item.id));

    const onItemPress = item => {
        setSelected(selected => {
            if(selected.includes(item.id)) {
                return selected.filter(id => id !== item.id);
            }
            if(enabled) {
                return selected.concat([ item.id ]);
            }
            return selected;
        })
    }

    const getContent = () => {
        if(!items || items.length === 0) {
            return null;
        }
        return items.map((item, index) => {
            return (
                <View key={index}
                style={{
                    width: '100%',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: index !== items.length - 1 ? 8 : 0,
                    opacity: selected.includes(item.id) || enabled ? 1 : 0.5
                }}>
                    <TouchableOpacity
                    activeOpacity={1}
                    onPress={onItemPress.bind(this, item)}
                    style={{
                        width: 20,
                        height: 20,
                        padding: 4,
                        borderRadius: 5,
                        overflow: 'hidden',
                        borderWidth: 1,
                        borderColor: Appearance.colors.softBorder(),
                        backgroundColor: Appearance.colors.background()
                    }}>
                        {selected.includes(item.id) && (
                            <View style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: 10,
                                overflow: 'hidden',
                                backgroundColor: Appearance.colors.primary()
                            }}/>
                        )}
                    </TouchableOpacity>
                    <Text style={{
                        ...Appearance.textStyles.subTitle(),
                        marginHorizontal: 12,
                        flexGrow: 1
                    }}>{item.title}</Text>
                    {item.cost > 0 && (
                        <AltBadge content={{
                            text: `Add ${Utils.toCurrency(item.cost)}`,
                            color: Appearance.colors.primary()
                        }} />
                    )}
                </View>
            )
        });
    }

    useEffect(() => {
        setEnabled(max && selected.length === max ? false : true);
        if(typeof(onChange) === 'function') {
            onChange(items.map(item => {
                item.selected = selected.includes(item.id);
                return item;
            }))
        }
    }, [selected]);

    return getContent();
}
export default CheckboxList;
