import React from 'react';

const StatusCodes = {
    reservations: {
        pending: null,
        rejected: 0,
        approved: 1,
        returned: 2,
        cancelled: 3,
        toPickup: 4,
        toDestination: 5,
        completed: 6,
        unpaid: 7,
        preauthorized: 8,
        preauthorizationFailed: 9,
        preauthorizationRevoked: 10,
        adminUpdated: 11,
        driverUpdated: 12,
        customerEdited: 13,
        chargeProcessing: 14,
        chargePosted: 15,
        chargeFailed: 16,
        chargeIssue: 17
    },
    routes: {
        pending: null,
        approved: 1,
        returned: 2,
        cancelled: 3,
        inProgress: 5,
        completed: 6,
        unpaid: 7
    },
    orders: {
        pending: null,
        rejected: 0,
        approved: 1,
        returned: 2,
        cancelled: 3,
        toPickup: 4,
        arrivedAtHost: 5,
        toDestination: 6,
        completed: 7,
        unpaid: 8,
        preauthorized: 9,
        preauthorizationFailed: 10,
        preauthorizationRevoked: 11,
        adminUpdated: 12,
        driverUpdated: 13,
        customerEdited: 14,
        chargeProcessing: 15,
        chargePosted: 16,
        chargeFailed: 17,
        chargeIssue: 18
    },
    supportTickets: {
        pending: null,
        accepted: 1,
        active: 2,
        closed: 3
    },
    subscriptions: {
        pending: null,
        activated: 1,
        deactivated: 2,
        cancelled: 3,
        metThreshold: 4,
        chargeProcessing: 5,
        chargePosted: 6,
        chargeFailed: 7,
        chargeIssue: 8,
        noCharge: 9
    },
    voApp: {
        pending: null,
        approved: 1,
        rejected: 2,
        cancelled: 3,
        infoNeeded: 4
    },
    doApp: {
        pending: null,
        approved: 1,
        rejected: 2,
        cancelled: 3,
        infoNeeded: 4
    },
    credits: {
        add: 1,
        return: 2,
        charge: 3,
        expired: 4,
        redeem: 5,
        removed: 6
    },
    rewards: {
        rejected: 0,
        approved: 1,
        cancelled: 2,
        completed: 3
    }
};

export default StatusCodes;
export const Drivers = {
    compensation: {
        fixed: 1,
        hour: 2,
        minute: 3,
        mileage: 4
    }
}
