import React, { useRef, useEffect, useState } from 'react';
import { Animated, Image, Linking, Platform, Text, View } from 'react-native';

import update from 'immutability-helper';

import Appearance from 'eCarra/styles/Appearance.js';
import { AppearanceProvider } from 'react-native-appearance';
import InnerShadow from 'eCarra/views/InnerShadow.js';
import LottieView from 'eCarra/views/Lottie/';
import Screen from 'eCarra/files/Screen.js';
import TextField from 'eCarra/views/TextField.js';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import Utils from 'eCarra/files/Utils.js';
import WebView from 'eCarra/views/WebView/';

export const NavigationHeight = (30 + (Screen.safeArea.top + 50));

const Navigation = ({ active, utils, state, middleContent, leftButton, onLeftPress, rightButton, onRightPress, onSearch, scrollTo }) => {

    const backButton = useRef(null);
    const navigation = useRef(null);
    const quick_scan = useRef(null);
    const verticalDetails = useRef(null);

    const [content, setContent] = useState({
        leftButton: leftButton,
        middleContent: middleContent,
        rightButton: rightButton
    });
    const [navState, setNavState] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [styling, setStyling] = useState({
        fields: {
            search: {
                animations: {
                    marginRight: Screen.width()
                },
                animateOnMount: null
            }
        },
        buttons: {
            navigation: 'white'
        },
        boxShadow: Appearance.boxShadow({
            opacity: 0.1,
            offset: {
                width: 0,
                height: 10
            }
        })
    })
    const [top, setTop] = useState(new Animated.Value(-125));

    const setLottie = state => {
        setTimeout(() => {
            if(quick_scan.current) {
                quick_scan.current[state || 'play']();
            }
            if(backButton.current) {
                backButton.current[state || 'play']();
            }
            if(navigation.current) {
                navigation.current[state || 'play']();
            }
            if(verticalDetails.current) {
                verticalDetails.current[state || 'play']();
            }
        }, 100);
    }

    // Set content
    useEffect(() => {
        setContent(content => update(content, {
            leftButton: {
                $set: leftButton || content.leftButton
            },
            middleContent: {
                $set: middleContent || content.middleContent
            },
            rightButton: {
                $set: rightButton || content.rightButton
            }
        }));

    }, [leftButton, middleContent, rightButton]);


    const onChangeSearchText = text => {
        setSearchText(text);
        if(typeof(onSearch) === 'function') {
            onSearch(text);
        }
    }

    const getLeftButton = () => {

        if(content.leftButton) {
            switch(content.leftButton.image) {
                case 'back':
                return (
                    <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={content.leftButton && content.leftButton.onPress ? content.leftButton.onPress : onLeftPress}
                    style={{
                        width: 30,
                        height: 30
                    }}>
                        <LottieView
                        ref={backButton}
                        autoPlay={false}
                        loop={false}
                        source={styling.buttons.navigation === 'white' ? require('eCarra/files/lottie/back-button-white.json') : require('eCarra/files/lottie/back-button-grey.json')}
                        duration={2500}
                        style={{
                            width: '100%',
                            height: '100%'
                        }}/>
                    </TouchableOpacity>
                )
            }
        }

        return (
            <TouchableOpacity
            activeOpacity={0.6}
            onPress={content.leftButton && content.leftButton.onPress ? content.leftButton.onPress : onLeftPress}
            style={{
                width: navState === 'bookRide' ? 30 : 35,
                height: navState === 'bookRide' ? 30 : 35,
                padding: content.rightButton ? 0:2
            }}>
                <LottieView
                ref={navigation}
                autoPlay={false}
                loop={false}
                source={styling.buttons.navigation === 'white' ? require('eCarra/files/lottie/navigation-white.json') : require('eCarra/files/lottie/navigation-grey.json')}
                duration={2500}
                style={{
                    width: '100%',
                    height: '100%'
                }}/>
            </TouchableOpacity>
        )
    }

    const getRightButton = () => {

        let updatedLottie = {};

        if(content.rightButton) {
            switch(content.rightButton.image) {
                case 'vertical-details':
                return (
                    <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={content.rightButton && content.rightButton.onPress ? content.rightButton.onPress : onRightPress}
                    style={{
                        width: 20,
                        height: 30,
                        padding: content.rightButton ? 0:2
                    }}>
                        <LottieView
                        ref={verticalDetails}
                        autoPlay={false}
                        loop={false}
                        duration={2500}
                        preserveAspectRatio={'xMidYMid slice'}
                        source={styling.buttons.navigation === 'white' ? require('eCarra/files/lottie/vertical-details-white.json') : require('eCarra/files/lottie/vertical-details-grey.json')}
                        style={{
                            width: '100%',
                            height: '100%'
                        }}/>
                    </TouchableOpacity>
                )
                break;

                case 'navigation':
                return (
                    <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={content.rightButton && content.rightButton.onPress ? content.rightButton.onPress : onRightPress}
                    style={{
                        width: 30,
                        height: 30,
                        padding: 2
                    }}>
                        <LottieView
                        ref={navigation}
                        autoPlay={false}
                        loop={false}
                        duration={2500}
                        source={styling.buttons.navigation === 'white' ? require('eCarra/files/lottie/navigation-white.json') : require('eCarra/files/lottie/navigation-grey.json')}
                        style={{
                            width: '100%',
                            height: '100%'
                        }}/>
                    </TouchableOpacity>
                )
                break;

                case 'quick_scan':
                return (
                    <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={content.rightButton && content.rightButton.onPress ? content.rightButton.onPress : onRightPress}
                    style={{
                        width: 35,
                        height: 35,
                    }}>
                        <LottieView
                        autoPlay={true}
                        loop={true}
                        duration={2500}
                        source={require('eCarra/files/lottie/quick-scan-white.json')}
                        style={{
                            width: '100%',
                            height: '100%'
                        }}/>
                    </TouchableOpacity>
                )
            }
            return null;
        }

        switch(navState) {
            case 'bookRide': // empty view if quick scan is not supported => needed to keep items aligned
            return (
                <TouchableOpacity
                activeOpacity={0.6}
                onPress={content.rightButton && content.rightButton.onPress ? content.rightButton.onPress : onRightPress}
                style={{
                    width: 35,
                    height: 35,
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    duration={2500}
                    source={require('eCarra/files/lottie/quick-scan-white.json')}
                    style={{
                        width: '100%',
                        height: '100%'
                    }}/>
                </TouchableOpacity>
            )
        }
        return null;
    }

    const getMiddleContent = () => {

        if(typeof(content.middleContent) === 'string') {
            return (
                <View style={{
                    flex: 1,
                    flexGrow: 1,
                    paddingLeft: 15,
                    paddingRight: 15
                }}>
                    <Text
                    numberOfLines={1}
                    eclipseMode={'tail'}
                    style={{
                        width: 'auto',
                        textAlign: 'center',
                        fontSize: 18,
                        ...Appearance.fontWeight.get(600),
                        color: 'white',
                        paddingRight: 30
                    }}>{content.middleContent}</Text>
                </View>
            )
        }

        let theme = Appearance.themeStyle();
        return (
            <Image
            source={utils.client.get().logos[theme]}
            style={{
                width: 'auto',
                height: utils.client.get().id === 'ecarra' ? 20 : 25,
                flexGrow: 1,
                resizeMode: 'contain',
                marginLeft: 8,
                marginRight: 8,
                tintColor: 'white'
            }}/>
        )
    }

    const getContent = () => {
        switch(navState) {
            case 'payments':
            case 'driverReservations':
            case 'vehicles':
            case 'reservations':
            return (
                <View style={{
                    width: '100%',
                    height: 50,
                    overflow: 'hidden'
                }}>
                    <View style={{
                        height: 50,
                        paddingTop: 8,
                        paddingBottom: 8
                    }}>
                        <View style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                            {getLeftButton()}
                            <View style={{
                                flexGrow: 1,
                                paddingLeft: 8,
                                marginLeft: 4
                            }}>
                                <TextField
                                icon={'search'}
                                value={searchText}
                                onChange={onChangeSearchText}
                                animations={styling.fields.search.animations}
                                animateOnMount={styling.fields.search.animateOnMount}/>
                            </View>
                        </View>
                    </View>
                </View>
            )

            case 'emissions':
            case 'messages':
            return (
                <View style={{
                    width: '100%',
                    height: 50,
                    overflow: 'hidden'
                }}>
                    <View style={{
                        height: 50,
                        paddingTop: 8,
                        paddingBottom: 8
                    }}>
                        <View style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                            {getLeftButton()}
                            <View style={{
                                padding: 5
                            }}>
                                {getRightButton()}
                            </View>
                        </View>
                    </View>
                </View>
            )

            case 'notifications':
            return (
                <View style={{
                    width: '100%',
                    height: 50,
                    overflow: 'hidden'
                }}>
                    <View style={{
                        height: 50,
                        paddingTop: 8,
                        paddingBottom: 8
                    }}>
                        <View style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                            {getLeftButton()}
                            <View style={{
                                flexGrow: 1,
                                marginRight: 8,
                                paddingLeft: 8,
                                marginLeft: 4
                            }}>
                                <TextField
                                icon={'search'}
                                value={searchText}
                                onChange={onChangeSearchText}
                                animations={styling.fields.search.animations}
                                animateOnMount={styling.fields.search.animateOnMount}/>
                            </View>
                            {getRightButton()}
                        </View>
                    </View>
                </View>
            )

            case 'settings':
            return (
                <View style={{
                    width: '100%',
                    height: 50,
                    overflow: 'hidden'
                }}>
                    <View style={{
                        height: 50,
                        paddingTop: 8,
                        paddingBottom: 8
                    }}>
                        <View style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                            {getLeftButton()}
                            <View style={{
                                padding: 5
                            }}>
                                {getRightButton()}
                            </View>
                        </View>
                    </View>
                </View>
            )

            default:
            // Specialty Channels
            if(navState && navState.includes('news') || navState && navState.includes('order')) {
                return (
                    <View style={{
                        width: '100%',
                        height: 50,
                        overflow: 'hidden'
                    }}>
                        <View style={{
                            height: 50,
                            paddingTop: 8,
                            paddingBottom: 8
                        }}>
                            <View style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                width: '100%'
                            }}>
                                {getLeftButton()}
                                <View style={{
                                    flexGrow: 1,
                                    paddingLeft: 8,
                                    marginLeft: 4
                                }}>
                                    <TextField
                                    icon={'search'}
                                    value={searchText}
                                    onChange={onChangeSearchText}
                                    animations={styling.fields.search.animations}
                                    animateOnMount={styling.fields.search.animateOnMount}/>
                                </View>
                            </View>
                        </View>
                    </View>
                )
            }
        }

        return (
            <BubbleHeader
            left={getLeftButton()}
            center={getMiddleContent()}
            right={getRightButton()}/>
        )
    }

    // View changes
    useEffect(() => {

        // hide Navigation
        Animated.timing(top, {
            toValue: -125,
            duration: 250,
            useNativeDriver: false
        }).start(() => {

            // make changes
            setLottie('reset');
            setNavState(active.view);
            setSearchText(null);
            setContent({
                leftButton: null,
                middleContent: null,
                rightButton: null
            })

            // changes only needed for web or tablet
            if(Platform.OS === 'web' || Utils.isMobile() === false) {
                if(active.view === 'bookRide') {
                    setContent(content => update(content, {
                        rightButton: {
                            $set: {
                                image: 'quick_scan',
                                color: 'white',
                                style: {
                                    marginTop: 2,
                                    width: 25,
                                    height: 25
                                }
                            }
                        }
                    }));
                    setStyling(styling => update(styling, {
                        boxShadow: {
                            $set: null
                        },
                        buttons: {
                            navigation: {
                                $set: 'white'
                            }
                        }
                    }));
                }
            }

            // changes are only needed for mobile
            if(Platform.OS !== 'web' && Utils.isMobile() === true) {
                let styles = null;
                switch(active.view) {
                    case 'bookRide':
                    styles = update(styling, {
                        boxShadow: {
                            $set: null
                        },
                        buttons: {
                            navigation: {
                                $set: 'white'
                            }
                        }
                    });
                    setContent(content => update(content, {
                        rightButton: {
                            $set: {
                                image: 'quick_scan',
                                color: 'white',
                                style: {
                                    marginTop: 2,
                                    width: 25,
                                    height: 25
                                }
                            }
                        }
                    }));
                    break;

                    case 'payments':
                    case 'driverReservations':
                    case 'messages':
                    case 'vehicles':
                    case 'reservations':
                    styles = update(styling, {
                        boxShadow: {
                            $set: null
                        },
                        buttons: {
                            navigation: {
                                $set: Appearance.themeStyle() == 'dark' ? 'white' : Appearance.colors.darkGrey
                            }
                        }
                    });
                    break;

                    case 'notifications':
                    styles = update(styling, {
                        boxShadow: {
                            $set: null
                        },
                        buttons: {
                            navigation: {
                                $set: Appearance.themeStyle() == 'dark' ? 'white' : Appearance.colors.darkGrey
                            }
                        }
                    });
                    setContent(content => update(content, {
                        rightButton: {
                            ...Platform.OS === 'web' && {
                                $set: null
                            },
                            ...Platform.OS !== 'web' && {
                                $set: {
                                    image: 'vertical-details',
                                    color: Appearance.themeStyle() == 'dark' ? 'white' : Appearance.colors.darkGrey,
                                    style: {
                                        width: 25,
                                        height: 25
                                    }
                                }
                            }
                        }
                    }));
                    break;

                    case 'emissions':
                    styles = update(styling, {
                        boxShadow: {
                            $set: null
                        },
                        buttons: {
                            navigation: {
                                $set: Appearance.themeStyle() == 'dark' ? 'white' : Appearance.colors.darkGrey
                            }
                        }
                    });
                    setContent(content => update(content, {
                        rightButton: {
                            ...Platform.OS === 'web' && {
                                $set: null
                            },
                            ...Platform.OS !== 'web' && {
                                $set: {
                                    image: 'vertical-details',
                                    color: Appearance.themeStyle() == 'dark' ? 'white' : Appearance.colors.darkGrey,
                                    style: {
                                        width: 25,
                                        height: 25
                                    }
                                }
                            }
                        }
                    }));
                    break;

                    case 'settings':
                    styles = update(styling, {
                        boxShadow: {
                            $set: null
                        },
                        buttons: {
                            navigation: {
                                $set: Appearance.themeStyle() == 'dark' ? 'white' : Appearance.colors.darkGrey
                            }
                        }
                    });
                    setContent(content => update(content, {
                        rightButton: {
                            $set: {
                                image: 'vertical-details',
                                color: Appearance.themeStyle() == 'dark' ? 'white' : Appearance.colors.darkGrey,
                                style: {
                                    width: 25,
                                    height: 25
                                }
                            }
                        }
                    }));
                    break;

                    default:
                    if(active.view && (active.view.includes('order') || active.view.includes('news'))) {
                        styles = update(styling, {
                            boxShadow: {
                                $set: null
                            },
                            buttons: {
                                navigation: {
                                    $set: Appearance.themeStyle() == 'dark' ? 'white' : Appearance.colors.darkGrey
                                }
                            }
                        });

                    } else {

                        styles = update(styling, {
                            boxShadow: {
                                $set: Appearance.boxShadow({
                                    opacity: 0.1,
                                    offset: {
                                        width: 0,
                                        height: 10
                                    }
                                })
                            },
                            buttons: {
                                navigation: {
                                    $set: 'white'
                                }
                            },
                            fields: {
                                search: {
                                    animateOnMount: {
                                        $set: null
                                    }
                                }
                            }
                        });
                    }
                }
                setStyling(styles);
            }

            // show navigation if view is bookRide
            if(active.view === 'bookRide') {
                utils.events.addListener('onLoadComplete', () => {
                    Animated.spring(top, {
                        toValue: 0,
                        duration: 250,
                        friction: 10,
                        useNativeDriver: false
                    }).start(() => {
                        utils.events.removeListener('onLoadComplete');
                    });
                    setLottie();
                })
                return;
            }

            // prevent moving forward if platform is web or device is tablet
            if(Platform.OS === 'web' || Utils.isMobile() === false) {
                return;
            }

            Animated.spring(top, {
                toValue: 0,
                duration: 250,
                friction: 10,
                useNativeDriver: false
            }).start();
            setLottie();

            setTimeout(() => {
                switch(active.view) {
                    case 'notifications':
                    case 'payments':
                    case 'driverReservations':
                    case 'messages':
                    case 'vehicles':
                    case 'reservations':
                        setStyling(styles => update(styles, {
                            fields: {
                                search: {
                                    animateOnMount: {
                                        $set: {
                                            marginRight: 0
                                        }
                                    }
                                }
                            }
                        }))
                        break;

                    default:
                    if(active.view && (active.view.includes('order') || active.view.includes('news'))) {

                        // All Order Channels
                        setStyling(styles => update(styles, {
                            fields: {
                                search: {
                                    animateOnMount: {
                                        $set: {
                                            marginRight: 0
                                        }
                                    }
                                }
                            }
                        }))
                    }
                }
            }, 250);
        })
    }, [active]);

    useEffect(() => {
        switch(state) {
            case 'reset':
            setContent(update(content, {
                leftButton: {
                    $set: null
                },
                middleContent: {
                    $set: null
                },
                rightButton: {
                    $set: null
                }
            }));
            break;

            case 'show':
            Animated.spring(top, {
                toValue: 0,
                duration: 250,
                friction: 10,
                useNativeDriver: false
            }).start();
            setLottie();
            break;

            case 'hide':
            Animated.timing(top, {
                toValue: -125,
                duration: 250,
                useNativeDriver: false
            }).start();
            setLottie('reset');
            break;
        }
    }, [state]);

    return (
        <Animated.View style={{
            position: 'absolute',
            top: top,
            left: 0,
            right: 0,
            height: 66,
            marginTop: Screen.safeArea.top
        }}>
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                padding: 15,
                width: '100%',
                height: 50,
                ...styling.boxShadow
            }}>
                {getContent()}
            </View>
        </Animated.View>
    )
}

export default Navigation;

export const BubbleHeader = ({ left, right, center, style }) => {
    return (
        <View style={{
            width: '100%',
            backgroundColor: Appearance.colors.primary(),
            borderRadius: 25,
            height: 50,
            overflow: 'hidden',
            maxWidth: Screen.component.maxWidth,
            ...style
        }}>
            <InnerShadow>
                <View style={{
                    height: '100%',
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingTop: 8,
                    paddingBottom: 8
                }}>
                    <View style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        {left}
                        {center}
                        {right}
                    </View>
                </View>
            </InnerShadow>
        </View>
    )
}
