import React from 'react';

const PromoCodes = {
    standard: {
        fixedCost: 1,
        percentage: 2,
        fullCost: 3,
        credits: 4
    }
};

export default PromoCodes;
