import React, { useRef, useState, useEffect } from 'react';
import { Animated, FlatList, Image, Keyboard, LogBox, Platform, Text, TouchableWithoutFeedback, View } from 'react-native';

LogBox.ignoreLogs([
    'Unrecognized WebSocket connection option(s) `agent`, `perMessageDeflate`, `pfx`, `key`, `passphrase`, `cert`, `ca`, `ciphers`, `rejectUnauthorized`. Did you mean to put these under `headers`?', 'Setting a timer', 'Async Storage', 'Layer index is greater than number of layers on map. Layer inserted at end of layer stack',
    'Animated: `useNativeDriver` was not specified. This is a required option and must be explicitly set to `true` or `false`'
]);

import { checkNotificationPermission } from 'eCarra/managers/Settings.js';
import { fetchDriverVehicle, driverVehicleMileageRequest } from 'eCarra/managers/Vehicles.js';
import { getContent } from 'eCarra/files/Content.js';
import moment from 'moment';
import update from 'immutability-helper';

import API from 'eCarra/files/api.js';
import Abstract, { buildTag } from 'eCarra/classes/Abstract.js';
import Alert from 'eCarra/views/Alert.js';
import AlertStack from 'eCarra/views/AlertStack.js';
import Appearance from 'eCarra/styles/Appearance.js';
import { AppearanceProvider } from 'eCarra/files/Appearance/';
import AsyncStorage from '@react-native-community/async-storage';
import BluetoothManager from 'eCarra/files/BluetoothManager.js';
import Button from 'eCarra/views/Button.js';
import ContentManager, { SearchList } from 'eCarra/managers/ContentManager.js';
import DatePickerAlert from 'eCarra/views/DatePickerAlert/';
import DeviceInfo from 'react-native-device-info';
import EventEmitter from 'eventemitter3';
import * as Font from 'expo-font';
import Layer, { LayerShell, FloatingLayers, LayerEndIndex, FloatingLayerMinimizedHeight } from 'eCarra/structure/Layer.js';
import Loader from 'eCarra/views/Loader.js';
import LocationManager from 'eCarra/files/LocationManager/';
import LoginCard from 'eCarra/views/LoginCard.js';
import KeyboardManager from 'eCarra/files/KeyboardManager.js';
import { Map } from 'eCarra/views/Maps/';
import Message from 'eCarra/classes/Message.js';
import { Messaging } from 'eCarra/managers/Messages.js';
import MessagingSidebar from 'eCarra/structure/MessagingSidebar.js';
import MobileNotification from 'eCarra/views/MobileNotification.js';
import Navigation, { NavigationHeight } from 'eCarra/structure/Navigation.js';
import Notification from 'eCarra/classes/Notification.js';
import OnDemandNew from 'eCarra/views/OnDemandNew.js';
import OnDemandSearching from 'eCarra/views/OnDemandSearching.js';
import Order from 'eCarra/classes/Order.js';
import { OrderDetails, OrderHostDetails } from 'eCarra/managers/Orders.js';
import Panel from 'eCarra/structure/Panel.js';
import { PaymentDetails, SubscriptionDetails } from 'eCarra/managers/Payments.js';
import PermissionsRequest from 'eCarra/views/PermissionsRequest.js';
import PushNotification from 'eCarra/files/PushNotification/';
import PushNotificationIOS from '@react-native-community/push-notification-ios';
import QRCamera from 'eCarra/views/QRCamera.js';
import QuickFeedback from 'eCarra/views/QuickFeedback.js';
import Request from 'eCarra/files/Request/';
import Reservation, { getReservationDriver } from 'eCarra/classes/Reservation.js';
import { ReservationDetails, DriverTimecardOverview } from 'eCarra/managers/Reservations.js';
import * as RNLocalize from 'react-native-localize';
import { RouteDetails } from 'eCarra/managers/Routes.js';
import SaaS from 'eCarra/classes/SaaS.js';
import Screen from 'eCarra/files/Screen.js';
import Sheet from 'eCarra/views/Sheet.js';
import Sidebar from 'eCarra/structure/Sidebar.js';
import SocketHelper from 'eCarra/files/SocketHelper.js';
import SocketManager from 'eCarra/files/SocketManager.js';
import Stripe from 'eCarra/files/Stripe/';
import TextField from 'eCarra/views/TextField.js';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import User from 'eCarra/classes/User.js';
import Utils, { NoDataFound } from 'eCarra/files/Utils.js';
import WebView from 'eCarra/views/WebView/';
import WebStyles from 'eCarra/styles/WebStyles/';

const contentManager = ContentManager.new();
const bluetoothManager = new BluetoothManager();
const eventEmitter = new EventEmitter();
const locationManager = new LocationManager();
const socketManager = new SocketManager();

const App = () => {

    const driverVehicle = useRef(null);
    const flatList = useRef(null);
    const userRef = useRef(null);

    const [alerts, setAlerts] = useState([]);
    const [animations, setAnimations] = useState({
        container: {
            top: new Animated.Value(-50),
            opacity: new Animated.Value(0)
        },
        layers: {
            dim: new Animated.Value(0)
        },
        messaging: {
            right: new Animated.Value(-Screen.sidebar.maxWidth)
        },
        navigation: {
            state: 'hide'
        },
        sidebar: {
            left: new Animated.Value(-Screen.sidebar.maxWidth)
        }
    });
    const [client, setClient] = useState(null);
    const [content, setContent] = useState(null);
    const [datePicker, setDatePicker] = useState(null);
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const [loader, setLoader] = useState(null);
    const [nonce, setNonce] = useState(moment().unix());
    const [notification, setNotification] = useState(null);
    const [onDemand, setOnDemand] = useState({ new: null, searching: null });
    const [permissionsRequest, setPermissionsRequest] = useState(null);
    const [qrCamera, setQRCamera] = useState(null);
    const [quickFeedback, setQuickFeedback] = useState(null);
    const [sheet, setSheet] = useState(null);
    const [size, setSize] = useState({ width: Screen.width(), height: Screen.height() })
    const [structure, setStructure] = useState({
        navigation: {
            subscribers: [],
            scrollTo: {
                offset: {
                    x: 0,
                    y: 0
                },
            }
        }
    })
    const [user, setUser] = useState(null);
    const [validating, setValidating] = useState(true);
    const [workspace, setWorkspace] = useState({
        layers: [],
        layerIndex: [],
        bottomLayers: [],
        floatingLayers: [],
        panels: [],
        active: {
            view: null,
            subView: null
        }
    })

    const utils = {
        alert: {
            show: props => {
                let id = `${moment().unix()}-${Math.random()}`;
                setAlerts(alerts => {
                    if(alerts.find(alert => alert.message === props.message)) {
                        return alerts;
                    }
                    return alerts.concat([{
                        id: id,
                        ...props
                    }])
                });
                return id;
            },
            hide: id => {
                setAlerts(alerts => {
                    return alerts.filter(alert => id !== alert.id)
                })
            },
            discard: callback => {
                setAlerts(alerts => {
                    return alerts.concat([{
                        id: `${moment().unix()}-${Math.random()}`,
                        title: 'Discard Changes',
                        message: 'Are you sure that you want to discard your changes? This can not be undone',
                        buttons: [{
                            key: 'discard',
                            title: 'Discard',
                            style: 'destructive'
                        },{
                            key: 'cancel',
                            title: 'Do Not Discard',
                            style: 'default'
                        }],
                        onPress: (key) => {
                            if(key === 'discard') {
                                utils.alert.show({
                                    title: 'Discarded',
                                    message: 'You changes have been discarded'
                                });
                                callback();
                            }
                        }
                    }]);
                });
            }
        },
        api: {
            contentType: () => 'application/json',
            client_id: () => `ID ${API.get_client_id()}`,
            timezone: () => `TZ ${RNLocalize.getTimeZone()}`,
            identification: () => userRef.current ? `User ${userRef.current.user_id}` : null,
            authorization: () => userRef.current ? `Bearer ${userRef.current.api_key}` : null,
            headers: () => {
                return {
                    'Content-Type': 'application/json',
                    'Identification': userRef.current ? `User ${userRef.current.user_id}` : null,
                    'Authorization': userRef.current ? `Bearer ${userRef.current.api_key}` : null,
                    'X-API': `Version ${API.version}`,
                    'X-Client': `ID ${API.get_client_id()}`,
                    'X-Timezone': `TZ ${RNLocalize.getTimeZone()}`
                }
            }
        },
        bluetooth: {
            get: () => bluetoothManager
        },
        client: {
            get: () => ({
                id: client ? client.id : 'ecarra',
                name: client ? client.name : 'eCarra',
                tagline: client ? client.tagline : 'Rides that Matter',
                email_address: client ? client.email_address : 'ridesthatmatter@ecarra.com',
                phone_number: client ? client.phone_number : '(972) 885-7884',
                privacyPolicy: client ? client.privacy : 'https://www.ecarra.com/privacy',
                colors: client ? client.colors : {},
                logos: client ? client.logos : {
                    light: require('eCarra/images/logo-light.png'),
                    dark: require('eCarra/images/logo-dark.png'),
                    icon: require('eCarra/images/icon-logo.png'),
                    mobile: require('eCarra/images/mobile.png')
                },
                facebook: client ? client.facebook : 'https://www.facebook.com/ridesthatmatter/',
                twitter: client ? client.twitter : 'https://twitter.com/ridesthatmatter',
                instagram: client ? client.instagram : 'https://www.instagram.com/ecarra.app/?hl=en',
                apps: client ? client.apps : {
                    bundle: {
                        ios: 'com.ecarra.ios',
                        android: 'com.ecarra.android',
                        web: 'com.ecarra.web'
                    },
                    ios: 'https://apps.apple.com/us/app/ecarra/id1446475106',
                    android: 'https://play.google.com/store/apps/details?id=com.ecarra.android&hl=en_US&gl=US',
                    facebook: '1110852945761394',
                    google: {
                        ios: '546052153171-koh7id9ntrdfp4toi551ptk0b42j9av9.apps.googleusercontent.com',
                        web: '546052153171-4b4hpunfkd5ntfrg7q87n1qrvg2a82od.apps.googleusercontent.com'
                    }
                }
            })
        },
        content: contentManager,
        datePicker: {
            show: props => setDatePicker({
                id: `${moment().unix()}-${Math.random()}`,
                ...props
            }),
            showDualPicker: props => {
                utils.layer.open({
                    id: 'dual-date-picker-alert',
                    Component: DualDatePicker.bind(this, props)
                })
            }
        },
        driver: {
            vehicle: {
                get: () => driverVehicle.current,
                set: vehicle => {
                    if(driverVehicle.current) {
                        utils.sockets.on('vehicles', `on_remove_driver_${driverVehicle.current.id}`, onRemoveDriver);
                    }
                    driverVehicle.current = vehicle;
                    onSetDriverVehicle();
                },
                return: () => {
                    driverVehicle.current = null;
                    onSetDriverVehicle();
                },
                targets: {
                    add: props => {
                        if(driverVehicle.current) {
                            driverVehicle.current = {
                                ...driverVehicle.current,
                                target: {
                                    ...driverVehicle.target,
                                    ...props
                                }
                            }
                        }
                    },
                    remove: key => {
                        if(driverVehicle.current) {
                            driverVehicle.current = {
                                ...driverVehicle.current,
                                target: {
                                    ...driverVehicle.target,
                                    [key]: null
                                }
                            }
                        }
                    }
                }
            },
        },
        events: eventEmitter,
        layer: {
            close: layerID => onCloseLayer(layerID),
            closeFloating: layerID => onCloseFloatingLayer(layerID),
            download: props => downloadContent(props),
            isFloatingLayerOpen: () => {
                return workspace.floatingLayers.reduce((total, layer) => {
                    total += layer.visible === false ? 0 : 1;
                    return total;
                }, 0) > 0;
            },
            messaging: abstract => {
                utils.sockets.emit('messages', 'join', {
                    id: abstract.getID(),
                    type: abstract.type
                });
                onOpenFloatingLayer({
                    id: `messages-${abstract.getTag()}`,
                    abstract: abstract,
                    Component: Messaging
                })
            },
            notes: object => notesManager(object),
            open: layer => onOpenLayer(layer),
            openFloating: layer => onOpenFloatingLayer(layer),
            order: {
                details: (order, callback) => orderDetails(order, callback)
            },
            orderHost: {
                details: (host, callback) => orderHostDetails(host, callback)
            },
            payment: {
                details: (payment, callback) => paymentDetails(payment, callback)
            },
            reservation: {
                details: (reservation, props) => reservationDetails(reservation, props)
            },
            route: {
                details: (route, props) => routeDetails(route, props)
            },
            subscription: {
                details: subscription => subscriptionDetails(subscription)
            },
            update: (layerID, props) => onUpdateLayer(layerID, props),
            webView: props => {
                if(Platform.OS === 'web' && props.overrideFrame === true) {
                    window.open(props.url);
                    return;
                }
                onOpenLayer({
                    id: props.id,
                    Component: WebView.bind(this, props)
                })
            }
        },
        loader: {
            show: () => {
                setLoader({ visible: true });
            },
            hide: callback => {
                setLoader({
                    visible: false,
                    onComplete: callback
                });
            }
        },
        keyboard: {
            subscribe: (id, callbacks) => KeyboardManager.subscribe(id, callbacks),
            unsubscribe: id => KeyboardManager.unsubscribe(id)
        },
        location: locationManager,
        notification: {
            show: (props, callback) => {
                ContentManager.fetch('notifications');
                setNotification(props);
            }
        },
        on_demand: {
            searching: {
                set: props => {
                    setOnDemand(prev => update(prev, {
                        searching: {
                            $set: props
                        }
                    }))
                }
            }
        },
        permissions: {
            request: props => {
                setPermissionsRequest(props);
            }
        },
        qrCamera: {
            show: props => setQRCamera(props)
        },
        quickFeedback: {
            show: props => {
                setQuickFeedback({
                    visible: true,
                    ...props
                });
            }
        },
        sheet: {
            show: (sheet, callback) => {
                setSheet({
                    ...sheet,
                    onPress: callback
                });
            }
        },
        sockets: socketManager,
        structure: {
            navigation: {
                state: () => animations.navigation.state,
                open: () => {
                    setAnimations(animations => update(animations, {
                        navigation: {
                            left: {
                                $set: 0
                            }
                        }
                    }))
                },
                scrollTo: offset =>  {
                    setStructure(structure => update(structure, {
                        navigation: {
                            scrollTo: {
                                offset: {
                                    $set: offset
                                }
                            }
                        }
                    }));
                },
                subscribe: (id, callbacks) => {
                    structure.navigation.subscribers = {
                        ...structure.navigation.subscribers,
                        ...{
                            [id]: {
                                id: id,
                                callbacks: callbacks
                            }
                        }
                    }
                },
                unsubscribe: id => {
                    delete structure.navigation.subscribers[id];
                },
                set: props => {
                    setAnimations(animations => update(animations, {
                        navigation: {
                            props: {
                                $set: props
                            }
                        }
                    }));
                },
                reset: callback => {
                    setAnimations(animations => update(animations, {
                        navigation: {
                            state: {
                                $set: 'reset'
                            },
                            props: {
                                $set: null
                            }
                        }
                    }));
                    if(typeof(callback) === 'function') {
                        setTimeout(callback, 250);
                    }
                },
                show: callback => {
                    setAnimations(animations => update(animations, {
                        navigation: {
                            state: {
                                $set: 'show'
                            }
                        }
                    }));
                    if(typeof(callback) === 'function') {
                        setTimeout(callback, 250);
                    }
                },
                showAsync: async () => {
                    return new Promise(resolve => {
                        utils.structure.navigation.show(resolve);
                    });
                },
                hide: callback => {
                    setAnimations(animations => update(animations, {
                        navigation: {
                            state: {
                                $set: 'hide'
                            }
                        }
                    }));
                    if(typeof(callback) === 'function') {
                        setTimeout(callback, 250);
                    }
                }
            },
            panels: {
                get: () => content
            },
            workspace: {
                set: view => setWorkspace(workspace => update(workspace, {
                    active: {
                        view: {
                            $set: view
                        }
                    }
                })),
                activeView: () => workspace && workspace.active ? workspace.active.view : null,
                scrollTo: (view, panelID) => {
                    if(!flatList.current) {
                        console.log('no ref');
                        return;
                    }
                    let index = content[view] && content[view].panels ? content[view].panels.findIndex(panel => {
                        return panel.id === panelID
                    }) : -1;

                    if(index >= 0) {
                        flatList.current.scrollToIndex({
                            animated: true,
                            index: index,
                            viewOffset: 20,
                            viewPosition: 0.5
                        });
                    }
                }
            }
        },
        user: {
            get: () => userRef.current,
            set: props => {
                setUser(user => {
                    user.close(props);
                    return user
                })
            }
        }
    }

    const orderDetails = async (order, props) => {
        try {
            onOpenLayer({
                id: order.id ? `order-${order.id}-${order.host.channel.id}` : `new-order-${order.host.channel.id}`,
                abstract: Abstract.create({
                    type: 'orders',
                    object: order
                }),
                Component: OrderDetails.bind(this, {
                    isNewTarget: order.id ? false : true,
                    channel: order.host.channel,
                    ...props
                })
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing the details for this order. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const orderHostDetails = async (host, callback) => {
        try {

            // fetch most recent location
            let location = await utils.location.get();

            // build order object and set default variables
            let order = Order.new();
            order.host = host;
            order.channel = channel;
            order.customer = utils.user.get();
            order.service = host.service;
            order.drop_off_date = moment().add(host.service.minBookingTime || 0, 'seconds');
            order.origin = host.getClosestLocation(utils);
            order.destination = location && {
                name: location.name,
                location: location
            }

            // open order details layer
            utils.layer.open({
                id: `order-host-details-${host.id}`,
                Component: OrderHostDetails.bind(this, {
                    host: host,
                    abstract: Abstract.create({
                        type: 'orders',
                        object: order
                    })
                })
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up your ${host.channel.name} order for ${host.name}. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const paymentDetails = payment => {
        onOpenLayer({
            id: `payment-${payment.id}`,
            abstract: Abstract.create({
                type: 'payments',
                object: payment
            }),
            Component: PaymentDetails
        });
    }

    const reservationDetails = async (reservation, props) => {
        onOpenLayer({
            id: reservation.id ? `reservation-${reservation.id}` : 'new-reservation',
            abstract: Abstract.create({
                type: 'reservations',
                object: reservation
            }),
            Component: ReservationDetails.bind(this, {
                isNewTarget: reservation.id ? false : true,
                ...props
            })
        });
    }

    const routeDetails = async (route, props) => {
        onOpenLayer({
            id: `route-${route.id}`,
            abstract: Abstract.create({
                type: 'routes',
                object: route
            }),
            Component: RouteDetails.bind(this, {
                ...props
            })
        });
    }

    const subscriptionDetails = subscription => {

        let abstract = Abstract.create({
            type: 'subscriptions',
            object: subscription
        });
        utils.layer.open({
            id: `subscription-${abstract.getID()}`,
            abstract: abstract,
            Component: SubscriptionDetails
        });
    }

    const isSaaSValid = () => {
        if(client && client.active !== true) {
            return (
                <View style={{
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: Appearance.colors.background(),
                    overflow: 'hidden'
                }}>
                    <Image source={Appearance.themeStyle() === 'dark' ? require('eCarra/images/saas-invalid-dark.png') : require('eCarra/images/saas-invalid-light.png')}
                    style={{
                        width: 125,
                        height: 125,
                        resizeMode: 'contain'
                    }}/>
                    <View style={{
                        paddingHorizontal: 30
                    }}>
                        <Text style={{
                            ...Appearance.textStyles.title(),
                            textAlign: 'center',
                            marginBottom: 4
                        }}>{'Pardon the Interuption'}</Text>
                        <Text style={{
                            ...Appearance.textStyles.subTitle(),
                            textAlign: 'center'
                        }}>{`There was an issue verifying the subscription for this SaaS client. Please contact ${utils.client.get().name} for more information.`}</Text>
                    </View>
                </View>
            )
        }
    }

    const onCloseLayer = layerID => {

        let updatedWorkspace = update(workspace, {
            layers: {
                $apply: layers => layers.map(l => {
                    if(l.id === layerID) {
                        l.visible = false;
                    }
                    return l;
                })
            },
            layerIndex: {
                $apply: ids => ids.filter(id => id !== layerID)
            }
        });
        if(updatedWorkspace.layers.filter(l => l.visible !== false).length > 0) {
            setWorkspace(updatedWorkspace);
            return;
        }
        Animated.timing(animations.layers.dim, {
            toValue: 0,
            duration: 250,
            useNativeDriver: false
        }).start(() => {
            setWorkspace(workspace => update(workspace, {
                layers: {
                    $set: []
                }
            }))
            console.log('layers reset');
        })
    }

    const onCloseFloatingLayer = layerID => {

        let index = workspace.floatingLayers.findIndex(l => {
            return l.id === layerID && l.visible !== false; // filter by visible so hidden layers are not closed a second time
        });
        if(index < 0) {
            return
        }

        let updatedWorkspace = update(workspace, {
            floatingLayers: {
                [index]: {
                    visible: {
                        $set: false
                    }
                }
            }
        });
        if(updatedWorkspace.floatingLayers.filter(l => l.visible !== false).length > 0) {
            setWorkspace(updatedWorkspace);
            return;
        }
        // Reset layers if none are visible
        setWorkspace(workspace => update(workspace, {
            floatingLayers: {
                $set: []
            }
        }))
        console.log('floating layers reset');
    }

    const onCheckProfileStatus = () => {
        let user = userRef.current;
        if(!user) {
            return;
        }
        if(!user.phone_number || !user.gender || !user.address) {
            setNotification({
                title: 'Continue with Account Setup',
                message: `It looks like there's some information missing from your account. Tap to complete your account setup.`,
                onPress: () => {
                    utils.structure.workspace.set('settings');
                }
            })
        }
    }

    const onFetchFirstLocation = async () => {
        try {
            let { foreground } = await utils.location.check();
            if(foreground === false){
                return;
            }
            await utils.location.get();
        } catch(e) {
            console.error(e.message);
        }
    }

    const onLogout = () => {
        utils.alert.show({
            title: 'Logout',
            message: 'Are you sure that you want to logout of your account?',
            buttons: [{
                key: 'logout',
                title: 'Logout',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Logout',
                style: 'default'
            }],
            onPress: key => {
                if(key == 'logout') {
                    onRunLogout();
                    return;
                }
            }
        })
    }

    const onMessagingClosed = () => {
        Animated.spring(animations.messaging.right, {
            toValue: -Screen.sidebar.maxWidth,
            duration: 500,
            friction: 10,
            useNativeDriver: false
        }).start();
    }

    const onNavigationSearch = text => {
        Object.values(structure.navigation.subscribers).forEach(subscriber => {
            if(subscriber.callbacks.onSearch && typeof(subscriber.callbacks.onSearch) === 'function') {
                subscriber.callbacks.onSearch(text);
            }
        });
    }

    const onCloseSidebar = () => {
        Animated.spring(animations.sidebar.left, {
            toValue: -Screen.sidebar.maxWidth,
            duration: 250,
            friction: 10,
            useNativeDriver: false
        }).start();
    }

    const onNavigationSelected = async props => {
        try {
            // open webview from supplied url if applicable
            if(props.url) {
                utils.layer.webView(props);
                return;
            }
            // animate sidebar out of view
            if(Utils.isMobile() === true) {
                onCloseSidebar();
            }

            // do not change if supplied props match navigation state
            if(props.view === workspace.active.view) {
                if(!props.subView || (props.subView == workspace.active.subView)) {
                    return;
                }
            }
            // animate panels out of view
            Animated.timing(animations.container.top, {
                toValue: -50,
                duration: 500,
                useNativeDriver: false
            }).start();
            await Utils.animateAsync.timing(animations.container.opacity, {
                toValue: 0,
                duration: 500,
                useNativeDriver: false
            })
            // update workspace with new panels
            setWorkspace(workspace => update(workspace, {
                active: {
                    $set: props
                }
            }));

        } catch(e) {
            console.error(e.message);
        }
    }

    const onOpenLayer = layer => {

        // Prevent duplicate standard layer
        if(workspace.layers.find(prevLayer => {
            return prevLayer.id === layer.id && prevLayer.visible !== false;
        })) {
            if(layer.abstract) {
                utils.alert.show({
                    title: Utils.ucFirst(layer.abstract.getTitle()),
                    message: `${layer.abstract.getTitle()} is already open`
                })
            }
            return;
        }

        setTimeout(() => {
            setWorkspace(workspace => {
                let newLayers = update(workspace, {
                    layers: {
                        $push: [layer]
                    },
                    layerIndex: {
                        $unshift: [layer.id]
                    }
                });
                if(newLayers.layers.filter(l => {
                    return l.visible !== false && l.showDimView !== false;
                }).length === 1) {
                    Animated.timing(animations.layers.dim, {
                        toValue: 1,
                        duration: 500,
                        useNativeDriver: false
                    }).start()
                }
                return newLayers ;
            });
        });
    }

    const onOpenFloatingLayer = layer => {

        layer.floating = true;
        layer.Component.layerID = layer.id; // used to identify layer when scrolling and removing

        if(workspace.floatingLayers.find(prevLayer => prevLayer.id === layer.id && prevLayer.visible !== false)) {
            utils.events.emit('onFloatingLayerEvent', {
                layerID: `${layer.id}-layer`,
                event: 'scrollTo',
                scrollToIndex: workspace.floatingLayers.findIndex(prevLayer => prevLayer.id === layer.id)
            })
            return;
        }

        setTimeout(() => {
            setWorkspace(workspace => update(workspace, {
                floatingLayers: {
                    $push: [layer]
                }
            }));
        });
    }

    const onParseNotificationPayload = async remote_notification => {
        try {
            // declare payload variables
            let { data, foreground } = remote_notification || {};
            if(!remote_notification || foreground === true) {
                return;
            }

            // define notification payload
            let target = data || remote_notification._data;
            let payload = target.user_info || target.user_info || {};

            // decode payload if applicable (android firebase)
            if(typeof(payload) === 'string') {
                payload = JSON.parse(payload);
            }

            // show on-demand ride prompt if applicable
            if(payload.category === 'NEW_ON_DEMAND_RIDE') {
                setOnDemand(props => update(props, {
                    new: {
                        $set: payload
                    }
                }));
                return;
            }

            // build notification object
            if(!payload.title || !payload.message) {
                return;
            }

            // show actions alert for notification
            let notification = Notification.create(payload);
            notification.contentOptions(utils, 'alert');

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: e.message
            });
        }
    }

    const onRevealContainer = async () => {
        try {
            // show loader if view is transitioning to ride booking
            if(workspace.active.view === 'bookRide') {
                utils.loader.show();
                await Utils.sleep(0.25);
            }

            // show or hide sidebar and messaging sidebar based on selected view for non-mobile devices
            if(Utils.isMobile() === false) {
                Animated.spring(animations.sidebar.left, {
                    toValue: workspace.active.view === 'bookRide' ? -Screen.sidebar.maxWidth : 0,
                    duration: 250,
                    friction: 10,
                    useNativeDriver: false
                }).start();
                Animated.spring(animations.messaging.right, {
                    toValue: workspace.active.view === 'bookRide' ? -Screen.sidebar.maxWidth : 0,
                    duration: 250,
                    friction: 10,
                    useNativeDriver: false
                }).start();
            }

            // animate container
            Animated.timing(animations.container.top, {
                toValue: 0,
                duration: 500,
                useNativeDriver: false
            }).start();
            Animated.timing(animations.container.opacity, {
                toValue: 1,
                duration: 500,
                useNativeDriver: false
            }).start();

        } catch(e) {
            console.error(e.message);
        }
    }

    const onRemoveDriver = data => {
        try {
            if(!driverVehicle.current || data.vehicle_id !== driverVehicle.current.id) {
                return;
            }
            utils.driver.vehicle.return();
        } catch(e) {
            console.error(e.message);
        }
    }

    const onRenderContent = () => {
        if(!workspace.panels || workspace.panels.length === 0) {
            return (
                <View style={{
                    flex: 1,
                    width: '100%',
                    height: '100%'
                }} />
            );
        }
        return (
            <View style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <Animated.View style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: Appearance.colors.background(),
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                    ...animations.container,
                    ...Platform.OS === 'web' && {
                        width: '100%',
                        height: '100vh'
                    },
                    ...(Platform.OS === 'web' || Utils.isMobile() === false) && {
                        alignItems: 'center'
                    }
                }}>
                    <View style={{
                        width: '100%',
                        flexGrow: 1,
                        ...Utils.isMobile() === false && {
                            paddingLeft: workspace.active.view === 'bookRide' ? 0 : Screen.sidebar.maxWidth,
                        },
                        ...Platform.OS === 'web' && {
                            maxWidth: workspace.active.view === 'bookRide' ? '100%' : (Screen.panel.maxWidth() + Screen.sidebar.maxWidth),
                        }
                    }}>
                        {getPanelComponents()}
                        {getNavigationComponents()}
                    </View>
                </Animated.View>
                {getSidebarComponents()}
                {getMessagingComponents()}
                {getLayerComponents()}
                {getInteractiveComponents()}
            </View>
        )
    }

    const onRequestWebLocation = () => {

        utils.alert.show({
            title: 'Location Services',
            message: `${utils.client.get().name} uses your location to connect you with nearby drivers and suggest nearby points of interest. We do not share your location with any third parties or advertisers.`,
            buttons: [{
                key: 'approve',
                title: 'Use My Location',
                style: 'default'
            },{
                key: 'decline',
                title: 'No Thanks',
                style: 'destructive'
            }],
            onClick: async key => {
                if(key === 'approve') {
                    utils.location.start();
                    return;
                }
            }
        })
    }

    const onRunLogout = async () => {
        try {

            await AsyncStorage.removeItem(`${utils.client.get().id}_login`);
            utils.structure.navigation.hide();

            Animated.timing(animations.sidebar.left, {
                toValue: -Screen.sidebar.maxWidth,
                duration: 250,
                useNativeDriver: false
            }).start();
            Animated.timing(animations.messaging.right, {
                toValue: -Screen.sidebar.maxWidth,
                duration: 250,
                useNativeDriver: false
            }).start();
            Animated.timing(animations.container.top, {
                toValue: -50,
                duration: 500,
                useNativeDriver: false
            }).start();
            Animated.timing(animations.container.opacity, {
                toValue: 0,
                delay: 150,
                duration: 500,
                useNativeDriver: false
            }).start(() => {

                setUser(null);
                setAnimations(animations => update(animations, {
                    navigation: {
                        state: {
                            $set: 'hide'
                        }
                    }
                }))
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `We ran into an issue while trying to log out of your account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSetDriverVehicle = () => {

        // setup listener for driver vehicle changes
        utils.events.emit('on_driver_vehicle_change', { vehicle: driverVehicle.current });

        // prevent moving forward is user is not a driver or no vehicle is present
        if(!userRef.current || userRef.current.level > User.level.driver || !driverVehicle.current) {
            utils.location.stop();
            return;
        }

        // setup seeds driven vehicle management
        utils.sockets.on('vehicles', `on_remove_driver_${driverVehicle.current.id}`, onRemoveDriver);

        // setup listener for driver location changes
        utils.location.subscribe('root', {
            onUpdateMany: async locations => {
                try {
                    await utils.sockets.emit('vehicles', 'active_update', {
                        locations: locations,
                        user_id: userRef.current.user_id,
                        ...driverVehicle.current && {
                            vehicle_id: driverVehicle.current.id,
                            ...driverVehicle.current.order_id && {
                                target: {
                                    type: 'orders',
                                    id: driverVehicle.current.order_id
                                }
                            },
                            ...driverVehicle.current.reservation_id && {
                                target: {
                                    type: 'reservations',
                                    id: driverVehicle.current.reservation_id
                                }
                            }
                        }
                    })
                } catch(e) {
                    console.error(e.message);
                }
            }
        });
    }

    const onSetupContent = async () => {
        if(!content) {
            return;
        }
        try {
            // bi-monthly feedback reminder
            let date = await AsyncStorage.getItem('lastFeedbackReminder');
            if(!date) {
                await AsyncStorage.setItem('lastFeedbackReminder', moment().format('YYYY-MM-DD HH:mm:ss'));

            } else if(moment(date).add(2, 'weeks') < moment()) {
                setTimeout(utils.quickFeedback.show, 2500);
                await AsyncStorage.setItem('lastFeedbackReminder', moment().format('YYYY-MM-DD HH:mm:ss'))
            }

            // set route and panels
            setWorkspace(workspace => update(workspace, {
                panels: {
                    $set: content
                },
                active: {
                    view: {
                        $set: 'bookRide'
                    },
                    subView: {
                        $set: null
                    }
                }
            }));

            // animate container into view
            Animated.timing(animations.container.opacity, {
                toValue: 1,
                duration: 1000,
                useNativeDriver: false
            }).start();
            Animated.timing(animations.container.top, {
                toValue: 0,
                duration: 500,
                useNativeDriver: false
            }).start();

            utils.events.addListener('onLoadComplete', () => {
                onShowSideContainers();
            });

        } catch(e) {
            console.error(e.message);
        }
    }

    const onSetupUser = async user => {

        try {
            // show loader
            utils.loader.show();

            // set user props
            userRef.current = user;
            setUser(user);

            // fetch application content
            let content = await getContent(utils);
            setContent(content);

            // setup root subscriber for user updates
            utils.content.subscribe('root', 'users', {
                onUpdate: abstract => {
                    if(abstract.getID() === user.user_id) {
                        setUser(abstract.object);
                    }
                }
            })

            // setup push notifications if applicable
            await Utils.sleep(1);
            setupPushNotifications();

            // check if profile has missing pieces of data
            await Utils.sleep(3);
            onCheckProfileStatus();

        } catch(e) {
            utils.loader.hide();
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the application. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSetupUserListeners = async () => {

        if(!user) {
            console.warn('waiting for user...');
            return;
        }
        try {
            // connect to sockets and setup root subscription
            await socketManager.connect(utils, user);
            socketManager.subscribe('root', getSocketCallbacks());
            if(user.level > User.level.driver) {
                return;
            }

            // fetch driver vehicle and verify mileage request
            let { vehicle, mileageRequest } = await fetchDriverVehicle(utils);
            driverVehicle.current = vehicle;
            if(mileageRequest) {
                await driverVehicleMileageRequest(vehicle, utils);
            }

        } catch(e) {
            console.error(e.message);
        }
    }

    const onShowSideContainers = async () => {
        try {
            // prevent automatically showing sidebars on devices that are in portrait orientation
            if(Platform.OS === 'web' && window.innerWidth < 1500) {
                return;
            }

            // animate navigation and messaging into view if platform is web
            await Utils.sleep(0.5);
            if(Platform.OS === 'web') {
                Animated.spring(animations.sidebar.left, {
                    toValue: 0,
                    duration: 250,
                    friction: 10,
                    useNativeDriver: false
                }).startleft
                Animated.spring(animations.messaging.right, {
                    toValue: 0,
                    duration: 250,
                    friction: 10,
                    useNativeDriver: false
                }).start();
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    const onToggleNavigationPress = () => {
        Animated.spring(animations.sidebar.left, {
            toValue: animations.sidebar.left._value === 0 ? -Screen.sidebar.maxWidth : 0,
            duration: 250,
            friction: 10,
            useNativeDriver: false
        }).start();
    }

    const onUpdateLayer = (layerID, props) => {

        let index = workspace.layers.findIndex(prevLayer => prevLayer.id === layerID);
        if(index >= 0) {
            let newLayers = update(workspace, {
                layers: {
                    [index]: {
                        $set: {
                            ...workspace.layers[index],
                            ...props
                        }
                    }
                }
            });
            setWorkspace(newLayers);
        }
    }

    const onWindowSizeChange = () => {
        setSize({
            width: window.innerWidth,
            height: window.innerHeight
        })
    }

    const getContentWithValidation = () => {
        if(validating) {
            return null;
        }
        if(client && client.active !== true) {
            return (
                <View style={{
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: Appearance.colors.background(),
                    overflow: 'hidden'
                }}>
                    <Image
                    source={Appearance.themeStyle() === 'dark' ? require('eCarra/images/saas-invalid-dark.png') : require('eCarra/images/saas-invalid-light.png')}
                    style={{
                        width: 125,
                        height: 125,
                        resizeMode: 'contain'
                    }}/>
                    <View style={{
                        paddingHorizontal: 30
                    }}>
                        <Text style={{
                            ...Appearance.textStyles.title(),
                            textAlign: 'center',
                            marginBottom: 4
                        }}>{'Pardon the Interuption'}</Text>
                        <Text style={{
                            ...Appearance.textStyles.subTitle(),
                            textAlign: 'center'
                        }}>{`There was an issue verifying the subscription for this SaaS client. Please contact ${utils.client.get().name} for more information.`}</Text>
                    </View>
                </View>
            )
        }

        return (
            <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
                <View style={{
                    width: '100%',
                    height: '100%'
                }}>
                    <Animated.View style={{
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%'
                    }}>
                        <LoginCard
                        utils={utils}
                        onLogin={onSetupUser} />

                        {/* layers */}
                        {workspace.layers.filter(l => {
                            return l.visible !== false;
                        }).length > 0 && (
                            <Animated.View style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                opacity: animations.layers.dim,
                                backgroundColor: Appearance.colors.dim
                            }}/>
                        )}

                        {/* standard layers */}
                        {workspace.layers.filter(l => {
                            return l.visible !== false;
                        }).map((Layer, index, layers) => {
                            return (
                                <Layer.Component
                                key={index}
                                utils={utils}
                                index={index}
                                abstract={Layer.abstract}
                                options={{
                                    ...Layer.options,
                                    index: index,
                                    order: (layers.length - 1) - index,
                                    zIndex: (LayerEndIndex - workspace.layerIndex.findIndex(id => id === Layer.id)),
                                    onClose: onCloseLayer
                                }}/>
                            )
                        })}
                    </Animated.View>
                </View>
            </TouchableWithoutFeedback>
        )
    }

    const getInteractiveComponents = () => {
        return (
            <>
            {quickFeedback && (
                <QuickFeedback
                {...quickFeedback}
                utils={utils}
                onClose={() => setQuickFeedback(null)} />
            )}
            {sheet && (
                <Sheet
                {...sheet}
                onClose={() => {
                    setSheet(null);
                    if(typeof(sheet.onClose) === 'function') {
                        sheet.onClose();
                    }
                }} />
            )}
            {onDemand && onDemand.new && (
                <OnDemandNew
                {...onDemand.new}
                utils={utils}
                onClose={() => {
                    setOnDemand(props => update(props, {
                        new: {
                            $set: null
                        }
                    }))
                }}/>
            )}
            {onDemand && onDemand.searching && (
                <OnDemandSearching
                {...onDemand.searching}
                utils={utils}
                onClose={() => {
                    setOnDemand(props => update(props, {
                        searching: {
                            $set: null
                        }
                    }))
                }}/>
            )}
            {notification && (
                <MobileNotification
                {...notification}
                utils={utils}
                onClose={() => setNotification(null)} />
            )}
            </>
        )
    }

    const getLayerComponents = () => {
        return (
            <>
            {/* layers dim background */}
            {workspace.layers.filter(l => {
                return l.visible !== false;
            }).length > 0 && (
                <Animated.View style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    opacity: animations.layers.dim,
                    backgroundColor: Appearance.colors.dim
                }}/>
            )}

            {/* standard layers */}
            {workspace.layers.filter(l => {
                return l.visible !== false;
            }).map((Layer, index, layers) => {

                return (
                    <Layer.Component
                    key={index}
                    utils={utils}
                    index={index}
                    abstract={Layer.abstract}
                    options={{
                        ...Layer.options,
                        index: index,
                        order: (layers.length - 1) - index,
                        zIndex: LayerEndIndex - workspace.layerIndex.findIndex(id => id === Layer.id),
                        onClose: onCloseLayer
                    }}/>
                )
            })}

            {/* floating layers */}
            <FloatingLayers
            utils={utils}
            options={{
                removePadding: true,
                removeSafeArea: true
            }}>
                {workspace.floatingLayers.map((Layer, index, layers) => {
                    return (
                        <Layer.Component
                        key={index}
                        utils={utils}
                        index={index}
                        abstract={Layer.abstract}
                        options={{
                            ...Layer.options,
                            index: index,
                            visible: Layer.visible, // pass as prop instead of filtering so layers don't render on visiblity change
                            zIndex: (LayerEndIndex - workspace.layerIndex.findIndex(id => id === Layer.id)),
                            onClose: onCloseFloatingLayer
                        }}/>
                    )
                })}
            </FloatingLayers>

            {/* bottom card layers */}
            {workspace.bottomLayers.filter(l => {
                return l.visible !== false;
            }).map((Layer, index, layers) => {
                return (
                    <Layer.Component
                    key={index}
                    utils={utils}
                    index={index}
                    abstract={Layer.abstract}
                    options={{
                        ...Layer.options,
                        index: index,
                        zIndex: (LayerEndIndex - workspace.layerIndex.findIndex(id => id === Layer.id)),
                        onClose: onCloseLayer
                    }}/>
                )
            })}
            </>
        )
    }

    const getNavigationComponents = () => {
        return (
            <Navigation
            {...animations.navigation.props}
            utils={utils}
            active={workspace.active}
            state={animations.navigation.state}
            onSearch={onNavigationSearch}
            scrollTo={structure.navigation.scrollTo}
            onLeftPress={onToggleNavigationPress}/>
        )
    }

    const getPanels = () => {

        if(!workspace || !workspace.active || !workspace.active.view) {
            return null;
        }

        let { active, panels } = workspace;
        return !active.subView ? panels[active.view].panels : (panels[active.view].subViews ? panels[active.view].subViews[active.subView].panels : panels[active.view].panels);
    }

    const getPanelComponents = () => {

        // fetch panels for view and subview
        let panels = getPanels() || [];
        return (
            <View style={{
                flex: 1,
                display: 'flex',
                width: '100%',
                height: '100%',
                ...Platform.OS === 'web' && {
                    width: '100%',
                    height: '100vh'
                }
            }}>
                {/* fullscreen panels */}
                {panels.filter(p => {
                    return p.fullscreen === true
                }).map((item, index) => {
                    let Component = item.Component;
                    return (
                        <View
                        key={index}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0
                        }}>
                            <Component
                            key={item.key}
                            utils={utils}
                            index={index}/>
                        </View>
                    )
                })}

                {/* panels */}
                {panels.filter(p => {
                    return p.fullscreen !== true
                }).length > 0 && (
                    <FlatList
                    ref={flatList}
                    showsVerticalScrollIndicator={false}
                    data={panels.filter(p => p.fullscreen !== true)}
                    scrollEventThrottle={16}
                    contentContainerStyle={{
                        paddingTop: 20 + Screen.safeArea.top,
                        paddingBottom: Screen.height() / 2,
                        ...Platform.OS !== 'web' && Utils.isMobile() === true && {
                            paddingTop: NavigationHeight
                        }
                    }}
                    renderItem={({ item }, index) => {
                        let Component = item.Component;
                        return (
                            <Component
                            key={item.key}
                            utils={utils}
                            index={index}/>
                        )
                    }}
                    onScroll={({ nativeEvent }) => {
                        if(keyboardOpen === true) {
                            Keyboard.dismiss();
                        }
                        if(Platform.OS !== 'web' && Utils.isMobile() === true) {
                            if(animations.navigation.state === 'hide' && nativeEvent.contentOffset.y < 15) {
                                utils.structure.navigation.show();
                            }
                            if(animations.navigation.state !== 'hide' && nativeEvent.contentOffset.y > 15) {
                                utils.structure.navigation.hide();
                            }
                        }
                    }} />
                )}
            </View>
        )
    }

    const getSidebarComponents = style => {
        return (
            <Sidebar
            utils={utils}
            animate={animations.sidebar.left}
            content={workspace.panels}
            user={user}
            workspace={workspace}
            activeView={workspace.active}
            onNavigationPress={onNavigationSelected}
            onLogout={onLogout}
            style={style}
            onClose={onCloseSidebar} />
        )
    }

    const getMessagingComponents = () => {
        return null;
        /*
        if(Platform.OS === 'web' || Utils.isMobile() == false) {
            return (
                <MessagingSidebar
                utils={utils}
                activeView={workspace.active}
                animate={animations.messaging.right}
                onClose={onMessagingClosed} />
            )
        }
        return null;
        */
    }

    const getSocketCallbacks = () => {
        return {
            on_notification: notification => {
                try {
                    utils.content.fetch('notifications');
                    if(API.dev_env === true) {
                        console.log(notification);
                    }

                    // update thread listeners with new message content
                    if(notification.user_info && notification.user_info.thread) {
                        let abstract = Abstract.create({
                            type: 'messageThreads',
                            object: Message.Thread.create(notification.user_info.thread)
                        });
                        utils.content.update(abstract);
                    }

                    // prevent notifications for message threads that are already open
                    let layers = workspace.floatingLayers.filter(l => l.visible !== false);
                    for(var i in layers) {

                        let layer = layers[i];
                        if(layer.id.includes('messages')) {

                            // prevent notification for message threads that are front and center
                            if(notification.user_info && layer.abstract.getTag() === buildTag(notification.user_info.abstract)) {

                                // show notification if layer is not front and center
                                utils.events.addListener('onFloatingLayerStatus', ({ visible }) => {
                                    if(!visible) {
                                        setNotification(notification.contentOptions(utils, 'notification'))
                                    }
                                });
                                utils.events.emit('getFloatingLayerStatus', {
                                    index: parseInt(i),
                                    layerID: `${layer.id}-layer`
                                })
                                return;
                            }
                        }
                    }
                    setNotification({
                        ...notification,
                        onPress: notification.contentOptions.bind(this, utils, 'alert')
                    });

                } catch(e) {
                    console.error(e.message);
                }
            },
            on_new_ride: async ({ type, data }) => {
                if(Platform.OS === 'web') {
                    console.warn('on demand rides not enabled for web');
                    return;
                }
                if(type === 'on_demand') {
                    setOnDemand(props => update(props, {
                        new: {
                            $set: data
                        }
                    }));
                    return;
                }

                let title = '';
                let message = '';
                let icon = false;
                let buttons = [{
                    key: 'cancel',
                    title: 'Dismiss',
                    style: 'cancel'
                }];

                try {
                    let { order, route, reservation } = await Utils.createMultiple(utils, {
                        order_id: data.order_id,
                        route_id: data.route_id,
                        reservation_id: data.reservation_id
                    });

                    switch(type) {
                        case 'orders':
                        if(!order || order.customer.user_id == utils.user.get().user_id) {
                            return;
                        }
                        title = 'New Order Placed';
                        message = `A new ${order.channel.name} Order for ${order.customer.full_name} has been placed for ${moment(order.drop_off_date).format('MMMM Do, YYYY [at] h:mma')}`;
                        icon = {
                            path: order.customer.avatar,
                            imageStyle: {
                                resizeMode: 'cover'
                            }
                        };
                        buttons = [{
                            key: 'order',
                            title: 'View Order',
                            style: 'default'
                        }].concat(buttons);

                        utils.content.update(Abstract.create({
                            type: 'orders',
                            object: order
                        }))
                        break;

                        case 'routes':
                        if(!route || !reservation) {
                            return;
                        }
                        if(reservation.customer.user_id == utils.user.get().user_id) {
                            return;
                        }
                        title = 'New QuickScan Ride';
                        message = `A new Quick Scan ride for ${reservation.customer.full_name} has been added to your Route`;
                        icon = {
                            path: reservation.customer.avatar,
                            imageStyle: {
                                resizeMode: 'cover'
                            }
                        };
                        buttons = [{
                            key: 'route',
                            title: 'View Route',
                            style: 'default'
                        },{
                            key: 'reservation',
                            title: 'View Reservation',
                            style: 'default'
                        }].concat(buttons);

                        utils.content.update(Abstract.create({
                            type: 'routes',
                            object: route
                        }))
                        utils.content.update(Abstract.create({
                            type: 'reservations',
                            object: reservation
                        }))
                        break;

                        case 'reservations':
                        if(!reservation || reservation.customer.user_id == utils.user.get().user_id) {
                            return;
                        }
                        title = 'New Reservation';
                        message = `A new Reservation for ${reservation.customer.full_name} has been submitted for ${moment(reservation.pickup_date).format('MMMM Do, YYYY [at] h:mma')}`;
                        icon = {
                            path: reservation.customer.avatar,
                            imageStyle: {
                                resizeMode: 'cover'
                            }
                        };
                        buttons = [{
                            key: 'reservation',
                            title: 'View Reservation',
                            style: 'default'
                        }].concat(buttons);

                        utils.content.update(Abstract.create({
                            type: 'reservations',
                            object: reservation
                        }))
                        break;

                        default:
                        return
                    }

                    utils.alert.show({
                        title: title,
                        message: message,
                        icon: icon,
                        buttons: buttons,
                        onPress: (key) => {
                            switch(key) {
                                case 'order':
                                utils.layer.order.details(order);
                                break;

                                case 'route':
                                utils.layer.route.details(route);
                                break;

                                case 'reservation':
                                utils.layer.reservation.details(reservation);
                                break;
                            }
                        }
                    })

                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue loading the information for this request. ${e.message || 'An unknown error occurred'}`
                    });
                }
            },
            on_status_change: async ({ id, type }) => {
                try {
                    let { abstract } = await Utils.create(utils, {
                        id: id,
                        type: type
                    });
                    if(abstract) {
                        utils.content.update(abstract);
                        console.log(`status update for ${abstract.type} ${abstract.getID()}`);
                    }
                } catch(e) {
                    console.error(e.message);
                }
            }
        };
    }

    const setupAndroidFonts = async () => {
        try {
            await Font.loadAsync({
                'Roboto-ThinItalic': {
                    uri: require('eCarra/assets/fonts/Roboto-ThinItalic.ttf'),
                    display: Font.FontDisplay.BLOCK,
                },
                'Roboto-Thin': {
                    uri: require('eCarra/assets/fonts/Roboto-Thin.ttf'),
                    display: Font.FontDisplay.BLOCK,
                },
                'Roboto-Regular': {
                    uri: require('eCarra/assets/fonts/Roboto-Regular.ttf'),
                    display: Font.FontDisplay.BLOCK,
                },
                'Roboto-MediumItalic': {
                    uri: require('eCarra/assets/fonts/Roboto-MediumItalic.ttf'),
                    display: Font.FontDisplay.BLOCK,
                },
                'Roboto-Medium': {
                    uri: require('eCarra/assets/fonts/Roboto-Medium.ttf'),
                    display: Font.FontDisplay.BLOCK,
                },
                'Roboto-LightItalic': {
                    uri: require('eCarra/assets/fonts/Roboto-LightItalic.ttf'),
                    display: Font.FontDisplay.BLOCK,
                },
                'Roboto-Light': {
                    uri: require('eCarra/assets/fonts/Roboto-Light.ttf'),
                    display: Font.FontDisplay.BLOCK,
                },
                'Roboto-Italic': {
                    uri: require('eCarra/assets/fonts/Roboto-Italic.ttf'),
                    display: Font.FontDisplay.BLOCK,
                },
                'Roboto-BoldItalic': {
                    uri: require('eCarra/assets/fonts/Roboto-BoldItalic.ttf'),
                    display: Font.FontDisplay.BLOCK,
                },
                'Roboto-Bold': {
                    uri: require('eCarra/assets/fonts/Roboto-Bold.ttf'),
                    display: Font.FontDisplay.BLOCK,
                },
                'Roboto-BlackItalic': {
                    uri: require('eCarra/assets/fonts/Roboto-BlackItalic.ttf'),
                    display: Font.FontDisplay.BLOCK,
                },
                'Roboto-Black': {
                    uri: require('eCarra/assets/fonts/Roboto-Black.ttf'),
                    display: Font.FontDisplay.BLOCK,
                }
            });

        } catch(e) {
            console.error(e.message);
        }
    }

    const setupPushNotifications = async () => {
        try {
            if(Platform.OS === 'web') {
                console.warn('Push notifications not implemented for web');
                return;
            }
            PushNotification.popInitialNotification(onParseNotificationPayload);
            PushNotification.onNotification = onParseNotificationPayload;
            PushNotification.onRegister = async ({ token, os }) => {
                try {
                    await Request.post(utils, '/user/', {
                        type: 'register_for_push',
                        os: os,
                        token: token
                    })
                } catch(e) {
                    console.error(e.message);
                }
            }
            let enabled = await checkNotificationPermission();
            if(enabled) {
                await PushNotification.requestPermissions();
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    const setupSaaS = async () => {

        if(API.get_client_id() === 'ecarra') {
            setValidating(false);
            return;
        }

        // validate SaaS client
        try {
            let client = await SaaS.ClientParameters.get(utils);
            global.client = client;
            setClient(client);
            setValidating(false);

            //set page title if platform is web
            if(Platform.OS === 'web') {
                document.title = client.name;
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the client information for this SaaS client. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const setupStripe = () => {
        Stripe.setOptions({
           publishableKey: API.stripe,
           merchantId: API.apple_pay
       });
    }

    useEffect(() => {
        onSetupContent();
    }, [content]);

    useEffect(() => {
        userRef.current = user;
        onSetupUserListeners();
    }, [user]);

    useEffect(() => {

        // set first location if applicable
        onFetchFirstLocation();

        // set window props if platform is web
        if(Platform.OS === 'web') {

            window.addEventListener('resize', onWindowSizeChange);

            // css variables
            document.documentElement.style.setProperty('--text', Appearance.colors.text());
            document.documentElement.style.setProperty('--textfield', Appearance.colors.textField());
            document.documentElement.style.setProperty('--soft_border', Appearance.colors.softBorder());

            // theme and theme listeners
            window.theme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
            document.body.className = window.theme;
            document.documentElement.style.setProperty('--theme', window.theme);
            window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
                window.theme = e.matches ? 'dark' : 'light';
                setNonce(moment().unix());
            });

            // setup facebook sdk
            window.fbAsyncInit = () => {
                window.FB.init({
                    appId: utils.client.get().apps.facebook,
                    autoLogAppEvents: false,
                    xfbml: true,
                    version: 'v11.0'
                });
            };
        }

        // load android fonts if applicable
        if(Platform.OS === 'android') {
            setupAndroidFonts();
        }

        // lock orientation to portrait for mobile devices
        if(Utils.isMobile() === true) {
            Screen.orientation.lockToPortrait();
        }
        if(Platform.OS !== 'web') {
            Screen.orientation.subscribe(() => {
                setNonce(moment().unix());
            });
        }

    }, []);

    useEffect(() => {
        onSetDriverVehicle();
    }, [driverVehicle.current]);

    useEffect(() => {
        workspace.floatingLayers.forEach(layer => {
            if(layer.closeOnViewChange) {
                utils.events.emit('onFloatingLayerEvent', {
                    layerID: layer.id,
                    event: 'close'
                });
                onCloseFloatingLayer(layer.id);
            }
        })
        onRevealContainer();
    }, [workspace.active]);

    useEffect(() => {
        if(user) {
            socketManager.updateSubscriber('root', getSocketCallbacks());
        }
    }, [workspace.active, workspace.panels, workspace.layers, workspace.floatingLayers])

    useEffect(() => {
        setupSaaS();
        setupStripe();
        utils.keyboard.subscribe('root', {
            onVisibility: visible => setKeyboardOpen(visible)
        })
        locationManager.subscribe('root', {
            onUpdate: location => {
                if(user) {
                    setUser(user => update(user, {
                        location: {
                            $set: location
                        }
                    }));
                }
            }
        })
    }, []);

    return (
        <AppearanceProvider nonce={nonce}>
            <View
            nonce={nonce}
            style={{
                flex: 1,
                width: '100%',
                height: '100%',
                overflowX: 'hidden',
                backgroundColor: Appearance.colors.background(),
                ...Platform.OS === 'web' && {
                    height: Screen.height()
                }
            }}>
                <Loader
                {...loader}
                utils={utils}>
                    {user && onRenderContent()}
                    {!user && getContentWithValidation()}
                </Loader>

                {qrCamera && (
                    <QRCamera
                    {...qrCamera}
                    utils={utils}
                    onClose={() => setQRCamera(null)} />
                )}

                <AlertStack
                alerts={alerts}
                utils={utils}
                onClose={id => {
                    setAlerts(alerts => {
                        return alerts.filter(alert => id !== alert.id);
                    })
                }} />

                {permissionsRequest && (
                    <PermissionsRequest
                    {...permissionsRequest}
                    utils={utils}
                    onClose={() => {
                        setPermissionsRequest(null);
                    }} />
                )}

                {datePicker && (
                    <DatePickerAlert
                    {...datePicker}
                    onClose={id => setDatePicker(null)} />
                )}

            </View>
        </AppearanceProvider>
    )
}

export default App;
