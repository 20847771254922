import React from 'react';
import { Dimensions, StatusBar, Platform } from 'react-native';

import DeviceInfo from 'react-native-device-info';
import Orientation from 'eCarra/files/Orientation/';
import StaticSafeAreaInsets from 'eCarra/files/SafeAreaInsets/';

// android screen constants
export const androidDeviceHeight = Dimensions.get('screen').height;
export const androidStatusBar = Platform.OS === 'android' ? (StatusBar.currentHeight || 24) : 0;
export const androidWindowHeight = Dimensions.get('window').height;

const getWidth = () => {
    switch(Platform.OS) {
        case 'ios':
        return Dimensions.get('screen').width;

        case 'android':
        if(Dimensions.get('screen').width < Dimensions.get('screen').height) {
            return Dimensions.get('screen').width;
        }
        return Dimensions.get('screen').height;

        case 'web':
        return window.innerWidth;
    }
}

const getHeight = () => {
    switch(Platform.OS) {
        case 'ios':
        return Dimensions.get('screen').height;

        case 'android':
        if(Dimensions.get('screen').height > Dimensions.get('screen').width) {
            return Dimensions.get('screen').height;
        }
        return Dimensions.get('screen').width;

        case 'web':
        return window.innerHeight;
    }
}

export const isMobile = () => {
    let type = DeviceInfo.getDeviceType();
    return type === 'Handset' || getWidth() < widthThreshold;
}

// mobile, tablet, web threshold
export const widthThreshold = 767.98;
const sidebarMaxWidth = Platform.OS === 'web' || isMobile() === false ? 325 : getWidth();

const Screen = {
    component: {
        maxWidth: isMobile() === false ? 500 : getWidth(),
        hero: {
            maxWidth: isMobile() === false ? 650 : getWidth()
        }
    },
    height: getHeight,
    layer: {
        maxWidth: isMobile() === false ? 600 : getWidth()
    },
    message: {
        attachment: {
            maxWidth: Platform.OS === 'web' || isMobile() === false ? 300 : getWidth() / 1.5
        }
    },
    navigationBar: androidDeviceHeight - androidWindowHeight - androidStatusBar,
    orientation: {
        get: async () => {
            return new Promise((resolve, reject) => {
                Orientation.getOrientation((error, orientation) => {
                    if(error) {
                        reject(error);
                        return;
                    }
                    resolve(orientation);
                });
            })
        },
        lockToLandscape: Orientation.lockToLandscape,
        lockToLandscapeLeft: Orientation.lockToLandscapeLeft,
        lockToPortrait: Orientation.lockToPortrait,
        subscribe: callback => {
            Orientation.addOrientationListener(callback);
        },
        unsubscribe: callback => {
            Orientation.removeOrientationListener(callback);
        }
    },
    panel: {
        maxWidth: () => {
            return Platform.OS === 'web' ? 1000 : getWidth();
        }
    },
    safeArea: {
        top: Platform.OS !== 'web' ? (StaticSafeAreaInsets.safeAreaInsetsTop || 0) : 0,
        bottom: Platform.OS !== 'web' ? (StaticSafeAreaInsets.safeAreaInsetsBottom || 0) : 0,
        left: Platform.OS !== 'web' ? (StaticSafeAreaInsets.safeAreaInsetsLeft || 0) : 0,
        right: Platform.OS !== 'web' ? (StaticSafeAreaInsets.safeAreaInsetsRight || 0) : 0,
        pixelDensity: Platform.OS !== 'web' ? (StaticSafeAreaInsets.safeAreaPixelDensity || 0) : 0
    },
    sidebar: {
        maxWidth: sidebarMaxWidth
    },
    statusBar: androidStatusBar,
    width: getWidth
}
export default Screen;
