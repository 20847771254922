import React, { useEffect, useState } from 'react';
import { View, Text, Image, Keyboard } from 'react-native';

import API from 'eCarra/files/api.js';
import Appearance from 'eCarra/styles/Appearance.js';
import Button from 'eCarra/views/Button.js';
import PromoCode from 'eCarra/classes/PromoCode.js';
import PromoCodes from 'eCarra/files/PromoCodes.js';
import Request from 'eCarra/files/Request/';
import TextField from 'eCarra/views/TextField.js';
import Views from 'eCarra/views/Main.js';

const PromoCodeLookup = ({ defaultPromoCode, onChange, redemption, utils }) => {

    const [code, setCode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState(null);
    const [valid, setValid] = useState(null);

    const onVerify = async () => {
        Keyboard.dismiss();
        if(!text) {
            utils.alert.show({
                title: 'Oops!',
                message: 'Please enter a promo code to continue'
            });
            return;
        }
        try {
            setLoading(true);
            let { message, is_valid, promo_code } = await Request.post(utils, '/promotion/', {
                type: 'verification',
                user_id: utils.user.get().user_id,
                code: text
            });

            setLoading(false);
            if(!is_valid) {
                setValid(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: message
                })
                return;
            }

            let code = PromoCode.create(promo_code);
            if(redemption === true && code.discountType !== PromoCodes.standard.credits) {
                throw new Error('This promo code is not meant for redeeming credits');
                return;
            }
            if(redemption !== true && code.discountType === PromoCodes.standard.credits) {
                throw new Error('This promo code is not meant to apply a discount to a Reservation');
                return;
            }

            setValid(true);
            if(typeof(onChange) === 'function') {
                onChange(code);
            }

        } catch(e) {
            setValid(false);
            setLoading(false);
            if(typeof(onChange) === 'function') {
                onChange(undefined);
            }
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue verifying your promo code. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onTextChange = text => {
        setValid(null);
        setCode(null);
        setText(text);
        if(typeof(onChange) === 'function') {
            onChange(undefined);
        }
    }

    const getAccessoryComponent = () => {
        if(typeof(valid) === 'boolean') {
            return (
                <Image
                source={valid === true ? require('eCarra/images/checkmark-green.png') : require('eCarra/images/rejected-red-small.png')}
                style={{
                    width: 25,
                    height: 25,
                    marginLeft: 8,
                    borderRadius: 12.5,
                    overflow: 'hidden'
                }} />
            )
        }
        if(typeof(text) === 'string' && text.length > 0) {
            return (
                <View style={{
                    marginLeft: 8
                }}>
                    <Button
                    label={'Verify'}
                    type={'small'}
                    color={'grey'}
                    onPress={onVerify} />
                </View>
            )
        }
        return null;
    }

    useEffect(() => {
        setText(defaultPromoCode);
    }, [defaultPromoCode]);

    return (
        <View style={{
            flex: 1,
            flexDirection: 'row',
            textAlign: 'left',
            alignItems: 'center',
            width: '100%'
        }}>
            <TextField
            onClick={() => setValid(valid === true ? true : null)}
            onChange={onTextChange}
            value={text}
            loading={loading}
            autoComplete={false}
            autoCorrect={false}
            autoCapitalize={false}
            spellCheck={false}
            containerStyle={{
                flexGrow: 1,
                width: '0%'
            }}/>
            {getAccessoryComponent()}
        </View>
    )
}
export default PromoCodeLookup;
