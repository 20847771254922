import React, { useRef, useState, useEffect } from 'react';
import { View, Text, Animated, TouchableOpacity } from 'react-native';

import Appearance from 'eCarra/styles/Appearance.js';

const BoolToggle = ({ enabled, disabled, isEnabled, onChange, style }) => {

    const ref = useRef(null);
    const [left, setLeft] = useState(new Animated.Value(0));
    const [status, setStatus] = useState(isEnabled);

    const onTogglePress = () => {
        setStatus(!status);
        if(onChange && typeof(onChange) === 'function') {
            onChange(!status);
        }
    }

    useEffect(() => {
        if(ref && ref.current) {
            ref.current.measure((ox, oy, width, height, px, py) => {
                Animated.spring(left, {
                    toValue: status ? (width / 2) : 0,
                    duration: 500,
                    friction: 10,
                    useNativeDriver: false
                }).start();
            })
        }
    }, [status]);

    return (
        <View ref={ref} style={{
            position: 'relative',
            flexDirection: 'row',
            width: '100%',
            height: 35,
            borderRadius: 17.5,
            borderWidth: 1,
            borderColor: Appearance.colors.softBorder(),
            overflow: 'hidden',
            margin: 'auto',
            ...style
        }}>
            <TouchableOpacity onPress={onTogglePress}
                style={{
                    width: '50%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 12,
                    paddingRight: 12
                }}>
                <Text style={{
                    fontSize: 12,
                    ...Appearance.fontWeight.get(500),
                    color: status ? Appearance.colors.text():'white'
                }}>{disabled}</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={onTogglePress}
                style={{
                    width: '50%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 12,
                    paddingRight: 12
                }}>
                <Text style={{
                    fontSize: 12,
                    ...Appearance.fontWeight.get(500),
                    color: status ? 'white':Appearance.colors.text()
                }}>{enabled}</Text>
            </TouchableOpacity>
            <Animated.View style={{
                position: 'absolute',
                left: left,
                width: '50%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 17.5,
                backgroundColor: Appearance.colors.grey()
            }}>
                <Text style={{
                    fontSize: 12,
                    ...Appearance.fontWeight.get(500),
                    color: 'white'
                }}>{status ? enabled:disabled}</Text>
            </Animated.View>
        </View>
    )
}
export default BoolToggle;
