import React, { useEffect, useState } from 'react';
import { Image, Platform, Text, View } from 'react-native';

import moment from 'moment';

import Appearance from 'eCarra/styles/Appearance.js';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import Utils from 'eCarra/files/Utils.js';

export const Calendar = ({ activeDate, channel, events, eventFilter, onClick, onDateChange, showOverflow, utils }) => {

    const weeks = [ 0, 1, 2, 3, 4 ];
    const [days, setDays] = useState([]);
    const [selectedDate, setSelectedDate] = useState(moment());
    const headers = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];

    useEffect(() => {

        setSelectedDate(activeDate);

        // 42 days accounts for 6 full rows of 7 day weeks if first of the month is thursday or later
        let startOfMonth = parseInt(moment(activeDate).startOf('month').format('e'));
        let totalDays = startOfMonth >= 5 ? 42 : 35;

        let days = [...new Array(totalDays)].map((_, day) => {
            return day < startOfMonth ? moment(activeDate).startOf('month').subtract(startOfMonth - day, 'days') : moment(activeDate).startOf('month').add(day - startOfMonth, 'days');
        });

        let chunks = days.reduce((array, day, index) => {
            let i = Math.floor(index / headers.length);
            if(!array[i]) {
                array[i] = [];
            }
            array[i].push({
                date: day,
                events: events.find(e => {
                    return eventFilter(day, e);
                }) ? true : false
            });
            return array;
        }, []);

        setDays(chunks);

    }, [activeDate, events]);

    return (
        <>
        <View style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-around',
            alignItems: 'center',
            textAlign: 'center',
            height: 40,
            paddingHorizontal: 15
        }}>
            {/* header days */}
            {headers.map((d, i) => (
                <Text
                key={i}
                numberOfLines={1}
                style={{
                    ...Appearance.textStyles.supporting(),
                    textAlign: 'center',
                    ...Appearance.fontWeight.get(600),
                    color: Appearance.colors.lightGrey,
                    flexGrow: 1,
                    width: '100%'
                }}>{d.toUpperCase()}</Text>
            ))}
        </View>
        {days.map((week, index) => {
            return (
                <View
                key={index}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    height: 45,
                    paddingHorizontal: 15,
                    marginBottom: Platform.OS === 'web' || Utils.isMobile() === false ? 25 : 8
                }}>
                    {week.map((entry, i) => {
                        return (
                            <View key={i}
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 1,
                                justifyContent: 'center',
                                height: '100%',
                                paddingLeft: 5,
                                paddingRight: 5,
                                opacity: entry.date.isSame(moment(activeDate), 'month') ? 1:0.25
                            }}>
                                <TouchableOpacity
                                activeOpacity={0.6}
                                onPress={entry.date.isSame(moment(activeDate), 'month') ? () => {
                                    setSelectedDate(entry.date.format('YYYY-MM-DD'));
                                    if(typeof(onDateChange) === 'function') {
                                        onDateChange(entry.date);
                                    }
                                } : null}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    height: '100%'
                                }}>
                                    <View style={{
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        borderRadius: 8,
                                        paddingTop: 6,
                                        width: '100%',
                                        height: '100%',
                                        maxWidth: 38,
                                        backgroundColor: selectedDate && entry.date.isSame(moment(selectedDate), 'day') ? Appearance.colors.primary() : null
                                    }}>
                                    <Text
                                    numberOfLines={1}
                                    style={{
                                        ...Appearance.textStyles.title(),
                                        ...Appearance.fontWeight.get(700),
                                        textAlign: 'center',
                                        width: '100%',
                                        fontSize: 16,
                                        ...selectedDate && entry.date.isSame(moment(selectedDate), 'day') && {
                                            color: 'white'
                                        }
                                    }}>{entry.date.format('D')}</Text>
                                    <View style={{
                                        width: 5,
                                        height: 5,
                                        borderRadius: 2.5,
                                        marginTop: 3,
                                        backgroundColor: selectedDate && entry.date.isSame(moment(selectedDate), 'day') ? 'white' : Appearance.colors.lightGrey,
                                        opacity: entry.events ? 1:0
                                    }}/>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        )
                    })}
                </View>
            )
        })}
        </>
    )
}

export default Calendar;
