import React, { useEffect, useState } from 'react';
import { Animated, Image, Keyboard, ScrollView, Text, View } from 'react-native';

import update from 'immutability-helper';
import Permissions, { RESULTS, PERMISSIONS } from 'eCarra/files/Permissions/';

import API from 'eCarra/files/api.js';
import Appearance from 'eCarra/styles/Appearance';
import Button from 'eCarra/views/Button.js';
import { Camera } from 'eCarra/files/Camera/';
import LottieView from 'eCarra/views/Lottie/';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import Screen from 'eCarra/files/Screen.js';
import TextField from 'eCarra/views/TextField.js';
import TouchableHighlight from 'eCarra/views/TouchableHighlight/';
import Utils from 'eCarra/files/Utils.js';

const QRCamera = ({ id, utils, onScan, onClose }) => {

    const [allowed, setAllowed] = useState(false);
    const [visible, setVisible] = useState(false);
    const [canScan, setCanScan] = useState(true);
    const [scale, setScale] = useState(new Animated.Value(0));
    const [bottom, setBottom] = useState(new Animated.Value(0));
    const [opacity, setOpacity] = useState(new Animated.Value(0));

    const hide = callback => {

        Animated.spring(scale, {
            toValue: 0,
            duration: 150,
            useNativeDriver: false
        }).start();
        Animated.timing(opacity, {
            toValue: 0,
            duration: 150,
            useNativeDriver: false
        }).start(() => {
            setVisible(false);
            if(typeof(onClose) === 'function') {
                onClose();
            }
            if(typeof(callback) === 'function') {
                callback();
            }
        });
    }

    const buttonPress = () => {
        hide(onScan);
    }

    const requestPermissions = async () => {
        try {

            await Utils.sleep(0.25);
            let camera = await Permissions.request(Platform.OS === 'ios' ? PERMISSIONS.IOS.CAMERA : PERMISSIONS.ANDROID.CAMERA);
            if(camera === RESULTS.GRANTED) {
                setAllowed(true);
                return;
            }
            throw new Error(`Camera permission is needed to continue. Please check the permissions for ${utils.client.get().name} in the Settings app`);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up your camera. ${e.message || 'An unknown error occurred'}`,
                onPress: hide
            })
        }
    }

    const onQRCodeScan = ({ data }) => {

        setCanScan(false);
        try {
            let json = JSON.parse(data);
            if(json.client_id !== API.get_client_id()) {
                utils.alert.show({
                    title: 'Oops!',
                    message: 'This code is not meant for this app',
                    onPress: () => setCanScan(true)
                });
                return;
            }
            hide(() => {
                if(typeof(onScan) === 'function') {
                    onScan(json);
                }
            })

        } catch(e) {
            console.error(e.message);
            setCanScan(true);
        }
    }

    useEffect(() => {

        Keyboard.dismiss();
        setVisible(true);
        requestPermissions();
        setTimeout(() => {

            Animated.spring(scale, {
                toValue: 1,
                duration: 500,
                friction: 5,
                useNativeDriver: false
            }).start();
            Animated.timing(opacity, {
                toValue: 1,
                duration: 250,
                useNativeDriver: false
            }).start();

            if(Platform.OS === 'ios') {
                ReactNativeHapticFeedback.trigger('notificationSuccess', {
                    enableVibrateFallback: false,
                    ignoreAndroidSystemSettings: false
                });
            }

        }, 0);

    }, [])

    return visible ? (
        <View style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            width: Screen.width(),
            height: Screen.height(),
            backgroundColor: Appearance.colors.transparent,
            zIndex: 9950
        }}>
            <Animated.View style={{
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                opacity: opacity,
                position: 'absolute',
                backgroundColor: Appearance.colors.dim
            }} />
            <Animated.View style={{
                borderRadius: 15,
                backgroundColor: Appearance.colors.alert(),
                overflow:'hidden',
                maxWidth: 500,
                maxHeight: Screen.height() - 30,
                width: Screen.width() - 60,
                alignItems: 'center',
                zIndex: 9999,
                opacity: opacity,
                bottom: bottom,
                transform: [{
                    scale: scale
                }]
            }}>
                <View style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'black',
                    borderRadius: 8,
                    overflow: 'hidden',
                    marginVertical: 12,
                    width: (Screen.width() - 60 > 500 ? 500 : Screen.width() - 60) - 30,
                    height: (Screen.width() - 60 > 500 ? 500 : Screen.width() - 60) - 30
                }}>
                    {allowed
                        ?
                        <Camera
                        onBarCodeRead={canScan ? onQRCodeScan : null}
                        type={Camera.Constants.Type.back}
                        flashMode={Camera.Constants.FlashMode.off}
                        style={{
                            borderRadius: 8,
                            overflow: 'hidden',
                            marginVertical: 12,
                            width: (Screen.width() - 60 > 500 ? 500 : Screen.width() - 60) - 30,
                            height: (Screen.width() - 60 > 500 ? 500 : Screen.width() - 60) - 30
                        }}
                        androidCameraPermissionOptions={{
                            title: 'Camera',
                            message: 'We need your permission to use your camera',
                            buttonPositive: 'Okay',
                            buttonNegative: 'Cancel',
                        }} />
                        :
                        <LottieView
                        autoPlay={true}
                        loop={true}
                        source={require('eCarra/files/lottie/dots-white.json')}
                        duration={2500}
                        style={{
                            width: 50
                        }}/>
                    }
                </View>
                <View style={{
                    paddingHorizontal: 15,
                    paddingBottom: 15,
                    width: '100%'
                }}>
                    <View style={{
                        width: '100%'
                    }}>
                        <Button
                        label={'Dismiss'}
                        color={Appearance.colors.grey()}
                        onPress={buttonPress} />
                    </View>
                </View>
            </Animated.View>
        </View>
    ) : null
}

export default QRCamera;
