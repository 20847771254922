import React, { useEffect, useState } from 'react';
import moment from 'moment';

import Appearance from 'eCarra/styles/Appearance.js';
import Button from 'eCarra/views/Button.js';
import TextField, { getIcon } from 'eCarra/views/TextField.js';
import Utils from 'eCarra/files/Utils.js';

const DatePickerComponent = ({ enabled, date, fadeToColor, minDate, onChange, onInvalidChange, onlyTime, style, showTime, utils }) => {

    const [dateVal, setDateVal] = useState(null);
    const [hover, setHover] = useState(false);
    const [invalidDate, setInvalidDate] = useState(false);
    const [time, setTime] = useState(null);
    const [timeOfDay, setTimeOfDay] = useState(null);
    const [times, setTimes] = useState([...new Array(144)].map((_, index) => moment().startOf('day').add(index * (1/12), 'hours').format('h:mm')));

    const onDateChange = date => {
        let formatted = date.format('YYYY-MM-DD');
        setDateVal(formatted);
    }

    const onFieldClick = () => {
        utils.datePicker.show({
            dateTime: false,
            date: dateVal ? moment(dateVal) : moment(),
            onDateChange: onDateChange
        })
    }

    const onFormatDate = () => {
        if(!dateVal) {
            return 'Date';
        }
        return moment(dateVal).format('MMMM Do, YYYY');
    }

    const onParseDateTime = () => {
        if(!dateVal || !time || !timeOfDay) {
            return;
        }
        let nextDate = moment(`${dateVal} ${time}${timeOfDay}`, 'YYYY-MM-DD hh:mma');
        if(minDate && nextDate < minDate) {
            setInvalidDate(true);
            return;
        }

        setInvalidDate(false);
        if(typeof(onChange) === 'function') {
            onChange(nextDate);
        }
    }

    const onSetupTimes = () => {

        let current = moment().startOf('day').unix();
        let min = moment().startOf('day').unix();
        let max = moment().endOf('day').unix();
        setTimes(() => {
            let times = [];
            while(current <= max) {
                let next = moment.unix(current);
                if(next.format('a') === timeOfDay) {
                    times.push(next.format('h:mm'));
                }
                current = next.add(5, 'minutes').unix();
            }
            return times;
        })
    }

    const getIconComponent = () => {
        return (
            <div style={{
                paddingRight: 8,
                marginLeft: 4
            }}>
                <i
                className={getIcon('calendar')}
                style={{
                    marginRight: 8,
                    color: Appearance.colors.softBorder(),
                    fontSize: 13
                }}/>
            </div>
        )
    }

    const getTextColor = () => {
        return dateVal ? Appearance.colors.text() : Appearance.colors.subText();
    }

    const setupTarget = () => {

        // prepare target dates
        let target = date ? moment(date) : moment();
        let next = Utils.conformDate(target, 5);

        // set date, time, and time of day values
        setDateVal(next.format('YYYY-MM-DD'));
        setTime(next.format('h:mm'));
        setTimeOfDay(next.format('a'));
    }

    useEffect(() => {
        onParseDateTime();
    }, [dateVal, time, timeOfDay]);

    useEffect(() => {
        onSetupTimes();
    }, [timeOfDay]);

    useEffect(() => {
        if(typeof(onInvalidChange) === 'function') {
            onInvalidChange(invalidDate);
        }
    }, [invalidDate]);

    useEffect(() => {
        setupTarget();
    }, [date]);

    return (
        <div
        className={'row p-0'}
        style={{
            width: '100%',
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: 0,
            ...style
        }}>
            <div className={'col-12 col-md-6 p-0'}>
                <div
                onClick={onFieldClick}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                className={`dummy-field ${hover ? 'active' : ''} ${window.theme} cursor-pointer`}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flexGrow: 1,
                    position: 'relative',
                    height: 35,
                    border: `1px solid ${Appearance.colors.softBorder()}`
                }}>
                    {getIconComponent()}
                    <span
                    className={'transition-500'}
                    style={{
                        fontSize: 12,
                        fontWeight: 500,
                        color: invalidDate ? Appearance.colors.red : getTextColor(),
                        width: '100%',
                        flexGrow: 1
                    }}>{onFormatDate()}</span>
                </div>
            </div>
            <div className={'col-12 col-md-3 p-0 px-md-1'}>
                <select
                className={`custom-select ${window.theme} ${invalidDate ? 'invalid' : ''}`}
                value={time || 'Time'}
                onChange={e => {
                    let index = Utils.attributeForKey.select(e, 'id');
                    setTime(times[index]);
                }}
                style={{
                    marginRight: 8,
                    color: time ? Appearance.colors.text() : Appearance.colors.subText(),
                    border: `1px solid ${Appearance.colors.softBorder()}`
                }}>
                    <option disabled={true}>{'Time'}</option>
                    {times.map((time, index) => {
                        return <option key={index} id={index}>{time}</option>
                    })}
                </select>
            </div>
            <div className={'col-12 col-md-3 p-0'}>
                <select
                className={`custom-select ${window.theme} ${invalidDate ? 'invalid' : ''}`}
                value={timeOfDay ? timeOfDay.toUpperCase() : 'AM'}
                onChange={e => {
                    let text = Utils.attributeForKey.select(e, 'id');
                    setTimeOfDay(text);
                }}
                style={{
                    marginRight: 8,
                    border: `1px solid ${Appearance.colors.softBorder()}`
                }}>
                    {[ 'am', 'pm' ].map((text, index) => {
                        return <option key={index} id={text}>{text.toUpperCase()}</option>
                    })}
                </select>
            </div>
        </div>
    )
}
export default DatePickerComponent;

export const MonthYearPicker = ({ enabled, date, minDate, onChange, style, utils }) => {

    const [month, setMonth] = useState(null);
    const [months, setMonths] = useState([]);
    const [year, setYear] = useState(null);
    const [years, setYears] = useState([]);

    const hasValidDate = () => {
        return month && year ? true : false;
    }

    const onMonthChange = evt => {
        let index = Utils.attributeForKey.select(evt, 'index');
        setMonth(months[index]);
    }

    const onParseDate = () => {
        if(!month || !year) {
            return;
        }
        let next_date = moment(`${month}-01-${year}`, 'MMMM-DD-YYYY');
        if(!next_date.isValid()) {
            //console.log('invalid date');
            if(typeof(onChange) === 'function') {
                onChange(null);
            }
            return;
        }
        //console.log(next_date.format('YYYY-MM-DD'))
        if(typeof(onChange) === 'function') {
            onChange(next_date);
        }
    }

    const onRevokeInteraction = () => {
        setMonth(null);
        setYear(null);
        if(typeof(onChange) === 'function') {
            onChange(null);
        }
    }

    const onYearChange = evt => {
        let index = Utils.attributeForKey.select(evt, 'index');
        setYear(years[index]);
    }

    const setupTarget = () => {

        // setup list items
        setMonths([ ...Array(12) ].map((_, index) => {
            return moment().startOf('year').add(index, 'months').format('MMMM')
        }));
        setYears([ ...Array(100) ].map((_, index) => {
            return moment().subtract(index, 'years').format('YYYY');
        }));

        // setup default month and year
        let target = date ? moment(date) : moment();
        setMonth(target.format('MMMM'));
        setYear(target.format('YYYY'));
    }

    useEffect(() => {
        onParseDate();
    }, [month, year]);

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <div
        className={'row p-0 m-0'}
        style={{
            width: '100%'
        }}>
            <div className={'col-6 p-0 pr-1'}>
                <select
                className={`custom-select ${window.theme}`}
                value={month || 'Month'}
                onChange={onMonthChange}
                style={{
                    width: '100%',
                    color: Appearance.colors.text(),
                    height: 35
                }}>
                    <option>{'Month'}</option>
                    {months.map((month, index) => {
                        return (
                            <option key={index} index={index}>{month}</option>
                        )
                    })}
                </select>
            </div>
            <div className={'col-6 p-0 pl-1'}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%'
                }}>
                    <select
                    className={`custom-select ${window.theme}`}
                    value={year || 'Year'}
                    onChange={onYearChange}
                    style={{
                        width: '100%',
                        color: Appearance.colors.text(),
                        height: 35
                    }}>
                        <option>{'Year'}</option>
                        {years.map((year, index) => {
                            return (
                                <option key={index} index={index}>{year}</option>
                            )
                        })}
                    </select>
                </div>
            </div>
        </div>
    )
}
