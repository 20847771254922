import React, { useRef, useState, useEffect } from 'react';
import { Animated, Image, Linking, Platform, ScrollView, Text, View } from 'react-native';

import moment from 'moment';
import update from 'immutability-helper';

import API from 'eCarra/files/api.js';
import Abstract from 'eCarra/classes/Abstract.js';
import { ActiveAbstractHeader } from 'eCarra/managers/Vehicles.js';
import Appearance from 'eCarra/styles/Appearance.js';
import { Call, Text as TextMessage } from 'react-native-openanything';
import DatePicker from 'eCarra/views/DatePicker/';
import { DriverNavigation } from 'eCarra/managers/Reservations.js';
import HeaderWithButton from 'eCarra/views/HeaderWithButton.js';
import Layer, { FloatingLayerMinimizedHeight, LayerHeaderSpacing, LayerShell } from 'eCarra/structure/Layer.js';
import LottieView from 'eCarra/views/Lottie/';
import { Map } from 'eCarra/views/Maps/';
import Panel, { MobilePageControl } from 'eCarra/structure/Panel.js';
import { QRCode } from 'eCarra/views/QRCode/';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import Request from 'eCarra/files/Request/';
import Reservation from 'eCarra/classes/Reservation.js';
import Route from 'eCarra/classes/Route.js';
import RouteCategory from 'eCarra/classes/RouteCategory.js';
import Screen from 'eCarra/files/Screen.js';
import Service from 'eCarra/classes/Service.js';
import Sound from 'eCarra/files/Sound/';
import StatusCodes from 'eCarra/files/StatusCodes.js';
import TextField from 'eCarra/views/TextField.js';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import User from 'eCarra/classes/User.js';
import Utils, { useLoading, useResultsManager } from 'eCarra/files/Utils.js';
import Views from 'eCarra/views/Main.js';

export const fetchQuickScanCategories = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { categories, paging } = await Request.get(utils, '/quick_scan/', {
                type: 'route_categories',
                ...props
            })
            resolve({
                categories: categories.map(category => RouteCategory.create(category)),
                paging: paging
            })
        } catch(e) {
            reject(e);
        }
    })
}

export const fetchQuickScanLineItems = async (utils, props) => {
    return new Promise(async (resolve ,reject) => {
        try {
            let { line_items, route } = await Request.get(utils, '/quick_scan/', {
                type: 'line_items',
                ...props
            });
            resolve({
                route: route,
                lineItems: line_items || []
            });
        } catch(e) {
            reject(e);
        }
    })
}

export const onStartRoute = async (utils, abstract) => {
    return new Promise(async (resolve, reject) => {
        try {
            if(!utils.driver.vehicle.get()) {
                throw new Error('You need to add a vehicle before you start this Reservation');
                return;
            }

            if(abstract.object.driver && abstract.object.driver.user_id !== utils.user.get().user_id) {
                throw new Error(`This QuickScan Route belongs to ${abstract.object.driver.full_name}. It can not be started by another driver`);
                return;
            }

            let status = await abstract.object.updateStatus(utils, StatusCodes.routes.inProgress);
            resolve(abstract);

            setTimeout(() => {
                abstract.object.status = status;
                utils.layer.openFloating({
                    id: `driver-navigation-${abstract.getTag()}`,
                    abstract: abstract,
                    Component: DriverNavigation.bind(this, {
                        resuming: false
                    })
                });
            }, 1000);

        } catch(e) {
            reject(e);
        }
    })
}

export const onResumeRoute = async (utils, abstract) => {
    return new Promise(async (resolve, reject) => {
        try {
            if(!utils.driver.vehicle.get()) {
                throw new Error('You need to add a vehicle before you start this QuickScan Route');
                return;
            }
            if(abstract.object.driver && abstract.object.driver.user_id !== utils.user.get().user_id) {
                throw new Error(`This QuickScan Route belongs to ${abstract.object.driver.full_name}. It can not be started by another driver`)
                return;
            }

            resolve();
            setTimeout(() => {
                utils.layer.openFloating({
                    id: `driver-navigation-${abstract.getTag()}`,
                    abstract: abstract,
                    Component: DriverNavigation.bind(this, {
                        resuming: true
                    })
                });
            }, 1000);

        } catch(e) {
            reject(e);
        }
    })
}

// Panels
export const DriverRoutesList = ({ utils }) => {

    const panelID = 'driverRoutesList';
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState(moment().format('YYYY-MM-DD HH:mm:ss'));
    const [annotations, setAnnotations] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [driverLocation, setDriverLocation] = useState(utils.location.last());

    const onCustomDate = () => {

        let tempDate = null;

        utils.layer.open({
            id: 'calendar-custom-date',
            Component: LayerShell.bind(this, {
                layerID: 'calendar-custom-date',
                extendedOptions: {
                    bottomCard: true
                },
                buttons: [{
                    key: 'done',
                    text: 'Done',
                    color: 'primary',
                    onPress: () => {
                        utils.events.emit('onLayerAction', {
                            action: 'close',
                            layerID: 'calendar-custom-date'
                        })
                        setDate(tempDate ? moment(tempDate) : date)
                    }
                }],
                children: (
                    <View style={{
                        alignItems: 'center',
                        marginTop: 5
                    }}>
                        <Text style={{
                            ...Appearance.textStyles.panelTitle(),
                            marginBottom: 6
                        }}>{'Browse Other Dates'}</Text>
                        <Text style={{
                            ...Appearance.textStyles.subTitle(),
                            marginBottom: 20,
                            textAlign: 'center'
                        }}>{'Choose a month and year from the list below to view more QuickScan Routes'}</Text>
                        <DatePicker
                        utils={utils}
                        date={date}
                        onChange={date => tempDate = date}/>
                    </View>
                )
            })
        })
    }

    const getButtons = route => {

        let buttons = {};
        if([ StatusCodes.routes.approved, StatusCodes.routes.returned ].includes(route.status.code)) {
            buttons.onStartRoute = onStartRoute;
        }
        if([ StatusCodes.routes.inProgress ].includes(route.status.code)) {
            buttons.onResumeRoute = onResumeRoute;
        }
        return buttons;
    }

    const onNewRoutePress = () => {
        utils.layer.open({
            id: 'new-quick-scan-route',
            Component: NewQuickScanRoute
        })
    }

    const onCodeAccepted = (id, data) => {
        utils.alert.hide(id);
        utils.sockets.off('quick_scan', `on_code_scan_${data.route_id}`, onCodeAccepted.bind(this, id));
    }

    const onRoutePress = route => {

        // Show qr code is no reservations have been added
        if(route.reservations.length === 0) {

            let vehicle = utils.driver.vehicle.get();
            if(!vehicle) {
                utils.alert.show({
                    title: 'Oops!',
                    message: 'Please add a vehicle before moving on'
                });
                return;
            }
            let id = utils.alert.show({
                title: 'QuickScan Code',
                message: 'Have your customer scan your QuickScan Code to add them to your Route. More information for your Route will be available once at least one customer has joined',
                content: (
                    <View style={{
                        padding: 15
                    }}>
                        <QRCode
                        color={'white'}
                        size={Screen.width() / 2}
                        backgroundColor={Appearance.colors.primary()}
                        content={JSON.stringify({
                            route_id: route.id,
                            driver: utils.user.get().user_id,
                            vehicle: vehicle.id,
                            channel: 'quick_scan',
                            client_id: API.get_client_id()
                        })} />
                    </View>
                )
            })
            utils.sockets.on('quick_scan', `on_code_scan_${route.id}`, onCodeAccepted.bind(this, id));
            return;
        }

        // Show route details if reservation has been added
        utils.layer.route.details(route, getButtons(route))
    }

    const fetchRoutes = async () => {

        // attempt to fetch location pending previous permission rejection
        // prompt user to enable location services in settings if unsuccessful
        let location = utils.location.last();
        if(!location) {
            try {
                location = await utils.location.get();
            } catch(e) {
                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue retrieving your location. We'll need to know your location to find your QuickScan rides. Please check that location permission is enabled for the ${utils.client.get().name} app`,
                    buttons: [{
                        key: 'settings',
                        title: 'Settings',
                        style: 'destructive'
                    },{
                        key: 'cancel',
                        title: 'Maybe Later',
                        style: 'default'
                    }],
                    onPress: key => {
                        if(key === 'settings') {
                            Linking.openSettings();
                            return;
                        }
                    }
                });
                return;
            }
        }

        try {
            let response = await Request.get(utils, '/quick_scan/', {
                type: 'all_routes',
                date: (date ? moment(date) : moment()).format('YYYY-MM-DD'),
                latitude: location.latitude,
                longitude: location.longitude
            });

            setLoading(false);
            let routes = response.map(r => Route.create(r));

            setRoutes(routes);
            setAnnotations(routes.reduce((array, route) => {
                return array.concat(route.reservations.map(reservation => {
                    return {
                        id: `reservation-${reservation.id}`,
                        title: `${reservation.customer.full_name} (${reservation.id})`,
                        subTitle: reservation.origin.address,
                        location: reservation.origin.location,
                        data: {
                            key: `reservation-${reservation.id}`,
                            reservation: reservation
                        }
                    }
                }))
            }, []))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your QuickScan routes. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onAddRide = data => {
        try {
            let { route_id, reservation_id, customer } = JSON.parse(data);
            utils.alert.show({
                title: 'New Ride Added',
                message: `${customer.full_name} has been added to Route #${route_id}`,
                icon: {
                    path: { uri: customer.avatar }
                },
                onPress: fetchRoutes
            })

        } catch(e) {
            console.error(e.message);
        }
    }

    const getSubLabel = () => {
        let target = moment(date);
        if(target.isSame(moment(), 'day')) {
            return null;
        }
        if(target.isSame(moment().add(1, 'days'), 'day')) {
            return `Tomorrow, ${target.format('MMM Do')}`;
        }
        if(target.isSame(moment().subtract(1, 'days'), 'day')) {
            return `Yesterday, ${target.format('MMM Do')}`;
        }
        if(target > moment() && target <= moment().add(6, 'days')) {
            return target.format('dddd, MMMM Do');
        }
        if(target >= moment().subtract(6, 'days') && target <= moment()) {
            return target.format('dddd, MMMM Do');
        }
        return target.format('MMMM Do, YYYY');
    }

    useEffect(() => {

        if(routes) {
            routes.forEach(route => {
                utils.sockets.off('quick_scan', `add_ride_${route.id}`, onAddRide)
                utils.sockets.on('quick_scan', `add_ride_${route.id}`, onAddRide)
            });
        }

        // Content updates
        utils.content.subscribe(panelID, [ 'routes', 'reservations' ], {
            onFetch: () => fetchRoutes(),
            onUpdate: abstract => {
                switch(abstract.type) {
                    case 'routes':
                    let index = (routes || []).findIndex(route => route.id === abstract.getID());
                    if(index >= 0) {
                        setRoutes(routes => update(routes, {
                            [index]: {
                                $set: abstract.object
                            }
                        }))
                    }
                    break;

                    case 'reservations':
                    setRoutes(routes => update(routes, {
                        $apply: routes => routes.map(route => {

                            let updatedRoute = route;
                            updatedRoute.reservations = route.reservations.map(reservation => {
                                let res = reservation;
                                if(res.id === abstract.getID()) {
                                    res = abstract.object;
                                }
                                return res;
                            })
                            return updatedRoute;
                        })
                    }));
                    break;
                }
            }
        })

        return () => {
            utils.content.unsubscribe(panelID);
            if(routes) {
                routes.forEach(route => {
                    utils.sockets.off('quick_scan', `add_ride_${route.id}`, onAddRide)
                })
            }
        }

    }, [date, routes])

    useEffect(() => {
        utils.location.subscribe(panelID, {
            onUpdate: location => {
                setDriverLocation(location);
            }
        })
        return () => {
            utils.location.unsubscribe(panelID)
        }
    }, []);

    useEffect(() => {
        fetchRoutes();
    }, [date]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        header={(
            <HeaderWithButton
            label={'QuickScan Rides'}
            subLabel={getSubLabel()}
            button={'new'}
            onDropDownPress={onCustomDate}
            onPress={onNewRoutePress} />
        )}
        options={{
            loading: loading,
            removeOverflow: true,
            shouldStyle: false
        }}>

            <View style={Appearance.styles.panel()}>
                {routes.length === 0
                    ?
                    Views.entry({
                        title: 'Nothing to see here',
                        subTitle: `No QuickScan rides are booked for ${moment(date).format('MMM Do')}`,
                        hideIcon: true,
                        bottomBorder: false
                    })
                    :
                    routes.map((route, index, routes) => {
                        return (
                            Views.entry({
                                key: index,
                                title: route.name || `Route #${route.id}`,
                                subTitle: route.reservations.length === 0 ? 'No Reservations have been added' : (route.reservations.length === 1 ? `Drop-Off at ${route.reservations[0].destination.name}` : `${route.reservations[0].destination.name} and ${route.reservations.length - 1} Other ${route.reservations.length - 1 === 1 ? 'Stop' : 'Stops'}`),
                                badge: route.status.code !== StatusCodes.routes.approved && route.status.code !== StatusCodes.routes.returned ? {
                                    text: route.status.text,
                                    color: route.status.color
                                } : null,
                                icon: () => route.reservations.length === 0 ? (
                                    <Image source={route.driver.avatar} style={Appearance.icons.user} />
                                ) : (
                                    <View style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        position: 'relative',
                                        height: Appearance.icons.standard.height,
                                        width: route.reservations.length > 0 ? (Appearance.icons.standard.width + (((route.reservations.length > 2 ? 2 : route.reservations.length) - 1) * 10)) : Appearance.icons.standard.width
                                    }}>
                                        {route.reservations.map((reservation, index) => {
                                            if(index > 2) {
                                                return null; // only show 3 images max
                                            }
                                            return (
                                                <Image key={index}
                                                source={reservation.customer.avatar}
                                                style={{
                                                    ...Appearance.icons.standard,
                                                    position: 'absolute',
                                                    left: index * 10,
                                                    top: 0,
                                                    resizeMode: 'cover',
                                                    borderWidth: 1,
                                                    borderColor: Appearance.colors.panelBackground()
                                                }}/>
                                            )
                                        })}
                                    </View>
                                ),
                                propStyles: {
                                    subTitle: {
                                        numberOfLines: 2
                                    }
                                },
                                bottomBorder: index !== routes.length - 1,
                                onPress: onRoutePress.bind(this, route)
                            })
                        )
                    })
                }
            </View>
        </Panel>
    )
}

// Layers
export const NewQuickScanRoute = ({ index, options, utils }) => {

    const layerID = 'new-quick-scan-route';
    const limit = 5;

    const [categories, setCategories] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useLoading(false);
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const onCreateRoute = async () => {
        try {
            let vehicle = utils.driver.vehicle.get();
            if(!vehicle) {
                throw new Error('Please add a vehicle before moving forward');
            }

            setLoading('done');
            await Request.post(utils, '/quick_scan/', {
                type: 'new_route',
                driver_id: utils.user.get().user_id,
                vehicle_id: vehicle.id,
                categories: selectedCategories
            });

            utils.content.fetch('routes');
            utils.alert.show({
                title: 'All Done!',
                message: 'Your new route has been created and is ready to start accepting riders',
                onPress: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating your route. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onCategoryPress = category => {
        setSelectedCategories(categories => {
            let index = categories.findIndex(id => id === category.id);
            if(index >= 0) {
                return update(categories, {
                    $splice:[
                        [index, 1]
                    ]
                });
            }
            return update(categories, {
                $push: [category.id]
            });
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getCategories = () => {
        if(categories.length === 0) {
            return (
                Views.entry({
                    title: 'No Categories Found',
                    subTitle: `There were no QuickScan categories found ${searchText ? 'from your search' : 'in the system'}`,
                    hideIcon: true,
                    bottomBorder: false,
                    propStyles: {
                        subTitle: {
                            numberOfLines: 2
                        }
                    }
                })
            )
        }
        return categories.map((category, index, categories) => {
            return Views.entry({
                key: index,
                title: category.name,
                subTitle: category.information,
                bottomBorder: index !== categories.length - 1,
                icon: {
                    path: require('eCarra/images/quick-scan-category-icon.png'),
                    style: {
                        backgroundColor: selectedCategories.find(id => id === category.id) ? Appearance.colors.primary() : Appearance.colors.grey()
                    }
                },
                onPress: onCategoryPress.bind(this, category)
            })
        })
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <>
            <ScrollView
            showsVerticalScrollIndicator={false}
            style={{
                width: '100%',
                borderTopWidth: 1,
                borderTopColor: Appearance.colors.divider()
            }}>
                {getCategories()}
            </ScrollView>
            {paging && (
                <MobilePageControl
                description={paging}
                limit={limit}
                offset={manager.offset}
                onChange={next => setManager('offset', next)} />
            )}
            </>
        )
    }

    const fetchCategories = async () => {
        try {
            let { categories, paging } = await fetchQuickScanCategories(utils, {
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setCategories(categories);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the QuickScan route categories. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchCategories();
    }, [manager]);

    return (
        <Layer
        id={layerID}
        title={'New QuickScan Route'}
        utils={utils}
        index={index}
        options={{
            ...options,
            loading: loading,
            layerState: layerState,
            bottomCard: true,
            removePadding: true
        }}
        buttons={[{
            key: 'done',
            text: 'Create Route',
            color: Appearance.colors.primary(),
            onPress: onCreateRoute
        }]}>
            <View style={{
                padding: 15,
                width: '100%',
                alignItems: 'center'
            }}>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 6
                }}>{'New QuickScan Route'}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    textAlign: 'center',
                    marginBottom: 20
                }}>{'This new ride will allow customers to scan your QR code and join your shared trip. You can select one or more Categories that you would like to drive or you can open up this ride to all possible destinations.'}</Text>

                <TextField
                icon={'search'}
                useDelay={true}
                loading={loading}
                placeholder={'Search by Category name...'}
                onChange={onSearchTextChange} />
            </View>
            <View style={{
                paddingHorizontal: 15
            }}>
                <View style={{
                    ...Appearance.styles.panel(),
                    width: '100%',
                    maxHeight: Screen.height() / 2.5,
                }}>
                    {getContent()}
                </View>
            </View>
        </Layer>
    )
}

export const NewQuickScanCustomerRoute = ({ onScan, onVerify, onClose }, { index, options, utils }) => {

    const layerID = 'new-quick-scan-customer-route';
    const [layerState, setLayerState] = useState(null);
    const [routeID, setRouteID] = useState(null);
    const [scale, setScale] = useState(new Animated.Value(0));
    const [shouldScan, setShouldScan] = useState(true);

    const onCancelPress = () => {
        setLayerState('close');
        if(typeof(onClose) === 'function') {
            onClose();
        }
    }

    const onNewRouteID = ({ route_id }) => {
        setRouteID(route_id);
        //Sound.Alert.Success.play();
        if(Platform.OS === 'ios') {
            ReactNativeHapticFeedback.trigger('notificationSuccess', {
                enableVibrateFallback: true,
                ignoreAndroidSystemSettings: false
            });
        }
    }

    const onVerifyCredentials = async () => {
        try {
            // prevent moving forward if no code has been scanned
            if(!routeID) {
                return;
            }

            // retrieve fresh location
            onScan(true);
            let location = await utils.location.get();
            if(!location) {

                onScan(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue gathering your current location. Please check that Location Permissions have been granted to ${utils.client.get().name} in the Settings app on your device`,
                    buttons: [{
                        key: 'settings',
                        title: 'Settings',
                        style: 'destructive'
                    },{
                        key: 'cancel',
                        title: 'Maybe Later',
                        style: 'default'
                    }],
                    onPress: key => {
                        if(key === 'settings') {
                            Linking.openSettings();
                            return;
                        }
                    }
                })
                return;
            }

            // fetch route details
            let { route } = await Request.get(utils, '/quick_scan/', {
                type: 'get_route',
                id: routeID,
                ...location
            });

            onScan(false);
            setLayerState('close');
            if(typeof(onVerify) === 'function') {
                onVerify(Route.create(route));
            }

        } catch(e) {
            onScan(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the route information. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        onVerifyCredentials();
    }, [routeID]);

    useEffect(() => {
        utils.events.addListener('on_new_quick_scan_route_id', onNewRouteID);
        return () => {
            utils.events.removeListener('on_new_quick_scan_route_id', onNewRouteID);
        }
    }, [])

    return (
        <Layer
        id={layerID}
        title={'QuickScan'}
        utils={utils}
        index={index}
        options={{
            ...options,
            layerState: layerState,
            bottomCard: true,
            showDimView: false
        }}
        buttons={[{
            key: 'cancel',
            text: 'Cancel',
            color: Appearance.colors.grey(),
            onPress: onCancelPress
        }]}>
            <View style={{
                alignItems: 'center',
                marginTop: 5
            }}>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 6
                }}>{'QuickScan'}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    textAlign: 'center'
                }}>{'Scan your driver’s QuickScan code to get started'}</Text>
            </View>
        </Layer>
    )
}

export const EditRouteItem = ({ children, item, onChange }, { index, options, utils }) => {

    const layerID = `edit-route-${item.key}`;
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const [layerState, setLayerState] = useState(null);

    useEffect(() => {
        utils.keyboard.subscribe(layerID, {
            onVisibility: visible => setKeyboardOpen(visible)
        });

        return () => {
            utils.keyboard.unsubscribe(layerID);
        }
    }, [])

    return (
        <Layer
            id={layerID}
            title={item.title}
            utils={utils}
            index={index}
            options={{
                ...options,
                bottomCard: true,
                layerState: layerState
            }}
            buttons={[{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onPress: () => {
                    Keyboard.dismiss();
                    if(typeof(onChange) === 'function') {
                        setTimeout(() => {
                            onChange(() => setLayerState('close')); // validated content
                        }, keyboardOpen ? 500 : 0);
                    }
                }
            }]}>

            <View style={{
                alignItems: 'center',
                marginTop: 5
            }}>
                <Text style={{
                    ...Appearance.textStyles.panelTitle(),
                    marginBottom: 6
                }}>{item.title}</Text>
                <Text style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 20,
                    textAlign: 'center'
                }}>{item.on_edit.description}</Text>
                {children()}
            </View>
        </Layer>
    )
}

export const RouteDetails = ({ onClose, onResumeRoute, onStartRoute }, { abstract, index, options, utils }) => {

    const layerID = `route-${abstract.getID()}`;

    const [annotations, setAnnotations] = useState([]);
    const [driver, setDriver] = useState(null);
    const [driverLocation, setDriverLocation] = useState(utils.location.last());
    const [layerState, setLayerState] = useState(null);
    const [lineItems, setLineItems] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingDriver, setLoadingDriver] = useState(false);
    const [overlays, setOverlays] = useState([]);
    const [route, setRoute] = useState(abstract.object);
    const [routeLineItems, setRouteLineItems] = useState(null);

    const onCancelRoute = () => {

        const submitCancellation = async () => {
            try {
                setLoading(true);
                await Request.get(utils, '/quick_scan/', {
                    type: 'cancel',
                    route_id: abstract.getID()
                });

                setLoading(false);
                utils.content.fetch('routes');
                utils.alert.show({
                    title: 'All Done!',
                    message: `Your QuickScan route has been cancelled`
                });

            } catch(e) {
                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue cancelling your QuickScan route. ${e.message || 'An unknown error occurred'}`
                })
            }
        }

        utils.alert.show({
            title: 'Cancel Route',
            message: 'Are you sure that you want to cancel this QuickScan route? This will also cancel all reservations within this route',
            buttons: [{
                key: 'confirm',
                title: 'Cancel',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Cancel',
                style: 'default'
            }],
            onPress: key => {
                if(key === 'confirm') {
                    submitCancellation();
                    return;
                }
            }
        })
    }

    const onCodeAccepted = (id, data) => {
        utils.alert.hide(id);
        utils.sockets.off('quick_scan', `on_code_scan_${data.route_id}`, onCodeAccepted.bind(this, id));
    }

    const onCodePress = () => {

        let vehicle = utils.driver.vehicle.get();
        if(!vehicle) {
            utils.alert.show({
                title: 'Oops!',
                message: 'Please add a vehicle before moving on'
            });
            return;
        }

        let id = utils.alert.show({
            title: 'QuickScan Code',
            message: 'Have your customer scan your QuickScan Code to add them to your Route',
            content: (
                <View style={{
                    padding: 15
                }}>
                    <QRCode
                    color={'white'}
                    size={Screen.width() / 2}
                    backgroundColor={Appearance.colors.primary()}
                    content={JSON.stringify({
                        route_id: route.id,
                        driver: utils.user.get().user_id,
                        vehicle: vehicle.id,
                        channel: 'quick_scan',
                        client_id: API.get_client_id()
                    })}  />
                </View>
            )
        })
        utils.sockets.on('quick_scan', `on_code_scan_${route.id}`, onCodeAccepted.bind(this, id));
    }

    const onContactUser = reservation => {
        utils.sheet.show({
            title: reservation.customer.full_name,
            message: `How would you like to contact ${reservation.customer.first_name}?`,
            items: [{
                key: 'call',
                title: 'Call',
                style: 'default'
            },{
                key: 'text',
                title: 'Text Message',
                style: 'default'
            },{
                key: 'message',
                title: 'In App Message',
                style: 'default'
            }]
        }, (key) => {
            if(key === 'call') {
                Call(reservation.customer.phone_number, true).catch(e => {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue setting up your call. ${e.message || 'An unknown error occurred'}`
                    })
                })

            } else if(key === 'text') {
                TextMessage(reservation.customer.phone_number).catch(e => {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue setting up your text message. ${e.message || 'An unknown error occurred'}`
                    })
                })

            } else if(key === 'message') {
                setLayerState('close');
                setTimeout(() => {
                    utils.layer.messaging(Abstract.create({
                        type: 'reservations',
                        object: reservation
                    }))
                }, 1000);
            }
        })
    }

    const onDeleteRoute = () => {
        utils.alert.show({
            title: 'Delete Route',
            message: 'Are you sure that you want to delete this route? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onPress: key => {
                if(key === 'confirm') {
                    onDeleteRouteConfirm();
                    return;
                }
            }
        })
    }

    const onDeleteRouteConfirm = async () => {
        try {
            setLoading('options');
            await Utils.sleep(1);
            await Request.post(utils, '/quick_scan/', {
                type: 'delete_route',
                id: abstract.getID()
            });

            setLoading(false);
            utils.content.fetch('routes');
            utils.alert.show({
                title: 'All Done!',
                message: 'This route has been deleted',
                onPress: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this route. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onItemPress = item => {
        if(item.on_press) {
            switch(item.on_press.type) {
                case 'reservation':
                    let reservation = abstract.object.reservations.find(reservation => reservation.id === item.value.id);
                    if(reservation) {
                        utils.layer.reservation.details(reservation)
                    }
                    break;

                case 'customer':
                    if(!item.value.user) {
                        return;
                    }
                    let user = User.create(item.value.user);
                    utils.sheet.show({
                        title: user.full_name,
                        message: `How would you like to contact ${user.first_name}?`,
                        items: [{
                            key: 'call',
                            title: 'Call',
                            style: 'default'
                        },{
                            key: 'text',
                            title: 'Text Message',
                            style: 'default'
                        },{
                            key: 'message',
                            title: 'In App Message',
                            style: 'default'
                        }]
                    }, (key) => {
                        if(key === 'call') {
                            Call(user.phone_number, true).catch(e => {
                                utils.alert.show({
                                    title: 'Oops!',
                                    message: `There was an issue setting up your call. ${e.message || 'An unknown error occurred'}`
                                })
                            })

                        } else if(key === 'text') {
                            TextMessage(user.phone_number).catch(e => {
                                utils.alert.show({
                                    title: 'Oops!',
                                    message: `There was an issue setting up your text message. ${e.message || 'An unknown error occurred'}`
                                })
                            })

                        } else if(key === 'message') {

                            const showMessaging = (id) => {

                                let reservation = abstract.object.reservations.find(reservation => reservation.id === id);
                                if(reservation) {
                                    setLayerState('close');
                                    setTimeout(() => {
                                        utils.layer.messaging(Abstract.create({
                                            type: 'reservations',
                                            object: reservation
                                        }))
                                    }, 1000);
                                }
                            }

                            if(item.value.reservations && item.value.reservations.length > 1) {
                                utils.sheet.show({
                                    items: item.value.reservations.map(id => {
                                        return {
                                            key: id,
                                            title: `Reservation #${id}`,
                                            style: 'default'
                                        }
                                    })
                                }, (key) => {
                                    if(!isNaN(key)) {
                                        showMessaging(key);
                                    }
                                })
                                return;
                            }
                            showMessaging(item.value.reservations[0]);
                        }
                    })
                    break;
            }
            return;
        }

        if(item.on_edit) {
            showEditOptions(item);
        }
    }

    const onOptionsPress = () => {
        utils.sheet.show({
            items: [{
                key: 'rename',
                title: 'Rename',
                style: 'default'
            },{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'rename') {
                onRenameRoute();
                return;
            }
            if(key === 'delete') {
                onDeleteRoute();
                return;
            }
        });
    }

    const onRenameRoute = () => {
        utils.alert.show({
            title: 'Rename Route',
            message: 'Please type your desired route name below. We will use this name to represent the route to you and others in the community.',
            textFields: [{
                key: 'name',
                placeholder: route.categories ? route.categories[0].name : 'Route Name'
            }],
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onPress: response => {
                if(response.name) {
                    onRenameRouteConfirm(response.name);
                    return;
                }
            }
        })
    }

    const onRenameRouteConfirm = async name => {
        try {
            setLoading('options');
            await Utils.sleep(1);
            await Request.post(utils, '/quick_scan/', {
                type: 'set_route_name',
                id: abstract.getID(),
                name: name
            });

            setLoading(false);
            abstract.object.name = name;
            utils.content.update(abstract);
            utils.alert.show({
                title: 'All Done!',
                message: `This route has been renamed to "${name}"`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue renaming this route. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const canShowCode = () => {
        return route.driver.user_id === utils.user.get().user_id && [
            StatusCodes.routes.approved,
            StatusCodes.routes.returned,
            StatusCodes.routes.inProgress
        ].includes(abstract.object.status.code);
    }

    const getButtons = () => {
        let buttons = [];
        if(onStartRoute || onResumeRoute) {
            buttons.push({
                key: 'options',
                text: 'Options',
                color: 'grey',
                onPress: onOptionsPress
            })
        }
        if(onStartRoute) {
            buttons.push({
                key: 'start',
                text: buttons.length > 0 ? 'Start' : 'Start Route',
                color: 'primary',
                loading: loadingDriver,
                onPress: async () => {
                    try {
                        setLoadingDriver(true);
                        await onStartRoute(utils, abstract);
                        setLayerState('close');
                    } catch(e) {
                        setLoadingDriver(false);
                        utils.alert.show({
                            title: 'Oops!',
                            message: `There was an issue starting your Route. ${e.message || 'An unknown error occurred'}`
                        })
                    }
                }
            });
        }
        if(onResumeRoute) {
            buttons.push({
                key: 'resume',
                text: buttons.length > 0 ? 'Resume' : 'Resume Route',
                color: Appearance.colors.blue,
                loading: loadingDriver,
                onPress: async () => {
                    try {
                        setLoadingDriver(true);
                        await onResumeRoute(utils, abstract);
                        setLayerState('close');
                    } catch(e) {
                        setLoadingDriver(false);
                        utils.alert.show({
                            title: 'Oops!',
                            message: `There was an issue resuming your Route. ${e.message || 'An unknown error occurred'}`
                        })
                    }
                }
            });
        }
        return buttons.length > 0 ? buttons : null;
    }

    const getRouteDescription = () => {
        let { reservations } = abstract.object;
        if(reservations.length === 0) {
            return 'No Reservations have been added';
        }
        if(reservations.length === 1) {
            return `Drop-Off at ${reservations[0].destination.name}`;
        }
        return `${reservations[0].destination.name} and ${reservations.length - 1} Other ${reservations.length - 1 === 1 ? 'Stop' : 'Stops'}`;
    }

    const getTripNavigator = () => {
        if(utils.user.get().level < User.level.customer) {
            return null;
        }
        return (
            <ActiveAbstractHeader
            utils={utils}
            driver={driver}
            abstract={abstract}
            style={{
                marginTop: 20
            }} />
        )
    }

    const fetchLineItems = async () => {
        try {
            let location = utils.location.last();
            let { route, lineItems } = await fetchQuickScanLineItems(utils, {
                route_id: abstract.getID(),
                start_location: location ? {
                    lat: location.latitude,
                    long: location.longitude
                } : null
            })

            setLineItems(lineItems.map(section => {
                section.items = section.items.filter(item => item.visible !== false);
                return section;
            }).filter(section => {
                return section.visible !== false;
            }));
            setRouteLineItems(route ? route.line_items : null);

            setAnnotations(abstract.object.reservations.map(reservation => {
                return {
                    id: `reservation-${reservation.id}`,
                    title: reservation.destination.name,
                    subTitle: reservation.destination.address,
                    location: reservation.destination.location
                }
            }));

            setOverlays(route && route.legs ? route.legs.map((leg, index) => {
                return Utils.decodePolyline(leg.shape, 6);
            }).reduce((overlays, leg) => {
                overlays[0].coordinates = overlays[0].coordinates.concat(leg);
                return overlays;
            }, [{
                key: 'legs',
                coordinates: []
            }]) : null);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the information for this QuickScan Route. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        setAnnotations(abstract.object.reservations.map(reservation => {
            return {
                id: `drop-off-${reservation.id}`,
                title: reservation.customer.full_name,
                subTitle: reservation.destination.address,
                location: reservation.destination.location,
                data: {
                    key: `drop-off-${reservation.id}`,
                    reservation: reservation
                }
            }
        }));
    }, [abstract.object.reservations]);

    useEffect(() => {

        fetchLineItems();
        utils.sockets.on('quick_scan', `add_ride_${route.id}`, fetchLineItems)
        utils.location.subscribe(layerID, {
            onUpdate: location => {
                setDriverLocation(location);
            }
        })
        return () => {
            utils.location.unsubscribe(layerID);
            utils.sockets.off('quick_scan', `add_ride_${route.id}`, fetchLineItems)
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={'Route Details'}
        index={index}
        utils={utils}
        buttons={getButtons()}
        contentStyle={{
            paddingBottom: FloatingLayerMinimizedHeight + 15
        }}
        options={{
            ...options,
            loading: loading,
            layerState: layerState,
            removePadding: true
        }}>
            { /* map and overview */}
            <View style={{
                paddingTop: LayerHeaderSpacing.get(),
                paddingHorizontal: 15
            }}>
                {getTripNavigator()}
                <View style={Appearance.styles.panel()}>
                    <View style={{
                        width: '100%',
                        borderRadius: 8,
                        overflow: 'hidden'
                    }}>
                        <Map
                        utils={utils}
                        userLocation={driverLocation}
                        userLocationIcon={require('eCarra/images/tesla-model-s.png')}
                        userLocationIconStyle={{
                            iconSize: 0.4,
                            iconRotate: driverLocation ? (driverLocation.heading || 0) : 0
                        }}
                        overlays={overlays}
                        annotations={annotations}
                        style={{
                            height: Platform.OS === 'web' || Utils.isMobile() === false ? 400 : 200,
                            width: Screen.layer.maxWidth - 24,
                            marginBottom: 4,
                            borderBottomWidth: 1,
                            borderBottomColor: Appearance.colors.divider()
                        }}/>
                        {Views.entry({
                            title: route.name || `Route #${abstract.getID()}`,
                            subTitle: getRouteDescription(),
                            hideIcon: !canShowCode(),
                            textStyles: {
                                title: {
                                    ...Appearance.textStyles.panelTitle(),
                                    fontSize: 16
                                }
                            },
                            icon: () => (
                                <TouchableOpacity
                                activeOpacity={0.6}
                                onPress={onCodePress}
                                style={{
                                    width: 35,
                                    height: 35,
                                    backgroundColor: Appearance.colors.primary(),
                                    padding: 3,
                                    borderRadius: 5,
                                    overflow: 'hidden'
                                }}>
                                    <LottieView
                                    autoPlay={true}
                                    loop={true}
                                    duration={2500}
                                    source={require('eCarra/files/lottie/quick-scan-white.json')}
                                    style={{
                                        width: '100%',
                                        height: '100%'
                                    }}/>
                                </TouchableOpacity>
                            )
                        })}
                        {routeLineItems && (
                            routeLineItems.map((item, index, items) => {
                                return (
                                    <View
                                    key={index}
                                    style={{
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        paddingHorizontal: 12,
                                        paddingVertical: 8,
                                        borderBottomWidth: 1,
                                        borderBottomColor: Appearance.colors.divider()
                                    }}>
                                        <Text style={Appearance.textStyles.key()}>{item.title}</Text>
                                        <Text style={Appearance.textStyles.value()}>{item.message}</Text>
                                    </View>
                                )
                            })
                        )}
                    </View>
                </View>

                {/* line items */}
                {lineItems && (
                    lineItems.filter(lineItem => {
                        return lineItem.items && lineItem.items.length > 0;
                    }).map((lineItem, index) => {
                        return (
                            <View
                            key={index}
                            style={{
                                marginTop: 20
                            }}>
                                <Text
                                ellipsizeMode={'tail'}
                                numberOfLines={1}
                                style={{
                                    ...Appearance.textStyles.panelTitle(),
                                    fontSize: 16,
                                    marginBottom: 12
                                }}>
                                    {lineItem.title}
                                </Text>
                                <View style={Appearance.styles.panel()}>
                                {lineItem.items.map((item, index, items) => {

                                    let badge = false;
                                    switch(lineItem.key) {
                                        case 'reservations':
                                            badge = item.value && item.value.status && item.value.status > StatusCodes.reservations.returned ? Reservation.getStatus(item.value.status) : null;
                                            break;
                                    }

                                    return  (
                                        Views.entry({
                                            key: index,
                                            title: item.title,
                                            subTitle: item.formatted || item.null_value,
                                            loading: item.loading,
                                            hideIcon: item.icon ? false : true,
                                            icon: item.icon && {
                                                path: { uri: item.icon.path },
                                                style: Appearance.icons[item.icon.style || 'user']
                                            },
                                            badge: badge,
                                            bottomBorder: index !== items.length - 1,
                                            onPress: item.on_press || (item.on_edit && item.on_edit.enabled) ? onItemPress.bind(this, item) : null,
                                            propStyles: {
                                                subTitle: {
                                                    numberOfLines: 2
                                                }
                                            }
                                        })
                                    )
                                })}
                                </View>
                            </View>
                        )
                    })
                )}
            </View>
        </Layer>
    )
}
