import React, { useState, useEffect, useRef } from 'react';
import { View, Text, Animated, ScrollView, Keyboard, TouchableWithoutFeedback } from 'react-native';

import Appearance from 'eCarra/styles/Appearance.js';
import Button from 'eCarra/views/Button.js';
import Screen from 'eCarra/files/Screen.js';

const LayerDropDown = ({ children, onCommit, onClose, options, style, visible }) => {

    const ref = useRef(null);
    const [open, setOpen] = useState(false);
    const [top, setTop] = useState(new Animated.Value(-Screen.height()));
    const [opacity, setOpacity] = useState(new Animated.Value(0));
    const [zIndex, setZIndex] = useState(null);

    const onContentCommit = () => {
        close(() => {
            if(onCommit && typeof(onCommit) === 'function') {
                onCommit();
            }
        })
    }

    const onMiddleButton = () => {

        Keyboard.dismiss();
        if(options.middleButton.onPress && typeof(options.middleButton.onPress) === 'function') {
            if(options.middleButton.closeonPress) {

                if(options.middleButton.waitForCallback) {
                    options.middleButton.onPress(close);
                } else {
                    close(() => {
                        options.middleButton.onPress();
                    })
                }

                return;
            }
            options.middleButton.onPress();
        }
    }

    const onRightButton = () => {

        Keyboard.dismiss();
        if(options.rightButton.onPress && typeof(options.rightButton.onPress) === 'function') {
            if(options.rightButton.closeonPress) {

                if(options.rightButton.waitForCallback) {
                    options.rightButton.onPress(close);
                } else {
                    close(() => {
                        options.rightButton.onPress();
                    })
                }

                return;
            }
            options.rightButton.onPress();
        }
    }

    const close = (callback) => {

        Animated.spring(opacity, {
            toValue: 0,
            duration: 250,
            useNativeDriver: false
        }).start();
        Animated.timing(top, {
            toValue: -Screen.height(),
            duration: 150,
            useNativeDriver: false
        }).start(() => {
            setOpen(false);
            if(callback && typeof(callback) === 'function') {
                callback();
            }
            if(onClose && typeof(onClose) === 'function') {
                onClose();
            }
        })
    }

    useEffect(() => {
        setOpen(visible);
        setTimeout(() => {
            Animated.spring(opacity, {
                toValue: visible ? 1 : 0,
                duration: 250,
                useNativeDriver: false
            }).start();
            Animated.spring(top, {
                toValue: visible ? 0 : -Screen.height(),
                duration: 250,
                friction: 10,
                useNativeDriver: false
            }).start();
        }, 250);
    }, [visible])

    return open ? (
        <Animated.View ref={ref} style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: opacity,
            borderRadius: 10,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0,0,0,0.25)',
            ...Appearance.boxShadow({
                opacity: 0.15,
                radius: 10,
                offset: {
                    width: 5,
                    height: 5
                }
            }),
            ...style
        }}>
            <Animated.View style={{
                position: 'absolute',
                top: top,
                width: '75%',
                backgroundColor: Appearance.colors.dropDown(),
                borderWidth: 1,
                borderColor: Appearance.colors.divider(),
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8
            }}>
                <ScrollView
                keyboardShouldPersistTaps={'always'}
                style={{
                    width: '100%',
                    height: '100%',
                    maxHeight: Screen.height() - Screen.safeArea.bottom - 125,
                }}>
                    <TouchableWithoutFeedback
                    onPress={() => Keyboard.dismiss()}>
                        <View style={{
                            padding: options && options.removePadding ? 0:10
                        }}>
                            {children}
                        </View>
                    </TouchableWithoutFeedback>
                </ScrollView>
                <View style={{
                    padding: 10,
                    borderTopWidth: 1,
                    borderTopColor: Appearance.colors.divider()
                }}>
                    {options && options.showDoneButton === false
                        ?
                        <Button label={'Close'} color={'dark'} type={'large'} onPress={close}/>
                        :
                        <View style={{
                            flexDirection: 'row',
                            width: '100%'
                        }}>
                            <View style={{
                                paddingRight: 4,
                                width: options && options.middleButton ? '33%' : '50%'
                            }}>
                                <Button
                                label={'Cancel'}
                                color={'dark'}
                                type={'large'}
                                onPress={close}/>
                            </View>
                            {options && options.middleButton
                                ?
                                <View style={{
                                    width: '33%',
                                    paddingLeft: 4,
                                    paddingRight: 4
                                }}>
                                    <Button
                                    label={options.middleButton.label}
                                    color={options.middleButton.color}
                                    type={'large'}
                                    onPress={onMiddleButton}/>
                                </View>
                                :
                                null
                            }
                            <View style={{
                                paddingLeft: 4,
                                width: options && options.middleButton ? '33%' : '50%'
                            }}>
                                <Button
                                type={'large'}
                                label={options && options.rightButton ? options.rightButton.label : 'Done'}
                                color={options && options.rightButton ? options.rightButton.color : 'primary'}
                                onPress={options && options.rightButton ? onRightButton : onContentCommit}/>
                            </View>
                        </View>
                    }
                </View>
            </Animated.View>
        </Animated.View>
    ) : null
}
export default LayerDropDown;
