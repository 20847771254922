export const Camera = () => {
    return (
        <div />
    )
}
export const Constants = {
    Type: {
        front: null,
        back: null
    },
    FlashMode: {
        on: null,
        off : null
    }
}
