export default {
    openPicker: async () => {
        return new Promise((resolve, reject) => {

            let el = document.createElement('input');
            el.type = 'file';
            el.class = `custom-file-input ${window.theme}`;
            el.accept = 'image/png, image/jpeg';
            el.style.display = 'none';

            let files = null;
            el.onchange = evt => {
                files = evt.target.files;
            }

            document.body.appendChild(el);
            document.body.onfocus = evt => {

                document.body.onfocus = null;
                if(!files || files.length === 0) {
                    el.remove();
                    let error = new Error('user clicked cancel');
                    reject(error);
                    return
                }

                let file = files[0];
                let reader = new FileReader();
                reader.onload = data => {
                    el.remove();
                    resolve({
                        data: data.target.result,
                        path: file.name,
                        mime: file.type
                    });
                };
                reader.readAsDataURL(files[0]);
            }
            el.click();
        });
    }
}
