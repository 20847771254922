import React, { useEffect, useState } from 'react';
import { ScrollView, Text, View } from 'react-native';

import Appearance from 'eCarra/styles/Appearance.js';
import ProgressBar from 'eCarra/views/ProgressBar/';
import Screen from 'eCarra/files/Screen.js';
import TextField from 'eCarra/views/TextField.js';
import Utils from 'eCarra/files/Utils';
import Views from 'eCarra/views/Main.js';

const CreditsManager = ({ defaultAmount, defaultCardID, utils, onChange, onRemove, maxAmount, style }) => {

    const [amount, setAmount] = useState(0);
    const [customerMethods, setCustomerMethods] = useState(null);
    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState([]);
    const [selected, setSelected] = useState(null);
    const [userMethods, setUserMethods] = useState(null);

    const fetchMethods = async () => {
        try {
            let { credits } = await utils.user.get().getPaymentMethods(utils);
            setLoading(false);
            setUserMethods(credits);
            setSelected(credits.find(m => defaultCardID === m.card.id));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your credits. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onPress = method => {
        if(method.balance() <= 0) {
            utils.alert.show({
                title: method.getType(),
                message: 'This Credits card does not have a usable balance'
            });
            return;
        }

        let newMethod = selected && selected.id === method.id ? null : method;
        setSelected(newMethod);

        if(newMethod && typeof(onChange) === 'function') {
            onChange({
                card_id: method.card.id,
                ...(newMethod && amount > 0 ? {
                    amount: parseFloat(amount)
                } : null)
            });
            return;
        }

        if(!newMethod && typeof(onRemove) === 'function') {
            onRemove();
        }
    }

    const onSetAmount = amount => {

        if(selected && selected.balance() < amount) {
            utils.alert.show({
                title: 'Oops!',
                message: `This credits amount can not exceed the balance of ${Utils.toCurrency(selected.balance())} for this card`
            })
            return;
        }
        if(maxAmount && amount > maxAmount) {
            utils.alert.show({
                title: 'Oops!',
                message: `This credits amount can not exceed the total cost of ${Utils.toCurrency(maxAmount)} for the Reservation`
            })
            return;
        }

        setAmount(amount);
        onChange({
            amount: amount > 0 ? parseFloat(amount) : null,
            card_id: selected ? selected.card.id : null
        });
    }

    useEffect(() => {
        fetchMethods();
        setAmount(defaultAmount || '0.00');
    }, []);

    return (
        <View style={{
            width: '100%',
            ...style
        }}>
            {/*userMethods && userMethods.length > 0 && (
                <>
                <Text style={{
                    ...Appearance.textStyles.title(),
                    paddingBottom: 8
                }}>{'Custom Amount (optional)'}</Text>
                <TextField
                prepend={'$'}
                icon={'credits'}
                onChange={text => onSetAmount(isNaN(text) ? 0 : parseFloat(text))}
                value={amount}
                containerStyle={{
                    marginBottom: 20
                }}/>
                </>
            )*/}
            <View style={{
                ...Appearance.styles.panel(),
                overflow: 'hidden'
            }}>
                {userMethods && userMethods.length > 0
                    ?
                    <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{
                        width: '100%',
                        maxHeight: 54 * 3.5
                    }}>
                        {userMethods.map((method, index, methods) => {
                            return Views.entry({
                                key: method.id,
                                title: method.getType(),
                                subTitle: `Balance: ${Utils.toCurrency(method.balance())}`,
                                icon: {
                                    style: {
                                        ...Appearance.icons.standard,
                                        backgroundColor: selected && selected.id === method.id ? Appearance.colors.primary() : Appearance.colors.grey()
                                    },
                                    path: method.balance() <= 0 ? require('eCarra/images/rejected-red-small.png') : require('eCarra/images/payment-icon-clear-small.png')
                                },
                                bottomBorder: index !== methods.length - 1,
                                onPress: onPress.bind(this, method)
                            })
                        })}
                    </ScrollView>
                    :
                    Views.entry({
                        title: loading ? 'Loading Credits...' : 'No Credits Found',
                        subTitle: loading ? 'This may take a moment' : 'Credits are not setup for your account',
                        icon: {
                            style: Appearance.icons.standard,
                            path: loading ? require('eCarra/images/payment-icon-clear-small.png') : require('eCarra/images/rejected-red-small.png')
                        },
                        bottomBorder: false
                    })
                }
                <ProgressBar animate={loading} />
            </View>
        </View>
    )
}
export default CreditsManager;
