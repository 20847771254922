import React, { useEffect, useState } from 'react';
import { Image, Keyboard, ScrollView, Text, View } from 'react-native';

import update from 'immutability-helper';

import API from 'eCarra/files/api.js';
import Appearance from 'eCarra/styles/Appearance.js';
import Request from 'eCarra/files/Request/';
import Screen from 'eCarra/files/Screen.js';
import TextField from 'eCarra/views/TextField.js';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';
import Utils from 'eCarra/files/Utils.js';
import Views from 'eCarra/views/Main.js';

const MultipleAddressLookupField = ({ autoComplete, autoCorrect, autoCapitalize, containerStyle, fieldStyle, icon, isSecure, utils, hideIcon, label, locations, onChange, placeholder, spellCheck }) => {

    const [nonce, setNonce] = useState(Utils.randomString());
    const [results, setResults] = useState([]);
    const [searching, setSearching] = useState(false);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [text, setText] = useState('');
    const [textTimeout, setTextTimeout] = useState(false);

    const onLocationPress = async location => {
        try {
            setText('');
            setResults([]);
            setSearching(true);
            let { result } = await Utils.geocode(utils, location, nonce);

            setSearching(false);
            let locations = update(selectedLocations, {
                $push: [{
                    ...location,
                    ...result,
                    ...result.location && {
                        location: {
                            latitude: result.location.lat,
                            longitude: result.location.long
                        }
                    }
                }]
            });

            setSelectedLocations(locations);
            setNonce(Utils.randomString());
            if(typeof(onChange) === 'function') {
                onChange(locations);
            }

        } catch(e) {
            setSearching(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue locating the information for this address. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onRemovePress = index => {
        let updatedLocations = update(selectedLocations, {
            $splice: [
                [index, 1]
            ]
        });
        setSelectedLocations(updatedLocations);
        if(typeof(onChange) === 'function') {
            onChange(updatedLocations);
        }
    }

    const onTextChange = text => {
        setSearching(true);
        if(textTimeout) {
            clearTimeout(textTimeout);
        }
        setText(text);
        setTextTimeout(setTimeout(async () => {
            if(text.length < 3) {
                setResults([]);
                setSearching(false);
                return;
            }
            try {
                let results = await Utils.addressLookup(utils, text, nonce);
                setSearching(false);
                setResults(results)

            } catch(e) {
                setSearching(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue loading the search results list. ${e.message || 'An unknown error occurred'}`
                })
            }

        }, 250));
    }

    useEffect(() => {
        setSelectedLocations(locations || []);
    }, [locations]);

    return (
        <View style={{
            textAlign: 'left',
            width: '100%'
        }}>
            <View style={{
                height: 35,
                ...containerStyle
            }}>
                <TextField
                value={text}
                loading={searching}
                autoComplete={false}
                autoCorrect={false}
                autoCapitalize={'sentences'}
                onChange={onTextChange} />
            </View>
            <ScrollView
            scrollEventThrottle={1}
            onScroll={() => Keyboard.dismiss()}
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps={'always'}
            style={{
                maxHeight: Screen.height() / 3
            }}
            contentContainerStyle={{
                marginTop: results.length > 0 || selectedLocations.length > 0 ? 15 : 0,
                ...containerStyle
            }}>
            {results.length > 0 && (
                <View style={Appearance.styles.panel()}>
                    {results.map((result, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: result.name,
                                subTitle: result.address,
                                hideIcon: true,
                                bottomBorder: index !== results.length - 1,
                                onPress: onLocationPress.bind(this, result)
                            })
                        )
                    })}
                </View>
            )}
            {selectedLocations.length > 0 && (
                <>
                <Text style={{
                    ...Appearance.textStyles.title(),
                    marginBottom: 8,
                    marginTop: 20
                }}>{label || 'Drop Off Locations'}</Text>
                <View style={Appearance.styles.panel()}>
                    {selectedLocations.map((location, index, locations) => {
                        return (
                            Views.entry({
                                key: index,
                                title: location.name,
                                subTitle: location.address,
                                bottomBorder: index !== locations.length - 1,
                                hideIcon: true,
                                rightContent: (
                                    <TouchableOpacity
                                    activeOpacity={0.6}
                                    onPress={onRemovePress.bind(this, index)}
                                    style={{
                                        width: 20,
                                        height: 20,
                                        marginLeft: 12
                                    }}>
                                        <Image
                                        source={require('eCarra/images/red-x-icon.png')}
                                        onPress={onRemovePress.bind(this, index)}
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }} />
                                    </TouchableOpacity>
                                )
                            })
                        )
                    })}
                </View>
                </>
            )}
            </ScrollView>
        </View>
    )
}
export default MultipleAddressLookupField;
