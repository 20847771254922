import React, { useEffect, useState } from 'react';
import { Animated, Image, Platform, Text, View } from 'react-native';

import update from 'immutability-helper';

import Appearance from 'eCarra/styles/Appearance.js';
import Button from 'eCarra/views/Button.js';
import LottieView from 'eCarra/views/Lottie/';
import Screen from 'eCarra/files/Screen.js';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';

const Panel = ({ animate, children, column, header, index, layer, name, onClose, options = {}, panelID, rightContent, style, utils }) => {

    const { buttons, loading, loadingPanel, maxWidth, pageControl, removeOverflow, removePadding, shouldStyle } = options;

    const [onHover, setOnHover] = useState(null);
    const [panelState, setPanelState] = useState({ action: null, frame: null });
    const [size, setSize] = useState({ width: Screen.width(), height: Screen.height() });
    const [visible, setVisible] = useState(true);

    const onPanelActionChange = action => {
        setVisible(action !== 'close');
        setPanelState(panelState => update(panelState, {
            action: {
                $set: action === panelState.action ? null : action
            }
        }))
    }

    const onWindowSizeChange = () => {
        setSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }

    return visible ? (
        <View style={{
            ...children && removePadding !== true && {
                paddingLeft: 15,
                paddingRight: 15,
                paddingBottom: 15
            }
        }}>
            <Animated.View style={{
                position: 'relative',
                width: '100%',
                height: 'auto',
                maxWidth: maxWidth,
                paddingBottom: children && removePadding !== true ? 10 : 0,
                ...panelState.action === 'fullscreen' && Platform.OS !== 'web' && {
                    width: '100%',
                    height: '100%'
                }
            }}>
                {(header || typeof(name) === 'string') && (
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 10,
                        marginBottom: header ? 5 : 12
                    }}>
                        {header || (
                            <Text style={Appearance.textStyles.panelTitle()}>{name}</Text>
                        )}
                        {rightContent}
                    </View>
                )}

                <View style={shouldStyle === false ? null : Appearance.styles.panel()}>
                    {loading
                        ?
                        <View style={{
                            width: '100%',
                            paddingHorizontal: removePadding ? 15 : 0,
                            marginBottom: shouldStyle ? 0 : 20
                        }}>
                            <View style={{
                                padding: 20,
                                alignItems: 'center',
                                justifyContent: 'center',
                                ...loadingPanel && loadingPanel.style,
                                ...shouldStyle === false && {
                                    ...Appearance.styles.panel()
                                }
                            }}>
                                <LottieView
                                autoPlay={true}
                                loop={true}
                                source={Appearance.themeStyle() === 'dark' ? require('eCarra/files/lottie/dots-white.json') : require('eCarra/files/lottie/dots-grey.json')}
                                duration={2500}
                                style={{
                                    width: 50
                                }}/>
                            </View>
                        </View>
                        :
                        <View style={{
                            position: 'relative',
                            overflow: removeOverflow ? 'visible' : 'hidden',
                            padding: removePadding || shouldStyle === false ? 0 : 10,
                            ...style
                        }}>
                            {children}
                        </View>
                    }
                    {pageControl && pageControl.description && pageControl.description.number_of_pages > 1 && (
                        <MobilePageControl {...pageControl} />
                    )}
                    {buttons && buttons.length > 0 && (
                        <View style={{
                            padding: 10,
                            borderTopWidth: 1,
                            borderTopColor: Appearance.colors.divider()
                        }}>
                            <View style={{
                                flex: 1,
                                flexDirection: 'row',
                                width: '100%'
                            }}>
                                {buttons.items.map(button => (
                                    <Button
                                    {...button}
                                    type={'large'}
                                    loading={button.loading || loading === button.key}
                                    style={{
                                        width: `${parseInt(100 / buttons.items.length)}%`
                                    }}/>
                                ))}
                            </View>
                        </View>
                    )}
                </View>
            </Animated.View>
        </View>
    ) : null
}
export default Panel;

export const MobilePageControl = props => {

    const { description, onPress } = props || {};
    
    const getContent = () => {
        if(!description || description.number_of_pages <= 1) {
            return null;
        }
        return (
            <View style={{
                padding: 10,
                borderTopWidth: 1,
                borderTopColor: Appearance.colors.divider()
            }}>
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: 'auto',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {description.current_page > 1 && (
                        <View style={{
                            paddingRight: description.current_page !== description.number_of_pages ? 4:0,
                            width: description.current_page !== description.number_of_pages ? '50%' : '100%'
                        }}>
                            <Button
                            label={'Go Back'}
                            style={'large'}
                            color={'secondary'}
                            onPress={onPress.bind(this, 'back')} />
                        </View>
                    )}
                    {description.current_page !== description.number_of_pages && (
                        <View style={{
                            paddingLeft: description.current_page > 1 ? 4:0,
                            width: description.current_page > 1 ? '50%' : '100%'
                        }}>
                            <Button
                            label={'View More'}
                            style={'large'}
                            color={'primary'}
                            onPress={onPress.bind(this, 'next')} />
                        </View>
                    )}
                </View>
            </View>
        )
    }

    return getContent()
}
