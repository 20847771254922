import React from 'react';
import { Image, View } from 'react-native';
import TouchableOpacity from 'eCarra/views/TouchableOpacity/';

export const AppleAuthenticationButton = ({ buttonStyle, cornerRadius, onPress, style }) => {

    const getButtonImage = () => {
        if(buttonStyle === AppleAuthenticationButtonStyle.WHITE) {
            return require('eCarra/images/sign-in-with-apple-white.jpg');
        }
        return require('eCarra/images/sign-in-with-apple-black.jpg');
    }

    return (
        <TouchableOpacity
        activeOpacity={0.6}
        onPress={onPress}
        style={{
            ...style,
            backgroundColor: buttonStyle === AppleAuthenticationButtonStyle.WHITE ? 'white' : 'black',
            borderRadius: cornerRadius,
            overflow: 'hidden'
        }}>
            <Image
            source={getButtonImage()}
            style={{
                width: '100%',
                height: '100%',
                resizeMode: 'contain'
            }} />
        </TouchableOpacity>
    )
}

export const AppleAuthenticationScope = {
    FULL_NAME: 0,
    EMAIL: 1
}
export const AppleAuthenticationButtonStyle = {
    WHITE: 0,
    WHITE_OUTLINE: 1,
    BLACK: 2
}
export const AppleAuthenticationButtonType = {
    SIGN_IN: 0,
    CONTINUE: 1,
    SIGN_UP: 2
}
export const isAvailableAsync = async () => {
    return new Promise(resolve => {
        resolve(window.AppleID && window.AppleID.auth ? true : false);
    })
}
export const signInAsync = async props => {
    return new Promise(async (resolve, reject) => {
        try {
            // check that browser has access to sdk
            if(!window.AppleID || !window.AppleID.auth) {
                throw new Error('Your browser does not support Sign in with Apple')
            }

            // add scopes to user supplied props
            let scopes = [];
            props.requestedScopes.forEach(scope => {
                switch(scope) {
                    case AppleAuthenticationScope.FULL_NAME:
                    scopes.push('name');
                    break;

                    case AppleAuthenticationScope.EMAIL:
                    scopes.push('email');
                    break;
                }
            })
            props.configure.scope = scopes.join(' ');

            // configure with apple client props
            window.AppleID.auth.init(props.configure);

            // setup document listeners
            document.addEventListener('AppleIDSignInOnSuccess', data => {
                let { authorization, user } = data.detail;
                resolve({
                    user: authorization.id_token,
                    ...user && {
                        email: user.email,
                        full_name: {
                            givenName: user.name.first_name,
                            familyName: user.name.last_name
                        }
                    }

                });
            });
            document.addEventListener('AppleIDSignInOnFailure', response => {
                let error = new Error(response.detail.error);
                reject(error);
            });
            await window.AppleID.auth.signIn();

        } catch(e) {
            reject(e);
        }
    })
}
