import React from 'react';
import moment from 'moment';

import API from 'eCarra/files/api.js';
import Appearance from 'eCarra/styles/Appearance.js';
import Company from 'eCarra/classes/Company';
import OrderCategory from 'eCarra/classes/OrderCategory.js';
import OrderChannel from 'eCarra/classes/OrderChannel.js';
import PromoCode from 'eCarra/classes/PromoCode.js';
import Request from 'eCarra/files/Request/';
import Service from 'eCarra/classes/Service.js';
import Utils from 'eCarra/files/Utils.js';

class OrderHostClass {

    id = null;
    name = null;
    description = null;
    channel = null;
    service = null;
    companies = null;
    date_added = null;
    locations = null;
    options = null;
    parameters = null;
    promotions = null;
    image = null;
    cover_image = null;
    categories = null;
    active = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.name = props.name;
        this.description = props.description;
        this.service = props.service ? Service.create(props.service) : null;
        this.channel = props.channel ? OrderChannel.create(props.channel) : null;
        this.companies = props.companies ? props.companies.map(c => Company.create(c)) : null;
        this.date_added = props.date_added ? moment(props.date_added) : null;
        this.options = props.options;
        this.parameters = props.parameters || {};
        this.image = props.image && typeof(props.image) !== 'object' ? { uri: props.image } : props.image;
        this.cover_image = props.cover_image ? { uri: props.cover_image } : null;
        this.active = props.active;
        this.promotions = props.promotions ? props.promotions.map(p => PromoCode.create(p)) : [];
        this.locations = props.locations ? props.locations.map(location => ({
            name: location.name,
            address: location.address,
            distance: location.distance,
            location: {
                latitude: location.lat,
                longitude: location.long
            }
        })) : [];
        return this;
    }

    getChosenLocation = (utils, userLocation) => {

        let selected = this.locations.find(location => location.selected);
        if(selected) {
            return selected;
        }

        if(userLocation) {
            let closest = this.locations.map((place, index) => {
                return {
                    index: index,
                    distance: Utils.linearDistance(place.location, userLocation.location)
                }
            }).sort((a,b) => {
                return a.distance < b.distance ? -1 : (a.distance > b.distance ? 1 : 0);
            })

            return closest.length > 0 ? this.locations[closest[0].index] : null;
        }

        return null;
    }

    getClosestLocation = (utils) => {

        let location = utils.location.last();
        if(location) {
            let closest = this.locations.map((place, index) => {
                return {
                    index: index,
                    distance: Utils.linearDistance(place.location, location)
                }
            }).sort((a,b) => {
                return a.distance < b.distance ? -1 : (a.distance > b.distance ? 1 : 0);
            })
            return closest.length > 0 ? this.locations[closest[0].index] : null;
        }

        return this.locations[0];
    }

    getCategories = async utils => {
        return new Promise(async (resolve, reject) => {
            if(this.categories) {
                resolve(this.categories);
                return;
            }
            try {
                let { categories } = await Request.get(utils, '/orders/', {
                    type: 'categories',
                    host_id: this.id,
                    order_channel: this.channel.id
                });
                console.log(categories);
                this.categories = categories.map(c => OrderCategory.create(c));
                resolve(this.categories);

            } catch(e) {
                reject(e);
            }
        })
    }

    verifyBookingDate = async date => {
        return new Promise((resolve, reject) => {
            try {
                let weekDay = parseInt(moment(date).format('d'));
                if(!this.parameters || !this.parameters.operations || !this.parameters.operations.hours) {
                    throw new Error(`${this.name} is currently closed`);
                }

                let today = moment(date).format('YYYY-MM-DD');
                let currentDay = this.parameters.operations.hours.find(entry => entry.day === weekDay);
                if(!currentDay) {
                    throw new Error(`${this.name} does not accept orders on ${moment(date).format('dddd')}`);
                }

                let { start, end } = currentDay;
                if(moment() < moment(`${today} ${start}`)) {
                    throw new Error(`${this.name} will start accepting orders at ${moment(`${today} ${end}`).format('h:mma')}`);
                }
                if(moment() > moment(`${today} ${end}`)) {
                    throw new Error(`${this.name} stopped accepting orders at ${moment(`${today} ${end}`).format('h:mma')}`);
                }
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    getHostOperatingHours = () => {

        let weekDay = parseInt(moment().format('d'));
        if(!this.parameters || !this.parameters.operations || !this.parameters.operations.hours) {
            return {
                text: 'Closed Right Now',
                color: Appearance.colors.red
            };
        }

        let today = moment().format('YYYY-MM-DD');
        let currentDay = this.parameters.operations.hours.find(entry => entry.day === weekDay);
        if(!currentDay) {
            return {
                text: `Closed Today`,
                color: Appearance.colors.red
            };
        }

        let { start, end } = currentDay;
        if(moment() < moment(`${today} ${start}`)) {
            return {
                text: `Available after ${moment(`${today} ${start}`).format('h:mma')}`,
                color: Appearance.colors.grey()
            };
        }
        if(moment() > moment(`${today} ${end}`)) {
            return {
                text: 'Closed Right Now',
                color: Appearance.colors.red
            };
        }
        return {
            text: `Available until ${moment(`${today} ${end}`).format('h:mma')}`,
            color: Appearance.colors.grey()
        };
    }

    businessHours = () => {
        let host = this.edits || this;
        return host.parameters && host.parameters.operations && host.parameters.operations.hours ? host.parameters.operations.hours.map(h => {
            return {
                ...h,
                formatted: `${moment(h.start, 'HH:mm:ss').format('h:mma')} to ${moment(h.end, 'HH:mm:ss').format('h:mma')}`
            }
        }) : null;
    }
}
export default {
    create: props => new OrderHostClass().create(props)
};
